import React from 'react';
import { Grid, Typography } from '@mui/material';

import theme from 'src/styles/theme';

interface ICardWithIconProps {
  title: string;
  description: string;
  icon: React.ReactNode;
}

const CardWithIcon = ({ title, description, icon }: ICardWithIconProps) => {
  return (
    <Grid
      container
      sx={{
        m: 0,
        p: 3,
        border: theme.custom.border,
        borderRadius: 2.5,
        alignContent: 'center',
        height: 92,
      }}
    >
      <Grid
        item
        p={0}
        sx={{
          width: 48,
          height: 48,
          background: theme.palette.colors.white,
          border: theme.custom.border,
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {icon}
      </Grid>
      <Grid item xs={8} pl={3}>
        <Typography variant="body1">{title}</Typography>
        <Typography variant="inherit" sx={{ color: theme.palette.colors.lightGrey, fontSize: 10 }}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CardWithIcon;
