import { useTranslation } from 'react-i18next';

import { RESEND_SHAREABLE_LINK } from 'src/services/graphql/mutations';

import { maskEmail } from './mask-email';
import useEnhancedMutation from './use-enhanced-mutation';
import useMessages from './use-messages';

const useExpiredSharableLink = ({ email }: { email: string }) => {
  const { t } = useTranslation();
  const { setErrorMessage, setSuccessMessage } = useMessages();

  const maskedEmail = maskEmail(email);

  const [resendShareableLink] = useEnhancedMutation<String>(RESEND_SHAREABLE_LINK, {
    onCompleted: () => setSuccessMessage(t('messages.resendShareableLink.success')),
    onError: () => setErrorMessage(t('messages.resendShareableLink.error')),
  });

  const handleResendLink = () => {
    resendShareableLink({
      variables: {
        email,
      },
    });
  };

  return {
    handleResendLink,
    maskedEmail,
  };
};

export default useExpiredSharableLink;
