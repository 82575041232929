import { MaterialityAssessmentTopic } from 'src/modules/common/types';
import {
  EvaluationTableTopics,
  MaterialityAssessmentTopicWithDisabled,
} from '../types/materialEvaluation';

export const sortMaterialTopics = <
  T extends MaterialityAssessmentTopic | MaterialityAssessmentTopicWithDisabled
>(
  array: T[]
): T[] => {
  const copiedArray = array.slice();

  return copiedArray.sort((a, b) => {
    if (a.status < b.status) return -1;
    if (a.status > b.status) return 1;

    if (a.materialTopic.area < b.materialTopic.area) return -1;
    if (a.materialTopic.area > b.materialTopic.area) return 1;

    if (a.materialTopic.title < b.materialTopic.title) return -1;
    if (a.materialTopic.title > b.materialTopic.title) return 1;

    return 0;
  });
};

export const sortMaterialEvaluationTopics = <T extends EvaluationTableTopics>(array: T[]): T[] => {
  const MATERIAL_WEIGHT = 2;
  const NON_MATERIAL_WEIGHT = 1;
  const BLANK_WEIGHT = 0;

  const copiedArray = array.slice();

  copiedArray.sort((a, b) => {
    const materialityA =
      a.materiality &&
      a.materiality.financialMateriality !== null &&
      a.materiality.financialMateriality !== undefined &&
      a.materiality.impactMateriality !== null &&
      a.materiality.impactMateriality !== undefined
        ? a.materiality.financialMateriality || a.materiality.impactMateriality
          ? MATERIAL_WEIGHT
          : NON_MATERIAL_WEIGHT
        : BLANK_WEIGHT;

    const materialityB =
      b.materiality &&
      b.materiality.financialMateriality !== null &&
      b.materiality.financialMateriality !== undefined &&
      b.materiality.impactMateriality !== null &&
      b.materiality.impactMateriality !== undefined
        ? b.materiality.financialMateriality || b.materiality.impactMateriality
          ? MATERIAL_WEIGHT
          : NON_MATERIAL_WEIGHT
        : BLANK_WEIGHT;

    if (materialityA < materialityB) return 1;
    if (materialityA > materialityB) return -1;

    if (a.materialTopic.area < b.materialTopic.area) return -1;
    if (a.materialTopic.area > b.materialTopic.area) return 1;

    if (a.materialTopic.title < b.materialTopic.title) return -1;
    if (a.materialTopic.title > b.materialTopic.title) return 1;

    return 0;
  });

  return copiedArray;
};

export const getActiveTabIndex = (pathname: string) => {
  if (pathname === '/materiality-assessment') return 0;
  else return 1;
};
