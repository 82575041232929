import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, PageTitle } from 'src/modules/common/components/Structure';
import { DataManagement as DataManagementContainer } from 'src/modules/data-management/components';

const DataManagement = () => {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <PageTitle title={t('modules.dataManagement.pageTitle')} />
      <DataManagementContainer />
    </PageContainer>
  );
};

export default DataManagement;
