import React, { useState } from 'react';
import { Box } from '@mui/material';

import { Loader, YearSelect } from 'src/modules/common/components';
import CollectDataTableFilter from '../CollectDataTableFilter';
import { DataOverviewComponent } from '../DataOverview/DataOverview';
import CollectEsgNonAdminTable from '../CollectEsgNonAdminTable/CollectEsgNonAdminTable';
import useCollectFilter from '../../application/use-collect-filter';
import useCollectOverview from '../../application/use-collect-overview';
import { SortingOrder } from 'src/modules/common/types';
import { FilterType } from 'src/modules/common/types/collect';

const PublicCollectOverview = ({
  years,
  token,
  accessToken,
  email,
}: {
  years: number[];
  token: string;
  accessToken: string;
  email: string;
}) => {
  const [selectedYear, setSelectedYear] = useState<number>(Math.max(...years));

  const {
    collectOverviews,
    collectOverviewLoading,
    collectOverviewStats,
    collectOverviewCategories,
    collectOverviewFacilities,
  } = useCollectOverview(selectedYear, accessToken);
  const {
    data: collectFilter,
    handleCollectFilterChange,
    filteredData,
    rowsPerPageCount,
    loading: filterDataLoading,
  } = useCollectFilter(collectOverviews, selectedYear, accessToken);

  if (collectOverviewLoading || !selectedYear || !collectOverviews || filterDataLoading)
    return <Loader />;

  const handleFilterChange = async (newValue: string[], filterType: FilterType) => {
    await handleCollectFilterChange({ newValue, filterType });
  };

  const handleRowsPerPageCountChange = async (count: number) => {
    await handleCollectFilterChange({ count });
  };

  const handleSortingChange = async (field: string, direction: SortingOrder) => {
    await handleCollectFilterChange({ field, direction });
  };

  return (
    <>
      <DataOverviewComponent data={collectOverviewStats} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ flexShrink: '1' }}>
          <YearSelect year={selectedYear} years={years} setYear={setSelectedYear} />
        </Box>
        <CollectDataTableFilter
          collectFilter={collectFilter}
          facilities={collectOverviewFacilities}
          categories={collectOverviewCategories}
          handleFilterChange={handleFilterChange}
        />
      </Box>
      <CollectEsgNonAdminTable
        data={filteredData}
        collectFilter={collectFilter}
        isExternal={true}
        token={token}
        email={email}
        rowsPerPageCount={rowsPerPageCount}
        handleOnRowCountChange={handleRowsPerPageCountChange}
        handleSortingChange={handleSortingChange}
      />
    </>
  );
};

export default PublicCollectOverview;
