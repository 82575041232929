import React, { Ref } from 'react';
import { Box } from '@mui/material';

import theme from 'src/styles/theme';

import ChatMessageContainer from '../ChatMessageContainer/ChatMessageContainer';
import ChatbotMessageInput from '../ChatbotMessageInput/ChatbotMessageInput';
import ChatbotHeader from '../ChatbotHeader';
import { ChatMessage } from '../../types';

interface FloatingChatbotProps {
  handlePinClick: () => void;
  handleOnClose: () => void;
  messages: ChatMessage[];
  loading: boolean;
  handleSendMessage: (inputMessage: string) => Promise<void>;
  contentRef: Ref<HTMLDivElement> | null;
}

const FloatingChatbot = (props: FloatingChatbotProps) => {
  const { handlePinClick, handleOnClose, messages, loading, handleSendMessage, contentRef } = props;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '20px',
        right: '100px',
        background: '#fff',
        height: '460px',
        borderRadius: '8px',
        zIndex: 1000,
        width: '360px',
        boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16)',
        overflowY: 'hidden',
      }}
    >
      <ChatbotHeader isFloating handlePinClick={handlePinClick} handleOnClose={handleOnClose} />
      <Box
        sx={{
          overflowY: 'scroll',
          px: 3,
          height: '318px',
        }}
        ref={contentRef}
      >
        <ChatMessageContainer messages={messages} loading={loading} />
      </Box>
      <Box
        sx={{
          padding: 2,
          background: theme.palette.background.default,
        }}
      >
        <ChatbotMessageInput
          sendMessage={(message: string) => {
            handleSendMessage(message);
          }}
        />
      </Box>
    </Box>
  );
};

export default FloatingChatbot;
