import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import { IconSizes } from 'src/modules/common/types';

interface Props {
  title: string;
  size: IconSizes;
  styles?: React.CSSProperties;
}

const InfoTooltip = ({ title, size, styles }: Props) => {
  return (
    <Tooltip title={title} sx={{ ...styles }}>
      <InfoOutlinedIcon fontSize={size} color="disabled" />
    </Tooltip>
  );
};
export default InfoTooltip;
