import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';
import TabPanel from './TabPanel';
import { TabBar as TabBarProps } from './types';

/**
 * Dynamically usable Tab component: https://mui.com/material-ui/react-tabs/
 *
 * How to use:
 * Pass Array of Objects that have the following variables:
 * label: string -> Text displayed in label
 * input: any object -> is displayed when chosing the tab
 * index: number
 * id: string
 * -> index and id are used for differentiating if there are multiple tab components on one page
 */

interface Props {
  tabBarStyles?: React.CSSProperties;
  content: TabBarProps[];
  SideComponent?: React.ElementType;
  activeTabIndex?: number;
  sideComponentStyles?: React.CSSProperties;
  onTabChange?: (index: number) => void;
}

const TabBar = ({
  content,
  tabBarStyles = {},
  SideComponent,
  activeTabIndex = 0,
  onTabChange,
  sideComponentStyles,
}: Props) => {
  const [value, setValue] = useState(activeTabIndex);

  const handleChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
      onTabChange && onTabChange(newValue);
    },
    [onTabChange]
  );

  useEffect(() => {
    setValue(activeTabIndex);
  }, [activeTabIndex]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          position: 'relative',
          display: 'flex',
          flexDirection: {
            xs: 'column-reverse !important',
          },
          ...tabBarStyles,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs-container"
          variant="scrollable"
          allowScrollButtonsMobile
          sx={{ overflowX: 'auto', width: 'fit-content' }}
        >
          {content.map(t =>
            t.to ? (
              <Tab
                sx={{ textTransform: 'none' }}
                key={t.index}
                label={t.label}
                component={Link}
                to={t.to}
              />
            ) : (
              <Tab sx={{ textTransform: 'none' }} key={t.index} label={t.label} />
            )
          )}
        </Tabs>

        {SideComponent && (
          <Box
            sx={{
              position: {
                xs: 'relative',
                sm: 'absolute',
              },
              right: '0',
              top: '8px',
              zIndex: 1,
              ...sideComponentStyles,
            }}
          >
            <SideComponent />
          </Box>
        )}
      </Box>
      <>
        {content.map(t => {
          return (
            <TabPanel key={t.index} value={value} index={t.index}>
              {t.input}
            </TabPanel>
          );
        })}
      </>
    </Box>
  );
};
export default TabBar;
