import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import { Navigate } from 'react-router-dom';

import { LOGIN_VIA_TOKEN } from 'src/services/graphql/mutations';
import { useEnhancedMutation, useUserData } from 'src/modules/common/application';
import { ResendVerification, SendAcceptTermsAndConditions } from 'src/modules/auth/components';
import { AuthLayout, CardLayout, Loader } from 'src/modules/common/components';
import useTermsAndConditions from 'src/modules/common/application/use-terms-conditions';
import FirebaseContext from 'src/modules/common/application/context/FirebaseContext';

const TokenizedLogin = () => {
  const { t } = useTranslation();
  const { user } = useUserData();
  const { authenticated } = useContext(FirebaseContext);

  const [loginCalled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loginError, setLoginError] = useState<boolean>(false);
  const [token] = useQueryParam('token', StringParam);
  let [email] = useQueryParam('email', StringParam);
  if (email) email = decodeURIComponent(email);

  const {
    acceptTermsAndConditions,
    loading: acceptTermsLoading,
    error: termsAndConditionsError,
  } = useTermsAndConditions();

  const [login_via_token] = useEnhancedMutation(LOGIN_VIA_TOKEN, {
    onError: async error => {
      setLoginError(true);
    },
    onCompleted: () => {
      setLoginError(false);
    },
    fetchPolicy: 'no-cache',
  });

  async function login() {
    setLoading(true);
    setLoginError(false);
    await login_via_token({ variables: { token } });
    await acceptTermsAndConditions();
    setLoading(false);
  }

  useEffect(() => {
    if (user && authenticated) {
      setLoading(false);
    } else if (token) {
      if (!loginCalled) login();
    } else setLoginError(true);
    // eslint-disable-next-line
  }, [token, loginCalled]);

  if (loading || acceptTermsLoading) return <Loader />;

  if (loginError) {
    return (
      <AuthLayout title={t('shared.authPages.register')} showDashboard={true}>
        <CardLayout height="fit-content">
          <ResendVerification
            title={t('modules.tokenizedLogin.title')}
            subtitle={t('modules.tokenizedLogin.subtitle')}
            buttonText={t('modules.tokenizedLogin.resend')}
            email={email as string}
          />
        </CardLayout>
      </AuthLayout>
    );
  } else if (termsAndConditionsError) {
    return (
      <AuthLayout title={t('shared.authPages.register')} showDashboard={true}>
        <CardLayout height="fit-content">
          <SendAcceptTermsAndConditions />
        </CardLayout>
      </AuthLayout>
    );
  } else {
    return <Navigate to="/" />;
  }
};

export default TokenizedLogin;
