import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { TextField } from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { getPasswordForgotSchema } from 'src/modules/common/application/auth.schema';
import Button from 'src/modules/common/components/Button/Button';

interface Props {
  success: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRouteChange: any;
}

// @ts-ignore eslint-disable-next-line
const PasswordForgotForm = ({ success, onSubmit, onRouteChange }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const passwordForgotSchema = getPasswordForgotSchema(t);
  return (
    <Grid container spacing={3}>
      <Grid item>
        {success ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                {t('modules.passwordForgot.passwordForgotForm.title')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                  paddingBottom: theme.spacing(2),
                  textAlign: 'center',
                }}
              >
                {t('modules.passwordForgot.passwordForgotForm.emailSent')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Link
                onClick={onRouteChange}
                color="primary"
                sx={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
              >
                <Typography variant="body2">
                  {t('modules.passwordForgot.passwordForgotForm.backToLogin')}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        ) : (
          <Formik
            initialValues={passwordForgotSchema.getDefault()}
            validateOnBlur={false}
            validationSchema={passwordForgotSchema}
            onSubmit={values => onSubmit(values)}
          >
            {({ isSubmitting }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4" sx={{ textAlign: 'center' }}>
                      {t('modules.passwordForgot.passwordForgotForm.title')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        paddingBottom: theme.spacing(2),
                        textAlign: 'center',
                      }}
                    >
                      {t('modules.passwordForgot.passwordForgotForm.subtitle')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ paddingBottom: theme.spacing(1) }}>
                    <Field
                      fullWidth
                      component={TextField}
                      type="text"
                      label={t('shared.types.user.email')}
                      name="email"
                      variant="outlined"
                      size="medium"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      loading={isSubmitting}
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      {t('modules.passwordForgot.passwordForgotForm.sendEmail')}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', mt: 3 }}>
                  <Typography variant="body2" sx={{ mr: 1 }}>
                    {t('modules.passwordForgot.passwordForgotForm.backToLogin')}
                  </Typography>
                  <Link
                    component={RouterLink}
                    to="/login"
                    color="primary"
                    sx={{
                      textDecoration: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    <Typography variant="body2">
                      {t('modules.passwordForgot.passwordForgotForm.link')}
                    </Typography>
                  </Link>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </Grid>
    </Grid>
  );
};

export default PasswordForgotForm;
