import * as React from 'react';
import { IconProps } from '../types';

const SvgMavueIcon = (props: IconProps) => (
  <svg
    width={props.width ? props.width : 35}
    height={props.height ? props.height : 35}
    fill="none"
    role="img"
    viewBox="0 0 35 35"
    {...props}
  >
    <path
      d="M2.6 30V10h3v3.04c.453-.8 1.173-1.56 2.16-2.28.987-.72 2.293-1.08 3.92-1.08 1.333 0 2.56.36 3.68 1.08 1.147.693 2.04 1.68 2.68 2.96.267-.453.68-1 1.24-1.64.587-.64 1.347-1.2 2.28-1.68.933-.48 2.053-.72 3.36-.72 1.28 0 2.48.333 3.6 1s2.027 1.627 2.72 2.88c.72 1.227 1.08 2.68 1.08 4.36V30h-3V18c0-1.68-.48-3.027-1.44-4.04-.933-1.04-2.107-1.56-3.52-1.56-1.467 0-2.733.493-3.8 1.48-1.067.987-1.6 2.373-1.6 4.16V30h-3V18c0-1.68-.48-3.027-1.44-4.04-.933-1.04-2.107-1.56-3.52-1.56-1.467 0-2.733.493-3.8 1.48-1.067.987-1.6 2.373-1.6 4.16V30h-3Z"
      fill="#101827"
    />
  </svg>
);
export default SvgMavueIcon;
