import { gql } from '@apollo/client';

// ToDo: Include department once BE is finish
export const USER_FRAGMENT = gql`
  fragment userValues on User {
    id
    firstName
    lastName
    email
    role
    position
    department
    status
    language
    numberFormat
    fullName @client
    isAdmin @client
  }
`;

export const USER_FOR_SUPER_FRAGMENT = gql`
  fragment userValues on UserForSuper {
    id
    firstName
    lastName
    email
    role
    position
    department
    status
    createdTimestamp
    firebaseId
    language
    numberFormat
    fullName @client
    isAdmin @client
  }
`;

export const USER_SETTING_FRAGMENT = gql`
  fragment userSettingValues on User {
    id
    email
    firstName
    lastName
    position
    department
    language
    numberFormat
  }
`;
