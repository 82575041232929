import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import theme from 'src/styles/theme';

interface IRoadmapStepper {
  isFirst: boolean;
  isLast: boolean;
  stepCount: number;
  isActive: boolean;
  isCompleted: boolean;
  isNextCompleted: boolean;
}

const RoadmapStepper = (props: IRoadmapStepper) => {
  const { isFirst, isLast, stepCount, isActive, isCompleted, isNextCompleted } = props;

  const leftStepperColor = useMemo(() => {
    if (!isActive && !isCompleted) {
      return theme.palette.colors.lightSmokeGrey;
    } else if (isCompleted) {
      return theme.palette.colors.lightSuccessGreen;
    }
    return theme.palette.colors.lightMainBlue;
  }, [isActive, isCompleted]);

  const rightStepperColor = useMemo(() => {
    if (isActive && !isLast) {
      return theme.palette.colors.lightSmokeGrey;
    } else if (isActive && isLast) {
      return theme.palette.colors.lightMainBlue;
    } else if (isCompleted && !isNextCompleted) {
      return theme.palette.colors.lightMainBlue;
    } else if (isCompleted && isNextCompleted) {
      return theme.palette.colors.lightSuccessGreen;
    } else if (!isActive && !isCompleted) {
      return theme.palette.colors.lightSmokeGrey;
    }
    return theme.palette.colors.lightMainBlue;
  }, [isActive, isCompleted, isLast, isNextCompleted]);

  const stepLabelColor = useMemo(() => {
    if (isActive) {
      return theme.palette.primary.main;
    } else if (isCompleted) {
      return theme.palette.colors.successGreen;
    }
    return theme.palette.background.grey;
  }, [isActive, isCompleted]);

  const stepLabelTextColor = useMemo(() => {
    if (isActive || isCompleted) {
      return theme.palette.colors.white;
    }
    return theme.palette.colors.lightGrey;
  }, [isActive, isCompleted]);

  return (
    <Box
      sx={{
        height: '10px',
        display: 'flex',
        background: theme.palette.colors.lightSmokeGrey,
        mt: 3,
        mb: 4,
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopLeftRadius: isFirst ? '4px' : 0,
        borderBottomLeftRadius: isFirst ? '4px' : 0,
      }}
    >
      <Box
        sx={{
          height: '10px',
          width: '50%',
          background: leftStepperColor,
          position: 'absolute',
          left: 0,
          borderTopLeftRadius: isFirst ? '4px' : 0,
          borderBottomLeftRadius: isFirst ? '4px' : 0,
        }}
      />
      <Box
        sx={{
          height: '10px',
          width: '50%',
          background: rightStepperColor,
          position: 'absolute',
          right: 0,
          borderTopLeftRadius: isFirst ? '4px' : 0,
          borderBottomLeftRadius: isFirst ? '4px' : 0,
        }}
      />
      <Box
        sx={{
          height: '40px',
          width: '40px',
          borderRadius: '50%',
          background: stepLabelColor,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 1,
          top: 0,
          bottom: 0,
          zIndex: 1,
        }}
      >
        <Typography fontSize={'20px !important'} fontWeight={600} color={stepLabelTextColor}>
          {stepCount}
        </Typography>
      </Box>
      {isLast && (
        <Box
          sx={{
            height: '40px',
            width: '40px',
            borderRadius: '50%',
            background: stepLabelColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 1,
            top: -16,
            bottom: 0,
            zIndex: 1,
            position: 'absolute',
            right: -24,
          }}
        >
          <CheckRoundedIcon
            style={{
              height: '44px',
              width: '44px',
              padding: '4px',
              fill: stepLabelTextColor,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default RoadmapStepper;
