import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from '@mui/material';
import { LanguageSelect, NumberFormatSelect } from 'src/modules/common/components';
import { useTheme } from '@mui/material/styles';

const PublicFooter = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Divider sx={{ mb: 1 }} />
      <Box
        sx={{
          ml: 5,
          display: 'flex',
          flexDirection: 'row',
          mb: 2,
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Box sx={{ ml: 5, display: 'flex', flexDirection: 'row', width: 250 }}>
          <Typography sx={{ mt: 0.5, mr: 2 }}>{`${t('shared.standards.language')}:`}</Typography>
          <LanguageSelect />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography sx={{ mt: 0.5, mr: 2 }}>{`${t(
            'shared.standards.numberFormat'
          )}:`}</Typography>
          <NumberFormatSelect />
        </Box>
      </Box>
    </Box>
  );
};

export default PublicFooter;
