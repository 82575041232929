import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody } from '@mui/material';

import { EditedUser, UserRole, UserAccountStatus } from 'src/modules/common/types';
import { getManageTeamsHeaders } from 'src/modules/common/types/constants/table-header';
import TableHeader from 'src/modules/common/components/Table/TableHeader';
import { genericSortTwoColumns } from 'src/modules/common/application/sort';
import { useUserData } from 'src/modules/common/application';
import theme from 'src/styles/theme';

import AdminSettingsTableRow from '../AdminSettingsTableRow/AdminSettingsTableRow';
import ManageTeamTableRowData from '../ManageTeamTableRowData/ManageTeamTableRowData';
import { ManageUser } from '../ManageTeam/types';

interface Props {
  data: ManageUser[];
  onResendInvitationClick: (user: ManageUser) => void;
  onChangePermissionClick: (user: ManageUser) => void;
  onDeleteUserClick: (user: ManageUser) => void;
}

const ManageTeamTable = ({
  data,
  onResendInvitationClick,
  onChangePermissionClick,
  onDeleteUserClick,
}: Props) => {
  const { t } = useTranslation();
  const { isAdmin } = useUserData();

  const manageTeamsHeaders = getManageTeamsHeaders.map(t) as string[];

  const newData: EditedUser[] = JSON.parse(JSON.stringify(data));
  newData.forEach(item => {
    item.fullName = `${item.lastName} ${item.firstName}`;
  });
  genericSortTwoColumns(newData, 'role', 'fullName');

  const getOptionsArray = (user: EditedUser) => {
    const options = [];
    if (user.status === UserAccountStatus.PENDING_INVITES) {
      options.push({
        action: (user: ManageUser) => onResendInvitationClick(user),
        text: t('modules.settings.manageTeam.resendInvitation'),
      });
    }
    if (user.role === UserRole.EMPLOYEE) {
      options.push(
        {
          action: (user: ManageUser) => onChangePermissionClick(user),
          text: t('modules.settings.manageTeam.changePermissions'),
        },
        {
          action: (user: ManageUser) => onDeleteUserClick(user),
          text: t('modules.settings.manageTeam.deleteUser'),
        }
      );
    }
    if (user.role === UserRole.EXTERNAL) {
      options.push({
        action: (user: ManageUser) => onDeleteUserClick(user),
        text: t('modules.settings.manageTeam.deleteUser'),
      });
    }
    return options;
  };

  return (
    <Table sx={{ tableLayout: 'auto', width: '100%', backgroundColor: theme.palette.colors.white }}>
      <TableHeader
        headers={manageTeamsHeaders}
        styles={{
          border: 'none',
        }}
        showLastHeader={isAdmin ? true : false}
      />
      <TableBody
        sx={{
          backgroundColor: theme.palette.colors.white,
        }}
      >
        {newData.map(user => (
          <AdminSettingsTableRow
            key={user.id}
            data={user}
            RowComponent={ManageTeamTableRowData}
            showMoreOptions={isAdmin && getOptionsArray(user).length > 0 ? true : false}
            moreOptions={getOptionsArray(user)}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default ManageTeamTable;
