import theme from 'src/styles/theme';

export const textFieldInputStyle = {
  fontSize: '0.875rem !important',
  fontWeight: 500,
  position: 'relative',
  '& .MuiInputBase-root': {
    height: '130px',
    position: 'relative',
    fontWeight: 500,
  },
  '& .MuiInputBase-input': {
    fontSize: '0.875rem !important',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.background.grey,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.background.grey,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.background.grey,
    },
  },
  '& .MuiFormHelperText-root': {
    position: 'absolute',
    right: 4,
    bottom: -70,
    color: theme.palette.colors.shadeLightGrey,
    fontWeight: 500,
    fontSize: '0.875rem !important',
  },
  '& .Mui-focused.MuiFormHelperText-root': {
    color: 'blue',
  },
  mt: 0.5,
};
