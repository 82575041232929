import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Select,
  TableRow,
  Typography,
  SelectChangeEvent,
  MenuItem,
  Tooltip,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { NumericFormat } from 'react-number-format';
import Switch from '@mui/material/Switch';

import {
  ChipColorVariants,
  EsgCategoryEnum,
  Modes,
  NumberFormat,
  TranslatedEsgKpi,
  Unit,
} from 'src/modules/common/types';
import { getCategoryChipColor, getUnitChipColor } from 'src/modules/common/application/ui';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import theme from 'src/styles/theme';
import { Chip } from 'src/modules/common/components/Chip';
import useNumberFormat from 'src/modules/common/application/use-number-format';
import { getIntercomDataAttribute } from 'src/modules/common/application/intercom';
import { IntercomDataAttribute } from 'src/modules/common/types/intercom';

import { StyledTableCell, StyledTextField } from './styles';
import { DeactivateKPIDialoge } from '../DeactivateKPIDialog/DeactivateKPIDialog';

interface Props {
  data: TranslatedEsgKpi;
  onDataChange: ({ id, field, value }: { id: string; field: string; value: string }) => void;
  handleOpenEsgDataInputSidebar: (id: string) => void;
  onActivateDectivateKpi?: (id: string, isDeactivated: boolean) => void;
  mode: Modes;
  index: number;
}

const EsgDataInputTableRow = (props: Props) => {
  const { t } = useTranslation();
  const { numberFormat } = useNumberFormat();
  const [isdeactivateDialogOpen, setDeactivateDialogOpen] = useState<boolean>(false);
  const [isDeactivated, setIsDeactivated] = useState<boolean>(false);

  const { data, onDataChange, handleOpenEsgDataInputSidebar, onActivateDectivateKpi, mode, index } =
    props;
  const { id, category, esgValue, units, translatedKpiTitle, translatedCategoryTitle } = data;

  const InputSelectedUnit = esgValue?.selectedUnit;

  const isMultipleUnits = units!.length > 1;

  const selectedUnit =
    !InputSelectedUnit && units && units.length > 0 ? units[0] : InputSelectedUnit;

  const value = esgValue ? esgValue.value : null;
  const skipIntercomAttribute = index !== 0;

  const handleChange = (field: string, data: string) => {
    onDataChange({
      id: esgValue?.id as string,
      field,
      value: data,
    });
  };

  const textInputProps = {
    type: 'number',
    disableUnderline: true,
    sx: {
      width: '80%',
      borderRadius: '5px',
      border: '1px solid',
      borderColor: theme.palette.colors.softGrey,
    },
    inputProps: {
      sx: {
        backgroundColor: 'colors.white',
        fontSize: '14px',
        textAlign: 'left',
        padding: 1.5,
      },
    },
  };

  const handleInfoIconClick = () => {
    handleOpenEsgDataInputSidebar(id);
  };

  useEffect(() => {
    setIsDeactivated(!esgValue);
  }, [esgValue]);

  return (
    <>
      <TableRow
        sx={{
          fontSize: '14px',
          backgroundColor: 'white',
        }}
      >
        <StyledTableCell
          sx={{
            width: '40%',
          }}
        >
          <Typography
            paddingLeft={2}
            marginRight={1}
            variant="inherit"
            style={{ opacity: isDeactivated ? 0.5 : 1 }}
          >
            {translatedKpiTitle}{' '}
            {
              <Tooltip
                title={t('modules.dataManagement.esgValueInput.tooltip')}
                onClick={handleInfoIconClick}
                data-intercom-target={getIntercomDataAttribute(
                  IntercomDataAttribute.kpiDetailTooltip,
                  skipIntercomAttribute
                )}
              >
                <InfoOutlinedIcon
                  fontSize="inherit"
                  sx={{ color: theme.palette.text.secondary, cursor: 'pointer' }}
                />
              </Tooltip>
            }
          </Typography>
        </StyledTableCell>
        <StyledTableCell sx={{ width: '20%' }}>
          <Chip
            size="small"
            styles={{
              maxWidth: 'fit-content',
              opacity: isDeactivated ? 0.5 : 1,
            }}
            colorVariant={getCategoryChipColor(category.title as EsgCategoryEnum)}
          >
            {translatedCategoryTitle}
          </Chip>
        </StyledTableCell>
        <StyledTableCell
          sx={{
            width: '20%',
            backgroundColor: theme.palette.colors.softGreyTransparent,
          }}
          data-intercom-target={getIntercomDataAttribute(
            IntercomDataAttribute.kpiDetailValueField,
            skipIntercomAttribute
          )}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NumericFormat
              {...textInputProps}
              value={value === null || Number.isNaN(value) || isDeactivated ? '' : value}
              name="value"
              customInput={StyledTextField}
              style={{
                opacity: isDeactivated ? 0.5 : 1,
                cursor: isDeactivated ? 'not-allowed' : 'pointer',
              }}
              type="text"
              allowNegative={false}
              allowedDecimalSeparators={['.', ',']}
              thousandSeparator={numberFormat === NumberFormat.COMMA_PERIOD ? ',' : '.'}
              decimalSeparator={numberFormat === NumberFormat.COMMA_PERIOD ? '.' : ','}
              onValueChange={(values, sourceInfo) => {
                handleChange('value', values.value);
              }}
              disabled={isDeactivated}
            />
          </Box>
        </StyledTableCell>
        <StyledTableCell sx={{ width: mode === Modes.PRIVATE ? '10%' : '20%' }}>
          {isMultipleUnits ? (
            <Select
              labelId="selectedUnit"
              id="selectedUnit"
              value={selectedUnit!}
              displayEmpty
              onChange={(e: SelectChangeEvent) => handleChange('selectedUnit', e.target.value)}
              sx={{
                width: '100%',
                border: '0px',
                '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                  boder: 0,
                },
              }}
              renderValue={selected => {
                if (!selected)
                  return (
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'colors.grey',
                        fontSize: '14px',
                        opacity: isDeactivated ? 0.5 : 1,
                      }}
                    >
                      {t('modules.dataManagement.esgValueInput.selectItem')}
                    </Typography>
                  );
                return (
                  <Box sx={{ display: 'flex' }}>
                    <Chip
                      size="small"
                      colorVariant={getUnitChipColor(selected as Unit)}
                      style={{ opacity: isDeactivated ? 0.5 : 1 }}
                    >
                      {t(`shared.types.unit.${removeUnderscoreToCamelCase(selected)}`)}
                    </Chip>
                  </Box>
                );
              }}
              disabled={isDeactivated}
            >
              {units!.map(u => {
                return (
                  <MenuItem key={u!} value={u!}>
                    {t(`shared.types.unit.${removeUnderscoreToCamelCase(u!)}`)}
                  </MenuItem>
                );
              })}
            </Select>
          ) : (
            <Box paddingLeft={2} maxWidth="fit-content">
              {selectedUnit! ? (
                <Chip
                  size="small"
                  colorVariant={getUnitChipColor(selectedUnit!)}
                  style={{ opacity: isDeactivated ? 0.5 : 1 }}
                >
                  {t(`shared.types.unit.${removeUnderscoreToCamelCase(selectedUnit)}`)}
                </Chip>
              ) : (
                <Chip
                  size="small"
                  colorVariant={ChipColorVariants.LIGHT_YELLOW}
                  style={{ opacity: isDeactivated ? 0.5 : 1 }}
                >
                  #
                </Chip>
              )}
            </Box>
          )}
        </StyledTableCell>
        {mode === Modes.PRIVATE && (
          <StyledTableCell
            sx={{ width: '10%' }}
            data-intercom-target={getIntercomDataAttribute(
              IntercomDataAttribute.kpiDetailActivationSwitch,
              skipIntercomAttribute
            )}
          >
            <Switch
              aria-label="Deactivate Switch"
              onChange={() => {
                if (value && !isDeactivated) {
                  setDeactivateDialogOpen(true);
                  setIsDeactivated(true);
                } else {
                  setIsDeactivated(!isDeactivated);
                  onActivateDectivateKpi && onActivateDectivateKpi(id, !isDeactivated);
                }
              }}
              checked={!isDeactivated}
            />
          </StyledTableCell>
        )}
      </TableRow>
      {mode === Modes.PRIVATE && (
        <DeactivateKPIDialoge
          isOpen={isdeactivateDialogOpen}
          onClose={() => {
            setDeactivateDialogOpen(false);
            setIsDeactivated(false);
          }}
          onDeactivate={() => {
            setIsDeactivated(true);
            setDeactivateDialogOpen(false);
            onActivateDectivateKpi && onActivateDectivateKpi(id, true);
          }}
          kpiName={translatedCategoryTitle}
        />
      )}
    </>
  );
};

export default EsgDataInputTableRow;
