import React from 'react';
import { Grid } from '@mui/material';

import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { useMessages } from 'src/modules/common/application';
import { Loader } from 'src/modules/common/components';
import useMaterialityAssessment from 'src/modules/materialityAssessment/application/use-materiality-assessment';

import { MaterialityTopicsTable } from '../MaterialityTopicsTable/MaterialityTopicsTable';
import MAOverviewStats from '../MAOverviewStats/MAOverviewStats';
import CreateMARequestForm from '../CreateMARequestForm/CreateMARequestForm';

interface MaterialityAssessmentTopicPreSelectionProps {
  year: number;
  isRequestFormOpen: boolean;
  handleOnRequestFormClose: () => void;
}

const MaterialityAssessmentTopicPreSelection = (
  props: MaterialityAssessmentTopicPreSelectionProps
) => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();
  const { year, isRequestFormOpen, handleOnRequestFormClose } = props;

  const {
    materialityAssessmentTopics,
    loading,
    error,
    overviewStatsData,
    filterStackholdersByIds,
  } = useMaterialityAssessment(year);

  if (loading || !materialityAssessmentTopics) return <Loader />;
  if (error) return <>{setErrorMessage(t('messages.generic.error'))}</>;

  return (
    <Box sx={{ marginTop: -3 }}>
      <Grid container spacing={4} sx={{ paddingTop: '0px !important', marginTop: 0 }}>
        <Grid item md={12} xs={12} sx={{ paddingTop: '0px !important' }}>
          <MAOverviewStats data={overviewStatsData} />
        </Grid>
        <Grid item md={12} xs={12} sx={{ paddingTop: '24px !important' }}>
          <MaterialityTopicsTable
            topics={materialityAssessmentTopics}
            filterStackholdersByIds={filterStackholdersByIds}
            year={year}
          />
        </Grid>
      </Grid>
      <CreateMARequestForm
        isOpen={isRequestFormOpen}
        onClose={handleOnRequestFormClose}
        year={year}
        isPreSelection
      />
    </Box>
  );
};

export default MaterialityAssessmentTopicPreSelection;
