import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MaterialTopic,
  MaterialityAssessment,
  MaterialityEvaluationResult,
  RequestStatus,
  User,
} from 'src/modules/common/types';

import { useEnhancedMutation, useEnhancedQuery, useMessages } from 'src/modules/common/application';
import { GET_MATERIALITY_ASSESSMENT } from 'src/services/graphql/queries';
import {
  EvaluationTableTopics,
  MaterialityAssessmentTopicWithDisabled,
  StakeholderStatusResult,
} from '../types/materialEvaluation';
import { UPDATE_MATERIALITY_EVALUATION } from 'src/services/graphql/mutations/material';

const useMaterialityAssessment = (year: number) => {
  const { t } = useTranslation();
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { loading, data, error } = useEnhancedQuery<{
    materialityAssessment: MaterialityAssessment;
  }>(GET_MATERIALITY_ASSESSMENT, {
    variables: {
      year,
    },
  });

  const [updateMaterialityEvaluation, { loading: updateMaterialityEvaluationLoading }] =
    useEnhancedMutation<{
      updateMaterialityEvaluation: MaterialityEvaluationResult;
    }>(UPDATE_MATERIALITY_EVALUATION, {
      onError: () => {
        setErrorMessage(t('messages.updateMaterialityEvaluation.error'));
      },
      onCompleted: () => {
        setSuccessMessage(t('messages.updateMaterialityEvaluation.success'));
      },
      update: (cache, { data }, { variables }) => {
        if (!data || !variables) return;
        const { materialityAssessment } = cache.readQuery<{
          materialityAssessment: MaterialityAssessment;
        }>({
          query: GET_MATERIALITY_ASSESSMENT,
          variables: {
            year,
          },
        }) || { materialityAssessment: null };

        if (!materialityAssessment) return;

        const { topics } = materialityAssessment;
        const { updateMaterialityEvaluation } = data;
        const { materialityAssessmentTopicId } = variables.input;

        const updatedTopics = topics.map(topic => {
          if (topic.id === materialityAssessmentTopicId) {
            return {
              ...topic,
              evaluation: updateMaterialityEvaluation,
            };
          }
          return topic;
        });

        cache.writeQuery({
          query: GET_MATERIALITY_ASSESSMENT,
          data: {
            materialityAssessment: {
              ...materialityAssessment,
              topics: updatedTopics,
            },
          },
        });
      },
    });

  const materialityAssessment = data?.materialityAssessment;

  const activeMaterialTopics = materialityAssessment?.topics.filter(
    topic => topic.status === 'ACTIVE'
  );

  const evaluationTableTopics: EvaluationTableTopics[] =
    materialityAssessment?.topics
      .filter(topic => topic.status === 'ACTIVE')
      ?.map(topic => {
        return {
          ...topic,
          totalRequests:
            materialityAssessment?.evaluationRequests?.filter(
              request =>
                request.response?.some(response => response.topicId === topic.materialTopic.id) ??
                false
            ).length ?? 0,
          completedRequests:
            materialityAssessment?.evaluationRequests?.filter(
              request =>
                request.response?.some(
                  response =>
                    response.topicId === topic.materialTopic.id &&
                    response.result?.financialDescription &&
                    response.result?.financialProbability &&
                    response.result?.financialSignificance &&
                    response.result?.impactDescription &&
                    response.result?.impactIrreversibility &&
                    response.result?.impactScale &&
                    response.result?.impactScope
                ) ?? false
            ).length ?? 0,
        };
      }) ?? [];

  const materialityAssessmentTopics: MaterialityAssessmentTopicWithDisabled[] =
    materialityAssessment?.topics?.map(topic => {
      const hasEvaluationResponse =
        materialityAssessment.evaluationRequests?.some(request =>
          request.response?.some(response => response.topicId === topic.materialTopic.id)
        ) ?? false;

      const hasMaterialityValue =
        topic.materiality?.financialMateriality || topic.materiality?.impactMateriality;

      return {
        ...topic,
        disabled: hasMaterialityValue || hasEvaluationResponse,
      };
    }) ?? [];

  const getMaterialTopicByMaterialityAssessmentTopicId = (
    materialityAssessmentTopicId: string
  ): MaterialTopic | null => {
    const result = materialityAssessment?.topics.find(
      topic => topic.id === materialityAssessmentTopicId
    )?.materialTopic;
    return result !== undefined ? result : null;
  };

  const getEvaluationByMaterialityAssessmentTopicId = (
    materialityAssessmentTopicId: string
  ): MaterialityEvaluationResult | null => {
    const result = materialityAssessment?.topics.find(
      materialityAssessmentTopic => materialityAssessmentTopic.id === materialityAssessmentTopicId
    )?.evaluation;
    return result !== undefined ? result : null;
  };

  const getEvaluationResponsesByMaterialityAssessmentTopicId = (
    materialityAssessmentTopicId: string
  ): StakeholderStatusResult[] => {
    const topicId = materialityAssessment?.topics.find(
      materialityAssessmentTopic => materialityAssessmentTopic.id === materialityAssessmentTopicId
    )?.materialTopic?.id;
    const stakeholderResults: StakeholderStatusResult[] = [];
    materialityAssessment?.evaluationRequests?.forEach(request => {
      const { stakeholder, response, status } = request;
      response?.forEach(response => {
        if (response.topicId === topicId) {
          const { result } = response;
          stakeholderResults.push({
            stakeholder,
            response: result ? result : null,
            status: status,
          });
        }
      });
    });
    return stakeholderResults;
  };

  const evaluationOverviewStats = materialityAssessment?.evaluationRequests?.reduce<
    Record<RequestStatus, number>
  >(
    (acc, request) => {
      const { status } = request;
      if (status) {
        acc[status] = (acc[status] || 0) + 1;
      }
      return acc;
    },
    {
      [RequestStatus.OVERDUE]: 0,
      [RequestStatus.PENDING]: 0,
      [RequestStatus.FINISHED]: 0,
    } as Record<RequestStatus, number>
  );

  const getTopicStats = () => {
    const totalTopics = activeMaterialTopics?.length ?? 0;

    const completedTopics =
      materialityAssessment?.topics.reduce<number>((acc, topic) => {
        if (
          topic.evaluation?.financialDescription &&
          topic.evaluation?.financialProbability &&
          topic.evaluation?.financialSignificance &&
          topic.evaluation?.impactDescription &&
          topic.evaluation?.impactIrreversibility &&
          topic.evaluation?.impactScale &&
          topic.evaluation?.impactScope
        ) {
          acc++;
        }
        return acc;
      }, 0) ?? 0;

    return {
      totalTopics,
      completedTopics,
    };
  };

  const getStackholdersByStatus = useCallback(
    (status: RequestStatus) => {
      return materialityAssessment
        ? materialityAssessment.identificationRequests.reduce<User[]>((filtered, request) => {
            if (request.status === status) {
              filtered.push(request.stakeholder);
            }
            return filtered;
          }, [])
        : [];
    },
    [materialityAssessment]
  );

  const filterStackholdersByIds = (ids: string[]) => {
    if (ids && ids.length === 0) return [];
    return materialityAssessment
      ? materialityAssessment.identificationRequests.reduce<User[]>((filtered, request) => {
          if (ids.includes(request.stakeholder.id)) {
            filtered.push(request.stakeholder);
          }
          return filtered;
        }, [])
      : [];
  };

  const overviewStatsData = useMemo(() => {
    return [
      {
        title: t('modules.materialityAssessment.materialityStats.finished'),
        type: RequestStatus.FINISHED,
        stackeholders: getStackholdersByStatus(RequestStatus.FINISHED),
      },
      {
        title: t('modules.materialityAssessment.materialityStats.pending'),
        type: RequestStatus.PENDING,
        stackeholders: getStackholdersByStatus(RequestStatus.PENDING),
      },
      {
        title: t('modules.materialityAssessment.materialityStats.overdue'),
        type: RequestStatus.OVERDUE,
        stackeholders: getStackholdersByStatus(RequestStatus.OVERDUE),
      },
    ];
  }, [getStackholdersByStatus, t]);

  const getEvaluationRequestTopicIdsByStakeholderEmail = (email: string) => {
    const materialTopicIds: string[] = [];
    data?.materialityAssessment.evaluationRequests
      ?.filter(request => request.stakeholder.email === email)
      .forEach(
        request =>
          request.response?.forEach(response => materialTopicIds.push(response.topicId)) || null
      );

    const materialityAssessmentTopicIds: string[] = [];
    materialTopicIds.forEach(materialTopicId => {
      const materialityAssessmentTopic = data?.materialityAssessment.topics.find(
        topic => topic.materialTopic.id === materialTopicId
      );
      if (materialityAssessmentTopic) {
        materialityAssessmentTopicIds.push(materialityAssessmentTopic.id);
      }
    });

    return materialityAssessmentTopicIds;
  };

  return {
    loading,
    materialityAssessment,
    materialityAssessmentTopics,
    activeMaterialTopics,
    evaluationTableTopics,
    evaluationOverviewStats,
    overviewStatsData,
    topicStats: getTopicStats(),
    error,
    filterStackholdersByIds,
    getMaterialTopicByMaterialityAssessmentTopicId,
    getEvaluationRequestTopicIdsByStakeholderEmail,
    getEvaluationByMaterialityAssessmentTopicId,
    getEvaluationResponsesByMaterialityAssessmentTopicId,
    updateMaterialityEvaluation,
    getTopicStats,
    updateMaterialityEvaluationLoading,
  };
};

export default useMaterialityAssessment;
