import { CollectReminder, ReminderStatus } from 'src/modules/common/types';

export const getTotalDone = (data: CollectReminder[]) => {
  let totalDone = 0;
  let lastReminderId = -1; // later to be replaced by the  id
  let nextReminderId = -1; // later to be replaced by the  id

  data.forEach((item, key) => {
    if (item.status === ReminderStatus.EXECUTED) {
      totalDone++;
    } else {
      if (lastReminderId < 0) {
        nextReminderId = key;
        lastReminderId = key - 1;
      }
    }
  });

  return { totalDone, lastReminderId, nextReminderId };
};
