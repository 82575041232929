import React from 'react';
import { Card, Box, LinearProgress, Typography } from '@mui/material';
import { Chip } from 'src/modules/common/components/Chip';
import { getProgressBarColor } from 'src/modules/common/application/ui';
import { getChipColor } from './utils';

interface Props {
  title: string;
  value: number;
  unit: string;
  progress?: number;
  development?: number;
  cardStyles?: React.CSSProperties;
}

const StatCard = ({ title, value, unit, progress, development, cardStyles = {} }: Props) => {
  const inProgress = progress !== undefined;
  const inDevelopment = development !== undefined;
  return (
    <Card
      sx={{ p: 3, border: '1px solid', borderColor: 'lightGrey', boxShadow: 'none', ...cardStyles }}
    >
      <Typography
        color="text.secondary"
        variant="body2"
        fontSize="1rem !important"
        component={'span'}
      >
        {title}
      </Typography>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <Typography sx={{ mr: 1 }} variant="h5" component={'span'}>
          {`${value} ${unit}`}
        </Typography>
        {(inProgress || inDevelopment) && (
          <Box sx={{ flexGrow: 1 }}>
            {inProgress && (
              <LinearProgress
                data-testid="linear-progress"
                color={getProgressBarColor(progress!)}
                value={progress}
                variant="determinate"
              />
            )}
            {inDevelopment && (
              <Chip
                size="small"
                styles={{ maxWidth: 'fit-content' }}
                colorVariant={getChipColor(development)}
              >{`${development} %`}</Chip>
            )}
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default StatCard;
