import React from 'react';
import { useTheme, Theme } from '@mui/material/styles';
import { Drawer } from './styles';

interface Props {
  open?: boolean;
  theme?: Theme;
  children: React.ReactNode;
}

const DrawerMenu = ({ open, children }: Props) => {
  const theme = useTheme();
  return (
    <Drawer
      variant="permanent"
      open={open}
      theme={theme}
      data-cy={open ? 'menu-is-open' : 'menu-is-closed'}
    >
      {children}
    </Drawer>
  );
};

export default DrawerMenu;
