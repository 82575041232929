import React from 'react';
import { Box, Typography } from '@mui/material';

import { AppIcon } from 'src/modules/common/components/icons';
import { EnhancedChatMessage } from '../../types';
import useTypingAnimation from '../../application/use-typing-animation';

interface AssistantMessageProps {
  message: EnhancedChatMessage;
}
const AssistantMessage = (props: AssistantMessageProps) => {
  const { message } = props;

  const { displayText } = useTypingAnimation(message);

  return (
    <React.Fragment>
      <Box
        sx={{
          width: '30px',
          height: '30px',
          borderWidth: 1,
          borderColor: 'red',
          borderRadius: '15px',
          backgroundColor: '#F8F9FB',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <AppIcon
          style={{
            width: '25px',
            height: '25px',
            transform: 'scale(0.5)',
          }}
        />
      </Box>
      <Box
        sx={{
          maxWidth: '80%',
          background: '#F8F9FB',
          py: '8px',
          px: '12px',
          color: '#000',
          borderRadius: '10px',
          ml: 1,
          mb: '15px',
        }}
      >
        <Typography variant="subtitle2" fontWeight={500}>
          {displayText}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default AssistantMessage;
