import * as Yup from 'yup';
import { TFunction } from 'react-i18next';

export const userSchema = (t: TFunction) => {
  return Yup.object().shape({
    email: Yup.string()
      .default('')
      .required(t('messages.warning.shared.required'))
      .min(1, t('messages.warning.shared.required')),
    firstName: Yup.string().default('').min(1, t('messages.warning.shared.required')),
    lastName: Yup.string().default('').min(1, t('messages.warning.shared.required')),
    role: Yup.string().default('').required(t('messages.warning.shared.required')),
    status: Yup.string().default(''),
    position: Yup.string().default(''),
    department: Yup.string().default(''),
  });
};

export const userForSuperSchema = (t: TFunction) => {
  return Yup.object().shape({
    email: Yup.string()
      .default('')
      .required(t('messages.warning.shared.required'))
      .min(1, t('messages.warning.shared.required')),
    firstName: Yup.string()
      .default(undefined)
      .notRequired()
      .min(1, t('messages.warning.shared.required')),
    lastName: Yup.string()
      .default(undefined)
      .notRequired()
      .min(1, t('messages.warning.shared.required')),
    role: Yup.string().default('').required(t('messages.warning.shared.required')),
    status: Yup.string().default(''),
    position: Yup.string().default(undefined).notRequired(),
    department: Yup.string().default(undefined).notRequired(),
  });
};
