import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { NumberFormat } from 'src/modules/common/types';
import useNumberFormat from 'src/modules/common/application/use-number-format';

const NumberFormatSelect = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { numberFormat, updateNumberFormat } = useNumberFormat();

  const handleNumberFormatChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
    const newFormat = (event.target as HTMLInputElement).value as NumberFormat;
    updateNumberFormat(newFormat);
  };

  return (
    <Box sx={{ width: 500, display: 'flex', justifyContent: 'flex-start' }}>
      <Select
        size="small"
        variant="standard"
        disableUnderline
        style={{ backgroundColor: 'inherit' }}
        value={numberFormat ? numberFormat : NumberFormat.COMMA_PERIOD}
        sx={{ width: 100, textAlign: 'left' }}
        onChange={handleNumberFormatChange}
      >
        <MenuItem value={NumberFormat.COMMA_PERIOD} disableRipple>
          <Typography sx={{ color: theme.palette.colors.lightGrey }}>
            {t('modules.settings.general.numberFormatCommaPeriod.text')}
          </Typography>
        </MenuItem>
        <MenuItem value={NumberFormat.PERIOD_COMMA}>
          <Typography sx={{ color: theme.palette.colors.lightGrey }}>
            {t('modules.settings.general.numberFormatPeriodComma.text')}
          </Typography>
        </MenuItem>
      </Select>
    </Box>
  );
};

export default NumberFormatSelect;
