import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Link, Typography } from '@mui/material';

const TermsAndConditions = () => {
  const { t } = useTranslation();
  return (
    <Grid
      item
      xs={12}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-end',
      }}
    >
      <Link
        href="https://www.mavue.io/privacy"
        color="#000000"
        variant="body2"
        underline="always"
        sx={{
          textDecorationColor: '#000000',
          cursor: 'pointer',
        }}
        target="_blank"
      >
        {t('shared.authPages.privacyPolicy')}
      </Link>
      <Typography sx={{ ml: 1, mr: 1 }}>•</Typography>
      <Link
        href="https://www.mavue.io/terms"
        color="#000000"
        variant="body2"
        underline="always"
        sx={{
          textDecorationColor: '#000000',
          cursor: 'pointer',
        }}
        target="_blank"
      >
        {t('shared.authPages.terms')}
      </Link>
    </Grid>
  );
};

export default TermsAndConditions;
