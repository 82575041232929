import React, { memo } from 'react';
import { Card, CardContent, Typography } from '@mui/material';

import theme from 'src/styles/theme';
import { areArraysEqual } from 'src/modules/common/application/array';

interface IRequirementsCardProps {
  requirements: string[];
}

const RequirementCard = ({ requirements }: IRequirementsCardProps) => {
  return (
    <Card
      sx={{
        height: '25vh',
        border: theme.custom.border,
        boxShadow: 'none',
        mt: -2,
        overflowY: 'auto',
      }}
    >
      <CardContent sx={{ p: '0' }}>
        <ul>
          {requirements.map((item, index) => (
            <div key={index}>
              <li>
                <Typography variant="subtitle2" color={theme.palette.colors.lightGrey}>
                  {item}
                </Typography>
              </li>
            </div>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

export default memo(RequirementCard, (prevProps, nextProps) => {
  return areArraysEqual(prevProps.requirements, nextProps.requirements);
});
