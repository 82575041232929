import React from 'react';
import { PageContainer, PageTitle } from 'src/modules/common/components/Structure';
import { useTranslation } from 'react-i18next';
import MaterialityAssessmentContainer from 'src/modules/materialityAssessment/components/MaterialityAssessment/MaterialityAssessment';

const MaterialityAssessment = () => {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <PageTitle
        title={t('modules.materialityAssessment.pageTitle')}
        tooltipText={t('modules.materialityAssessment.tooltip')}
      />
      <MaterialityAssessmentContainer />
    </PageContainer>
  );
};

export default MaterialityAssessment;
