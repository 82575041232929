/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Box, Grid, Link, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { getRegisterWithEmailSchema } from 'src/modules/common/application/auth.schema';
import Button from 'src/modules/common/components/Button/Button';

interface Props {
  onSubmit: any;
}

const RegisterForm = ({ onSubmit }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const prefilledEmail = searchParams.get('email');

  const registerSchema = getRegisterWithEmailSchema(t);
  const initialValues = registerSchema.getDefault();

  if (prefilledEmail) {
    initialValues.email = prefilledEmail;
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validationSchema={registerSchema}
      onSubmit={values => onSubmit(values)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                {t('modules.register.registrationForm.title')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                  paddingBottom: theme.spacing(2),
                  textAlign: 'center',
                }}
              >
                {t('modules.register.registrationForm.subtitle')}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ paddingBottom: theme.spacing(1) }}>
              <Field
                fullWidth
                component={TextField}
                type="text"
                label={t('shared.types.user.email')}
                name="email"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                loading={isSubmitting}
                size="small"
                variant="contained"
                color="primary"
                type="submit"
              >
                {t('modules.register.registrationForm.button')}
              </Button>
            </Grid>
          </Grid>

          <Box sx={{ mt: 5 }}>
            <Typography variant="body2" color={theme.palette.colors.lightGrey}>
              {t('modules.register.registrationForm.prefix')}
              <Link
                href="https://www.mavue.io/privacy"
                color="primary"
                variant="body2"
                sx={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                target="_blank"
              >
                {t('shared.authPages.privacyPolicy')}
              </Link>
              {t('modules.register.registrationForm.infix')}
              <Link
                href="https://www.mavue.io/terms"
                color="primary"
                variant="body2"
                sx={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
                target="_blank"
              >
                {t('shared.authPages.terms')}
              </Link>
              {t('modules.register.registrationForm.suffix')}
            </Typography>
          </Box>
          <Grid item xs={12} sx={{ display: 'flex', mt: 3 }}>
            <Typography variant="body2" sx={{ mr: 1 }}>
              {t('modules.register.registrationForm.login.description')}
            </Typography>
            <Link
              component={RouterLink}
              to="/login"
              color="primary"
              sx={{
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              <Typography variant="body2">
                {t('modules.register.registrationForm.login.link')}
              </Typography>
            </Link>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterForm;
