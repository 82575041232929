import { gql } from '@apollo/client';
import { COMPANY_FRAGMENT } from '../fragments';

export const CREATE_COMPANY = gql`
  mutation CreateCompany($input: CreateCompanyBySuperInput!) {
    createCompany(input: $input) {
      ...companyValues
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const EDIT_COMPANY = gql`
  mutation EditCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      company {
        ...companyValues
      }
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const EDIT_COMPANY_BY_SUPER = gql`
  mutation EditCompanyBySuper($input: UpdateCompanyBySuperInput!) {
    updateCompanyBySuper(input: $input) {
      ...companyValues
    }
  }
  ${COMPANY_FRAGMENT}
`;
