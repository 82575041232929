/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Link, Typography, useTheme, Box, InputAdornment } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';
import { getLoginSchema } from 'src/modules/common/application/auth.schema';
import Button from 'src/modules/common/components/Button/Button';
import { CheckboxField } from 'src/modules/common/components/Forms';
import { usePasswordToggle } from 'src/modules/common/application';

interface Props {
  onSubmit: any;
  prefilledEmail?: string;
}

const LoginForm = ({ onSubmit, prefilledEmail }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { inputType: PasswordInputType, Icon: ToggleIcon, toggleVisibility } = usePasswordToggle();

  const loginSchema = getLoginSchema(t);
  const initialValues = loginSchema.getDefault();

  if (prefilledEmail) {
    initialValues.email = prefilledEmail;
    initialValues.rememberUser = true;
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validationSchema={loginSchema}
      onSubmit={values => onSubmit(values)}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{ textAlign: 'center' }}>
                {t('modules.login.loginForm.title')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                  paddingBottom: theme.spacing(2),
                  textAlign: 'center',
                }}
              >
                {t('modules.login.loginForm.subtitle')}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ paddingBottom: theme.spacing(1) }}>
              <Field
                fullWidth
                component={TextField}
                type="text"
                label={t('shared.types.user.email')}
                name="email"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                fullWidth
                component={TextField}
                label={t('shared.authPages.minimumCharacters')}
                type={PasswordInputType}
                name="password"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ToggleIcon
                        onClick={() => toggleVisibility()}
                        sx={{ '&:hover': { cursor: 'pointer' } }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={CheckboxField}
                label={t('modules.login.loginForm.actions.rememberMe')}
                name="rememberUser"
                childComponent={
                  <Box
                    component="span"
                    sx={{
                      cursor: 'pointer',
                      fontFamily: 'Inter',
                      fontSize: '1rem',
                      fontWeight: 600,
                      color: 'text.secondary',
                    }}
                  >
                    {t('modules.login.loginForm.actions.rememberMe')}
                  </Box>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                loading={isSubmitting}
                size="small"
                variant="contained"
                color="primary"
                type="submit"
              >
                {t('modules.login.loginForm.actions.signIn')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Link
                component={RouterLink}
                to="/password-forgot"
                color="primary"
                sx={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
              >
                <Typography variant="body2">
                  {t('modules.login.loginForm.actions.forgotPasswordReferral')}
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex' }}>
              <Typography variant="body2" sx={{ mr: 1 }}>
                {t('modules.login.loginForm.register.description')}
              </Typography>
              <Link
                component={RouterLink}
                to="/register"
                color="primary"
                sx={{
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
              >
                <Typography variant="body2">
                  {t('modules.login.loginForm.register.link')}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
