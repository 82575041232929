import React from 'react';
import { Box, Checkbox, FormHelperText, useTheme } from '@mui/material';
import { getIn } from 'formik';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: Record<string, any>;
  childComponent: React.ReactNode;
  className?: string;
}

const CheckboxField = ({ field, form, childComponent }: Props) => {
  const { name, value } = field;
  const { errors, touched } = form;
  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && typeof fieldError === 'string';
  const theme = useTheme();

  return (
    <>
      <Checkbox color="primary" checked={!!value} {...field} />
      <Box
        component="span"
        sx={{
          paddingLeft: theme.spacing(0.5),
        }}
      >
        {childComponent}
      </Box>
      {showError && <FormHelperText error>{fieldError}</FormHelperText>}
    </>
  );
};

export default CheckboxField;
