import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

import { UpdateUserInput, User } from 'src/modules/common/types';
import { GET_USER_SETTING_DETAILS } from 'src/services/graphql/queries';
import { useEnhancedQuery, useMessages } from 'src/modules/common/application';
import {
  removeSpacesAtStart,
  removeUnderscoreToCamelCase,
} from 'src/modules/common/application/string';
import { Loader } from 'src/modules/common/components';
import Button from 'src/modules/common/components/Button/Button';

import DataInfo from '../DataInfo/DataInfo';
import EditPersonalDetailsForm from './EditPersonalDetailsForm';
import useUserSettings from 'src/modules/common/application/use-user-settings';

const UserInfo = () => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();
  const { loading, data, error } = useEnhancedQuery<{ user: User }>(GET_USER_SETTING_DETAILS);
  const { updateUser, loading: updateUserLoading } = useUserSettings();

  const [isEditState, seteditState] = useState(false);
  const changeEditState = () => {
    seteditState(!isEditState);
  };

  const handleInformationSubmit = async (data: UpdateUserInput) => {
    seteditState(false);
    await updateUser({
      firstName: data.firstName,
      lastName: data.lastName,
      department: data.department || undefined,
      position: data.position || undefined,
    } as UpdateUserInput);
  };

  const handleReset = () => {
    seteditState(false);
  };

  if (loading || !data) return <Loader />;
  if (error) return <>{setErrorMessage(t('messages.getUserInfo.error'))}</>;

  const { user } = data;

  return (
    <>
      <DataInfo
        dataField1={
          !user.firstName && !user.lastName
            ? user.email
            : user.firstName
            ? removeSpacesAtStart(user.firstName!)
            : ''
        }
        dataField2={user.lastName ? removeSpacesAtStart(user.lastName) : ''}
        dataField3={
          user.position
            ? t('shared.types.userPosition.' + removeUnderscoreToCamelCase(user.position))
            : '-'
        }
        dataField4={
          user.department
            ? t('shared.types.userDepartment.' + removeUnderscoreToCamelCase(user.department))
            : '-'
        }
        user={user}
      />
      {!user ? null : (
        <Box
          sx={{
            height: '100%',
            backgroundColor: 'background.white',
            display: 'flex',
            padding: 1,
            flexDirection: 'column',
            color: 'text.secondary',
            border: '1px solid',
            borderColor: 'background.grey',
            borderRadius: '10px',
            fontSize: '14px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Box>
              <Typography variant="h5" sx={{ color: 'text.primary', pl: 2.5, pt: 1 }}>
                {t('modules.settings.editPersonalDetails.title')}
              </Typography>
            </Box>

            <Box>
              {isEditState ? null : (
                <Button
                  onClick={changeEditState}
                  variant="outlined"
                  sx={{
                    fontSize: '14px',
                    pl: 2,
                    pr: 2,
                    mr: 2.5,
                  }}
                >
                  {t('modules.settings.editPersonalDetails.edit')}
                </Button>
              )}
            </Box>
          </Box>
          <EditPersonalDetailsForm
            data={user}
            onInformationSubmit={handleInformationSubmit}
            disabled={!isEditState}
            onReset={handleReset}
            showCancel={true}
            loading={updateUserLoading}
          />
        </Box>
      )}
    </>
  );
};

export default UserInfo;
