import React from 'react';
import { Box, Card, Typography } from '@mui/material';

import { CollectOverviewStatus } from 'src/modules/common/types';
import { getCollectOverviewStatusChipColor } from 'src/modules/common/application/ui';
import { Chip } from 'src/modules/common/components/Chip';

interface DataOverviewStatsCardProps {
  type: CollectOverviewStatus;
  title: string;
  value: number;
}

export const DataOverviewStatsCard = (props: DataOverviewStatsCardProps) => {
  const { type, title, value } = props;
  return (
    <Card
      sx={{
        p: 3,
        border: '1px solid',
        borderColor: 'lightGrey',
        boxShadow: 'none',
        minHeight: '114px',
        width: '100%',
      }}
    >
      <Chip
        size="small"
        styles={{ maxWidth: 'fit-content' }}
        colorVariant={getCollectOverviewStatusChipColor(type)}
      >
        {title}
      </Chip>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          mt: 1,
          ml: 1,
        }}
      >
        <Typography fontSize={'1rem !important'} fontWeight={600} color="#000">
          {value ? value : '-'}
        </Typography>
      </Box>
    </Card>
  );
};
