import { gql } from '@apollo/client';

export const GET_NUVO_COLUMN_FORMAT = gql`
  query NuvoColumnFormat($categoryId: String!) {
    nuvoColumnFormat(categoryId: $categoryId) {
      key
      label
      columnType
      options {
        label
        value
        type
      }
    }
  }
`;
