import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TableContainer } from '@mui/material';

import { OpenDataRequest } from 'src/modules/common/types';

import OpenDataRequestTable from '../OpenDataRequestTable/OpenDataRequestTable';
import EnhancedChart from 'src/modules/common/components/EnhancedChart';

interface Props {
  openDataRequests: OpenDataRequest[];
}

const OpenDataRequests = ({ openDataRequests }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRowClick = (collectOverviewId: string) => {
    navigate(`/data-management/collect/${collectOverviewId}/approval`);
  };

  return (
    <EnhancedChart
      cardProps={{
        showInfoIcon: false,
        title: t('modules.dataManagement.openDataRequest.title'),
        infoText: '',
      }}
      noData={openDataRequests.length === 0}
    >
      <TableContainer sx={{ borderRadius: 0, border: 'none' }}>
        <OpenDataRequestTable openDataRequests={openDataRequests} onRowClick={handleRowClick} />
      </TableContainer>
    </EnhancedChart>
  );
};

export default OpenDataRequests;
