import { Snackbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'src/modules/common/components/Button/Button';
import { MultiSelectSnackbarActionTypes } from 'src/modules/common/types/collect';
import theme from 'src/styles/theme';

interface MultiSelectSnackbarProps {
  isOpen: boolean;
  selectedOwnerCount: number;
  onSelectedAction: (
    type: MultiSelectSnackbarActionTypes,
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => void;
}

export const MultiSelectSnackbar = (props: MultiSelectSnackbarProps) => {
  const { isOpen, selectedOwnerCount, onSelectedAction } = props;
  const { t } = useTranslation();

  const createSelectedActionHandler =
    (type: MultiSelectSnackbarActionTypes) =>
    (event: React.SyntheticEvent | Event, reason?: string) => {
      onSelectedAction(type, event, reason);
    };

  return (
    <Snackbar open={isOpen} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: '#fff',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxShadow: '0px 4px 10px 0px #D9D9D9',
          px: 3,
          py: 2,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mr: 6,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: theme.palette.colors.lightMainBlue,
              borderRadius: 1,
              px: 1,
              py: 0.5,
              mr: 1,
            }}
          >
            <Typography fontSize={'0.875rem !important'} color={theme.palette.primary.main}>
              {selectedOwnerCount}
            </Typography>
          </Box>
          <Typography
            color={'#68677F'}
            fontWeight={500}
            fontSize={'0.875rem !important'}
            textAlign={'center'}
          >
            {t('modules.dataManagement.multiSelectSnackbar.selected')}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            size="small"
            onClick={createSelectedActionHandler(MultiSelectSnackbarActionTypes.ASSIGN_DATA_OWNER)}
          >
            {t('modules.dataManagement.multiSelectSnackbar.assignDataOwner')}
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={createSelectedActionHandler(MultiSelectSnackbarActionTypes.CHANGE_DUE_DATE)}
            sx={{ ml: 2 }}
          >
            {t('modules.dataManagement.multiSelectSnackbar.changeDueDate')}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={createSelectedActionHandler(MultiSelectSnackbarActionTypes.SEND_REMINDER)}
            sx={{ ml: 2 }}
          >
            {t('modules.dataManagement.multiSelectSnackbar.sendReminder')}
          </Button>
        </Box>
      </Box>
    </Snackbar>
  );
};
