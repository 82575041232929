import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COLLECT_ASSIGN_DATA_OWNER } from 'src/services/graphql/mutations/collect';
import { COLLECT_OVERVIEWS } from 'src/services/graphql/queries/collect';
import { AssignCollectInput, CollectOverview } from 'src/modules/common/types';

import useUserData from './use-user-data';
import useMessages from './use-messages';
import useEnhancedMutation from './use-enhanced-mutation';

interface AssignedUser {
  row: string;
  assignedUser: string;
}

const useAssignedUser = (year: number) => {
  const { t } = useTranslation();
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { user } = useUserData();

  const [assignedUsers, setAssignedUsers] = useState<readonly AssignedUser[]>([]);
  const [showAssignUsersDialog, setAssignedUsersDialog] = useState<boolean>(false);

  const [assignCollect, { loading: assignDataOwnerLoading }] = useEnhancedMutation(
    COLLECT_ASSIGN_DATA_OWNER,
    {
      onCompleted: () => {
        const isRemove = assignedUsers.filter(({ assignedUser }) => !assignedUser).length > 0;
        handleAssignUserReset();
        setAssignedUsersDialog(false);
        setSuccessMessage(
          t(isRemove ? 'messages.removeAssignOwner.success' : 'messages.assignOwner.success')
        );
      },
      onError: () => {
        const isRemove = assignedUsers.filter(({ assignedUser }) => !assignedUser).length > 0;
        setErrorMessage(
          t(isRemove ? 'messages.removeAssignOwner.error' : 'messages.assignOwner.error')
        );
      },
      update(cache, result, { variables }) {
        const { collectOverviews } = cache.readQuery<{ collectOverviews: CollectOverview[] }>({
          query: COLLECT_OVERVIEWS,
          variables: {
            year,
          },
        }) || { collectOverviews: [] };

        const existedData: CollectOverview[] = JSON.parse(JSON.stringify(collectOverviews));

        if (variables && variables.assignments && variables.assignments.length > 0) {
          for (const assignment of variables.assignments) {
            const index = existedData.findIndex(
              collectOverview => collectOverview.id === assignment.collectOverviewId
            );

            if (index > -1) {
              existedData[index].assignedToId = assignment.userId;
              existedData[index].assignedById = user?.id;
            }
          }

          cache.writeQuery({
            query: COLLECT_OVERVIEWS,
            data: { collectOverviews: existedData },
            variables: {
              year,
            },
          });
        }
      },
    }
  );

  const getAssignedUser = (targetRow: string) => {
    const assignedUserIndex = assignedUsers.findIndex(user => user.row === targetRow);
    return assignedUserIndex !== -1 ? assignedUsers[assignedUserIndex].assignedUser : '';
  };

  const numAssignedUser = assignedUsers.length;

  const isAssignedUser = (rowId: string) =>
    assignedUsers.findIndex(user => user.row === rowId) !== -1;

  const handleUserAssignment = (targetRow: string, assignedUser: string) => {
    const assignedUserIndex = assignedUsers.findIndex(user => user.row === targetRow);
    let newAssignedUsers: readonly AssignedUser[] = [];
    const newAssignedUser = { row: targetRow, assignedUser };

    if (assignedUserIndex !== -1) {
      const reducedUsers = assignedUsers.filter(user => user.row !== targetRow);
      newAssignedUsers = newAssignedUsers.concat(reducedUsers, newAssignedUser);
    } else {
      newAssignedUsers = newAssignedUsers.concat(assignedUsers, newAssignedUser);
    }
    setAssignedUsers(newAssignedUsers);
  };

  const handleMultiUserAssignment = (targetRows: string[], assignedUser: string) => {
    const newAssignedUsers = [] as AssignedUser[];
    targetRows.forEach(targetRow => {
      newAssignedUsers.push({ row: targetRow, assignedUser });
    });
    setAssignedUsers(newAssignedUsers);
  };

  const hasAssignedUser = assignedUsers.length > 0;

  const handleAssignUserReset = () => {
    setAssignedUsers([]);
  };

  const handleSaveDataOwner = (notify: boolean, message?: string) => {
    const assignments: AssignCollectInput[] = assignedUsers.map(user => ({
      collectOverviewId: user.row,
      userId: user.assignedUser && user.assignedUser.length > 0 ? user.assignedUser : null,
    }));
    assignCollect({
      variables: {
        assignments,
        notify,
        message,
      },
    });
  };

  return {
    assignedUsers,
    hasAssignedUser,
    numAssignedUser,
    showAssignUsersDialog,
    assignDataOwnerLoading,
    isAssignedUser,
    getAssignedUser,
    handleUserAssignment,
    setAssignedUsers,
    handleAssignUserReset,
    setAssignedUsersDialog,
    handleSaveDataOwner,
    handleMultiUserAssignment,
  };
};

export default useAssignedUser;
