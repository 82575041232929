import { useTranslation } from 'react-i18next';
import { useFeatureFlagEnabled } from 'posthog-js/react';

import { UPDATE_REPORT_INPUT } from 'src/services/graphql/mutations';
import { useMessages, useEnhancedMutation } from 'src/modules/common/application';
import { FeatureFlag } from 'src/modules/common/types/featureFlags';
import useLanguage from 'src/modules/common/application/use-language';

import { rewriteExistedReportInputCache } from './reportInputCache';

const useReportInput = (reportId: string, reportSectionId: string) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { setErrorMessage, setSuccessMessage } = useMessages();

  // TODO remove after feature flag "report-mark-as-complete-button" was removed
  const isMarkedCompletedEnabled = useFeatureFlagEnabled(
    FeatureFlag.REPORT_MARK_AS_COMPLETE_BUTTON
  );

  const [updateReportInput, { loading: updateReportInputLoading }] = useEnhancedMutation(
    UPDATE_REPORT_INPUT,
    {
      onCompleted: (data, options) => {
        if (options?.variables?.isMarkedCompleted) {
          setSuccessMessage(t(`messages.updateReportComplete.complete`));
        } else if (options?.variables?.input && options?.variables?.input.length > 0) {
          setSuccessMessage(t('messages.updateReport.success'));
        }
      },
      onError: () => {
        setErrorMessage(t('messages.updateReport.error'));
      },
      update(cache, { data }, { variables }) {
        rewriteExistedReportInputCache(
          reportId,
          reportSectionId,
          language,
          variables || {},
          cache,
          data?.updateReportInput
        );
      },
    }
  );

  const handleUpdateReport = async (input?: string, isMarkedCompleted?: boolean) => {
    await updateReportInput({
      variables: {
        reportId,
        sectionId: reportSectionId,
        input,
        isMarkedCompleted,
        oldProgressCalculation: !isMarkedCompletedEnabled ? true : undefined,
      },
    });
  };

  return {
    handleUpdateReport,
    updateReportInputLoading,
  };
};

export default useReportInput;
