import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button } from '@mui/material';
import { CardLayout } from 'src/modules/common/components';
import { AppIcon } from 'src/modules/common/components/icons';
import theme from 'src/styles/theme';
import useExpiredSharableLink from 'src/modules/common/application/use-expired-shareable-link';

const PublicExpiredLinkCard = ({ email }: { email: string }) => {
  const { t } = useTranslation();

  const { maskedEmail, handleResendLink } = useExpiredSharableLink({
    email,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <CardLayout height="flex-grow">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <AppIcon />
          <Typography fontSize={'1.5rem'} fontWeight={600} sx={{ mt: 2 }}>
            {t('modules.dataManagement.publicLinkExpiredCard.title')}
          </Typography>
          <Typography
            fontSize={'1rem'}
            fontWeight={500}
            textAlign="center"
            color={theme.palette.colors.lightGrey}
            sx={{ mt: 2 }}
            paddingTop={1}
          >
            {t('modules.dataManagement.publicLinkExpiredCard.description')}
            <span style={{ color: '#000', marginLeft: 8 }}>
              {t('modules.dataManagement.publicLinkExpiredCard.maskedEmail', {
                maskedEmail,
              })}
            </span>
          </Typography>
          <Button variant="contained" style={{ marginTop: 32 }} onClick={handleResendLink}>
            {t('modules.dataManagement.publicLinkExpiredCard.ctaText')}
          </Button>
        </Box>
      </CardLayout>
    </Box>
  );
};

export default PublicExpiredLinkCard;
