import { useTranslation } from 'react-i18next';
import {
  MaterialityAssessment,
  MaterialityAssessmentStatus,
  MaterialityAssessmentTopic,
} from 'src/modules/common/types';
import { useEnhancedMutation, useMessages } from 'src/modules/common/application';
import {
  CREATE_MATERIALITY_ASSESSMENT_TOPIC,
  DELETE_MATERIALITY_ASSESSMENT_TOPIC,
} from 'src/services/graphql/mutations/material';
import { GET_MATERIALITY_ASSESSMENT } from 'src/services/graphql/queries';

interface UseMaterialityAssessmentTopicProps {
  year: number;
  onCreateTopicSuccess?: () => void;
  onDeleteTopicSuccess?: () => void;
}

const useMaterialityAssessmentTopic = ({
  year,
  onCreateTopicSuccess,
  onDeleteTopicSuccess,
}: UseMaterialityAssessmentTopicProps) => {
  const { t } = useTranslation();
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const [createMATopic, { loading: createLoading }] = useEnhancedMutation(
    CREATE_MATERIALITY_ASSESSMENT_TOPIC,
    {
      onCompleted: () => {
        onCreateTopicSuccess && onCreateTopicSuccess();
        setSuccessMessage(t('messages.generic.success'));
      },
      onError: () => {
        setErrorMessage(t('messages.generic.error'));
      },
      update(cache, { data }, { variables }) {
        const result = cache.readQuery<{
          materialityAssessment: MaterialityAssessment;
        }>({
          query: GET_MATERIALITY_ASSESSMENT,
          variables: {
            year,
          },
        });

        if (result) {
          const { materialityAssessment } = result;

          const updatedTopic = data?.createMaterialityAssessmentTopic;

          const topics = JSON.parse(JSON.stringify(materialityAssessment?.topics));

          const updatedTopics = topics.map((topic: MaterialityAssessmentTopic) => {
            if (topic.materialTopic.id === variables?.materialTopicId) {
              return { ...topic, id: updatedTopic.id, status: updatedTopic.status };
            }
            return topic;
          });

          cache.writeQuery({
            query: GET_MATERIALITY_ASSESSMENT,
            variables: {
              year,
            },
            data: {
              materialityAssessment: { ...materialityAssessment, topics: updatedTopics },
            },
          });
        }
      },
    }
  );

  const [deleteMATopic, { loading: deleteLoading }] = useEnhancedMutation(
    DELETE_MATERIALITY_ASSESSMENT_TOPIC,
    {
      onCompleted: () => {
        onDeleteTopicSuccess && onDeleteTopicSuccess();
        setSuccessMessage(t('messages.generic.success'));
      },
      onError: () => {
        setErrorMessage(t('messages.generic.error'));
      },
      update(cache, { data }, { variables }) {
        const { materialityAssessment } = cache.readQuery<{
          materialityAssessment: MaterialityAssessment;
        }>({
          query: GET_MATERIALITY_ASSESSMENT,
          variables: {
            year,
          },
        }) || { materialityAssessment: null };

        const topics = JSON.parse(JSON.stringify(materialityAssessment?.topics));

        const updatedTopics = topics.map((topic: MaterialityAssessmentTopic) => {
          if (topic.id === variables?.id) {
            return { ...topic, id: null, status: MaterialityAssessmentStatus.DEACTIVE };
          }
          return topic;
        });

        cache.writeQuery({
          query: GET_MATERIALITY_ASSESSMENT,
          variables: {
            year,
          },
          data: {
            materialityAssessment: { ...materialityAssessment, topics: updatedTopics },
          },
        });
      },
    }
  );

  return {
    deleteMATopic,
    createMATopic,
    createLoading,
    deleteLoading,
  };
};

export default useMaterialityAssessmentTopic;
