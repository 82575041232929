import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) @client
  }
`;

export const LOGIN_VIA_TOKEN = gql`
  mutation LoginViaToken($token: String!) {
    loginViaToken(token: $token) @client
  }
`;

export const REGISTER_USER = gql`
  mutation registerUser($email: String!, $password: String!, $termsAccepted: Boolean!) {
    registerUser(email: $email, password: $password, termsAccepted: $termsAccepted) @client
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($newPassword: String!) {
    updatePassword(newPassword: $newPassword) @client
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout @client
  }
`;

export const SEND_PASSWORD_RESET_MAIL = gql`
  mutation SendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email)
  }
`;
