import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ConfigProvider } from 'src/modules/common/application/context/config';
import theme from 'src/styles/theme';
import App from './App';
import { FirebaseProvider } from 'src/modules/common/application/context/FirebaseContext';
import ApolloProvider from 'src/services/graphql/ApolloProvider';
import Firebase from 'src/services/firebase.service';
import getI18Next from 'src/modules/common/application/i18n';
import { initSentry } from 'src/infrastructure/errorHandler';
import { MessageProvider } from 'src/modules/common/application/context/MessageContext';

declare global {
  // eslint-disable-next-line no-var
  var __rootdir__: string;
}

initSentry();

// Mock Firebase Auth
const useFirebaseAuthMock = process.env.REACT_APP_FIREBASE_USE_EMULATOR as string;
const firebase = new Firebase(useFirebaseAuthMock === 'true');

// Get GraphQL endpoint from environment variable
const graphqlEndpoint = process.env.REACT_APP_GQL_ENDPOINT as string;

// Initialise internationalisation
const useI18NextCiMode = process.env.REACT_APP_CI_MODE as string;
// eslint-disable-next-line
const i18n = getI18Next(useI18NextCiMode === 'true');

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ConfigProvider>
    <ThemeProvider theme={theme}>
      <FirebaseProvider firebase={firebase}>
        <ApolloProvider firebase={firebase} graphqlEndpoint={graphqlEndpoint}>
          <MessageProvider>
            <CssBaseline />
            <App />
          </MessageProvider>
        </ApolloProvider>
      </FirebaseProvider>
    </ThemeProvider>
  </ConfigProvider>
);
