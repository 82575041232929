import React from 'react';
import { useTranslation } from 'react-i18next';

import { Company } from 'src/modules/common/types';
import { GET_COMPANY_DETAILS } from 'src/services/graphql/queries';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import { useEnhancedQuery, useMessages } from 'src/modules/common/application';
import { Loader } from 'src/modules/common/components';

import DataInfo from '../DataInfo/DataInfo';
import BusinessDetails from './BusinessDetails';

const BusinessInfo = () => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();
  const { loading, data, error } = useEnhancedQuery<{ company: Company }>(GET_COMPANY_DETAILS);

  if (loading || !data) return <Loader />;
  if (error) return <>{setErrorMessage(t('messages.generic.error'))}</>;

  const { company } = data;

  return (
    <>
      <DataInfo
        dataField1={company.name}
        dataField2=""
        dataField3={t(
          'shared.types.subIndustry.' +
            removeUnderscoreToCamelCase(data?.company?.subIndustry?.name || '')
        )}
        dataField4={company.headquarter}
      />
      {company ? <BusinessDetails data={company} /> : null}
    </>
  );
};

export default BusinessInfo;
