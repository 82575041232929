import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';

import FacilityList from 'src/modules/super/components/Facility/FacilityList';
import useFacilitySuper from 'src/modules/common/application/use-facility-super';
import FacilityForm from './FacilityForm';

const FacilityAdminPanel = ({ companyId }: { companyId: string }) => {
  const { t } = useTranslation();
  const { facilities, facility, handleSelectFacility, editMode, handleSubmitFacility } =
    useFacilitySuper(companyId);

  return (
    <Grid item xs={4}>
      <Typography variant="h5">{t('modules.super.facility.title')}</Typography>
      <FacilityList facilities={facilities} selected={facility} onSelect={handleSelectFacility} />
      <FacilityForm
        data={facility}
        handleSubmit={handleSubmitFacility}
        editMode={editMode}
        handleReset={() => handleSelectFacility('')}
      />
    </Grid>
  );
};

export default FacilityAdminPanel;
