import { Theme } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

export interface BreadcrumbData {
  name: string;
  to?: string;
  facilityNameState?: string;
  year?: string;
}

export const generateBreadCrumbs = (data: BreadcrumbData[], theme: Theme) => {
  return data.map((d, idx) => {
    const isLast = idx === data.length - 1;
    const key = `${d.name}-${d.to}`;
    if (isLast && data.length > 1) {
      return (
        <Typography
          key={key}
          sx={{
            fontSize: '0.875rem !important',
            fontWeight: 600,
            color: theme.palette.colors.grey,
          }}
          variant="body2"
        >
          {d.name}
        </Typography>
      );
    }
    return (
      <Link
        style={{
          fontSize: '0.875rem',
          color: theme.palette.colors.lightGrey,
          textDecoration: 'none',
          fontFamily: 'Inter',
        }}
        key={key}
        to={d.to || ''}
        state={{ facilityName: d.facilityNameState, year: d.year } || ''}
      >
        {d.name}
      </Link>
    );
  });
};
