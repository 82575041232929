import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { InviteUserInput, InviteUserResponse, UserAccountStatus } from 'src/modules/common/types';
import { INVITE_USERS, RESEND_INVITATION_EMAIL } from 'src/services/graphql/mutations';
import { useMessages } from 'src/modules/common/application';
import { ManageUser } from 'src/modules/settings/components/ManageTeam/types';
import { GET_USERS } from 'src/services/graphql/queries';

const useUserInvitation = () => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { t } = useTranslation();

  const [inviteUsers, { loading }] = useMutation(INVITE_USERS, {
    onError: () => setErrorMessage(t('messages.generic.error')),
    update(cache, result) {
      if (result.data.inviteUsers.__typename === 'InviteUserError') return;

      const newUsers: ManageUser[] = [];

      result.data.inviteUsers.users.forEach((user: ManageUser) => {
        newUsers.push({
          ...user,
          status: UserAccountStatus.PENDING_INVITES,
        });
      });

      const { users } = cache.readQuery<{
        users: ManageUser[];
      }>({
        query: GET_USERS,
      }) || { users: [] };

      cache.writeQuery({
        query: GET_USERS,
        data: {
          users: [...users, ...newUsers],
        },
      });
    },
  });

  const [resendInviteEmail, { loading: loadingResendInviteEmail }] = useMutation(
    RESEND_INVITATION_EMAIL,
    {
      onError: () => setErrorMessage(t('messages.generic.error')),
      onCompleted: () =>
        setSuccessMessage(t('modules.registerInvited.resendVerification.resendConfirmationTitle')),
    }
  );

  const onInviteUsers = async (users: InviteUserInput[]): Promise<InviteUserResponse> => {
    const response = await inviteUsers({ variables: { input: users } });

    if (response.data?.inviteUsers.__typename === 'InviteUserSuccess') {
      setSuccessMessage(t('messages.inviteUser.success'));
      return response?.data?.inviteUsers;
    } else {
      setErrorMessage(t('modules.settings.inviteUser.emailsExists'));
      return response?.data?.inviteUsers;
    }
  };

  return {
    onInviteUsers,
    resendInviteEmail,
    loading,
    loadingResendInviteEmail,
  };
};

export default useUserInvitation;
