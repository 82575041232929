import { gql } from '@apollo/client';
import { CORE_ESG_VALUE_FIELDS } from '../fragments/esgValue';

export const GET_REQUESTED_ESG_VALUES_BY_COLLECT_OVERVIEW_ID = gql`
  ${CORE_ESG_VALUE_FIELDS}
  query RequestedEsgValuesByCollectOverviewId($collectOverviewId: String!) {
    requestedEsgValuesByCollectOverviewId(collectOverviewId: $collectOverviewId) {
      ...CoreEsgValueFields
    }
  }
`;

export const GET_REQUESTED_ESG_VALUES_BY_OWNER = gql`
  query RequestedEsgValuesByOwner($collectOverviewId: String!) {
    requestedEsgValuesByOwner(collectOverviewId: $collectOverviewId) {
      externalUserEmail
      requestStatus
      esgValues {
        id
        kpiTitle
        currentValue
        currentUnit
        newValue
        newUnit
        approvalStatus
      }
    }
  }
`;
