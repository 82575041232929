import { useState } from 'react';

const useReminderDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [recipientId, setRecipientId] = useState<string | undefined>(undefined);

  const onSend = (message?: string) => {
    setIsOpen(false);
  };

  const onCancel = () => {
    setIsOpen(false);
  };
  const onOpen = () => {
    setIsOpen(true);
  };

  return {
    isOpen,
    onCancel,
    onSend,
    onOpen,
    recipientId,
    setRecipientId,
  };
};

export default useReminderDialog;
