export const getRowsPerPage = (screenHeight: number): number => {
  const height = screenHeight - 450;
  const rowHeight = 60;
  const pageSizes = [5, 10, 25, 50, 100];

  const maxRows = Math.floor(height / rowHeight);

  let nearestPageSize = pageSizes[0];
  let minDifference = Math.abs(maxRows - pageSizes[0]);

  for (let i = 1; i < pageSizes.length; i++) {
    const difference = Math.abs(maxRows - pageSizes[i]);
    if (difference < minDifference) {
      minDifference = difference;
      nearestPageSize = pageSizes[i];
    }
  }

  return nearestPageSize;
};

export const getTotalPages = (rowsPerPage: number, totalRows: number): number => {
  return Math.ceil(totalRows / rowsPerPage);
};
