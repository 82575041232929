import { useTranslation } from 'react-i18next';

import { EsgCategory, TranslatedCategoryType } from 'src/modules/common/types';
import { GET_ESG_CATEGORIES } from 'src/services/graphql/queries';
import useEnhancedQuery from 'src/modules/common/application/use-enhanced-query';
import useMessages from './use-messages';

import { removeUnderscoreToCamelCase } from './string';
import { genericSortSingleColumn } from './sort';

const useEsgCategory = () => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();
  const { loading, data } = useEnhancedQuery<{ esgCategories: EsgCategory[] }>(GET_ESG_CATEGORIES, {
    fetchPolicy: 'cache-first',
    onError: () => setErrorMessage(t('messages.generic.error')),
  });

  const categories: Record<string, EsgCategory> = {};
  if (data?.esgCategories) {
    for (const category of data.esgCategories) {
      categories[category.id] = category;
    }
  }

  const getCategoryById = (categoryId: string): EsgCategory | undefined => {
    return categories[categoryId];
  };

  const getCategoryByTitle = (title: string): EsgCategory | undefined => {
    return data?.esgCategories?.find(category => category.title === title);
  };

  const sortCategoryArray = (categories: EsgCategory[]) => {
    const translatedCategories: TranslatedCategoryType[] = categories.map(category => ({
      ...category,
      translatedTitle: t('shared.types.esgCategory.' + removeUnderscoreToCamelCase(category.title)),
    }));
    genericSortSingleColumn(translatedCategories, 'translatedTitle');
    return translatedCategories.map(({ area, id, title }) => ({
      area,
      id,
      title,
    })) as EsgCategory[];
  };

  return {
    categoriesArray: data?.esgCategories ? sortCategoryArray(data.esgCategories) : [],
    categories,
    loading,
    getCategoryById,
    getCategoryByTitle,
  };
};

export default useEsgCategory;
