import React, { useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

import { Chip, TabBar } from 'src/modules/common/components';
import { useYears } from 'src/modules/common/application';
import { getYearChipColor } from 'src/modules/common/application/ui';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import Button from 'src/modules/common/components/Button/Button';

import {
  FinancialMaterialityPayload,
  ImpactMaterialityPayload,
  MaterialityType,
} from '../../types/materialEvaluation';
import MaterialityDetail from '../MaterialityDetail/MaterialityDetail';
import useMaterialityAssessment from '../../application/use-materiality-assessment';

const MaterialityEvaluationDetailContainer = ({
  materialityAssessmentTopicId,
}: {
  materialityAssessmentTopicId: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const intialPayload =
    activeTabIndex === 1 ? ({} as FinancialMaterialityPayload) : ({} as ImpactMaterialityPayload);
  const [payload, setPayload] = useState<FinancialMaterialityPayload | ImpactMaterialityPayload>(
    intialPayload
  );

  const { selectedYear } = useYears();

  const {
    getEvaluationByMaterialityAssessmentTopicId,
    getMaterialTopicByMaterialityAssessmentTopicId,
    getEvaluationResponsesByMaterialityAssessmentTopicId,
    updateMaterialityEvaluation,
    updateMaterialityEvaluationLoading,
  } = useMaterialityAssessment(selectedYear);

  const materialTopic = getMaterialTopicByMaterialityAssessmentTopicId(
    materialityAssessmentTopicId
  );

  const handleSaveEvaluationClick = () => {
    updateMaterialityEvaluation({
      variables: {
        input: {
          ...payload,
          materialityAssessmentTopicId,
        },
      },
    });
  };

  const evalData = getEvaluationByMaterialityAssessmentTopicId(materialityAssessmentTopicId);

  useEffect(() => {
    if (evalData) {
      setPayload({
        financialDescription: evalData.financialDescription!,
        financialProbability: evalData.financialProbability!,
        financialSignificance: evalData.financialSignificance!,
        impactDescription: evalData.impactDescription!,
        impactIrreversibility: evalData.impactIrreversibility!,
        impactScale: evalData.impactScale!,
        impactScope: evalData.impactScope!,
      });
    }
  }, [evalData]);

  const TABS = useMemo(() => {
    return [
      {
        input: (
          <MaterialityDetail
            evaluationData={evalData}
            stakeholderResponseData={getEvaluationResponsesByMaterialityAssessmentTopicId(
              materialityAssessmentTopicId
            )}
            type={MaterialityType.Impact}
            payload={payload}
            setPayload={setPayload}
          />
        ),
        label: t(
          'modules.materialityAssessment.materialityEvaluationDetail.impactMateriality.title'
        ),
        index: 0,
        id: 'impact-materiality',
        to: `/materiality-assessment/evaluation/${materialityAssessmentTopicId}`,
      },
      {
        input: (
          <MaterialityDetail
            evaluationData={evalData}
            stakeholderResponseData={getEvaluationResponsesByMaterialityAssessmentTopicId(
              materialityAssessmentTopicId
            )}
            type={MaterialityType.Financial}
            setPayload={setPayload}
            payload={payload}
          />
        ),
        label: t(
          'modules.materialityAssessment.materialityEvaluationDetail.financialMateriality.title'
        ),
        index: 1,
        id: 'financial-materiality',
        to: `/materiality-assessment/evaluation/${materialityAssessmentTopicId}`,
      },
    ];
  }, [
    evalData,
    getEvaluationResponsesByMaterialityAssessmentTopicId,
    materialityAssessmentTopicId,
    payload,
    t,
  ]);

  return (
    <Box>
      <Box py={2}>
        <IconButton
          onClick={() => {
            navigate('/materiality-assessment/evaluation');
          }}
        >
          <ArrowBackIcon color="primary" />
        </IconButton>
      </Box>
      {materialTopic ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            paddingTop: 1,
            paddingBottom: 2,
          }}
        >
          <Typography variant="h4" marginRight={2}>
            {t(`shared.types.materialTopic.${removeUnderscoreToCamelCase(materialTopic.title)}`)}
          </Typography>
          <Chip
            colorVariant={getYearChipColor(selectedYear)}
            styles={{
              maxWidth: 'fit-content',
            }}
          >
            {selectedYear}
          </Chip>
        </Box>
      ) : null}
      <TabBar
        activeTabIndex={activeTabIndex}
        content={TABS}
        onTabChange={tabIndex => {
          setActiveTabIndex(tabIndex);
        }}
        SideComponent={() => (
          <Box
            sx={{
              display: 'flex',
              right: 0,
              alignItems: 'flex-end',
            }}
          >
            <Button variant="outlined" size="small">
              {t('modules.materialityAssessment.materialityEvaluationDetail.cancel')}
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={handleSaveEvaluationClick}
              sx={{
                marginLeft: 1,
              }}
              loading={updateMaterialityEvaluationLoading}
            >
              {t('modules.materialityAssessment.materialityEvaluationDetail.save')}
            </Button>
          </Box>
        )}
        sideComponentStyles={{
          display: 'flex',
          justifyContent: 'flex-end',
          top: 0,
        }}
      />
    </Box>
  );
};

export default MaterialityEvaluationDetailContainer;
