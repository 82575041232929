import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Company } from 'src/modules/common/types';

interface Props {
  companies: Company[] | undefined;
  selected: Company | undefined;
  onSelect: (value: string) => void;
}

const CompanyList = ({ companies, onSelect, selected }: Props) => {
  return (
    <Select
      sx={{
        marginBottom: 2,
        '&.MuiInputBase-root': {
          width: '100%',
        },
      }}
      id="company-select"
      name="company"
      value={selected ? selected.id : ''}
      IconComponent={KeyboardArrowDownIcon}
      onChange={(e: SelectChangeEvent) => onSelect(e.target.value)}
    >
      {companies?.map(company => (
        <MenuItem key={company.id} value={company.id}>
          {company.name} - {company.headquarter}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CompanyList;
