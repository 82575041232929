export const MATopicsTableHeaders = [
  'modules.materialityAssessment.materialityTopics.topics',
  'modules.materialityAssessment.materialityTopics.category',
  'modules.materialityAssessment.materialityTopics.inFavour',
  'modules.materialityAssessment.materialityTopics.against',
  'modules.materialityAssessment.materialityTopics.identified',
];

export const MAEvaluationTableHeaders = [
  'modules.materialityAssessment.materialityEvaluation.topic',
  'modules.materialityAssessment.materialityEvaluation.category',
  'modules.materialityAssessment.materialityEvaluation.overallMateriality',
  'modules.materialityAssessment.materialityEvaluation.financialMateriality',
  'modules.materialityAssessment.materialityEvaluation.impactMateriality',
  'modules.materialityAssessment.materialityEvaluation.responseProgress',
];
