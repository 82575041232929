import { Box, Card } from '@mui/material';
import React, { useState } from 'react';

import { User, RequestStatus } from 'src/modules/common/types';
import { Chip } from 'src/modules/common/components/Chip';
import { getRequestStatusChipColor } from 'src/modules/common/application/ui';

import { GroupedStakeholderAvatarWithCount } from '../MaterialityTopicsTable/GroupedStakeholderAvatar';
import { StakeholdersResultsDialog } from '../StakeholderResultDialog/StakeholderResultDialog';

interface MAStatsCardProps {
  title: string;
  type: RequestStatus;
  stackeholders: User[];
}

const MAStatsCard = ({ title, type, stackeholders }: MAStatsCardProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handeleOnGroupAvatarClick = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Card
      sx={{
        p: 3,
        border: '1px solid',
        borderColor: 'lightGrey',
        boxShadow: 'none',
        minHeight: '114px',
      }}
    >
      <Chip
        size="small"
        styles={{ maxWidth: 'fit-content' }}
        colorVariant={getRequestStatusChipColor(type)}
      >
        {title}
      </Chip>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          mt: 1,
          ml: 1,
        }}
      >
        {stackeholders?.length > 0 ? (
          <GroupedStakeholderAvatarWithCount
            collaborators={stackeholders}
            handeleOnContainerClick={handeleOnGroupAvatarClick}
          />
        ) : (
          '-'
        )}
      </Box>
      <StakeholdersResultsDialog
        isOpen={isOpen}
        data={stackeholders}
        onClose={onClose}
        showTitleSubTitle={false}
      />
    </Card>
  );
};

export default MAStatsCard;
