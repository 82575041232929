import React from 'react';
import { useParams } from 'react-router-dom';

import MaterialityEvaluationDetailContainer from 'src/modules/materialityAssessment/components/MaterialityEvaluationDetail/MaterialityEvaluationDetailContainer';
import PageContainer from 'src/modules/common/components/Structure/PageContainer';
import { useMessages } from 'src/modules/common/application';
import { useTranslation } from 'react-i18next';

const MaterialityEvaluation = () => {
  const { materialityAssessmentTopicId } = useParams();
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();

  if (!materialityAssessmentTopicId) return <>{setErrorMessage(t('messages.getTopicId.error'))}</>;
  return (
    <PageContainer>
      <MaterialityEvaluationDetailContainer
        materialityAssessmentTopicId={materialityAssessmentTopicId}
      />
    </PageContainer>
  );
};

export default MaterialityEvaluation;
