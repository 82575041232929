import { useEffect, useRef, useState } from 'react';
import { EnhancedChatMessage, ChatMessageRole } from '../types';
import { useEnhancedMutation, useMessages } from 'src/modules/common/application';
import { CHAT } from 'src/services/graphql/mutations/chat';
import { useTranslation } from 'react-i18next';
import { useHotkeys } from 'react-hotkeys-hook';

const useChatbot = () => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();
  const contentRef = useRef<HTMLDivElement>(null);

  const defaultInitialMessage: EnhancedChatMessage = {
    role: ChatMessageRole.ASSISTANT,
    content: t('modules.chat.initialMessage'),
  };

  const [messages, setMessages] = useState<EnhancedChatMessage[]>([defaultInitialMessage]);
  const [open, setOpen] = useState(false);

  const [showFloatingChatBot, setShowFloatingChatBot] = useState(false);
  const [isChatBotHidden, setIsChatBotHidden] = useState(false);

  useHotkeys(
    ['ctrl+k'],
    () => {
      setOpen(!open);
    },
    [open]
  );

  const handleCloseBot = () => {
    setShowFloatingChatBot(false);
    setOpen(false);
  };

  const handleUnpinBot = () => {
    setShowFloatingChatBot(false);
    const newMessages = deleteAnimations();
    setMessages(newMessages);
  };

  const handlePin = () => {
    setShowFloatingChatBot(true);
    const newMessages = deleteAnimations();
    setMessages(newMessages);
  };

  const toggleHiddenChatBot = () => {
    setIsChatBotHidden(!isChatBotHidden);
    jumpToBottom();
  };

  const toggleDialog = () => {
    setOpen(!open);
  };

  const [chat, { loading }] = useEnhancedMutation(CHAT, {
    onError: () => setErrorMessage(t('messages.generic.error')),
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (loading) jumpToBottom();
  }, [loading]);

  const handleSendMessage = async (inputMessage: string) => {
    let newMessages: EnhancedChatMessage[] = deleteAnimations();

    newMessages.push({
      role: ChatMessageRole.USER,
      content: inputMessage,
    });

    setMessages(newMessages);

    const response = await chat({
      variables: {
        messages: newMessages,
      },
    });

    newMessages = [
      ...newMessages,
      {
        role: ChatMessageRole.ASSISTANT,
        content: response.data?.chat,
        showAnimated: true,
      },
    ];
    setMessages(newMessages);
    jumpToBottom();
  };

  const deleteAnimations = () => {
    const newMessages = [];
    for (const message of messages) {
      newMessages.push({
        content: message.content,
        role: message.role,
      });
    }
    return newMessages;
  };

  const jumpToBottom = () => {
    contentRef?.current?.scrollTo(0, contentRef.current.scrollHeight + 200);
  };

  return {
    messages,
    isChatBotHidden,
    loading,
    open,
    handleSendMessage,
    toggleDialog,
    toggleHiddenChatBot,
    handleCloseBot,
    showFloatingChatBot,
    handleUnpinBot,
    handlePin,
    contentRef,
  };
};

export default useChatbot;
