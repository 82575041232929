import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useMessages } from 'src/modules/common/application';
import ReportSection from 'src/modules/reporting/components/ReportSection';

const ReportSectionPage = () => {
  const { sectionId, reportId } = useParams();
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();

  if (!reportId) return <>{setErrorMessage(t('messages.getUserInfo.error'))}</>;

  return <ReportSection reportId={reportId} sectionId={sectionId} />;
};

export default ReportSectionPage;
