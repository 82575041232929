import { gql } from '@apollo/client';

export const COMPANY_FRAGMENT = gql`
  fragment companyValues on Company {
    id
    name
    headquarter
    industry {
      id
      name
    }
    subIndustry {
      id
      name
    }
    status
    employeesNum
    dashboardUrl
  }
`;
