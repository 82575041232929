import { Theme } from '@mui/material';
import { createArrayFromLength } from 'src/modules/common/application/array';
import { ManageUser } from '../../ManageTeam/types';

const createFormValues = (formNo: number) => {
  return {
    [`email${formNo}`]: '',
    [`role${formNo}`]: '',
  };
};

export const createInitialFormValues = (totalForms: number) => {
  return createArrayFromLength(totalForms).reduce((acc, formNo) => {
    return {
      ...acc,
      ...createFormValues(formNo),
    };
  }, {});
};

export const createInitialEditFormValues = (user: ManageUser) => {
  return {
    email1: user.email,
    role1: user.role,
  };
};

export const getInputLabelStyles = (theme: Theme) => ({
  transform: `${theme.custom.inputLabels} !important`,
  fontSize: '1rem !important',
  color: 'colors.lightGrey',
});
