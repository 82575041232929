import React from 'react';
import { Box, Typography } from '@mui/material';

interface SystemMessageProps {
  message: string;
}
const SystemMessage = (props: SystemMessageProps) => {
  const { message } = props;

  return (
    <React.Fragment>
      <Box
        sx={{
          width: '100%',
          background: '#F8F9FB',
          py: '8px',
          px: '12px',
          color: '#000',
          borderRadius: '10px',
          ml: 1,
          mb: '15px',
        }}
      >
        <Typography variant="subtitle1" textAlign={'center'} fontWeight={500}>
          {message}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default SystemMessage;
