import * as React from 'react';
import { IconProps } from '../types';

const SvgMaterialityAssessment = (props: IconProps) => (
  <svg width={64} height={48} fill="none" role="img" {...props}>
    <path
      d="M43.3456 11.6288C44.3477 11.6288 45.16 10.8165 45.16 9.8144C45.16 8.81233 44.3477 8 43.3456 8C42.3436 8 41.5312 8.81233 41.5312 9.8144C41.5312 10.8165 42.3436 11.6288 43.3456 11.6288Z"
      fill="inherit"
    />
    <path
      d="M36.7988 15.2929C37.8008 15.2929 38.6132 14.4805 38.6132 13.4785C38.6132 12.4764 37.8008 11.6641 36.7988 11.6641C35.7967 11.6641 34.9844 12.4764 34.9844 13.4785C34.9844 14.4805 35.7967 15.2929 36.7988 15.2929Z"
      fill="inherit"
    />
    <path
      d="M19.8574 28.4804C20.8594 28.4804 21.6718 27.668 21.6718 26.666C21.6718 25.6639 20.8594 24.8516 19.8574 24.8516C18.8553 24.8516 18.043 25.6639 18.043 26.666C18.043 27.668 18.8553 28.4804 19.8574 28.4804Z"
      fill="inherit"
    />
    <path
      d="M23.5175 30.2577C24.5196 30.2577 25.3319 29.4454 25.3319 28.4433C25.3319 27.4412 24.5196 26.6289 23.5175 26.6289C22.5155 26.6289 21.7031 27.4412 21.7031 28.4433C21.7031 29.4454 22.5155 30.2577 23.5175 30.2577Z"
      fill="inherit"
    />
    <path
      d="M27.1464 28.4804C28.1485 28.4804 28.9608 27.668 28.9608 26.666C28.9608 25.6639 28.1485 24.8516 27.1464 24.8516C26.1444 24.8516 25.332 25.6639 25.332 26.666C25.332 27.668 26.1444 28.4804 27.1464 28.4804Z"
      fill="inherit"
    />
    <path
      d="M18.2363 34.4725C19.2383 34.4725 20.0507 33.6602 20.0507 32.6581C20.0507 31.6561 19.2383 30.8438 18.2363 30.8438C17.2342 30.8438 16.4219 31.6561 16.4219 32.6581C16.4219 33.6602 17.2342 34.4725 18.2363 34.4725Z"
      fill="inherit"
    />
    <path
      d="M21.4785 19.7304C22.4805 19.7304 23.2929 18.918 23.2929 17.916C23.2929 16.9139 22.4805 16.1016 21.4785 16.1016C20.4764 16.1016 19.6641 16.9139 19.6641 17.916C19.6641 18.918 20.4764 19.7304 21.4785 19.7304Z"
      fill="inherit"
    />
    <path
      d="M23.5175 13.2499C24.5196 13.2499 25.3319 12.4376 25.3319 11.4355C25.3319 10.4334 24.5196 9.62109 23.5175 9.62109C22.5155 9.62109 21.7031 10.4334 21.7031 11.4355C21.7031 12.4376 22.5155 13.2499 23.5175 13.2499Z"
      fill="inherit"
    />
    <path
      d="M36.0566 20.9569C37.0587 20.9569 37.871 20.1446 37.871 19.1425C37.871 18.1405 37.0587 17.3281 36.0566 17.3281C35.0545 17.3281 34.2422 18.1405 34.2422 19.1425C34.2422 20.1446 35.0545 20.9569 36.0566 20.9569Z"
      fill="inherit"
    />
    <path
      d="M36.4785 28.246C37.4805 28.246 38.2929 27.4337 38.2929 26.4316C38.2929 25.4295 37.4805 24.6172 36.4785 24.6172C35.4764 24.6172 34.6641 25.4295 34.6641 26.4316C34.6641 27.4337 35.4764 28.246 36.4785 28.246Z"
      fill="inherit"
    />
    <path
      d="M40.1074 16.9101C41.1094 16.9101 41.9218 16.0977 41.9218 15.0957C41.9218 14.0936 41.1094 13.2812 40.1074 13.2812C39.1053 13.2812 38.293 14.0936 38.293 15.0957C38.293 16.0977 39.1053 16.9101 40.1074 16.9101Z"
      fill="inherit"
    />
    <path
      d="M31.6499 21.932V8H30.0299V21.932H16.4219V23.552H30.0299V37.16H31.6499V23.552H45.5819V21.932H31.6499Z"
      fill="inherit"
    />
  </svg>
);
export default SvgMaterialityAssessment;
