import React, { createContext, useContext } from 'react';
import { useLocation, useMatch } from 'react-router-dom';

import { GET_USER_LOGIN_DETAILS } from 'src/services/graphql/queries';
import {
  Company,
  CompanyStatus,
  User,
  UserAccountStatus,
  UserRole,
} from 'src/modules/common/types';
import useEnhancedQuery from 'src/modules/common/application/use-enhanced-query';
import { UserContextInterface } from 'src/modules/common/types/user-context.interface';
import FirebaseContext from './FirebaseContext';
import useNumberFormat from '../use-number-format';

const UserContext = createContext<UserContextInterface>({
  hasCompleteProfile: false,
  user: undefined,
  company: undefined,
  loadingUserData: true,
  hasCompanyOnboarded: false,
  isAdmin: false,
  refetch: () => undefined,
});

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { authenticated } = useContext(FirebaseContext);

  const location = useLocation();
  const isRegisterPage = location.pathname === '/register' || location.pathname === '/verify-email';
  const fetchPolicy = authenticated && !isRegisterPage ? 'cache-and-network' : 'cache-only';
  const shareablePath =
    '/shareable' ||
    '/shareable/:collectOverviewId' ||
    '/shareable/:userId/:year/facilities/:facilityId/categories/:categoryId' ||
    '/shareable/:userId/:year/facilities/:facilityId/categories/:categoryId/import';

  const isShareablePaths = useMatch(shareablePath);

  const {
    data,
    loading: loadingUserData,
    refetch,
  } = useEnhancedQuery(GET_USER_LOGIN_DETAILS, {
    fetchPolicy,
    skip: !!isShareablePaths,
  });

  const { updateNumberFormat } = useNumberFormat();

  if (data && data.user) updateNumberFormat((data.user as User).numberFormat);

  const hasCompleteProfile = (data?.user as User)?.status === UserAccountStatus.USER_INFO_COMPLETED;

  const hasCompanyOnboarded =
    (data?.user as User)?.role !== UserRole.ADMIN ||
    ((data?.user as User)?.role === UserRole.ADMIN &&
      (data?.company as Company)?.status === CompanyStatus.COMPANY_ACTIVE);

  const isAdmin = (data?.user as User)?.role === UserRole.ADMIN;

  return (
    <UserContext.Provider
      value={{
        hasCompleteProfile,
        user: data?.user as User,
        company: data?.company as Company,
        loadingUserData,
        refetch,
        hasCompanyOnboarded,
        isAdmin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider };

export default UserContext;
