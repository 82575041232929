import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import { Field, FieldArray, FieldArrayRenderProps, Form, Formik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';

import { Sidebar } from 'src/modules/common/components/Base';
import Button from 'src/modules/common/components/Button/Button';
import {
  RequestMaterialityIdentificationAssessmentInput,
  MaterialityAssessmentTopic,
  RequestMaterialityEvaluationAssessmentInput,
} from 'src/modules/common/types';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import { getInputLabelStyles } from 'src/modules/settings/components/ManageTeamUserForm/utils';
import {
  getCreateRequestValidationSchema,
  getStakeholderEvaluationFormValidationSchema,
} from 'src/modules/common/application/materiality-assessment.schema';
import { SORTED_STAKEHOLDERS_POSITIONS } from 'src/modules/common/types/constants/sorted-enums';
import { getErrorLabelStyles } from 'src/modules/common/application/form-styles';
import theme from 'src/styles/theme';
import useMaterialityAssessmentRequest from 'src/modules/materialityAssessment/application/use-materiality-assessment-request';
import { formatTimeToEarlyMorning } from 'src/modules/common/application/date';

import useMaterialityAssessment from '../../application/use-materiality-assessment';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  year: number;
  isPreSelection: boolean;
  preSelectedTopics?: MaterialityAssessmentTopic[];
}
const CreateMARequestForm = ({
  isOpen,
  onClose,
  year,
  isPreSelection,
  preSelectedTopics,
}: Props) => {
  const { t } = useTranslation();
  const {
    requestMaterialityIdentificationAssessment,
    requestMaterialityEvaluationAssessment,
    evaluationLoading,
    loading,
  } = useMaterialityAssessmentRequest({
    year,
    onComplete: () => {
      onClose();
    },
  });

  const [stakeholderTopicIds, setStakeholderTopicIds] = useState<string[]>([]);

  const { getEvaluationRequestTopicIdsByStakeholderEmail } = useMaterialityAssessment(year);

  const transformedTopics = preSelectedTopics?.map(materialityAssessmentTopic => {
    return {
      id: materialityAssessmentTopic.id,
      title: materialityAssessmentTopic.materialTopic.title,
    };
  });

  const title = t('modules.materialityAssessment.createRequest.title');

  const createRequestSchema = isPreSelection
    ? getCreateRequestValidationSchema(t)
    : getStakeholderEvaluationFormValidationSchema(t);

  const initialValues = isPreSelection
    ? createRequestSchema.getDefault()
    : {
        firstName: '',
        lastName: '',
        email: '',
        position: '',
        dueDate: null,
        evaluationTopics: [{ id: '', title: '' }],
      };

  const handleEvaluationOnSubmit = (values: any) => {
    //needs to be done because formik only works if array is initialized with one empty element
    values.evaluationTopics.shift();
    // TODO check if possible to send materialTopicIds instead of materialAssessmentIds
    const evaluationTopicIds: string[] = values.evaluationTopics.map((t: { id: string }) => t.id);

    const newRequest = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      position: values.position,
      dueDate: values.dueDate,
      materialAssessmentTopicIds: evaluationTopicIds,
      year: year,
    } as RequestMaterialityEvaluationAssessmentInput;
    requestMaterialityEvaluationAssessment({ variables: { input: newRequest } });
  };
  const handleOnSubmit = (values: any) => {
    const newRequest = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      position: values.position,
      dueDate: values.dueDate,
      year: year,
    } as RequestMaterialityIdentificationAssessmentInput;
    requestMaterialityIdentificationAssessment({
      variables: {
        input: newRequest,
      },
    });
  };

  const handleTopicPreSelectionCheck = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    setStakeholderTopicIds([]);
    const email = event.target.value;
    const materialAssessmentTopicIds: string[] =
      getEvaluationRequestTopicIdsByStakeholderEmail(email);
    if (materialAssessmentTopicIds!.length > 0) {
      setStakeholderTopicIds(materialAssessmentTopicIds);
    }
  };

  return (
    <Sidebar isOpen={isOpen} onClose={onClose} title={title}>
      <Box
        sx={{
          padding: 3,
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'scroll',
          width: '460px',
        }}
      >
        <Formik
          initialValues={initialValues}
          validateOnBlur={false}
          validationSchema={createRequestSchema}
          enableReinitialize={true}
          onSubmit={isPreSelection ? handleOnSubmit : handleEvaluationOnSubmit}
          onReset={values => {
            values = initialValues;
          }}
        >
          {({ submitForm, handleChange, setFieldValue, errors, values }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} marginBottom={2}>
                  <FormControl fullWidth>
                    <InputLabel shrink required htmlFor="firstName" sx={getInputLabelStyles}>
                      {t('modules.materialityAssessment.createRequest.firstName')}
                    </InputLabel>
                    <TextField
                      inputProps={{ form: { autoComplete: 'off' } }}
                      fullWidth
                      name="firstName"
                      id="firstName"
                      variant="outlined"
                      onChange={handleChange}
                      value={values.firstName}
                      placeholder={t(
                        'modules.materialityAssessment.createRequest.placeholder.firstName'
                      )}
                    />
                    {errors.firstName && (
                      <FormHelperText sx={getErrorLabelStyles}>{errors.firstName}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} marginBottom={2}>
                  <FormControl fullWidth>
                    <InputLabel shrink required htmlFor="lastName" sx={getInputLabelStyles}>
                      {t('modules.materialityAssessment.createRequest.lastName')}
                    </InputLabel>
                    <TextField
                      inputProps={{ form: { autoComplete: 'off' } }}
                      fullWidth
                      name="lastName"
                      id="lastName"
                      variant="outlined"
                      onChange={handleChange}
                      value={values.lastName}
                      placeholder={t(
                        'modules.materialityAssessment.createRequest.placeholder.lastName'
                      )}
                    />
                    {errors.lastName && (
                      <FormHelperText sx={getErrorLabelStyles}>{errors.lastName}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} marginBottom={2}>
                  <FormControl fullWidth>
                    <InputLabel shrink required htmlFor="email" sx={getInputLabelStyles}>
                      {t('modules.materialityAssessment.createRequest.email')}
                    </InputLabel>
                    <TextField
                      inputProps={{ form: { autoComplete: 'off' } }}
                      fullWidth
                      name="email"
                      id="email"
                      variant="outlined"
                      onChange={handleChange}
                      value={values.email}
                      placeholder={t(
                        'modules.materialityAssessment.createRequest.placeholder.email'
                      )}
                      onBlur={e => handleTopicPreSelectionCheck(e)}
                    />
                    {errors.email && (
                      <FormHelperText sx={getErrorLabelStyles}>{errors.email}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} marginBottom={2}>
                  <FormControl fullWidth>
                    <InputLabel shrink required htmlFor="position" sx={getInputLabelStyles}>
                      {t('modules.materialityAssessment.createRequest.position')}
                    </InputLabel>
                    <Select
                      id="position"
                      name="position"
                      value={values.position}
                      onChange={handleChange}
                      defaultValue={t(
                        'modules.materialityAssessment.createRequest.placeholder.position'
                      )}
                    >
                      {SORTED_STAKEHOLDERS_POSITIONS.map((position, key) => {
                        return (
                          <MenuItem key={key} value={position}>
                            {t(
                              'shared.types.userPosition.' + removeUnderscoreToCamelCase(position)
                            )}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {errors.position && (
                      <FormHelperText sx={getErrorLabelStyles}>{errors.position}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} marginBottom={2}>
                  <FormControl fullWidth>
                    <InputLabel shrink required htmlFor="dueDate" sx={getInputLabelStyles}>
                      {t('modules.materialityAssessment.createRequest.dueDate')}
                    </InputLabel>
                    <DatePicker
                      value={values.dueDate}
                      inputFormat="DD/MM/YYYY"
                      onChange={date => {
                        const updatedTime = formatTimeToEarlyMorning(date);
                        setFieldValue('dueDate', updatedTime);
                      }}
                      renderInput={params => {
                        return (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: t(
                                'modules.materialityAssessment.createRequest.placeholder.dueDate'
                              ),
                            }}
                          />
                        );
                      }}
                    />
                    {errors.dueDate && (
                      <FormHelperText sx={getErrorLabelStyles}>{errors.dueDate}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {!isPreSelection && (
                  <Grid item xs={12} marginBottom={2}>
                    <FormControl fullWidth>
                      <InputLabel
                        shrink
                        required
                        htmlFor="evaluationTopics"
                        sx={getInputLabelStyles}
                      >
                        {t('modules.materialityAssessment.createRequest.evaluationTopics')}
                      </InputLabel>
                      <FieldArray
                        name="evaluationTopics"
                        render={(arrayHelpers: FieldArrayRenderProps) => (
                          <>
                            {transformedTopics?.map((topic, index) => (
                              <Typography
                                key={index}
                                variant="body2"
                                color={theme.palette.colors.lightGrey}
                                sx={{ ml: 1.5 }}
                              >
                                <Field
                                  type="checkbox"
                                  name={topic.id}
                                  id={topic.id}
                                  value={topic.id}
                                  as={FormControlLabel}
                                  control={<Checkbox size="small" />}
                                  label={t(
                                    `shared.types.materialTopic.${removeUnderscoreToCamelCase(
                                      topic.title
                                    )}`
                                  )}
                                  checked={
                                    values.evaluationTopics.some(t => t.id === topic.id) ||
                                    stakeholderTopicIds.includes(topic.id)
                                  }
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e.target.checked) {
                                      arrayHelpers.push(topic);
                                    } else {
                                      const idx = values.evaluationTopics
                                        .map(function (t) {
                                          return t.id;
                                        })
                                        .indexOf(topic.id);
                                      arrayHelpers.remove(idx);
                                    }
                                  }}
                                  disabled={stakeholderTopicIds.includes(topic.id)}
                                />
                              </Typography>
                            ))}
                          </>
                        )}
                      />
                      {errors.evaluationTopics && (
                        <FormHelperText sx={getErrorLabelStyles}>
                          {t('fieldWarnings.maRequestFormTopicsWarning')}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Box
                sx={{
                  width: '100%',
                  position: 'absolute',
                  bottom: 0,
                  padding: '24px',
                  borderTop: '1px solid',
                  borderColor: 'background.grey',
                  bgcolor: '#fff',
                  zIndex: 2,
                }}
              >
                <Button
                  size="small"
                  fullWidth
                  type="submit"
                  variant="contained"
                  loading={isPreSelection ? loading : evaluationLoading}
                  sx={{ width: '50%' }}
                  onClick={submitForm}
                >
                  {t('modules.materialityAssessment.createRequest.cta')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Sidebar>
  );
};

export default CreateMARequestForm;
