import dayjs from 'dayjs';

export const getProperFormatedDate = (date: string, format = 'DD-MMM-YYYY') =>
  dayjs(date).format(format);

export const formatTimeEnglish = (date: Date) => {
  let hours = date.getHours();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const strTime = hours + ':' + String(date.getMinutes()).padStart(2, '0') + ' ' + ampm;
  return strTime;
};

export const formatTimeGerman = (time: Date) => {
  return time.getHours() + ':' + String(time.getMinutes()).padStart(2, '0');
};

export const formatTimeToEarlyMorning = (time: Date | string | null) => {
  if (!time) {
    return null;
  }
  const inputTime = dayjs(time);

  if (!inputTime.isValid()) {
    return null;
  }
  const updatedTime = inputTime.startOf('day').add(9, 'h').toDate();
  return updatedTime;
};
