import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ApexOptions } from 'apexcharts';

import theme from 'src/styles/theme';
import { FacilityGender } from 'src/modules/common/types';
import EnhancedChart from 'src/modules/common/components/EnhancedChart';

const LocationGenderStructure = ({ data }: { data: FacilityGender[] }) => {
  const { t } = useTranslation();

  const facilityNames = data.map(item => item.facility.name);
  const femaleEmployees = data.map(item => (item.femaleEmployees ? item.femaleEmployees : 0));
  const maleEmployees = data.map(item => (item.maleEmployees ? item.maleEmployees : 0));

  const chartOptions: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: [theme.palette.colors.pearlAqua, theme.palette.colors.cascade],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '35%',
      },
    },
    stroke: {
      colors: ['transparent'],
      width: 2,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: facilityNames,
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
    },
  };

  const chartSeries: ApexAxisChartSeries = [
    {
      name: t('modules.analysis.locationGenderStructure.femaleEmployees'),
      data: femaleEmployees,
    },
    {
      name: t('modules.analysis.locationGenderStructure.maleEmployees'),
      data: maleEmployees,
    },
  ];

  return (
    <EnhancedChart
      noData={data.length === 0}
      cardProps={{
        title: t('modules.analysis.locationGenderStructure.title'),
        infoText: t('modules.analysis.locationGenderStructure.info'),
      }}
      chartProps={{
        height: '90%',
        options: chartOptions,
        series: chartSeries,
        type: 'bar',
      }}
    />
  );
};

export default LocationGenderStructure;
