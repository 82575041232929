import { GET_NEXT_CALCULATION_TIME } from 'src/services/graphql/queries';
import useEnhancedQuery from 'src/modules/common/application/use-enhanced-query';
import useLanguage from './use-language';
import { LanguageCode } from 'src/modules/common/types';

import { formatTimeEnglish, formatTimeGerman } from './date';

const useCalculationStatus = () => {
  const { loading, data, error } = useEnhancedQuery<{ getNextCalculationTime: number }>(
    GET_NEXT_CALCULATION_TIME,
    {
      fetchPolicy: 'network-only',
    }
  );
  const { language } = useLanguage();

  let nextCalculationTime = undefined;

  if (
    data !== undefined &&
    data.getNextCalculationTime !== undefined &&
    data.getNextCalculationTime !== 0
  ) {
    const time = new Date(data.getNextCalculationTime);
    nextCalculationTime =
      language === LanguageCode.DE ? formatTimeGerman(time) : formatTimeEnglish(time);
  }

  return {
    nextCalculationTime,
    loading,
    error,
  };
};

export default useCalculationStatus;
