import React from 'react';
import { TableHead, TableRow, Checkbox, Box, TableSortLabel, SortDirection } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TransformedCollectOverview } from 'src/modules/common/types/collect';
import { SortingOrder, visuallyHidden } from 'src/modules/common/types';
import { IntercomDataAttribute } from 'src/modules/common/types/intercom';
import { getIntercomDataAttribute } from 'src/modules/common/application/intercom';

import { StyledTableCell } from '../CollectEsgAdminTable/styles';

interface HeadCell {
  disablePadding: boolean;
  id: keyof TransformedCollectOverview;
  numeric: boolean;
  disableIntercomDataAtrribute: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'facilityName',
    numeric: false,
    disablePadding: true,
    disableIntercomDataAtrribute: true,
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    disableIntercomDataAtrribute: true,
  },
  {
    id: 'assignedTo',
    numeric: false,
    disablePadding: false,
    disableIntercomDataAtrribute: true,
  },
  {
    id: 'progress',
    numeric: true,
    disablePadding: false,
    disableIntercomDataAtrribute: false,
  },
  {
    id: 'dueDate',
    numeric: false,
    disablePadding: false,
    disableIntercomDataAtrribute: true,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    disableIntercomDataAtrribute: true,
  },
];

interface CollectEsgAdminTableHeaderProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof TransformedCollectOverview
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: SortingOrder;
  orderBy: string;
  rowCount: number;
  checkboxDisabled: boolean;
}

const CollectEsgAdminTableHeader = (props: CollectEsgAdminTableHeaderProps) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    checkboxDisabled,
  } = props;
  const { t } = useTranslation();
  const createSortHandler =
    (property: keyof TransformedCollectOverview) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell align="center" sx={{ width: '70px' }}>
          <Checkbox
            color="primary"
            disabled={checkboxDisabled}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </StyledTableCell>
        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? (order.toLowerCase() as SortDirection) : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={
                orderBy === headCell.id ? (order.toLowerCase() as SortDirection) || 'asc' : 'asc'
              }
              onClick={createSortHandler(headCell.id)}
              data-intercom-target={getIntercomDataAttribute(
                IntercomDataAttribute.collectTableProgressHeader,
                headCell.disableIntercomDataAtrribute
              )}
            >
              {t('modules.dataManagement.collectEsgAdminTable.tableHeader.' + headCell.id)}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === SortingOrder.DESC ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        <StyledTableCell sx={{ width: '70px' }} />
      </TableRow>
    </TableHead>
  );
};

export default CollectEsgAdminTableHeader;
