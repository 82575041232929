import * as Yup from 'yup';
import { TFunction } from 'react-i18next';

export const getRegistrationSchema = (t: TFunction) => {
  return Yup.object().shape({
    email: Yup.string()
      .default('')
      .email(t('messages.warning.shared.emailFormat'))
      .required(t('messages.warning.shared.emailRequired')),
    password: Yup.string()
      .default('')
      .required(t('messages.warning.shared.passwordRequired'))
      .min(6, t('messages.warning.shared.passwordCharacter')),
    termsAccepted: Yup.bool()
      .default(false)
      .oneOf([true], t('messages.warning.shared.termsRequired'))
      .required(t('messages.warning.shared.termsRequired')),
  });
};

export const getRegisterWithEmailSchema = (t: TFunction) => {
  return Yup.object().shape({
    email: Yup.string()
      .default('')
      .email(t('messages.warning.shared.emailFormat'))
      .required(t('messages.warning.shared.emailRequired')),
  });
};

export const getSetPasswordSchema = (t: TFunction) => {
  return Yup.object().shape({
    email: Yup.string()
      .default('')
      .email(t('messages.warning.shared.emailFormat'))
      .required(t('messages.warning.shared.emailRequired')),
    password: Yup.string()
      .default('')
      .required(t('messages.warning.shared.passwordRequired'))
      .min(6, t('messages.warning.shared.passwordCharacter')),
    passwordRepeat: Yup.string()
      .default('')
      .required(t('messages.warning.shared.passwordRequired'))
      .min(6, t('messages.warning.shared.passwordCharacter')),
  });
};

export const getLoginSchema = (t: TFunction) => {
  return Yup.object().shape({
    email: Yup.string()
      .default('')
      .email(t('messages.warning.shared.emailFormat'))
      .required(t('messages.warning.shared.emailRequired')),
    password: Yup.string().default('').required(t('messages.warning.shared.passwordRequired')),
    rememberUser: Yup.boolean().default(false),
  });
};

export const getPasswordResetSchema = (t: TFunction) => {
  return Yup.object().shape({
    oldPassword: Yup.string()
      .min(6, t('messages.warning.shared.passwordCharacter'))
      .required(t('messages.warning.shared.passwordRequired'))
      .default(''),
    newPassword: Yup.string()
      .min(6, t('messages.warning.shared.passwordCharacter'))
      .required(t('messages.warning.shared.passwordRequired'))
      .default(''),
  });
};

export const getPasswordForgotSchema = (t: TFunction) => {
  return Yup.object().shape({
    email: Yup.string()
      .email(t('messages.warning.shared.emailFormat'))
      .required(t('messages.warning.shared.emailRequired')),
  });
};
