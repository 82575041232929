import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const getDueDateValidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    dueDate: Yup.date()
      .default(null)
      .nullable()
      .test('is-date', t('messages.warning.shared.dateFormat'), value => {
        if (!value) return true;
        return !isNaN(new Date(value).getTime());
      }),
  });
};
