import { Input, TableCell, TableRow, TablePagination } from '@mui/material';
import { styled } from '@mui/system';

export const StyledTableRow = styled(TableRow)({
  fontSize: '0.875rem',
  cursor: 'pointer',
  backgroundColor: '#fff',
  '&.Mui-selected': {
    '&:hover': {
      backgroundColor: '#fff',
    },
    backgroundColor: '#fff',
  },
});

export const StyledTableCell = styled(TableCell)({
  borderRadius: 0,
  fontSize: '0.875rem !important',
  padding: 0,
  borderBottom: 'none',
});

export const StyledTablePagination = styled(TablePagination)({
  '.MuiInputBase-root': {
    backgroundColor: 'transparent',
  },
  '.MuiTablePagination-select': {
    fontSize: '0.875rem',
  },
  borderBottom: 'none',
});

export const StyledTextField = styled(Input)({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
});
