import { useRef, useReducer, useCallback } from 'react';

import { createErrorBoundary, UseErrorBoundaryWrapper } from './create-error-boundary';

export interface ErrorState {
  error: any | null;
}

export interface UseErrorBoundaryState extends ErrorState {
  ErrorBoundary: UseErrorBoundaryWrapper;
  reset: () => void;
}

interface StateAction {
  type: 'catch' | 'reset';
  error?: any | null;
}

type UseErrorBoundaryReducer = (state: ErrorState, action: StateAction) => ErrorState;

const useErrorBoundaryReducer: UseErrorBoundaryReducer = (state, action) => {
  switch (action.type) {
    case 'catch':
      return {
        error: action.error,
      };
    case 'reset':
      return {
        error: null,
      };
    default:
      return state;
  }
};

function useErrorBoundary(): UseErrorBoundaryState {
  const [state, dispatch] = useReducer<UseErrorBoundaryReducer>(useErrorBoundaryReducer, {
    error: null,
  });
  const errorBoundaryWrapperRef = useRef<UseErrorBoundaryWrapper | null>(null);

  function createWrappedErrorBoundary() {
    return createErrorBoundary();
  }

  function getWrappedErrorBoundary() {
    const errorBoundaryWrapper = errorBoundaryWrapperRef.current;

    if (errorBoundaryWrapper !== null) {
      return errorBoundaryWrapper;
    }

    errorBoundaryWrapperRef.current = createWrappedErrorBoundary();

    return errorBoundaryWrapperRef.current;
  }

  const reset = useCallback(() => {
    errorBoundaryWrapperRef.current = createWrappedErrorBoundary();
    dispatch({ type: 'reset' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ErrorBoundary: getWrappedErrorBoundary(),
    error: state.error,
    reset,
  };
}

export default useErrorBoundary;
