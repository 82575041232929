import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { Tab } from 'src/modules/common/types';
import { useMessages, useYears } from 'src/modules/common/application';
import { CalculationStatus, Loader, TabBar, YearSelect } from 'src/modules/common/components';

import EnvironmentTab from '../EnvironmentTab/EnvironmentTab';
import SocialTab from '../SocialTab/SocialTab';

const Analysis = () => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();

  const { data, loading, error, selectedYear, handleSetSelectedYear, sortedYears } = useYears();

  if (loading || !data) return <Loader />;
  if (error) return <>{setErrorMessage(t('messages.generic.error'))}</>;

  const years = sortedYears ? sortedYears : [];

  const tabsArray: Tab[] = [
    {
      input: <EnvironmentTab year={selectedYear} />,
      label: t('modules.analysis.environmentTab'),
      index: 0,
      id: 'simpletab-two',
    },
    {
      input: <SocialTab year={selectedYear} />,
      label: t('modules.analysis.socialTab'),
      index: 1,
      id: 'simpletab-three',
    },
  ];
  return (
    <TabBar
      content={tabsArray}
      SideComponent={() => (
        <Box
          sx={{
            display: 'flex',
            right: 0,
          }}
        >
          <CalculationStatus />
          <YearSelect years={years} year={selectedYear} setYear={handleSetSelectedYear} />
        </Box>
      )}
      sideComponentStyles={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    />
  );
};

export default Analysis;
