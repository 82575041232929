import React from 'react';
import {
  Box,
  Dialog,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import Button from 'src/modules/common/components/Button/Button';
import { getDueDateValidationSchema } from 'src/modules/data-management/application/due-date-schema';
import {
  getErrorLabelStyles,
  getInputLabelStyles,
} from 'src/modules/common/application/form-styles';
import { formatTimeToEarlyMorning } from 'src/modules/common/application/date';

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onSave: (dueDate: Date | null | undefined) => void;
  initialDate: Date | null;
}

export const DueDateDialog = ({ isOpen, isLoading, onCancel, onSave, initialDate }: Props) => {
  const { t } = useTranslation();

  const dueDateschema = getDueDateValidationSchema(t);
  const initialValues = {
    dueDate: initialDate,
  };

  const handleOnSubmit = (values: { dueDate: Date | null | undefined }) => {
    onSave(values.dueDate ? values.dueDate : null);
  };

  return (
    <>
      <Dialog open={isOpen}>
        <Box
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: '25px',
            minWidth: '600px',
            background: '#fff',
          }}
        >
          <Typography fontSize={'1.5rem !important'} fontWeight={600}>
            {t('modules.dataManagement.dueDateDialog.title')}
          </Typography>
          <Formik
            initialValues={initialValues}
            validateOnBlur={false}
            validationSchema={dueDateschema}
            enableReinitialize={true}
            onSubmit={handleOnSubmit}
            onReset={values => {
              values = initialValues;
            }}
          >
            {({ submitForm, setFieldValue, errors, values }) => (
              <Form>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel shrink required htmlFor="dueDate" sx={getInputLabelStyles}>
                    {t('modules.dataManagement.dueDateDialog.label')}
                  </InputLabel>
                  <DatePicker
                    value={values.dueDate}
                    inputFormat="DD/MM/YYYY"
                    onChange={date => {
                      const updatedTime = formatTimeToEarlyMorning(date);
                      setFieldValue('dueDate', updatedTime);
                    }}
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: t('modules.dataManagement.dueDateDialog.placeholder'),
                          }}
                        />
                      );
                    }}
                  />
                  {errors.dueDate && (
                    <FormHelperText sx={getErrorLabelStyles}>{errors.dueDate}</FormHelperText>
                  )}
                </FormControl>
                <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
                  <Button variant="outlined" size="small" onClick={onCancel}>
                    {t('modules.dataManagement.dueDateDialog.cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    loading={isLoading}
                    onClick={submitForm}
                    sx={{ ml: 2 }}
                  >
                    {t('modules.dataManagement.dueDateDialog.save')}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Dialog>
    </>
  );
};
