import { useNavigate } from 'react-router-dom';
import { ACCEPT_TERMS_AND_CONDOTIONS } from 'src/services/graphql/mutations';

import useEnhancedMutation from './use-enhanced-mutation';
import useLogout from './use-logout';
import { useState } from 'react';

const useTermsAndConditions = () => {
  const [error, setError] = useState<Error | null>(null);

  const navigate = useNavigate();
  const { logout } = useLogout(false);

  const [acceptTermsAndConditions, { loading }] = useEnhancedMutation(ACCEPT_TERMS_AND_CONDOTIONS, {
    onError: async error => {
      setError(error);
      await logout();
    },
    onCompleted: () => {
      setError(null);
      navigate('/');
    },
    fetchPolicy: 'no-cache',
  });

  return {
    acceptTermsAndConditions,
    loading,
    error,
  };
};

export default useTermsAndConditions;
