import React from 'react';
import { Box, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import theme from 'src/styles/theme';

export const MAPreSelectionIcon = ({
  title,
  tooltipText,
}: {
  title: string;
  tooltipText: string;
}) => {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      {title}
      <Tooltip title={tooltipText}>
        <InfoOutlinedIcon
          fontSize="inherit"
          sx={{ color: theme.palette.text.secondary, cursor: 'pointer', ml: 0.5, mt: -1 }}
        />
      </Tooltip>
    </Box>
  );
};
