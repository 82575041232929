import React, { createElement } from 'react';

import { ErrorBoundary, ErrorBoundaryProps } from '@sentry/react';

export type UseErrorBoundaryWrapper = (
  props: Omit<ErrorBoundaryProps, 'onDidCatch'>
) => React.ReactElement;

export function createErrorBoundary(): UseErrorBoundaryWrapper {
  return function UseErrorBoundaryWrapper(props) {
    // eslint-disable-next-line react/no-children-prop
    return createElement<ErrorBoundaryProps>(ErrorBoundary, {
      children: props.children,
      showDialog: props.showDialog,
      fallback: props.fallback,
    });
  };
}
