import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableBody, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TableHeader from 'src/modules/common/components/Table/TableHeader';
import { User } from 'src/modules/common/types';
import { MATopicsTableHeaders } from 'src/modules/materialityAssessment/application/materiality-assessment';

import { MaterialityTopicsTableRow } from './MaterialityTopicsTableRow';
import { sortMaterialTopics } from '../../application/utils';
import { MaterialityAssessmentTopicWithDisabled } from '../../types/materialEvaluation';

interface MaterialityTopicsTableProps {
  topics: MaterialityAssessmentTopicWithDisabled[];
  filterStackholdersByIds: (ids: string[]) => User[];
  year: number;
}

export const MaterialityTopicsTable = ({
  topics,
  filterStackholdersByIds,
  year,
}: MaterialityTopicsTableProps) => {
  const { t } = useTranslation();
  const [sortedTopics, setSortedTopics] = useState<MaterialityAssessmentTopicWithDisabled[]>(
    sortMaterialTopics(topics)
  );
  const headers = MATopicsTableHeaders.map(t) as string[];

  useEffect(() => {
    setSortedTopics(sortMaterialTopics(topics));
  }, [topics]);

  return (
    <Box>
      <TableContainer sx={theme => ({ border: theme.custom.border })}>
        <Table
          sx={{
            tableLayout: 'auto',
            width: '100%',
          }}
        >
          <TableHeader headers={headers} showLastHeader={false} />
          <TableBody>
            {sortedTopics?.map((row, index) => (
              <MaterialityTopicsTableRow
                data={row}
                key={index}
                filterStackholdersByIds={filterStackholdersByIds}
                year={year}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
