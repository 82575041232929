import { Input, TableCell } from '@mui/material';
import { styled } from '@mui/system';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderRadius: 0,
  fontSize: '0.875rem !important',
  padding: 0,
  borderBottom: 'none',
}));

export const StyledTextField = styled(Input)(({ theme }) => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
}));
