import React from 'react';
import { Box, Typography, SvgIcon } from '@mui/material';
import PositionIcon from 'src/modules/common/components/icons/settings/Position.icon';
import CompanyIcon from 'src/modules/common/components/icons/settings/Company.icon';
import Avatar from 'src/modules/common/components/Avatar/Avatar';
import { User } from 'src/modules/common/types';
import { getUserLabel } from 'src/modules/common/application/user';

interface Props {
  dataField1: string;
  dataField2: string;
  dataField3: string;
  dataField4: string;
  user?: User;
}

const DataInfo = ({ dataField1, dataField2, dataField3, dataField4, user }: Props) => {
  return (
    <Box
      sx={{
        height: '200px',
        backgroundColor: 'background.white',
        display: 'flex',
        alignItems: 'flex-start',
        padding: 1,
        color: 'text.secondary',
        border: '1px solid',
        borderColor: 'background.grey',
        borderRadius: '10px',
        mb: 5,
        overflow: 'auto',
      }}
    >
      <Box>
        <Avatar
          input={user ? getUserLabel(user) : `${dataField1} ${dataField2}`}
          styles={{
            position: 'relative',
            marginLeft: 7,
            marginTop: 2.5,
            width: 140,
            height: 140,
            borderColor: 'white',
            border: '4px solid white',
            fontSize: '80px',
          }}
        />
      </Box>

      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pl: 5,
        }}
      >
        <Typography variant="h4" sx={{ color: 'text.primary', mb: 1 }}>
          {`${dataField1} ${dataField2}`}
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
          <SvgIcon component={PositionIcon} inheritViewBox sx={{ fontSize: 24 }} />

          <Typography variant="body2" sx={{ ml: 1, textTransform: 'capitalize' }}>
            {`${dataField3}`.toLowerCase()}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <SvgIcon component={CompanyIcon} inheritViewBox sx={{ fontSize: 24 }} />
          <Typography sx={{ ml: 1 }} variant="body2">{`${dataField4}`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DataInfo;
