import { gql } from '@apollo/client';
import { USER_FOR_SUPER_FRAGMENT, USER_FRAGMENT, USER_SETTING_FRAGMENT } from '../fragments';

export const UPDATE_USER_BY_SUPER = gql`
  mutation UpdateUserBySuper($input: UpdateUserBySuper!) {
    updateUserBySuper(input: $input) {
      ...userValues
    }
  }
  ${USER_FOR_SUPER_FRAGMENT}
`;

export const UPDATE_USER_BY_ADMIN = gql`
  mutation UpdateUserByAdmin($input: UpdateUserByAdminInput!) {
    updateUserByAdmin(input: $input) {
      ...userValues
    }
  }
  ${USER_FRAGMENT}
`;

export const UPDATE_USER_SETTING = gql`
  mutation UpdateUserSetting($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...userSettingValues
    }
  }
  ${USER_SETTING_FRAGMENT}
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: UUID!) {
    deleteUser(id: $id)
  }
`;

export const STORE_USER_EMAIL = gql`
  mutation StoreUserEmail($email: String!) {
    storeUserEmail(email: $email) @client
  }
`;

export const PASSWORD_UPDATED = gql`
  mutation PasswordUpdated {
    passwordUpdated {
      ...userValues
    }
  }
  ${USER_FRAGMENT}
`;

export const INVITE_USERS = gql`
  mutation InviteUsers($input: [InviteUserInput!]!) {
    inviteUsers(input: $input) {
      ... on InviteUserSuccess {
        users {
          ...userValues
        }
      }
      ... on InviteUserError {
        error
        existingEmails
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const ACTIVATE_USER = gql`
  mutation ActivateUser($email: String!) {
    activateUser(email: $email)
  }
`;

export const RESEND_VERIFY_EMAIL = gql`
  mutation ResendVerifyEmail($email: String!) {
    resendVerifyEmail(email: $email)
  }
`;

export const RESEND_INVITATION_EMAIL = gql`
  mutation ResendInviteEmail($email: String!) {
    resendInviteEmail(email: $email)
  }
`;

export const ACCEPT_TERMS_AND_CONDOTIONS = gql`
  mutation AcceptTermsAndConditions {
    acceptTermsAndConditions {
      ...userValues
    }
  }
  ${USER_FRAGMENT}
`;

export const SET_SELECTED_YEAR = gql`
  mutation SetSelectedYear($year: Int!) {
    setSelectedYear(year: $year) {
      id
      firstName
      lastName
      selectedYear
    }
  }
`;
