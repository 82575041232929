import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';

import useCollectYearsByExternal from 'src/modules/data-management/application/use-collect-years-by-external';
import useTokenLogin from 'src/modules/common/application/use-token-login';

import { Loader, PageContainer } from 'src/modules/common/components';
import { PublicErrorCard, PublicFooter } from 'src/modules/data-management/components';
import PublicExpiredLinkCard from 'src/modules/data-management/components/PublicErrorCard/PublicExpiredLinkCard';
import { AppLogoIcon } from 'src/modules/common/components/icons';
import PublicCollectOverview from 'src/modules/data-management/components/PublicCollectOverview/PublicCollectOverview';

const PublicOverview = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const email = searchParams.get('email');

  const { accessToken, loading: accessTokenLoading, isTokenExpired } = useTokenLogin(token);
  const { years, collectionYearsLoading, error } = useCollectYearsByExternal(accessToken);

  if (isTokenExpired && email) return <PublicExpiredLinkCard email={email} />;
  if (accessTokenLoading || collectionYearsLoading || !accessToken || !years || !email || !token)
    return <Loader />;
  if (error || (!collectionYearsLoading && years.length === 0)) return <PublicErrorCard />;

  return (
    <PageContainer meta={'Mavue'}>
      <Box sx={{ margin: 5 }}>
        <Box sx={{ mb: 4 }}>
          <AppLogoIcon />
        </Box>
        <PublicCollectOverview
          years={years}
          token={token}
          accessToken={accessToken}
          email={email}
        />
      </Box>
      <PublicFooter />
    </PageContainer>
  );
};

export default PublicOverview;
