import React from 'react';
import { useTheme } from '@mui/material';

const EyeOutlinedIcon = (props: any) => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M9.5998 6.1C12.6318 6.1 15.3358 7.804 16.6558 10.5C15.3358 13.196 12.6398 14.9 9.5998 14.9C6.5598 14.9 3.8638 13.196 2.5438 10.5C3.8638 7.804 6.5678 6.1 9.5998 6.1ZM9.5998 4.5C5.5998 4.5 2.1838 6.988 0.799805 10.5C2.1838 14.012 5.5998 16.5 9.5998 16.5C13.5998 16.5 17.0158 14.012 18.3998 10.5C17.0158 6.988 13.5998 4.5 9.5998 4.5ZM9.5998 8.5C10.7038 8.5 11.5998 9.396 11.5998 10.5C11.5998 11.604 10.7038 12.5 9.5998 12.5C8.4958 12.5 7.5998 11.604 7.5998 10.5C7.5998 9.396 8.4958 8.5 9.5998 8.5ZM9.5998 6.9C7.6158 6.9 5.9998 8.516 5.9998 10.5C5.9998 12.484 7.6158 14.1 9.5998 14.1C11.5838 14.1 13.1998 12.484 13.1998 10.5C13.1998 8.516 11.5838 6.9 9.5998 6.9Z"
        fill={theme.palette.colors.grey}
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default EyeOutlinedIcon;
