import {
  ChipColorVariants,
  EsgArea,
  EsgCategoryEnum,
  Unit,
  Status,
  ApprovalStatus,
  ReportingFramework,
  FacilityType,
  CollectOverviewStatus,
  RequestStatus,
} from 'src/modules/common/types';

export const getRandomChipColor = () => {
  const chipKeys = Object.keys(ChipColorVariants).filter(chipKey => chipKey !== 'RED') as Array<
    keyof typeof ChipColorVariants
  >;
  return ChipColorVariants[
    chipKeys[Math.floor(Math.random() * chipKeys.length)] as keyof typeof ChipColorVariants
  ];
};

export const getChipColorForLetter = (text: String) => {
  const chipKeys = Object.keys(ChipColorVariants).filter(chipKey => chipKey !== 'RED') as Array<
    keyof typeof ChipColorVariants
  >;
  const letter = text.charAt(0).toUpperCase();
  const index = (letter.charCodeAt(0) - 65) % chipKeys.length;
  return ChipColorVariants[chipKeys[index] as keyof typeof ChipColorVariants];
};

export const getStatusChipColor = (status: Status) => {
  switch (status) {
    case Status.COMPLETED:
      return ChipColorVariants.LIGHT_GREEN;

    case Status.IN_PROGRESS:
      return ChipColorVariants.LIGHT_YELLOW;

    case Status.NOT_STARTED:
      return ChipColorVariants.ALMOND;
    default:
      return ChipColorVariants.RED;
  }
};

export const getCategoryChipColor = (category: EsgCategoryEnum) => {
  switch (category) {
    case EsgCategoryEnum.VEHICLE_FLEET:
      return ChipColorVariants.ALMOND;

    case EsgCategoryEnum.PURCHASED_ENERGY:
      return ChipColorVariants.LIGHT_GREEN;

    case EsgCategoryEnum.WATER:
      return ChipColorVariants.PURPLE;

    case EsgCategoryEnum.EMPLOYMENT_DIVERSITY:
      return ChipColorVariants.LIGHT_YELLOW;

    case EsgCategoryEnum.AGE_STRUCTURE:
      return ChipColorVariants.ICE_BERG;

    case EsgCategoryEnum.TRAINING:
      return ChipColorVariants.WATER;

    case EsgCategoryEnum.HEALTH_SAFETY:
      return ChipColorVariants.TEALISH_BLUE;

    case EsgCategoryEnum.SELF_PRODUCED_ENERGY:
      return ChipColorVariants.GREENISH_CYAN;

    case EsgCategoryEnum.DISCRIMINATION:
      return ChipColorVariants.GREENISH_YELLOW;

    case EsgCategoryEnum.WASTE:
      return ChipColorVariants.PALE_GREEN;

    case EsgCategoryEnum.RAW_MATERIALS:
      return ChipColorVariants.GHOST;

    case EsgCategoryEnum.BOARD_DIVERSITY:
      return ChipColorVariants.ALMOND;

    case EsgCategoryEnum.FINANCIAL:
      return ChipColorVariants.JAGGED_ICE;

    case EsgCategoryEnum.COMPLIANCE:
      return ChipColorVariants.LIGHT_GREEN;
    default:
      return ChipColorVariants.RED;
  }
};

export const getUnitChipColor = (unit: Unit) => {
  switch (unit) {
    case Unit.KWH:
      return ChipColorVariants.ALMOND;

    case Unit.LITRE:
      return ChipColorVariants.LIGHT_GREEN;

    case Unit.ML:
      return ChipColorVariants.PURPLE;

    case Unit.MWH:
      return ChipColorVariants.LIGHT_YELLOW;

    case Unit.EURO:
      return ChipColorVariants.ICE_BERG;

    case Unit.KG:
      return ChipColorVariants.WATER;

    case Unit.TONNES:
      return ChipColorVariants.TEALISH_BLUE;

    case Unit.HRS:
      return ChipColorVariants.GREENISH_CYAN;

    case Unit.M3:
      return ChipColorVariants.GREENISH_YELLOW;

    case Unit.PERCENTAGE:
      return ChipColorVariants.PALE_GREEN;

    case Unit.GCO2KWH:
      return ChipColorVariants.ICE_BERG;

    default:
      return ChipColorVariants.RED;
  }
};

export const getProgressBarColor = (progress: number) => {
  if (progress >= 0 && progress < 25) {
    return 'error';
  } else if (progress >= 25 && progress <= 75) {
    return 'warning';
  } else if (progress > 75 && progress < 100) {
    return 'primary';
  } else if (progress === 100) {
    return 'success';
  }
  return 'info';
};

export const getESGAreaChipColor = (area: EsgArea) => {
  switch (area) {
    case EsgArea.ENVIRONMENT:
      return ChipColorVariants.LIGHT_GREEN;

    case EsgArea.SOCIAL:
      return ChipColorVariants.LIGHT_YELLOW;

    case EsgArea.GOVERNANCE:
      return ChipColorVariants.WATER;
    default:
      return ChipColorVariants.RED;
  }
};

export const getApprovalStatusChipColor = (status: ApprovalStatus) => {
  switch (status) {
    case ApprovalStatus.OPEN:
      return ChipColorVariants.LIGHT_YELLOW;

    case ApprovalStatus.APPROVED:
      return ChipColorVariants.LIGHT_GREEN;

    case ApprovalStatus.REJECTED:
      return ChipColorVariants.ALMOND;

    default:
      return ChipColorVariants.RED;
  }
};

export const getFrameworkChipColor = (framework: ReportingFramework) => {
  switch (framework) {
    case ReportingFramework.GRI:
      return ChipColorVariants.TEALISH_BLUE;

    case ReportingFramework.DNK:
      return ChipColorVariants.LIGHT_GREEN;

    case ReportingFramework.ESRS:
      return ChipColorVariants.LIGHT_YELLOW;

    default:
      return ChipColorVariants.RED;
  }
};

export const getYearChipColor = (year: number) => {
  switch (year) {
    case 2019:
      return ChipColorVariants.GREENISH_YELLOW;

    case 2020:
      return ChipColorVariants.ALMOND;

    case 2021:
      return ChipColorVariants.GREENISH_CYAN;

    case 2022:
      return ChipColorVariants.WATER;

    case 2023:
      return ChipColorVariants.PURPLE;
    default:
      return ChipColorVariants.RED;
  }
};

export const getFacilityTypeColor = (type: FacilityType) => {
  switch (type) {
    case FacilityType.LOGISTICS:
      return ChipColorVariants.ALMOND;
    case FacilityType.MANUFACTURING:
      return ChipColorVariants.LIGHT_GREEN;
    case FacilityType.SERVICE:
      return ChipColorVariants.PURPLE;
    case FacilityType.OFFICE:
      return ChipColorVariants.LIGHT_YELLOW;
    case FacilityType.WAREHOUSE:
      return ChipColorVariants.WATER;
    default:
      return ChipColorVariants.RED;
  }
};

export const getCollectOverviewStatusChipColor = (type: CollectOverviewStatus) => {
  switch (type) {
    case CollectOverviewStatus.NOT_STARTED:
      return ChipColorVariants.GRAY;
    case CollectOverviewStatus.IN_PROGRESS:
      return ChipColorVariants.LIGHT_YELLOW;
    case CollectOverviewStatus.COMPLETED:
      return ChipColorVariants.LIGHT_GREEN;
    case CollectOverviewStatus.OVERDUE:
      return ChipColorVariants.LIGHT_RED;
    default:
      return ChipColorVariants.RED;
  }
};

export const getRequestStatusChipColor = (type: RequestStatus) => {
  switch (type) {
    case RequestStatus.PENDING:
      return ChipColorVariants.LIGHT_YELLOW;
    case RequestStatus.FINISHED:
      return ChipColorVariants.LIGHT_GREEN;
    case RequestStatus.OVERDUE:
      return ChipColorVariants.ALMOND;
    default:
      return ChipColorVariants.RED;
  }
};
