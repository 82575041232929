import { gql } from '@apollo/client';
import { CORE_ESG_VALUE_FIELDS } from '../fragments/esgValue';

export const GET_ESG_VALUES_BY_OVERVIW_ID = gql`
  ${CORE_ESG_VALUE_FIELDS}
  query EsgValuesByCollectOverviewId($collectOverviewId: String!) {
    esgValuesByCollectOverviewId(collectOverviewId: $collectOverviewId) {
      ...CoreEsgValueFields
    }
  }
`;

export const ESG_VALUE_EXPORT = gql`
  query EsgValueExport($year: Int!) {
    esgValueExport(year: $year) {
      category
      facility
      kpiTitle
      unit
      value
      year
    }
  }
`;
