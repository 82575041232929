import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody } from '@mui/material';

import { Facility } from 'src/modules/common/types';
import { getManageFacilityHeaders } from 'src/modules/common/types/constants/table-header';
import TableHeader from 'src/modules/common/components/Table/TableHeader';
import { genericSortThreeColumns } from 'src/modules/common/application/sort';
import theme from 'src/styles/theme';

import AdminSettingsTableRow from '../AdminSettingsTableRow/AdminSettingsTableRow';
import FacilityTableRowData from '../ManageFacilityTableRowData/ManageFacilityTableRowData';

import { ROW_OPTIONS } from './types';
interface Props {
  data: Facility[];
  onEditFacilityClick?: (f: Facility) => void;
  onDeleteFacilityClick?: (f: Facility) => void;
  onRowClick?: (f: Facility) => void;
  showMoreOptions?: boolean;
}

const ManageFacilityTable = ({
  data,
  onEditFacilityClick = () => {},
  onDeleteFacilityClick = () => {},
  onRowClick,
  showMoreOptions = true,
}: Props) => {
  const { t } = useTranslation();

  const facilityTableHeaders = getManageFacilityHeaders.map(t) as string[];

  const sortedData: Facility[] = JSON.parse(JSON.stringify(data));
  genericSortThreeColumns(sortedData, 'country', 'city', 'name');

  const moreOptions =
    sortedData.length > 1
      ? [
          {
            action: onEditFacilityClick,
            text: t(ROW_OPTIONS.EDIT),
          },
          { action: onDeleteFacilityClick, text: t(ROW_OPTIONS.DELETE) },
        ]
      : [
          {
            action: onEditFacilityClick,
            text: t(ROW_OPTIONS.EDIT),
          },
        ];

  return (
    <>
      <Table
        sx={{ tableLayout: 'auto', width: '100%', backgroundColor: theme.palette.colors.white }}
      >
        <TableHeader headers={facilityTableHeaders} showLastHeader={showMoreOptions} />
        <TableBody
          sx={{
            backgroundColor: 'colors.white',
          }}
        >
          {sortedData.map(facility => (
            <AdminSettingsTableRow
              key={facility.id}
              data={facility}
              RowComponent={FacilityTableRowData}
              showMoreOptions={showMoreOptions}
              onRowClick={onRowClick}
              moreOptions={moreOptions}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default ManageFacilityTable;
