import React, { useMemo } from 'react';
import useTheme from '@mui/system/useTheme';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BreadcrumbData, generateBreadCrumbs } from './utils';

interface Props {
  data: BreadcrumbData[];
}

const AppBreadCrumb = ({ data }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  data.forEach(crumb => {
    crumb.name = t(crumb.name);
  });
  const breadcrumbs = useMemo(() => generateBreadCrumbs(data, theme), [data, theme]);

  return (
    <Breadcrumbs
      separator={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <NavigateNextIcon fontSize="small" color={theme.palette.colors.lightGrey} />
        </Box>
      }
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};

export default AppBreadCrumb;
