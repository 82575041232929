import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ApexOptions } from 'apexcharts';
import theme from 'src/styles/theme';
import EnhancedChart from 'src/modules/common/components/EnhancedChart';

interface IOverallEmissionProps {
  directEmissions: number;
  indirectEmissions: number;
}

const OverallEmission = ({ directEmissions, indirectEmissions }: IOverallEmissionProps) => {
  const { t } = useTranslation();
  const chartSeries = [directEmissions, indirectEmissions, 0];

  const chartOptions: ApexOptions = {
    labels: [
      t('modules.analysis.overallEmission.scopeOne'),
      t('modules.analysis.overallEmission.scopeTwo'),
      t('modules.analysis.overallEmission.scopeThree'),
    ],
    colors: [
      theme.palette.colors.cascade,
      theme.palette.colors.pearlAqua,
      theme.palette.colors.jaggedIce,
    ],
    responsive: [
      {
        breakpoint: 1130,
        options: {
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 900,
        options: {
          legend: {
            position: 'right',
          },
        },
      },
      {
        breakpoint: 600,
        options: {
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return val + 't CO2';
        },
      },
    },
  };
  return (
    <EnhancedChart
      noData={directEmissions === 0 && indirectEmissions === 0}
      cardProps={{
        title: t('modules.analysis.overallEmission.title'),
        infoText: t('modules.analysis.overallEmission.info'),
      }}
      chartProps={{
        height: '90%',
        options: chartOptions,
        series: chartSeries,
        type: 'donut',
      }}
    />
  );
};

export default OverallEmission;
