import React, { useEffect, useState } from 'react';
import { TextField, Typography, Tooltip, Radio } from '@mui/material';
import { Box } from '@mui/system';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';

import theme from 'src/styles/theme';
import { MaterialityEvaluationResult } from 'src/modules/common/types';

import { MaterialityType } from '../../types/materialEvaluation';

import { textFieldInputStyle } from './styles';

interface Props {
  evaluationData: MaterialityEvaluationResult | null;
  type: MaterialityType;
  optionsLabel?: string;
  noteInputLabel: React.ReactNode | string;
  isDisabled?: boolean;
  handleRadioOptionsUpdate?: (key: string, value: string | number) => void;
  handleDescriptionUpdate?: (value: string) => void;
}

const MaterialityEvaluationCard = (props: Props) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<{ [key: string]: number | null }>({});
  const [note, setNote] = useState<string>();
  const {
    evaluationData,
    type,
    optionsLabel = false,
    noteInputLabel,
    isDisabled = false,
    handleRadioOptionsUpdate,
    handleDescriptionUpdate,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const initValue = { ...selectedValue };
    initValue[key] = Number(event.target.value);
    setSelectedValue(initValue);
    handleRadioOptionsUpdate && handleRadioOptionsUpdate(key, Number(event.target.value));
  };

  useEffect(() => {
    if (evaluationData || !isDisabled) {
      if (type === MaterialityType.Impact) {
        setNote(evaluationData?.impactDescription || '');
        setSelectedValue({
          impactScale: evaluationData?.impactScale || null,
          impactScope: evaluationData?.impactScope || null,
          impactIrreversibility: evaluationData?.impactIrreversibility || null,
        });
      } else if (type === MaterialityType.Financial) {
        setNote(evaluationData?.financialDescription || '');
        setSelectedValue({
          financialProbability: evaluationData?.financialProbability || null,
          financialSignificance: evaluationData?.financialSignificance || null,
        });
      }
    }
  }, [evaluationData, type, isDisabled]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      <Box width={'45%'} height={!isDisabled || evaluationData ? '172px' : 'auto'}>
        {noteInputLabel}
        {!isDisabled || evaluationData ? (
          <TextField
            multiline
            margin="normal"
            sx={textFieldInputStyle}
            rows={5}
            variant="outlined"
            fullWidth
            placeholder={t('modules.materialityAssessment.materialityEvaluationDetail.enter')}
            value={note}
            onChange={e => {
              setNote(e.target.value);
              handleDescriptionUpdate && handleDescriptionUpdate(e.target.value);
            }}
            disabled={isDisabled}
          />
        ) : null}
      </Box>
      <Box
        width={'405px'}
        sx={{
          marginLeft: '15%',
        }}
      >
        {optionsLabel && optionsLabel.length ? (
          <Typography
            sx={{ color: theme.palette.colors.grey }}
            fontSize={'1rem !important'}
            fontWeight={500}
            marginBottom={1}
          >
            {optionsLabel}
          </Typography>
        ) : null}

        {!isDisabled || evaluationData ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              mt: optionsLabel && optionsLabel.length > 0 ? 0 : 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '60%',
              }}
            >
              <Typography
                sx={{ color: theme.palette.colors.shadeLightGrey }}
                fontSize={'1rem !important'}
                fontWeight={500}
              >
                {t('modules.materialityAssessment.materialityEvaluationDetail.veryLow')}
              </Typography>
              <Typography
                sx={{ color: theme.palette.colors.shadeLightGrey }}
                fontSize={'1rem !important'}
                fontWeight={500}
              >
                {t('modules.materialityAssessment.materialityEvaluationDetail.veryHigh')}
              </Typography>
            </Box>
          </Box>
        ) : null}
        {selectedValue
          ? Object.entries(selectedValue).map(([key, value], index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      width: '30%',
                    }}
                  >
                    <Typography
                      sx={{ color: '#68677F' }}
                      fontSize={'1rem !important'}
                      fontWeight={500}
                    >
                      {t(
                        `modules.materialityAssessment.materialityEvaluationDetail.${type}Materiality.radioOptions.${key}`
                      )}
                    </Typography>
                    <Tooltip
                      title={t(
                        `modules.materialityAssessment.materialityEvaluationDetail.${type}Materiality.radioOptions.${key}Tooltip`
                      )}
                    >
                      <InfoOutlinedIcon
                        fontSize="inherit"
                        sx={{ color: theme.palette.text.secondary, cursor: 'pointer', ml: 0.5 }}
                      />
                    </Tooltip>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '60%',
                    }}
                  >
                    {Array.from(Array(5).keys()).map(el => {
                      const adjustedValue = el + 1; // Adding 1 to the index
                      return (
                        <Radio
                          key={adjustedValue}
                          checked={value === adjustedValue}
                          onChange={e => handleChange(e, key)}
                          value={adjustedValue}
                          name="radio-buttons"
                          inputProps={{ 'aria-label': `${adjustedValue}` }}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 20,
                            },
                          }}
                          disabled={isDisabled}
                        />
                      );
                    })}
                  </Box>
                </Box>
              );
            })
          : null}
      </Box>
    </Box>
  );
};

export default MaterialityEvaluationCard;
