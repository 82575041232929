import { User } from 'src/modules/common/types';
import { GET_USERS } from 'src/services/graphql/queries';
import useEnhancedQuery from 'src/modules/common/application/use-enhanced-query';

const useUser = () => {
  const { loading, data, error } = useEnhancedQuery<{ users: User[] }>(GET_USERS, {
    fetchPolicy: 'cache-first',
  });

  const getSignedUsers = (): User[] | undefined => {
    return data?.users?.filter(user => user.status === 'USER_INFO_COMPLETED');
  };

  return {
    users: data && data.users ? data?.users : [],
    loading,
    error,
    getSignedUsers,
  };
};

export default useUser;
