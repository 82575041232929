import React from 'react';
import { Box } from '@mui/material';

import { EnhancedChatMessage, ChatMessageRole } from '../../types';
import AssistantMessage from './AssistantMessage';
import UserMessage from './UserMessage';
import SystemMessage from './SystemMessage';
import ThinkingMessage from './ThinkingMessage';

interface Props {
  messages: EnhancedChatMessage[];
  loading?: boolean;
}

const ChatMessageContainer = (props: Props) => {
  const { messages, loading } = props;
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
      >
        {messages.map((item, index) => {
          const isLast = index === messages.length - 1;
          const isFirst = index === 0;
          const isAssistant = item.role === ChatMessageRole.ASSISTANT;
          const isUser = item.role === ChatMessageRole.USER;
          const isSystem = item.role === ChatMessageRole.SYSTEM;
          return (
            <React.Fragment key={index}>
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: isUser ? 'row-reverse' : 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                  width: '100%',
                  marginBottom: isLast ? 2 : undefined,
                  marginTop: isFirst ? 2 : undefined,
                }}
              >
                {isAssistant && <AssistantMessage message={item} />}
                {isUser && <UserMessage message={item.content} />}
                {isSystem && <SystemMessage message={item.content} />}
              </Box>
              {!isLast && <Box sx={{ width: '100%', my: 1 }} />}
            </React.Fragment>
          );
        })}
        {loading && <ThinkingMessage />}
      </Box>
    </Box>
  );
};

export default ChatMessageContainer;
