import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';

import { PageContainer } from 'src/modules/common/components/Structure';
import CompanyList from 'src/modules/super/components/Company/CompanyList';

import CompanyForm from 'src/modules/super/components/Company/CompanyForm';
import useCompanySuper from 'src/modules/common/application/use-company-super';
import FacilityAdminPanel from 'src/modules/super/components/Facility';
import UserAdminPanel from 'src/modules/super/components/User';
import GeneralCalculations from 'src/modules/super/components/Statistics/GeneralCalculations';
import YearlyCalculations from 'src/modules/super/components/Statistics/YearlyCalculations';
import OverallCalculations from 'src/modules/super/components/Statistics/OverallCalculations';
import SyncYears from 'src/modules/super/components/Statistics/SyncYears';
import OverallReportProgressCalculations from 'src/modules/super/components/Statistics/OverallReportProgressCalculations';
import CalculateCollectOverviewProgressStatus from 'src/modules/super/components/Statistics/CalculateCollectOverviewStats';
import ReportAdminPanel from 'src/modules/super/components/Report';
import CleanUnlinkedRequestedESGValue from 'src/modules/super/components/Statistics/CleanUnlinkedRequestedEsgValuesStats';

const SuperAdmin = () => {
  const { t } = useTranslation();
  const { companies, handleSelectCompany, company, editMode, handleSubmitCompany } =
    useCompanySuper();

  return (
    <PageContainer>
      <Grid container spacing={5} sx={{ p: 2.5 }}>
        <Grid item xs={12}>
          <Typography variant="h2">{t('modules.super.title')}</Typography>
        </Grid>
        {/* Company */}
        <Grid item xs={4}>
          <Typography variant="h5">{t('modules.super.company.title')}</Typography>
          <CompanyList companies={companies} selected={company} onSelect={handleSelectCompany} />
          <CompanyForm
            data={company}
            handleSubmit={handleSubmitCompany}
            editMode={editMode}
            handleReset={() => handleSelectCompany('')}
          />
          {company && <GeneralCalculations companyId={company?.id} />}
          {company && <YearlyCalculations companyId={company?.id} />}
          {company && <CalculateCollectOverviewProgressStatus companyId={company?.id} />}
          {company && <CleanUnlinkedRequestedESGValue companyId={company?.id} />}

          <OverallCalculations />
          <SyncYears />
          <OverallReportProgressCalculations />
        </Grid>

        {/* User */}
        {company ? <UserAdminPanel companyId={company.id} /> : null}

        {/* Facility */}
        {company ? <FacilityAdminPanel companyId={company.id} /> : null}

        {/* Report */}
        {company ? <ReportAdminPanel companyId={company.id} /> : null}
      </Grid>
    </PageContainer>
  );
};

export default SuperAdmin;
