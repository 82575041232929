import { gql } from '@apollo/client';
import {
  USER_FRAGMENT,
  COMPANY_FRAGMENT,
  USER_SETTING_FRAGMENT,
  USER_FOR_SUPER_FRAGMENT,
} from '../fragments';

export const GET_USER_SETTING_DETAILS = gql`
  query GetUserSettingDetails {
    user {
      ...userSettingValues
    }
  }
  ${USER_SETTING_FRAGMENT}
`;

export const GET_USERS = gql`
  query GetUsers {
    users {
      id
      firstName
      lastName
      email
      role
      status
      language
    }
  }
`;

export const GET_USERS_BY_COMPANY_ID = gql`
  query GetUsersByCompanyId($companyId: UUID!) {
    usersByCompanyId(companyId: $companyId) {
      ...userValues
    }
  }
  ${USER_FOR_SUPER_FRAGMENT}
`;

export const GET_USER_LOGIN_DETAILS = gql`
  query GetUserLoginDetails {
    user {
      ...userValues
      selectedYear
    }
    company {
      ...companyValues
    }
  }
  ${USER_FRAGMENT}
  ${COMPANY_FRAGMENT}
`;

export const GET_COMPANY_USERS = gql`
  query GetCompanyUsers {
    companyUsers {
      edges {
        cursor
        node {
          ...userValues
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const NUMBER_FORMAT = gql`
  query NumberFormat {
    numberFormat @client
  }
`;
