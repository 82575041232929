import React from 'react';

const ArrowUpIcon = (props: any) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path
        {...props}
        d="M15.8803 14.7095L12.0003 10.8295L8.12027 14.7095C7.73027 15.0995 7.10027 15.0995 6.71027 14.7095C6.32027 14.3195 6.32027 13.6895 6.71027 13.2995L11.3003 8.70945C11.6903 8.31945 12.3203 8.31945 12.7103 8.70945L17.3003 13.2995C17.6903 13.6895 17.6903 14.3195 17.3003 14.7095C16.9103 15.0895 16.2703 15.0995 15.8803 14.7095Z"
      />
    </svg>
  );
};

export default ArrowUpIcon;
