import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Box,
  Typography,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
} from '@mui/material';
import { Formik, Form } from 'formik';

import { LanguageCode, User } from 'src/modules/common/types';
import Button from 'src/modules/common/components/Button/Button';
import theme from 'src/styles/theme';
import {
  getErrorLabelStyles,
  getInputLabelStyles,
} from 'src/modules/common/application/form-styles';
import { getExternalUserSchema } from 'src/modules/common/application/user';

interface Props {
  open: boolean;
  users: Partial<User>[];
  onSend: (data: {
    firstName: string;
    lastName: string;
    email: string;
    language: LanguageCode;
  }) => void;
  onClose: () => void;
}

const InviteExternalUserDialog = ({ open, onSend, onClose, users }: Props) => {
  const { t } = useTranslation();

  const externalUserSchema = getExternalUserSchema(t, users);
  const initialFormState = {
    firstName: '',
    lastName: '',
    email: '',
    language: LanguageCode.DE,
  };
  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <Box
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          background: '#fff',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', mb: 4 }}>
          <Typography fontSize={'1.5rem !important'} fontWeight={600} gutterBottom>
            {t('modules.dataManagement.inviteExternalUser.title')}
          </Typography>
          <Typography variant="body2" sx={{ color: theme.palette.colors.lightGrey }}>
            {t('modules.dataManagement.inviteExternalUser.subtitle')}
          </Typography>
        </Box>
        <Formik
          initialValues={initialFormState}
          validationSchema={externalUserSchema}
          onSubmit={values => onSend(values)}
          onReset={values => {
            values = initialFormState;
          }}
        >
          {({ submitForm, handleChange, errors, values }) => (
            <Form>
              <Grid
                container
                rowSpacing={1}
                sx={{
                  mb: 6,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Grid item xs={4.5}>
                  <FormControl fullWidth>
                    <InputLabel shrink sx={getInputLabelStyles} htmlFor="firstName">
                      {t('modules.dataManagement.inviteExternalUser.firstName')}
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="firstName"
                      name="firstName"
                      type="text"
                      variant="outlined"
                      placeholder={t(
                        'modules.dataManagement.inviteExternalUser.placeholderFirstName'
                      )}
                      value={values.firstName}
                      onChange={handleChange}
                    />
                    {errors.firstName && (
                      <FormHelperText sx={getErrorLabelStyles}>{errors.firstName}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4.5}>
                  <FormControl fullWidth>
                    <InputLabel shrink sx={getInputLabelStyles} htmlFor="lastName">
                      {t('modules.dataManagement.inviteExternalUser.lastName')}
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="lastName"
                      name="lastName"
                      type="text"
                      variant="outlined"
                      placeholder={t(
                        'modules.dataManagement.inviteExternalUser.placeholderLastName'
                      )}
                      value={values.lastName}
                      onChange={handleChange}
                    />
                    {errors.lastName && (
                      <FormHelperText sx={getErrorLabelStyles}>{errors.lastName}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="language" sx={getInputLabelStyles}>
                      {t('modules.settings.inviteUser.language')}
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: 'language',
                        id: 'language',
                      }}
                      fullWidth
                      value={values.language}
                      displayEmpty
                      onChange={handleChange}
                      renderValue={selected => {
                        return selected.toUpperCase();
                      }}
                    >
                      <MenuItem key={LanguageCode.DE} value={LanguageCode.DE}>
                        {t('modules.settings.manageTeam.de')}
                      </MenuItem>
                      <MenuItem key={LanguageCode.EN} value={LanguageCode.EN}>
                        {t('modules.settings.manageTeam.en')}
                      </MenuItem>
                    </Select>
                    {errors.language && (
                      <FormHelperText sx={getErrorLabelStyles}>{errors.language}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel shrink sx={getInputLabelStyles} htmlFor="email">
                      {t('modules.dataManagement.inviteExternalUser.email')}
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      type="email"
                      variant="outlined"
                      placeholder={t('modules.dataManagement.inviteExternalUser.placeholderEmail')}
                      value={values.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <FormHelperText sx={getErrorLabelStyles}>{errors.email}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
                <Button onClick={onClose} variant="outlined" size="small">
                  {t('modules.dataManagement.inviteExternalUser.cancel')}
                </Button>
                <Button sx={{ ml: 1 }} onClick={submitForm} variant="contained" size="small">
                  {t('modules.dataManagement.inviteExternalUser.confirm')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

export default InviteExternalUserDialog;
