import React from 'react';
import { Backdrop, Container } from '@mui/material';
import AuthLayout from '../AuthLayout/AuthLayout';
import { useTheme } from '@mui/material/styles';

interface Props {
  children: React.ReactElement;
  title: string;
  isLarge?: boolean;
}

const CenteredOverlayedContent = ({ children, title, isLarge }: Props) => {
  const theme = useTheme();

  const Dialog = () => {
    return (
      <Backdrop
        open={true}
        sx={{
          zIndex: 8000,
          backgroundColor: 'transparent',
        }}
      >
        <Container
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            top: '50%',
            transform: 'translateY(-50%)',
            height: isLarge ? '100%' : '70%',
            backgroundColor: 'white',
            borderRadius: '30px',
            border: '1px solid',
            borderColor: 'background.grey',
            margin: theme.spacing(2),
          }}
        >
          {children}
        </Container>
      </Backdrop>
    );
  };
  return (
    <AuthLayout title={title} showDashboard={false}>
      <Dialog />
    </AuthLayout>
  );
};

export default CenteredOverlayedContent;
