import { gql } from '@apollo/client';
import { CORE_REPORT_FIELDS } from '../fragments/report';

export const GET_REPORTS = gql`
  ${CORE_REPORT_FIELDS}
  query GetReports {
    reports {
      ...CoreReportFields
    }
  }
`;

export const GET_REPORTS_BY_COMPANY_ID = gql`
  ${CORE_REPORT_FIELDS}
  query GetReportsByCompanyId($companyId: UUID!) {
    reportsByCompanyId(companyId: $companyId) {
      ...CoreReportFields
    }
  }
`;

export const GET_REPORT_BY_ID = gql`
  ${CORE_REPORT_FIELDS}
  query GetReportById($reportId: UUID!) {
    report(reportId: $reportId) {
      ...CoreReportFields
    }
  }
`;

export const GET_REPORT_SECTIONS = gql`
  query GetReportSections($language: LanguageCode!, $reportId: UUID!) {
    reportSections(language: $language, reportId: $reportId) {
      id
      category
      chapter
      requirements
      framework
      example
      reportInput {
        id
        input
        isGenerating
        isMarkedCompleted
      }
    }
  }
`;

export const GET_REPORT_INPUTS = gql`
  query DownloadReport($reportId: UUID!) {
    downloadReport(reportId: $reportId) {
      id
      category
      chapter
      input
    }
  }
`;
