import React from 'react';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Box, IconButton, Typography, Drawer } from '@mui/material';

interface Props {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Sidebar = (props: Props) => {
  const { isOpen, onClose, children, title } = props;

  const handleOnClose = () => {
    typeof onClose === 'function' && onClose();
  };
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleOnClose}
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'colors.white',
        },
        width: '100%',
        maxWidth: '300px',
        height: '100%',
        overflowY: 'auto',
      }}
    >
      <Box>
        <Box
          sx={{
            padding: '24px 24px 8px 16px',
          }}
        >
          <IconButton onClick={handleOnClose}>
            <DoubleArrowIcon />
          </IconButton>
        </Box>
        <Typography
          variant="h3"
          fontWeight={600}
          fontSize="30px !important"
          paddingLeft={3}
          marginBottom={4}
          maxWidth={400}
        >
          {title}
        </Typography>
        {children}
      </Box>
    </Drawer>
  );
};

export default Sidebar;
