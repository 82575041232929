import React from 'react';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';

import { TableRow, Typography, IconButton, Box } from '@mui/material';
import { ApprovalStatus, RequestedEsgValuesByOwner, Unit } from 'src/modules/common/types';
import { Chip } from 'src/modules/common/components/Chip';
import { StyledTableCell } from './styles';
import { getApprovalStatusChipColor } from 'src/modules/common/application/ui';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';

interface Props {
  data: RequestedEsgValuesByOwner;
  onApprovalClick: (
    esgValueId: string,
    newValue: number,
    newUnit: Unit,
    status: ApprovalStatus
  ) => void;
}

const EsgDataApprovalTableRow = (props: Props) => {
  const { t } = useTranslation();

  const { data, onApprovalClick } = props;
  const { id, kpiTitle, currentValue, currentUnit, newValue, newUnit, approvalStatus } = data;

  return (
    <TableRow
      sx={{
        fontSize: '14px',
        backgroundColor: 'white',
      }}
    >
      <StyledTableCell sx={{ width: '40%' }}>
        <Typography paddingLeft={2} variant="inherit">
          {t(`shared.types.kpi.${removeUnderscoreToCamelCase(kpiTitle)}`)}
        </Typography>
      </StyledTableCell>
      <StyledTableCell sx={{ width: '25%' }}>
        <Typography paddingLeft={2} variant="inherit">
          {currentValue === undefined || currentValue === null
            ? '-'
            : currentValue +
              (currentUnit === undefined || currentUnit === null
                ? ''
                : ' ' + t('shared.types.unit.' + removeUnderscoreToCamelCase(currentUnit)))}
        </Typography>
      </StyledTableCell>
      <StyledTableCell sx={{ width: '25%' }}>
        <Typography paddingLeft={2} variant="inherit">
          {newValue === undefined || newValue === null
            ? '-'
            : newValue +
              (newUnit === undefined || newUnit === null
                ? ''
                : ' ' + t('shared.types.unit.' + removeUnderscoreToCamelCase(newUnit)))}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        {approvalStatus === ApprovalStatus.PENDING ? (
          <>
            <IconButton
              onClick={() => onApprovalClick(id, newValue!, newUnit!, ApprovalStatus.APPROVED)}
            >
              <CheckIcon fontSize="small" color="success" />
            </IconButton>
            <IconButton
              onClick={() => onApprovalClick(id, newValue!, newUnit!, ApprovalStatus.REJECTED)}
            >
              <ClearIcon fontSize="small" color="error" />
            </IconButton>
          </>
        ) : (
          <Box sx={{ display: 'flex' }}>
            <Chip size="small" colorVariant={getApprovalStatusChipColor(approvalStatus!)}>
              {t(`shared.types.approvalStatus.${removeUnderscoreToCamelCase(approvalStatus!)}`)}
            </Chip>
          </Box>
        )}
      </StyledTableCell>
    </TableRow>
  );
};

export default EsgDataApprovalTableRow;
