import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import { ReportSection } from 'src/modules/common/types';
import theme from 'src/styles/theme';

import ReportSidebarAccordian from './ReportSidebarAccordian';

interface IReportStructureSidebar {
  tree: {
    category: string;
    reportSections: ReportSection[];
  }[];
  reportYear: number;
  reportFramework: string;
  handleReportStructureSidebarMenuClick: () => void;
  isSidebarCollapsed: boolean;
}

const ReportStructureSidebar = ({
  tree,
  reportYear,
  reportFramework,
  handleReportStructureSidebarMenuClick,
  isSidebarCollapsed,
}: IReportStructureSidebar) => {
  return (
    <Card
      sx={{
        height: 'calc(100vh - 96px)',
        '@media (max-width: 600px)': { height: 'fit-content' },
        border: theme.custom.border,
        boxShadow: 'none',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      <CardContent
        sx={{
          height: '100%',
          padding: '0',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            pt: 4,
            pb: 3,
            px: 2,
          }}
        >
          <Typography
            fontSize={'1rem !important'}
            fontWeight={500}
            color={theme.palette.colors.grey}
          >
            {reportFramework}
          </Typography>
          <Typography
            fontSize={'0.875rem !important'}
            fontWeight={500}
            color={theme.palette.colors.lightGrey}
          >
            {reportYear}
          </Typography>
        </Box>
        {tree.map((item, index) => (
          <ReportSidebarAccordian
            key={index}
            reportSidebarData={item}
            isSidebarCollapsed={isSidebarCollapsed}
          />
        ))}
      </CardContent>
      <Box
        sx={{
          position: 'absolute',
          right: 10,
          bottom: 6,
        }}
      >
        <DoubleArrowIcon
          sx={{
            height: '20px',
            width: '20px',
            color: theme.palette.colors.lightGrey,
            cursor: 'pointer',
          }}
          onClick={handleReportStructureSidebarMenuClick}
        />
      </Box>
    </Card>
  );
};

export default ReportStructureSidebar;
