import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer, PageTitle } from 'src/modules/common/components/Structure';
import UserSettings from 'src/modules/settings/components/UserSettings/UserSettings';

const Settings = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <PageTitle title={t('modules.settings.pageTitle')} />
      <UserSettings />
    </PageContainer>
  );
};

export default Settings;
