import { gql } from '@apollo/client';

export const FACILITY_DATA_MANAGEMENT_FRAGMENT = gql`
  fragment facilityDataManagementValues on FacilityDataManagement {
    id
    facilityName
    type
    contributors {
      firstName
      lastName
      email
    }
    progress
  }
`;

export const FACILITY_FRAGMENT = gql`
  fragment facilityValues on Facility {
    id
    name
    city
    country
    type
  }
`;
