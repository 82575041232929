import React, { ForwardedRef, Suspense, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent } from '@mui/material';

import theme from 'src/styles/theme';
import { Loader } from 'src/modules/common/components';
import { lazyWithRetry } from 'src/modules/common/application/lazyWithRetry';

const LazyQuillEditor = lazyWithRetry(
  () => import(/* webpackChunkName: "TextEditor" */ '../QuillEditor/QuillEditor')
);

interface ITextInputCardProps {
  input: string | undefined;
  isMarkedCompleted: boolean;
}

const TextInputCard = (
  { input, isMarkedCompleted }: ITextInputCardProps,
  ref: ForwardedRef<any>
) => {
  const { t } = useTranslation();
  const editorRef = ref;

  return (
    <Card
      sx={{
        height: 'calc(75vh - 258px)',
        border: theme.custom.border,
        boxShadow: 'none',
        mt: 1,
        overflowY: 'auto',
      }}
    >
      <CardContent sx={{ height: '100%', padding: '0', overflow: 'auto' }}>
        <Suspense fallback={<Loader />}>
          <LazyQuillEditor
            ref={editorRef}
            placeholder={t('modules.reporting.textInput.placeholder')}
            value={input}
            disabled={isMarkedCompleted}
          />
        </Suspense>
      </CardContent>
    </Card>
  );
};

export default forwardRef(TextInputCard);
