export enum IntercomDataAttribute {
  collectStatusOverviewContainer = 'COLLECT_STATUS_OVERVIEW_CONTAINER',
  collectTableContainer = 'COLLECT_TABLE_CONTAINER',
  collectTableFirstRowCheckbox = 'COLLECT_TABLE_FIRST_ROW_CHECKBOX',
  collectTableFirstRowDataOwner = 'COLLECT_TABLE_FIRST_ROW_DATA_OWNER',
  collectTableFirstRowDueDate = 'COLLECT_TABLE_FIRST_ROW_DUE_DATE',
  collectTableFirstRowBellIcon = 'COLLECT_TABLE_FIRST_ROW_BELL_ICON',
  collectTableFirstRowStatus = 'COLLECT_TABLE_FIRST_ROW_STATUS',
  collectTableFirstRowKPIDetailIcon = 'COLLECT_TABLE_FIRST_ROW_KPI_DETAIL_ICON',
  collectTableProgressHeader = 'COLLECT_TABLE_PROGRESS_HEADER',
  collectSaveDataOwnerButton = 'COLLECT_SAVE_DATA_OWNER_BUTTON',
  collectAssignDataOwnerContainer = 'COLLECT_ASSIGN_DATA_OWNER_CONTAINER',
  kpiDetailTableContainer = 'KPI_DETAIL_TABLE_CONTAINER',
  kpiDetailTooltip = 'KPI_DETAIL_TOOLTIP',
  kpiDetailHeaderContainer = 'KPI_DETAIL_HEADER_CONTAINER',
  kpiDetailActivationSwitch = 'KPI_DETAIL_ACTIVATION_SWITCH',
  kpiDetailValueField = 'KPI_DETAIL_VALUE_FIELD',
  kpiDetailSaveButton = 'KPI_DETAIL_SAVE_BUTTON',
  collectQuickViewIcon = 'COLLECT_QUICK_VIEW_ICON',
  homeSideNavMenu = 'HOME_SIDE_NAV_MENU',
  materialitySideNavMenu = 'MATERIALITY_SIDE_NAV_MENU',
  dataManagementSideNavMenu = 'DATA_MANAGEMENT_SIDE_NAV_MENU',
  analysisSideNavMenu = 'ANALYSIS_SIDE_NAV_MENU',
  reportingSideNavMenu = 'REPORTING_SIDE_NAV_MENU',
}
