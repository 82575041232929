import { gql } from '@apollo/client';

export const CREATE_REPORT = gql`
  mutation CreateReport($input: CreateReportInput!) {
    createReport(input: $input) {
      id
      title
      year
      framework
      progress
    }
  }
`;

export const UPDATE_REPORT = gql`
  mutation UpdateReport($input: UpdateReportInput!) {
    updateReport(input: $input) {
      id
      title
      year
      framework
      progress
    }
  }
`;

export const DELETE_REPORT = gql`
  mutation DeleteReport($reportId: String!) {
    deleteReport(reportId: $reportId)
  }
`;

export const UPDATE_REPORT_INPUT = gql`
  mutation UpdateReportInput(
    $reportId: UUID!
    $sectionId: UUID!
    $input: String
    $isMarkedCompleted: Boolean
    $oldProgressCalculation: Boolean
  ) {
    updateReportInput(
      reportId: $reportId
      sectionId: $sectionId
      input: $input
      isMarkedCompleted: $isMarkedCompleted
      oldProgressCalculation: $oldProgressCalculation
    ) {
      id
      isGenerating
      input
      isMarkedCompleted
    }
  }
`;

export const GENERATE_AI_REPORT_BY_REPORT_ID = gql`
  mutation GenerateReport($reportId: String!, $reportSectionId: String) {
    generateReport(reportId: $reportId, reportSectionId: $reportSectionId)
  }
`;

export const CALCULATE_AI_REPORT_SIMILARITY = gql`
  mutation CalculateAiReportSimilarity($reportId: UUID!, $companyId: UUID!) {
    calculateAiReportSimilarity(reportId: $reportId, companyId: $companyId) {
      chapter
      similarity
    }
  }
`;
