import { useApolloClient } from '@apollo/client';

import { NumberFormat } from 'src/modules/common/types';
import useEnhancedQuery from './use-enhanced-query';
import { NUMBER_FORMAT } from 'src/services/graphql/queries';

const useNumberFormat = () => {
  const client = useApolloClient();

  const { data, loading, error } = useEnhancedQuery(NUMBER_FORMAT, {
    fetchPolicy: 'cache-only',
  });

  const updateNumberFormat = (numberFormat: NumberFormat | undefined | null) => {
    client.writeQuery({
      query: NUMBER_FORMAT,
      data: {
        numberFormat,
      },
    });
  };

  return { numberFormat: data?.numberFormat as NumberFormat, loading, error, updateNumberFormat };
};

export default useNumberFormat;
