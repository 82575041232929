import React, { useContext, useEffect, useMemo } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { usePostHog } from 'posthog-js/react';

import { CompanyStatus, IRoute, UserAccountStatus, UserRole } from 'src/modules/common/types';
import { NotFound, PublicOverview, PublicCollectInput } from 'src/pages';
import { Layout, PrivateRoute } from 'src/modules/common/components';
import useUserData from 'src/modules/common/application/use-user-data';
import FirebaseContext from 'src/modules/common/application/context/FirebaseContext';
import useRoute from 'src/modules/common/application/use-route';
import ChatbotContainer from 'src/modules/chatbot/components/ChatbotContainer/ChatbotContainer';

const Redirect = ({ role, path }: { role: UserRole | undefined; path: string }) => {
  const redirectPath = useMemo(() => {
    if (role === UserRole.SUPER) {
      return '/super';
    }
    if (role === UserRole.ADMIN) {
      return '/';
    }
    return '/data-management';
  }, [role]);

  return (
    <Navigate
      to={redirectPath}
      state={{
        redirect: path,
      }}
    />
  );
};

const AuthorizedRoutes = ({ route }: { route: IRoute }) => {
  const { user, hasCompleteProfile, hasCompanyOnboarded, company } = useUserData();

  if (route.path === '/verify-email') {
    return (
      <Layout>
        <route.Element />
      </Layout>
    );
  }

  if (
    hasCompleteProfile &&
    hasCompanyOnboarded &&
    route.path !== '/user-info' &&
    route.path !== '/set-password' &&
    route.path !== '/company-info' &&
    route.path !== '/add-facility' &&
    route.path !== '/onboarding-start'
  ) {
    return (
      <Layout>
        <route.Element />
      </Layout>
    );
  } else if ((!hasCompleteProfile || !hasCompanyOnboarded) && route.path !== '/verify-email') {
    if (route.path === '/set-password' && user?.status === UserAccountStatus.ACCOUNT_CREATED) {
      return (
        <Layout>
          <route.Element />
        </Layout>
      );
    }
    // hasCompleteProfile === false && hasCompleteProfile === false
    if (user?.status === UserAccountStatus.ACCOUNT_CREATED) {
      return (
        <Navigate
          to="/set-password"
          state={{
            redirect: route.path,
          }}
        />
      );
    }

    if (
      route.path === '/user-info' &&
      (user?.status === UserAccountStatus.NEW_PASSWORD_SET || !hasCompanyOnboarded)
    ) {
      return (
        <Layout>
          <route.Element />
        </Layout>
      );
    }

    if (route.path === '/onboarding-start' && !hasCompanyOnboarded) {
      return (
        <Layout>
          <route.Element />
        </Layout>
      );
    }

    if (route.path === '/company-info' && hasCompleteProfile && !hasCompanyOnboarded) {
      return (
        <Layout>
          <route.Element />
        </Layout>
      );
    }

    if (route.path === '/add-facility' && hasCompleteProfile && !hasCompanyOnboarded) {
      return (
        <Layout>
          <route.Element />
        </Layout>
      );
    }

    if (user?.status === UserAccountStatus.NEW_PASSWORD_SET) {
      return hasCompanyOnboarded ? (
        <Navigate
          to="/user-info"
          state={{
            redirect: route.path,
          }}
        />
      ) : (
        <Navigate
          to="/onboarding-start"
          state={{
            redirect: route.path,
          }}
        />
      );
    }

    if (
      user?.status === UserAccountStatus.USER_INFO_COMPLETED &&
      !hasCompanyOnboarded &&
      company?.status === CompanyStatus.COMPANY_CREATED
    ) {
      return (
        <Navigate
          to="/company-info"
          state={{
            redirect: route.path,
          }}
        />
      );
    }

    if (hasCompleteProfile && !hasCompanyOnboarded) {
      return (
        <Navigate
          to="/add-facility"
          state={{
            redirect: route.path,
          }}
        />
      );
    }

    return hasCompanyOnboarded ? (
      <Layout>
        <route.Element />
      </Layout>
    ) : (
      <Navigate
        to="/onboarding-start"
        state={{
          redirect: route.path,
        }}
      />
    );
  }

  return <Redirect path={route.path} role={user?.role} />;
};

const WhenLoggedoutRoutes = ({ route }: { route: IRoute }) => {
  const { user } = useUserData();
  const { authenticated } = useContext(FirebaseContext);

  if (route.path === '/verify-email') {
    return <route.Element />;
  }

  return authenticated && user ? (
    <Redirect path={route.path} role={user?.role} />
  ) : (
    <route.Element />
  );
};

const Router = () => {
  const { user, company, loadingUserData } = useUserData();
  const posthog = usePostHog();
  const { routes, pathPattern } = useRoute();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!window.location.host.startsWith('localhost') && user && company) {
      posthog?.identify(user.id, {
        email: user.email,
        role: user.role,
        environment: process.env.REACT_APP_ENV,
        preferred_language: user.language,
      });
      posthog?.group('company', company.id, {
        name: company.name,
      });
    }
  }, [posthog, user, company]);

  useEffect(() => {
    if (!window.location.host.startsWith('localhost') && posthog?.get_distinct_id()) {
      posthog?.capture('$pageview', {
        actual_path: pathname,
        generic_path: pathPattern,
      });
    }
  }, [pathname, posthog, pathPattern]);

  if (loadingUserData) return <></>;

  return (
    <Routes>
      {routes.reduce((filtered: JSX.Element[], route: IRoute) => {
        if (route.onlyWhenLoggedOut) {
          filtered.push(
            <Route
              key={route.path}
              path={route.path}
              element={<WhenLoggedoutRoutes route={route} />}
            />
          );
        }
        return filtered;
      }, [])}

      <Route path="/shareable" element={<PublicOverview />} />
      <Route
        path="/shareable/:userId/:year/facilities/:facilityId/categories/:categoryId"
        element={<PublicOverview />}
      />
      <Route path="/shareable/:collectOverviewId" element={<PublicCollectInput />} />
      <Route
        path="/*"
        element={
          <PrivateRoute>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ChatbotContainer />
              <Routes>
                {routes.reduce((filtered: JSX.Element[], route: IRoute) => {
                  if (!route.onlyWhenLoggedOut) {
                    filtered.push(
                      <Route
                        key={route.path}
                        path={route.path}
                        element={
                          user?.role && route.allowedRoles?.includes(user.role) ? (
                            <AuthorizedRoutes route={route} />
                          ) : (
                            <Redirect path={route.path} role={user?.role} />
                          )
                        }
                      />
                    );
                  }

                  return filtered;
                }, [])}
              </Routes>
            </LocalizationProvider>
          </PrivateRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
