import * as React from 'react';
import { IconProps } from '../types';

const SvgReport = (props: IconProps) => (
  <svg width={64} height={49} fill="none" role="img" {...props}>
    <path d="M39.194 8.05a.685.685 0 0 0-.484-.2H20.606a1.756 1.756 0 0 0-1.756 1.756v28.912a1.756 1.756 0 0 0 1.756 1.756h21.788a1.756 1.756 0 0 0 1.756-1.756V13.29a.685.685 0 0 0-.2-.484l-.002-.001-.001-.001-4.753-4.753Zm2.837 30.853H20.607a.385.385 0 0 1-.385-.385V9.606c0-.213.172-.385.385-.385h17.418v2.998a1.756 1.756 0 0 0 1.756 1.756h2.999v24.543a.386.386 0 0 1-.386.385h-.362ZM39.395 12.22v-2.03l2.415 2.415h-2.03a.385.385 0 0 1-.385-.386Z" />
    <path d="M22.934 23.818h3.627c.182 0 .357.072.485.201m-4.112-.2 4.006.306.106-.106m-4.112-.2a.685.685 0 0 0-.685.685m.685-.686-.685.686m4.797-.485c.128.129.2.303.2.485m-.2-.485.2.485m-4.997 0v10.983m0-10.983v10.983m0 0c0 .182.072.356.2.485m-.2-.485.2.485m0 0c.129.128.303.2.485.2m-.485-.2.485.2m0 0h3.627m-3.627 0h3.627m0 0a.686.686 0 0 0 .485-.2m-.485.2.485-.2m0 0a.685.685 0 0 0 .2-.485m-.2.485.2-.485m0 0V24.504m0 10.983V24.504m-1.37 10.298h-2.257v-9.613h2.257v9.613ZM33.162 22.02h-3.478a.685.685 0 0 0-.685.685v12.78a.685.685 0 0 0 .685.685h3.628a.686.686 0 0 0 .685-.685v-12.78a.685.685 0 0 0-.685-.686h-.15Zm-.535 1.37V34.8h-2.258V23.39h2.258ZM40.067 18.872h-3.628a.685.685 0 0 0-.685.685v15.926a.685.685 0 0 0 .685.685h3.628a.685.685 0 0 0 .685-.685V19.556a.686.686 0 0 0-.685-.685Zm-.686 15.925h-2.256V20.243h2.256v14.554Z" />
  </svg>
);
export default SvgReport;
