import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';

import Button from 'src/modules/common/components/Button/Button';
import useTermsAndConditions from 'src/modules/common/application/use-terms-conditions';

const SendAcceptTermsAndConditions = () => {
  const { t } = useTranslation();

  const { acceptTermsAndConditions, loading } = useTermsAndConditions();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>
          {t('modules.tokenizedLogin.termsAndConditions.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" sx={{ color: 'text.secondary', textAlign: 'center' }}>
          {t('modules.tokenizedLogin.termsAndConditions.error')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={() => acceptTermsAndConditions()} loading={loading} fullWidth>
          {t('modules.tokenizedLogin.termsAndConditions.button')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SendAcceptTermsAndConditions;
