import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { User } from 'src/modules/common/types';
import { Avatar } from 'src/modules/common/components';
import { getStackholderLabel } from 'src/modules/common/application/user';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';

interface StateholdersResultsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  data?: User[];
  type?: 'FAVOUR' | 'AGAINST';
  showTitleSubTitle?: boolean;
}

export const StakeholdersResultsDialog = (props: StateholdersResultsDialogProps) => {
  const { t } = useTranslation();
  const { isOpen, onClose, data, type, showTitleSubTitle = true } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={(e, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {showTitleSubTitle && (
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 600, fontSize: 20 }}>
          {t(
            `modules.materialityAssessment.resultModal.${
              type === 'FAVOUR' ? 'favourTitle' : 'againstTitle'
            }`
          )}
        </DialogTitle>
      )}
      <DialogContent sx={{ minWidth: 400 }}>
        {showTitleSubTitle && (
          <DialogContentText id="alert-dialog-description" sx={{ fontWeight: 500, fontSize: 16 }}>
            {t(
              `modules.materialityAssessment.resultModal.${
                type === 'FAVOUR' ? 'favourSubTitle' : 'againstSubTitle'
              }`
            )}
          </DialogContentText>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            mt: showTitleSubTitle ? 2 : 0,
          }}
        >
          {data?.map((stakeholder, index) => {
            return (
              <Box
                key={index}
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 1,
                }}
              >
                <Box
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Avatar
                    input={getStackholderLabel(stakeholder)}
                    styles={{
                      width: 30,
                      height: 30,
                      fontSize: '0.875rem',
                      fontWeight: 600,
                    }}
                  />
                  <Typography sx={{ ml: 1, fontSize: 16, fontWeight: 500 }}>
                    {stakeholder.firstName} {stakeholder.lastName}
                  </Typography>
                </Box>
                <Typography sx={{ ml: 1, fontSize: 16, fontWeight: 500, color: '#BEBEBD' }}>
                  {t(
                    'shared.types.userPosition.' +
                      removeUnderscoreToCamelCase(stakeholder.position!)
                  )}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button size="small" onClick={onClose} variant="outlined">
          {t('modules.materialityAssessment.resultModal.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
