import { gql } from '@apollo/client';

export const REQUESTED_ESG_VALUE_BY_OWNER_FRAGMENT = gql`
  fragment requestedEsgValuesByOwner on RequestedEsgValuesByOwner {
    approvalStatus
    currentUnit
    currentValue
    id
    kpiTitle
    newUnit
    newValue
  }
`;
