import { EditedUser } from 'src/modules/common/types';
import { getUserLabel } from './user';

export const genericSortTwoColumns = <T>(array: T[], firstColumn: string, secondColumn: string) => {
  array.sort((a: any, b: any) => {
    const result = a[firstColumn].localeCompare(b[firstColumn]);
    return result === 0 ? a[secondColumn].localeCompare(b[secondColumn]) : result;
  });

  return array;
};

export const genericSortSingleColumn = <T>(array: T[], column: string) => {
  array.sort((a: any, b: any) => {
    return a[column] < b[column] ? -1 : a[column] > b[column] ? 1 : 0;
  });
};

export const genericSortThreeColumns = <T>(
  array: T[],
  firstColumn: string,
  secondColumn: string,
  thirdColumn: string
) => {
  array.sort(
    (a: any, b: any) =>
      a[firstColumn].localeCompare(b[firstColumn]) ||
      a[secondColumn].localeCompare(b[secondColumn]) ||
      a[thirdColumn].localeCompare(b[thirdColumn])
  );

  return array;
};

export const sortUserArray = (users: Partial<EditedUser>[]) => {
  const sortedUsers: Partial<EditedUser>[] = JSON.parse(JSON.stringify(users));
  sortedUsers.forEach(user => {
    user.fullName = getUserLabel(user);
  });
  sortedUsers.sort((a, b) => a.fullName!.localeCompare(b.fullName!));
  return sortedUsers;
};
