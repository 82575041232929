import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Report } from 'src/modules/common/types';

interface Props {
  reports: Report[] | undefined;
  selected: Report | undefined;
  onSelect: (value: string) => void;
}

const ReportList = ({ reports, onSelect, selected }: Props) => {
  return (
    <Select
      sx={{
        marginBottom: 2,
        '&.MuiInputBase-root': {
          width: '100%',
        },
      }}
      id="facility-select"
      name="facility"
      value={selected ? selected.id : ''}
      IconComponent={KeyboardArrowDownIcon}
      onChange={(e: SelectChangeEvent) => onSelect(e.target.value)}
    >
      {reports?.map(report => (
        <MenuItem key={report.id} value={report.id}>
          {report.title}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ReportList;
