import { EditedUser, User } from 'src/modules/common/types';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { removeSpacesAtStart } from 'src/modules/common/application/string';

export const getUserLabel = function (user: Partial<EditedUser>): string {
  return user.firstName && user.lastName
    ? `${removeSpacesAtStart(user.firstName)} ${removeSpacesAtStart(user.lastName)}`
    : (user.email as string);
};

export const getStackholderLabel = function (stakeholder: User): string {
  return stakeholder.firstName && stakeholder.lastName
    ? `${stakeholder.firstName} ${stakeholder.lastName}`
    : stakeholder.email;
};

export const getExternalUserSchema = (t: TFunction, users: Partial<User>[]) => {
  return Yup.object().shape({
    firstName: Yup.string().default('').required(t('fieldWarnings.requiredWarning')),
    lastName: Yup.string().default('').required(t('fieldWarnings.requiredWarning')),
    language: Yup.string().default('de').required(t('fieldWarnings.requiredWarning')),
    email: Yup.string()
      .default('')
      .email(t('fieldWarnings.emailWarning'))
      .required(t('fieldWarnings.requiredWarning'))
      .test(
        'userAlreadyExists',
        t('fieldWarnings.emailExistsWarning'),
        value => users.find(user => user.email === value) === undefined
      ),
  });
};

export const getNameById = (id: string, users: Partial<EditedUser>[]) => {
  const user = users.find(u => u.id === id);
  return user?.fullName || '';
};
