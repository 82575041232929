import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stats } from 'src/modules/common/types';
import { Grid } from '@mui/material';
import StatCard from '../StatCard/StatCard';

interface Props {
  data: Stats[];
}

const OverviewStats = ({ data }: Props) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={4}
      sx={{
        alignItems: 'stretch',
      }}
    >
      {data.map(s => (
        <Grid key={s.title} item md={3} sm={6} xs={12}>
          <StatCard
            title={t(s.title)}
            value={s.value}
            unit={s.unit!}
            development={s.development!}
            progress={s.progress!}
            cardStyles={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default OverviewStats;
