import { gql } from '@apollo/client';

export const UPDATE_COLLECTION_YEARS = gql`
  mutation UpdateYears($years: [Int!]!) {
    updateYears(years: $years)
  }
`;

export const SYNC_YEARS = gql`
  mutation SyncYears {
    syncYears
  }
`;
