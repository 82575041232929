import React from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface MARequestInputButtonProps {
  handleOnButtonClick: () => void;
}

const MARequestInputButton = (props: MARequestInputButtonProps) => {
  const { handleOnButtonClick } = props;
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: 'fit-content',
      }}
    >
      <Button size="small" variant="contained" color="primary" onClick={handleOnButtonClick}>
        {t('modules.materialityAssessment.newRequest')}
      </Button>
    </Box>
  );
};

export default MARequestInputButton;
