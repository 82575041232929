import React from 'react';
import { Box, Fade } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppIcon, AppLogoIcon } from 'src/modules/common/components/icons';
interface Props {
  drawerExpanded: boolean;
}

const DrawerHead = ({ drawerExpanded }: Props) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        cursor: 'pointer',
        height: '96px',
      }}
      onClick={() => navigate('/')}
    >
      <Fade in={!drawerExpanded}>
        <Box>
          <AppIcon
            style={{
              width: '36px',
              transform: 'translate(30px, 40px)',
            }}
          />
        </Box>
      </Fade>
      <Fade in={drawerExpanded}>
        <Box>
          <AppLogoIcon
            style={{
              width: '200px',
              transform: 'translate(30px, 0px)',
            }}
          />
        </Box>
      </Fade>
    </Box>
  );
};

export default DrawerHead;
