import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider } from '@mui/material';

import { Chip } from 'src/modules/common/components/Chip';
import { ChipColorVariants } from 'src/modules/common/types';
import { PageContainer, PageTitle } from 'src/modules/common/components/Structure';
import { ReportsOverview } from 'src/modules/reporting/components';

const Reporting = () => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const [mode, setMode] = useState<'create' | 'edit'>('create');

  const handleCreateReport = () => {
    setMode('create');
    setOpen(true);
  };

  return (
    <PageContainer>
      <Box
        sx={{
          display: 'flex',
          alignItems: {
            xs: 'flex-start',
            sm: 'flex-end',
          },
          justifyContent: 'space-between',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}
        >
          <PageTitle title={t('modules.reporting.pageTitle')} />
          <Chip
            size="small"
            styles={{
              marginLeft: 2,
              marginBottom: '12px',
              maxWidth: 'fit-content',
              padding: '4px 8px !important',
            }}
            labelStyles={{ fontWeight: 500 }}
            colorVariant={ChipColorVariants.WARM_BLUE}
          >
            powered by AI
          </Chip>
        </Box>
        <Box
          sx={{
            justifyContent: 'flex-end',
            width: {
              xs: '100%',
              sm: 'unset',
            },
            display: 'flex',
          }}
        >
          <Button variant="contained" size="small" onClick={handleCreateReport}>
            {t('modules.reporting.reportsOverview.createReport')}
          </Button>
        </Box>
      </Box>
      <Divider sx={{ mt: 1, mb: 0 }} />
      <ReportsOverview mode={mode} isOpen={isOpen} setOpen={setOpen} setMode={setMode} />
    </PageContainer>
  );
};

export default Reporting;
