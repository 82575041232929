import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useFeatureFlagEnabled } from 'posthog-js/react';

import { FeatureFlag, IRoute, UserRole } from 'src/modules/common/types';

import {
  Controlling,
  Login,
  SetPassword,
  PasswordForgot,
  UserInfo,
  AddFacility,
  CompanyInfo,
  OnboardingStart,
  Register,
  TokenizedLogin,
  SuperAdmin,
  EsgDataInput,
  Analysis,
  Home,
  Reporting,
  Settings,
  MaterialityAssessment,
  DataManagement,
  ReportSectionPage,
  MaterialityEvaluation,
} from 'src/pages';

const useRoute = () => {
  const { pathname } = useLocation();
  const isMaterialityEvaluationEnabled = useFeatureFlagEnabled(
    FeatureFlag.MATERIALITY_EVALUATION_ROUTES
  );

  const materialityEvaluationRoutes: IRoute[] = useMemo(() => {
    return isMaterialityEvaluationEnabled
      ? [
          {
            path: '/materiality-assessment/evaluation',
            Element: MaterialityAssessment,
            allowedRoles: [UserRole.ADMIN],
          },
          {
            path: '/materiality-assessment/evaluation/:materialityAssessmentTopicId',
            Element: MaterialityEvaluation,
            allowedRoles: [UserRole.ADMIN],
          },
        ]
      : [];
  }, [isMaterialityEvaluationEnabled]);

  const baseRoutes: IRoute[] = [
    {
      path: '/login',
      Element: Login,
      onlyWhenLoggedOut: true,
    },
    {
      path: '/register',
      Element: Register,
      onlyWhenLoggedOut: true,
    },
    {
      path: '/password-forgot',
      Element: PasswordForgot,
      onlyWhenLoggedOut: true,
    },
    {
      path: '/verify-email',
      Element: TokenizedLogin,
      onlyWhenLoggedOut: true,
    },
    {
      path: '/super',
      Element: SuperAdmin,
      allowedRoles: [UserRole.SUPER],
    },
    {
      path: '/',
      Element: Home,
      allowedRoles: [UserRole.ADMIN],
    },
    {
      path: '/controlling',
      Element: Controlling,
      allowedRoles: [UserRole.ADMIN, UserRole.EMPLOYEE],
    },
    {
      path: '/data-management',
      Element: DataManagement,
      allowedRoles: [UserRole.ADMIN, UserRole.EMPLOYEE],
    },
    {
      path: '/data-management/collect',
      Element: DataManagement,
      allowedRoles: [UserRole.ADMIN, UserRole.EMPLOYEE],
    },
    {
      path: '/data-management/collect/:collectOverviewId',
      Element: EsgDataInput,
      allowedRoles: [UserRole.ADMIN, UserRole.EMPLOYEE],
    },
    {
      path: '/data-management/collect/:collectOverviewId/approval',
      Element: EsgDataInput,
      allowedRoles: [UserRole.ADMIN, UserRole.EMPLOYEE],
    },
    {
      path: '/analysis',
      Element: Analysis,
      allowedRoles: [UserRole.ADMIN, UserRole.EMPLOYEE],
    },
    {
      path: '/reports',
      Element: Reporting,
      allowedRoles: [UserRole.ADMIN, UserRole.EMPLOYEE],
    },
    {
      path: '/reports/:reportId',
      Element: ReportSectionPage,
      allowedRoles: [UserRole.ADMIN, UserRole.EMPLOYEE],
    },
    {
      path: '/reports/:reportId/section/:sectionId',
      Element: ReportSectionPage,
      allowedRoles: [UserRole.ADMIN, UserRole.EMPLOYEE],
    },
    {
      path: '/settings',
      Element: Settings,
      allowedRoles: [UserRole.ADMIN, UserRole.EMPLOYEE],
    },
    {
      path: '/user-info',
      Element: UserInfo,
      allowedRoles: [UserRole.ADMIN, UserRole.EMPLOYEE],
    },
    {
      path: '/set-password',
      Element: SetPassword,
      allowedRoles: [UserRole.ADMIN, UserRole.EMPLOYEE, UserRole.SUPER],
    },
    {
      path: '/onboarding-start',
      Element: OnboardingStart,
      allowedRoles: [UserRole.ADMIN],
    },
    {
      path: '/company-info',
      Element: CompanyInfo,
      allowedRoles: [UserRole.ADMIN],
    },
    {
      path: '/add-facility',
      Element: AddFacility,
      allowedRoles: [UserRole.ADMIN],
    },
    {
      path: '/materiality-assessment',
      Element: MaterialityAssessment,
      allowedRoles: [UserRole.ADMIN],
    },
  ];

  const getPathPattern = () => {
    const routes = [...baseRoutes, ...materialityEvaluationRoutes];
    let pathPattern = pathname;
    for (const route of routes) {
      const match = matchPath({ path: route.path }, pathname);
      if (match) {
        pathPattern = route.path;
        break;
      }
    }
    return pathPattern;
  };

  return {
    routes: [...baseRoutes, ...materialityEvaluationRoutes],
    pathPattern: getPathPattern(),
  };
};

export default useRoute;
