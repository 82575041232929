import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useFormik } from 'formik';
import { PostHogFeature } from 'posthog-js/react';

import { CreateReportInput, ReportingFramework, FeatureFlag } from 'src/modules/common/types';
import { Sidebar } from 'src/modules/common/components/Base';
import Button from 'src/modules/common/components/Button/Button';

import { getInputLabelStyles } from './utils/form-styles';
import { getCreateReportValidationSchema } from './utils/validations';

interface Props {
  isOpen: boolean;
  mode: string;
  years: number[];
  onClose: () => void;
  onSubmit: (input: CreateReportInput) => void;
  loading: boolean;
  editReportValues?: {
    title: string;
    framework: ReportingFramework;
    year: string;
  };
}
const ReportForm = ({
  years,
  isOpen,
  onClose,
  onSubmit,
  editReportValues,
  mode,
  loading,
}: Props) => {
  const { t } = useTranslation();

  const title =
    mode === 'edit'
      ? t('modules.reporting.createReportMenu.editStateTitle')
      : t('modules.reporting.createReportMenu.title');

  const formInitialValues = {
    title: '',
    framework: '',
    year: '',
  };

  const formik = useFormik({
    initialValues: mode === 'edit' ? editReportValues! : formInitialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: getCreateReportValidationSchema,
    onSubmit: values => {
      const newReport = {
        title: values.title,
        year: parseInt(values.year),
        framework: values.framework,
      } as CreateReportInput;
      onSubmit(newReport);
    },
  });

  return (
    <Sidebar isOpen={isOpen} onClose={onClose} title={title}>
      <form
        onSubmit={formik.handleSubmit}
        style={{
          width: '450px',
        }}
      >
        <Box
          sx={{
            padding: 3,
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'scroll',
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} marginBottom={2}>
              <FormControl fullWidth>
                <InputLabel shrink required htmlFor="title" sx={getInputLabelStyles}>
                  {t('modules.reporting.createReportMenu.reportTitle')}
                </InputLabel>
                <TextField
                  fullWidth
                  name="title"
                  id="title"
                  variant="outlined"
                  onChange={formik.handleChange}
                  value={formik.values.title}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} marginBottom={2}>
              <FormControl fullWidth>
                <InputLabel shrink required htmlFor="framework" sx={getInputLabelStyles}>
                  {t('modules.reporting.createReportMenu.framework')}
                </InputLabel>
                <Select
                  id="framework"
                  name="framework"
                  value={formik.values.framework}
                  onChange={formik.handleChange}
                  disabled={mode === 'edit'}
                >
                  <MenuItem key={'GRI'} value={'GRI'}>
                    {'GRI'}
                  </MenuItem>
                  <MenuItem key={'DNK'} value={'DNK'}>
                    {'DNK'}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} marginBottom={2}>
              <FormControl fullWidth>
                <InputLabel shrink required htmlFor="year" sx={getInputLabelStyles}>
                  {t('modules.reporting.createReportMenu.year')}
                </InputLabel>
                <Select
                  id="year"
                  name="year"
                  value={formik.values.year}
                  onChange={formik.handleChange}
                >
                  {years.map(year => {
                    return (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} marginBottom={2}>
              <PostHogFeature flag={FeatureFlag.AI_REPORT_GENERATION_SWITCHER} match={true}>
                <FormControlLabel
                  control={<Switch id="ai" name="ai" onChange={formik.handleChange} />}
                  label="Create Ai generated report"
                />
              </PostHogFeature>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: '100%',
            position: 'absolute',
            bottom: 0,
            padding: '24px',
            borderTop: '1px solid',
            borderColor: 'background.grey',
          }}
        >
          {/* ToDo: on submit validation */}
          <Button
            size="small"
            fullWidth
            type="submit"
            variant="contained"
            loading={loading}
            sx={{ width: '50%' }}
          >
            {mode === 'edit'
              ? t('modules.reporting.createReportMenu.editReport')
              : t('modules.reporting.createReportMenu.createReport')}
          </Button>
        </Box>
      </form>
    </Sidebar>
  );
};

export default ReportForm;
