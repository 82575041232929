import { EsgValue, EsgValueInput, NewEsgValueInput } from 'src/modules/common/types/graphql';

export const compareModifiedEsgValue = (
  originalEsgValueArray: EsgValue[],
  modifiedEsgValueArray: EsgValue[]
) => {
  const modifiedEsgValue = [] as EsgValue[];

  const map = new Map();
  for (const obj of originalEsgValueArray) {
    map.set(obj.id, obj);
  }

  for (const obj of modifiedEsgValueArray) {
    if (map.has(obj.id)) {
      const originalEsgValue = map.get(obj.id);
      if (
        obj.value !== originalEsgValue.value ||
        obj.selectedUnit !== originalEsgValue.selectedUnit
      ) {
        modifiedEsgValue.push(obj);
      }
      map.delete(obj.id);
    } else {
      modifiedEsgValue.push(obj);
    }
  }

  const removedEsgValue: EsgValue[] = [...map.values()];

  const modifiedData = getTypeEsgValueInput(modifiedEsgValue);
  const removedData = getTypeEsgValueInput(removedEsgValue);

  const updatedEsgValue: EsgValueInput[] = modifiedData.filter(el => !el.id.includes('NEW-'));

  const createdEsgValue: NewEsgValueInput[] = modifiedData
    ?.filter(el => el.id.includes('NEW-'))
    ?.map(item => {
      return {
        esgKpiId: item.esgKpiId,
        selectedUnit: item.selectedUnit,
        value: item.value,
      };
    });

  return {
    updatedEsgValue: updatedEsgValue,
    createdEsgValue: createdEsgValue,
    removedEsgValue: removedData,
  };
};

export const getModifiedesgValue = (
  modifiedEsgValueArray: EsgValue[],
  originalEsgValueArrayCopy: EsgValue[]
) => {
  const esgValueArrayModified = [];
  for (let i = 0; i < modifiedEsgValueArray.length; i++) {
    if (
      modifiedEsgValueArray[i].value !== originalEsgValueArrayCopy[i].value ||
      modifiedEsgValueArray[i].selectedUnit !== originalEsgValueArrayCopy[i].selectedUnit
    ) {
      esgValueArrayModified.push(modifiedEsgValueArray[i]);
    }
  }
  return esgValueArrayModified;
};

export const getTypeEsgValueInput = (esgValueArray: EsgValue[]) => {
  const filteredesgValueArray = [];
  for (let i = 0; i < esgValueArray.length; i++) {
    filteredesgValueArray.push({
      id: esgValueArray[i].id,
      selectedUnit: esgValueArray[i].selectedUnit
        ? esgValueArray[i].selectedUnit
        : esgValueArray[i].kpi.units && esgValueArray[i].kpi.units.length > 0
        ? esgValueArray[i].kpi.units[0]
        : undefined,
      value: esgValueArray[i].value!,
      esgKpiId: esgValueArray[i].kpi.id,
    });
  }
  return filteredesgValueArray;
};

export const getActiveTabIndex = (pathname: string) => {
  const last = pathname.substring(pathname.length - 8);
  if (last === 'approval') return 1;
  return 0;
};
