import * as React from 'react';
import { IconProps } from '../types';

const CompanyIcon = (props: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <path
      fill="#68677F"
      d="M23.2 0H10.4c-.44 0-.8.36-.8.8v12H.8c-.44 0-.8.36-.8.8v9.6c0 .44.36.8.8.8h22.4c.44 0 .8-.36.8-.8V.8c0-.44-.36-.8-.8-.8zM1.6 14.4h8v8h-8v-8zm20.8 8H11.2V1.6h11.2v20.8z"
    ></path>
    <path
      fill="#68677F"
      d="M13.6 4.8h1.6v2.4h-1.6V4.8zM18.4 4.8H20v2.4h-1.6V4.8zM13.6 8.8h1.6v2.4h-1.6V8.8zM18.4 8.8H20v2.4h-1.6V8.8zM13.6 12.8h1.6v2.4h-1.6v-2.4zM18.4 12.8H20v2.4h-1.6v-2.4zM3.2 16H8v1.6H3.2V16z"
    ></path>
  </svg>
);
export default CompanyIcon;
