import { gql } from '@apollo/client';

export const CALCULATE_OVERALL_STATISTICS = gql`
  mutation calculateOverallStatistics {
    calculateOverallStatistics
  }
`;

export const CALCULATE_GENERAL_STATISTICS = gql`
  mutation calculateGeneralStatistics($companyId: UUID!) {
    calculateGeneralStatistics(companyId: $companyId)
  }
`;

export const CALCULATE_YEARLY_STATISTICS = gql`
  mutation calculateYearlyStatistics($companyId: UUID!) {
    calculateYearlyStatistics(companyId: $companyId)
  }
`;

export const CALCULATE_OVERALL_REPORT_PROGRESS = gql`
  mutation caluculateOveralReportProgress {
    caluculateOveralReportProgress
  }
`;
