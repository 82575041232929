import { useTranslation } from 'react-i18next';
import { EsgValue, UpdateRequstedEsgValueInput } from 'src/modules/common/types';
import { GET_REQUESTED_ESG_VALUES_BY_COLLECT_OVERVIEW_ID } from 'src/services/graphql/queries';
import { useEnhancedQuery, useEnhancedMutation, useMessages } from 'src/modules/common/application';
import { UPDATE_REQUESTED_ESG_VALUES } from 'src/services/graphql/mutations';

const useRequestedEsgValue = (collectOverviewId: string, accessToken: string) => {
  const { t } = useTranslation();
  const { setErrorMessage, setSuccessMessage } = useMessages();

  const { loading, data, error } = useEnhancedQuery<{
    requestedEsgValuesByCollectOverviewId: EsgValue[];
  }>(GET_REQUESTED_ESG_VALUES_BY_COLLECT_OVERVIEW_ID, {
    variables: {
      collectOverviewId,
    },
    onError: () => setErrorMessage(t('messages.generic.error')),
    context: { headers: { authorization: `Bearer ${accessToken}` } },
  });

  const [updateRequestedEsgValues, { loading: updateRequestedEsgValuesLoading }] =
    useEnhancedMutation<UpdateRequstedEsgValueInput[]>(UPDATE_REQUESTED_ESG_VALUES, {
      onCompleted: () => setSuccessMessage(t('messages.updateEsgValues.success')),
      onError: () => setErrorMessage(t('messages.updateEsgValues.error')),
      context: { headers: { authorization: `Bearer ${accessToken}` } },
    });

  return {
    requestedEsgValues: data?.requestedEsgValuesByCollectOverviewId,
    loading: loading,
    updateRequestedEsgValues,
    updateRequestedEsgValuesLoading,
    error,
  };
};

export default useRequestedEsgValue;
