import { Snackbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IntercomDataAttribute } from 'src/modules/common/types/intercom';
import { getIntercomDataAttribute } from 'src/modules/common/application/intercom';
import Button from 'src/modules/common/components/Button/Button';
import theme from 'src/styles/theme';

interface AssignDataOwnerSnackbarProps {
  isOpen: boolean;
  selectedOwnerCount: number;
  onCancel: (event: React.SyntheticEvent | Event, reason?: string) => void;
  onSave: (event: React.SyntheticEvent | Event, reason?: string) => void;
}

export const AssignDataOwnerSnackbar = (props: AssignDataOwnerSnackbarProps) => {
  const { isOpen, selectedOwnerCount, onCancel, onSave } = props;
  const { t } = useTranslation();

  return (
    <Snackbar
      open={isOpen}
      onClose={onCancel}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Box
        sx={{
          display: 'flex',
          backgroundColor: '#fff',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxShadow: '0px 4px 10px 0px #D9D9D9',
          px: 3,
          py: 2,
          borderRadius: 1,
        }}
        data-intercom-target={getIntercomDataAttribute(
          IntercomDataAttribute.collectAssignDataOwnerContainer
        )}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mr: 6,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: theme.palette.colors.lightMainBlue,
              borderRadius: 1,
              px: 1,
              py: 0.5,
              mr: 1,
            }}
          >
            <Typography fontSize={'0.875rem !important'} color={theme.palette.colors.lightMainBlue}>
              {selectedOwnerCount}
            </Typography>
          </Box>
          <Typography
            color={theme.palette.colors.lightGrey}
            fontWeight={500}
            fontSize={'0.875rem !important'}
          >
            {t('modules.dataManagement.assignDataOwnerSnackbar.title')}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="outlined" size="small" onClick={onCancel}>
            {t('modules.dataManagement.assignDataOwnerSnackbar.cancel')}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={onSave}
            sx={{ ml: 2 }}
            data-intercom-target={getIntercomDataAttribute(
              IntercomDataAttribute.collectSaveDataOwnerButton
            )}
          >
            {t('modules.dataManagement.assignDataOwnerSnackbar.save')}
          </Button>
        </Box>
      </Box>
    </Snackbar>
  );
};
