import { useLazyQuery } from '@apollo/client';
import { AiReportSimilarity, EsgValueExport } from 'src/modules/common/types';
import { ESG_VALUE_EXPORT } from 'src/services/graphql/queries';
import { useMessages } from 'src/modules/common/application';
import { camelCaseString } from './string';

const useCsvDataExport = () => {
  const { setErrorMessage } = useMessages();

  const [getEsgValueExport, { loading }] = useLazyQuery<{
    esgValueExport: EsgValueExport[];
  }>(ESG_VALUE_EXPORT, {
    fetchPolicy: 'network-only',
    onError: error => {
      setErrorMessage(error?.message);
    },
  });

  const generateCsvData = <T>(exportData: T[], headers: string[]) => {
    const csvData = [
      headers,
      ...exportData.map(item =>
        headers.map(
          header => (item as Record<string, any>)[camelCaseString(header.replace(' ', ''))]
        )
      ),
    ]
      .map(row => row.join(';'))
      .join('\n');
    return csvData;
  };

  const exportToCsv = (csvData: string, documentTitle: string) => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${documentTitle}.csv`;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const getExportData = async (exportYear: number) => {
    const csvExportHeaders = ['Year', 'Facility', 'Category', 'Kpi Title', 'Unit', 'Value'];
    const result = await getEsgValueExport({ variables: { year: exportYear } });
    const csvData = generateCsvData(result.data!.esgValueExport, csvExportHeaders);
    exportToCsv(csvData, `EsgValueExport ${exportYear}`);
  };

  const superAdminCalculateSimilarity = async (exportData: AiReportSimilarity[]) => {
    const csvExportHeaders = ['Chapter', 'Similarity'];
    const csvData = generateCsvData(exportData, csvExportHeaders);
    exportToCsv(csvData, `AI Report Similarity`);
  };

  return { getExportData, loading, superAdminCalculateSimilarity };
};

export default useCsvDataExport;
