import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  EsgValue,
  EsgValueInput,
  EsgValueOutput,
  UpdateEsgValuesOutput,
} from 'src/modules/common/types';
import { useEnhancedMutation, useEnhancedQuery, useMessages } from 'src/modules/common/application';
import { compareModifiedEsgValue } from 'src/modules/common/application/esgValue';
import { UPDATE_ESG_VALUES_BY_OVERVIEW_ID } from 'src/services/graphql/mutations';
import { GET_ESG_VALUES_BY_OVERVIW_ID } from 'src/services/graphql/queries/esgValue';
import useEsgKpi from './use-kpi';

const useEsgValue = (collectOverviewId: string) => {
  const { t } = useTranslation();
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const [esgValues, setEsgValues] = useState<EsgValue[] | undefined>();
  const { esgKpiById } = useEsgKpi();

  const { loading: esgValueLoading, data } = useEnhancedQuery<{
    esgValuesByCollectOverviewId: EsgValue[];
  }>(GET_ESG_VALUES_BY_OVERVIW_ID, {
    variables: {
      collectOverviewId,
    },
    onError: () => {
      setErrorMessage(t('messages.generic.error'));
    },
  });

  useEffect(() => {
    setEsgValues(data?.esgValuesByCollectOverviewId);
  }, [data]);

  const [updateEsgValues, { loading: updateEsgValuesLoading }] = useEnhancedMutation<
    EsgValueInput[]
  >(UPDATE_ESG_VALUES_BY_OVERVIEW_ID, {
    onCompleted: () => {
      setSuccessMessage(t('messages.updateEsgValues.success'));
    },
    onError: error => {
      setErrorMessage(t('messages.updateEsgValues.error'));
    },
    update(cache, result, { variables }) {
      const { esgValuesByCollectOverviewId } = cache.readQuery<{
        esgValuesByCollectOverviewId: EsgValue[];
      }>({
        query: GET_ESG_VALUES_BY_OVERVIW_ID,
        variables: {
          collectOverviewId,
        },
      }) || { esgValuesByCollectOverviewId: [] };

      const { data } = result as unknown as {
        data: {
          updateEsgValues: UpdateEsgValuesOutput;
        };
      };

      const { updated, created, removed } = data.updateEsgValues;

      const newEsgValues = JSON.parse(JSON.stringify(esgValuesByCollectOverviewId));

      created.forEach((esgValue: EsgValue) => {
        newEsgValues.push(esgValue);
      });

      removed.forEach((esgValue: EsgValueOutput) => {
        const index = newEsgValues.findIndex((value: EsgValue) => value.id === esgValue.id);
        if (index > -1) newEsgValues.splice(index, 1);
      });

      updated.forEach((esgValue: EsgValue) => {
        const index = newEsgValues.findIndex((value: EsgValue) => value.id === esgValue.id);
        if (index > -1) {
          newEsgValues[index].value = esgValue.value;
          newEsgValues[index].selectedUnit = esgValue.selectedUnit;
        }
      });

      cache.writeQuery({
        query: GET_ESG_VALUES_BY_OVERVIW_ID,
        variables: {
          collectOverviewId,
        },
        data: { esgValuesByCollectOverviewId: newEsgValues },
      });
      setEsgValues(newEsgValues);
    },
  });

  const numTotalEsgValues = esgValues?.length;
  const numFilledValues = esgValues?.filter(value => value.value !== null).length;

  const handleUpdateEsgValues = async () => {
    const { updatedEsgValue, createdEsgValue, removedEsgValue } = compareModifiedEsgValue(
      data?.esgValuesByCollectOverviewId || [],
      esgValues || []
    );

    await updateEsgValues({
      variables: {
        input: {
          updated: updatedEsgValue,
          created: createdEsgValue,
          removed: removedEsgValue,
          collectOverviewId,
        },
      },
    });
  };

  const updateEsgValueOnTableRowDataChange = (
    id: string,
    field: string,
    value: string,
    esgValues: EsgValue[]
  ) => {
    const newesgValueByCategory = esgValues?.map(esgValue => {
      if (esgValue.id === id) {
        if (field === 'value') {
          return {
            ...esgValue,
            [field]: parseFloat(value),
          };
        }
        return {
          ...esgValue,
          [field]: value,
        };
      }
      return esgValue;
    });
    setEsgValues(newesgValueByCategory);
  };

  const updateEsgValuesOnActivateDectivateKpi = (id: string, isDeactivated: boolean) => {
    if (isDeactivated) {
      const updatedEsgValues = esgValues?.filter((esgValue: EsgValue) => esgValue.kpi.id !== id);
      setEsgValues(updatedEsgValues);
      return;
    } else {
      const updatedEsgValues = data?.esgValuesByCollectOverviewId
        ? data?.esgValuesByCollectOverviewId?.filter((esgValue: EsgValue) => esgValue.kpi.id === id)
        : [];

      if (updatedEsgValues?.length === 0) {
        const kpiData = esgKpiById(id);
        const updatedValue = {
          id: `NEW-${uuidv4()}`,
          kpi: kpiData,
          value: null,
          selectedUnit:
            kpiData && kpiData?.units && kpiData?.units?.length > 0 ? kpiData.units[0] : undefined,
        } as EsgValue;
        setEsgValues([...(esgValues || []), updatedValue]);
        return;
      } else {
        const transformedValue = updatedEsgValues?.map((esgValue: EsgValue) => {
          const updatedValue = JSON.parse(JSON.stringify(esgValue)) as EsgValue;
          updatedValue.value = null;
          return updatedValue;
        });
        setEsgValues([...(esgValues || []), ...(transformedValue || [])]);
        return;
      }
    }
  };

  return {
    esgValues,
    esgValueLoading,
    updateEsgValuesLoading,
    numTotalEsgValues,
    numFilledValues,
    setEsgValues,
    handleUpdateEsgValues,
    updateEsgValueOnTableRowDataChange,
    updateEsgValuesOnActivateDectivateKpi,
  };
};

export default useEsgValue;
