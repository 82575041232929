import React from 'react';
import { Box, Portal } from '@mui/material';
import { PostHogFeature } from 'posthog-js/react';

import { AppIcon } from 'src/modules/common/components/icons';
import { FeatureFlag } from 'src/modules/common/types/featureFlags';

import ChatbotDialog from '../ChatbotDialog/ChatboatDialog';
import FloatingChatbot from '../FloatingChatbot/FloatingChatbot';
import useChatbot from '../../application/use-chatbot';

const ChatbotContainer = () => {
  const {
    messages,
    handleSendMessage,
    loading,
    showFloatingChatBot,
    isChatBotHidden,
    handleUnpinBot,
    handleCloseBot,
    toggleHiddenChatBot,
    open,
    toggleDialog,
    handlePin,
    contentRef,
  } = useChatbot();

  return (
    <PostHogFeature flag={FeatureFlag.AI_CHATBOT} match={true}>
      <Portal>
        {showFloatingChatBot && (
          <>
            {!isChatBotHidden && (
              <FloatingChatbot
                handlePinClick={handleUnpinBot}
                handleOnClose={handleCloseBot}
                messages={messages}
                loading={loading}
                handleSendMessage={handleSendMessage}
                contentRef={contentRef}
              />
            )}
            <Box
              onClick={toggleHiddenChatBot}
              style={{ position: 'fixed', bottom: '80px', right: '20px' }}
            >
              <Box
                sx={{
                  width: '48px',
                  height: '48px',
                  borderWidth: 1,
                  borderColor: 'red',
                  borderRadius: '24px',
                  backgroundColor: '#F8F9FB',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16)',
                  cursor: 'pointer',
                }}
              >
                <AppIcon
                  style={{
                    width: '36px',
                    height: '36px',
                    transform: 'scale(0.5)',
                  }}
                />
              </Box>
            </Box>
          </>
        )}
        {!showFloatingChatBot && (
          <ChatbotDialog
            open={open}
            toggleDialog={toggleDialog}
            handlePinClick={handlePin}
            messages={messages}
            loading={loading}
            handleSendMessage={handleSendMessage}
            contentRef={contentRef}
          />
        )}
      </Portal>
    </PostHogFeature>
  );
};

export default ChatbotContainer;
