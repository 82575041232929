import { gql } from '@apollo/client';

export const GET_ESG_KPIS = gql`
  query EsgKpis {
    esgKpis {
      id
      title
      category {
        title
        id
        area
      }
      description
      frameworks {
        esrs
        gri
        dnk
      }
      source
      units
      type
    }
  }
`;
