import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import {
  CollectFilter,
  CollectOverviewStatus,
  ChipColorVariantsMap,
  EsgCategory,
  EsgCategoryEnum,
  Facility,
  User,
} from 'src/modules/common/types';
import Button from 'src/modules/common/components/Button/Button';
import { CollectFilterItem, FilterType } from 'src/modules/common/types/collect';
import ResetIcon from 'src/modules/common/components/icons/ResetIcon';
import {
  getCategoryChipColor,
  getCollectOverviewStatusChipColor,
} from 'src/modules/common/application/ui';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import { Avatar, Chip } from 'src/modules/common/components';
import { genericSortSingleColumn } from 'src/modules/common/application/sort';
import { getUserLabel } from 'src/modules/common/application/user';

import CollectDataTableFilterItem from '../CollectDataTableFilterItem';

const CollectDataTableFilter = ({
  collectFilter,
  facilities,
  categories,
  users,
  lazyLoading = false,
  hasSelected = false,
  hasAssignedUser = false,
  onOpenImportDialog,
  handleFilterChange,
}: {
  collectFilter: CollectFilter | undefined;
  facilities: Facility[] | undefined;
  categories: EsgCategory[] | undefined;
  users?: User[];
  hasSelected?: boolean;
  hasAssignedUser?: boolean;
  lazyLoading?: boolean;
  onOpenImportDialog?: (() => void) | undefined;
  handleFilterChange: (newValue: string[], filterType: FilterType) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const {
    statuses,
    facilities: filteredFacilites,
    categories: filteredCategories,
    assignedTo: filteredAssignedTo,
  } = collectFilter || {
    statuses: [],
    facilities: [],
    categories: [],
    assignedTo: [],
  };

  let sortedFacilities = [] as Facility[];

  if (facilities) {
    sortedFacilities = [...facilities];
    genericSortSingleColumn(sortedFacilities, 'name');
  }

  const statusOptions = [
    {
      key: CollectOverviewStatus.OVERDUE,
      label: t('shared.types.collectOverviewStatus.overdue'),
    },
    {
      key: CollectOverviewStatus.NOT_STARTED,
      label: t('shared.types.collectOverviewStatus.notStarted'),
    },
    {
      key: CollectOverviewStatus.IN_PROGRESS,
      label: t('shared.types.collectOverviewStatus.inProgress'),
    },
    {
      key: CollectOverviewStatus.COMPLETED,
      label: t('shared.types.collectOverviewStatus.completed'),
    },
  ];

  const dataOwnerOption = useMemo(() => {
    const options = [] as CollectFilterItem[];
    const defaultOption: CollectFilterItem = {
      key: 'UNASSIGNED_DATA_OWNER',
      label: '-',
    };

    if (users) {
      users.forEach(user => {
        const userName = getUserLabel(user);
        options.push({
          key: user.id,
          label: userName,
        });
      });
    }

    genericSortSingleColumn(options, 'label');
    options.push(defaultOption);

    return options;
  }, [users]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        my: 2,
      }}
    >
      {/* Reset */}
      <Box
        sx={{
          marginRight: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={() => handleFilterChange([], FilterType.RESET)}
      >
        <ResetIcon />
      </Box>
      {/* Status */}
      <CollectDataTableFilterItem
        data={statuses}
        options={statusOptions}
        title={t('modules.dataManagement.collectDataTableFilter.status')}
        onChange={statuses => handleFilterChange(statuses, FilterType.STATUS)}
        customOptionRender={(option: CollectFilterItem) => {
          const chipContainerStyles =
            ChipColorVariantsMap[
              getCollectOverviewStatusChipColor(option.key as CollectOverviewStatus)
            ];
          return (
            <div
              style={{
                ...chipContainerStyles,
                width: 'auto',
                height: '25px',
                paddingRight: 8,
                paddingLeft: 8,
                paddingTop: 4,
                paddingBottom: 4,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '20px',
              }}
            >
              <Typography variant="subtitle2" sx={{ color: 'colors.lightGrey' }}>
                {option.label}
              </Typography>
            </div>
          );
        }}
      />
      {/* Facility */}
      <CollectDataTableFilterItem
        data={filteredFacilites}
        options={sortedFacilities.map(facility => {
          return {
            key: facility.id,
            label: facility.name,
          };
        })}
        title={t('modules.dataManagement.collectDataTableFilter.facility')}
        onChange={filteredFacilites => handleFilterChange(filteredFacilites, FilterType.FACILITY)}
      />
      {/* Category */}
      <CollectDataTableFilterItem
        data={filteredCategories}
        options={
          categories
            ? categories.map(category => {
                return {
                  key: category.id,
                  label: category.title,
                };
              })
            : []
        }
        title={t('modules.dataManagement.collectDataTableFilter.category')}
        customOptionRender={(option: CollectFilterItem) => {
          return (
            <Chip
              size="small"
              styles={{
                maxWidth: 'fit-content',
                opacity: 1,
              }}
              colorVariant={getCategoryChipColor(option.label as EsgCategoryEnum)}
            >
              <Typography variant="subtitle2" sx={{ color: 'colors.lightGrey' }}>
                {t('shared.types.esgCategory.' + removeUnderscoreToCamelCase(option.label))}
              </Typography>
            </Chip>
          );
        }}
        onChange={filteredCategories => handleFilterChange(filteredCategories, FilterType.CATEGORY)}
      />
      {/* Data Owner */}
      {users && (
        <CollectDataTableFilterItem
          data={filteredAssignedTo}
          options={dataOwnerOption}
          title={t('modules.dataManagement.collectDataTableFilter.dataOwner')}
          onChange={filteredAssignedTo =>
            handleFilterChange(filteredAssignedTo, FilterType.ASSIGNED_TO)
          }
          customOptionRender={(option: CollectFilterItem) => {
            return (
              <Box
                key={option.key}
                sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}
              >
                <Avatar
                  styles={{
                    width: '25px',
                    height: '25px',
                    fontSize: '0.675rem',
                    fontWeight: 500,
                  }}
                  input={option.label}
                />
                <Typography
                  variant="body1"
                  sx={{
                    ml: 1,
                    fontSize: '0.875rem !important',
                  }}
                >
                  {option.label}
                </Typography>
              </Box>
            );
          }}
        />
      )}
      {/* Import */}
      {onOpenImportDialog && (
        <Button
          size="small"
          variant="contained"
          onClick={onOpenImportDialog}
          loading={lazyLoading}
          disabled={hasSelected || hasAssignedUser}
          sx={{ boxShadow: 'none' }}
        >
          {t('modules.dataManagement.importFileDialog.title')}
        </Button>
      )}
    </Box>
  );
};

export default CollectDataTableFilter;
