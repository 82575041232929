import { TableHeaderVariants } from './types';
import theme from 'src/styles/theme';

export const TableHeaderVariantsMap = {
  [TableHeaderVariants.PRIMARY]: {
    backgroundColor: theme.palette.colors.softGrey,
  },
  [TableHeaderVariants.SECONDARY]: {
    backgroundColor: theme.palette.colors.softGrey,
  },
} as const;

export const headersMock = ['header1', 'header2', 'header 3'];
