import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useMessages, useEnhancedMutation } from 'src/modules/common/application';
import AddFacilityForm from 'src/modules/company-onboarding/components/AddFacilityForm';
import OnboardingLayout from 'src/modules/common/components/Structure/OnboardingLayout';
import { Company, CompanyStatus, CountryCode, FacilityType, User } from 'src/modules/common/types';
import { CREATE_FACILITY } from 'src/services/graphql/mutations';
import { GET_USER_LOGIN_DETAILS } from 'src/services/graphql/queries';

const AddFacility = () => {
  const { t } = useTranslation();
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const navigate = useNavigate();

  const [createFacility, { loading }] = useEnhancedMutation(CREATE_FACILITY, {
    onCompleted: () => {
      setSuccessMessage(t('messages.createFacility.success'));
    },
    onError: () => setErrorMessage(t('messages.createFacility.error')),
    update(cache, result, { variables }) {
      const { user, company } = cache.readQuery<{
        user: User;
        company: Company;
      }>({
        query: GET_USER_LOGIN_DETAILS,
      }) || { user: undefined, company: undefined };

      if (company)
        cache.writeQuery({
          query: GET_USER_LOGIN_DETAILS,
          data: {
            user,
            company: {
              ...company,
              status: CompanyStatus.COMPANY_ACTIVE,
            },
          },
        });
    },
  });

  const onSubmit = async (data: {
    name: string;
    type: FacilityType | undefined;
    city: string;
    country: CountryCode;
  }) => {
    await createFacility({
      variables: {
        input: {
          name: data.name,
          type: data.type,
          city: data.city,
          country: data.country,
        },
      },
    });
    navigate('/');
  };

  const onBack = () => {
    navigate('/company-info');
  };

  return (
    <OnboardingLayout height="content-fit">
      <AddFacilityForm onInformationSubmit={onSubmit} onBack={onBack} loading={loading} />
    </OnboardingLayout>
  );
};

export default AddFacility;
