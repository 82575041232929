import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { GeneralStatistic, OpenDataRequest } from 'src/modules/common/types';
import { GET_DATA_MANAGEMENT_OVERVIEW_STATS } from 'src/services/graphql/queries';
import { useEnhancedQuery, useMessages } from 'src/modules/common/application';
import { Loader } from 'src/modules/common/components';

import DataOverviewStats from '../DataOverviewStats/DataOverviewStats';
import OpenDataRequests from '../OpenDataRequests/OpenDataRequests';
import OverviewDataAreas from '../OverviewDataAreas/OverviewDataAreas';

const DataOverviewTab = ({ year }: { year: number }) => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();

  const { loading, data, error } = useEnhancedQuery<{
    numberRequestedEsgValues: number;
    openDataRequests: OpenDataRequest[];
    getYearlyGeneralStatistics: GeneralStatistic;
  }>(GET_DATA_MANAGEMENT_OVERVIEW_STATS, {
    variables: {
      year,
    },
  });

  if (loading || !data) return <Loader />;
  if (error) return <>{setErrorMessage(t('messages.generic.error'))}</>;

  const { numberRequestedEsgValues, openDataRequests, getYearlyGeneralStatistics } = data;

  const { esgProgressByCategory, overallEsgProgress, completedFacilities } =
    getYearlyGeneralStatistics;

  const totalCompletedFacilities = esgProgressByCategory?.filter(
    facility => facility?.progress === 100
  )?.length;

  return (
    <Grid container spacing={4}>
      <Grid item md={12} xs={12}>
        <DataOverviewStats
          overallProgress={overallEsgProgress ? overallEsgProgress : 0}
          numberRequestedValues={numberRequestedEsgValues}
          completedFacilities={
            completedFacilities
              ? completedFacilities
              : {
                  completedFacilities: 0,
                  totalFacilities: 0,
                }
          }
          completedCategory={{
            completedCategories: totalCompletedFacilities ? totalCompletedFacilities : 0,
            totalCategories: esgProgressByCategory ? esgProgressByCategory.length : 0,
          }}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <OverviewDataAreas
          data={
            esgProgressByCategory && esgProgressByCategory.length > 0 ? esgProgressByCategory : []
          }
        />
      </Grid>
      <Grid item md={8} xs={12}>
        <OpenDataRequests openDataRequests={openDataRequests} />
      </Grid>
    </Grid>
  );
};

export default DataOverviewTab;
