import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';

interface Props {
  to: string;
  title: string;
}

const BackPageButton = ({ to, title }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <IconButton
      sx={{
        mb: 2,
      }}
      onClick={() => navigate(to)}
    >
      <ArrowBackIcon
        sx={{
          color: 'primary.main',
        }}
      />
      <Typography
        variant="body2"
        ml={2}
        sx={{
          color: 'colors.lightGrey',
        }}
      >
        {' '}
        {t(title)}
      </Typography>
    </IconButton>
  );
};

export default BackPageButton;
