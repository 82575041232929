import { gql } from '@apollo/client';
import { CORE_COLLECT_OVERVIEW_FIELDS } from '../fragments';

export const COLLECT_OVERVIEWS = gql`
  ${CORE_COLLECT_OVERVIEW_FIELDS}
  query CollectOverviews($year: Int!) {
    collectOverviews(year: $year) {
      ...CoreCollectOverviewFields
    }
  }
`;

export const COLLECT_OVERVIEW_BY_ID = gql`
  ${CORE_COLLECT_OVERVIEW_FIELDS}
  query CollectOverviewById($id: String!) {
    collectOverviewById(id: $id) {
      ...CoreCollectOverviewFields
    }
  }
`;

export const REMINDER = gql`
  query Reminder($input: ReminderInput!) {
    reminder(input: $input) {
      collectOverviewId
      createDate
      executionDate
      id
      status
      recipientId
    }
  }
`;

export const COLLECT_FILTERS = gql`
  query CollectFilter($year: Int!) {
    collectFilter(year: $year) {
      statuses
      assignedTo
      categories
      facilities
      userId
      rowsPerPageCount
      orderField
      orderDirection
      year
    }
  }
`;

export const COLLECT_OVERVIEW_YEARS_BY_EXTERNAL_USER = gql`
  query CollectYearsByExternal {
    collectYearsByExternal
  }
`;
