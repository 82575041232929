import React from 'react';
import { Box, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';

import { Chip } from 'src/modules/common/components/Chip';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import { getESGAreaChipColor } from 'src/modules/common/application/ui';
import { StyledTableCell } from 'src/modules/data-management/components/EsgDataInputTableRow/styles';
import { LinearProgressBar } from 'src/modules/common/components';

import { EvaluationTableTopics } from '../../types/materialEvaluation';

interface MaterialityEvaluationTableRowProps {
  data: EvaluationTableTopics;
  setShowActionId: (value: string) => void;
  selectedActionId: string;
}

export const MaterialityEvaluationTableRow = ({
  data,
  setShowActionId,
  selectedActionId,
}: MaterialityEvaluationTableRowProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <TableRow
      sx={{
        fontSize: '14px',
        backgroundColor: 'white',
      }}
      onMouseEnter={() => {
        setShowActionId(data.id);
      }}
      onMouseLeave={() => setShowActionId('')}
    >
      <StyledTableCell
        sx={{
          width: '16%',
        }}
      >
        <Typography paddingLeft={2} marginRight={1} variant="inherit" style={{ opacity: 1 }}>
          {t(`shared.types.materialTopic.${removeUnderscoreToCamelCase(data.materialTopic.title)}`)}
        </Typography>
      </StyledTableCell>
      <StyledTableCell sx={{ width: '14%' }}>
        <Chip
          size="small"
          styles={{
            maxWidth: 'fit-content',
            opacity: 1,
          }}
          colorVariant={getESGAreaChipColor(data.materialTopic.area)}
        >
          {t(`shared.types.esgArea.${removeUnderscoreToCamelCase(data.materialTopic.area)}`)}
        </Chip>
      </StyledTableCell>
      <StyledTableCell
        sx={{
          width: '14%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            ml: 2,
            opacity: 1,
            width: '100%',
            cursor: 'pointer',
          }}
        >
          <Typography
            variant="inherit"
            sx={{
              textAlign: 'left',
            }}
          >
            {data.materiality &&
            data.materiality.financialMateriality !== null &&
            data.materiality.financialMateriality !== undefined &&
            data.materiality.impactMateriality !== null &&
            data.materiality.impactMateriality !== undefined
              ? data.materiality.financialMateriality || data.materiality.impactMateriality
                ? t('shared.types.materiality.material')
                : t('shared.types.materiality.notMaterial')
              : '-'}
          </Typography>
        </Box>
      </StyledTableCell>
      <StyledTableCell
        sx={{
          width: '14%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            ml: 2,
            opacity: 1,
            width: '100%',
            cursor: 'pointer',
          }}
        >
          <Typography
            variant="inherit"
            sx={{
              textAlign: 'left',
            }}
          >
            {data.materiality &&
            data.materiality.financialMateriality !== null &&
            data.materiality.financialMateriality !== undefined
              ? data.materiality.financialMateriality
                ? t('shared.types.materiality.material')
                : t('shared.types.materiality.notMaterial')
              : '-'}
          </Typography>
        </Box>
      </StyledTableCell>
      <StyledTableCell sx={{ width: '14%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            ml: 2,
            opacity: 1,
            width: '100%',
            cursor: 'pointer',
          }}
        >
          <Typography
            variant="inherit"
            sx={{
              textAlign: 'left',
            }}
          >
            {data.materiality &&
            data.materiality.impactMateriality !== null &&
            data.materiality.impactMateriality !== undefined
              ? data.materiality.impactMateriality
                ? t('shared.types.materiality.material')
                : t('shared.types.materiality.notMaterial')
              : '-'}
          </Typography>
        </Box>
      </StyledTableCell>
      <StyledTableCell sx={{ width: '22%', overflow: 'hidden' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            ml: 2,
            paddingRight: 4,
          }}
        >
          <LinearProgressBar
            progress={(data.completedRequests / data.totalRequests) * 100}
            label={data.completedRequests + '/' + data.totalRequests}
            textStyle={{
              bgcolor: '#F8F9FB',
              width: '44px',
              color: '#68677F',
              borderRadius: '4px',
              textAlign: 'center',
              fontSize: '0.875rem !important',
              fontWeight: 500,
              py: 0.5,
            }}
          />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: 4,
              paddingRight: 2,
              width: '30%',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/materiality-assessment/evaluation/' + data.id);
            }}
          >
            {data.id === selectedActionId && <ArrowForwardIcon color="primary" />}
          </Box>
        </Box>
      </StyledTableCell>
    </TableRow>
  );
};
