import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export interface Props {
  height: string;
  minHeight?: string;
  width?: string;
  children: React.ReactNode;
}

const CardLayout = ({ children, minHeight, height, width }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: 2,
      }}
    >
      <Box
        mt={5}
        sx={{
          width: width ? width : 500,
          height: height,
          minHeight: minHeight,
          background: theme.palette.colors.white,
          paddingTop: 4,
          paddingBottom: 5,
          paddingLeft: 4,
          paddingRight: 4,
          borderRadius: 2.5,
          boxShadow: theme.custom.boxShadow,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default CardLayout;
