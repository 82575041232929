import useMessages from './use-messages';
import { UPDATE_USER_SETTING } from 'src/services/graphql/mutations';
import { t } from 'i18next';
import { User } from '@sentry/react';
import { Company, UpdateUserInput, UserAccountStatus } from 'src/modules/common/types';
import { GET_USER_LOGIN_DETAILS } from 'src/services/graphql/queries';
import { useEnhancedMutation } from 'src/modules/common/application';

const useUserSettings = (onCompleted: () => void | undefined = () => {}) => {
  const { setErrorMessage, setSuccessMessage } = useMessages();

  const [updateUserMutation, { loading }] = useEnhancedMutation(UPDATE_USER_SETTING, {
    onCompleted: () => {
      setSuccessMessage(t('messages.updateUser.success'));
      onCompleted();
    },
    onError: () => setErrorMessage(t('messages.updateUser.error')),
    update(cache, result, { variables }) {
      const { user, company } = cache.readQuery<{
        user: User;
        company: Company;
      }>({
        query: GET_USER_LOGIN_DETAILS,
      }) || { user: undefined, company: undefined };

      const valuesToOverride = Object.fromEntries(
        Object.entries(variables?.input).filter(([key, value]) => value !== undefined)
      );

      if (
        result.data.updateUser &&
        !(Object.keys(valuesToOverride).length === 1 && 'language' in valuesToOverride)
      ) {
        cache.writeQuery({
          query: GET_USER_LOGIN_DETAILS,
          data: {
            user: {
              ...user,
              ...valuesToOverride,
              status: UserAccountStatus.USER_INFO_COMPLETED,
            },
            company,
          },
        });
      }
    },
  });

  const updateUser = async (data: UpdateUserInput) => {
    await updateUserMutation({
      variables: {
        input: {
          firstName: data.firstName,
          lastName: data.lastName,
          department: data.department || undefined,
          position: data.position || undefined,
          language: data.language || undefined,
          numberFormat: data.numberFormat || undefined,
        } as UpdateUserInput,
      },
    });
  };

  return {
    updateUser,
    loading,
  };
};

export default useUserSettings;
