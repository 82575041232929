import React, { Suspense, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { Report } from 'src/modules/common/types';
import { getFrameworkChipColor, getYearChipColor } from 'src/modules/common/application/ui';
import { LinearProgressBar, Chip, Loader } from 'src/modules/common/components';
import { lazyWithRetry } from 'src/modules/common/application/lazyWithRetry';

interface Props {
  report: Report;
  setSelectedReport: (report: Report) => void;
  setOpen: (open: boolean) => void;
  setMode: (mode: 'create' | 'edit') => void;
  setShowDeleteDialog: (show: boolean) => void;
}

const ReportOverviewCard = ({
  report,
  setSelectedReport,
  setOpen,
  setMode,
  setShowDeleteDialog,
}: Props) => {
  const { t } = useTranslation();
  const { id, title, progress, year, framework } = report;
  const navigate = useNavigate();

  const LazyPdfDocumentWrapper = lazyWithRetry(
    () => import(/* webpackChunkName: "PDF" */ './PdfDocumentWrapper')
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleCardClick = () => {
    navigate(id);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleDownloadClick = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleEditClick = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    setSelectedReport(report);
    setMode('edit');
    setOpen(true);
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    setSelectedReport(report);
    setShowDeleteDialog(true);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          maxWidth: 450,
          width: '100%',
          border: '1px solid',
          borderColor: 'lightGrey',
          boxShadow: 'none',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        <CardContent
          sx={{
            padding: 4,
          }}
          onClick={handleCardClick}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 1,
              }}
            >
              <Typography variant="h3" fontSize="1.25rem !important">
                {title}
              </Typography>
              <IconButton
                size="small"
                onClick={handleMenuClick}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={handleEditClick}>
                  {t('modules.reporting.reportOverviewCard.edit')}
                </MenuItem>
                <MenuItem onClick={handleDownloadClick}>
                  <Suspense fallback={<Loader />}>
                    <LazyPdfDocumentWrapper report={report} />
                  </Suspense>
                </MenuItem>
                <MenuItem onClick={handleDeleteClick}>
                  {t('modules.reporting.reportOverviewCard.delete')}
                </MenuItem>
              </Menu>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Chip
                colorVariant={getYearChipColor(year)}
                styles={{
                  maxWidth: 'fit-content',
                  marginBottom: 3,
                }}
              >
                {year}
              </Chip>
              <Chip
                colorVariant={getFrameworkChipColor(framework)}
                styles={{
                  maxWidth: 'fit-content',
                  marginBottom: 3,
                  marginLeft: 1,
                }}
              >
                {framework}
              </Chip>
            </Box>

            <LinearProgressBar progress={progress || 0} />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ReportOverviewCard;
