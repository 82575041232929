import { useTranslation } from 'react-i18next';
import { Facility } from 'src/modules/common/types';
import { useEnhancedMutation, useEnhancedQuery, useMessages } from 'src/modules/common/application';
import { CREATE_FACILITY, DELETE_FACILITY, UPDATE_FACILITY } from 'src/services/graphql/mutations';
import { GET_FACILITIES } from 'src/services/graphql/queries';

const useFacility = ({
  selectedFacility,
  onCreateSuccess,
  onUpdateSuccess,
}: {
  selectedFacility?: Facility | null;
  onCreateSuccess?: () => void;
  onUpdateSuccess?: () => void;
}) => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { t } = useTranslation();

  const { loading, data, error } = useEnhancedQuery<{
    facilities: Facility[];
  }>(GET_FACILITIES, {
    fetchPolicy: 'cache-first',
    onError: () => setErrorMessage(t('messages.generic.error')),
  });

  const [createFacility, { loading: createFacilityLoading }] = useEnhancedMutation(
    CREATE_FACILITY,
    {
      onCompleted: () => {
        onCreateSuccess && onCreateSuccess();
        setSuccessMessage(t('messages.createFacility.success'));
      },
      onError: () => setErrorMessage(t('messages.createFacility.error')),
      update(cache, { data }) {
        const { facilities } = cache.readQuery<{ facilities: Facility[] }>({
          query: GET_FACILITIES,
        }) || { facilities: [] };
        cache.writeQuery({
          query: GET_FACILITIES,
          data: { facilities: [...facilities, data?.createFacility] },
        });
      },
    }
  );

  const [updateFacility, { loading: updateFacilityLoading }] = useEnhancedMutation(
    UPDATE_FACILITY,
    {
      onCompleted: () => {
        onUpdateSuccess && onUpdateSuccess();
        setSuccessMessage(t('messages.updateFacility.success'));
      },
      onError: () => setErrorMessage(t('messages.updateFacility.error')),
      update(cache, { data }) {
        const { facilities } = cache.readQuery<{ facilities: Facility[] }>({
          query: GET_FACILITIES,
        }) || { facilities: [] };

        const index = facilities.findIndex(report => report.id === data?.updateFacility?.id);

        if (data?.updateFacility && index > -1) {
          const newFacilities = [...facilities];
          newFacilities[index] = { ...data.updateFacility, contributors: [] };
          cache.writeQuery({
            query: GET_FACILITIES,
            data: { facilities: newFacilities },
          });
        }
      },
    }
  );

  const [deleteFacility, { loading: deleteFacilityLoading }] = useEnhancedMutation(
    DELETE_FACILITY,
    {
      onCompleted: () => {
        setSuccessMessage(t('messages.deleteFacility.success'));
      },
      onError: () => setErrorMessage(t('messages.deleteFacility.error')),
      update(cache, { data }) {
        const { facilities } = cache.readQuery<{ facilities: Facility[] }>({
          query: GET_FACILITIES,
        }) || { facilities: [] };
        const updatedFacilities = facilities.filter(
          facility => facility.id !== selectedFacility?.id
        );
        cache.writeQuery({
          query: GET_FACILITIES,
          data: { facilities: updatedFacilities },
        });
      },
    }
  );

  const getFacilityById = (facilityId: string) => {
    return data?.facilities?.find(facility => facility.id === facilityId);
  };

  return {
    loading,
    createFacilityLoading,
    updateFacilityLoading,
    deleteFacilityLoading,
    facilities: data?.facilities,
    error,
    getFacilityById,
    createFacility,
    updateFacility,
    deleteFacility,
  };
};

export default useFacility;
