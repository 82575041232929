import { useState } from 'react';
import useEsgKpi from 'src/modules/common/application/use-kpi';
import { TransformedCollectOverview } from 'src/modules/common/types/collect';

const useQuickView = () => {
  const [openQuickViewDialog, setOpenQuickViewDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [quickViewEsgKpis, setQuickViewEsgKpis] = useState<{ name: string; id: string }[] | []>([]);

  const { getKpisByCategory } = useEsgKpi();

  const handleQuickViewDialogOpen = (row: TransformedCollectOverview) => {
    const esgKpis = getKpisByCategory(row.categoryId);
    const quickViewValues = esgKpis.map(kpi => {
      return {
        name: kpi.title,
        id: kpi.id,
      };
    });
    setQuickViewEsgKpis(quickViewValues);
    setOpenQuickViewDialog(true);
  };

  const handleQuickViewDialogClose = () => {
    setOpenQuickViewDialog(false);
    setAnchorEl(undefined);
    setQuickViewEsgKpis([]);
  };

  return {
    handleQuickViewDialogOpen,
    handleQuickViewDialogClose,
    setAnchorEl,
    openQuickViewDialog,
    quickViewEsgKpis,
    anchorEl,
  };
};

export default useQuickView;
