import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableBody, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TableHeader from 'src/modules/common/components/Table/TableHeader';
import { MAEvaluationTableHeaders } from 'src/modules/materialityAssessment/application/materiality-assessment';

import { MaterialityEvaluationTableRow } from './MaterialityEvaluationTableRow';
import { sortMaterialEvaluationTopics } from '../../application/utils';
import { EvaluationTableTopics } from '../../types/materialEvaluation';

interface MaterialityEvaluationTableProps {
  topics: EvaluationTableTopics[];
}

export const MaterialityEvaluationTable = ({ topics }: MaterialityEvaluationTableProps) => {
  const { t } = useTranslation();
  const [showActionId, setShowActionId] = useState('');
  const [sortedTopics, setSortedTopics] = useState<EvaluationTableTopics[]>(
    sortMaterialEvaluationTopics(topics)
  );
  const headers = MAEvaluationTableHeaders.map(t) as string[];

  useEffect(() => {
    setSortedTopics(sortMaterialEvaluationTopics(topics));
  }, [topics]);

  return (
    <Box>
      <TableContainer sx={theme => ({ border: theme.custom.border })}>
        <Table
          sx={{
            tableLayout: 'auto',
            width: '100%',
          }}
        >
          <TableHeader headers={headers} showLastHeader={false} />
          <TableBody>
            {sortedTopics?.map((row, index) => (
              <MaterialityEvaluationTableRow
                data={row}
                key={index}
                selectedActionId={showActionId}
                setShowActionId={(value: string) => setShowActionId(value)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
