import React, { useContext } from 'react';
import {
  Box,
  SvgIcon,
  ListItem,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { getMainNavigation } from 'src/modules/common/types/constants/drawer-menu-items';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import kebabCase from 'lodash/kebabCase';

import UserContext from 'src/modules/common/application/context/UserContext';
import { UserRole } from 'src/modules/common/types';
import { IntercomDataAttribute } from 'src/modules/common/types/intercom';

import { getListItemStyles } from './styles';

const DrawerMenu = ({ drawerExpanded }: { drawerExpanded: boolean }) => {
  const { user } = useContext(UserContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const menuItems = getMainNavigation();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        cursor: 'pointer',
        margin: '32px 12px 32px 2px',
      }}
    >
      <List>
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          menuItems.map(
            (
              {
                Icon,
                label,
                to,
                allowedRoles,
                intercomDataAttribute,
              }: {
                Icon: any;
                label: string;
                to: string;
                allowedRoles: UserRole[];
                intercomDataAttribute: IntercomDataAttribute;
              },
              index
            ) => {
              const isActiveNavItem =
                location.pathname === to || (to !== '/' && location.pathname.includes(to));
              const render = allowedRoles.includes(user?.role as UserRole);
              return (
                render && (
                  <ListItem
                    key={index}
                    sx={{
                      display: 'flex',
                      py: '4px',
                    }}
                    data-intercom-target={intercomDataAttribute}
                  >
                    <ListItemButton
                      sx={getListItemStyles(theme, drawerExpanded)}
                      selected={isActiveNavItem}
                      onClick={() => navigate(to)}
                      data-cy={`menu-link-${kebabCase(label)}`}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <SvgIcon
                          component={Icon}
                          inheritViewBox
                          sx={{
                            fontSize: 62,
                            marginTop: 2,
                            justifyContent: 'center',
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        sx={{
                          opacity: drawerExpanded ? 1 : 0,
                        }}
                      >
                        {t(label)}
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                )
              );
            }
          )
        }
      </List>
    </Box>
  );
};

export default DrawerMenu;
