import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';

import UserList from 'src/modules/super/components/User/UserList';

import useUserSuper from 'src/modules/common/application/use-user-super';
import UserForm from './UserForm';

const UserAdminPanel = ({ companyId }: { companyId: string }) => {
  const { t } = useTranslation();
  const { users, user, handleSelectUser, handleSubmitUser, editMode } = useUserSuper(companyId);

  return (
    <Grid item xs={4}>
      <Typography variant="h5">{t('modules.super.user.title')}</Typography>
      <UserList users={users} selected={user} onSelect={handleSelectUser} />
      <UserForm
        data={user}
        handleSubmit={user => handleSubmitUser(user, companyId)}
        editMode={editMode}
        handleReset={() => handleSelectUser('')}
      />
    </Grid>
  );
};

export default UserAdminPanel;
