import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { getInputLabelStyles } from 'src/modules/common/application/form-styles';
import Button from 'src/modules/common/components/Button/Button';
import { createYears } from 'src/modules/common/application/year';

interface Props {
  years: number[];
  onInformationSubmit: (years: number[]) => void;
  onReset: () => void;
  disabled?: boolean;
  showCancel?: boolean;
  loading: boolean;
}

const EditOtherDetailsForm = ({
  years,
  disabled,
  onInformationSubmit,
  onReset,
  loading,
}: Props) => {
  const { t } = useTranslation();
  const formInitialValues = {
    years: createYears(years)
      .filter(year => year.disabled)
      .map(year => year.year),
  };

  const formik = useFormik<typeof formInitialValues>({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (_values: any) => {
      onInformationSubmit(formik.values.years);
    },
  });

  const handleReset = () => {
    formik.resetForm();
    onReset();
  };

  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
        style={{
          maxWidth: '450px',
          width: '100%',
        }}
      >
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={12} marginBottom={2} marginTop={2}>
            <FormControl fullWidth>
              <InputLabel shrink required htmlFor="collectionYear" sx={getInputLabelStyles}>
                {t('modules.settings.other.collectionYear')}
              </InputLabel>
              <Select
                id="collectionYear"
                name="collectionYear"
                multiple
                disabled={disabled}
                value={formik.values.years}
                onChange={e => {
                  formik.setFieldValue('years', e.target.value);
                }}
              >
                {createYears(years).map(year => (
                  <MenuItem key={year.year} value={year.year} disabled={year.disabled}>
                    {year.year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {!disabled && (
          <Grid
            container
            sx={{
              mb: 2,
              ml: 2,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              '@media (max-width: 600px)': {
                flexDirection: 'column',
              },
            }}
          >
            <Box>
              <Button size="small" fullWidth type="submit" loading={loading}>
                {t('modules.settings.editPersonalDetails.save')}
              </Button>
            </Box>
            <Box pl={2}>
              <Button onClick={handleReset} size="small" variant="outlined">
                {t('modules.settings.editPersonalDetails.cancel')}
              </Button>
            </Box>
          </Grid>
        )}
      </form>
    </>
  );
};

export default EditOtherDetailsForm;
