export enum ChatMessageRole {
  SYSTEM = 'SYSTEM',
  USER = 'USER',
  ASSISTANT = 'ASSISTANT',
}

export interface ChatMessage {
  role: ChatMessageRole;
  content: string;
}

export interface EnhancedChatMessage extends ChatMessage {
  showAnimated?: boolean;
}
