import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Menu,
  MenuItem,
  MenuProps,
} from '@mui/material';

import { getBottomNavigation } from 'src/modules/common/types/constants';
import { LOGOUT } from 'src/services/graphql/mutations';
import { useMessages, useUserData, useEnhancedMutation } from 'src/modules/common/application';
import { getListItemStyles } from 'src/modules/common/components/Base/Drawer/styles';
import { removeLocalStorage } from 'src/modules/common/application/localstorage';
import { localStorageKeys } from 'src/modules/common/types/localstorage';
import useLanguage from 'src/modules/common/application/use-language';
import { LanguageCode } from 'src/modules/common/types';

interface Props {
  drawerExpanded: boolean;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    width: '54px',
    boxShadow: theme.custom.boxShadow,
    border: theme.custom.border,
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
  },
}));

const DrawerBottom = ({ drawerExpanded }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { t } = useTranslation();
  const { hasCompleteProfile, hasCompanyOnboarded } = useUserData();
  const { setLanguage } = useLanguage();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickLanguageSwich = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSwitch = (lang: string) => {
    if (setLanguage !== undefined)
      setLanguage(lang as LanguageCode)?.then(() => {
        setAnchorEl(null);
      });
  };

  const [logout] = useEnhancedMutation(LOGOUT, {
    onError: () => setErrorMessage(t('messages.logout.error')),
    onCompleted: () => {
      removeLocalStorage(localStorageKeys.i18nextLng);
      const redirectPath = '/login';
      navigate(redirectPath);
      setSuccessMessage(t('messages.logout.success'));
    },
    fetchPolicy: 'no-cache',
  });

  const handleClickSettings = () => navigate('/settings');
  const handleClickLogout = () => logout();

  const menuItems = getBottomNavigation([
    handleClickSettings,
    handleClickLanguageSwich,
    handleClickLogout,
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        marginTop: 'auto',
        flexDirection: 'column',
        marginBottom: '32px',
      }}
    >
      <List>
        {menuItems.map(
          (
            {
              Icon,
              label,
              handleClick,
              dataCy,
            }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
            { Icon: any; label: string; handleClick: any; dataCy: string },
            index
          ) => {
            if (
              (!hasCompleteProfile || !hasCompanyOnboarded) &&
              dataCy !== 'action-logout' &&
              dataCy !== 'action-language'
            )
              return null;

            if (hasCompleteProfile && hasCompanyOnboarded && dataCy === 'action-language')
              return null;

            return (
              <ListItem
                key={index}
                sx={{
                  display: 'flex',
                  py: '4px',
                }}
              >
                <ListItemButton
                  sx={getListItemStyles(theme, drawerExpanded)}
                  onClick={handleClick}
                  data-cy={dataCy}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      marginTop: 2,
                      justifyContent: 'center',
                    }}
                  >
                    <SvgIcon component={Icon} inheritViewBox sx={{ fontSize: 60 }} />
                  </ListItemIcon>
                  <ListItemText disableTypography sx={{ opacity: drawerExpanded ? 1 : 0 }}>
                    {t(label)}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          }
        )}
      </List>
      {(!hasCompleteProfile || !hasCompanyOnboarded) && (
        <StyledMenu theme={theme} anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem onClick={() => handleLanguageSwitch('en')} disableRipple>
            EN
          </MenuItem>
          <MenuItem onClick={() => handleLanguageSwitch('de')} disableRipple>
            DE
          </MenuItem>
        </StyledMenu>
      )}
    </Box>
  );
};

export default DrawerBottom;
