import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

import { useMessages, useEnhancedMutation } from 'src/modules/common/application';
import { LOGOUT } from 'src/services/graphql/mutations';

import { localStorageKeys } from 'src/modules/common/types/localstorage';
import { removeLocalStorage } from 'src/modules/common/application/localstorage';

const useLogout = (showSuccessMessage = true) => {
  const posthog = usePostHog();
  const { t } = useTranslation();
  const { setErrorMessage, setSuccessMessage } = useMessages();

  const [logout, { loading }] = useEnhancedMutation(LOGOUT, {
    onError: () => setErrorMessage(t('messages.logout.error')),
    onCompleted: () => {
      removeLocalStorage(localStorageKeys.i18nextLng);
      posthog?.reset();
      if (showSuccessMessage) setSuccessMessage(t('messages.logout.success'));
    },
    fetchPolicy: 'no-cache',
  });

  return {
    loading,
    logout,
  };
};

export default useLogout;
