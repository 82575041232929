import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ApexOptions } from 'apexcharts';
import theme from 'src/styles/theme';
import { WaterConsumptionEmissionsFacility } from 'src/modules/common/types';
import EnhancedChart from 'src/modules/common/components/EnhancedChart';

interface IWaterConsumptionPerFacility {
  waterConsumption: WaterConsumptionEmissionsFacility[];
}

const WaterConsumptionPerFacility = ({ waterConsumption }: IWaterConsumptionPerFacility) => {
  const { t } = useTranslation();

  const names: string[] = [];
  const waterConsumptions: number[] = [];

  for (const item of waterConsumption) {
    names.push(item.Facility.name);
    waterConsumptions.push(item.Emission);
  }

  const chartOptions: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: [
      theme.palette.colors.pearlAqua,
      theme.palette.colors.summerGreen,
      theme.palette.colors.cascade,
      theme.palette.colors.greyChateau,
      theme.palette.colors.regentGrey,
    ],
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      //didn't translate because will be coming from backend
      categories: names,
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return val + ' m³';
        },
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      markers: {
        radius: 10,
      },
    },
  };

  const chartSeries = [
    {
      name: t('modules.analysis.waterConsumptionPerFacility.waterConsumption'),
      data: waterConsumptions,
    },
  ];

  return (
    <EnhancedChart
      noData={waterConsumption.length === 0}
      cardProps={{
        title: t('modules.analysis.waterConsumptionPerFacility.title'),
        infoText: t('modules.analysis.waterConsumptionPerFacility.info'),
      }}
      chartProps={{
        height: '95%',
        options: chartOptions,
        series: chartSeries,
        type: 'bar',
      }}
    />
  );
};

export default WaterConsumptionPerFacility;
