import React from 'react';
import { useTranslation } from 'react-i18next';
import { TabBar } from 'src/modules/common/components';
import BusinessInfo from '../BusinessInfo/BusinessInfo';
import ManageTeam from '../ManageTeam/ManageTeam';
import UserInfo from '../UserInfo/UserInfo';
import { ManageFacility } from '../ManageFacility';
import OtherSettings from '../OtherSettings/OtherSettings';
import GeneralSettings from '../GeneralSettings/GeneralSettings';
import { useUserData } from 'src/modules/common/application';

const UserSettings = () => {
  const { t } = useTranslation();
  const { isAdmin } = useUserData();
  const tabsArray = isAdmin
    ? [
        {
          input: <GeneralSettings />,
          label: t('modules.settings.general.tabTitle'),
          index: 0,
          id: 'simpletab-one',
        },
        {
          input: <UserInfo />,
          label: t('modules.settings.userInfoTab'),
          index: 1,
          id: 'simpletab-two',
        },
        {
          input: <BusinessInfo />,
          label: t('modules.settings.businessInfoTab'),
          index: 2,
          id: 'simpletab-three',
        },
        {
          input: <ManageTeam />,
          label: t('modules.settings.teamTab'),
          index: 3,
          id: 'simpletab-four',
        },
        {
          input: <ManageFacility />,
          label: t('modules.settings.facilityTab'),
          index: 4,
          id: 'simpletab-five',
        },
        {
          input: <OtherSettings />,
          label: t('modules.settings.otherTab'),
          index: 5,
          id: 'simpletab-six',
        },
      ]
    : [
        {
          input: <GeneralSettings />,
          label: t('modules.settings.general.tabTitle'),
          index: 0,
          id: 'simpletab-one',
        },
        {
          input: <UserInfo />,
          label: t('modules.settings.userInfoTab'),
          index: 1,
          id: 'simpletab-two',
        },
      ];

  return <TabBar content={tabsArray} />;
};

export default UserSettings;
