import React from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

import theme from 'src/styles/theme';

import RoadmapStep from '../RoadmapStep/RoadmapStep';
import { IRoadmapStep } from '../../types';

interface IRoadmapContainerProps {
  roadmapStepsData: IRoadmapStep[];
}

const RoadmapContainer = (props: IRoadmapContainerProps) => {
  const { t } = useTranslation();
  const { roadmapStepsData } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.colors.white,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: `${theme.palette.background.grey} !important`,
        padding: '2rem',
        borderRadius: '10px',
        marginTop: '1rem',
      }}
    >
      <Typography fontSize={'1.25rem !important'} fontWeight={600}>
        {t('modules.home.sustainabilityReportingRoadmap.title')}
      </Typography>
      <Typography
        sx={{ color: theme.palette.colors.lightGrey }}
        fontSize={'0.875rem !important'}
        fontWeight={500}
        marginBottom={1}
      >
        {t('modules.home.sustainabilityReportingRoadmap.subtitle')}
      </Typography>
      <RoadmapStep roadmapStepsData={roadmapStepsData} />
    </Box>
  );
};

export default RoadmapContainer;
