import React, { useEffect, useState } from 'react';
import { ApolloClient, ApolloProvider as OriginalApolloProvider } from '@apollo/client';
import Firebase from 'src/services/firebase.service';
import initializeApolloClient from './client';

interface Props {
  children: React.ReactNode;
  firebase: Firebase;
  graphqlEndpoint: string;
}

const ApolloProvider = ({ children, firebase, graphqlEndpoint }: Props) => {
  const [apolloClient, setApolloClient] = useState<ApolloClient<unknown> | undefined>(undefined);
  const hasClient = !!apolloClient;

  useEffect(() => {
    if (hasClient) return;

    async function init() {
      // wait for store to by hydrated from localStorage
      const client = await initializeApolloClient({
        firebase,
        uri: graphqlEndpoint,
      });
      setApolloClient(client);
    }

    init();
  }, [hasClient, firebase, graphqlEndpoint]);
  if (!apolloClient) return null;

  return <OriginalApolloProvider client={apolloClient}>{children}</OriginalApolloProvider>;
};

export default ApolloProvider;
