import React from 'react';
import { Card, CardContent, CardHeader, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface IProps {
  children: React.ReactNode;
  title: string;
  infoText: string;
  showInfoIcon?: boolean;
  isScrollable?: boolean;
}

const InfoCard = ({
  children,
  title,
  infoText,
  showInfoIcon = true,
  isScrollable = false,
}: IProps) => {
  const scrollableStyles = isScrollable && { overflow: 'auto' };

  return (
    <Card
      sx={{
        height: '350px',
        border: '1px solid',
        borderColor: 'lightGrey',
        boxShadow: 'none',
        ...scrollableStyles,
      }}
    >
      <CardHeader
        titleTypographyProps={{
          component: 'h3',
          fontSize: '1rem !important',
        }}
        title={title}
        action={
          showInfoIcon && (
            <Tooltip title={infoText}>
              <InfoOutlinedIcon sx={{ color: 'action.active' }} />
            </Tooltip>
          )
        }
        sx={{
          '& .MuiCardHeader-action': {
            cursor: 'pointer',
            margin: 0,
            transform: 'translate(4px, -4px)',
          },
        }}
      />
      <CardContent sx={{ height: '90%', padding: '0', overflow: 'auto' }}>{children}</CardContent>
    </Card>
  );
};

export default InfoCard;
