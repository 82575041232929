import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageContainer, PageTitle } from 'src/modules/common/components';
import HomePageContainer from 'src/modules/home/components/HomePageContainer/HomePageContainer';

const Home = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <PageTitle title={t('modules.home.pageTitle')} />
      <HomePageContainer />
    </PageContainer>
  );
};

export default Home;
