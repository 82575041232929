import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { AppBreadCrumb, Loader } from 'src/modules/common/components';
import { useMessages } from 'src/modules/common/application';
import ReportStructureSidebar from 'src/modules/reporting/components/ReportStructureSidebar/ReportStructureSidebar';
import ReportInput from 'src/modules/reporting/components/ReportInput/ReportInput';

import useReportSection from '../../application/use-report';

const ReportSection = ({ reportId, sectionId }: { reportId: string; sectionId?: string }) => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();
  const navigate = useNavigate();

  const {
    title,
    loading,
    error,
    reportYear,
    reportFramework,
    getFirstReportSection,
    breadCrumbs,
    tree,
    isReportStructureSidebarOpen,
    reportSection,
    handleReportStructureSidebarMenuClick,
  } = useReportSection(reportId, sectionId);

  if (loading) return <Loader />;
  if (error) return <>{setErrorMessage(t('messages.getUserInfo.error'))}</>;

  if (!sectionId) {
    return (
      <Navigate
        to={'/reports/' + reportId + '/section/' + getFirstReportSection(reportFramework)?.id}
      />
    );
  }

  if (!reportSection) return <>{setErrorMessage(t('messages.getUserInfo.error'))}</>;

  return (
    <Box>
      <Box py={3} display="flex" flexDirection="row" alignItems="center">
        <ArrowBackIcon
          sx={{
            color: 'primary.main',
            height: '20px',
            width: '20px',
            mr: 1,
            cusrsor: 'pointer',
          }}
          onClick={() => navigate('/reports')}
        />
        <AppBreadCrumb data={breadCrumbs} />
      </Box>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid
          item
          lg={isReportStructureSidebarOpen ? 3 : 1}
          md={isReportStructureSidebarOpen ? 3 : 1}
          sm={6}
          xs={12}
          sx={{ pt: '0 !important' }}
        >
          <ReportStructureSidebar
            tree={tree}
            reportYear={reportYear}
            reportFramework={reportFramework}
            handleReportStructureSidebarMenuClick={handleReportStructureSidebarMenuClick}
            isSidebarCollapsed={!isReportStructureSidebarOpen}
          />
        </Grid>
        <Grid
          item
          lg={isReportStructureSidebarOpen ? 9 : 11}
          md={isReportStructureSidebarOpen ? 9 : 11}
          sm={isReportStructureSidebarOpen ? 6 : 11}
          xs={12}
          sx={{ pt: '0 !important' }}
        >
          <ReportInput
            reportId={reportId}
            reportSection={reportSection}
            year={reportYear}
            title={title}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportSection;
