import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Grid } from '@mui/material';

import {
  CreateReportInput,
  Report,
  ReportingFramework,
  UpdateReportInput,
} from 'src/modules/common/types';
import { useMessages, useYears } from 'src/modules/common/application';
import useReportsManagement from 'src/modules/reporting/application/use-reports-management';
import { Loader, DeleteObjectDialog } from 'src/modules/common/components';

import ReportForm from '../ReportForm/ReportForm';
import ReportOverviewCard from '../ReportOverviewCard/ReportOverviewCard';

interface ReportsOverviewProps {
  mode: 'create' | 'edit';
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  setMode: (mode: 'create' | 'edit') => void;
}

const ReportsOverview = (props: ReportsOverviewProps) => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();

  const { mode, isOpen, setOpen, setMode } = props;

  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const {
    data: reportsData,
    loading: reportsLoading,
    createReportLoading,
    deleteReportLoading,
    error: reportsError,
    createReport,
    updateReport,
    deleteReport,
  } = useReportsManagement({ selectedReport });
  const { data: yearsData, loading: yearsLoading, error: yearsError, sortedYears } = useYears();

  if (reportsLoading || yearsLoading || !reportsData || !yearsData) return <Loader />;
  if (reportsError || yearsError) return <>{setErrorMessage(t('messages.generic.error'))}</>;

  const { reports } = reportsData;
  const years = sortedYears ? sortedYears : [];

  const handleSubmit = (input: CreateReportInput) => {
    if (mode === 'create') {
      createReport({
        variables: {
          input,
        },
      });
    } else {
      updateReport({
        variables: {
          input: {
            reportId: selectedReport?.id,
            title: input.title,
            year: input.year,
          } as UpdateReportInput,
        },
      });
    }
    setOpen(false);
  };

  const handleDelete = () => {
    deleteReport({
      variables: {
        reportId: selectedReport?.id,
      },
    });
    setShowDeleteDialog(false);
  };

  return (
    <>
      <Box>
        {reports.length === 0 ? (
          <Box sx={{ position: 'absolute', top: '50%', left: '40%' }}>
            <Box display="flex" justifyContent="center">
              <Typography color="text.secondary" variant="body1" mt={1}>
                {t('modules.reporting.reportsOverview.noReports')}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Grid container spacing={3} mt={0}>
            {reports.map(f => (
              <Grid key={f.id} item lg={3} md={4} sm={6} xs={12}>
                <ReportOverviewCard
                  report={f}
                  setSelectedReport={setSelectedReport}
                  setOpen={setOpen}
                  setMode={setMode}
                  setShowDeleteDialog={setShowDeleteDialog}
                  key={f.id}
                />
              </Grid>
            ))}
          </Grid>
        )}
        <ReportForm
          years={years}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          mode={mode}
          onSubmit={handleSubmit}
          loading={createReportLoading}
          editReportValues={{
            title: selectedReport?.title || '',
            framework: selectedReport?.framework || ReportingFramework.GRI,
            year: selectedReport?.year?.toString() || '',
          }}
        />
        <DeleteObjectDialog
          isOpen={showDeleteDialog}
          isLoading={deleteReportLoading}
          onClose={() => setShowDeleteDialog(false)}
          onDelete={handleDelete}
          deleteConfirmation={
            t('shared.components.deleteObjectDialog.confirmation') +
            selectedReport?.title! +
            t('shared.components.deleteObjectDialog.confirmationPartTwo')
          }
          info={t('shared.components.deleteObjectDialog.info')}
        />
      </Box>
    </>
  );
};

export default ReportsOverview;
