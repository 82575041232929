import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody } from '@mui/material';

import { OpenDataRequest, TranslatedOpenDataRequest } from 'src/modules/common/types';
import TableHeader from 'src/modules/common/components/Table/TableHeader';
import { genericSortThreeColumns } from 'src/modules/common/application/sort';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';

import { getHeaders } from './constants';
import OpenDataRequestTableRow from './OpenDataRequestTableRow';

interface Props {
  openDataRequests: OpenDataRequest[];
  onRowClick?: (collectOverviewId: string) => void;
}

const OpenDataRequestTable = ({ openDataRequests, onRowClick }: Props) => {
  const { t } = useTranslation();
  const headers = getHeaders.map(t) as string[];

  const sortedRequests: TranslatedOpenDataRequest[] = JSON.parse(JSON.stringify(openDataRequests));
  sortedRequests.forEach(request => {
    request.translatedStatus = t(
      `shared.types.status.${removeUnderscoreToCamelCase(request.status)}`
    );
    request.translatedEsgCategory = t(
      `shared.types.esgCategory.${removeUnderscoreToCamelCase(request.category.title)}`
    );
    request.locationName = request.facility.city;
  });

  genericSortThreeColumns(
    sortedRequests,
    'translatedStatus',
    'translatedEsgCategory',
    'locationName'
  );

  return (
    <Table data-testid="open-data-request-table" sx={{ tableLayout: 'auto', width: '100%' }}>
      <TableHeader
        headers={headers}
        showLastHeader={false}
        headerCellStyles={{ fontSize: '0.875rem !important' }}
      />
      <TableBody>
        {sortedRequests.map(t => (
          <OpenDataRequestTableRow key={t.collectOverviewId} data={t} onRowClick={onRowClick} />
        ))}
      </TableBody>
    </Table>
  );
};

export default OpenDataRequestTable;
