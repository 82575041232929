import React from 'react';
import { Box, Typography } from '@mui/material';

import { Avatar } from 'src/modules/common/components';
import { getUserLabel } from 'src/modules/common/application/user';
import { useUserData } from 'src/modules/common/application';
import theme from 'src/styles/theme';

interface UserMessageProps {
  message: string;
}
const UserMessage = (props: UserMessageProps) => {
  const { message } = props;
  const { user } = useUserData();

  return (
    <React.Fragment>
      <Avatar
        input={`${getUserLabel(user!)}` || ''}
        styles={{
          width: '30px',
          height: '30px',
          fontSize: '0.675rem',
          borderWidth: 1,
          padding: 1,
        }}
      />
      <Box
        sx={{
          maxWidth: '80%',
          background: theme.palette.primary.main,
          py: '8px',
          px: '12px',
          color: '#fff',
          borderRadius: '10px',
          mr: 1,
          mb: '15px',
        }}
      >
        <Typography variant="subtitle2" fontWeight={500}>
          {message}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default UserMessage;
