import React, { Fragment } from 'react';
import { CircularProgress, Typography, Box, SxProps } from '@mui/material';
import { FadeMount } from '../../Animations';

interface Props {
  label?: string;
  size?: number;
  spinner?: boolean;
  overlay?: boolean;
  overlayShimmer?: boolean;
  spinnerStyle?: SxProps;
}

const Loader = ({
  label,
  size,
  overlay = false,
  overlayShimmer = false,
  spinner = true,
  spinnerStyle = {},
}: Props) => {
  return (
    <Fragment>
      {overlay && (
        <FadeMount>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              background: !overlayShimmer ? '#FFFFFF' : '#000',
              opacity: !overlayShimmer ? '1' : '0.2 !important',
              zIndex: 1,
            }}
          >
            {overlayShimmer && <div className="shimmer" />}
          </Box>
        </FadeMount>
      )}
      {spinner && (
        <Box
          component="div"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            zIndex: 1,
            ...spinnerStyle,
          }}
        >
          <CircularProgress size={size} />
          {label && (
            <Typography noWrap variant="subtitle2">
              {label}
            </Typography>
          )}
        </Box>
      )}
    </Fragment>
  );
};

export default Loader;
