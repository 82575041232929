import { gql } from '@apollo/client';

export const CORE_COLLECT_OVERVIEW_FIELDS = gql`
  fragment CoreCollectOverviewFields on CollectOverview {
    requestedProgress
    assignedById
    assignedToId
    category {
      id
      title
    }
    dueDate
    facility {
      id
      name
    }
    id
    progress
    status
    year
  }
`;
