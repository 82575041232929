import React from 'react';
import type { ApexOptions } from 'apexcharts';
import { useTranslation } from 'react-i18next';
import theme from 'src/styles/theme';
import EnhancedChart from 'src/modules/common/components/EnhancedChart';

const LevelGenderStructureChart = ({
  totalFemaleManagerEmployees,
  totalFemaleNonManagerEmployees,
  totalMaleManagerEmployees,
  totalMaleNonManagerEmployees,
}: {
  totalFemaleManagerEmployees?: number | null;
  totalFemaleNonManagerEmployees?: number | null;
  totalMaleManagerEmployees?: number | null;
  totalMaleNonManagerEmployees?: number | null;
}) => {
  const { t } = useTranslation();

  const chartOptions: ApexOptions = {
    chart: {
      stacked: true,
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '20%',
      },
    },
    colors: [theme.palette.colors.pearlAqua, theme.palette.colors.summerGreen],
    labels: [
      t(`modules.analysis.levelGenderStructureChart.managerial`),
      t(`modules.analysis.levelGenderStructureChart.nonManagerial`),
    ],
    legend: {
      markers: {
        radius: 10,
      },
    },

    responsive: [
      {
        breakpoint: 1250,
        options: {
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const chartSeries = [
    {
      name: t('modules.analysis.levelGenderStructureChart.male'),
      data: [
        totalMaleManagerEmployees ? totalMaleManagerEmployees : 0,
        totalMaleNonManagerEmployees ? totalMaleNonManagerEmployees : 0,
      ],
    },
    {
      name: t('modules.analysis.levelGenderStructureChart.female'),
      data: [
        totalFemaleManagerEmployees ? totalFemaleManagerEmployees : 0,
        totalFemaleNonManagerEmployees ? totalFemaleNonManagerEmployees : 0,
      ],
    },
  ];

  return (
    <EnhancedChart
      cardProps={{
        title: t('modules.analysis.levelGenderStructureChart.title'),
        infoText: t('modules.analysis.levelGenderStructureChart.info'),
      }}
      chartProps={{
        height: '90%',
        options: chartOptions,
        series: chartSeries,
        type: 'bar',
      }}
      noData={
        !totalFemaleManagerEmployees &&
        !totalFemaleNonManagerEmployees &&
        !totalMaleManagerEmployees &&
        !totalMaleNonManagerEmployees
      }
    />
  );
};

export default LevelGenderStructureChart;
