import React from 'react';
import { Box, Typography, Divider, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Props {
  title?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TitleRowActionItem?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  titleRowActionItemProps?: Record<string, unknown>;
  spacing?: number;
  showDivider?: boolean;
  showLightTitle?: boolean;
  children: React.ReactElement;
}

const PageSection = ({
  title,
  TitleRowActionItem,
  titleRowActionItemProps,
  spacing = 3,
  showDivider = false,
  showLightTitle = false,
  children,
}: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2, 0, 1, 1),
        justifyContent: 'space-between',
        mt: '8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          paddingBottom: theme.spacing(3),
        }}
      >
        {title && <Typography variant="h5">{title}</Typography>}
        {TitleRowActionItem && <TitleRowActionItem {...titleRowActionItemProps} />}
      </Box>
      <Grid spacing={spacing} alignItems="stretch" container>
        {children}
      </Grid>
      {showDivider && (
        <Divider
          light
          sx={{ color: 'primary', width: '100%', height: '30px', py: theme.spacing(1) }}
        />
      )}
    </Box>
  );
};
export default PageSection;
