import React from 'react';
import { CALCULATE_OVERALL_REPORT_PROGRESS } from 'src/services/graphql/mutations';
import { useMessages, useEnhancedMutation } from 'src/modules/common/application';
import { useTranslation } from 'react-i18next';
import Button from 'src/modules/common/components/Button/Button';

const OverallReportProgressCalculations = () => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { t } = useTranslation();

  const [calculateOverallStatistics, { loading }] = useEnhancedMutation(
    CALCULATE_OVERALL_REPORT_PROGRESS,
    {
      onCompleted: () => setSuccessMessage(t('messages.calculation.success')),
      onError: () => setErrorMessage(t('messages.calculation.error')),
    }
  );

  const handleClick = () => {
    calculateOverallStatistics();
  };

  return (
    <Button
      sx={{
        width: '100%',
        mt: 2,
      }}
      onClick={handleClick}
      loading={loading}
    >
      Calculate Overall Report Progress
    </Button>
  );
};

export default OverallReportProgressCalculations;
