import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AppIcon } from 'src/modules/common/components/icons';

const ThinkingMessage = () => {
  const { t } = useTranslation();

  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    const fullText = t('modules.chat.thinking');
    let i = 0;

    const intervalId = setInterval(() => {
      setDisplayedText(fullText.slice(0, i));
      i++;

      if (i > fullText.length) {
        i = 1;
      }
    }, 130);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '30px',
          height: '30px',
          borderWidth: 1,
          borderColor: 'red',
          borderRadius: '15px',
          backgroundColor: '#F8F9FB',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <AppIcon
          style={{
            width: '25px',
            height: '25px',
            transform: 'scale(0.5)',
          }}
        />
      </Box>
      <Box
        sx={{
          maxWidth: '80%',
          background: '#F8F9FB',
          py: '8px',
          px: '12px',
          color: '#000',
          borderRadius: '10px',
          ml: 1,
          mb: '15px',
        }}
      >
        <Typography variant="subtitle2" fontWeight={500}>
          {displayedText}
        </Typography>
      </Box>
    </Box>
  );
};

export default ThinkingMessage;
