import { gql } from '@apollo/client';

export const CORE_ESG_VALUE_FIELDS = gql`
  fragment CoreEsgValueFields on EsgValue {
    collectOverview {
      id
    }
    id
    kpi {
      description
      frameworks {
        esrs
        gri
        dnk
      }
      category {
        id
        title
      }
      id
      source
      type
      title
      units
    }
    selectedUnit
    value
  }
`;
