import React, { useEffect, useContext } from 'react';
import FirebaseContext from './FirebaseContext';
import { useUserData } from '..';
import { IntercomConstants } from 'src/modules/common/types/constants';
import { LanguageCode } from 'src/modules/common/types';

// eslint-disable-next-line import/no-named-as-default-member
const IntercomContext = React.createContext<null>(null);

export const IntercomProvider = ({ children }: { children: React.ReactNode }) => {
  const { authenticated } = useContext(FirebaseContext);
  const { user, company, loadingUserData } = useUserData();

  useEffect(() => {
    if (authenticated && !loadingUserData && user && Object.keys(user).length > 0) {
      const intercomScript = document.createElement('script');
      intercomScript.async = true;
      intercomScript.src = IntercomConstants.INTERCOM_SCRIPT_URL;
      document.head.appendChild(intercomScript);

      intercomScript.onload = () => {
        window.intercomSettings = {
          app_id: IntercomConstants.INTERCOM_API_ID,
          api_base: IntercomConstants.INTERCOM_API_BASE_URL,
        };

        window.Intercom('boot', {
          user_id: user.id,
          email: user.email,
          name: `${user.firstName} ${user?.lastName}`,
          created_at: user.createdTimestamp,
          company: company,
          language_override: user.language,
          preferred_language: user.language,
          role: user.role,
        });
      };

      document.head.appendChild(intercomScript);

      return () => {
        if (window.Intercom) {
          window.Intercom('shutdown');
        }
        document.head.removeChild(intercomScript);
      };
    }
  }, [authenticated, company, loadingUserData, user]);

  return <IntercomContext.Provider value={null}>{children}</IntercomContext.Provider>;
};

export const updateIntercomLanguagePreference = (language: LanguageCode) => {
  if (window.Intercom) {
    window.Intercom('update', {
      language_override: language,
    });
  }
};
