import { gql } from '@apollo/client';

export const GET_INDUSTRIES = gql`
  query Industries {
    industries {
      id
      name
    }
  }
`;

export const GET_SUB_INDUSTRIES = gql`
  query SubIndustries {
    subIndustries {
      id
      industry {
        id
        name
      }
      name
    }
  }
`;
