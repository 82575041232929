import { useTranslation } from 'react-i18next';
import { CountryType } from 'src/modules/common/types/countryCode';
import { CountryCode } from 'src/modules/common/types/graphql';
import { genericSortSingleColumn } from 'src/modules/common/application/sort';

export const useCountrySelectOptions = (): CountryType[] => {
  const { t } = useTranslation();
  const countries = Object.values(CountryCode).map((code: CountryCode) => {
    return {
      code,
      label: t(`shared.types.countryCode.${code}`),
    };
  });
  genericSortSingleColumn(countries, 'label');
  return countries;
};
