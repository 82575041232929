import React from 'react';
import { Helmet } from 'react-helmet';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MobileWarning } from 'src/modules/common/components/Structure';
import Router from 'src/routes/Router';
import { useErrorBoundary } from 'src/infrastructure/errorHandler';
import ErrorPage from 'src/pages/Error/Error';
import useYbugApi from 'src/services/ybug.service';
import { UserProvider } from 'src/modules/common/application/context/UserContext';
import { HeadProvider } from 'src/modules/common/application/context/HeadContext';
import { LanguageProvider } from './modules/common/application/context/LanguageContext';
import { IntercomProvider } from './modules/common/application/context/IntercomContext';
import PostHogProviderWrapper from './modules/common/components/PosthogProviderWrapper/PosthogProviderWrapper';

const App = () => {
  const { t } = useTranslation();
  const { ErrorBoundary, error } = useErrorBoundary();
  useYbugApi();

  return (
    <>
      <Helmet>
        <meta name="description" content={t('meta.description')} />
      </Helmet>

      <ErrorBoundary fallback={<ErrorPage error={error} />}>
        <HeadProvider>
          <MobileWarning />
          <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <UserProvider>
                <LanguageProvider>
                  <PostHogProviderWrapper>
                    <IntercomProvider>
                      <Router />
                    </IntercomProvider>
                  </PostHogProviderWrapper>
                </LanguageProvider>
              </UserProvider>
            </QueryParamProvider>
          </BrowserRouter>
        </HeadProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
