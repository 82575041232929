import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';

import { useMessages, useYears } from 'src/modules/common/application';
import { Loader } from 'src/modules/common/components';

import HomeWelcomeMessage from '../WelcomeMessage/WelcomeMessage';
import useRoadmap from '../../application/use-roadmap';
import RoadmapContainer from '../RoadmapContainer/RoadmapContainer';

const HomePageContainer = () => {
  const { setErrorMessage } = useMessages();
  const { t } = useTranslation();

  const { data, loading, error, selectedYear, handleSetSelectedYear, sortedYears } = useYears();

  const {
    data: roadmapData,
    roadmapStepsData,
    loading: isRoadmapDataLoading,
    error: roadmapDataError,
  } = useRoadmap(selectedYear);

  if (loading || !data || isRoadmapDataLoading || !roadmapData) return <Loader />;
  if (error || roadmapDataError) return <>{setErrorMessage(t('messages.generic.error'))}</>;

  const years = sortedYears ? sortedYears : [];

  return (
    <Box>
      <HomeWelcomeMessage
        years={years}
        setSelectedYear={handleSetSelectedYear}
        selectedYear={selectedYear}
      />
      <RoadmapContainer roadmapStepsData={roadmapStepsData} />
    </Box>
  );
};

export default HomePageContainer;
