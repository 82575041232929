import React, { useContext } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import FirebaseContext from 'src/modules/common/application/context/FirebaseContext';
import { useUserData } from 'src/modules/common/application';
import { Loader } from '../Base';

const PrivateRoute = ({ children }: { children: React.ReactElement }) => {
  const { pathname, search } = useLocation();
  const { authenticated } = useContext(FirebaseContext);
  const { user, company, loadingUserData } = useUserData();

  // check if user data in cache is sufficient
  const localStateSufficient = user && company;

  if (loadingUserData) {
    return <Loader />;
  }

  if ((!authenticated || !localStateSufficient || !user) && pathname !== '/verify-email') {
    return (
      <Navigate
        to="/login"
        state={{
          redirect: `${pathname}${search}`,
        }}
      />
    );
  }

  return children;
};

export default PrivateRoute;
