import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';

import { AppLogoIcon, AuthLayoutIcon } from 'src/modules/common/components/icons';

export interface Props {
  title: string;
  children: React.ReactNode;
  showDashboard: boolean;
  showAppLogo?: boolean;
}

const AuthLayout = ({ title, children, showDashboard, showAppLogo = true }: Props) => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        height: '100vh',
        minHeight: '100%',
        backgroundColor: 'transparent',
      }}
    >
      <Helmet>
        <title>{title} - Mavue</title>
      </Helmet>
      {showAppLogo && (
        <AppLogoIcon
          style={{
            position: 'absolute',
            top: '6%',
            left: '6%',
            width: 180,
          }}
        />
      )}
      <Box
        component="div"
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          justifyContent: 'center',
          alignItems: 'center',
          width: '60%',
        }}
      >
        {children}
      </Box>
      {showDashboard && (
        <Box
          component="div"
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '40%',
            height: '100%',
            background:
              'linear-gradient(160.03deg, rgba(67, 86, 237, 0.05) 0%, rgba(67, 86, 237, 0.05) 70%)',
          }}
        >
          <AuthLayoutIcon />
        </Box>
      )}
    </Box>
  );
};

export default AuthLayout;
