import { gql } from '@apollo/client';
import { COMPANY_FRAGMENT } from '../fragments';

export const GET_COMPANY_DETAILS = gql`
  query GetCompanyDetails {
    company {
      ...companyValues
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const GET_COMPANIES = gql`
  query GetCompanies {
    companies {
      ...companyValues
    }
  }
  ${COMPANY_FRAGMENT}
`;
