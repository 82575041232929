import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const usePasswordToggle = () => {
  const [visible, setVisibility] = useState(false);

  const Icon = visible ? VisibilityIcon : VisibilityOffIcon;
  const inputType = visible ? 'text' : 'password';
  const toggleVisibility = () => {
    setVisibility(!visible);
  };

  return { inputType, Icon, toggleVisibility };
};

export default usePasswordToggle;
