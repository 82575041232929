import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Table,
  TableBody,
  Grid,
  Typography,
  TableContainer,
  TableRow,
  TableCell,
} from '@mui/material';
import { EsgKpi, EsgValue, Modes, TranslatedEsgKpi } from 'src/modules/common/types';
import TableHeader from 'src/modules/common/components/Table/TableHeader';
import Button from 'src/modules/common/components/Button/Button';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import { genericSortTwoColumns } from 'src/modules/common/application/sort';
import { useMessages } from 'src/modules/common/application';

import NoRequstedInputDataPlaceholder from 'src/modules/common/components/NoRequstedInputDataPlaceholder/NoRequstedInputDataPlaceholder';
import { IntercomDataAttribute } from 'src/modules/common/types/intercom';
import { getIntercomDataAttribute } from 'src/modules/common/application/intercom';

import EsgDataInputTableRow from '../EsgDataInputTableRow/EsgDataInputTableRow';
import { EsgDataInputTableHeaders, EsgDataInputTableHeadersPublic } from './constants';
import EsgDataInputSidebar from '../EsgDataInputSidebar/EsgDataInputSidebar';

interface Props {
  mode: Modes;
  data: EsgValue[];
  onTableRowDataChange: ({
    id,
    field,
    value,
  }: {
    id: string;
    field: string;
    value: string;
  }) => void;
  handleSaveClick: () => void;
  onActivateDectivateKpi?: (id: string, isDeactivated: boolean) => void;
  loading: boolean;
  kpis: EsgKpi[];
}

const EsgDataInputTable = ({
  mode,
  data,
  onTableRowDataChange,
  onActivateDectivateKpi,
  handleSaveClick,
  loading,
  kpis,
}: Props) => {
  const { t } = useTranslation();

  const headers =
    mode === Modes.PUBLIC
      ? (EsgDataInputTableHeadersPublic.map(t) as string[])
      : (EsgDataInputTableHeaders.map(t) as string[]);

  const { setErrorMessage } = useMessages();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarData, setSidebarData] = useState<TranslatedEsgKpi | null>(null);

  const handleCloseEsgDataInputSidebar = () => {
    setIsSidebarOpen(false);
    setSidebarData(null);
  };

  const transformedData = useMemo(() => {
    const newData: TranslatedEsgKpi[] = kpis ? JSON.parse(JSON.stringify(kpis)) : [];

    newData.forEach((kpi: TranslatedEsgKpi) => {
      kpi.translatedKpiTitle = t(`shared.types.kpi.${removeUnderscoreToCamelCase(kpi.title)}`);
      kpi.translatedCategoryTitle = t(
        `shared.types.esgCategory.${removeUnderscoreToCamelCase(kpi.category.title)}`
      );

      const kpiEsgValue = data.find((d: EsgValue) => d.kpi.id === kpi.id);
      kpi.esgValue = kpiEsgValue;
    });

    return newData;
  }, [data, kpis, t]);

  genericSortTwoColumns(transformedData, 'translatedCategoryTitle', 'translatedKpiTitle');

  const handleOpenEsgDataInputSidebar = (id: string) => {
    const selectedEsgKpi = transformedData.find((d: TranslatedEsgKpi) => d.id === id);
    if (selectedEsgKpi) {
      //fetch sidebar data
      setSidebarData(selectedEsgKpi);
      setIsSidebarOpen(true);
    } else {
      return <>{setErrorMessage(t('messages.generic.error'))}</>;
    }
  };

  return (
    <Box>
      {mode !== Modes.PRIVATE && (
        <Grid container sx={{ marginBottom: 2, justifyContent: 'flex-end' }}>
          <Grid item md={8} xs={6}>
            <Typography variant="h5" mt={1}>
              {t('modules.dataManagement.esgValueInput.enterValues')}
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={6}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              '@media (max-width: 600px)': {
                justifyContent: 'flex-start',
              },
            }}
          >
            <Button
              size="small"
              loading={loading}
              onClick={handleSaveClick}
              data-intercom-target={getIntercomDataAttribute(
                IntercomDataAttribute.kpiDetailSaveButton
              )}
            >
              {t('modules.dataManagement.publicEsgValueInput.submitData')}
            </Button>
          </Grid>
        </Grid>
      )}
      <EsgDataInputSidebar
        sidebarWidth={400}
        isOpen={isSidebarOpen}
        onClose={handleCloseEsgDataInputSidebar}
        esgKpi={sidebarData}
      />
      <TableContainer
        sx={theme => ({ border: theme.custom.border })}
        data-intercom-target={getIntercomDataAttribute(
          IntercomDataAttribute.kpiDetailTableContainer
        )}
      >
        <Table
          sx={{
            tableLayout: 'auto',
            width: '100%',
          }}
        >
          <TableHeader headers={headers} showLastHeader={false} />
          <TableBody>
            {transformedData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <NoRequstedInputDataPlaceholder />
                </TableCell>
              </TableRow>
            ) : (
              transformedData.map((item: TranslatedEsgKpi, index: number) => {
                return (
                  <EsgDataInputTableRow
                    key={item.esgValue ? item.esgValue.id : item.id}
                    onDataChange={onTableRowDataChange}
                    onActivateDectivateKpi={onActivateDectivateKpi}
                    data={item}
                    handleOpenEsgDataInputSidebar={handleOpenEsgDataInputSidebar}
                    mode={mode}
                    index={index}
                  />
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default EsgDataInputTable;
