import React from 'react';
import { Company } from 'src/modules/common/types/graphql';
import { Box, Typography, TextField, Grid, InputLabel, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getInputLabelStyles } from 'src/modules/common/application/form-styles';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';

interface Props {
  data: Company;
}

const BusinessDetails = ({ data }: Props) => {
  const { name, headquarter, industry, subIndustry, employeesNum } = data;
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: 'background.white',
        display: 'flex',
        padding: 1,
        flexDirection: 'column',
        color: 'text.secondary',
        border: '1px solid',
        borderColor: 'background.grey',
        borderRadius: '10px',
        fontSize: '14px',
      }}
    >
      <Grid container>
        <Typography variant="h5" sx={{ color: 'text.primary', pl: 2.5, pt: 1 }}>
          {t('modules.settings.businessDetails.title')}
        </Typography>
      </Grid>

      <Grid container rowSpacing={2} sx={{ p: 2.5, mt: 2.5 }}>
        <Grid container item columnSpacing={3}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor={name} sx={getInputLabelStyles}>
                {t('shared.types.company.name')}
              </InputLabel>
              <TextField id="company" type="text" value={name} fullWidth disabled />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor={headquarter} sx={getInputLabelStyles}>
                {t('shared.types.company.headquarterLocation')}
              </InputLabel>
              <TextField
                id="headquarterLocation"
                type="text"
                value={headquarter}
                fullWidth
                disabled
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container item columnSpacing={3} sx={{ mt: 2.5 }}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor={subIndustry?.industry?.name} sx={getInputLabelStyles}>
                {t('shared.types.company.industry')}
              </InputLabel>
              <TextField
                id="industry"
                type="text"
                value={t(
                  'shared.types.industry.' + removeUnderscoreToCamelCase(industry?.name || '')
                )}
                fullWidth
                disabled
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor={subIndustry?.name} sx={getInputLabelStyles}>
                {t('shared.types.company.subIndustry')}
              </InputLabel>
              <TextField
                id="subIndustry"
                type="text"
                value={t(
                  'shared.types.subIndustry.' + removeUnderscoreToCamelCase(subIndustry?.name || '')
                )}
                fullWidth
                disabled
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container item columnSpacing={3} sx={{ mt: 2.5 }}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor={employeesNum} sx={getInputLabelStyles}>
                {t('shared.types.company.numberOfEmployees')}
              </InputLabel>
              <TextField
                id="numberEmployees"
                type="text"
                value={t(
                  'shared.types.employeesNum.' + removeUnderscoreToCamelCase(employeesNum || '')
                )}
                fullWidth
                disabled
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessDetails;
