import { gql } from '@apollo/client';

export const GET_MATERIALITY_ASSESSMENT = gql`
  query GetMaterialityAssessment($year: Int!) {
    materialityAssessment(year: $year) {
      id
      companyId
      year
      topics {
        id
        materialityAssessmentId
        status
        against
        inFavor
        materialTopic {
          area
          id
          title
          type
        }
        materiality {
          financialMateriality
          impactMateriality
        }
        evaluation {
          impactDescription
          financialDescription
          impactScale
          impactScope
          impactIrreversibility
          financialSignificance
          financialProbability
        }
      }
      identificationRequests {
        id
        stakeholderId
        materialityAssessmentId
        dueDate
        status
        stakeholder {
          id
          email
          firstName
          lastName
          position
        }
      }
      evaluationRequests {
        id
        stakeholderId
        materialityAssessmentId
        dueDate
        status
        stakeholder {
          id
          email
          firstName
          lastName
          position
        }
        response {
          topicId
          result {
            impactDescription
            financialDescription
            impactScale
            impactScope
            impactIrreversibility
            financialSignificance
            financialProbability
          }
        }
      }
    }
  }
`;
