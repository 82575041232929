import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DeactivateKPIDialogeProps } from './types';
import { useTranslation } from 'react-i18next';

export const DeactivateKPIDialoge = (props: DeactivateKPIDialogeProps) => {
  const { t } = useTranslation();
  const { isOpen, onClose, kpiName, onDeactivate } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={(e, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('modules.dataManagement.deactivateKPIConfirmationDialogue.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('modules.dataManagement.deactivateKPIConfirmationDialogue.subtitle', {
            kpiName,
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={onClose} variant="outlined">
          {t('modules.dataManagement.deactivateKPIConfirmationDialogue.cancel')}
        </Button>
        <Button size="small" onClick={onDeactivate} variant="contained">
          {t('modules.dataManagement.deactivateKPIConfirmationDialogue.deactivate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
