import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import { PageContainer } from 'src/modules/common/components';
import CompanyOnboardingStart from 'src/modules/company-onboarding/components/CompanyOnboardingStart';

const OnboardingStart = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/user-info');
  };

  return (
    <PageContainer>
      <Grid container sx={{ alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <Grid item>
          <CompanyOnboardingStart onNext={handleNext} />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default OnboardingStart;
