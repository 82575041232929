import React, { useState } from 'react';
import { Button, Checkbox, Menu, MenuItem, Typography, useTheme } from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { CollectFilterItem } from 'src/modules/common/types/collect';
import { ArrowDownIcon, ArrowUpIcon } from 'src/modules/common/components/icons';

function CollectDataTableFilterItem({
  data,
  options,
  title,
  onChange,
  customOptionRender,
}: {
  data: string[];
  options: CollectFilterItem[];
  title: string;
  onChange: (data: string[]) => void;
  customOptionRender?: (option: CollectFilterItem) => JSX.Element;
}) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const hasData = data.length > 0;
  const theme = useTheme();

  const handleChange = (option: CollectFilterItem) => {
    const isChecked = data.some(item => item === option.key);
    if (!isChecked) {
      onChange([...data, option.key]);
    } else {
      onChange(data.filter(item => item !== option.key));
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleActionClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `${title}-filter-menu` : undefined;

  return (
    <>
      <Button
        onClick={handleActionClick}
        aria-describedby={id}
        variant="outlined"
        size="small"
        sx={{
          marginRight: 2,
          fontWeight: 600,
          color: hasData ? 'default' : 'colors.lightGrey',
          border: hasData ? '1px solid default' : theme.custom.border,
          '&:hover': {
            border: hasData ? '1px solid default' : theme.custom.border,
          },
        }}
        endIcon={
          open ? (
            <ArrowUpIcon
              fill={hasData ? theme.palette.primary.main : 'colors.lightGrey'}
              fillOpacity={hasData ? '1' : '0.54'}
            />
          ) : (
            <ArrowDownIcon
              fill={hasData ? theme.palette.primary.main : 'colors.lightGrey'}
              fillOpacity={hasData ? '1' : '0.54'}
            />
          )
        }
      >
        {title}
      </Button>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleActionClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          minWidth: '250px',
          maxHeight: 700,
          '@media (max-height: 1000px)': {
            maxHeight: 550,
          },
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option.key}
            sx={{
              width: '100%',
              paddingLeft: 3,
              paddingRight: 3,
              marginTop: 1,
              marginBottom: 1,
              '&:hover': {
                backgroundColor: 'palette.background.default',
              },
            }}
            onClick={() => handleChange(option)}
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              size="small"
              checked={data.some(item => item === option.key)}
              onChange={() => handleChange(option)}
            />
            {customOptionRender ? (
              customOptionRender(option)
            ) : (
              <Typography variant="subtitle2" sx={{ color: 'colors.lightGrey' }}>
                {option.label}
              </Typography>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default CollectDataTableFilterItem;
