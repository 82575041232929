import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { EnvironmentalEmissions } from 'src/modules/common/types';
import { useEnhancedQuery, useMessages } from 'src/modules/common/application';
import { Loader } from 'src/modules/common/components';
import { GET_YEARLY_ENVIRONMENTAL_STATISTICS } from 'src/services/graphql/queries';

import OverallEmission from '../OverallEmission/OverallEmission';
import EnvironmentalOverviewStats from '../EnvironmentalOverviewStats/EnvironmentalOverviewStats';
import EmissionPerFacility from '../EmissionPerFacility/EmissionPerFacility';
import WaterConsumptionPerFacility from '../WaterConsumptionPerFacility/WaterConsumptionPerFacility';
import EnergyConsumption from '../EnergyConsumption/EnergyConsumption';

const EnvironmentTab = ({ year }: { year: number }) => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();

  const { loading, data, error } = useEnhancedQuery<{
    getYearlyEnvironmentalStatistics: EnvironmentalEmissions;
  }>(GET_YEARLY_ENVIRONMENTAL_STATISTICS, {
    variables: {
      year,
    },
  });

  if (loading || !data) return <Loader />;
  if (error) return <>{setErrorMessage(t('messages.generic.error'))}</>;

  const { getYearlyEnvironmentalStatistics } = data;

  return (
    <Grid container spacing={4}>
      <Grid item md={12} xs={12}>
        <EnvironmentalOverviewStats
          environmentalProgress={getYearlyEnvironmentalStatistics.environmentalEsgProgress}
          directEmissions={getYearlyEnvironmentalStatistics.directEmissions}
          indirectEmissions={getYearlyEnvironmentalStatistics.indirectEmissions}
          emissionIntensity={getYearlyEnvironmentalStatistics.emissionIntensity}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <OverallEmission
          directEmissions={getYearlyEnvironmentalStatistics.directEmissions}
          indirectEmissions={getYearlyEnvironmentalStatistics.indirectEmissions}
        />
      </Grid>
      <Grid item md={7} xs={12}>
        <EmissionPerFacility
          facilityIndirectConsumption={getYearlyEnvironmentalStatistics.facilityIndirectConsumption}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <EnergyConsumption
          renewable={getYearlyEnvironmentalStatistics.totalRenewableEnergy}
          nonRenewable={getYearlyEnvironmentalStatistics.totalNonRenewableEnergy}
          unkown={0}
        />
      </Grid>
      <Grid item md={7} xs={12}>
        <WaterConsumptionPerFacility
          waterConsumption={getYearlyEnvironmentalStatistics.waterConsumption}
        />
      </Grid>
    </Grid>
  );
};

export default EnvironmentTab;
