import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';

import {
  CollectFilter,
  CollectFilterInput,
  CollectOverviewStatus,
  SortingOrder,
} from 'src/modules/common/types';
import useEnhancedQuery from 'src/modules/common/application/use-enhanced-query';
import { UPDATE_COLLECT_FILTER } from 'src/services/graphql/mutations/collect';
import { COLLECT_FILTERS } from 'src/services/graphql/queries/collect';
import { TransformedCollectOverview, FilterType } from 'src/modules/common/types/collect';
import { useEnhancedMutation, useMessages, useUserData } from 'src/modules/common/application';

import {
  DEFAULT_ORDER_DIRECTION,
  DEFAULT_ORDER_FIELD,
  DEFAULT_ROWS_PER_PAGE_COUNT,
} from './constant';
import { getRowsPerPage } from './data-collect';

const useCollectFilter = (
  originalData: TransformedCollectOverview[] | undefined,
  year: number,
  accessToken?: string | null
) => {
  const { setErrorMessage } = useMessages();
  const { t } = useTranslation();
  const client = useApolloClient();
  const { user } = useUserData();
  const [rowsPerPage, setRowsPerPage] = useState(getRowsPerPage(window.innerHeight));

  const { loading, data } = useEnhancedQuery<{ collectFilter: CollectFilter }>(COLLECT_FILTERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      year,
    },
    onError: () => {
      setErrorMessage(t('messages.getCollectFilter.error'));
    },
    context: {
      headers: {
        authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        'x-user-type': accessToken ? 'external' : 'internal',
      },
    },
  });

  const filteredData = useMemo(() => {
    return originalData
      ? originalData.length > 0 && data?.collectFilter
        ? originalData.filter(item => {
            if (!data?.collectFilter) return true;

            if (data.collectFilter.statuses.length > 0) {
              if (!data.collectFilter.statuses.includes(item.status)) return false;
            }
            if (data.collectFilter.facilities.length > 0) {
              if (!data.collectFilter.facilities.includes(item.facilityId)) return false;
            }
            if (data.collectFilter.categories.length > 0) {
              if (!data.collectFilter.categories.includes(item.categoryId)) return false;
            }
            if (data.collectFilter.assignedTo.length > 0) {
              const hasNoAssignedDataOwnerFilter =
                data.collectFilter.assignedTo.includes('UNASSIGNED_DATA_OWNER');
              if (!hasNoAssignedDataOwnerFilter) {
                if (
                  !item.assignedToId ||
                  !data.collectFilter.assignedTo.includes(item.assignedToId)
                )
                  return false;
              } else {
                if (item.assignedToId && !data.collectFilter.assignedTo.includes(item.assignedToId))
                  return false;
              }
            }
            return true;
          })
        : originalData
      : [];
  }, [data?.collectFilter, originalData]);

  const [updateCollectFilter, { loading: loadingUpdate }] = useEnhancedMutation(
    UPDATE_COLLECT_FILTER,
    {
      onError: () => setErrorMessage(t('messages.updateCollectFilter.error')),
      update(cache, result, { variables }) {
        cache.writeQuery({
          query: COLLECT_FILTERS,
          data: { collectFilter: result.data.updateCollectFilter },
          variables: {
            year,
          },
        });
      },
      context: {
        headers: {
          authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          'x-user-type': accessToken ? 'external' : 'internal',
        },
      },
    }
  );

  async function handleUpdateCollectFilter(filter: CollectFilterInput) {
    await updateCollectFilter({
      variables: {
        filter,
      },
    });
  }

  async function handleCollectFilterChange({
    newValue,
    filterType,
    count,
    field,
    direction,
  }: {
    newValue?: string[];
    filterType?: FilterType;
    count?: number;
    field?: string;
    direction?: SortingOrder;
  }) {
    const cache: { collectFilter: CollectFilter | null } | null = client.readQuery({
      query: COLLECT_FILTERS,
      variables: {
        year,
      },
    });
    if (!cache) return;

    let existedFilter: CollectFilter = JSON.parse(JSON.stringify(cache.collectFilter));

    if (!existedFilter) {
      existedFilter = {
        userId: user?.id,
        year,
        statuses: [],
        facilities: [],
        categories: [],
        assignedTo: [],
        rowsPerPageCount: DEFAULT_ROWS_PER_PAGE_COUNT,
        orderField: DEFAULT_ORDER_FIELD,
        orderDirection: DEFAULT_ORDER_DIRECTION,
      };
    }

    if (newValue && filterType) {
      if (filterType === FilterType.RESET) {
        existedFilter.assignedTo = [];
        existedFilter.categories = [];
        existedFilter.facilities = [];
        existedFilter.statuses = [];
      } else if (filterType === FilterType.FACILITY)
        existedFilter.facilities = newValue.map(item => item);
      else if (filterType === FilterType.CATEGORY)
        existedFilter.categories = newValue.map(item => item);
      else if (filterType === FilterType.ASSIGNED_TO)
        existedFilter.assignedTo = newValue.map(item => item);
      else if (filterType === FilterType.STATUS)
        existedFilter.statuses = newValue.map(item => item as CollectOverviewStatus);
    }

    if (count !== undefined) {
      existedFilter.rowsPerPageCount = count;
      setRowsPerPage(count);
    }

    if (field && direction) {
      existedFilter.orderField = field;
      existedFilter.orderDirection = direction;
    }

    await handleUpdateCollectFilter({
      year: existedFilter.year,
      statuses: existedFilter.statuses,
      facilities: existedFilter.facilities,
      categories: existedFilter.categories,
      assignedTo: existedFilter.assignedTo,
      rowsPerPageCount: existedFilter.rowsPerPageCount,
      orderField: existedFilter.orderField,
      orderDirection: existedFilter.orderDirection,
    });
  }

  useEffect(() => {
    if (data?.collectFilter && data?.collectFilter?.rowsPerPageCount) {
      setRowsPerPage(data?.collectFilter?.rowsPerPageCount);
    } else if (!data?.collectFilter) {
      handleCollectFilterChange({ count: rowsPerPage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.collectFilter]);

  return {
    filteredData,
    loading,
    data: data?.collectFilter,
    loadingUpdate,
    rowsPerPageCount: rowsPerPage,
    handleCollectFilterChange,
  };
};

export default useCollectFilter;
