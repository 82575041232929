import React, { useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  TableFooter,
  Typography,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Chip, LinearProgressBar } from 'src/modules/common/components';
import {
  getCategoryChipColor,
  getCollectOverviewStatusChipColor,
} from 'src/modules/common/application/ui';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import NoFilteredDataPlaceholder from 'src/modules/common/components/NoFilteredDataPlaceholder/NoFilteredDataPlaceholder';
import { getTotalPages } from 'src/modules/data-management/application/data-collect';
import { TransformedCollectOverview } from 'src/modules/common/types/collect';

import { IntercomDataAttribute } from 'src/modules/common/types/intercom';
import { getIntercomDataAttribute } from 'src/modules/common/application/intercom';
import { getProperFormatedDate } from 'src/modules/common/application/date';

import CollectEsgNonAdminTableHeader from '../CollectEsgNonAdminTableHeader/CollectEsgNonAdminTableHeader';
import { StyledTableCell, StyledTablePagination, StyledTableRow } from './styles';
import { CollectFilter, SortingOrder } from 'src/modules/common/types';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = 'asc' | 'desc';

function getComparator<T>(order: SortingOrder, orderBy: keyof T): (a: T, b: T) => number {
  return order === SortingOrder.ASC
    ? (a, b) => descendingComparator<T>(a, b, orderBy)
    : (a, b) => -descendingComparator<T>(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

interface CollectEsgNonAdminTableProps {
  data: TransformedCollectOverview[];
  isExternal: boolean;
  token?: string | null;
  email?: string | null;
  rowsPerPageCount: number;
  collectFilter: CollectFilter | undefined;
  handleOnRowCountChange: (count: number) => Promise<void>;
  handleSortingChange: (field: string, direction: SortingOrder) => Promise<void>;
}

const CollectEsgNonAdminTable = (props: CollectEsgNonAdminTableProps) => {
  const {
    data,
    isExternal,
    token,
    email,
    rowsPerPageCount,
    collectFilter,
    handleOnRowCountChange,
    handleSortingChange,
  } = props;

  const { orderDirection, orderField } = collectFilter || {
    orderDirection: SortingOrder.ASC,
    orderField: 'facilityName',
  };

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [showActionId, setShowActionId] = useState('');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof TransformedCollectOverview
  ) => {
    const isAsc = orderField === property && orderDirection === SortingOrder.ASC;
    handleSortingChange(property, isAsc ? SortingOrder.DESC : SortingOrder.ASC);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    handleOnRowCountChange(parseInt(event.target.value, 10));
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPageCount - data.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort<TransformedCollectOverview>(
        data,
        getComparator(orderDirection, orderField as keyof TransformedCollectOverview)
      ).slice(page * rowsPerPageCount, page * rowsPerPageCount + rowsPerPageCount),
    [data, orderDirection, orderField, page, rowsPerPageCount]
  );

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer
        data-intercom-target={getIntercomDataAttribute(IntercomDataAttribute.collectTableContainer)}
      >
        <Table
          sx={{ minWidth: 750, backgroundColor: '#fff' }}
          aria-labelledby="tableTitle"
          size={'medium'}
        >
          <CollectEsgNonAdminTableHeader
            order={orderDirection}
            orderBy={orderField}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {visibleRows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <NoFilteredDataPlaceholder height={60 * rowsPerPageCount} />
                </TableCell>
              </TableRow>
            ) : (
              visibleRows.map((row, index) => {
                const skipIntercomAttribute = index !== 0;
                return (
                  <StyledTableRow
                    tabIndex={-1}
                    key={row.id}
                    onMouseEnter={() => {
                      setShowActionId(row.id);
                    }}
                    onMouseLeave={() => setShowActionId('')}
                    sx={{ '&:hover': { cursor: 'default' } }}
                  >
                    <StyledTableCell sx={{ width: '25%', paddingLeft: 2 }}>
                      {row.facilityName}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '25%', paddingLeft: 2 }}>
                      <Chip
                        size="small"
                        styles={{
                          maxWidth: 'fit-content',
                          opacity: 1,
                        }}
                        colorVariant={getCategoryChipColor(row.category)}
                      >
                        {t('shared.types.esgCategory.' + removeUnderscoreToCamelCase(row.category))}
                      </Chip>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '15%', paddingLeft: 2 }}>
                      <Box sx={{ paddingRight: 4 }}>
                        <LinearProgressBar progress={row.progress} />
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '15%' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          variant="body2"
                          display="inline"
                          sx={{
                            color: 'colors.grey',
                            fontSize: '0.875rem !important',
                            textAlign: 'left',
                          }}
                        >
                          {row.dueDate
                            ? getProperFormatedDate(row.dueDate.toString(), 'DD.MM.YYYY')
                            : t('modules.dataManagement.collectEsgAdminTable.dueDateDefault')}
                        </Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '15%', paddingLeft: 2 }}>
                      <Chip
                        size="small"
                        styles={{
                          maxWidth: 'fit-content',
                          opacity: 1,
                        }}
                        colorVariant={getCollectOverviewStatusChipColor(row.status)}
                      >
                        {t(
                          'shared.types.collectOverviewStatus.' +
                            removeUnderscoreToCamelCase(row.status)
                        )}
                      </Chip>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ width: '70px' }}
                      onClick={() => {
                        isExternal
                          ? navigate(
                              '/shareable/' +
                                row.id +
                                '?token=' +
                                token +
                                '&email=' +
                                encodeURIComponent(email!)
                            )
                          : navigate('/data-management/collect/' + row.id);
                      }}
                      data-intercom-target={getIntercomDataAttribute(
                        IntercomDataAttribute.collectTableFirstRowKPIDetailIcon,
                        skipIntercomAttribute
                      )}
                    >
                      {row.id === showActionId ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <ArrowForwardIcon color="primary" cursor="pointer" />
                        </Box>
                      ) : (
                        ''
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                count={data.length}
                labelRowsPerPage={
                  <span style={{ fontSize: '0.875rem' }}>
                    {t('modules.dataManagement.collectEsgAdminTable.pagination.rowsPerPage')}
                  </span>
                }
                labelDisplayedRows={row => (
                  <span style={{ fontSize: '0.875rem' }}>
                    {t('modules.dataManagement.collectEsgAdminTable.pagination.currentPage', {
                      currentPage: page + 1,
                      totalPages: getTotalPages(rowsPerPageCount, data.length),
                    })}
                  </span>
                )}
                rowsPerPage={rowsPerPageCount}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CollectEsgNonAdminTable;
