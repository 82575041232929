import React, { useCallback, useEffect, useState } from 'react';
import { Box, TableRow, Typography, Switch, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Chip } from 'src/modules/common/components/Chip';
import { EsgArea, MaterialTopicType, User } from 'src/modules/common/types';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import { getESGAreaChipColor } from 'src/modules/common/application/ui';
import useMaterialityAssessmentTopic from 'src/modules/materialityAssessment/application/use-materiality-assessment-topic';
import useMaterialityAssessmentResultDialog from 'src/modules/materialityAssessment/application/use-materiality-assessment-result-dialogue';
import { StyledTableCell } from 'src/modules/data-management/components/EsgDataInputTableRow/styles';

import { GroupedStakeholderAvatarWithCount } from './GroupedStakeholderAvatar';
import { StakeholdersResultsDialog } from '../StakeholderResultDialog/StakeholderResultDialog';
import { MaterialityAssessmentTopicWithDisabled } from '../../types/materialEvaluation';

interface MaterialityTopicsTableRowProps {
  data: MaterialityAssessmentTopicWithDisabled;
  filterStackholdersByIds: (ids: string[]) => User[];
  year: number;
}

export const MaterialityTopicsTableRow = ({
  data,
  filterStackholdersByIds,
  year,
}: MaterialityTopicsTableRowProps) => {
  const { t } = useTranslation();
  const [isDeactivated, setIsDeactivated] = useState<boolean>(false);

  const { deleteMATopic, createMATopic, createLoading, deleteLoading } =
    useMaterialityAssessmentTopic({
      year,
      onCreateTopicSuccess: () => {
        setIsDeactivated(false);
      },
      onDeleteTopicSuccess: () => {
        setIsDeactivated(true);
      },
    });

  const {
    isOpen,
    data: resultData,
    type,
    onClose,
    openDialog,
  } = useMaterialityAssessmentResultDialog();

  const inFavourStakeholders = filterStackholdersByIds(data?.inFavor);
  const inAgainstStakeholders = filterStackholdersByIds(data?.against);

  const isRecommendedByMavue = data?.materialTopic.type === MaterialTopicType.RECOMMENDED;

  const handleCreateTopic = useCallback(() => {
    createMATopic({
      variables: {
        materialityAssessmentId: data.materialityAssessmentId,
        materialTopicId: data.materialTopic.id,
      },
    });
  }, [createMATopic, data]);

  const handleDeleteTopic = useCallback(() => {
    deleteMATopic({
      variables: {
        id: data.id,
      },
    });
  }, [deleteMATopic, data]);

  useEffect(() => {
    if (data) {
      setIsDeactivated(data.status === 'DEACTIVE' ? true : false);
    }
  }, [data]);

  return (
    <>
      <TableRow
        sx={{
          fontSize: '14px',
          backgroundColor: 'white',
        }}
      >
        <StyledTableCell
          sx={{
            width: '30%',
          }}
        >
          <Typography
            paddingLeft={2}
            marginRight={1}
            variant="inherit"
            style={{ opacity: isDeactivated ? 0.5 : 1 }}
          >
            {t(
              `shared.types.materialTopic.${removeUnderscoreToCamelCase(
                data?.materialTopic?.title
              )}`
            )}
          </Typography>
        </StyledTableCell>
        <StyledTableCell sx={{ width: '20%' }}>
          <Chip
            size="small"
            styles={{
              maxWidth: 'fit-content',
              opacity: isDeactivated ? 0.5 : 1,
            }}
            colorVariant={getESGAreaChipColor(data?.materialTopic.area as EsgArea)}
          >
            {t(`shared.types.esgArea.${removeUnderscoreToCamelCase(data?.materialTopic.area)}`)}
          </Chip>
        </StyledTableCell>
        <StyledTableCell
          sx={{
            width: '20%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              ml: 2,
              opacity: isDeactivated ? 0.5 : 1,
              width: '100%',
              cursor: 'pointer',
            }}
            onClick={() => {
              inFavourStakeholders?.length > 0 && openDialog('FAVOUR', inFavourStakeholders);
            }}
          >
            {isRecommendedByMavue || inFavourStakeholders?.length > 0 ? (
              <GroupedStakeholderAvatarWithCount
                showMavueRecommendation={isRecommendedByMavue}
                maxCount={2}
                collaborators={[...inFavourStakeholders]}
              />
            ) : (
              '-'
            )}
          </Box>
        </StyledTableCell>
        <StyledTableCell
          sx={{
            width: '20%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              ml: 2,
              opacity: isDeactivated ? 0.5 : 1,
              width: '100%',
              cursor: 'pointer',
            }}
            onClick={() => {
              inAgainstStakeholders?.length > 0 && openDialog('AGAINST', inAgainstStakeholders);
            }}
          >
            {inAgainstStakeholders?.length > 0 ? (
              <GroupedStakeholderAvatarWithCount collaborators={inAgainstStakeholders} />
            ) : (
              <Typography
                sx={{
                  textAlign: 'left',
                }}
              >
                -
              </Typography>
            )}
          </Box>
        </StyledTableCell>
        <StyledTableCell sx={{ width: '10%' }}>
          <Tooltip
            title={
              data.disabled
                ? t('modules.materialityAssessment.materialityTopicsTableRow.tooltip')
                : null
            }
          >
            <span>
              <Switch
                aria-label="Deactivate Switch"
                checked={!isDeactivated}
                disabled={data.disabled || createLoading || deleteLoading}
                onChange={() => (isDeactivated ? handleCreateTopic() : handleDeleteTopic())}
              />
            </span>
          </Tooltip>
        </StyledTableCell>
      </TableRow>
      <StakeholdersResultsDialog isOpen={isOpen} data={resultData} type={type} onClose={onClose} />
    </>
  );
};
