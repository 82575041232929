import React, { useState, useEffect, createContext, ReactNode } from 'react';
import { Snackbar, Alert, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

interface Props {
  children: ReactNode;
}

interface MessageContextProps {
  setErrorMessage: (errorMessage: string) => void;
  setSuccessMessage: (successMessage: string) => void;
  setInfoMessage: (infoMessage: string) => void;
}

const MessageContext = createContext<MessageContextProps>({
  setErrorMessage: () => {
    console.error('unimplemented context messageContext');
  },
  setSuccessMessage: () => {
    console.error('unimplemented context messageContext');
  },
  setInfoMessage: () => {
    console.error('unimplemeneted context messageContext');
  },
});

const MessageProvider: React.FC<Props> = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [infoMessage, setInfoMessage] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  const resetMessage = () => {
    setErrorMessage(null);
    setSuccessMessage(null);
    setInfoMessage(null);
  };

  const message: string | null = errorMessage || successMessage || infoMessage;

  useEffect(() => setOpen(!!message), [message]);

  const onClose = () => {
    setOpen(false);
    setTimeout(() => resetMessage(), 750);
  };

  return (
    <MessageContext.Provider
      value={{
        setErrorMessage,
        setSuccessMessage,
        setInfoMessage,
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{ zIndex: 9999, maxWidth: '500px' }}
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
      >
        <Alert
          variant="standard"
          onClose={onClose}
          sx={{ textAlign: 'center' }}
          iconMapping={{
            success: <CheckCircleIcon fontSize="inherit" sx={{ color: 'success.main' }} />,
            error: <ErrorIcon fontSize="inherit" sx={{ color: 'error.main' }} />,
          }}
          action={
            <CloseIcon
              fontSize="small"
              sx={{ marginTop: '6px', cursor: 'pointer', fontSize: '20px' }}
              onClick={onClose}
            />
          }
          severity={successMessage ? 'success' : infoMessage ? 'info' : 'error'}
          data-cy={`alert-${successMessage ? 'success' : 'error'}`}
        >
          <Typography variant="subtitle2" data-cy="snackbar-message-text">
            {message}
          </Typography>
        </Alert>
      </Snackbar>
      {children}
    </MessageContext.Provider>
  );
};

export { MessageProvider };

export default MessageContext;
