import React from 'react';
import { PageContainer, PageTitle } from 'src/modules/common/components';
import { useTranslation } from 'react-i18next';

const Controlling = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <PageTitle title={t('modules.controlling.pageTitle')} />
    </PageContainer>
  );
};

export default Controlling;
