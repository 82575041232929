import { gql } from '@apollo/client';
import { FACILITY_DATA_MANAGEMENT_FRAGMENT, FACILITY_FRAGMENT } from '../fragments';

export const GET_ALL_FACILITIES_AND_USERS = gql`
  query GetFacilitiesAndUsers {
    users {
      id
      firstName
      lastName
    }
    facilities {
      id
      name
      city
      country
      type
      contributors {
        firstName
        id
        lastName
        email
      }
    }
  }
`;

export const GET_ORGANIZE_BY_YEAR = gql`
  ${FACILITY_DATA_MANAGEMENT_FRAGMENT}
  query GetOrganizeByYear($year: Int!) {
    facilitiesByYear(year: $year) {
      ...facilityDataManagementValues
    }
    esgCategoriesByYear(year: $year) {
      id
      area
      category
      progress
      contributors {
        firstName
        lastName
        email
      }
    }
  }
`;

export const GET_FACILITY_BY_USER_AND_YEAR = gql`
  ${FACILITY_DATA_MANAGEMENT_FRAGMENT}
  query GetFacilityByUserAndYear($year: Int!) {
    facilitiesByUserAndYear(year: $year) {
      ...facilityDataManagementValues
    }
    esgCategoriesByUserYear(year: $year) {
      id
      area
      category
      progress
      contributors {
        firstName
        lastName
        email
      }
    }
  }
`;

export const GET_FACILITIES = gql`
  query GetFacilities {
    facilities {
      id
      name
      city
      country
      type
    }
  }
`;

export const GET_FACILITIES_BY_COMPANY_ID = gql`
  ${FACILITY_FRAGMENT}
  query GetFacilitiesByCompanyId($companyId: UUID!) {
    facilitiesByCompanyId(companyId: $companyId) {
      ...facilityValues
    }
  }
`;
