import React, { createContext } from 'react';
import { NestedObject } from 'src/modules/common/types/generic-types';
import { appQueryParams, userRole } from 'src/modules/common/types/constants';
import configData from './configData.json';

class Config {
  fireBaseConfig: NestedObject;

  constructor(config: NestedObject) {
    Object.assign(this, config);

    this.fireBaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG as string);
  }
}

interface ConfigContextInterface {
  config: Config;
}

const defaultConfig = configData.development;
const environment = process.env.REACT_APP_ENV || 'development';
const environmentConfig: NestedObject = configData[environment as keyof typeof configData];
const finalConfig: NestedObject = {
  ...defaultConfig,
  ...environmentConfig,
  appQueryParams,
  userRole,
};

const ConfigContext = createContext<ConfigContextInterface | null>(null);
const ConfigConsumer = ConfigContext.Consumer;
const configInstance = new Config(finalConfig);

const ConfigProvider = ({ children }: { children: React.ReactNode }) => (
  <ConfigContext.Provider value={{ config: configInstance }}>{children}</ConfigContext.Provider>
);

export { ConfigProvider, ConfigConsumer, configInstance, Config };

export default ConfigContext;
