import { useTranslation } from 'react-i18next';
import { Company, CompanyStatus, UpdateCompanyInput, User } from 'src/modules/common/types';
import {
  useEnhancedQuery,
  useIndustry,
  useMessages,
  useEnhancedMutation,
} from 'src/modules/common/application';
import { EDIT_COMPANY } from 'src/services/graphql/mutations';
import { GET_COMPANY_DETAILS, GET_USER_LOGIN_DETAILS } from 'src/services/graphql/queries';

const useCompany = () => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { t } = useTranslation();
  const { industries, subIndustries } = useIndustry();

  const { loading, data, error } = useEnhancedQuery<{ company: Company }>(GET_COMPANY_DETAILS);

  const [updateCompany] = useEnhancedMutation(EDIT_COMPANY, {
    onError: () => setErrorMessage(t('messages.updateCompany.error')),
    onCompleted: async () => {
      setSuccessMessage(t('messages.updateCompany.success'));
    },
    fetchPolicy: 'no-cache',
    update(cache, result, { variables }) {
      const { user, company } = cache.readQuery<{
        user: User;
        company: Company;
      }>({
        query: GET_USER_LOGIN_DETAILS,
      }) || { user: undefined, company: undefined };

      const { company: companyDetails } = cache.readQuery<{
        company: Company;
      }>({
        query: GET_COMPANY_DETAILS,
      }) || { company: undefined };

      if (company) {
        cache.writeQuery({
          query: GET_USER_LOGIN_DETAILS,
          data: {
            user,
            company: {
              ...company,
              ...variables?.input,
              status: CompanyStatus.COMPANY_INFO_COMPLETED,
            },
          },
        });
      }

      if (variables && variables.input.subIndustryId) {
        const subIndustry = subIndustries?.find(
          subIndustry => subIndustry.id === variables.input.subIndustryId
        );
        cache.writeQuery({
          query: GET_COMPANY_DETAILS,
          data: {
            company: {
              ...companyDetails,
              industry: industries?.find(industry => industry.id === subIndustry?.industry.id),
              subIndustry,
            },
          },
        });
      }
    },
  });

  const handleSubmitCompany = async (newCompany: UpdateCompanyInput) => {
    await updateCompany({
      variables: {
        input: {
          name: newCompany.name,
          dashboardUrl: newCompany.dashboardUrl,
          headquarter: newCompany.headquarter,
          employeesNum: newCompany.employeesNum,
          subIndustryId: newCompany.subIndustryId,
        },
      },
    });
  };

  return {
    loading,
    error,
    company: data?.company,
    handleSubmitCompany,
  };
};

export default useCompany;
