import {
  signInWithEmailAndPassword,
  updatePassword as firebaseUpdatePassword,
  createUserWithEmailAndPassword,
  signInWithCustomToken,
  signOut,
  Auth,
} from 'firebase/auth';
import { throwError } from 'src/modules/common/application/error-handling';
import { User } from 'src/modules/common/types/graphql';
import { generateRandomPassword } from 'src/modules/common/application/password';

import { PASSWORD_UPDATED, ACTIVATE_USER } from '../mutations';
import { cachePersistor } from '../cache';

const authResolvers = {
  Query: {},
  Mutation: {
    login: async (
      _: void,
      { password, email }: { password: string; email: string },
      // eslint-disable-next-line
      { client: { defaultOptions } }: any
    ) => {
      const auth = defaultOptions.firebase.getAuth();
      try {
        await signInWithEmailAndPassword(auth, email, password);
        // eslint-disable-next-line
      } catch (error: any) {
        throwError(error);
      }
    },
    loginViaToken: async (
      _: void,
      { token }: { token: string },
      { client: { defaultOptions } }: any
    ) => {
      try {
        const auth = defaultOptions.firebase.getAuth();
        return await signInWithCustomToken(auth, token);
      } catch (error) {
        throwError(error);
      }
    },
    logout: async (_: void, __: void, { client }: any) => {
      const auth = client.defaultOptions.firebase.getAuth();
      await client.resetStore();
      await client.clearStore();
      await signOut(auth);
      await cachePersistor.purge();
    },
    registerUser: async (
      _: void,
      { email }: { email: string },
      { client: { defaultOptions, mutate } }: any
    ) => {
      const auth: Auth = defaultOptions.firebase.getAuth();
      try {
        try {
          await createUserWithEmailAndPassword(auth, email as string, generateRandomPassword());
        } catch (error: any) {
          if (error.code !== 'auth/email-already-in-use') {
            throwError(error);
          }
        }
        await signOut(auth);
        return await mutate({
          mutation: ACTIVATE_USER,
          variables: { email },
        });
      } catch (error: any) {
        throwError(error);
      }
    },
    updatePassword: async (
      _: void,
      { newPassword }: { newPassword: string },
      { client }: any
    ): Promise<User | undefined> => {
      const auth = client.defaultOptions.firebase.getAuth();

      const user = auth.currentUser;

      try {
        await firebaseUpdatePassword(user, newPassword);
        const result = await client.mutate({
          mutation: PASSWORD_UPDATED,
        });

        return result?.data?.passwordUpdated;
      } catch (error: any) {
        throwError(error);
      }
    },
  },
};
export default authResolvers;
