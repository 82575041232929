import React from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Grid,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { getInputLabelStyles } from 'src/modules/common/application/form-styles';
import { CountryCode, Facility, FacilityType } from 'src/modules/common/types/graphql';
import Button from 'src/modules/common/components/Button/Button';
import { facilitySchema } from 'src/modules/common/application/facility.schema';

interface Props {
  data?: Facility;
  handleSubmit: (data: Facility) => void;
  handleReset?: () => void;
  editMode?: boolean;
}

const FacilityForm = ({ data, handleSubmit, handleReset, editMode }: Props) => {
  const { t } = useTranslation();
  const initalFormState = data
    ? data
    : ({
        name: '',
        city: '',
        type: FacilityType.OFFICE,
        country: CountryCode.DE,
        id: '',
      } as Facility);
  const fieldStyle = {
    marginBottom: 5,
  };

  return (
    <div>
      <Formik
        initialValues={{
          ...initalFormState,
        }}
        enableReinitialize={true}
        validationSchema={facilitySchema(t)}
        onSubmit={(values: Facility) => {
          handleSubmit(values);
        }}
      >
        {({ submitForm, handleChange, errors, values }) => (
          <Form>
            <Grid container rowSpacing={2} sx={{ mt: 2.5 }}>
              <Grid container item columnSpacing={3} sx={{ paddingTop: 2 }}>
                <Grid item mb={0}>
                  {/* name */}
                  <FormControl fullWidth sx={fieldStyle}>
                    <InputLabel htmlFor={values.name || ''} sx={getInputLabelStyles}>
                      {t('shared.types.facility.name')}
                    </InputLabel>
                    <TextField
                      id="name"
                      name="name"
                      type="text"
                      value={values.name ? values.name : ''}
                      onChange={handleChange}
                      fullWidth
                      placeholder={t('shared.types.facility.name')}
                    />
                    {errors.name && <FormHelperText>{errors.name}</FormHelperText>}
                  </FormControl>

                  {/* city */}
                  <FormControl fullWidth sx={fieldStyle}>
                    <InputLabel htmlFor={values.city || ''} sx={getInputLabelStyles}>
                      {t('shared.types.facility.city')}
                    </InputLabel>
                    <TextField
                      id="city"
                      name="city"
                      type="text"
                      value={values.city ? values.city : ''}
                      onChange={handleChange}
                      fullWidth
                      placeholder={t('shared.types.facility.city')}
                    />
                    {errors.city && <FormHelperText>{errors.city}</FormHelperText>}
                  </FormControl>

                  {/* type */}
                  <FormControl fullWidth sx={fieldStyle}>
                    <InputLabel htmlFor={values.type || ''} sx={getInputLabelStyles}>
                      {t('shared.types.facility.type')}
                    </InputLabel>
                    <Select
                      fullWidth
                      name="type"
                      id="type"
                      value={values.type ? values.type : ''}
                      onChange={handleChange}
                      displayEmpty
                    >
                      {Object.values(FacilityType).map(type => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.type && <FormHelperText>{errors.type}</FormHelperText>}
                  </FormControl>

                  {/* country */}
                  <FormControl fullWidth sx={fieldStyle}>
                    <InputLabel htmlFor={values.country || ''} sx={getInputLabelStyles}>
                      {t('shared.types.facility.country')}
                    </InputLabel>
                    <TextField
                      id="country"
                      name="country"
                      type="text"
                      value={values.country ? values.country : ''}
                      onChange={handleChange}
                      fullWidth
                      placeholder={t('shared.types.facility.country')}
                    />
                    {errors.country && <FormHelperText>{errors.country}</FormHelperText>}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                item
                sx={{
                  mt: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Button onClick={submitForm}>
                  {editMode ? t('modules.super.update') : t('modules.super.saveChanges')}
                </Button>
                <Button onClick={handleReset} color="secondary">
                  {t('modules.super.reset')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FacilityForm;
