import { useCallback } from 'react';
import isUndefined from 'lodash/isUndefined';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { useQuery, useApolloClient } = require('@apollo/client');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { GET_DRAWER_STATE_CLIENT } = require('src/services/graphql/queries');

const useDrawerState = () => {
  const client = useApolloClient();

  const { data } = useQuery(GET_DRAWER_STATE_CLIENT);

  const drawerOpen = data !== undefined ? data.drawerOpen : false;

  const toggleDrawer = useCallback(
    (open: boolean) => {
      const openState = isUndefined(open) ? (!drawerOpen as boolean) : (open as boolean);
      client.writeQuery({
        query: GET_DRAWER_STATE_CLIENT,
        data: {
          drawerOpen: openState,
        },
      });
    },
    [drawerOpen, client]
  );

  return {
    drawerOpen,
    toggleDrawer,
  };
};

export default useDrawerState;
