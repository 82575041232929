import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';

import useTokenLogin from 'src/modules/common/application/use-token-login';
import { Loader, PageContainer } from 'src/modules/common/components';
import {
  PublicErrorCard,
  PublicEsgDataInput,
  PublicFooter,
} from 'src/modules/data-management/components';
import PublicExpiredLinkCard from 'src/modules/data-management/components/PublicErrorCard/PublicExpiredLinkCard';
import { AppLogoIcon } from 'src/modules/common/components/icons';
import { useMessages } from 'src/modules/common/application';
import useRequestedEsgValue from 'src/modules/common/application/use-requested-esg-value';
import { UpdateRequstedEsgValueInput } from 'src/modules/common/types';

const PublicCollectInput = () => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const { collectOverviewId } = useParams();

  const { accessToken, loading: accessTokenLoading, isTokenExpired } = useTokenLogin(token);

  const {
    requestedEsgValues,
    loading,
    updateRequestedEsgValues,
    updateRequestedEsgValuesLoading,
    error,
  } = useRequestedEsgValue(collectOverviewId as string, accessToken as string);

  if (isTokenExpired && email) return <PublicExpiredLinkCard email={email} />;
  if (error) return <PublicErrorCard />;
  if (accessTokenLoading || !accessToken || loading || !requestedEsgValues) return <Loader />;
  if (!collectOverviewId) return <>{setErrorMessage(t('messages.generic.error'))}</>;

  const handleUpdateRequestedEsgValues = (value: UpdateRequstedEsgValueInput[]) => {
    updateRequestedEsgValues({
      variables: {
        input: value,
      },
    });
  };

  return (
    <PageContainer meta={'Mavue'}>
      <Box sx={{ margin: 5 }}>
        <Box>
          <IconButton
            onClick={() => {
              navigate('/shareable?email=' + encodeURIComponent(email!) + '&token=' + token);
            }}
          >
            <ArrowBackIcon color="primary" />
          </IconButton>
        </Box>
        <Box sx={{ mb: 2, mt: 2 }}>
          <AppLogoIcon />
        </Box>
        <PublicEsgDataInput
          handleUpdateRequestedEsgValues={handleUpdateRequestedEsgValues}
          requestedEsgValues={requestedEsgValues}
          updateRequestedEsgValuesLoading={updateRequestedEsgValuesLoading}
          accessToken={accessToken}
        />
      </Box>
      <PublicFooter />
    </PageContainer>
  );
};

export default PublicCollectInput;
