import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { PageContainer } from 'src/modules/common/components/Structure';

interface IErrorProps {
  error: any;
}

const Error = ({ error }: IErrorProps) => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
        }}
      >
        <Typography variant="h3" data-cy="snackbar-message-text">
          {t('modules.error.title')}
        </Typography>
      </Box>
    </PageContainer>
  );
};

export default Error;
