import { useMemo } from 'react';

import { useEnhancedQuery } from 'src/modules/common/application';
import { RoadmapStatistics } from 'src/modules/common/types';
import { GET_ROADMAP_STATS } from 'src/services/graphql/queries';
import {
  DataManagementIcon,
  ReportIcon,
  MaterialityAssessmentIcon,
} from 'src/modules/common/components/icons';

import { IRoadmapStep } from '../types';

const useRoadmap = (year: number) => {
  const { loading, data, error } = useEnhancedQuery<{
    getRoadmapStatistics: RoadmapStatistics;
  }>(GET_ROADMAP_STATS, {
    variables: {
      year,
    },
  });

  const roadmapStepsData = useMemo(() => {
    let materialityIsActive = true;
    let dataCollectionIsActive = false;
    let reportWritingIsActive = false;
    let materialityIsCompleted = false;
    let dataCollectionIsCompleted = false;
    let reportWritingIsCompleted = false;
    let materialityIsNextCompleted = false;
    let dataCollectionIsNextCompleted = false;
    let reportWritingIsNextCompleted = false;

    if (data?.getRoadmapStatistics) {
      const { materialityAssessment, dataCollection, reportWriting } = data.getRoadmapStatistics;
      if (materialityAssessment === 100) {
        materialityIsCompleted = true;
        dataCollectionIsActive = true;
        materialityIsActive = false;
      }
      if (materialityAssessment === 100 && dataCollection === 100) {
        dataCollectionIsCompleted = true;
        reportWritingIsActive = true;
        dataCollectionIsActive = false;
        materialityIsNextCompleted = true;
      }

      if (materialityAssessment === 100 && dataCollection === 100 && reportWriting === 100) {
        reportWritingIsCompleted = true;
        reportWritingIsActive = false;
        dataCollectionIsNextCompleted = true;
        reportWritingIsNextCompleted = true;
      }
    }

    return [
      {
        title: 'modules.home.sustainabilityReportingRoadmap.step1.title',
        to: '/materiality-assessment',
        Icon: MaterialityAssessmentIcon,
        tasks: [
          'modules.home.sustainabilityReportingRoadmap.step1.tasks.task1',
          'modules.home.sustainabilityReportingRoadmap.step1.tasks.task2',
          'modules.home.sustainabilityReportingRoadmap.step1.tasks.task3',
          'modules.home.sustainabilityReportingRoadmap.step1.tasks.task4',
          'modules.home.sustainabilityReportingRoadmap.step1.tasks.task5',
        ],
        progress: data?.getRoadmapStatistics?.materialityAssessment,
        ctaLabel: `modules.home.sustainabilityReportingRoadmap.step1.${
          materialityIsCompleted ? 'completeCtaLabel' : 'ctaLabel'
        }`,
        isActive: materialityIsActive,
        isCompleted: materialityIsCompleted,
        isNextCompleted: materialityIsNextCompleted,
      },
      {
        title: 'modules.home.sustainabilityReportingRoadmap.step2.title',
        to: '/data-management/collect',
        Icon: DataManagementIcon,
        tasks: [
          'modules.home.sustainabilityReportingRoadmap.step2.tasks.task1',
          'modules.home.sustainabilityReportingRoadmap.step2.tasks.task2',
          'modules.home.sustainabilityReportingRoadmap.step2.tasks.task3',
          'modules.home.sustainabilityReportingRoadmap.step2.tasks.task4',
          'modules.home.sustainabilityReportingRoadmap.step2.tasks.task5',
        ],
        progress: data?.getRoadmapStatistics?.dataCollection,
        ctaLabel: `modules.home.sustainabilityReportingRoadmap.step2.${
          dataCollectionIsCompleted ? 'completeCtaLabel' : 'ctaLabel'
        }`,
        isActive: dataCollectionIsActive,
        isCompleted: dataCollectionIsCompleted,
        isNextCompleted: dataCollectionIsNextCompleted,
      },
      {
        title: 'modules.home.sustainabilityReportingRoadmap.step3.title',
        to: '/reports',
        Icon: ReportIcon,
        tasks: [
          'modules.home.sustainabilityReportingRoadmap.step3.tasks.task1',
          'modules.home.sustainabilityReportingRoadmap.step3.tasks.task2',
          'modules.home.sustainabilityReportingRoadmap.step3.tasks.task3',
          'modules.home.sustainabilityReportingRoadmap.step3.tasks.task4',
          'modules.home.sustainabilityReportingRoadmap.step3.tasks.task5',
        ],
        ctaLabel: `modules.home.sustainabilityReportingRoadmap.step3.${
          reportWritingIsCompleted ? 'completeCtaLabel' : 'ctaLabel'
        }`,
        progress: data?.getRoadmapStatistics?.reportWriting,
        isActive: reportWritingIsActive,
        isCompleted: reportWritingIsCompleted,
        isNextCompleted: reportWritingIsNextCompleted,
      },
    ] as IRoadmapStep[];
  }, [data?.getRoadmapStatistics]);

  return {
    loading,
    error,
    data,
    roadmapStepsData,
  };
};

export default useRoadmap;
