import { AI_GENERATED_REPORT } from 'src/services/graphql/queries';
import useEnhancedQuery from '../../common/application/use-enhanced-query';
import useReportSections from './use-report-sections';
import useLanguage from '../../common/application/use-language';

export type SidebarData = {
  title: string;
  value: string;
  unit: string;
};

const useReportExample = (reportId: string, sectionId: string) => {
  const { language } = useLanguage();
  const { data, loading, error } = useEnhancedQuery<{
    aIGeneratedReport: string;
  }>(AI_GENERATED_REPORT, {
    variables: {
      reportId,
      reportSectionId: sectionId,
      language,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { getReportSectionById } = useReportSections(language, reportId);

  const reportSection = getReportSectionById(sectionId);
  const examples: string[] = [];

  if (data?.aIGeneratedReport) {
    examples.push(data?.aIGeneratedReport);
  }
  if (reportSection && reportSection.example) {
    examples.push(reportSection.example);
  }

  return {
    examples,
    loading,
    error,
  };
};

export default useReportExample;
