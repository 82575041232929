import React from 'react';
import { Grid, Link, styled, Theme, Typography, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ErrorStateProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  errorSubtitle?: string;
  label?: string;
  center?: boolean;
  to?: string;
}

interface ContainerProps {
  center?: boolean;
  theme: Theme;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = styled('div')<ContainerProps>(({ center = true, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'start',
  color: theme.palette.text.secondary,
  fontWeight: 500,

  ...(center && {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-60%, -50%, 0)',
  }),
}));

const ErrorState = ({ error, errorSubtitle, label, center = true, to }: ErrorStateProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  let displayError = '';

  if (error instanceof Error) displayError = error.message;
  if (typeof error === 'string') displayError = error;
  if (!error) displayError = t('messages.error.generic.fetchData');

  return (
    <Container center={center} theme={theme}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ lineHeight: 1.2 }}>
            {displayError}
          </Typography>
        </Grid>
        {errorSubtitle && (
          <Grid item xs={12}>
            <Typography
              variant="body2"
              sx={{
                color: 'text.secondary',
                paddingBottom: theme.spacing(2),
              }}
            >
              {errorSubtitle}
            </Typography>
          </Grid>
        )}
        {label && to && (
          <Grid item xs={12}>
            <Link
              component={RouterLink}
              to={to}
              color="primary"
              sx={{
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              <Typography variant="body2">{label}</Typography>
            </Link>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default ErrorState;
