import {
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
  DefaultContext,
  ApolloCache,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import { captureException } from 'src/infrastructure/errorHandler';

const useEnhancedMutation = <
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, TContext, TCache>,
  breakOnError?: boolean
): MutationTuple<TData, TVariables, TContext, TCache> => {
  const [mutateFunction, context] = useMutation<TData, TVariables, TContext, TCache>(
    mutation,
    options
  );
  if (context.error) {
    captureException(context.error);
    if (breakOnError) throw context.error;
  }
  return [mutateFunction, context];
};

export default useEnhancedMutation;
