import * as React from 'react';
import { Avatar } from '@mui/material';
import theme from 'src/styles/theme';
import { getColorForLetter } from './utils';
import { removeSpacesAtStart } from 'src/modules/common/application/string';

interface Props {
  input?: string;
  styles?: React.CSSProperties;
}

const LetterAvatars = ({ input = '', styles }: Props) => {
  const inputArray: string[] = input.split(' ');
  function getAvatarLetter(): string {
    if (!inputArray) return '';
    if (inputArray.length > 1) {
      return `${removeSpacesAtStart(inputArray[0]).charAt(0).toUpperCase()}${removeSpacesAtStart(
        inputArray[1]
      )
        .charAt(0)
        .toUpperCase()}`;
    } else {
      return `${removeSpacesAtStart(inputArray[0]).charAt(0).toUpperCase()}${removeSpacesAtStart(
        inputArray[0]
      )
        .charAt(1)
        .toUpperCase()}`;
    }
  }

  return (
    <div>
      {inputArray.length > 0 ? (
        <Avatar
          sx={{
            ...styles,
            bgcolor: getColorForLetter(removeSpacesAtStart(inputArray[0]).charAt(0).toUpperCase()),
            color: theme.palette.colors.lightGrey,
          }}
        >
          {getAvatarLetter()}
        </Avatar>
      ) : (
        <Avatar
          data-testid="avatar"
          sx={{
            ...styles,
          }}
        />
      )}
    </div>
  );
};

export default LetterAvatars;
