import React, { useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Button,
  TableFooter,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import CreateIcon from '@mui/icons-material/Create';

import { Avatar, Chip, LinearProgressBar } from 'src/modules/common/components';
import {
  getCategoryChipColor,
  getCollectOverviewStatusChipColor,
} from 'src/modules/common/application/ui';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import useUser from 'src/modules/common/application/use-user';
import NoFilteredDataPlaceholder from 'src/modules/common/components/NoFilteredDataPlaceholder/NoFilteredDataPlaceholder';
import { getProperFormatedDate } from 'src/modules/common/application/date';
import { ReminderIcon, EyeOutlinedIcon } from 'src/modules/common/components/icons';
import { getTotalPages } from 'src/modules/data-management/application/data-collect';
import { sortUserArray } from 'src/modules/common/application/sort';
import { getNameById } from 'src/modules/common/application/user';
import { TransformedCollectOverview } from 'src/modules/common/types/collect';
import { IntercomDataAttribute } from 'src/modules/common/types/intercom';
import { getIntercomDataAttribute } from 'src/modules/common/application/intercom';

import { StyledTableCell, StyledTablePagination, StyledTableRow } from './styles';
import CollectEsgAdminTableHeader from '../CollectEsgAdminTableHeader/CollectEsgAdminTableHeader';
import { CollectFilter, SortingOrder } from 'src/modules/common/types';

function descendingComparator<T extends Record<keyof T, any>>(a: T, b: T, orderBy: keyof T) {
  const valueA = orderBy === 'assignedTo' && a[orderBy] ? a[orderBy].firstName : a[orderBy];
  const valueB = orderBy === 'assignedTo' && b[orderBy] ? b[orderBy].firstName : b[orderBy];

  if (valueB === undefined || valueB === null || valueB < valueA) {
    return -1;
  }
  if (valueA === undefined || valueA === null || valueA < valueB) {
    return 1;
  }
  return 0;
}

function getComparator<T>(order: SortingOrder, orderBy: keyof T): (a: T, b: T) => number {
  return order === SortingOrder.DESC
    ? (a, b) => descendingComparator<T>(a, b, orderBy)
    : (a, b) => -descendingComparator<T>(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

interface CollectEsgAdminTableProps {
  data: TransformedCollectOverview[];
  hasAssignedUser: boolean;
  isAssignedUser: (id: string) => boolean;
  getAssignedUser: (id: string) => string;
  handleAssignUserChange: (id: string, person: string) => void;
  selectedRows: Record<string, TransformedCollectOverview>;
  hasSelectedCheckbox: boolean;
  isSelected: (id: TransformedCollectOverview) => boolean;
  handleSelectAllClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    data: TransformedCollectOverview[]
  ) => void;
  handleCheckboxClick: (row: TransformedCollectOverview) => void;
  handleDueDateClick: (row: TransformedCollectOverview) => void;
  handleReminderDialogOpen: (row: TransformedCollectOverview) => void;
  handleOpenAddExternalUserDialog: (
    isMultiAssignemnt: boolean,
    collectOverviewIds?: string[]
  ) => void;
  rowsPerPageCount: number;
  collectFilter: CollectFilter | undefined;
  handleOnRowCountChange: (count: number) => Promise<void>;
  handleSortingChange: (field: string, direction: SortingOrder) => Promise<void>;
  handleOpenQuickViewDialog: (row: TransformedCollectOverview, e: HTMLElement) => void;
}

const CollectEsgAdminTable = (props: CollectEsgAdminTableProps) => {
  const {
    data,
    hasAssignedUser,
    isAssignedUser,
    getAssignedUser,
    handleAssignUserChange,
    selectedRows,
    hasSelectedCheckbox,
    isSelected,
    handleSelectAllClick,
    handleCheckboxClick,
    handleDueDateClick,
    handleReminderDialogOpen,
    handleOpenAddExternalUserDialog,
    rowsPerPageCount,
    collectFilter,
    handleOnRowCountChange,
    handleSortingChange,
    handleOpenQuickViewDialog,
  } = props;

  const { orderDirection, orderField } = collectFilter || {
    orderDirection: SortingOrder.ASC,
    orderField: 'facilityName',
  };

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [showActionId, setShowActionId] = useState('');
  const [openMenuList, setOpenMenuList] = useState('');

  const { users } = useUser();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof TransformedCollectOverview
  ) => {
    const isAsc = orderField === property && orderDirection === SortingOrder.ASC;
    handleSortingChange(property, isAsc ? SortingOrder.DESC : SortingOrder.ASC);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    handleOnRowCountChange(parseInt(event.target.value, 10));
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPageCount - data.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort<TransformedCollectOverview>(
        data,
        getComparator(orderDirection, orderField as keyof TransformedCollectOverview)
      ).slice(page * rowsPerPageCount, page * rowsPerPageCount + rowsPerPageCount),
    [data, orderDirection, orderField, page, rowsPerPageCount]
  );

  const assignedUser = useCallback(
    (rowId: string): string | undefined => {
      if (visibleRows && visibleRows.length > 0) {
        const row = visibleRows.find((r: TransformedCollectOverview) => r.id === rowId);
        if (!row) {
          return '';
        } else if (row && isAssignedUser(row.id)) {
          const userId = getAssignedUser(row.id);
          return userId;
        } else if (row && !isAssignedUser(row.id) && row.assignedTo) {
          return row.assignedTo.id;
        } else {
          return '';
        }
      }
    },
    [getAssignedUser, isAssignedUser, visibleRows]
  );

  const sortedUsers = sortUserArray(users);

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer
        data-intercom-target={getIntercomDataAttribute(IntercomDataAttribute.collectTableContainer)}
      >
        <Table
          sx={{ minWidth: 750, backgroundColor: '#fff' }}
          aria-labelledby="tableTitle"
          size={'medium'}
        >
          <CollectEsgAdminTableHeader
            numSelected={Object.keys(selectedRows).length}
            order={orderDirection}
            orderBy={orderField}
            onSelectAllClick={event => handleSelectAllClick(event, data)}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            checkboxDisabled={hasAssignedUser && !hasSelectedCheckbox}
          />
          <TableBody>
            {visibleRows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <NoFilteredDataPlaceholder height={60 * rowsPerPageCount} />
                </TableCell>
              </TableRow>
            ) : (
              visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row);
                const labelId = `enhanced-table-checkbox-${index}`;
                const skipIntercomAttribute = index !== 0;
                return (
                  <StyledTableRow
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    onMouseEnter={() => {
                      setShowActionId(row.id);
                    }}
                    onMouseLeave={() => setShowActionId('')}
                    sx={{ '&:hover': { cursor: 'default' } }}
                  >
                    <StyledTableCell
                      align="center"
                      sx={{ width: '70px' }}
                      data-intercom-target={getIntercomDataAttribute(
                        IntercomDataAttribute.collectTableFirstRowCheckbox,
                        skipIntercomAttribute
                      )}
                    >
                      {(row.id === showActionId || isSelected(row)) &&
                      (!hasAssignedUser || hasSelectedCheckbox) ? (
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={() => handleCheckboxClick(row)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '12%' }}>{row.facilityName}</StyledTableCell>
                    <StyledTableCell sx={{ width: '18%' }}>
                      <Chip
                        size="small"
                        styles={{
                          maxWidth: 'fit-content',
                          opacity: 1,
                        }}
                        colorVariant={getCategoryChipColor(row.category)}
                      >
                        {t('shared.types.esgCategory.' + removeUnderscoreToCamelCase(row.category))}
                      </Chip>
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        paddingLeft: 0,
                        width: 250,
                      }}
                      data-intercom-target={getIntercomDataAttribute(
                        IntercomDataAttribute.collectTableFirstRowDataOwner,
                        skipIntercomAttribute
                      )}
                    >
                      <Select
                        labelId="responsiblePerson"
                        id="responsiblePerson"
                        value={assignedUser(row.id)}
                        displayEmpty
                        open={row.id === openMenuList}
                        onClose={() => setOpenMenuList('')}
                        IconComponent={() =>
                          (showActionId !== row.id || hasSelectedCheckbox) &&
                          !isAssignedUser(row.id) ? null : (
                            <ArrowDropDownIcon />
                          )
                        }
                        disabled={hasSelectedCheckbox}
                        onOpen={() => setOpenMenuList(row.id)}
                        onChange={(e: SelectChangeEvent) => {
                          e.stopPropagation();
                          if (e.target.value === 'ADD_EXTERNAL_USER') {
                            handleOpenAddExternalUserDialog(false, [row.id]);
                          } else {
                            handleAssignUserChange(row.id, e.target.value);
                          }
                          setOpenMenuList('');
                        }}
                        sx={{
                          paddingLeft: 0,
                          width: '200px',
                          border: '0px',
                          fontSize: '0.875rem !important',
                          '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            border: 0,
                          },
                          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                            boder: 0,
                          },
                          '&:hover': { cursor: 'pointer' },
                        }}
                        renderValue={selected => {
                          if (!selected) {
                            return (
                              <Typography
                                variant="body2"
                                sx={{ color: 'colors.grey', fontSize: '0.875rem !important' }}
                              >
                                {t('modules.dataManagement.esgDataOwner.selectUser')}
                              </Typography>
                            );
                          }
                          const userName = getNameById(selected, sortedUsers);
                          return (
                            <Box
                              key={selected}
                              sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}
                            >
                              <Avatar
                                styles={{
                                  width: '25px',
                                  height: '25px',
                                  fontSize: '0.675rem',
                                  fontWeight: 500,
                                }}
                                input={userName}
                              />
                              <Typography
                                variant="body1"
                                sx={{
                                  ml: 1,
                                  fontSize: '0.875rem !important',
                                }}
                              >
                                {userName}
                              </Typography>
                            </Box>
                          );
                        }}
                      >
                        <MenuItem value="">
                          <em>{t('modules.dataManagement.esgDataOwner.userSelect')}</em>
                        </MenuItem>
                        {sortedUsers?.map(user => {
                          return (
                            <MenuItem key={user.id} value={user.id} sx={{ height: '36px' }}>
                              <Box
                                sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}
                              >
                                <Avatar
                                  styles={{
                                    width: '25px',
                                    height: '25px',
                                    fontSize: '0.675rem',
                                    borderWidth: 1,
                                    fontWeight: 500,
                                  }}
                                  input={user.fullName}
                                />
                                <Typography
                                  variant="body1"
                                  sx={{
                                    ml: 1,
                                    fontSize: '0.875rem !important',
                                    fontWeight: 500,
                                  }}
                                >
                                  {user.fullName}
                                </Typography>
                              </Box>
                            </MenuItem>
                          );
                        })}
                        <MenuItem value="ADD_EXTERNAL_USER">
                          <Box
                            sx={{
                              width: '100%',
                              textAlign: 'left',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '0.875rem !important',
                                fontWeight: 500,
                                color: 'blue',
                                mr: 0.5,
                              }}
                            >
                              <em>{t('modules.dataManagement.esgDataOwner.addExternal')}</em>{' '}
                            </Typography>
                            <CreateIcon sx={{ color: 'blue', height: 18 }} />
                          </Box>
                        </MenuItem>
                      </Select>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '15%' }} id={`${row.id}-progress`}>
                      <Box sx={{ paddingRight: 4, display: 'flex', alignItems: 'center' }}>
                        <LinearProgressBar
                          progress={row.progress}
                          textStyle={{
                            bgcolor: '#F8F9FB',
                            width: '44px',
                            color: '#68677F',
                            borderRadius: '4px',
                            textAlign: 'center',
                            fontSize: '0.875rem !important',
                            fontWeight: 500,
                            py: 0.5,
                          }}
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '32px',
                            height: '26px',
                          }}
                          data-intercom-target={getIntercomDataAttribute(
                            IntercomDataAttribute.collectQuickViewIcon,
                            skipIntercomAttribute
                          )}
                        >
                          {row.id === showActionId && !hasSelectedCheckbox && !hasAssignedUser && (
                            <EyeOutlinedIcon
                              onClick={() => {
                                const e = document.getElementById(`${row.id}-progress`);
                                if (e) handleOpenQuickViewDialog(row, e);
                              }}
                              cursor="pointer"
                            />
                          )}
                        </Box>
                      </Box>
                    </StyledTableCell>

                    <StyledTableCell sx={{ width: '12%' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                          variant="text"
                          disabled={hasSelectedCheckbox || hasAssignedUser}
                          onClick={() => handleDueDateClick(row)}
                          sx={{
                            width: '110px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            paddingLeft: 0,
                            '&:hover': {
                              backgroundColor: 'transparent',
                              boxShadow: 'none',
                            },
                          }}
                          data-intercom-target={getIntercomDataAttribute(
                            IntercomDataAttribute.collectTableFirstRowDueDate,
                            skipIntercomAttribute
                          )}
                        >
                          <Typography
                            variant="body2"
                            display="inline"
                            sx={{
                              color: 'colors.grey',
                              fontSize: '0.875rem !important',
                              textDecoration:
                                row.id === showActionId && !hasSelectedCheckbox && !hasAssignedUser
                                  ? 'underline'
                                  : 'none',
                              textAlign: 'left',
                            }}
                          >
                            {row.dueDate
                              ? getProperFormatedDate(row.dueDate.toString(), 'DD.MM.YYYY')
                              : t('modules.dataManagement.collectEsgAdminTable.dueDateDefault')}
                          </Typography>
                        </Button>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '32px',
                            height: '26px',
                          }}
                          data-intercom-target={getIntercomDataAttribute(
                            IntercomDataAttribute.collectTableFirstRowBellIcon,
                            skipIntercomAttribute
                          )}
                        >
                          {row.id === showActionId && !hasSelectedCheckbox && !hasAssignedUser && (
                            <ReminderIcon
                              onClick={() => handleReminderDialogOpen(row)}
                              cursor="pointer"
                            />
                          )}
                        </Box>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '12%' }}>
                      <Chip
                        size="small"
                        styles={{
                          maxWidth: 'fit-content',
                          opacity: 1,
                        }}
                        colorVariant={getCollectOverviewStatusChipColor(row.status)}
                      >
                        {t(
                          'shared.types.collectOverviewStatus.' +
                            removeUnderscoreToCamelCase(row.status)
                        )}
                      </Chip>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ width: '70px' }}
                      onClick={() => {
                        navigate('/data-management/collect/' + row.id);
                      }}
                      data-intercom-target={getIntercomDataAttribute(
                        IntercomDataAttribute.collectTableFirstRowKPIDetailIcon,
                        skipIntercomAttribute
                      )}
                    >
                      {row.id === showActionId && !hasSelectedCheckbox && !hasAssignedUser ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <ArrowForwardIcon color="primary" cursor="pointer" />
                        </Box>
                      ) : (
                        ''
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            )}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                count={data.length}
                labelRowsPerPage={
                  <span style={{ fontSize: '0.875rem' }}>
                    {t('modules.dataManagement.collectEsgAdminTable.pagination.rowsPerPage')}
                  </span>
                }
                labelDisplayedRows={row => (
                  <span style={{ fontSize: '0.875rem' }}>
                    {t('modules.dataManagement.collectEsgAdminTable.pagination.currentPage', {
                      currentPage: page + 1,
                      totalPages: getTotalPages(rowsPerPageCount, data.length),
                    })}
                  </span>
                )}
                rowsPerPage={rowsPerPageCount}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CollectEsgAdminTable;
