import React from 'react';

const ArrowDownIcon = (props: any) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25">
      <path
        {...props}
        d="M8.92 9.79l3.88 3.88 3.88-3.88a.996.996 0 111.41 1.41l-4.59 4.59a.996.996 0 01-1.41 0L7.5 11.2a.996.996 0 010-1.41c.39-.38 1.03-.39 1.42 0z"
      ></path>
    </svg>
  );
};

export default ArrowDownIcon;
