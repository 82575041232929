import React from 'react';
import { Stats } from 'src/modules/common/types';
import { OverviewStats } from 'src/modules/common/components';

interface ISocialStatProps {
  numberEmployees: number;
  femaleEmployees: number;
  occupationalAccidents: number;
  trainingHours: number;
}

const SocialOverviewStats = ({
  numberEmployees,
  femaleEmployees,
  occupationalAccidents,
  trainingHours,
}: ISocialStatProps) => {
  const socialStats1Mock: Stats = {
    title: 'modules.analysis.socialOverviewStats.totalNumberEmployees',
    value: numberEmployees,
    unit: '',
  };

  const socialStats2Mock: Stats = {
    title: 'modules.analysis.socialOverviewStats.percentageFemaleEmployees',
    value: femaleEmployees,
    unit: '%',
    progress: femaleEmployees,
  };

  const socialStats3Mock: Stats = {
    title: 'modules.analysis.socialOverviewStats.amountWorkAccidents',
    value: occupationalAccidents,
    unit: '',
  };

  const socialStats4Mock: Stats = {
    title: 'modules.analysis.socialOverviewStats.averageTrainingHours',
    value: trainingHours,
    unit: '',
  };
  return (
    <OverviewStats
      data={[socialStats1Mock, socialStats2Mock, socialStats3Mock, socialStats4Mock]}
    />
  );
};

export default SocialOverviewStats;
