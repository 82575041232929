import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COLLECT_OVERVIEWS } from 'src/services/graphql/queries/collect';
import { CollectOverview } from 'src/modules/common/types';
import { TransformedCollectOverview } from 'src/modules/common/types/collect';
import { CHANGE_COLLECT_DUE_DATE } from 'src/services/graphql/mutations/collect';
import { useEnhancedMutation, useMessages } from 'src/modules/common/application';

const useCollectDueDate = (
  year: number,
  selectedRows: Record<string, TransformedCollectOverview>,
  singleSelection: TransformedCollectOverview | null,
  dispatchMutli: boolean
) => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { t } = useTranslation();

  const [openDueDateDialog, setOpenDueDateDialog] = useState(false);
  const [initialDueDateValue, setInitialDueDateValue] = useState<Date | null>(null);

  const [changeDueDate, { loading }] = useEnhancedMutation(CHANGE_COLLECT_DUE_DATE, {
    onCompleted: () => setSuccessMessage(t('messages.dueDate.success')),
    onError: () => setErrorMessage(t('messages.dueDate.error')),
    update(cache, result, { variables }) {
      const { collectOverviews } = cache.readQuery<{ collectOverviews: CollectOverview[] }>({
        query: COLLECT_OVERVIEWS,
        variables: {
          year,
        },
      }) || { collectOverviews: [] };

      const existedData: CollectOverview[] = JSON.parse(JSON.stringify(collectOverviews));

      if (variables && variables.collectOverviewIds && variables.collectOverviewIds.length > 0) {
        for (const collectOverviewId of variables.collectOverviewIds) {
          const index = existedData.findIndex(
            collectOverview => collectOverview.id === collectOverviewId
          );

          if (index > -1) {
            existedData[index].dueDate = variables.dueDate ? new Date(variables.dueDate) : null;
          }
        }

        cache.writeQuery({
          query: COLLECT_OVERVIEWS,
          data: { collectOverviews: existedData },
          variables: {
            year,
          },
        });
      }
    },
  });

  const updateDueDate = async (dueDate: Date | null | undefined) => {
    let collectOverviewIds: string[] = [];
    if (dispatchMutli) {
      for (const [, value] of Object.entries(selectedRows)) {
        collectOverviewIds.push(value.id);
      }
    } else if (singleSelection) {
      collectOverviewIds = [singleSelection.id];
    }

    if (collectOverviewIds.length === 0) return;

    await changeDueDate({
      variables: {
        collectOverviewIds,
        dueDate: dueDate ? new Date(dueDate) : undefined,
      },
    });
  };

  const onDueDateClick = (row?: TransformedCollectOverview) => {
    setOpenDueDateDialog(true);
    setInitialDueDateValue(row && row.dueDate ? row.dueDate : null);
  };

  const handleDueDateSaveButton = async (dueDate: Date | null | undefined) => {
    await updateDueDate(dueDate);
    setOpenDueDateDialog(false);
  };

  const handleDueDateCancelButton = () => {
    setOpenDueDateDialog(false);
  };

  return {
    loading,
    onDueDateClick,
    handleDueDateCancelButton,
    handleDueDateSaveButton,
    openDueDateDialog,
    initialDueDateValue,
    setInitialDueDateValue,
  };
};

export default useCollectDueDate;
