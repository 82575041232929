import React from 'react';
import { Box, Typography } from '@mui/material';

import { getStackholderLabel } from 'src/modules/common/application/user';
import Avatar from 'src/modules/common/components/Avatar/Avatar';
import { User } from 'src/modules/common/types';
import { AppIcon } from 'src/modules/common/components/icons';

interface GroupedStakeholderAvatarProps {
  collaborators: User[];
  maxCount?: number;
  showMavueRecommendation?: boolean;
  handeleOnContainerClick?: () => void;
}

export const GroupedStakeholderAvatarWithCount = ({
  collaborators,
  maxCount = 3,
  showMavueRecommendation = false,
  handeleOnContainerClick,
}: GroupedStakeholderAvatarProps) => {
  const modifiedArray =
    collaborators.length <= maxCount ? collaborators : collaborators.slice(0, maxCount);
  const remainingCount = collaborators.length <= maxCount ? 0 : collaborators.length - maxCount;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={() => {
        if (handeleOnContainerClick) handeleOnContainerClick();
      }}
    >
      {modifiedArray?.map((c, index) => (
        <Avatar
          key={index}
          input={`${getStackholderLabel(c!)}` || ''}
          styles={{
            width: '25px',
            height: '25px',
            fontSize: '0.675rem',
            borderWidth: 1,
            padding: 1,
            marginLeft: index > 0 ? -1 : 0,
          }}
        />
      ))}
      {showMavueRecommendation && (
        <Box
          sx={{
            width: '25px',
            height: '25px',
            borderWidth: 1,
            borderColor: 'red',
            borderRadius: '50%',
            backgroundColor: '#F8F9FB',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            marginLeft: modifiedArray.length > 0 ? -1 : 0,
            zIndex: 1,
          }}
        >
          <AppIcon
            style={{
              width: '25px',
              height: '25px',
              transform: 'scale(0.5)',
            }}
          />
        </Box>
      )}
      {remainingCount > 0 && (
        <Typography
          sx={{
            color: 'colors.lightGrey',
            pl: 1,
            fontSize: '14px !important',
            fontWeight: 500,
          }}
        >
          +{remainingCount}
        </Typography>
      )}
    </Box>
  );
};
