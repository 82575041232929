import * as i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const i18nConfig = {
  fallbackLng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false,
  },
};

const getI18Next = (ciMode = false) => {
  return ciMode
    ? i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
          ...i18nConfig,
          lng: 'ciMode',
        })
    : i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(i18nConfig);
};

export default getI18Next;
