export const generateTestCompanyMembers = () => ({
  __typename: 'CompanyUsers',
  edges: [
    {
      node: {
        __typename: 'User',
        id: '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d',
        firstName: 'Clemens',
        lastName: 'Dieffendahl',
        email: 'cdieffendahl@gmail.com',
        role: 'Admin',
        position: 'Associate',
        createdTimestamp: null,
      },
      cursor: 'YXJyYXljb25uZWN0aW9uOjA=',
    },
  ],
});
