import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  ApprovalStatus,
  Unit,
  UpdatedRequestedEsgValuesByOwnerInput,
} from 'src/modules/common/types';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import { getStatusChipColor } from 'src/modules/common/application/ui';
import { Loader, Chip } from 'src/modules/common/components';
import { NoDataIcon } from 'src/modules/common/components/icons';

import EsgDataApprovalTable from '../EsgDataApprovalTable/EsgDataApprovalTable';
import useRequstedValueByOwner from 'src/modules/common/application/use-requsted-values-by-owner';

const EsgDataApproval = () => {
  const { t } = useTranslation();
  const { collectOverviewId } = useParams();

  const {
    requestedEsgValuesByOwner,
    requestedEsgValuesByOwnerLoading,
    updateRequestedEsgValueApprovalStatus,
  } = useRequstedValueByOwner(collectOverviewId ? collectOverviewId.toString() : '');

  if (requestedEsgValuesByOwnerLoading || !requestedEsgValuesByOwner) return <Loader />;

  const handleApprovalClick = (
    esgValueId: string,
    newValue: number,
    newUnit: Unit,
    status: ApprovalStatus
  ) => {
    updateRequestedEsgValueApprovalStatus({
      variables: {
        input: {
          id: esgValueId,
          newValue: parseFloat(newValue.toString()),
          newUnit,
          approvalStatus: status,
        } as UpdatedRequestedEsgValuesByOwnerInput,
      },
    });
  };

  return (
    <>
      {requestedEsgValuesByOwner.length === 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          justifyContent="center"
          alignItems="center"
          marginTop="15%"
        >
          <NoDataIcon width="34" height="39" />
          <Typography variant="body1" align="center">
            {t('modules.dataManagement.esgDataApproval.notRequired')}
          </Typography>
          <Typography variant="body2" align="center">
            {t('modules.dataManagement.esgDataApproval.explanation')}
          </Typography>
        </Box>
      ) : (
        <Box>
          {requestedEsgValuesByOwner.map(item => {
            return item.esgValues === null ? (
              <Accordion
                key={item.externalUserEmail}
                disabled
                sx={{
                  backgroundColor: 'white',
                  marginBottom: 3,
                  boxShadow: 'none',
                  border: '1px solid',
                  borderColor: 'lightGrey',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="esg-data-approval-accordion-disabled-content"
                  id="esg-data-approval-accordion-disabled"
                >
                  <Typography>
                    {item.externalUserEmail === 'REMOVED_USER'
                      ? t(`modules.dataManagement.esgDataApproval.removedUser`)
                      : item.externalUserEmail}
                  </Typography>
                  <Typography paddingLeft={20}>{item.requestStatus}</Typography>
                </AccordionSummary>
              </Accordion>
            ) : (
              <Accordion
                key={item.externalUserEmail}
                square
                sx={{
                  backgroundColor: 'white',
                  marginBottom: 3,
                  boxShadow: 'none',
                  border: '1px solid',
                  borderColor: 'lightGrey',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="esg-data-approval-accordion-content"
                  id="esg-data-approval-accordion"
                >
                  <Typography
                    variant="inherit"
                    fontSize={'0.875rem !important'}
                    sx={{ paddingLeft: 2 }}
                  >
                    {item.externalUserEmail === 'REMOVED_USER'
                      ? t(`modules.dataManagement.esgDataApproval.removedUser`)
                      : item.externalUserEmail}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }}></Box>
                  <Box sx={{ paddingRight: 2 }}>
                    <Chip size="small" colorVariant={getStatusChipColor(item.requestStatus)}>
                      {t(`shared.types.status.${removeUnderscoreToCamelCase(item.requestStatus)}`)}
                    </Chip>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0' }}>
                  {item.esgValues && (
                    <EsgDataApprovalTable
                      data={item.esgValues}
                      onApprovalClick={handleApprovalClick}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      )}
    </>
  );
};

export default EsgDataApproval;
