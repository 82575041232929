import React from 'react';
import { useMessages, useEnhancedMutation } from 'src/modules/common/application';
import { useTranslation } from 'react-i18next';
import Button from 'src/modules/common/components/Button/Button';
import { CLEAN_UNLINKED_REQUESTED_ESG_VALUES } from 'src/services/graphql/mutations';

const CleanUnlinkedRequestedESGValue = ({ companyId }: { companyId: string }) => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { t } = useTranslation();

  const [cleanUnlinkedRequestedESGValue, { loading }] = useEnhancedMutation(
    CLEAN_UNLINKED_REQUESTED_ESG_VALUES,
    {
      onCompleted: () => setSuccessMessage(t('messages.calculation.success')),
      onError: () => setErrorMessage(t('messages.calculation.error')),
    }
  );

  const handleClick = () => {
    cleanUnlinkedRequestedESGValue({
      variables: {
        companyId,
      },
    });
  };

  return (
    <Button
      sx={{
        width: '100%',
        mt: 2,
      }}
      onClick={handleClick}
      loading={loading}
    >
      Clean Unlinked Requested ESG Value
    </Button>
  );
};

export default CleanUnlinkedRequestedESGValue;
