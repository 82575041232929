import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';
import { captureException } from 'src/infrastructure/errorHandler';

const useEnhancedQuery = <TData = any>(
  query: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  options?: QueryHookOptions,
  breakOnError?: boolean
) => {
  const { data, loading, refetch, error } = useQuery<TData>(query, options);

  if (error) {
    captureException(error);
    if (breakOnError) throw error;
  }

  return {
    data,
    loading,
    refetch,
    error,
  };
};

export default useEnhancedQuery;
