import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { Grid, LinearProgress, Popover, Typography } from '@mui/material';
import { CollectOverviewStatus, EsgCategoryEnum, User } from 'src/modules/common/types';
import Button from 'src/modules/common/components/Button/Button';
import { Chip } from 'src/modules/common/components/Chip';
import theme from 'src/styles/theme';
import Avatar from 'src/modules/common/components/Avatar/Avatar';
import { getUserLabel } from 'src/modules/common/application/user';
import useEsgValue from 'src/modules/common/application/use-esg-value';
import {
  getCategoryChipColor,
  getCollectOverviewStatusChipColor,
  getProgressBarColor,
} from 'src/modules/common/application/ui';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import { QuickViewKPICard } from '../QuickViewKPICard/QuickViewKPICard';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  dueDate?: Date;
  assignee?: Partial<User>;
  status: CollectOverviewStatus;
  category: EsgCategoryEnum;
  esgKpis: { name: string; id: string }[];
  anchorEl?: HTMLElement;
  id: string;
}

export const EsgQuickViewDialog = (props: Props) => {
  const { t } = useTranslation();
  const { isOpen, onClose, dueDate, assignee, status, category, esgKpis, anchorEl, id } = props;

  const { esgValues } = useEsgValue(id);
  const filteredKpis = esgKpis.filter(
    kpi => esgValues?.findIndex(esgValue => esgValue.kpi.id === kpi.id) !== -1
  );
  const transformedKpis: { name: string; collected: boolean }[] = filteredKpis.map(kpi => {
    const esgValue = esgValues?.find(value => value.kpi.id === kpi.id);
    return {
      name: kpi.name,
      collected: esgValue?.value !== null,
    };
  });

  const completedKpis = transformedKpis.filter(kpi => kpi.collected).length;
  const progress = (completedKpis / transformedKpis.length) * 100;

  const navigate = useNavigate();

  const minWidth = '25%';
  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          px: 5,
          py: 4,
          boxShadow: '0px 4px 10px 0px #D9D9D9',
          borderRadius: '5px',
          border: '1px solid #D9D6D6',
          width: '460px',
          gap: 2,
          backgroundColor: '#fff',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography fontSize={'1.5rem !important'} fontWeight={600}>
            {t('modules.dataManagement.esgQuickViewDialog.title')}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 'fit-content',
            }}
          >
            <LinearProgress
              data-testid="linear-progress"
              color={getProgressBarColor(progress!)}
              value={progress}
              variant="determinate"
              sx={{
                width: '120px',
                borderRadius: '5px',
                height: '5px',
              }}
            />
            <Typography
              sx={{
                ml: 1,
                background: theme.palette.background.default,
                px: 1,
                py: 0.5,
                borderRadius: '5px',
              }}
              color={theme.palette.colors.lightGrey}
              fontSize={'0.875rem !important'}
              fontWeight={500}
            >
              {completedKpis + ' / ' + transformedKpis.length}
            </Typography>
          </Box>
        </Box>
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Grid item xs={4}>
            <Typography
              color={theme.palette.colors.lightGrey}
              fontSize={'0.875rem !important'}
              fontWeight={500}
              minWidth={minWidth}
            >
              {t('modules.dataManagement.esgQuickViewDialog.dueDate')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography fontSize={'0.875rem !important'} fontWeight={500}>
              {dueDate && new Date(dueDate).toLocaleDateString()}
              {!dueDate && '-'}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Grid item xs={4}>
            <Typography
              color={theme.palette.colors.lightGrey}
              fontSize={'0.875rem !important'}
              fontWeight={500}
              minWidth={minWidth}
            >
              {t('modules.dataManagement.esgQuickViewDialog.assignee')}
            </Typography>
          </Grid>

          <Grid
            item
            xs={8}
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {!assignee && '-'}
            {assignee && (
              <Avatar
                input={getUserLabel(assignee as User)}
                styles={{
                  width: 25,
                  height: 25,
                  fontSize: '0.676rem',
                  fontWeight: 500,
                }}
              />
            )}
            {assignee && (
              <Typography
                color={theme.palette.colors.lightGrey}
                fontSize={'0.875rem !important'}
                fontWeight={500}
                sx={{ ml: 1 }}
              >
                {getUserLabel(assignee)}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Grid item xs={4}>
            <Typography
              color={theme.palette.colors.lightGrey}
              fontSize={'0.875rem !important'}
              fontWeight={500}
              width={'25%'}
            >
              {t('modules.dataManagement.esgQuickViewDialog.status')}
            </Typography>
          </Grid>
          <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <Chip
              size="small"
              styles={{ maxWidth: 'fit-content', marginRight: 1, marginBottom: 1 }}
              colorVariant={getCollectOverviewStatusChipColor(status)}
            >
              {t(`shared.types.collectOverviewStatus.${removeUnderscoreToCamelCase(status)}`)}
            </Chip>

            <Chip
              size="small"
              styles={{ maxWidth: 'fit-content' }}
              colorVariant={getCategoryChipColor(category)}
            >
              {t(`shared.types.esgCategory.${removeUnderscoreToCamelCase(category)}`)}
            </Chip>
          </Grid>
        </Grid>
        <Box
          sx={{
            overflowY: 'auto',
            maxHeight: '200px',
          }}
        >
          {transformedKpis.length > 0 ? (
            transformedKpis.map((kpi, key) => {
              return <QuickViewKPICard key={key} data={kpi} />;
            })
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                p: '12px',
                border: '1px solid #D9D6D6',
                borderRadius: '5px',
                alignItems: 'center',
                mb: 1,
              }}
            >
              <Typography
                color={theme.palette.colors.lightGrey}
                fontSize={'0.875rem !important'}
                fontWeight={500}
                width={'90%'}
              >
                {t('modules.dataManagement.esgQuickViewDialog.noActiveKpis')}
              </Typography>
            </Box>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end" sx={{ mt: 1 }}>
          <Button variant="outlined" size="small" onClick={onClose}>
            {t('modules.dataManagement.esgQuickViewDialog.close')}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => navigate('/data-management/collect/' + id)}
            sx={{ ml: 2 }}
          >
            {t('modules.dataManagement.esgQuickViewDialog.moreInfo')}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

export default EsgQuickViewDialog;
