import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { NoDataIcon } from 'src/modules/common/components/icons';

const NoDataPlaceholder = () => {
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <NoDataIcon width="34" height="39" />
      <Typography>{t('messages.generic.noData')}</Typography>
    </Box>
  );
};

export default NoDataPlaceholder;
