import { useTranslation } from 'react-i18next';

import { Report } from 'src/modules/common/types';
import { GET_REPORTS } from 'src/services/graphql/queries';
import { CREATE_REPORT, DELETE_REPORT, UPDATE_REPORT } from 'src/services/graphql/mutations';
import { useEnhancedQuery, useMessages, useEnhancedMutation } from 'src/modules/common/application';

const useReportsManagement = ({ selectedReport }: { selectedReport: Report | null }) => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { t } = useTranslation();

  const { loading, data, error } = useEnhancedQuery<{ reports: Report[] }>(GET_REPORTS);

  const [createReport, { loading: createReportLoading }] = useEnhancedMutation<{
    createReport: Report;
  }>(CREATE_REPORT, {
    onCompleted: () => setSuccessMessage(t('messages.createReport.success')),
    onError: () => setErrorMessage(t('messages.createReport.error')),
    update(cache, { data }) {
      const { reports } = cache.readQuery<{ reports: Report[] }>({
        query: GET_REPORTS,
      }) || { reports: [] };

      cache.writeQuery({
        query: GET_REPORTS,
        data: { reports: [...reports, data?.createReport] },
      });
    },
  });

  const [updateReport] = useEnhancedMutation<{
    updateReport: Report;
  }>(UPDATE_REPORT, {
    onCompleted: () => setSuccessMessage(t('messages.updateReport.success')),
    onError: () => setErrorMessage(t('messages.updateReport.error')),
    update(cache, { data }) {
      const { reports } = cache.readQuery<{ reports: Report[] }>({
        query: GET_REPORTS,
      }) || { reports: [] };

      const index = reports.findIndex(report => report.id === data?.updateReport?.id);

      if (data?.updateReport && index > -1) {
        const newReports = [...reports];
        newReports[index] = data.updateReport;
        cache.writeQuery({
          query: GET_REPORTS,
          data: { reports: newReports },
        });
      }
    },
  });

  const [deleteReport, { loading: deleteReportLoading }] = useEnhancedMutation<{
    reportId: string;
  }>(DELETE_REPORT, {
    onCompleted: () => setSuccessMessage(t('messages.deleteReport.success')),
    onError: () => setErrorMessage(t('messages.deleteReport.error')),
    update(cache) {
      const { reports } = cache.readQuery<{ reports: Report[] }>({
        query: GET_REPORTS,
      }) || { reports: [] };
      const updatedReports = reports.filter(report => report.id !== selectedReport?.id);
      cache.writeQuery({
        query: GET_REPORTS,
        data: { reports: updatedReports },
      });
    },
  });

  return {
    loading,
    createReportLoading,
    deleteReportLoading,
    data,
    error,
    createReport,
    updateReport,
    deleteReport,
  };
};

export default useReportsManagement;
