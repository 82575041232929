import React from 'react';
import { useTheme } from '@mui/material';

const ReminderIcon = (props: any) => {
  const theme = useTheme();
  return (
    <svg
      width="31"
      height="25"
      viewBox="0 0 31 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.200195" width="30.3241" height="25" rx="5" fill="#F8F9FB" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5832 14.3655L21.5743 14.3565C21.1624 13.9689 20.2441 12.4401 20.1857 10.6536V8.6561C20.1857 6.15368 18.1541 4.125 15.3623 4.125C12.5704 4.125 10.5388 6.15368 10.5388 8.6561V10.5347C10.5096 12.0887 9.75092 13.7911 9.15026 14.3565L9.14129 14.3655C9.05467 14.4521 8.95532 14.5244 8.85614 14.5967L8.79529 14.641C8.43429 14.9071 8.2002 15.3353 8.2002 15.8182C8.2002 16.6255 8.85468 17.2798 9.66184 17.2798H21.0627C21.87 17.2798 22.5243 16.6255 22.5243 15.8182C22.5243 15.3354 22.2902 14.9071 21.9292 14.641L21.8685 14.5965C21.7692 14.5244 21.6699 14.4521 21.5832 14.3655L21.5832 14.3655ZM12.0003 10.5621L12.0006 10.5347V8.65613C12.0006 7.02092 13.3166 5.58667 15.3624 5.58667C17.4082 5.58667 18.7242 7.02092 18.7242 8.65613V10.6537C18.7242 10.6612 18.7242 10.6688 18.7243 10.6763C18.7245 10.6847 18.7246 10.6931 18.7248 10.7015C18.7617 11.8235 19.0653 12.8373 19.4101 13.6194C19.7323 14.35 20.1527 15.0169 20.553 15.4022C20.7324 15.581 20.9476 15.7353 21.0339 15.7973L21.062 15.8175L9.66197 15.8182L9.69085 15.7973C9.77698 15.7355 9.99149 15.5815 10.1708 15.4032C10.6951 14.9007 11.1252 14.1077 11.4213 13.3459C11.7365 12.5352 11.9818 11.5492 12.0002 10.5621L12.0003 10.5621Z"
        fill={theme.palette.colors.grey}
        fillOpacity="0.5"
      />
      <path
        d="M13.4722 19.6113C13.3693 19.3629 13.3164 19.097 13.3164 18.8281H17.409C17.409 19.097 17.3562 19.3629 17.2532 19.6113C17.1505 19.8594 16.9997 20.0852 16.8097 20.2751C16.6196 20.4652 16.394 20.6159 16.1458 20.7187C15.8975 20.8216 15.6314 20.8745 15.3627 20.8745C15.094 20.8745 14.828 20.8216 14.5797 20.7187C14.3314 20.6159 14.1058 20.4652 13.9158 20.2751C13.7257 20.0852 13.575 19.8595 13.4722 19.6113H13.4722Z"
        fill={theme.palette.colors.grey}
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default ReminderIcon;
