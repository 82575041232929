import React, { ReactNode } from 'react';
import { PostHogProvider } from 'posthog-js/react';

const PostHogProviderWrapper = ({ children }: { children: ReactNode }) => {
  const options = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  };

  if (window.location.host.startsWith('localhost')) return <>{children}</>;
  return (
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
      {children}
    </PostHogProvider>
  );
};

export default PostHogProviderWrapper;
