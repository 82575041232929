import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { useNavigate, useParams } from 'react-router-dom';

import { ReportSection } from 'src/modules/common/types';
import theme from 'src/styles/theme';

interface IReportSectionSidebarCard {
  sectionData: ReportSection;
  isSidebarCollapsed: boolean;
}

const ReportSectionSidebarCard = (props: IReportSectionSidebarCard) => {
  const { t } = useTranslation();
  const { reportId } = useParams();
  const navigate = useNavigate();
  const { sectionData, isSidebarCollapsed } = props;

  const isMarkedCompleted = sectionData.reportInput?.isMarkedCompleted === true ? true : false;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        border: theme.custom.border,
        borderColor: isMarkedCompleted
          ? theme.palette.colors.successGreen
          : theme.palette.background.grey,
        backgroundColor: isMarkedCompleted
          ? theme.palette.colors.lightSuccessGreen
          : theme.palette.colors.white,
        px: isSidebarCollapsed ? 0 : '20px',
        py: 1,
        mb: 1,
        borderRadius: '5px',
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: isSidebarCollapsed ? 'center' : 'flex-start',
      }}
      onClick={() => navigate('/reports/' + reportId + '/section/' + sectionData.id)}
    >
      {!isMarkedCompleted && (
        <CircleOutlinedIcon
          sx={{
            mr: isSidebarCollapsed ? 0 : 1,
            color: '#D9D9D9',
            height: '24px',
            width: '24px',
          }}
        />
      )}
      {isMarkedCompleted && (
        <TaskAltOutlinedIcon
          sx={{
            mr: isSidebarCollapsed ? 0 : 1,
            color: theme.palette.colors.successGreen,
            height: '24px',
            width: '24px',
          }}
        />
      )}
      {!isSidebarCollapsed && (
        <Typography
          fontSize={'0.875rem !important'}
          fontWeight={500}
          color={
            isMarkedCompleted ? theme.palette.colors.successGreen : theme.palette.colors.lightGrey
          }
        >
          {t(`shared.types.reportSectionChapter.${sectionData.chapter}`)}
        </Typography>
      )}
    </Box>
  );
};

export default ReportSectionSidebarCard;
