import { useState } from 'react';

import { TransformedCollectOverview } from 'src/modules/common/types/collect';

const useCollectSelection = () => {
  const [dispatchMutli, setDispatchMutli] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Record<string, TransformedCollectOverview>>({});
  const [singleSelection, setSingleSelection] = useState<TransformedCollectOverview | null>(null);
  const [showMultiSelectSnackbar, setShowMultiSelectSnackbar] = useState(false);

  const hasSelected = Object.keys(selectedRows).length > 0;

  function toggleSelect(row: TransformedCollectOverview) {
    let hasAnySelected = true;

    if (!isSelected(row)) {
      setSelectedRows({ ...selectedRows, [row.id]: row });
    } else {
      const newSelection = { ...selectedRows };
      delete newSelection[row.id];
      if (Object.keys(newSelection).length === 0) {
        hasAnySelected = false;
      }
      setSelectedRows(newSelection);
    }

    setShowMultiSelectSnackbar(hasAnySelected);
  }

  function isSelected(row: TransformedCollectOverview) {
    return selectedRows.hasOwnProperty(row.id);
  }

  function selectAll(
    event: React.ChangeEvent<HTMLInputElement>,
    rows: TransformedCollectOverview[]
  ) {
    const newSelection: Record<string, TransformedCollectOverview> = {};

    if (event.target.checked) {
      rows.forEach(row => {
        newSelection[row.id] = row;
      });
      setShowMultiSelectSnackbar(true);
    } else {
      setShowMultiSelectSnackbar(false);
    }
    setSelectedRows(newSelection);
  }

  function resetSelection() {
    setSelectedRows({});
  }

  function selectedRowsCount() {
    return Object.keys(selectedRows).length;
  }

  return {
    selectedRows,
    singleSelection,
    setSelectedRows,
    setSingleSelection,
    hasSelected,
    dispatchMutli,
    setDispatchMutli,
    toggleSelect,
    isSelected,
    selectAll,
    resetSelection,
    selectedRowsCount,
    showMultiSelectSnackbar,
    setShowMultiSelectSnackbar,
  };
};

export default useCollectSelection;
