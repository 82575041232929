import React from 'react';
import { useMessages, useEnhancedMutation } from 'src/modules/common/application';
import { useTranslation } from 'react-i18next';
import Button from 'src/modules/common/components/Button/Button';
import { SYNC_YEARS } from 'src/services/graphql/mutations';

const SyncYears = () => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { t } = useTranslation();

  const [syncYears, { loading }] = useEnhancedMutation(SYNC_YEARS, {
    onCompleted: () => setSuccessMessage(t('messages.calculation.success')),
    onError: () => setErrorMessage(t('messages.calculation.error')),
  });

  const handleClick = () => {
    syncYears();
  };

  return (
    <Button
      sx={{
        width: '100%',
        mt: 2,
      }}
      onClick={handleClick}
      loading={loading}
    >
      Sync Years
    </Button>
  );
};

export default SyncYears;
