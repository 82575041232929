import { useTranslation } from 'react-i18next';

import { REMINDER } from 'src/services/graphql/queries/collect';
import { CollectReminder, ManualReminderInput } from 'src/modules/common/types';
import { SEND_REMINDER } from 'src/services/graphql/mutations/collect';
import { TransformedCollectOverview } from 'src/modules/common/types/collect';
import { useEnhancedMutation, useEnhancedQuery, useMessages } from 'src/modules/common/application';

const useReminder = (
  collectOverviewId: string | undefined,
  selectedRows: Record<string, TransformedCollectOverview>,
  singleSelection: TransformedCollectOverview | null,
  dispatchMutli: boolean,
  recipientId: string | undefined
) => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { t } = useTranslation();

  const { loading, data, error } = useEnhancedQuery<{ reminder: CollectReminder[] }>(REMINDER, {
    variables: {
      input: {
        collectOverviewId,
        recipientId,
      },
    },
    skip: !collectOverviewId || !recipientId,
  });

  const [sendReminder, { loading: loadingSendReminder }] = useEnhancedMutation(SEND_REMINDER, {
    onCompleted: () => setSuccessMessage(t('messages.sendReminder.success')),
    onError: () => setErrorMessage(t('messages.sendReminder.error')),
  });

  async function handleSendReminder(message: string) {
    let collectOverviewIds: string[] = [];
    if (dispatchMutli) {
      for (const [, value] of Object.entries(selectedRows)) {
        if (value.assignedTo?.id) collectOverviewIds.push(value.id);
      }
    } else if (singleSelection) {
      collectOverviewIds = [singleSelection.id];
    }

    if (collectOverviewIds.length === 0) return;

    await sendReminder({
      variables: {
        input: { collectOverviewIds, message } as ManualReminderInput,
      },
    });
  }

  return {
    loading,
    data:
      data && data.reminder
        ? JSON.parse(JSON.stringify(data.reminder)).sort((a: CollectReminder, b: CollectReminder) =>
            a.executionDate > b.executionDate ? 1 : -1
          )
        : undefined,
    error,
    handleSendReminder,
    loadingSendReminder,
  };
};

export default useReminder;
