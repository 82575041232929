import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole, RewriteFrames } from '@sentry/integrations';

export { default, default as useErrorBoundary } from './use-error-boundary';

global.__rootdir__ = __dirname || process.cwd();

export const initSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    tunnel: process.env.REACT_APP_PUBLIC_SENTRY_HOST,
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 1.0,
    integrations: [
      new BrowserTracing({
        beforeNavigate: context => ({
          ...context,
          name: window.location.pathname,
        }),
      }),
      new CaptureConsole({
        levels: ['error'],
      }),
      new RewriteFrames({
        root: global.__rootdir__,
      }),
    ],
  });
};

export const captureException = (error: Error | ApolloError) => {
  Sentry.captureException(error);
};

export const captureMessage = (message: string) => {
  Sentry.captureMessage(message);
};
