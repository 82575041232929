import { Theme } from '@mui/material';

export const getInputLabelStyles = (theme: Theme) => ({
  transform: `${theme.custom.inputLabels} !important`,
  fontSize: '0.875rem !important',
  color: 'colors.lightGrey',
});

export const getMenuItemStyles = () => ({
  fontSize: '16px !important',
});

export const getErrorLabelStyles = (theme: Theme) => ({
  color: theme.palette.error.main,
});
