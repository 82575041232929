import { gql } from '@apollo/client';
import { REQUESTED_ESG_VALUE_BY_OWNER_FRAGMENT } from '../fragments';

export const UPDATE_ESG_VALUES_KPI_FACILITY = gql`
  mutation UpdateEsgValuesByKpiFacility($input: [FacilityEsgValueInput]!) {
    updateEsgValuesByKpiFacility(input: $input) {
      id
      facilityId
      categoryId
      year
      assignedToId
      assignedById
      progress
      requestedProgress
      dueDate
      status
    }
  }
`;

export const UPDATE_REQUESTED_ESG_VALUES = gql`
  mutation UpdateRequestedEsgValues($input: [UpdateRequstedEsgValueInput]!) {
    updateRequestedEsgValues(input: $input)
  }
`;

export const RESEND_SHAREABLE_LINK = gql`
  mutation ReSendShareableLink($email: String!) {
    resendShareableLink(email: $email)
  }
`;

export const UPDATE_REQUESTED_ESG_VALUE_APPROVAL_STATUS = gql`
  mutation UpdateRequestedEsgValueApprovalStatus($input: UpdatedRequestedEsgValuesByOwnerInput!) {
    updateRequestedEsgValueApprovalStatus(input: $input) {
      ...requestedEsgValuesByOwner
    }
  }
  ${REQUESTED_ESG_VALUE_BY_OWNER_FRAGMENT}
`;

export const UPDATE_KPI_TYPE = gql`
  mutation UpdateKpiType {
    updateKpiType
  }
`;

export const CREATE_EXTERNAL_USER = gql`
  mutation CreateExternalUser($input: CreateExternalUserInput!) {
    createExternalUser(input: $input) {
      id
      firstName
      lastName
      email
      role
      position
      department
      status
      language
      numberFormat
      fullName @client
      isAdmin @client
    }
  }
`;
