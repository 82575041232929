import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ApexOptions } from 'apexcharts';

import theme from 'src/styles/theme';
import { FacilityIndirectConsumption } from 'src/modules/common/types';
import EnhancedChart from 'src/modules/common/components/EnhancedChart';

interface IEmissionPerFacilityProps {
  facilityIndirectConsumption: FacilityIndirectConsumption[];
}

const EmissionPerFacility = ({ facilityIndirectConsumption }: IEmissionPerFacilityProps) => {
  const { t } = useTranslation();

  const names: string[] = [];
  const gasConsumption: number[] = [];
  const eneryConsumption: number[] = [];

  for (const item of facilityIndirectConsumption) {
    names.push(item.Facility.name);
    gasConsumption.push(item.Gas);
    eneryConsumption.push(item.Energy);
  }

  const chartOptions: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: [theme.palette.colors.cascade, theme.palette.colors.pearlAqua],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '35%',
      },
    },
    stroke: {
      colors: ['transparent'],
      width: 2,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return val + 't CO2';
        },
      },
    },
    xaxis: {
      categories: names,
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
    },
  };

  const chartSeries = [
    {
      name: t('modules.analysis.emissionPerFacility.heatingConsumption'),
      data: gasConsumption,
    },
    {
      name: t('modules.analysis.emissionPerFacility.powerConsumption'),
      data: eneryConsumption,
    },
  ];

  return (
    <EnhancedChart
      cardProps={{
        title: t('modules.analysis.emissionPerFacility.title'),
        infoText: t('modules.analysis.emissionPerFacility.info'),
      }}
      chartProps={{
        height: '90%',
        options: chartOptions,
        series: chartSeries,
        type: 'bar',
      }}
      noData={facilityIndirectConsumption.length === 0}
    />
  );
};

export default EmissionPerFacility;
