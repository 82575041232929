export const getEsgDataHeadersAdmin: string[] = [
  '',
  'modules.dataManagement.esgDataOwner.categoryName',
  'modules.dataManagement.esgDataOwner.esgArea',
  'modules.dataManagement.esgDataOwner.owner',
  'modules.dataManagement.esgDataOwner.progress',
];

export const getCategoryHeadersAdmin: string[] = [
  '',
  'modules.dataManagement.facilitiesDataManagement.name',
  'modules.dataManagement.facilitiesDataManagement.facilityType',
  'modules.dataManagement.esgDataOwner.owner',
  'modules.dataManagement.esgDataOwner.progress',
];

export const getCategoryHeadersNonAdmin: string[] = [
  '',
  'modules.dataManagement.facilitiesDataManagement.name',
  'modules.dataManagement.facilitiesDataManagement.facilityType',
  'modules.dataManagement.esgDataOwner.progress',
];

export const getManageTeamsHeaders: string[] = [
  'shared.types.user.name',
  'shared.types.user.email',
  'modules.settings.manageTeam.userRole',
  'modules.settings.manageTeam.language',
  'modules.settings.manageTeam.status',
];

export const getRelevantDataHeaders: string[] = [
  'modules.reporting.additionalInfoSidebar.data',
  'modules.reporting.additionalInfoSidebar.value',
  'modules.reporting.additionalInfoSidebar.unit',
];

export const getManageFacilityHeaders: string[] = [
  'modules.dataManagement.facilitiesDataManagement.name',
  'modules.dataManagement.facilitiesDataManagement.city',
  'modules.dataManagement.facilitiesDataManagement.country',
  'modules.dataManagement.facilitiesDataManagement.facilityType',
];

export const getFacilityTableHeaders: string[] = [
  '',
  'modules.dataManagement.userDataRequest.category',
  'modules.dataManagement.userDataRequest.esgArea',
  'modules.dataManagement.userDataRequest.progress',
];
