import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import theme from 'src/styles/theme';
import { Chip } from 'src/modules/common/components';
import PushPinIcon from '@mui/icons-material/PushPin';
import CancelIcon from '@mui/icons-material/Cancel';

interface IChatbotHeaderProps {
  isFloating?: boolean;
  handlePinClick: () => void;
  handleOnClose: () => void;
}

const ChatbotHeader = ({ isFloating, handlePinClick, handleOnClose }: IChatbotHeaderProps) => {
  const containerStyle = useMemo(
    () =>
      isFloating
        ? {
            px: 3,
            py: 2,
            background: '#fff',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }
        : {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
    [isFloating]
  );

  const poushPinIconStyle = useMemo(
    () =>
      isFloating
        ? { color: 'action.active', fontSize: '18px', cursor: 'pointer' }
        : {
            color: 'action.active',
            fontSize: '18px',
            cursor: 'pointer',
            transform: 'rotate(-45deg)',
          },
    [isFloating]
  );

  return (
    <Box sx={containerStyle}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <Typography fontWeight={600} fontSize={'1.2rem !important'}>
          Mavue AI Chat
        </Typography>
        <Chip
          sx={{
            ml: 1,
            background: '#4356ED33',
            color: theme.palette.primary.main,
          }}
        >
          Beta
        </Chip>
      </Box>
      <Box>
        <PushPinIcon onClick={handlePinClick} sx={poushPinIconStyle} />
        <CancelIcon
          onClick={handleOnClose}
          sx={{ color: 'action.active', fontSize: '18px', ml: 1, cursor: 'pointer' }}
        />
      </Box>
    </Box>
  );
};

export default ChatbotHeader;
