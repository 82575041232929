import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useMessages, useYears } from 'src/modules/common/application';
import { Loader } from 'src/modules/common/components';
import EditCompanyInfo from 'src/modules/company-onboarding/components/EditCompanyInfo';
import OnboardingLayout from 'src/modules/common/components/Structure/OnboardingLayout';
import { UpdateCompanyInput } from 'src/modules/common/types';
import useCompany from 'src/modules/common/application/use-company';
import { createStartYears } from 'src/modules/common/application/year';

const CompanyInfo = () => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();
  const navigate = useNavigate();

  const { company, loading, error, handleSubmitCompany } = useCompany();
  const { updateYears, updateYearsLoading } = useYears('cache-first', false);

  const onSubmit = async (data: UpdateCompanyInput & { defaultYear: number }) => {
    await handleSubmitCompany({
      dashboardUrl: data.dashboardUrl,
      employeesNum: data.employeesNum,
      headquarter: data.headquarter,
      name: data.name,
      subIndustryId: data.subIndustryId,
    });
    const startYear = !data.defaultYear ? 2023 : data.defaultYear;
    await updateYears({
      variables: { years: createStartYears(startYear) },
    });
    navigate('/add-facility');
  };

  const onBack = () => {
    navigate('/user-info');
  };

  if (loading || !company) return <Loader />;
  if (error) return <>{setErrorMessage(t('messages.getUserInfo.error'))}</>;

  return (
    <OnboardingLayout height="content-fit">
      <EditCompanyInfo
        data={company}
        onInformationSubmit={onSubmit}
        onBack={onBack}
        loading={updateYearsLoading}
      />
    </OnboardingLayout>
  );
};

export default CompanyInfo;
