import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CollectOverviewStatus } from 'src/modules/common/types';

import { IntercomDataAttribute } from 'src/modules/common/types/intercom';
import { getIntercomDataAttribute } from 'src/modules/common/application/intercom';

import { DataOverviewStatsCard } from '../DataOverviewStatsCard/DataStatsCard';

interface Props {
  data: Record<CollectOverviewStatus, number>;
}

export const DataOverviewComponent = ({ data }: Props) => {
  const { t } = useTranslation();

  const statsData = useMemo(() => {
    const dataArray = [
      {
        type: CollectOverviewStatus.OVERDUE,
        title: t('shared.types.collectOverviewStatus.overdue'),
        value: data[CollectOverviewStatus.OVERDUE],
      },
      {
        type: CollectOverviewStatus.NOT_STARTED,
        title: t('shared.types.collectOverviewStatus.notStarted'),
        value: data[CollectOverviewStatus.NOT_STARTED],
      },
      {
        type: CollectOverviewStatus.IN_PROGRESS,
        title: t('shared.types.collectOverviewStatus.inProgress'),
        value: data[CollectOverviewStatus.IN_PROGRESS],
      },
      {
        type: CollectOverviewStatus.COMPLETED,
        title: t('shared.types.collectOverviewStatus.completed'),
        value: data[CollectOverviewStatus.COMPLETED],
      },
    ];

    return dataArray;
  }, [data, t]);

  return (
    <Grid
      container
      spacing={4}
      sx={{
        alignItems: 'stretch',
        marginLeft: 0,
        marginTop: 0,
      }}
      data-intercom-target={getIntercomDataAttribute(
        IntercomDataAttribute.collectStatusOverviewContainer
      )}
    >
      {statsData.map((item, key) => (
        <Grid
          item
          key={key}
          md={3}
          sm={6}
          xs={12}
          sx={{
            paddingLeft: '0 !important',
            paddingTop: '0 !important',
            paddingRight: 4,
            width: '100%',
          }}
        >
          <DataOverviewStatsCard title={item.title} value={item.value} type={item.type} />
        </Grid>
      ))}
    </Grid>
  );
};
