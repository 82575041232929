import { CollectOverviewStatus, EsgCategoryEnum, User } from 'src/modules/common/types';

export type CollectFilterItem = {
  key: string;
  label: string;
};

export enum FilterType {
  STATUS = 'status',
  FACILITY = 'facility',
  CATEGORY = 'category',
  ASSIGNED_TO = 'assignedTo',
  RESET = 'reset',
}

export type Order = 'asc' | 'desc';

export interface TransformedCollectOverview {
  id: string;
  facilityName: string;
  category: EsgCategoryEnum;
  year: number;
  assignedTo: User | undefined;
  progress: number;
  requestedProgress: number | undefined;
  dueDate: Date | undefined;
  status: CollectOverviewStatus;
  facilityId: string;
  categoryId: string;
  assignedToId: string | undefined;
}

export enum MultiSelectSnackbarActionTypes {
  ASSIGN_DATA_OWNER,
  CHANGE_DUE_DATE,
  SEND_REMINDER,
}
