import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Box, SvgIcon, Typography } from '@mui/material';

import theme from 'src/styles/theme';

import { IRoadmapStep } from '../../types';
import { useNavigate } from 'react-router-dom';

interface IRoadmapStepCard {
  data: IRoadmapStep;
  isActive: boolean;
  isCompleted: boolean;
}

const RoadmapStepCard = (props: IRoadmapStepCard) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, isActive, isCompleted } = props;

  const { primaryColor, secondaryColor, borderColor } = useMemo(() => {
    let primaryColor = '#D9D6D633';
    let secondaryColor = theme.palette.colors.lightGrey;
    let borderColor = theme.palette.background.grey;
    if (isActive) {
      primaryColor = theme.palette.primary.main;
      secondaryColor = theme.palette.colors.white;
      borderColor = theme.palette.primary.main;
    }
    if (isCompleted) {
      primaryColor = theme.palette.colors.successGreen;
      secondaryColor = theme.palette.colors.white;
    }
    return {
      primaryColor,
      secondaryColor,
      borderColor,
    };
  }, [isActive, isCompleted]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        mt: 2,
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '95%',
            lg: '95%',
          },
          maxWidth: '390px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            background: primaryColor,
            py: 1,
            px: 3,
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '10px',
            border: '1px solid',
            borderColor: borderColor,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <SvgIcon
              component={data?.Icon}
              inheritViewBox
              sx={{
                fontSize: 48,
                marginRight: 1,
                justifyContent: 'center',
                fill: secondaryColor,
              }}
            />
            <Typography fontSize={'1rem !important'} fontWeight={600} color={secondaryColor}>
              {t(data.title)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography fontSize={'1rem !important'} fontWeight={600} color={secondaryColor}>
              {data.progress}%
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            background: '#fff',
            borderRadius: '10px',
            borderColor: `${theme.palette.background.grey} !important`,
            borderStyle: 'solid',
            borderWidth: 1,
            py: '20px',
            px: '30px',
            minHeight: '264px',
          }}
        >
          {data?.tasks?.map((item: any, key: number) => {
            return (
              <Box
                key={key}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  marginBottom: 1,
                }}
              >
                <Box
                  sx={{
                    height: '26px',
                    width: '26px',
                    borderRadius: '50%',
                    background: theme.palette.background.default,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 1,
                  }}
                >
                  <Typography
                    fontSize={'0.875rem !important'}
                    fontWeight={600}
                    color={theme.palette.colors.lightGrey}
                  >
                    {key + 1}
                  </Typography>
                </Box>

                <Trans
                  i18nKey={item}
                  components={{
                    parent: (
                      <Typography
                        fontSize={'0.875rem !important'}
                        fontWeight={500}
                        color={theme.palette.colors.lightGrey}
                        width={'calc(100% - 30px)'}
                      />
                    ),
                    children: (
                      <span
                        onClick={e => {
                          e.stopPropagation();
                          navigate('/analysis');
                        }}
                        style={{
                          fontSize: '0.875rem !important',
                          textDecoration: 'underline',
                          fontWeight: 600,
                          cursor: 'pointer',
                        }}
                      />
                    ),
                  }}
                />
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            background: primaryColor,
            py: 2,
            px: 3,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px',
            mt: 1,
            cursor: 'pointer',
            border: '1px solid',
            borderColor: borderColor,
          }}
          onClick={() => {
            navigate(data.to);
          }}
        >
          <Typography
            sx={{
              fontSize: '0.9375rem !important',
              fontWeight: 500,
              color: secondaryColor,
            }}
          >
            {t(data.ctaLabel)}
          </Typography>
          <ArrowForwardIosRoundedIcon
            style={{
              height: '24px',
              widows: '24px',
              fill: secondaryColor,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RoadmapStepCard;
