import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from '@mui/material';

import theme from 'src/styles/theme';
import { Chip } from 'src/modules/common/components';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import { getRequestStatusChipColor } from 'src/modules/common/application/ui';

import { MaterialityType, StakeholderStatusResult } from '../../types/materialEvaluation';
import MaterialityEvaluationCard from '../MaterialityEvaluationCard/MaterialityEvaluationCard';

interface Props {
  results: StakeholderStatusResult[];
  type: MaterialityType;
  subHeader: string;
}

const StakeholderEvaluationContainer = (props: Props) => {
  const { results, type, subHeader } = props;
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        background: '#fff',
        border: '1px solid #D9D6D6',
        borderRadius: '8px',
        p: 4,
        mt: 3,
      }}
    >
      <Typography
        fontSize={'1.25rem !important'}
        fontWeight={600}
        sx={{
          marginBottom: 3,
        }}
      >
        {t('modules.materialityAssessment.materialityEvaluationDetail.stakeholderEvaluation')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <Typography
          fontSize={'1rem !important'}
          fontWeight={500}
          sx={{
            marginBottom: 3,
          }}
          width={'45%'}
        >
          {subHeader}
        </Typography>{' '}
        <Typography
          fontSize={'1rem !important'}
          fontWeight={500}
          sx={{
            marginBottom: 3,
            marginLeft: '15%',
          }}
          width={'405px'}
        >
          {t('modules.materialityAssessment.materialityEvaluationDetail.evaluation')}
        </Typography>
      </Box>
      {results.map((item, index) => {
        const isLastItem = index === results.length - 1;
        return (
          <Fragment key={index}>
            <MaterialityEvaluationCard
              key={index}
              evaluationData={item.response}
              type={type}
              isDisabled
              noteInputLabel={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mb: 0.5,
                  }}
                >
                  <Typography
                    sx={{ color: theme.palette.colors.grey }}
                    fontSize={'0.875rem !important'}
                    fontWeight={500}
                  >
                    {item.stakeholder.email}
                  </Typography>
                  <Chip
                    size="small"
                    styles={{ maxWidth: 'fit-content', marginLeft: 1 }}
                    colorVariant={getRequestStatusChipColor(item.status)}
                  >
                    {t(`shared.types.requestStatus.${removeUnderscoreToCamelCase(item.status)}`)}
                  </Chip>
                </Box>
              }
            />
            {!isLastItem && (
              <Divider
                sx={{
                  mt: results.length > 0 ? 4 : 3,
                  mb: 3,
                }}
              />
            )}
          </Fragment>
        );
      })}
    </Box>
  );
};

export default StakeholderEvaluationContainer;
