import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell, TableRow, Typography } from '@mui/material';

import { TranslatedOpenDataRequest } from 'src/modules/common/types';
import {
  camelCaseString,
  capitalizeFirstLetter,
  insertSpaces,
  removeUnderscoreToCamelCase,
} from 'src/modules/common/application/string';
import { Chip } from 'src/modules/common/components';
import { getStatusChipColor, getChipColorForLetter } from 'src/modules/common/application/ui';

interface Props {
  data: TranslatedOpenDataRequest;
  onRowClick?: (data: any) => void;
}

const StyledTableCell = ({
  children,
}: {
  children: React.ReactNode;
  styles?: React.CSSProperties;
}) => {
  return <TableCell sx={{ borderBottom: 'none' }}>{children}</TableCell>;
};

const OpenDataRequestTableRow = ({ data, onRowClick }: Props) => {
  const { t } = useTranslation();
  const isOnRowClickFnc = typeof onRowClick === 'function';

  return (
    <TableRow
      data-testid="open-data-request-table-row"
      key={data.collectOverviewId}
      sx={{
        fontSize: '0.875rem !important',
        '&:hover': isOnRowClickFnc
          ? theme => ({
              cursor: 'pointer',
              backgroundColor: theme.palette.colors.softGreyTransparent,
            })
          : null,
      }}
      onClick={() => isOnRowClickFnc && onRowClick(data.collectOverviewId)}
    >
      <StyledTableCell styles={{ width: '25%' }}>
        <Typography variant="body1" fontSize="0.875rem !important">
          {t(`shared.types.esgCategory.${removeUnderscoreToCamelCase(data.category.title)}`)}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Chip
          size="small"
          styles={{
            maxWidth: 'fit-content',
            justifyContent: 'flex-start',
          }}
          colorVariant={getChipColorForLetter(data.facility.city)}
        >
          {capitalizeFirstLetter(insertSpaces(camelCaseString(data.facility.city)))}
        </Chip>
      </StyledTableCell>
      <StyledTableCell>
        <Chip
          size="small"
          styles={{
            maxWidth: 'fit-content',
            justifyContent: 'flex-start',
          }}
          colorVariant={getStatusChipColor(data.status)}
        >
          {t(`shared.types.status.${removeUnderscoreToCamelCase(data.status)}`)}
        </Chip>
      </StyledTableCell>
    </TableRow>
  );
};

export default OpenDataRequestTableRow;
