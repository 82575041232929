import React from 'react';
import ReactApexChart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import { Box } from '@mui/system';

import { InfoCard } from 'src/modules/common/components';
import NoDataPlaceholder from 'src/modules/common/components/NoDataPlaceholder/NoDataPlaceholder';

interface IProps {
  children?: React.ReactNode;
  noData?: boolean;
  cardProps: {
    title: string;
    infoText: string;
    showInfoIcon?: boolean;
    isScrollable?: boolean;
  };
  chartProps?: {
    options: ApexOptions;
    series: ApexOptions['series'];
    height: string;
    type:
      | 'area'
      | 'line'
      | 'bar'
      | 'pie'
      | 'donut'
      | 'radialBar'
      | 'scatter'
      | 'bubble'
      | 'heatmap'
      | 'treemap'
      | 'boxPlot'
      | 'candlestick'
      | 'radar'
      | 'polarArea'
      | 'rangeBar'
      | 'histogram'
      | undefined;
  };
}

const EnhancedChart = ({ noData, chartProps, cardProps, children }: IProps) => {
  return (
    <InfoCard {...cardProps}>
      {noData ? (
        <Box sx={{ height: '100%' }} display="flex" justifyContent="center" alignItems="center">
          <NoDataPlaceholder />
        </Box>
      ) : children ? (
        children
      ) : (
        <ReactApexChart {...chartProps} />
      )}
    </InfoCard>
  );
};

export default EnhancedChart;
