import React from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Grid,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { getInputLabelStyles } from 'src/modules/common/application/form-styles';
import { Company, CompanyStatus, EmployeesNum } from 'src/modules/common/types/graphql';
import Button from 'src/modules/common/components/Button/Button';
import { companySchema } from 'src/modules/common/application/company.schema';

interface Props {
  data?: Company;
  handleSubmit: (data: Company) => void;
  handleReset?: () => void;
  editMode?: boolean;
}

const CompanyForm = ({ data, handleSubmit, handleReset, editMode }: Props) => {
  const { t } = useTranslation();
  const initalFormState = data
    ? data
    : ({
        name: '',
        dashboardUrl: '',
        employeesNum: EmployeesNum.N_1_10,
        headquarter: '',
        id: '',
        status: CompanyStatus.COMPANY_CREATED,
      } as Company);
  const fieldStyle = {
    marginBottom: 5,
  };

  return (
    <div>
      <Formik
        initialValues={{
          ...initalFormState,
        }}
        enableReinitialize={true}
        validationSchema={companySchema(t)}
        onSubmit={(values: Company) => {
          handleSubmit(values);
        }}
      >
        {({ submitForm, handleChange, errors, values }) => (
          <Form>
            <Grid container rowSpacing={2} sx={{ mt: 2.5 }}>
              <Grid container item columnSpacing={3} sx={{ paddingTop: 2 }}>
                <Grid item mb={0}>
                  {/* name */}
                  <FormControl fullWidth sx={fieldStyle}>
                    <InputLabel htmlFor={values.name || ''} sx={getInputLabelStyles}>
                      {t('shared.types.company.name')}
                    </InputLabel>
                    <TextField
                      id="name"
                      name="name"
                      type="text"
                      value={values.name ? values.name : ''}
                      onChange={handleChange}
                      fullWidth
                      placeholder={t('shared.types.user.firstName')}
                    />
                    {errors.name && <FormHelperText>{errors.name}</FormHelperText>}
                  </FormControl>

                  {/* headquarterLocation */}
                  <FormControl fullWidth sx={fieldStyle}>
                    <InputLabel htmlFor={values.headquarter || ''} sx={getInputLabelStyles}>
                      {t('shared.types.company.headquarterLocation')}
                    </InputLabel>
                    <TextField
                      id="headquarter"
                      name="headquarter"
                      type="text"
                      value={values.headquarter ? values.headquarter : ''}
                      onChange={handleChange}
                      fullWidth
                      placeholder={t('shared.types.company.headquarterLocation')}
                    />
                    {errors.headquarter && <FormHelperText>{errors.headquarter}</FormHelperText>}
                  </FormControl>

                  {/* employeesNum */}
                  <FormControl fullWidth sx={fieldStyle}>
                    <InputLabel htmlFor={values.employeesNum || ''} sx={getInputLabelStyles}>
                      {t('shared.types.company.numberOfEmployees')}
                    </InputLabel>
                    <Select
                      fullWidth
                      name="employeesNum"
                      id="employeesNum"
                      value={values.employeesNum ? values.employeesNum : ''}
                      onChange={handleChange}
                      displayEmpty
                    >
                      {Object.values(EmployeesNum).map(num => (
                        <MenuItem key={num} value={num}>
                          {num}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.employeesNum && <FormHelperText>{errors.employeesNum}</FormHelperText>}
                  </FormControl>

                  {/* dashboardUrl */}
                  <FormControl fullWidth sx={fieldStyle}>
                    <InputLabel htmlFor={values.dashboardUrl || ''} sx={getInputLabelStyles}>
                      {t('shared.types.company.dashboardUrl')}
                    </InputLabel>
                    <TextField
                      id="dashboardUrl"
                      name="dashboardUrl"
                      type="text"
                      value={values.dashboardUrl ? values.dashboardUrl : ''}
                      onChange={handleChange}
                      fullWidth
                      placeholder={t('shared.types.company.dashboardUrl')}
                    />
                    {errors.dashboardUrl && <FormHelperText>{errors.dashboardUrl}</FormHelperText>}
                  </FormControl>

                  {/* status */}
                  <FormControl fullWidth sx={fieldStyle}>
                    <InputLabel htmlFor={values.status || ''} sx={getInputLabelStyles}>
                      {t('shared.types.company.status')}
                    </InputLabel>
                    <Select
                      fullWidth
                      name="status"
                      id="status"
                      value={values.status ? values.status : ''}
                      onChange={handleChange}
                      displayEmpty
                    >
                      {Object.values(CompanyStatus).map(status => (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.status && <FormHelperText>{errors.status}</FormHelperText>}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                item
                sx={{
                  mt: 0,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Button onClick={submitForm}>
                  {editMode ? t('modules.super.update') : t('modules.super.saveChanges')}
                </Button>
                <Button onClick={handleReset} color="secondary">
                  {t('modules.super.reset')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompanyForm;
