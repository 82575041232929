import React from 'react';
import { Box, Typography, Divider, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface Props {
  title?: string;
  subtitle?: string;
  showDivider?: boolean;
  showLightTitle?: boolean;
  tooltipText?: string;
}

const PageTitle = ({ title, subtitle, showDivider, showLightTitle, tooltipText }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
        justifyContent: 'space-between',
        position: 'relative',
        mt: '8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
        }}
      >
        <Typography
          variant="h4"
          data-cy="page-title"
          sx={{ fontWeight: showLightTitle ? 400 : 600 }}
        >
          {title}
        </Typography>
        {tooltipText && tooltipText.length > 0 && (
          <Tooltip title={tooltipText}>
            <InfoOutlinedIcon
              fontSize="inherit"
              sx={{ color: theme.palette.text.secondary, cursor: 'pointer', ml: 0.5, mt: -1 }}
            />
          </Tooltip>
        )}
      </Box>

      {subtitle && (
        <Typography variant="body1" sx={{ pt: theme.spacing(1), color: 'text.grey' }}>
          {subtitle}
        </Typography>
      )}
      {showDivider && (
        <Divider
          light
          sx={{ color: 'primary', width: '100%', height: '2px', py: theme.spacing(1) }}
        />
      )}
    </Box>
  );
};
export default PageTitle;
