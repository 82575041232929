import React from 'react';
import { Box, LinearProgress, SxProps, Typography } from '@mui/material';
import { getProgressBarColor } from 'src/modules/common/application/ui';

const LinearProgressBar = ({
  progress,
  label,
  textStyle,
}: {
  progress: number;
  label?: string;
  textStyle?: SxProps;
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      flexGrow: 1,
      '& > span': {
        flexGrow: 1,
      },
    }}
  >
    <LinearProgress color={getProgressBarColor(progress)} value={progress} variant="determinate" />
    <Typography
      variant="body1"
      ml={1}
      fontSize="0.875rem !important"
      fontWeight={400}
      sx={{ ...textStyle }}
    >
      {label ? label : progress + '%'}
    </Typography>
  </Box>
);

export default LinearProgressBar;
