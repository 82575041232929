import React from 'react';
import { Chip as MaterialChip, ChipProps as MaterialChipProps } from '@mui/material';
import { ChipColorVariants, ChipColorVariantsMap } from 'src/modules/common/types';

export interface ChipProps extends Omit<MaterialChipProps, 'children'> {
  children: React.ReactNode;
  styles?: React.CSSProperties;
  labelStyles?: React.CSSProperties;
  colorVariant?: ChipColorVariants;
}

const Chip = (props: ChipProps) => {
  const {
    children,
    styles = {},
    labelStyles = {},
    colorVariant = ChipColorVariants.LIGHT_GREEN,
    ...rest
  } = props;
  const chipContainerStyles = ChipColorVariantsMap[colorVariant];
  return (
    <MaterialChip
      sx={{
        ...chipContainerStyles,
        display: 'flex',
        alignItems: 'center',
        padding: '8px 16px',
        ...styles,
        '& .MuiChip-label': {
          fontSize: '0.875rem',
          ...labelStyles,
        },
      }}
      label={children}
      {...rest}
    />
  );
};

export default Chip;
