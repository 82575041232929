import React, { useState } from 'react';
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material';

import { TableRow, IconButton, Menu, MenuItem, TableCell } from '@mui/material';
import { capitalizeFirstLetter } from 'src/modules/common/application/string';
import theme from 'src/styles/theme';

// NOTE: needs to find better way to pass the type on runtime
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataTypesAllowed = any;

interface MoreOption {
  action: (data: DataTypesAllowed) => void;
  text: string;
}
interface Props {
  RowComponent: React.FC<{ data: DataTypesAllowed }>;
  data: DataTypesAllowed;
  moreOptions: MoreOption[];
  showMoreOptions?: boolean;
  onRowClick?: (data: any) => void;
}

const AdminSettingsTableRow = ({
  data,
  moreOptions,
  showMoreOptions = true,
  RowComponent: TableRowDataComponent,
  onRowClick,
}: Props) => {
  const isOnRowClickFnc = typeof onRowClick === 'function';
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleActionClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    if (onRowClick) onRowClick(data);
  };

  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const handleMoreOptionsClick = (e: React.MouseEvent, moreOption: MoreOption) => {
    e.preventDefault();
    handleActionClose();
    typeof moreOption.action && moreOption.action(data);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <TableRow
      sx={{
        backgroundColor: theme.palette.colors.white,
        '&:hover': isOnRowClickFnc
          ? theme => ({
              cursor: 'pointer',
              background: theme.palette.colors.softGrey,
            })
          : null,
      }}
      onClick={() => isOnRowClickFnc && onRowClick(data)}
    >
      <TableRowDataComponent data={data} />
      {showMoreOptions && (
        <TableCell
          sx={{
            borderBottom: 'none',
            backgroundColor: theme.palette.colors.white,
            padding: 0,
            fontSize: '0.875rem !important',
          }}
        >
          <IconButton onClick={handleActionClick} aria-describedby={id}>
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleActionClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {moreOptions.map(mOption => (
              <MenuItem
                key={mOption.text}
                onClick={(e: React.MouseEvent) => handleMoreOptionsClick(e, mOption)}
              >
                {capitalizeFirstLetter(mOption.text)}
              </MenuItem>
            ))}
          </Menu>
        </TableCell>
      )}
    </TableRow>
  );
};

export default AdminSettingsTableRow;
