import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useTheme } from '@mui/material/styles';
import { getLanguageCode } from 'src/modules/common/application/languageCodeFormater';

const LanguageSelect = () => {
  const { i18n } = useTranslation();
  const theme = useTheme();

  const browserLang: string = getLanguageCode(i18n.language);

  return (
    <Box sx={{ width: 500, display: 'flex', justifyContent: 'flex-start' }}>
      <LanguageIcon
        fontSize="small"
        sx={{ color: theme.palette.colors.lightGrey, mt: 0.75, mr: 0.5 }}
      />

      <Select
        size="small"
        variant="standard"
        disableUnderline
        style={{ backgroundColor: 'inherit' }}
        value={browserLang}
        sx={{ width: 100, textAlign: 'left' }}
      >
        <MenuItem value="en" onClick={() => i18n.changeLanguage('en')} disableRipple sx={{}}>
          <Typography sx={{ color: theme.palette.colors.lightGrey }}>English</Typography>
        </MenuItem>
        <MenuItem value="de" onClick={() => i18n.changeLanguage('de')}>
          <Typography sx={{ color: theme.palette.colors.lightGrey }}>Deutsch</Typography>
        </MenuItem>
      </Select>
    </Box>
  );
};

export default LanguageSelect;
