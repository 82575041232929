import React from 'react';
import { useTheme } from '@mui/material';

const AddFacility = (props: any) => {
  const theme = useTheme();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.9438 12.2718V23.5537H12.7339C12.623 23.7023 12.5119 23.851 12.3978 23.9994H20.1687C20.2917 23.9994 20.3937 23.8985 20.3937 23.7766V12.2717C20.3246 12.3103 20.2497 12.3341 20.1687 12.3341C20.0877 12.3341 20.0128 12.3103 19.9437 12.2717L19.9438 12.2718Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M11.2756 23.5542H4.06567V12.2718C3.9966 12.3103 3.92168 12.3342 3.84069 12.3342C3.75969 12.3342 3.68476 12.3103 3.6157 12.2718V23.7771C3.6157 23.899 3.71762 24 3.84069 24H11.6115C11.4975 23.8514 11.3834 23.7027 11.2754 23.5543L11.2756 23.5542Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M20.1689 2.12584e-05C18.0507 2.12584e-05 16.3314 1.70316 16.3314 3.80144C16.3314 3.81637 16.3314 3.93226 16.3464 4.13128C16.3494 4.16694 16.3525 4.20861 16.3554 4.24718C16.3554 4.25319 16.3584 4.25899 16.3584 4.26501C16.4724 5.48942 17.0664 7.86426 20.1657 11.8885C23.265 7.86426 23.8592 5.48942 23.973 4.26501C23.973 4.25899 23.9759 4.25319 23.9759 4.24718C23.9789 4.20861 23.982 4.16694 23.9849 4.13128C24 3.92915 24 3.81616 24 3.80434C24 1.70293 22.2807 0 20.1625 0L20.1689 2.12584e-05ZM20.1689 6.09609C18.9688 6.09609 17.9935 5.1302 17.9935 3.94118C17.9935 2.75243 18.9686 1.78628 20.1689 1.78628C21.3689 1.78628 22.3442 2.75217 22.3442 3.94118C22.3442 5.12993 21.3691 6.09609 20.1689 6.09609Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M20.1689 2.23206C19.2177 2.23206 18.4437 2.99892 18.4437 3.94098C18.4437 4.88326 19.2179 5.64991 20.1689 5.64991C21.1201 5.64991 21.894 4.88305 21.894 3.94098C21.894 2.99871 21.1199 2.23206 20.1689 2.23206Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M3.84064 11.8886C6.93998 7.8643 7.53415 5.48946 7.64795 4.26504C7.64795 4.25903 7.65088 4.25323 7.65088 4.24721C7.65381 4.20865 7.65695 4.16698 7.65988 4.13132C7.67495 3.92918 7.67495 3.8162 7.67495 3.80438C7.67495 1.70297 5.95565 3.88725e-05 3.83748 3.88725e-05C1.7193 3.88725e-05 0 1.70318 0 3.80146C0 3.81638 0 3.93227 0.0150686 4.1313C0.0179987 4.16696 0.0211378 4.20863 0.0240679 4.24719C0.0240679 4.25321 0.0269979 4.25901 0.0269979 4.26502C0.141059 5.48944 0.735022 7.86428 3.83431 11.8886L3.84064 11.8886ZM1.6653 3.94129C1.6653 2.75254 2.64035 1.78639 3.84064 1.78639C5.04067 1.78639 6.01599 2.75228 6.01599 3.94129C6.01599 5.13004 5.04093 6.0962 3.84064 6.0962C2.64062 6.0962 1.6653 5.13031 1.6653 3.94129Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M3.84065 5.65028C4.79186 5.65028 5.56578 4.88342 5.56578 3.94135C5.56578 2.99908 4.79165 2.23243 3.84065 2.23243C2.88944 2.23243 2.11551 2.99929 2.11551 3.94135C2.11551 4.88363 2.88965 5.65028 3.84065 5.65028Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M12.0048 23.7771C15.1041 19.7529 15.6983 17.378 15.8121 16.1536C15.8121 16.1476 15.815 16.1418 15.815 16.1358C15.8179 16.0972 15.8211 16.0555 15.824 16.0199C15.8391 15.8178 15.8391 15.7048 15.8391 15.6929C15.8391 13.5915 14.1198 11.8886 12.0016 11.8886C9.88341 11.8886 8.16411 13.5917 8.16411 15.69C8.16411 15.705 8.16411 15.8208 8.17918 16.0199C8.18211 16.0555 8.18525 16.0972 8.18818 16.1358C8.18818 16.1418 8.19111 16.1476 8.19111 16.1536C8.30517 17.378 8.89913 19.7528 11.9984 23.7771L12.0048 23.7771ZM9.82941 15.8299C9.82941 14.6411 10.8045 13.675 12.0048 13.675C13.2048 13.675 14.1801 14.6408 14.1801 15.8299C14.1801 17.0186 13.205 17.9848 12.0048 17.9848C10.8047 17.9848 9.82941 17.0189 9.82941 15.8299Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M13.7299 15.8298C13.7299 16.7736 12.9577 17.5388 12.0048 17.5388C11.0519 17.5388 10.2797 16.7736 10.2797 15.8298C10.2797 14.8859 11.0519 14.1207 12.0048 14.1207C12.9577 14.1207 13.7299 14.8859 13.7299 15.8298Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};

export default AddFacility;
