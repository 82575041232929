import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

import theme from 'src/styles/theme';
import Button from 'src/modules/common/components/Button/Button';
import { UserAvatar, CompanyInfo, AddFacility } from 'src/modules/common/components/icons';
import CardWithIcon from './CardWithIcon';

const CompanyOnboardingStart = ({ onNext }: { onNext: () => void }) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      rowSpacing={0.5}
      sx={{
        pt: 6,
        pl: 4,
        pr: 4,
        pb: 4,
        border: theme.custom.border,
        borderRadius: 2.5,
        bgcolor: theme.palette.colors.white,
        width: 500,
        height: 658,
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="inherit"
          pb={1}
          sx={{ lineHeight: '29px', fontWeight: 600, fontSize: '24px' }}
        >
          {t('modules.companyOnboarding.start.title')}
        </Typography>
        <Typography variant="inherit" sx={{ color: theme.palette.colors.lightGrey, fontSize: 16 }}>
          {t('modules.companyOnboarding.start.description')}
        </Typography>
      </Grid>
      <CardWithIcon
        title={t('modules.companyOnboarding.start.user.title')}
        description={t('modules.companyOnboarding.start.user.description')}
        icon={<UserAvatar />}
      />
      <CardWithIcon
        title={t('modules.companyOnboarding.start.company.title')}
        description={t('modules.companyOnboarding.start.company.description')}
        icon={<CompanyInfo />}
      />
      <CardWithIcon
        title={t('modules.companyOnboarding.start.facility.title')}
        description={t('modules.companyOnboarding.start.facility.description')}
        icon={<AddFacility />}
      />
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
        <Button
          role="button"
          aria-label="primary-button"
          sx={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          onClick={onNext}
        >
          {t('modules.companyOnboarding.start.button')}
          <ArrowForward
            sx={{
              color: theme.palette.colors.white,
              pl: 1,
              width: 32,
              height: 32,
            }}
          />
        </Button>
      </Grid>
    </Grid>
  );
};

export default CompanyOnboardingStart;
