import React from 'react';
import { Backdrop, Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useConfig } from 'src/modules/common/application';
import { IpadBannerIcon, PhoneBannerIcon } from 'src/assets';

const MobileWarning = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { minScreenWidth, mobileScreenWidth } = useConfig();
  const matchesMinScreenWidth = useMediaQuery(`(max-width:${mobileScreenWidth}px)`);
  const matchesMidScreenSize = useMediaQuery(`(max-width:${minScreenWidth}px)`);

  return (
    <Backdrop
      open={matchesMinScreenWidth || matchesMidScreenSize}
      sx={{
        backgroundColor: 'white',
        zIndex: 9999,
      }}
    >
      <Container
        sx={{
          position: 'absolute',
          width: '80%',
          height: '100%',
          margin: theme.spacing(2),
          backgroundColor: 'white',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={matchesMidScreenSize && !matchesMinScreenWidth ? IpadBannerIcon : PhoneBannerIcon}
          alt={t('mobileDisclaimer.title')}
          style={{
            maxWidth: matchesMidScreenSize && !matchesMinScreenWidth ? '300px' : '230px',
            width: matchesMidScreenSize && !matchesMinScreenWidth ? '50%' : '60%',
          }}
        />
        <Box pt={6} pb={1}>
          <Typography variant={matchesMidScreenSize && !matchesMinScreenWidth ? 'h1' : 'h2'}>
            {t('mobileDisclaimer.title')}
          </Typography>
        </Box>
        <Box py={1}>
          <Typography
            variant={matchesMidScreenSize && !matchesMinScreenWidth ? 'subtitle1' : 'subtitle2'}
          >
            {t('mobileDisclaimer.text')}
          </Typography>
        </Box>
      </Container>
    </Backdrop>
  );
};

export default MobileWarning;
