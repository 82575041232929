import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import OnboardingStepper from 'src/modules/company-onboarding/components/OnboardingStepper';
import { useUserData } from 'src/modules/common/application';
import AuthLayout from '../AuthLayout/AuthLayout';
import CardLayout from '../CardLayout/CardLayout';

interface Props {
  children: ReactNode;
  height: string;
  minHeight?: string;
}

const OnboardingLayout = ({ children, height, minHeight }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { hasCompanyOnboarded } = useUserData();

  const title = location.pathname.includes('user-info')
    ? 'user'
    : location.pathname.includes('company-info')
    ? 'company'
    : 'facility';

  return (
    <AuthLayout title="" showAppLogo={false} showDashboard={true}>
      <CardLayout height={height} minHeight={minHeight}>
        <Typography variant="h5" sx={{ textAlign: 'center', mb: 2 }}>
          {t(`modules.companyOnboarding.${title.toLowerCase()}.title`)}
        </Typography>
        {!hasCompanyOnboarded && (
          <Box sx={{ mb: 2 }}>
            <OnboardingStepper />
          </Box>
        )}
        {children}
      </CardLayout>
    </AuthLayout>
  );
};

export default OnboardingLayout;
