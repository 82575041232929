import { useTranslation } from 'react-i18next';
import {
  MaterialityAssessment,
  MaterialityAssessmentEvaluationRequest,
} from 'src/modules/common/types';

import { useEnhancedMutation, useMessages } from 'src/modules/common/application';
import {
  REQUEST_MATERIALITY_IDENTIFICATION_ASSESSMENT,
  REQUEST_MATERIALITY_ASSESSMENT_EVALUATION_REQUEST,
} from 'src/services/graphql/mutations/material';
import { GET_MATERIALITY_ASSESSMENT } from 'src/services/graphql/queries';

interface UseMaterialityAssessmentRequestProps {
  year: number;
  onComplete?: () => void;
}

const useMaterialityAssessmentRequest = ({
  year,
  onComplete,
}: UseMaterialityAssessmentRequestProps) => {
  const { t } = useTranslation();
  const { setErrorMessage, setSuccessMessage } = useMessages();

  const [requestMaterialityIdentificationAssessment, { loading: identificationLoading }] =
    useEnhancedMutation(REQUEST_MATERIALITY_IDENTIFICATION_ASSESSMENT, {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        onComplete && onComplete();
        setSuccessMessage(t('messages.requestMaterialityIdentificationAssessment.success'));
      },
      onError: () => {
        setErrorMessage(t('messages.requestMaterialityIdentificationAssessment.error'));
      },
      update(cache, { data }) {
        const result = cache.readQuery<{
          materialityAssessment: MaterialityAssessment;
        }>({
          query: GET_MATERIALITY_ASSESSMENT,
          variables: {
            year,
          },
        });

        if (result) {
          const { materialityAssessment } = result;

          const updatedIdentificationRequests = [
            ...materialityAssessment.identificationRequests,
            data.requestMaterialityIdentificationAssessment,
          ];

          cache.writeQuery({
            query: GET_MATERIALITY_ASSESSMENT,
            variables: {
              year,
            },
            data: {
              materialityAssessment: {
                ...materialityAssessment,
                identificationRequests: updatedIdentificationRequests,
              },
            },
          });
        }
      },
    });

  const [requestMaterialityEvaluationAssessment, { loading: evaluationLoading }] =
    useEnhancedMutation<{
      requestMaterialityEvaluationAssessment: MaterialityAssessmentEvaluationRequest;
    }>(REQUEST_MATERIALITY_ASSESSMENT_EVALUATION_REQUEST, {
      onCompleted: () => {
        onComplete && onComplete();
        setSuccessMessage(t('messages.requestMaterialityEvaluationAssessment.success'));
      },
      onError: () => {
        setErrorMessage(t('messages.requestMaterialityEvaluationAssessment.error'));
      },
      update(cache, { data }) {
        const result = cache.readQuery<{
          materialityAssessment: MaterialityAssessment;
        }>({
          query: GET_MATERIALITY_ASSESSMENT,
          variables: {
            year,
          },
        });

        if (result) {
          const { materialityAssessment } = result;

          const updatedEvaluationRequests: MaterialityAssessmentEvaluationRequest[] = JSON.parse(
            JSON.stringify(materialityAssessment.evaluationRequests || [])
          );

          const indexToUpdate = updatedEvaluationRequests.findIndex(
            item => item.id === data?.requestMaterialityEvaluationAssessment?.id
          );

          if (indexToUpdate !== -1) {
            updatedEvaluationRequests[indexToUpdate] =
              data?.requestMaterialityEvaluationAssessment!;
          } else {
            updatedEvaluationRequests.push(data?.requestMaterialityEvaluationAssessment!);
          }

          cache.writeQuery({
            query: GET_MATERIALITY_ASSESSMENT,
            variables: {
              year,
            },
            data: {
              materialityAssessment: {
                ...materialityAssessment,
                evaluationRequests: updatedEvaluationRequests,
              },
            },
          });
        }
      },
    });

  return {
    requestMaterialityIdentificationAssessment,
    requestMaterialityEvaluationAssessment,
    loading: identificationLoading,
    evaluationLoading,
  };
};

export default useMaterialityAssessmentRequest;
