import React, { useState } from 'react';
import {
  Box,
  Dialog,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CreateIcon from '@mui/icons-material/Create';

import Button from 'src/modules/common/components/Button/Button';
import theme from 'src/styles/theme';
import { MAX_CHARACTER_COUNT } from 'src/modules/common/types/constants/assign-data-owner-dialog';
import { AssignDataOwnerDialogOptions } from 'src/modules/common/types/assign-data-owner-dialog';
import { sortUserArray } from 'src/modules/common/application/sort';
import useUser from 'src/modules/common/application/use-user';
import { getNameById } from 'src/modules/common/application/user';

import { textFieldInputStyle } from './styles';
import Avatar from 'src/modules/common/components/Avatar/Avatar';

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onSave: (notify: boolean, message?: string) => void;
  isMultiSelect: boolean;
  handleMultiAssignUserChange: (person?: string) => void;
  handleOpenAddExternalUserDialog: (
    isMultiAssignemnt: boolean,
    collectOverviewIds?: string[]
  ) => void;
}

export const AssignDataOwnerDialog = ({
  isOpen,
  isLoading,
  onCancel,
  onSave,
  isMultiSelect,
  handleMultiAssignUserChange,
  handleOpenAddExternalUserDialog,
}: Props) => {
  const { t } = useTranslation();
  const [radioValue, setRadioValue] = useState<AssignDataOwnerDialogOptions>(
    AssignDataOwnerDialogOptions.WITH_EMAIL
  );
  const [selectedUser, setSelectedUser] = useState('');

  const [note, setNote] = useState<string>('');

  const { users } = useUser();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value as AssignDataOwnerDialogOptions);
  };

  const handleSave = () => {
    const notify = radioValue === AssignDataOwnerDialogOptions.WITH_EMAIL ? true : false;
    onSave(notify, note && note.length > 0 ? note : undefined);
    setNote('');
    setSelectedUser('');
  };

  const handleOnCancel = () => {
    onCancel();
    setNote('');
    setSelectedUser('');
  };

  const handleUserSelectChange = (event: SelectChangeEvent<string>) => {
    if (event.target.value === 'ADD_EXTERNAL_USER') {
      handleOpenAddExternalUserDialog(true);
    } else {
      setSelectedUser(event.target.value as string);
      if (event.target.value && event.target.value.length > 0) {
        handleMultiAssignUserChange(
          event.target.value && event.target.value.length > 0 ? event.target.value : undefined
        );
      }
    }
  };

  const sortedUsers = sortUserArray(users);
  const isRadioDisabeld = isMultiSelect && selectedUser.length === 0 ? true : false;
  const isSaveDisabeld = isMultiSelect && selectedUser.length === 0 ? true : false;

  return (
    <Dialog open={isOpen}>
      <Box
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          minWidth: '600px',
          background: '#fff',
        }}
      >
        <Typography fontSize={'1.5rem !important'} fontWeight={600}>
          {t('modules.dataManagement.assignDataOwner.title')}
        </Typography>
        {isMultiSelect && (
          <FormControl>
            <Typography
              sx={{ color: theme.palette.colors.lightGrey, mb: 0.5 }}
              fontSize={'0.875rem !important'}
              fontWeight={500}
            >
              {t('modules.dataManagement.assignDataOwner.selectUserLabel')}
            </Typography>
            <Select
              labelId="responsiblePersonId"
              id="responsiblePersonId"
              value={selectedUser}
              displayEmpty
              onChange={handleUserSelectChange}
              sx={{
                fontSize: '0.875rem !important',
                '& .MuiInputBase-input': {
                  height: 'auto',
                },
              }}
              renderValue={selected => {
                if (!selected) {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ color: 'colors.grey', fontSize: '0.875rem !important', pt: 0.5 }}
                      >
                        {t('modules.dataManagement.assignDataOwner.selectUserLabel')}
                      </Typography>
                    </Box>
                  );
                }
                const userName = getNameById(selected, sortedUsers);
                return (
                  <Box
                    key={selected}
                    sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}
                  >
                    <Avatar
                      styles={{
                        width: '25px',
                        height: '25px',
                        fontSize: '0.675rem',
                        fontWeight: 500,
                      }}
                      input={userName}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        ml: 1,
                        fontSize: '0.875rem !important',
                      }}
                    >
                      {userName}
                    </Typography>
                  </Box>
                );
              }}
            >
              <MenuItem value="">
                <em>{t('modules.dataManagement.assignDataOwner.selectUserLabel')}</em>
              </MenuItem>
              {sortedUsers.map(user => {
                return (
                  <MenuItem key={user.id} value={user.id} sx={{ height: '36px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                      <Avatar
                        styles={{
                          width: '25px',
                          height: '25px',
                          fontSize: '0.675rem',
                          borderWidth: 1,
                          fontWeight: 500,
                        }}
                        input={user.fullName}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          ml: 1,
                          fontSize: '0.875rem !important',
                          fontWeight: 500,
                        }}
                      >
                        {user.fullName}
                      </Typography>
                    </Box>
                  </MenuItem>
                );
              })}
              <MenuItem value="ADD_EXTERNAL_USER">
                <Box
                  sx={{
                    width: '100%',
                    textAlign: 'left',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '0.875rem !important',
                      fontWeight: 500,
                      color: 'blue',
                      mr: 0.5,
                    }}
                  >
                    <em>{t('modules.dataManagement.assignDataOwner.addExternal')}</em>
                  </Typography>
                  <CreateIcon sx={{ color: 'blue', height: 18 }} />
                </Box>
              </MenuItem>
            </Select>
          </FormControl>
        )}
        <RadioGroup value={radioValue} onChange={handleRadioChange}>
          <FormControl>
            <FormControlLabel
              value={AssignDataOwnerDialogOptions.WITH_EMAIL}
              control={<Radio />}
              label={
                <Typography
                  sx={{ color: theme.palette.colors.lightGrey }}
                  fontSize={'0.875rem !important'}
                  fontWeight={500}
                >
                  {t('modules.dataManagement.assignDataOwner.withEmail')}
                </Typography>
              }
              sx={{ mb: 0.5 }}
              disabled={isRadioDisabeld}
            />
            {radioValue === AssignDataOwnerDialogOptions.WITH_EMAIL && (
              <Box height={'170px'}>
                <Typography
                  sx={{ color: theme.palette.colors.lightGrey }}
                  fontSize={'0.875rem !important'}
                  fontWeight={500}
                >
                  {t('modules.dataManagement.assignDataOwner.leaveNote')}
                </Typography>
                <TextField
                  multiline
                  margin="normal"
                  inputProps={{
                    maxLength: MAX_CHARACTER_COUNT,
                  }}
                  sx={textFieldInputStyle}
                  rows={5}
                  variant="outlined"
                  fullWidth
                  placeholder={t('modules.dataManagement.assignDataOwner.optional')}
                  helperText={`${note.length}/${MAX_CHARACTER_COUNT}`}
                  value={note}
                  onChange={e => setNote(e.target.value)}
                  disabled={isRadioDisabeld}
                />
              </Box>
            )}
          </FormControl>
          <FormControlLabel
            value={AssignDataOwnerDialogOptions.WITHOUT_EMAIL}
            control={<Radio />}
            label={
              <Typography
                sx={{ color: theme.palette.colors.lightGrey }}
                fontSize={'0.875rem !important'}
                fontWeight={500}
              >
                {t('modules.dataManagement.assignDataOwner.withoutEmail')}
              </Typography>
            }
            disabled={isRadioDisabeld}
          />
        </RadioGroup>
        <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
          <Button variant="outlined" size="small" onClick={handleOnCancel}>
            {t('modules.dataManagement.assignDataOwner.cancel')}
          </Button>
          <Button
            variant="contained"
            size="small"
            loading={isLoading}
            onClick={handleSave}
            sx={{ ml: 2 }}
            disabled={isSaveDisabeld}
          >
            {t('modules.dataManagement.assignDataOwner.save')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
