import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Button from 'src/modules/common/components/Button/Button';
import { VerifyEmailSvg, AppLogoIcon } from 'src/modules/common/components/icons';
import { PageContainer } from 'src/modules/common/components';
import { useRegister } from 'src/modules/common/application';

const VerifyEmail = ({ email }: { email: string }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { resendVerifyEmail } = useRegister();

  const onSubmit = () => {
    if (email)
      resendVerifyEmail({
        variables: { email },
      });
  };

  return (
    <PageContainer>
      <Helmet>
        <title>Registration - Mavue</title>
      </Helmet>
      <AppLogoIcon
        style={{
          position: 'absolute',
          top: '8%',
          left: '6%',
          width: 180,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <VerifyEmailSvg />
        <Typography variant="h4" sx={{ paddingBottom: theme.spacing(2), mt: 4 }}>
          {t('modules.register.verifyRegistration.title')}
        </Typography>
        <Typography
          variant="body2"
          component="div"
          sx={{
            paddingBottom: theme.spacing(2),
            color: theme.palette.colors.lightGrey,
            maxWidth: 571,
            textAlign: 'center',
          }}
        >
          <div>
            {t('modules.register.verifyRegistration.subtitle-top')} {email}
          </div>
          {t('modules.register.verifyRegistration.subtitle-bottom')}
        </Typography>

        <Button onClick={onSubmit}>{t('modules.register.verifyRegistration.button')}</Button>
      </Box>
    </PageContainer>
  );
};

export default VerifyEmail;
