import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, TableContainer, Button } from '@mui/material';

import { GET_USERS } from 'src/services/graphql/queries';
import { useEnhancedQuery, useMessages } from 'src/modules/common/application';
import { Loader, DeleteObjectDialog } from 'src/modules/common/components';
import { UserRole, InviteUserInput } from 'src/modules/common/types';
import useUserAdmin from 'src/modules/common/application/use-user-admin';
import useUserInvitation from 'src/modules/common/application/use-user-invitation';

import ManageTeamTable from '../ManageTeamTable/ManageTeamTable';
import { ManageUser } from './types';
import InviteUserDialog from '../InviteUserDialog/InviteUserDialog';
import ChangePermissionDialog from '../ChangePermissionDialog/ChangePermissionDialog';

const ManageTeam = () => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();
  const [isInviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);
  const [isChangePermissionDialogOpen, setChangePermissionDialogOpen] = useState(false);
  const [isDeleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<ManageUser>({} as ManageUser);
  const { loading, data, error } = useEnhancedQuery<{ users: ManageUser[] }>(GET_USERS);
  const { onInviteUsers, loading: loadingUserInvitation, resendInviteEmail } = useUserInvitation();
  const [submitErrors, setSubmitErrors] = useState<string[]>([]);
  const { handleUpdateUser, deleteUser, deleteUserLoading } = useUserAdmin();

  if (loading || !data) return <Loader />;
  if (error) return <>{setErrorMessage(t('messages.getUserInfo.error'))}</>;

  const { users } = data;

  const handleResendInvitationClick = async (user: ManageUser) => {
    await resendInviteEmail({
      variables: {
        email: user.email,
      },
    });
  };

  const handleChangePermissionClick = (u: ManageUser) => {
    setSelectedUser(u);
    setChangePermissionDialogOpen(true);
  };

  const handleOpenInviteUserDialog = () => {
    setInviteUserDialogOpen(true);
  };

  const handleCloseInviteUserDialog = () => {
    setInviteUserDialogOpen(false);
  };

  const handleSendInviteClick = async (values: InviteUserInput[]) => {
    const result = await onInviteUsers(values);

    if (result.__typename === 'InviteUserError') {
      setSubmitErrors(result.existingEmails);
    } else {
      setInviteUserDialogOpen(false);
    }
  };

  const handleUpdateUserPermissionClick = async (user: ManageUser) => {
    await handleUpdateUser({
      id: user.id,
      role: user.role,
    });
    setChangePermissionDialogOpen(false);
  };

  const handleUserRoleChange = (role: UserRole) => {
    const updatedUser = { ...selectedUser, role };
    setSelectedUser(updatedUser);
  };

  const handleDeleteUserClick = (user: ManageUser) => {
    setSelectedUser(user);
    setDeleteUserDialogOpen(true);
  };

  const handleDeleteUser = (user: ManageUser) => {
    deleteUser({ variables: { id: user.id } });
    setDeleteUserDialogOpen(false);
    setSelectedUser({} as ManageUser);
  };

  return (
    <>
      <InviteUserDialog
        open={isInviteUserDialogOpen}
        onClose={handleCloseInviteUserDialog}
        handleSendInvite={handleSendInviteClick}
        loading={loadingUserInvitation}
        submitErrors={submitErrors}
      />
      <ChangePermissionDialog
        user={selectedUser}
        handleRoleChange={handleUserRoleChange}
        open={isChangePermissionDialogOpen}
        onClose={() => setChangePermissionDialogOpen(false)}
        handleChangePermission={handleUpdateUserPermissionClick}
      />
      <DeleteObjectDialog
        isOpen={isDeleteUserDialogOpen}
        isLoading={deleteUserLoading}
        onClose={() => setDeleteUserDialogOpen(false)}
        onDelete={() => handleDeleteUser(selectedUser)}
        deleteConfirmation={
          selectedUser && selectedUser?.role === UserRole.EXTERNAL
            ? t('modules.settings.deleteExternalUser.info', {
                fullName: selectedUser?.firstName
                  ? `${selectedUser.firstName} ${selectedUser.lastName}`
                  : selectedUser?.email,
              })
            : t('modules.settings.deleteUser.info')
        }
        info={
          selectedUser && selectedUser?.role === UserRole.EXTERNAL
            ? ''
            : t('modules.settings.deleteUser.confirmation') +
              `${
                selectedUser?.firstName
                  ? `${selectedUser.firstName} ${selectedUser.lastName}`
                  : selectedUser?.email!
              }` +
              t('modules.settings.deleteUser.confirmationPartTwo')
        }
      />
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 2,
            alignItems: 'center',
          }}
        >
          <Typography variant="h3" sx={{ fontSize: '1.5rem !important', fontWeight: 600 }}>
            {t('modules.settings.manageTeam.title')}
          </Typography>
          <Button
            variant="contained"
            size="small"
            sx={{ fontSize: '14px' }}
            onClick={handleOpenInviteUserDialog}
          >
            {t('modules.settings.manageTeam.inviteUsers')}
          </Button>
        </Box>
        <TableContainer>
          <ManageTeamTable
            data={users}
            onResendInvitationClick={handleResendInvitationClick}
            onChangePermissionClick={handleChangePermissionClick}
            onDeleteUserClick={handleDeleteUserClick}
          />
        </TableContainer>
      </Box>
    </>
  );
};

export default ManageTeam;
