import { REPORTING_YEARS } from 'src/modules/common/types/constants/reporting-years';
import { IYear } from 'src/modules/common/types/year';

export const createYears = (year: number[]) => {
  const years: IYear[] = [];
  REPORTING_YEARS.forEach(y => {
    if (year.includes(y)) {
      years.push({ year: y, disabled: true });
    } else {
      years.push({ year: y, disabled: false });
    }
  });
  return years;
};

export const createStartYears = (startYear: number) => {
  if (startYear === Math.max(...REPORTING_YEARS)) {
    return [startYear];
  }
  const filteredYears = REPORTING_YEARS.filter(year => year >= startYear);
  const maxYear = Math.max(...filteredYears);
  return filteredYears.filter(year => year !== maxYear);
};
