import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';
import { NoDataIcon } from 'src/modules/common/components/icons';
const NoRequstedInputDataPlaceholder = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="50%"
        height="400"
      >
        <NoDataIcon width="50" height="50" />
        <Typography align="center" variant="subtitle1">
          {t('shared.components.noRequstedInputDataPlaceholder.title')}
        </Typography>
        <Typography
          align="center"
          variant="subtitle1"
          sx={{ color: theme.palette.colors.lightGrey, fontWeight: 400 }}
        >
          {t('shared.components.noRequstedInputDataPlaceholder.subtitle')}
        </Typography>
      </Box>
    </Box>
  );
};

export default NoRequstedInputDataPlaceholder;
