import React from 'react';
import { Box, Grid } from '@mui/material';

import { IRoadmapStep } from '../../types';
import RoadmapStepper from '../RoadmapStepper/RoadmapStepper';
import RoadmapStepCard from '../RoadmapStepCard/RoadmapStepCard';

interface IRoadmapStepData {
  roadmapStepsData: IRoadmapStep[];
}

const RoadmapStep = (props: IRoadmapStepData) => {
  const { roadmapStepsData } = props;
  return (
    <Box
      sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexDirection: 'column' }}
    >
      <Grid container alignItems="flex-start">
        {roadmapStepsData.map((item, key) => {
          const isFirst = key === 0;
          const isLast = key === roadmapStepsData.length - 1;

          return (
            <Grid key={key} item xs={12} md={4}>
              <RoadmapStepper
                isFirst={isFirst}
                isLast={isLast}
                stepCount={key + 1}
                isActive={item.isActive}
                isCompleted={item.isCompleted}
                isNextCompleted={item.isNextCompleted}
              />
              <RoadmapStepCard
                isActive={item.isActive}
                isCompleted={item.isCompleted}
                data={item}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default RoadmapStep;
