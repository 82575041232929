import React from 'react';
import { Box, Card, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { RequestStatus } from 'src/modules/common/types';

import MaterialityEvaluationStatCard from '../MaterialityEvaluationStatCard/MaterialityEvaluationStatCard';

interface MaterialityEvaluationOverviewStatsProps {
  stats: Record<RequestStatus, number>;
  topicStats: {
    totalTopics: number;
    completedTopics: number;
  };
}

const MaterialityEvaluationOverviewStats = ({
  stats,
  topicStats,
}: MaterialityEvaluationOverviewStatsProps) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={3}
      sx={{
        alignItems: 'stretch',
        paddingTop: '0px !important',
        marginTop: 0,
      }}
    >
      {Object.entries(stats).map(([type, count]) => (
        <Grid key={type} item md={3} sm={6} xs={12} sx={{ paddingTop: '24px !important' }}>
          <MaterialityEvaluationStatCard type={type as RequestStatus} count={count} />
        </Grid>
      ))}
      <Grid item md={3} sm={6} xs={12}>
        <Card
          sx={{
            p: 3,
            border: '1px solid',
            borderColor: 'lightGrey',
            boxShadow: 'none',
            minHeight: '114px',
          }}
        >
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            {t('modules.materialityAssessment.materialityEvaluation.completedTopics')}
          </Typography>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              mt: 1,
              ml: 1,
            }}
          >
            {topicStats.completedTopics + ' / ' + topicStats.totalTopics}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MaterialityEvaluationOverviewStats;
