export const maskEmail = (email: string): string => {
  const [name, domain] = email.split('@');
  const maskedName = maskString(name);
  const maskedDomain = maskDomain(domain);
  return `${maskedName}@${maskedDomain}`;
};

const maskString = (str: string): string => {
  const visibleCharacters = Math.ceil(str.length / 3);
  const maskedCharacters = str.length - visibleCharacters;
  const maskedString = '*'.repeat(maskedCharacters);
  const visibleString = str.slice(-visibleCharacters);
  return `${maskedString}${visibleString}`;
};

const maskDomain = (domain: string): string => {
  const parts = domain.split('.');
  const maskedParts = parts.map(part => maskString(part));
  return maskedParts.join('.');
};
