import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EsgValueAgg } from 'src/modules/common/types';
import { GET_ESG_VALUES_AGG_BY_REPORT_SECTION } from 'src/services/graphql/queries';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import useEnhancedQuery from './use-enhanced-query';
import useLanguage from './use-language';

export type SidebarData = {
  title: string;
  value: string;
  unit: string;
};

const useAdditionalInfoSidebar = (reportId: string, sectionId: string, year: number) => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const { data, loading, error } = useEnhancedQuery<{
    esgValuesAggByReportSection: EsgValueAgg[];
  }>(GET_ESG_VALUES_AGG_BY_REPORT_SECTION, {
    variables: {
      reportId,
      reportSectionId: sectionId,
      year,
      language,
    },
  });

  const esgValues = useMemo(() => data?.esgValuesAggByReportSection, [data]);

  const sidebarData = useMemo(
    () =>
      !esgValues
        ? []
        : esgValues.reduce<SidebarData[]>((filtered, option) => {
            if (option.value) {
              filtered.push({
                value: option.value.toString(),
                unit: option.selectedUnit ? option.selectedUnit : '',
                title: t(`shared.types.kpi.${removeUnderscoreToCamelCase(option?.kpi?.title)}`),
              });
            } else {
              filtered.push({
                value: t('modules.reporting.additionalInfoSidebar.missingData'),
                unit: option.selectedUnit ? option.selectedUnit : '',
                title: t(`shared.types.kpi.${removeUnderscoreToCamelCase(option?.kpi?.title)}`),
              });
            }
            return filtered;
          }, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [esgValues]
  );

  return {
    sidebarData,
    loading,
    error,
  };
};

export default useAdditionalInfoSidebar;
