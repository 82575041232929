import React from 'react';
import { styled, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePageTitle } from 'src/modules/common/application';

export const Wrapper = styled(Box)({
  position: 'relative',
  maxWidth: '100%',
  margin: '0 auto',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
});

interface Props {
  meta?: string;
  children?: React.ReactNode;
}

const PageContainer = ({ meta = '', children }: Props) => {
  const { t } = useTranslation();
  const { setHelmetTitle } = usePageTitle();
  setHelmetTitle(t(meta ? meta : ''));
  return <Wrapper>{children}</Wrapper>;
};

export default PageContainer;
