import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Table, TableBody } from '@mui/material';
import { ApprovalStatus, RequestedEsgValuesByOwner, Unit } from 'src/modules/common/types';
import TableHeader from 'src/modules/common/components/Table/TableHeader';
import EsgDataApprovalTableRow from '../EsgDataApprovalTableRow/EsgDataApprovalTableRow';
import { EsgDataApprovalTableHeaders } from './constants';

interface Props {
  data: RequestedEsgValuesByOwner[];
  onApprovalClick: (
    esgValueId: string,
    newValue: number,
    newUnit: Unit,
    status: ApprovalStatus
  ) => void;
}

const EsgDataApprovalTable = ({ data, onApprovalClick }: Props) => {
  const { t } = useTranslation();
  const headers = EsgDataApprovalTableHeaders.map(t) as string[];

  return (
    <Box>
      <Table sx={{ tableLayout: 'auto', width: '100%' }}>
        <TableHeader headers={headers} showLastHeader={false} />
        <TableBody>
          {data.map((esgValue: RequestedEsgValuesByOwner) => {
            return (
              <EsgDataApprovalTableRow
                key={esgValue.id}
                data={esgValue}
                onApprovalClick={onApprovalClick}
              />
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default EsgDataApprovalTable;
