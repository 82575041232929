import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from 'formik-material-ui';
import { Field, Form, Formik, FormikContextType, useFormikContext } from 'formik';
import { Grid, Box, Typography, useTheme, InputAdornment } from '@mui/material';
import { getSetPasswordSchema } from 'src/modules/common/application/auth.schema';
import { useUserData, usePasswordToggle } from 'src/modules/common/application';
import Button from 'src/modules/common/components/Button/Button';

interface IFormProps {
  termsAccepted: boolean;
  passwordRepeat: string;
  password: string;
  email: string;
}

// @ts-ignore eslint-disable-next-line
const SetPasswordForm = ({ onSubmit, loading }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const theme = useTheme();
  const { user } = useUserData();
  const { inputType: PasswordInputType, Icon: ToggleIcon, toggleVisibility } = usePasswordToggle();
  const {
    inputType: RepeatPasswordInputType,
    Icon: RepeatToggleIcon,
    toggleVisibility: RepeatToggleVisibility,
  } = usePasswordToggle();

  const setPasswordSchema = getSetPasswordSchema(t);
  const defaultData = setPasswordSchema.getDefault();
  defaultData.email = user ? user.email : '';

  const inputStyle = {
    inputlabelprops: {
      style: {
        fontSize: theme.spacing(2),
      },
    },
    sx: {
      '& .MuiFormHelperText-root': {
        display: 'none',
      },
    },
  };

  const PasswordError = () => {
    const { values, errors }: FormikContextType<IFormProps> = useFormikContext();

    if (values.password === '' && values.passwordRepeat === '')
      return (
        <Typography variant="subtitle2" color={theme.palette.text.secondary} mb={4.5}>
          {t('modules.setPassword.setPasswordForm.hint')}
        </Typography>
      );

    let message = '';
    if (errors.password) message = t(errors.password);
    else if (values.password !== values.passwordRepeat)
      message = t('modules.setPassword.setPasswordForm.password-not-match-message');
    else message = t('modules.setPassword.setPasswordForm.password-match-message');

    return (
      <Typography variant="subtitle2" color={theme.palette.colors.grey} mb={4}>
        {message}
      </Typography>
    );
  };

  return (
    <>
      <Formik
        initialValues={defaultData}
        validateOnBlur={false}
        validationSchema={setPasswordSchema}
        onSubmit={values => onSubmit(values)}
      >
        {({ handleSubmit, isSubmitting, handleChange, values }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography
                  variant="h4"
                  textAlign="center"
                  mb={4}
                  color={theme.palette.colors.grey}
                >
                  {t('modules.register.registrationForm.title')}
                </Typography>
                <Typography textAlign="center" variant="subtitle1">
                  {t('modules.setPassword.setPasswordForm.pageTitle')}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ paddingTop: `${theme.spacing(3)} !important` }}>
                <Field
                  component={TextField}
                  fullWidth
                  type="text"
                  label={t('shared.types.user.email')}
                  name="email"
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  fullWidth
                  type={PasswordInputType}
                  name="password"
                  label={t('modules.setPassword.setPasswordForm.password-label')}
                  variant="outlined"
                  {...inputStyle}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <ToggleIcon
                          onClick={() => toggleVisibility()}
                          sx={{ '&:hover': { cursor: 'pointer' } }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  fullWidth
                  type={RepeatPasswordInputType}
                  name="passwordRepeat"
                  label={t('modules.setPassword.setPasswordForm.password-repeat-label')}
                  variant="outlined"
                  {...inputStyle}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <RepeatToggleIcon
                          onClick={() => RepeatToggleVisibility()}
                          sx={{ '&:hover': { cursor: 'pointer' } }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
                <PasswordError />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Button
                    loading={isSubmitting || loading}
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {t('modules.setPassword.setPasswordForm.submit-button')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SetPasswordForm;
