import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Trans, useTranslation } from 'react-i18next';

import useCalculationStatus from 'src/modules/common/application/use-calculation-status';

import { Loader } from '../Base';

const CalculationStatus = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { nextCalculationTime, error, loading } = useCalculationStatus();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: 1,
        width: 'fit-content',
        border: '1px solid #D9D6D6',
        borderRadius: '4px',
        marginRight: 1,
        padding: '0 1.2rem',
        flexShrink: 0,
      }}
    >
      {loading || error ? (
        <Loader
          size={20}
          spinnerStyle={{
            position: 'relative',
            top: 0,
            left: 0,
            WebkitTransform: 'none',
            transform: 'none',
          }}
        />
      ) : (
        <>
          <Box
            sx={{
              height: 8,
              width: 8,
              backgroundColor:
                nextCalculationTime === undefined
                  ? theme.palette.success.main
                  : theme.palette.error.main,
              borderRadius: '50%',
              display: 'inline-block',
            }}
          ></Box>
          {nextCalculationTime === undefined ? (
            <Typography
              variant="body2"
              sx={{ color: theme.palette.colors.lightGrey, fontSize: '1rem !important' }}
            >
              {t('modules.dataManagement.calculationStatus.upToDate')}
            </Typography>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Trans
                i18nKey="modules.dataManagement.calculationStatus.nextUpdate"
                components={{
                  parent: (
                    <Typography
                      variant="body2"
                      sx={{ color: theme.palette.colors.lightGrey, fontSize: '1rem !important' }}
                    />
                  ),
                  children: (
                    <span
                      style={{
                        fontSize: '1rem !important',
                        color: '#000',
                      }}
                    />
                  ),
                }}
                values={{ nextCalculationTime }}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default CalculationStatus;
