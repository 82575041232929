import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SEND_PASSWORD_RESET_MAIL } from 'src/services/graphql/mutations';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';

import {
  AuthLayout,
  CardLayout,
  LanguageSelect,
  TermsAndConditions,
} from 'src/modules/common/components';
import {
  useBackendErrorMessage,
  useMessages,
  useEnhancedMutation,
} from 'src/modules/common/application';
import { PasswordForgotForm } from 'src/modules/auth/components';

const PasswordForgot = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setSuccessMessage } = useMessages();
  const setBackendError = useBackendErrorMessage();
  const [mailSent, setMailSent] = useState<boolean | null>(false);

  const [resetMail] = useEnhancedMutation(SEND_PASSWORD_RESET_MAIL, {
    onError: error => {
      setBackendError(error?.message, t('messages.updatePassword.emailError'));
      setMailSent(false);
    },
    onCompleted: () => {
      setSuccessMessage(t('messages.updatePassword.emailSent'));
      setMailSent(true);
    },
    fetchPolicy: 'no-cache',
  });

  const handleEmailSubmit = async ({ email }: { email: string }) => {
    await resetMail({ variables: { email } });
  };

  const routeLogin = () => {
    setMailSent(false);
    navigate('/login');
  };

  return (
    <AuthLayout title={t('modules.passwordForgot.authLayout.title')} showDashboard>
      <CardLayout height="fit-content">
        <PasswordForgotForm
          onSubmit={handleEmailSubmit}
          onRouteChange={routeLogin}
          success={mailSent as boolean}
        />
      </CardLayout>
      <Box sx={{ width: 500, mt: 2 }}>
        <LanguageSelect />
      </Box>
      <Box sx={{ position: 'absolute', bottom: 5 }}>
        <TermsAndConditions />
      </Box>
    </AuthLayout>
  );
};

export default PasswordForgot;
