import React, { createContext } from 'react';
import { useLocation } from 'react-router-dom';

import { LanguageCode } from 'src/modules/common/types';
import { TFunction, useTranslation } from 'react-i18next';
import { getLanguageCode } from '../languageCodeFormater';
import useUserData from '../use-user-data';
import useUserSettings from '../use-user-settings';

interface LanguageContextInterface {
  language: LanguageCode;
  setLanguage: (language: LanguageCode) => Promise<TFunction> | undefined;
}

const LanguageContext = createContext<LanguageContextInterface>({
  language: LanguageCode.EN,
  setLanguage: () => undefined,
});

const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation();
  const location = useLocation();

  const { user } = useUserData();
  const { updateUser } = useUserSettings();

  const setLanguage = async (language: LanguageCode): Promise<TFunction> => {
    await updateUser({ language: language });
    return i18n.changeLanguage(language as string);
  };

  if (
    location.pathname !== '/user-info' &&
    location.pathname !== '/company-info' &&
    location.pathname !== '/add-facility' &&
    location.pathname !== '/onboarding-start' &&
    location.pathname !== '/set-password'
  )
    if (user?.language && getLanguageCode(i18n.language) !== user?.language) {
      i18n.changeLanguage(user?.language as LanguageCode);
    }

  return (
    <LanguageContext.Provider
      value={{
        language: getLanguageCode(i18n.language) as LanguageCode,
        setLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageProvider };

export default LanguageContext;
