/* eslint-disable no-console */
import { EsgKpi } from 'src/modules/common/types';
import { GET_ESG_KPIS } from 'src/services/graphql/queries';
import useEnhancedQuery from 'src/modules/common/application/use-enhanced-query';

const useEsgKpi = (accessToken?: string) => {
  const { data, loading: isEsgKpiLoading } = useEnhancedQuery<{
    esgKpis: EsgKpi[];
  }>(GET_ESG_KPIS, {
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        'x-user-type': accessToken ? 'external' : 'internal',
      },
    },
  });

  const getKpisByCategory = (categoryId: string) => {
    return data?.esgKpis
      ? data?.esgKpis?.filter((kpi: EsgKpi) => kpi.category.id === categoryId)
      : [];
  };

  const esgKpiById = (id: string) => {
    return data?.esgKpis?.find((kpi: EsgKpi) => kpi.id === id);
  };

  return {
    esgKpis: data?.esgKpis || [],
    isEsgKpiLoading: isEsgKpiLoading,
    getKpisByCategory,
    esgKpiById,
  };
};

export default useEsgKpi;
