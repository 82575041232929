import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import { textFieldInputStyle } from '../ChatbotContainer/styles';

interface ChatbotMessageInputProps {
  sendMessage: (message: string) => void;
}

const ChatbotMessageInput = (props: ChatbotMessageInputProps) => {
  const { sendMessage } = props;
  const [inputMessage, setInputMessage] = useState<string>('');

  const hasInputMessage = inputMessage.length > 0;

  const handleSendMessage = (message: string) => {
    sendMessage(message);
    setInputMessage('');
  };

  return (
    <TextField
      placeholder="Send a message"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              sx={{
                px: 1.5,
                py: 1,
                borderRadius: '4px',
                background: hasInputMessage ? '#4356ED' : 'inherit',
              }}
              size="small"
              onClick={() => {
                handleSendMessage(inputMessage);
              }}
            >
              <SendIcon
                sx={{
                  height: '20px',
                  width: '20px',
                  fill: hasInputMessage ? '#fff' : undefined,
                }}
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
      variant="outlined"
      fullWidth
      sx={textFieldInputStyle}
      value={inputMessage}
      onChange={e => {
        setInputMessage(e.currentTarget.value);
      }}
      onKeyUp={e => {
        if (e.key === 'Enter') {
          handleSendMessage(inputMessage);
        }
      }}
    />
  );
};

export default ChatbotMessageInput;
