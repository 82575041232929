import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { PostHogFeature } from 'posthog-js/react';

import { TabBar } from 'src/modules/common/components';
import { Chip } from 'src/modules/common/components/Chip';
import { ReportSection, ChipColorVariants, FeatureFlag } from 'src/modules/common/types';
import { MemorizedButton } from 'src/modules/common/components/Button/Button';
import useAdditionalInfoSidebar from 'src/modules/common/application/use-additional-info-sidebar';

import useReportInput from '../../application/use-report-input';
import TextInputCard from '../TextInputCard/TextInputCard';
import RequirementCard from '../RequirementCard/RequirementCard';
import useAIGenerateReport from '../../application/use-ai-generate-report';
import RelevantDataTable from '../RelevantDataTable/RelevantDataTable';
import ExampleTab from '../AdditionalInfoSidebar/ExampleTab';

interface Props {
  reportSection: ReportSection;
  year: number;
  reportId: string;
  title: string;
}

const ReportInputSection = ({ reportSection, year, reportId, title }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const editorRef = useRef<any>(null);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const { sidebarData } = useAdditionalInfoSidebar(reportId, reportSection.id, year);

  const { handleUpdateReport, updateReportInputLoading } = useReportInput(
    reportId,
    reportSection.id
  );

  const { handleGenerateAiReports, loading: generatingLoading } = useAIGenerateReport(
    reportId,
    reportSection.id
  );

  const handleUpdateCompletion = (): void => {
    handleUpdateReport(undefined, !isMarkedCompleted);
  };

  const onGenerate = async () => {
    await handleGenerateAiReports();
  };

  const TABS = useMemo(() => {
    const tabs = [
      {
        input: <RequirementCard requirements={reportSection.requirements!} />,
        label: t('modules.reporting.reportSectionTabs.tab1'),
        index: 0,
        id: 'report-detail-one',
        showTab: true,
        to: `/reports/${reportId}/section/${reportSection.id}`,
      },
      {
        input: <RelevantDataTable data={sidebarData} />,
        label: t('modules.reporting.reportSectionTabs.tab2'),
        index: 1,
        id: 'report-detail-two',
        showTab: sidebarData.length > 0,
        to: `/reports/${reportId}/section/${reportSection.id}`,
      },
      {
        input: <ExampleTab reportId={reportId} reportSectionId={reportSection.id} />,
        label: t('modules.reporting.reportSectionTabs.tab3'),
        index: sidebarData.length > 0 ? 2 : 1,
        id: 'report-detail-three',
        showTab: true,
        to: `/reports/${reportId}/section/${reportSection.id}`,
      },
    ];

    setActiveTabIndex(0);
    return tabs.filter(tab => tab.showTab);
  }, [reportId, reportSection, sidebarData, t]);

  const isMarkedCompleted = reportSection.reportInput?.isMarkedCompleted === true ? true : false;

  const handleSave = () => {
    if (editorRef.current) {
      const htmlText = editorRef.current.getHTML();
      handleUpdateReport(htmlText === '<p><br></p>' ? '' : htmlText);
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        {!isMarkedCompleted && (
          <CircleOutlinedIcon
            sx={{
              height: '32px',
              width: '32px',
              color: '#D9D9D9',
              mr: 1,
            }}
          />
        )}
        {isMarkedCompleted && (
          <TaskAltOutlinedIcon
            sx={{
              mr: 1,
              color: theme.palette.colors.successGreen,
              height: '32px',
              width: '32px',
            }}
          />
        )}
        <Typography fontSize={'1.5rem !important'} fontWeight={600}>
          {title}
        </Typography>
        <Chip
          size="small"
          styles={{
            marginLeft: 1,
            maxWidth: 'fit-content',
          }}
          labelStyles={{
            fontWeight: 500,
            fontSize: '0.875rem !important',
            paddingTop: 1,
            paddingBottom: 1,
          }}
          colorVariant={ChipColorVariants.WARM_BLUE}
        >
          powered by AI
        </Chip>
      </Box>
      <TabBar
        activeTabIndex={activeTabIndex}
        content={TABS}
        onTabChange={tabIndex => {
          setActiveTabIndex(tabIndex);
        }}
        SideComponent={() => (
          <Box
            sx={{
              display: 'flex',
              right: 0,
            }}
          >
            <PostHogFeature flag={FeatureFlag.REPORT_MARK_AS_COMPLETE_BUTTON} match={true}>
              <MemorizedButton
                variant={isMarkedCompleted ? 'contained' : 'outlined'}
                size="small"
                color="success"
                onClick={handleUpdateCompletion}
                loading={updateReportInputLoading}
                startIcon={
                  isMarkedCompleted ? (
                    <CheckCircleOutlineIcon style={{ color: 'white' }} />
                  ) : undefined
                }
                buttonLabelStyles={{
                  color: isMarkedCompleted
                    ? 'white !important'
                    : theme.palette.colors.successGreen + ' !important',
                }}
                sx={{ color: theme.palette.colors.successGreen }}
              >
                {t(
                  `modules.reporting.textInput.${isMarkedCompleted ? 'completed' : 'markComplete'}`
                )}
              </MemorizedButton>
            </PostHogFeature>
          </Box>
        )}
        sideComponentStyles={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
        tabBarStyles={{
          borderBottom: 0,
        }}
      />
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          mt: 3,
        }}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <EditIcon
            sx={{
              height: '20px',
              width: '20px',
              color: theme.palette.colors.lightGrey,
              mr: 1,
            }}
          />

          <Typography fontSize={'1.25rem !important'} fontWeight={600}>
            {t('modules.reporting.textInput.title')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            height: '10%',
          }}
        >
          <PostHogFeature flag={FeatureFlag.AI_REPORT_GENERATE_BUTTON} match={true}>
            <MemorizedButton
              endIcon={<AutoAwesomeIcon />}
              variant="contained"
              size="small"
              onClick={onGenerate}
              loading={generatingLoading}
              disabled={
                !!(
                  reportSection?.reportInput?.input &&
                  reportSection?.reportInput?.input.length > 0 &&
                  reportSection?.reportInput?.input !== '\n'
                )
              }
            >
              {t('modules.reporting.textInput.generate')}
            </MemorizedButton>
          </PostHogFeature>
          <MemorizedButton
            variant="outlined"
            size="small"
            onClick={handleSave}
            loading={updateReportInputLoading}
            disabled={isMarkedCompleted || generatingLoading}
            sx={{
              marginLeft: 2,
            }}
          >
            {t('modules.reporting.textInput.save')}
          </MemorizedButton>
        </Box>
      </Box>
      <TextInputCard
        isMarkedCompleted={isMarkedCompleted}
        input={reportSection?.reportInput?.input!}
        ref={editorRef}
      />
    </>
  );
};

export default ReportInputSection;
