import { InMemoryCache } from '@apollo/client';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import typePolicies from './typePolicies';

// Persistant storage definition
export const cache = new InMemoryCache({
  typePolicies,
});

export const cachePersistor = new CachePersistor({
  cache,
  debounce: 1000,
  storage: new LocalStorageWrapper(window.localStorage),
});
