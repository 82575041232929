import { useTranslation } from 'react-i18next';
import {
  GroupedRequestedEsgValuesByExternalUser,
  UpdatedRequestedEsgValuesByOwnerInput,
} from 'src/modules/common/types';
import { GET_REQUESTED_ESG_VALUES_BY_OWNER } from 'src/services/graphql/queries';
import { useEnhancedQuery, useEnhancedMutation, useMessages } from 'src/modules/common/application';
import { UPDATE_REQUESTED_ESG_VALUE_APPROVAL_STATUS } from 'src/services/graphql/mutations';

const useRequstedValueByOwner = (collectOverviewId: string) => {
  const { t } = useTranslation();
  const { setErrorMessage, setSuccessMessage } = useMessages();

  const { loading, data } = useEnhancedQuery<{
    requestedEsgValuesByOwner: GroupedRequestedEsgValuesByExternalUser[];
  }>(GET_REQUESTED_ESG_VALUES_BY_OWNER, {
    variables: {
      collectOverviewId,
    },
    onError: () => {
      setErrorMessage(t('messages.getEsgValues.error'));
    },
  });

  const [
    updateRequestedEsgValueApprovalStatus,
    { loading: updateRequestedEsgValueApprovalStatusLoading },
  ] = useEnhancedMutation<UpdatedRequestedEsgValuesByOwnerInput>(
    UPDATE_REQUESTED_ESG_VALUE_APPROVAL_STATUS,
    {
      onCompleted: () => setSuccessMessage(t('messages.updateEsgValues.success')),
      onError: () => setErrorMessage(t('messages.updateEsgValues.error')),
    }
  );

  const numPendingValues = (): number | null => {
    if (!data?.requestedEsgValuesByOwner) {
      return null;
    }

    const requestedEsgValuesByOwner = data?.requestedEsgValuesByOwner || [];
    const uniquePendingEsgValuesSet = new Set();

    requestedEsgValuesByOwner.forEach(user => {
      const esgValues = user?.esgValues || [];
      esgValues.forEach(value => {
        if (value?.approvalStatus === 'PENDING') {
          uniquePendingEsgValuesSet.add(value.id);
        }
      });
    });

    const openValues = Array.from(uniquePendingEsgValuesSet);

    return openValues.length;
  };

  return {
    requestedEsgValuesByOwner: data?.requestedEsgValuesByOwner || [],
    requestedEsgValuesByOwnerLoading: loading,
    updateRequestedEsgValueApprovalStatusLoading,
    numPendingValues,
    updateRequestedEsgValueApprovalStatus,
  };
};

export default useRequstedValueByOwner;
