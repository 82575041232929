import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';

import { EsgCategoryEnum, EsgProgressByCategory } from 'src/modules/common/types';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import { getCategoryChipColor } from 'src/modules/common/application/ui';
import { InfoCard } from 'src/modules/common/components';
import { Chip } from 'src/modules/common/components/Chip';
import NoDataPlaceholder from 'src/modules/common/components/NoDataPlaceholder/NoDataPlaceholder';
import { Maybe } from 'graphql/jsutils/Maybe';

interface Props {
  data: Maybe<EsgProgressByCategory>[];
}

const OverviewDataAreas = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <InfoCard
      title={t('modules.dataManagement.overviewDataAreas.title')}
      infoText={t('modules.dataManagement.overviewDataAreas.info')}
    >
      {data.length === 0 ? (
        <Box sx={{ height: '100%' }} display="flex" justifyContent="center" alignItems="center">
          <NoDataPlaceholder />
        </Box>
      ) : (
        <>
          <Divider />
          <List sx={{ padding: '0' }}>
            {data.map((topic, i) => {
              const showDivider = data.length - 1;
              if (!topic) return null;

              const { category, progress } = topic;
              return (
                <ListItem key={category.id} divider={i < showDivider} sx={{ pt: 2.5, pb: 2.5 }}>
                  <Chip
                    size="small"
                    styles={{
                      maxWidth: '100%',
                      justifyContent: 'flex-start',
                    }}
                    colorVariant={getCategoryChipColor(category.title as EsgCategoryEnum)}
                  >
                    {t(`shared.types.esgCategory.${removeUnderscoreToCamelCase(category.title)}`)}
                  </Chip>
                  <ListItemText
                    disableTypography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <Typography
                      color="text.primary"
                      variant="subtitle2"
                      noWrap
                      sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      {`${progress}%`}
                    </Typography>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    </InfoCard>
  );
};

export default OverviewDataAreas;
