import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, TableContainer, Typography } from '@mui/material';

import { CreateFacilityInput, Facility, UpdateFacilityInput } from 'src/modules/common/types';
import { Loader, DeleteObjectDialog } from 'src/modules/common/components';

import ManageFacilityTable from '../ManageFacilityTable/ManageFacilityTable';
import ManageFacilityForm from '../ManageFacilityForm/ManageFacilityForm';
import useFacility from 'src/modules/common/application/use-facility';

const ManageFacility = () => {
  const { t } = useTranslation();
  const [facility, setFacility] = useState<Facility | null>(null);
  const [isEditFacilityMode, setEditFacilityMode] = useState(false);
  const [isDeleteFacilityDialogOpen, setIsDeleteFacilityDialogOpen] = useState(false);
  const [isFacilityFormOpen, setFacilityFormOpen] = useState(false);

  const {
    facilities,
    createFacility,
    updateFacility,
    deleteFacility,
    loading,
    createFacilityLoading,
    updateFacilityLoading,
    deleteFacilityLoading,
  } = useFacility({
    selectedFacility: facility,
    onCreateSuccess: () => {
      handleClose();
    },
    onUpdateSuccess: () => {
      handleClose();
    },
  });

  if (loading || !facilities) return <Loader />;

  const handleCreateFacilityClick = () => {
    setEditFacilityMode(false);
    setFacilityFormOpen(true);
  };
  const handleEditFacilityClick = (f: Facility) => {
    setFacility(f);
    setEditFacilityMode(true);
    setFacilityFormOpen(true);
  };

  const handleDeleteFacilityClick = (f: Facility) => {
    setFacility(f);
    setIsDeleteFacilityDialogOpen(true);
    setIsDeleteFacilityDialogOpen(true);
  };

  const handleClose = () => {
    setFacility(null);
    setEditFacilityMode(false);
    setFacilityFormOpen(false);
  };

  const handleUpdateFacility = async (f: UpdateFacilityInput) => {
    updateFacility({ variables: { input: f } });
  };

  const handleCreateFacility = async (f: CreateFacilityInput) => {
    createFacility({ variables: { input: f } });
  };

  const handleDeleteFacility = async (f: Facility) => {
    deleteFacility({ variables: { id: f.id } });
    setIsDeleteFacilityDialogOpen(false);
    setFacility(null);
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: '1.5rem !important',
            }}
          >
            {t('modules.settings.facility.title')}
          </Typography>
          <Button variant="contained" size="small" onClick={() => handleCreateFacilityClick()}>
            {' '}
            {t('modules.settings.facility.addFacility')}
          </Button>
        </Box>
        <TableContainer>
          <ManageFacilityTable
            data={facilities}
            onEditFacilityClick={handleEditFacilityClick}
            onDeleteFacilityClick={handleDeleteFacilityClick}
          />
        </TableContainer>
      </Box>
      <ManageFacilityForm
        isOpen={isFacilityFormOpen}
        onClose={handleClose}
        isEditMode={isEditFacilityMode}
        facility={facility as Facility}
        onUpdate={handleUpdateFacility}
        onCreate={handleCreateFacility}
        loading={createFacilityLoading || updateFacilityLoading}
      />
      <DeleteObjectDialog
        isOpen={isDeleteFacilityDialogOpen}
        isLoading={deleteFacilityLoading}
        onClose={() => setIsDeleteFacilityDialogOpen(false)}
        onDelete={() => handleDeleteFacility(facility as Facility)}
        deleteConfirmation={
          t('modules.settings.deleteFacility.confirmation') +
          facility?.name! +
          t('modules.settings.deleteFacility.confirmationPartTwo')
        }
        info={t('modules.settings.deleteFacility.info')}
      />
    </>
  );
};

export default ManageFacility;
