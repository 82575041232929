import { ChipColorVariants } from 'src/modules/common/types';

export const getChipColor = (progress: number) => {
  if (progress < 0) {
    return ChipColorVariants.RED;
  } else if (progress > 0) {
    return ChipColorVariants.LIGHT_GREEN;
  }
  return ChipColorVariants.ALMOND;
};
