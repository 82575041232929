import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Link } from '@mui/material';
import { CardLayout } from 'src/modules/common/components';
import { AppIcon } from 'src/modules/common/components/icons';
import theme from 'src/styles/theme';

const PublicErrorCard = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Add this to make the box fill the screen
      }}
    >
      <CardLayout height="flex-grow">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <AppIcon />
          <Typography fontSize={'1.5rem'} sx={{ mt: 2 }}>
            {t('modules.dataManagement.publicErrorCard.title1')}
          </Typography>
          <Typography textAlign="center" fontSize={'1.5rem'}>
            {t('modules.dataManagement.publicErrorCard.title2')}
          </Typography>
          <Typography
            fontSize={'1rem'}
            fontWeight={500}
            textAlign="center"
            color={theme.palette.colors.lightGrey}
            sx={{ mt: 2 }}
          >
            {t('modules.dataManagement.publicErrorCard.description1')}
          </Typography>
          <Typography
            fontSize={'1rem'}
            fontWeight={500}
            textAlign="center"
            color={theme.palette.colors.lightGrey}
            sx={{ mt: 2 }}
          >
            {t('modules.dataManagement.publicErrorCard.description2')}
            <Link href="mailto:support@mavue.io">support@mavue.io</Link>
            {t('modules.dataManagement.publicErrorCard.description3')}
          </Typography>
        </Box>
      </CardLayout>
    </Box>
  );
};

export default PublicErrorCard;
