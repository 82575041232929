import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, Typography } from '@mui/material';
import Button from 'src/modules/common/components/Button/Button';

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onDelete: () => void;
  deleteConfirmation: string;
  info: string;
}

const DeleteObjectDialog = ({
  isOpen,
  isLoading,
  onClose,
  onDelete,
  deleteConfirmation,
  info,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen}>
      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: '25px' }}>
        <Typography variant="h5">{t('shared.components.deleteObjectDialog.title')}</Typography>
        <Typography variant="body2">{deleteConfirmation}</Typography>
        <Typography variant="body2">{info}</Typography>

        <Box display="flex" justifyContent="flex-end">
          <Button variant="outlined" onClick={onClose}>
            {t('shared.components.deleteObjectDialog.cancel')}
          </Button>
          <Button variant="contained" loading={isLoading} onClick={onDelete} sx={{ ml: 2 }}>
            {t('shared.components.deleteObjectDialog.confirm')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DeleteObjectDialog;
