import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { UpdateUserInput, User } from 'src/modules/common/types';
import { GET_USER_SETTING_DETAILS } from 'src/services/graphql/queries';
import { useEnhancedQuery, useMessages, useUserData } from 'src/modules/common/application';
import { Loader } from 'src/modules/common/components';
import EditPersonalDetailsForm from 'src/modules/settings/components/UserInfo/EditPersonalDetailsForm';
import useUserSettings from 'src/modules/common/application/use-user-settings';
import OnboardingLayout from 'src/modules/common/components/Structure/OnboardingLayout';

const UserInfo = () => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();
  const { hasCompanyOnboarded } = useUserData();
  const navigate = useNavigate();
  // TOOD remove extra GET_USER_SETTING_DETAILS. data already exists in GET_USER_LOGIN_DETAILS
  const { loading, data, error } = useEnhancedQuery<{ user: User }>(GET_USER_SETTING_DETAILS);

  const onUpdateCompleted = () => {
    !hasCompanyOnboarded ? navigate('/company-info') : navigate('/');
  };
  const { updateUser, loading: updateUserLoading } = useUserSettings(onUpdateCompleted);

  const handleInformationSubmit = async (data: UpdateUserInput) => {
    await updateUser({
      firstName: data.firstName,
      lastName: data.lastName,
      department: data.department || undefined,
      position: data.position || undefined,
    } as UpdateUserInput);
  };

  const onBack = () => {
    navigate('/onboarding-start');
  };

  if (loading || !data) return <Loader />;
  if (error) return <>{setErrorMessage(t('messages.getUserInfo.error'))}</>;

  return (
    <OnboardingLayout height="content-fit">
      <EditPersonalDetailsForm
        data={data?.user}
        onInformationSubmit={handleInformationSubmit}
        vertical
        isOnboarding={!hasCompanyOnboarded}
        onBack={onBack}
        loading={updateUserLoading}
      />
    </OnboardingLayout>
  );
};

export default UserInfo;
