import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { EsgValue, Modes, UpdateRequstedEsgValueInput } from 'src/modules/common/types';
import { getModifiedesgValue } from 'src/modules/common/application/esgValue';

import EsgDataInputTable from '../EsgDataInputTable/EsgDataInputTable';
import useEsgKpi from 'src/modules/common/application/use-kpi';

interface PublicEsgDataInputProps {
  handleUpdateRequestedEsgValues: (value: UpdateRequstedEsgValueInput[]) => void;
  requestedEsgValues: EsgValue[];
  updateRequestedEsgValuesLoading: boolean;
  accessToken?: string;
}

const PublicEsgDataInput = ({
  handleUpdateRequestedEsgValues,
  requestedEsgValues,
  updateRequestedEsgValuesLoading,
  accessToken,
}: PublicEsgDataInputProps) => {
  const [esgValueByCategory, setEsgValueByCategory] = useState<EsgValue[] | undefined>(
    requestedEsgValues
  );

  const { getKpisByCategory } = useEsgKpi(accessToken);
  const kpis = getKpisByCategory(requestedEsgValues[0]?.kpi?.category?.id);

  useEffect(() => {
    setEsgValueByCategory(requestedEsgValues);
  }, [requestedEsgValues]);

  const handleTableRowDataChange = ({
    id,
    field,
    value,
  }: {
    id: string;
    field: string;
    value: string;
  }) => {
    if (esgValueByCategory) {
      const newesgValueByCategory = esgValueByCategory.map(esgValue => {
        if (esgValue.id === id) {
          if (field === 'value') {
            return {
              ...esgValue,
              [field]: parseFloat(value),
            };
          }
          return {
            ...esgValue,
            [field]: value,
          };
        }
        return esgValue;
      });
      setEsgValueByCategory(newesgValueByCategory);
    }
  };

  const handleSaveClick = () => {
    const modifiedesgValue = getModifiedesgValue(esgValueByCategory || [], requestedEsgValues);

    const requstedEsgValueInput: UpdateRequstedEsgValueInput[] = modifiedesgValue.map(esgValue => {
      return {
        id: esgValue.id,
        selectedUnit: esgValue.selectedUnit
          ? esgValue.selectedUnit
          : esgValue.kpi.units && esgValue.kpi.units.length > 0
          ? esgValue.kpi.units[0]
          : null,
        value: esgValue.value,
      };
    });

    handleUpdateRequestedEsgValues(requstedEsgValueInput);
  };
  return (
    <Box sx={{ position: 'relative', mb: 5 }}>
      <EsgDataInputTable
        data={esgValueByCategory || []}
        onTableRowDataChange={handleTableRowDataChange}
        handleSaveClick={handleSaveClick}
        mode={Modes.PUBLIC}
        loading={updateRequestedEsgValuesLoading}
        kpis={kpis}
      />
    </Box>
  );
};

export default PublicEsgDataInput;
