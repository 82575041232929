import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ApexOptions } from 'apexcharts';

import { Kpi } from 'src/modules/common/types';
import theme from 'src/styles/theme';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import EnhancedChart from 'src/modules/common/components/EnhancedChart';

interface IAgeStructureProps {
  totalNumberOfEmployeesUnder30: number;
  totalNumberOfEmployees30To50: number;
  totalNumberOfEmployeesOver50: number;
}

const AgeStructure = ({
  totalNumberOfEmployees30To50,
  totalNumberOfEmployeesOver50,
  totalNumberOfEmployeesUnder30,
}: IAgeStructureProps) => {
  const { t } = useTranslation();

  const categories = [
    t(
      `modules.analysis.ageStructure.${removeUnderscoreToCamelCase(Kpi.NUMBER_EMPLOYEES_UNDER_30)}`
    ),
    t(`modules.analysis.ageStructure.${removeUnderscoreToCamelCase(Kpi.NUMBER_EMPLOYEES_30_50)}`),
    t(`modules.analysis.ageStructure.${removeUnderscoreToCamelCase(Kpi.NUMBER_EMPLOYEES_OVER_50)}`),
  ];

  const numberEmployees = [
    totalNumberOfEmployeesUnder30,
    totalNumberOfEmployees30To50,
    totalNumberOfEmployeesOver50,
  ];

  const chartOptions: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    colors: [
      theme.palette.colors.casper,
      theme.palette.colors.greyChateau,
      theme.palette.colors.regentGrey,
      theme.palette.colors.cascade,
      theme.palette.colors.summerGreen,
      theme.palette.colors.pearlAqua,
    ],
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      markers: {
        radius: 10,
      },
    },
  };

  const chartSeries = [
    {
      name: t('modules.analysis.ageStructure.amount'),
      data: numberEmployees,
    },
  ];

  return (
    <EnhancedChart
      cardProps={{
        title: t('modules.analysis.ageStructure.title'),
        infoText: t('modules.analysis.ageStructure.info'),
      }}
      chartProps={{
        height: '95%',
        options: chartOptions,
        series: chartSeries,
        type: 'bar',
      }}
      noData={numberEmployees.length === 0}
    />
  );
};

export default AgeStructure;
