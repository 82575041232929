import React from 'react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Grid,
  FormControl,
  FormHelperText,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from '@mui/material';
import {
  getErrorLabelStyles,
  getInputLabelStyles,
} from 'src/modules/common/application/form-styles';
import { CountryCode, CreateFacilityInput, FacilityType } from 'src/modules/common/types/graphql';
import Button from 'src/modules/common/components/Button/Button';
import { ArrowForward } from '@mui/icons-material';
import theme from 'src/styles/theme';
import { getAddFacilitySchema } from 'src/modules/common/application/welcome.schema';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import { useCountrySelectOptions } from 'src/modules/common/application/use-country-select-options';
import { CountryType } from 'src/modules/common/types/countryCode';

interface Props {
  loading: boolean;
  onInformationSubmit: (values: CreateFacilityInput) => void;
  onBack?: () => void;
}

const AddFacilityForm = ({ onInformationSubmit, onBack, loading }: Props) => {
  const { t } = useTranslation();

  const initalFormState = {
    name: '',
    type: '',
    city: '',
    country: { code: CountryCode.DE, label: t(`shared.types.countryCode.${CountryCode.DE}`) },
  };

  const countries = useCountrySelectOptions();
  const facilitySchema = getAddFacilitySchema(t);

  const handleSubmitClick = (values: any) => {
    const createFacilityInput = {
      name: values.name,
      type: values.type,
      city: values.city,
      country: values.country.code,
    } as CreateFacilityInput;
    onInformationSubmit(createFacilityInput);
  };

  return (
    <div>
      <Formik
        initialValues={initalFormState}
        enableReinitialize={true}
        validationSchema={facilitySchema}
        onSubmit={values => {
          handleSubmitClick(values);
        }}
        onReset={values => {
          values = initalFormState;
        }}
      >
        {({ submitForm, handleChange, setFieldValue, errors, values }) => (
          <Form>
            <Grid container rowSpacing={1} sx={{ p: 0, mt: 2.5 }}>
              {/* name */}
              <Grid item xs={12} mb={2}>
                <FormControl fullWidth>
                  <TextField
                    id="name"
                    name="name"
                    type="text"
                    label={t('shared.types.facility.name')}
                    value={values.name ? values.name : ''}
                    onChange={handleChange}
                    fullWidth
                    placeholder={t('shared.types.facility.name')}
                    required
                    variant="outlined"
                  />
                  {errors.name && (
                    <FormHelperText sx={getErrorLabelStyles}>{errors.name}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* type */}
              <Grid item xs={12} mb={2}>
                <FormControl fullWidth required>
                  <InputLabel htmlFor={'type'}>
                    {t('modules.companyOnboarding.facility.type')}
                  </InputLabel>
                  <Select
                    id="type-select"
                    value={values.type ? values.type : ''}
                    name="type"
                    onChange={handleChange}
                    label={t('modules.companyOnboarding.facility.type')}
                    variant="outlined"
                  >
                    {Object.values(FacilityType).map(type => {
                      return (
                        <MenuItem key={type} value={type}>
                          {t('shared.types.facilityType.' + removeUnderscoreToCamelCase(type))}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errors.type && (
                    <FormHelperText sx={getErrorLabelStyles}>
                      {errors.type as string}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* city */}
              <Grid item xs={12} mb={2}>
                <FormControl fullWidth>
                  <TextField
                    id="city"
                    name="city"
                    type="text"
                    label={t('shared.types.facility.city')}
                    value={values.city ? values.city : ''}
                    onChange={handleChange}
                    fullWidth
                    placeholder={t('shared.types.facility.city')}
                    required
                    variant="outlined"
                  />
                  {errors.city && (
                    <FormHelperText sx={getErrorLabelStyles}>{errors.city}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} marginBottom={2}>
                <FormControl fullWidth>
                  <InputLabel shrink required htmlFor="country" sx={getInputLabelStyles}>
                    {t('modules.settings.facility.country')}
                  </InputLabel>
                  <Field
                    as={Autocomplete}
                    id="country"
                    name="country"
                    options={countries}
                    getOptionLabel={(option: CountryType) => option.label}
                    isOptionEqualToValue={(option: any, value: any) => option.code === value.code}
                    onChange={(e: any, value: any) => {
                      setFieldValue('country', value);
                    }}
                    values={values.country ? values.country : ''}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        name="country"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                  {errors.country && (
                    <FormHelperText sx={getErrorLabelStyles}>
                      {t('fieldWarnings.requiredWarning')}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* buttons */}
              <Grid
                item
                xs={12}
                mb={0}
                sx={{
                  mt: 2.5,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Button
                    onClick={onBack}
                    variant="outlined"
                    sx={{
                      fontSize: '14px',
                      pl: 2,
                      pr: 2,
                    }}
                  >
                    {t('modules.companyOnboarding.back')}
                  </Button>
                </Box>
                <Button
                  role="button"
                  aria-label="primary-button"
                  sx={{
                    padding: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={submitForm}
                  loading={loading}
                >
                  {t('modules.companyOnboarding.finish')}
                  <ArrowForward
                    sx={{
                      color: theme.palette.colors.white,
                      pl: 1,
                      width: 32,
                      height: 32,
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddFacilityForm;
