import React from 'react';
import { useTheme } from '@mui/material';

const CompanyInfo = (props: any) => {
  const theme = useTheme();
  return (
    <svg
      width="14"
      height="24"
      viewBox="0 0 14 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.31795 16.7104C5.31795 16.3739 5.54225 16.1496 5.87869 16.1496H8.12165C8.4581 16.1496 8.68239 16.3739 8.68239 16.7104V24H13.1683V12.3366L9.91602 9.02824C6.88802 6.00024 2.68215 1.73861 0.832031 0V23.9997H5.31795V16.7104ZM5.87869 10.5422H8.12165C8.4581 10.5422 8.68239 10.7665 8.68239 11.103C8.68239 11.4394 8.4581 11.6637 8.12165 11.6637H5.87869C5.54225 11.6637 5.31795 11.4394 5.31795 11.103C5.31795 10.7665 5.54225 10.5422 5.87869 10.5422ZM5.87869 12.2245H8.12165C8.4581 12.2245 8.68239 12.4488 8.68239 12.7852C8.68239 13.1216 8.4581 13.3459 8.12165 13.3459H5.87869C5.54225 13.3459 5.31795 13.1216 5.31795 12.7852C5.31795 12.4488 5.54225 12.2245 5.87869 12.2245Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};

export default CompanyInfo;
