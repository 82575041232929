import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface IYearSelectProps {
  years: number[];
  year: number;
  setYear: (year: number) => void;
  disabled?: boolean;
}

const YearSelect = ({ years, year, setYear, disabled }: IYearSelectProps) => {
  return (
    <Select
      sx={{
        '&.MuiInputBase-root': {
          width: '100%',
          height: 32,
          color: 'colors.lightGrey',
        },
      }}
      id="year-select"
      name="year"
      value={String(year)}
      disabled={disabled}
      IconComponent={KeyboardArrowDownIcon}
      onChange={(e: SelectChangeEvent) => setYear(parseInt(e.target.value))}
    >
      {years.map(year => (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      ))}
    </Select>
  );
};

export default YearSelect;
