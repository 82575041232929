import { gql } from '@apollo/client';

export const CHANGE_COLLECT_DUE_DATE = gql`
  mutation ChangeCollectDueDate($collectOverviewIds: [UUID!]!, $dueDate: DateTime) {
    changeCollectDueDate(collectOverviewIds: $collectOverviewIds, dueDate: $dueDate)
  }
`;

export const COLLECT_ASSIGN_DATA_OWNER = gql`
  mutation AssignCollect(
    $assignments: [AssignCollectInput!]!
    $notify: Boolean!
    $message: String
  ) {
    assignCollect(assignments: $assignments, sendMail: $notify, message: $message)
  }
`;

export const SEND_REMINDER = gql`
  mutation Mutation($input: ManualReminderInput!) {
    sendReminder(input: $input)
  }
`;

export const UPDATE_COLLECT_FILTER = gql`
  mutation UpdateCollectFilter($filter: CollectFilterInput!) {
    updateCollectFilter(filter: $filter) {
      assignedTo
      categories
      facilities
      statuses
      rowsPerPageCount
      orderField
      orderDirection
      userId
      year
    }
  }
`;

export const CALCULATE_COLLECT_OVERVIEW_PROGRESS_STATUS = gql`
  mutation CalculateCollectOverviewProgressStatus($companyId: UUID!) {
    calculateCollectOverviewProgressStatus(companyId: $companyId)
  }
`;
