import { gql } from '@apollo/client';

export const GET_YEARLY_ENVIRONMENTAL_STATISTICS = gql`
  query getYearlyEnvironmentalStatistics($year: Int!) {
    getYearlyEnvironmentalStatistics(year: $year) {
      directEmissions
      emissionIntensity
      environmentalEsgProgress
      facilityIndirectConsumption {
        Energy
        Facility {
          id
          name
        }
        Gas
      }
      indirectEmissions
      totalNonRenewableEnergy
      totalRenewableEnergy
      waterConsumption {
        Emission
        Facility {
          id
          name
        }
      }
    }
  }
`;

export const GET_YEARLY_SOCIAL_STATISTICS = gql`
  query getYearlySocialStatistics($year: Int!) {
    getYearlySocialStatistics(year: $year) {
      employeeTurnoverRate
      numberOccupationalAccidents
      percentageFemaleEmployees
      totalFemaleManagerEmployees
      totalFemaleNonManagerEmployees
      totalMaleManagerEmployees
      totalMaleNonManagerEmployees
      totalNumberOfEmployees
      totalNumberOfEmployees30To50
      totalNumberOfEmployeesOver50
      totalNumberOfEmployeesUnder30
      trainingHoursPerEmployee
      facilityGenders {
        facility {
          id
          name
        }
        femaleEmployees
        maleEmployees
      }
    }
  }
`;

export const GET_NEXT_CALCULATION_TIME = gql`
  query GetNextCalculationTime {
    getNextCalculationTime
  }
`;

export const GET_ESG_VALUES_AGG_BY_REPORT_SECTION = gql`
  query esgValuesAggByReportSection($reportSectionId: UUID!, $year: Int!) {
    esgValuesAggByReportSection(reportSectionId: $reportSectionId, year: $year) {
      kpi {
        category {
          area
          id
          title
        }
        description
        id
        source
        title
        units
      }
      selectedUnit
      value
    }
  }
`;

export const GET_DATA_MANAGEMENT_OVERVIEW_STATS = gql`
  query DataManagementOverviewStats($year: Int!) {
    numberRequestedEsgValues(year: $year)
    openDataRequests(year: $year) {
      category {
        id
        title
        area
      }
      facility {
        id
        city
      }
      collectOverviewId
      status
      requestedBy {
        lastName
        firstName
        email
      }
      year
    }
    getYearlyGeneralStatistics(year: $year) {
      overallEsgProgress
      numberOfCompletedEsgValues
      esgProgressByCategory {
        progress
        category {
          title
          id
          area
        }
      }
      completedFacilities {
        completedFacilities
        totalFacilities
      }
    }
  }
`;

export const AI_GENERATED_REPORT = gql`
  query aIGeneratedReport($reportSectionId: UUID!, $reportId: UUID!, $language: LanguageCode!) {
    aIGeneratedReport(reportId: $reportId, reportSectionId: $reportSectionId, language: $language)
  }
`;

export const GET_ROADMAP_STATS = gql`
  query GetRoadmapStatistics($year: Int!) {
    getRoadmapStatistics(year: $year) {
      dataCollection
      materialityAssessment
      reportWriting
    }
  }
`;
