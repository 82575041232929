import { BreadcrumbData } from 'src/modules/common/components/Base/AppBreadcrumb/utils';

export const reportBreadCrumbs = (reportTitle: string) => {
  return [
    {
      name: 'modules.reporting.breadcrumbs.firstBreadcrumb',
      to: '/reports',
    },
    {
      name: reportTitle,
    },
  ] as BreadcrumbData[];
};
