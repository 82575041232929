import { ChipColorVariants } from 'src/modules/common/types';
import { UserRole } from 'src/modules/common/types/graphql';

export const getRoleChipColor = (userRole: UserRole) => {
  switch (userRole) {
    case UserRole.ADMIN:
      return ChipColorVariants.ALMOND;
    case UserRole.LEAD:
      return ChipColorVariants.LIGHT_GREEN;
    case UserRole.EMPLOYEE:
      return ChipColorVariants.WATER;
    case UserRole.EXTERNAL:
      return ChipColorVariants.GRAY;
    default:
      return ChipColorVariants.WATER;
  }
};
