import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AiReportSimilarity, Report } from 'src/modules/common/types';
import { GET_REPORTS_BY_COMPANY_ID } from 'src/services/graphql/queries';
import { useEnhancedMutation, useEnhancedQuery, useMessages } from 'src/modules/common/application';
import {
  CALCULATE_AI_REPORT_SIMILARITY,
  GENERATE_AI_REPORT_BY_REPORT_ID,
} from 'src/services/graphql/mutations';
import useCsvDataExport from 'src/modules/common/application/use-csv-data-export';

const useReportSuper = ({ companyId }: { companyId: string }) => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { t } = useTranslation();

  const { superAdminCalculateSimilarity } = useCsvDataExport();

  const { data } = useEnhancedQuery<{ reportsByCompanyId: Report[] }>(GET_REPORTS_BY_COMPANY_ID, {
    variables: {
      companyId,
    },
    onError: () => {
      setErrorMessage(t('messages.getReports.error'));
    },
  });

  const [generateReport, { loading }] = useEnhancedMutation(GENERATE_AI_REPORT_BY_REPORT_ID, {
    onCompleted: () => {
      setSuccessMessage('The reports were created successfully');
    },
    onError: () => {
      setErrorMessage(t('messages.generateReports.error'));
    },
  });

  const [calculateAiReportSimilarity, { loading: calculateSimilarityLoading }] =
    useEnhancedMutation<{
      calculateAiReportSimilarity: AiReportSimilarity[];
    }>(CALCULATE_AI_REPORT_SIMILARITY, {
      onError: () => {
        setErrorMessage(t('messages.getReports.error'));
      },
    });

  const reports = data ? data.reportsByCompanyId : undefined;

  const [report, setReport] = useState<Report | undefined>(undefined);

  const handleSelectReport = (id: string) => {
    setReport(reports?.find(report => report.id === id));
  };

  const handleGenerateAiReports = () => {
    if (report && report.id) {
      generateReport({
        variables: {
          reportId: report.id,
          companyId,
        },
      });
    }
  };

  const handleCalculateAiReportSimilarity = async () => {
    if (report && report.id) {
      const { data } = await calculateAiReportSimilarity({
        variables: {
          reportId: report.id,
          companyId,
        },
      });
      if (data?.calculateAiReportSimilarity) {
        superAdminCalculateSimilarity(data?.calculateAiReportSimilarity);
      } else {
        setErrorMessage('No report data found to compare to AI');
      }
    }
  };

  return {
    reports,
    report,
    generateReportLoading: loading,
    calculateSimilarityLoading,
    handleSelectReport,
    handleGenerateAiReports,
    handleCalculateAiReportSimilarity,
  };
};

export default useReportSuper;
