import { GET_INITIAL_STATE } from '../queries';

const userResolvers = {
  Query: {},
  Mutation: {
    storeUserEmail: async (
      _: void,
      { email }: { email: string },
      // eslint-disable-next-line
      { client }: any
    ) => {
      const previousState = client.readQuery({
        query: GET_INITIAL_STATE,
      });

      client.writeQuery({
        query: GET_INITIAL_STATE,
        data: {
          userEmail: email,
          ...previousState,
        },
      });
    },
  },
};

export default userResolvers;
