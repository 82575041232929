import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ApexOptions } from 'apexcharts';
import theme from 'src/styles/theme';
import EnhancedChart from 'src/modules/common/components/EnhancedChart';

interface IEnergyConsumptionProps {
  renewable: number;
  nonRenewable: number;
  unkown: number;
}

const EnergyConsumption = ({ renewable, nonRenewable, unkown }: IEnergyConsumptionProps) => {
  const { t } = useTranslation();

  const chartOptions: ApexOptions = {
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: [
      t('modules.analysis.energyConsumption.renewable'),
      t('modules.analysis.energyConsumption.nonRenewable'),
      t('modules.analysis.energyConsumption.unknown'),
    ],
    colors: [theme.palette.colors.cascade, theme.palette.colors.opal, theme.palette.colors.nebula],
    responsive: [
      {
        breakpoint: 1260,
        options: {
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 900,
        options: {
          legend: {
            position: 'right',
          },
        },
      },
      {
        breakpoint: 600,
        options: {
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return val + ' kWh';
        },
      },
    },
  };

  const chartSeries = [renewable, nonRenewable, unkown];
  return (
    <EnhancedChart
      cardProps={{
        title: t('modules.analysis.energyConsumption.title'),
        infoText: t('modules.analysis.energyConsumption.info'),
      }}
      chartProps={{
        height: '90%',
        options: chartOptions,
        series: chartSeries,
        type: 'pie',
      }}
      noData={renewable === 0 && nonRenewable === 0 && unkown === 0}
    />
  );
};

export default EnergyConsumption;
