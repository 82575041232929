import {
  MaterialityAssessmentTopic,
  MaterialityEvaluationResult,
  RequestStatus,
  User,
} from 'src/modules/common/types';

export interface ImpactMaterialityPayload {
  impactDescription: string;
  impactScale: number;
  impactScope: number;
  impactIrreversibility: number;
}

export interface FinancialMaterialityPayload {
  financialSignificance: number;
  financialProbability: number;
  financialDescription: string;
}

export enum MaterialityType {
  Financial = 'financial',
  Impact = 'impact',
}

export type StakeholderResult = { stakeholder: User; response: MaterialityEvaluationResult | null };

export type StakeholderStatusResult = StakeholderResult & { status: RequestStatus };

export type MaterialityAssessmentTopicWithDisabled = MaterialityAssessmentTopic & {
  disabled: boolean;
};

export type EvaluationTableTopics = MaterialityAssessmentTopic & {
  totalRequests: number;
  completedRequests: number;
};
