import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { useMessages, useYears } from 'src/modules/common/application';
import { CalculationStatus, Loader, TabBar, YearSelect } from 'src/modules/common/components';

import CollectData from '../CollectData/CollectData';

import OverviewTab from '../DataOverviewTab/DataOverviewTab';
import { getActiveTabIndex } from './utils';

const DataManagement = () => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();
  const location = useLocation();
  const [disabledYearSelect, setDisabledYearSelect] = useState(false);

  const { data, loading, error, selectedYear, handleSetSelectedYear, sortedYears } = useYears();

  if (loading || !data) return <Loader />;
  if (error) return <>{setErrorMessage(t('messages.generic.error'))}</>;

  const years = sortedYears ? sortedYears : [];

  const handleTableChange = (edit: boolean) => {
    setDisabledYearSelect(edit);
  };

  const TABS = [
    {
      input: <OverviewTab year={selectedYear} />,
      label: t('modules.dataManagement.overviewTab'),
      index: 0,
      id: 'data-management-one',
      to: `/data-management`,
    },
    {
      input: <CollectData year={selectedYear} handleTableChange={handleTableChange} />,
      label: t('modules.dataManagement.collectDataTab'),
      index: 1,
      id: 'data-management-two',
      to: '/data-management/collect',
    },
  ];

  return (
    <React.Fragment>
      <TabBar
        activeTabIndex={getActiveTabIndex(location.pathname)}
        content={TABS}
        SideComponent={() => (
          <Box
            sx={{
              display: 'flex',
              right: 0,
            }}
          >
            <CalculationStatus />
            <YearSelect
              years={years}
              year={selectedYear}
              setYear={handleSetSelectedYear}
              disabled={disabledYearSelect}
            />
          </Box>
        )}
        sideComponentStyles={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      />
    </React.Fragment>
  );
};

export default DataManagement;
