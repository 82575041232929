import React from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CreateMARequestForm from '../CreateMARequestForm/CreateMARequestForm';

import { useMessages } from 'src/modules/common/application';
import { Loader } from 'src/modules/common/components';

import { MaterialityEvaluationTable } from '../MaterialityEvaluationTable/MaterialityEvaluationTable';
import useMaterialityAssessment from '../../application/use-materiality-assessment';
import MaterialityEvaluationOverviewStats from '../MaterialityEvaluationOverviewStats/MaterialityEvaluationOverviewStats';

interface MaterialityAssessmentTopicEvaluationProps {
  year: number;
  isRequestFormOpen: boolean;
  handleOnRequestFormClose: () => void;
}

const MaterialityAssessmentTopicEvaluation = (props: MaterialityAssessmentTopicEvaluationProps) => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();
  const { year, isRequestFormOpen, handleOnRequestFormClose } = props;

  const {
    activeMaterialTopics,
    evaluationTableTopics,
    loading,
    error,
    evaluationOverviewStats,
    topicStats,
  } = useMaterialityAssessment(year);

  if (loading || !evaluationOverviewStats) return <Loader />;
  if (error) return <>{setErrorMessage(t('messages.generic.error'))}</>;

  return (
    <Box sx={{ marginTop: -3 }}>
      <Grid container spacing={4} sx={{ paddingTop: '0px !important', marginTop: 0 }}>
        <Grid item md={12} xs={12} sx={{ paddingTop: '0px !important' }}>
          <MaterialityEvaluationOverviewStats
            stats={evaluationOverviewStats}
            topicStats={topicStats}
          />
        </Grid>
        <Grid item md={12} xs={12} sx={{ paddingTop: '24px !important' }}>
          {evaluationTableTopics && evaluationTableTopics?.length > 0 && (
            <MaterialityEvaluationTable topics={evaluationTableTopics} />
          )}
        </Grid>
      </Grid>
      <CreateMARequestForm
        isOpen={isRequestFormOpen}
        onClose={handleOnRequestFormClose}
        year={year}
        isPreSelection={false}
        preSelectedTopics={activeMaterialTopics}
      />
    </Box>
  );
};

export default MaterialityAssessmentTopicEvaluation;
