import { ApolloCache, ApolloClient } from '@apollo/client';

import { LanguageCode, ReportInput, ReportSection } from 'src/modules/common/types';
import { GET_REPORT_SECTIONS } from 'src/services/graphql/queries';

export const rewriteExistedReportInputCache = (
  reportId: string,
  reportSectionId: string,
  language: LanguageCode,
  payload: {
    input?: string;
    isGenerating?: boolean;
    isMarkedCompleted?: boolean;
  },
  client: ApolloCache<any> | ApolloClient<object>,
  existedCache?: ReportInput
) => {
  const { reportSections } = client.readQuery<{
    reportSections: ReportSection[];
  }>({
    query: GET_REPORT_SECTIONS,
    variables: {
      language: language,
      reportId: reportId,
    },
  }) || { reportSections: [] };

  const existedReportSection = reportSections.find(
    reportSection => reportSection.id === reportSectionId
  );

  if (existedReportSection) {
    client.writeQuery({
      query: GET_REPORT_SECTIONS,
      variables: {
        language: language,
        reportId: reportId,
      },
      data: {
        reportSections: reportSections.map(reportSection => {
          if (reportSection.id === reportSectionId) {
            return {
              ...reportSection,
              reportInput: existedCache
                ? { ...existedCache }
                : {
                    ...reportSection.reportInput,
                    ...payload,
                  },
            };
          }
          return reportSection;
        }),
      },
    });
  }
};
