import React from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Grid,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
  InputLabel,
  Box,
} from '@mui/material';
import {
  getInputLabelStyles,
  getErrorLabelStyles,
} from 'src/modules/common/application/form-styles';
import { UpdateUserInput } from 'src/modules/common/types/graphql';
import Button from 'src/modules/common/components/Button/Button';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import { ArrowForward } from '@mui/icons-material';
import theme from 'src/styles/theme';
import { getUserInfoSchema } from 'src/modules/common/application/welcome.schema';
import { UserInfoData } from './types';
import {
  SORTED_USER_DEPARTMENTS,
  SORTED_USER_POSITIONS,
} from 'src/modules/common/types/constants/sorted-enums';

interface Props {
  data: UserInfoData;
  onInformationSubmit: (data: UpdateUserInput) => void;
  onReset?: () => void;
  disabled?: boolean;
  showCancel?: boolean;
  vertical?: boolean;
  isOnboarding?: boolean;
  onBack?: () => void;
  loading: boolean;
}

const EditPersonalDetailsForm = ({
  data,
  onInformationSubmit,
  disabled,
  onReset,
  showCancel,
  vertical,
  isOnboarding,
  onBack,
  loading,
}: Props) => {
  const { t } = useTranslation();
  const initalFormState = data;
  const userSchema = getUserInfoSchema(t);

  return (
    <div>
      <Formik
        initialValues={{
          ...initalFormState,
        }}
        enableReinitialize={true}
        validationSchema={userSchema}
        onSubmit={values => {
          onInformationSubmit(values);
        }}
        onReset={values => {
          values = initalFormState;
          if (onReset !== undefined) onReset();
        }}
      >
        {({ submitForm, handleChange, handleReset, errors, values }) => (
          <Form>
            <Grid
              container
              item
              sx={{
                padding: vertical ? '0 !important' : 2.5,
                paddingTop: vertical ? 0 : 7,
                display: 'grid',
                rowGap: vertical ? 3 : 5,
                columnGap: vertical ? 0 : 3,
                gridTemplateRows: vertical ? 'repeat(6, 1fr)' : 'repeat(3, 1fr)',
                gridTemplateColumns: vertical ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)',
              }}
            >
              {/* firstName */}
              <Box>
                <FormControl fullWidth>
                  {vertical ? null : (
                    <InputLabel htmlFor={values.firstName || ''} sx={getInputLabelStyles}>
                      {t('shared.types.user.firstName')}
                    </InputLabel>
                  )}
                  <TextField
                    id="firstName"
                    name="firstName"
                    type="text"
                    label={vertical ? t('shared.types.user.firstName') : ''}
                    value={values.firstName ? values.firstName : ''}
                    onChange={handleChange}
                    disabled={disabled}
                    fullWidth
                    placeholder={vertical ? t('shared.types.user.firstName') : ''}
                    variant="outlined"
                  />
                  {errors.firstName && (
                    <FormHelperText sx={vertical ? getErrorLabelStyles : {}}>
                      {errors.firstName}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>

              {/* lastName */}
              <Box>
                <FormControl fullWidth>
                  {vertical ? null : (
                    <InputLabel htmlFor={values.lastName || ''} sx={getInputLabelStyles}>
                      {t('shared.types.user.lastName')}
                    </InputLabel>
                  )}
                  <TextField
                    id="lastName"
                    name="lastName"
                    type="text"
                    value={values.lastName ? values.lastName : ''}
                    label={vertical ? t('shared.types.user.lastName') : ''}
                    variant="outlined"
                    onChange={handleChange}
                    disabled={disabled}
                    fullWidth
                    placeholder={vertical ? t('shared.types.user.lastName') : ''}
                  />
                  {errors.lastName && (
                    <FormHelperText sx={vertical ? getErrorLabelStyles : {}}>
                      {errors.lastName}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>

              {/* email */}
              <Box
                style={
                  vertical
                    ? {
                        gridRowStart: vertical ? 1 : 3,
                        marginBottom: 2,
                      }
                    : {
                        marginBottom: 0,
                      }
                }
              >
                <FormControl fullWidth>
                  {vertical ? null : (
                    <InputLabel htmlFor={values.email} sx={getInputLabelStyles}>
                      {t('shared.types.user.email')}
                    </InputLabel>
                  )}
                  <TextField
                    id="email"
                    name="email"
                    type="email"
                    value={values.email ? values.email : ''}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    placeholder={vertical ? t('shared.types.user.email') : ''}
                    label={vertical ? t('shared.types.user.email') : ''}
                    required
                    variant="outlined"
                  />
                  {errors.email && (
                    <FormHelperText sx={vertical ? getErrorLabelStyles : {}}>
                      {errors.email}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>

              {/* position */}
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor={'position'} sx={vertical ? {} : getInputLabelStyles}>
                    {t('shared.types.user.position')}
                  </InputLabel>
                  <Select
                    id="position-select"
                    value={values.position ? values.position : ''}
                    name="position"
                    onChange={handleChange}
                    disabled={disabled}
                    label={vertical ? t('shared.types.user.position') : ''}
                    variant="outlined"
                  >
                    {SORTED_USER_POSITIONS.map(d => {
                      return (
                        <MenuItem key={d} value={d}>
                          {t('shared.types.userPosition.' + removeUnderscoreToCamelCase(d))}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errors.position && (
                    <FormHelperText sx={vertical ? getErrorLabelStyles : {}}>
                      {errors.position}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>

              {/* department */}
              <Box>
                <FormControl fullWidth>
                  <InputLabel htmlFor={'department'} sx={vertical ? {} : getInputLabelStyles}>
                    {t('shared.types.user.department')}
                  </InputLabel>
                  <Select
                    id="department-select"
                    name="department"
                    value={values.department ? values.department : ''}
                    onChange={handleChange}
                    disabled={disabled}
                    label={vertical ? t('shared.types.user.department') : ''}
                    variant="outlined"
                  >
                    {SORTED_USER_DEPARTMENTS.map(d => {
                      return (
                        <MenuItem key={d} value={d}>
                          {t('shared.types.userDepartment.' + removeUnderscoreToCamelCase(d))}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errors.department && (
                    <FormHelperText sx={vertical ? getErrorLabelStyles : {}}>
                      {errors.department}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>

              {!disabled && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: isOnboarding
                      ? 'space-between'
                      : vertical
                      ? 'flex-end'
                      : 'flex-start',
                    '@media (max-width: 600px)': {
                      flexDirection: 'column',
                    },
                    gridColumnStart: 1,
                  }}
                >
                  {isOnboarding && (
                    <Box>
                      <Button
                        onClick={onBack}
                        variant="outlined"
                        sx={{
                          fontSize: '14px',
                          pl: 2,
                          pr: 2,
                        }}
                      >
                        {t('modules.companyOnboarding.back')}
                      </Button>
                    </Box>
                  )}
                  <Box>
                    {isOnboarding ? (
                      <Button
                        role="button"
                        aria-label="primary-button"
                        sx={{
                          padding: 2,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        onClick={submitForm}
                        loading={loading}
                      >
                        {t('modules.companyOnboarding.next')}
                        <ArrowForward
                          sx={{
                            color: theme.palette.colors.white,
                            pl: 1,
                            width: 32,
                            height: 32,
                          }}
                        />
                      </Button>
                    ) : (
                      <Button
                        onClick={submitForm}
                        loading={loading}
                        sx={{
                          fontSize: '14px',
                          pl: 2,
                          pr: 2,
                        }}
                      >
                        {vertical
                          ? t('modules.settings.editPersonalDetails.save')
                          : t('modules.settings.editPersonalDetails.saveChanges')}
                      </Button>
                    )}
                  </Box>
                  {showCancel && (
                    <Box>
                      <Button
                        onClick={handleReset}
                        variant="outlined"
                        sx={{
                          fontSize: '14px',
                          pl: 2,
                          pr: 2,
                          marginLeft: 3,
                          '@media (max-width: 600px)': {
                            marginLeft: 0,
                            marginTop: 2,
                          },
                        }}
                      >
                        {t('modules.settings.editPersonalDetails.cancel')}
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditPersonalDetailsForm;
