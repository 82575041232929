import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';

import theme from 'src/styles/theme';
import { MaterialityEvaluationResult } from 'src/modules/common/types';

import MaterialityEvaluationCard from '../MaterialityEvaluationCard/MaterialityEvaluationCard';
import StakeholderEvaluationContainer from '../StakeholderEvaluation/StakeholderEvaluationContainer';
import {
  FinancialMaterialityPayload,
  ImpactMaterialityPayload,
  MaterialityType,
  StakeholderStatusResult,
} from '../../types/materialEvaluation';

interface Props {
  evaluationData: MaterialityEvaluationResult | null;
  stakeholderResponseData: StakeholderStatusResult[];
  type: MaterialityType;
  payload: ImpactMaterialityPayload | FinancialMaterialityPayload;
  setPayload: (payload: ImpactMaterialityPayload | FinancialMaterialityPayload) => void;
}

const MaterialityDetail = (props: Props) => {
  const { t } = useTranslation();
  const { evaluationData, stakeholderResponseData, type, setPayload, payload } = props;

  const handlePayloadUpdate = (key: string, value: string | number) => {
    const newPayload = {
      ...payload,
      [key]: value,
    };
    setPayload(newPayload);
  };

  const hasInput = useMemo(() => {
    if (payload && Object.keys(payload).length === 0) return false;

    const payloadConv: MaterialityEvaluationResult = JSON.parse(JSON.stringify(payload));

    if (
      type === MaterialityType.Financial &&
      !(
        payloadConv?.financialDescription === evaluationData?.financialDescription &&
        payloadConv?.financialProbability === evaluationData?.financialProbability &&
        payloadConv?.financialSignificance === evaluationData?.financialSignificance
      )
    ) {
      return true;
    } else if (
      type === MaterialityType.Impact &&
      !(
        payloadConv.impactDescription === evaluationData?.impactDescription &&
        payloadConv.impactIrreversibility === evaluationData?.impactIrreversibility &&
        payloadConv.impactScale === evaluationData?.impactScale &&
        payloadConv.impactScope === evaluationData?.impactScope
      )
    ) {
      return true;
    }
    return false;
  }, [evaluationData, payload, type]);

  return (
    <Box>
      <Box
        sx={{
          background: hasInput ? '#4356ED0D' : '#fff',
          border: '1px solid',
          borderColor: hasInput ? theme.palette.primary.main : theme.palette.background.grey,
          borderRadius: '8px',
          p: 4,
        }}
      >
        <Typography
          fontSize={'1.25rem !important'}
          fontWeight={600}
          sx={{
            marginBottom: 3,
          }}
        >
          {t('modules.materialityAssessment.materialityEvaluationDetail.evaluationCategory')}
        </Typography>
        <MaterialityEvaluationCard
          evaluationData={payload}
          type={type}
          optionsLabel={t(
            type === MaterialityType.Financial
              ? 'modules.materialityAssessment.materialityEvaluationDetail.financialMateriality.evaluateFinancialMateriality'
              : 'modules.materialityAssessment.materialityEvaluationDetail.impactMateriality.evaluateImpactMateriality'
          )}
          handleRadioOptionsUpdate={handlePayloadUpdate}
          handleDescriptionUpdate={(value: string) => {
            handlePayloadUpdate(
              type === MaterialityType.Financial ? 'financialDescription' : 'impactDescription',
              value
            );
          }}
          noteInputLabel={
            <Typography
              sx={{ color: theme.palette.colors.grey }}
              fontSize={'1rem !important'}
              fontWeight={500}
            >
              {t(
                type === MaterialityType.Financial
                  ? 'modules.materialityAssessment.materialityEvaluationDetail.financialMateriality.defineBiggestRiskOpportunity'
                  : 'modules.materialityAssessment.materialityEvaluationDetail.impactMateriality.defineBiggestRisk'
              )}
            </Typography>
          }
        />
      </Box>
      {stakeholderResponseData.length > 0 ? (
        <StakeholderEvaluationContainer
          results={stakeholderResponseData}
          type={type}
          subHeader={t(
            type === MaterialityType.Financial
              ? 'modules.materialityAssessment.materialityEvaluationDetail.financialMateriality.biggestRiskOpportunityNamed'
              : 'modules.materialityAssessment.materialityEvaluationDetail.impactMateriality.biggestRiskNamed'
          )}
        />
      ) : null}
    </Box>
  );
};

export default MaterialityDetail;
