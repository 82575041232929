import React, { Suspense, useState } from 'react';
import { Box } from '@mui/material';

import {
  TransformedCollectOverview,
  MultiSelectSnackbarActionTypes,
  FilterType,
} from 'src/modules/common/types/collect';
import { useCategory, useUserData } from 'src/modules/common/application';
import useAssignedUser from 'src/modules/common/application/use-assigned-user';
import useUser from 'src/modules/common/application/use-user';
import useExternalUser from 'src/modules/common/application/use-external-user';
import useCollectDueDate from 'src/modules/data-management/application/use-collect-due-date';
import { Loader } from 'src/modules/common/components';
import { LanguageCode, SortingOrder } from 'src/modules/common/types';
import { lazyWithRetry } from 'src/modules/common/application/lazyWithRetry';

import { AssignDataOwnerSnackbar } from '../AssignDataOwnerSnackbar/AssignDataOwnerSnackbar';
import { MultiSelectSnackbar } from '../MultiSelectSnackbar/MultiSelectSnackbar';
import ImportFileDialog from '../ImportFileDialog/ImportFileDialog';
import CollectEsgAdminTable from '../CollectEsgAdminTable/CollectEsgAdminTable';
import { DueDateDialog } from '../DueDateDialog/DueDateDialog';
import { DataOverviewComponent } from '../DataOverview/DataOverview';
import { AssignDataOwnerDialog } from '../AssignDataOwnerDialog/AssignDataOwnerDialog';
import { EsgQuickViewDialog } from '../EsgQuickViewDialog/EsgQuickViewDialog';
import CollectDataTableFilter from '../CollectDataTableFilter';
import CollectEsgNonAdminTable from '../CollectEsgNonAdminTable/CollectEsgNonAdminTable';
import InviteExternalUserDialog from '../InviteExternalUserDialog/InviteExternalUserDialog';
import useCollectFilter from '../../application/use-collect-filter';
import useCollectOverview from '../../application/use-collect-overview';
import useCollectSelection from '../../application/use-collect-selection';
import useQuickView from '../../application/use-quick-view';
import useReminderDialog from '../../application/use-reminder-dialog';
import { ReminderDialog } from '../ReminderDialog/ReminderDialog';
import { LoadingStateTracker } from 'src/modules/common/components/LoadingStateTracker/LoadingStateTracker';

const LazyNuvoDialog = lazyWithRetry(
  () => import(/* webpackChunkName: "Importer" */ '../NuvoDialog/NuvoDialog')
);

const CollectData = ({
  year,
  handleTableChange,
}: {
  year: number;
  handleTableChange: (edit: boolean) => void;
}) => {
  const { isAdmin } = useUserData();
  const [open, setOpen] = useState(false);
  const [openNuvo, setOpenNuvo] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [openAssignUserSnackbar, setOpenAssignUserSnackbar] = useState(false);
  const [lazyLoading, setLazyLoading] = useState(false);

  const {
    handleQuickViewDialogOpen,
    handleQuickViewDialogClose,
    setAnchorEl,
    openQuickViewDialog,
    anchorEl,
    quickViewEsgKpis,
  } = useQuickView();

  const { users } = useUser();
  const {
    collectOverviews,
    collectOverviewLoading,
    collectOverviewStats,
    collectOverviewFacilities,
    getCollectOverviewFacilitiesByCategoryId,
  } = useCollectOverview(year, undefined, users);

  const {
    data: collectFilter,
    handleCollectFilterChange,
    rowsPerPageCount,
    filteredData,
    loading: filterDataLoading,
  } = useCollectFilter(collectOverviews, year);

  const {
    assignedUsers,
    hasAssignedUser,
    numAssignedUser,
    showAssignUsersDialog,
    assignDataOwnerLoading,
    isAssignedUser,
    getAssignedUser,
    handleUserAssignment,
    handleMultiUserAssignment,
    handleAssignUserReset,
    setAssignedUsersDialog,
    handleSaveDataOwner,
  } = useAssignedUser(year);

  const { categoriesArray, loading: categoryLoading } = useCategory();

  const {
    selectedRows,
    singleSelection,
    dispatchMutli,
    isSelected,
    hasSelected,
    toggleSelect,
    showMultiSelectSnackbar,
    setShowMultiSelectSnackbar,
    selectAll,
    setDispatchMutli,
    setSingleSelection,
    resetSelection,
  } = useCollectSelection();

  const {
    onDueDateClick,
    handleDueDateCancelButton,
    handleDueDateSaveButton,
    setInitialDueDateValue,
    openDueDateDialog,
    initialDueDateValue,
    loading: dueDateLoading,
  } = useCollectDueDate(year, selectedRows, singleSelection, dispatchMutli);

  const { isOpen, onCancel, onSend, onOpen, recipientId, setRecipientId } = useReminderDialog();

  const {
    handleInviteExternalUserDialogOpen,
    isInviteExternalUserDialogOpen,
    handleInviteAndAssignExternalUser,
    handleInviteExternalUserDialogClose,
  } = useExternalUser(year);

  if (
    collectOverviewLoading ||
    categoryLoading ||
    !collectOverviews ||
    !categoriesArray ||
    filterDataLoading
  )
    return <Loader />;

  const handleSaveUserAssignment = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAssignUserSnackbar(false);

    // For removing the user
    if (assignedUsers.length === 1 && assignedUsers[0].assignedUser.length === 0) {
      handleOnSaveDataOwner(false);
    } else {
      setAssignedUsersDialog(true);
    }
  };

  function handleDueDateClick(row: TransformedCollectOverview) {
    setDispatchMutli(false);
    setSingleSelection(row);
    onDueDateClick(row);
  }

  function handleReminderDialogOpen(row: TransformedCollectOverview) {
    setSingleSelection(row);
    setRecipientId(row.assignedToId);
    onOpen();
  }

  const handleCancelUserAssignment = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAssignUserSnackbar(false);
    handleAssignUserReset();
  };

  const handleAssignUserChange = (rowId: string, person: string) => {
    if (!hasAssignedUser && !isInviteExternalUserDialogOpen) {
      setOpenAssignUserSnackbar(true);
    }
    handleUserAssignment(rowId, person);
  };

  const handleMultiAssignUserChange = (person?: string) => {
    if (person) {
      handleMultiUserAssignment(Object.keys(selectedRows), person);
    }
  };

  const handleMultiSelectSnackbar = async (
    type: MultiSelectSnackbarActionTypes,
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setDispatchMutli(true);
    setSingleSelection(null);
    setInitialDueDateValue(null);
    if (type === MultiSelectSnackbarActionTypes.ASSIGN_DATA_OWNER) {
      setAssignedUsersDialog(true);
    } else if (type === MultiSelectSnackbarActionTypes.CHANGE_DUE_DATE) {
      onDueDateClick();
    } else if (type === MultiSelectSnackbarActionTypes.SEND_REMINDER) {
      setRecipientId(undefined);
      onOpen();
    }
    setShowMultiSelectSnackbar(false);
  };

  const handleOpenButtonClick = () => {
    setOpen(true);
  };

  const handleCloseButtonClick = () => {
    setOpen(false);
  };

  const handleSaveButtonClick = (categoryId: string) => {
    setSelectedCategory(categoryId);
    setOpen(false);
    setOpenNuvo(true);
  };

  const handleCancelAssignedUserDialog = () => {
    setAssignedUsersDialog(false);
    handleAssignUserReset();
    resetSelectionOnCancelOrSave();
  };

  const resetSelectionOnCancelOrSave = () => {
    resetSelection();
    setDispatchMutli(false);
  };

  const handleOnSaveDataOwner = (notify: boolean, message?: string) => {
    handleSaveDataOwner(notify, message);
    if (dispatchMutli) {
      resetSelectionOnCancelOrSave();
    }
  };

  const handleOnDueDateCancel = () => {
    handleDueDateCancelButton();
    resetSelectionOnCancelOrSave();
  };

  const handleOnDueDateSave = (dueDate: Date | null | undefined) => {
    handleDueDateSaveButton(dueDate);
    if (dispatchMutli) {
      resetSelectionOnCancelOrSave();
    }
  };

  const handleOnReminderDialogCancel = () => {
    onCancel();
    resetSelectionOnCancelOrSave();
  };

  const handleOnReminderDialogSend = (message?: string) => {
    onSend(message);
    if (dispatchMutli) {
      resetSelectionOnCancelOrSave();
    }
  };

  const handleCloseAddExternalUserDialog = () => {
    handleInviteExternalUserDialogClose();
    if (dispatchMutli) {
      resetSelection();
      setDispatchMutli(false);
    }
  };
  const handleSaveAddExternalUserDialog = (data: {
    firstName: string;
    lastName: string;
    email: string;
    language: LanguageCode;
  }) => {
    handleInviteAndAssignExternalUser(data);
    handleCloseAddExternalUserDialog();
  };

  const handleOpenAddExternalUserDialog = (
    isMultiAssignemnt: boolean,
    collectOverviewIds?: string[]
  ) => {
    handleAssignUserReset();
    if (isMultiAssignemnt) {
      setAssignedUsersDialog(false);
      handleInviteExternalUserDialogOpen(Object.keys(selectedRows));
    } else if (collectOverviewIds && collectOverviewIds?.length > 0) {
      handleInviteExternalUserDialogOpen(collectOverviewIds);
    }
  };

  const handleOpenQuickViewDialog = (row: TransformedCollectOverview, e: HTMLElement) => {
    setSingleSelection(row);
    setAnchorEl(e);
    handleQuickViewDialogOpen(row);
  };

  const handleFilterChange = async (newValue: string[], filterType: FilterType) => {
    await handleCollectFilterChange({ newValue, filterType });
  };

  const handleRowsPerPageCountChange = async (count: number) => {
    await handleCollectFilterChange({ count });
  };

  const handleSortingChange = async (field: string, direction: SortingOrder) => {
    await handleCollectFilterChange({ field, direction });
  };

  return (
    <Box>
      <DataOverviewComponent data={collectOverviewStats} />
      <CollectDataTableFilter
        collectFilter={collectFilter}
        handleFilterChange={handleFilterChange}
        hasSelected={hasSelected}
        hasAssignedUser={hasAssignedUser}
        onOpenImportDialog={handleOpenButtonClick}
        facilities={collectOverviewFacilities}
        categories={categoriesArray}
        users={users}
        lazyLoading={lazyLoading}
      />

      {isAdmin && (
        <CollectEsgAdminTable
          data={filteredData}
          hasAssignedUser={hasAssignedUser}
          isAssignedUser={isAssignedUser}
          getAssignedUser={getAssignedUser}
          handleAssignUserChange={handleAssignUserChange}
          selectedRows={selectedRows}
          hasSelectedCheckbox={hasSelected}
          isSelected={isSelected}
          handleSelectAllClick={selectAll}
          handleCheckboxClick={toggleSelect}
          handleDueDateClick={handleDueDateClick}
          handleReminderDialogOpen={handleReminderDialogOpen}
          handleOpenAddExternalUserDialog={handleOpenAddExternalUserDialog}
          handleOpenQuickViewDialog={handleOpenQuickViewDialog}
          rowsPerPageCount={rowsPerPageCount}
          collectFilter={collectFilter}
          handleOnRowCountChange={handleRowsPerPageCountChange}
          handleSortingChange={handleSortingChange}
        />
      )}
      {!isAdmin && (
        <CollectEsgNonAdminTable
          data={filteredData}
          collectFilter={collectFilter}
          isExternal={false}
          rowsPerPageCount={rowsPerPageCount}
          handleOnRowCountChange={handleRowsPerPageCountChange}
          handleSortingChange={handleSortingChange}
        />
      )}
      <AssignDataOwnerSnackbar
        isOpen={openAssignUserSnackbar}
        selectedOwnerCount={numAssignedUser}
        onCancel={handleCancelUserAssignment}
        onSave={handleSaveUserAssignment}
      />
      <MultiSelectSnackbar
        isOpen={showMultiSelectSnackbar}
        selectedOwnerCount={Object.keys(selectedRows).length}
        onSelectedAction={handleMultiSelectSnackbar}
      />
      <DueDateDialog
        onCancel={handleOnDueDateCancel}
        onSave={handleOnDueDateSave}
        isLoading={dueDateLoading}
        isOpen={openDueDateDialog}
        initialDate={initialDueDateValue}
      />
      <AssignDataOwnerDialog
        isOpen={showAssignUsersDialog}
        isLoading={assignDataOwnerLoading}
        onCancel={handleCancelAssignedUserDialog}
        onSave={handleOnSaveDataOwner}
        isMultiSelect={dispatchMutli}
        handleMultiAssignUserChange={handleMultiAssignUserChange}
        handleOpenAddExternalUserDialog={handleOpenAddExternalUserDialog}
      />
      <ImportFileDialog
        open={open}
        categories={categoriesArray}
        handleAssign={handleSaveButtonClick}
        onClose={handleCloseButtonClick}
      />
      <Suspense
        fallback={
          <LoadingStateTracker
            onMount={() => setLazyLoading(true)}
            onUnmount={() => setLazyLoading(false)}
          />
        }
      >
        <LazyNuvoDialog
          categoryId={selectedCategory}
          facilities={getCollectOverviewFacilitiesByCategoryId(selectedCategory)}
          year={year}
          open={openNuvo}
          onClose={() => setOpenNuvo(false)}
        />
      </Suspense>
      {isOpen && (
        <ReminderDialog
          collectOverviewId={singleSelection?.id}
          isOpen={isOpen}
          onCancel={handleOnReminderDialogCancel}
          onSend={handleOnReminderDialogSend}
          selectedRows={selectedRows}
          singleSelection={singleSelection}
          dispatchMutli={dispatchMutli}
          recipientId={recipientId}
        />
      )}
      <InviteExternalUserDialog
        open={isInviteExternalUserDialogOpen}
        users={users || []}
        onSend={handleSaveAddExternalUserDialog}
        onClose={handleCloseAddExternalUserDialog}
      />
      {singleSelection && (
        <EsgQuickViewDialog
          isOpen={openQuickViewDialog}
          onClose={handleQuickViewDialogClose}
          dueDate={singleSelection.dueDate}
          assignee={singleSelection.assignedTo}
          status={singleSelection.status}
          category={singleSelection.category}
          esgKpis={quickViewEsgKpis}
          anchorEl={anchorEl}
          id={singleSelection.id}
        />
      )}
    </Box>
  );
};

export default CollectData;
