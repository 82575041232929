import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useEnhancedMutation, useMessages } from 'src/modules/common/application';
import { GENERATE_AI_REPORT_BY_REPORT_ID } from 'src/services/graphql/mutations';
import useLanguage from 'src/modules/common/application/use-language';

import { rewriteExistedReportInputCache } from './reportInputCache';

const CHARACTERS_TO_WRITE = 40;
const ANIMATION_INTERVAL = 100;

const useAIGenerateReport = (reportId: string, reportSectionId: string) => {
  const { setErrorMessage } = useMessages();
  const { t } = useTranslation();
  const { language } = useLanguage();
  const apolloClient = useApolloClient();

  let animationInterval: NodeJS.Timer | undefined = undefined;

  useEffect(() => {
    clearInterval(animationInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, reportSectionId]);

  const [generateReport, { loading }] = useEnhancedMutation(GENERATE_AI_REPORT_BY_REPORT_ID, {
    onError: () => {
      setErrorMessage(t('messages.generateReports.error'));
    },
    update(cache, { data }, { variables }) {
      if (variables?.reportId && variables?.reportSectionId) {
        let count = 0;
        const fullText = data?.generateReport[0];

        if (fullText) {
          const newInterval = setInterval(() => {
            count += CHARACTERS_TO_WRITE;

            if (count >= fullText.length) {
              clearInterval(animationInterval);
              rewriteExistedReportInputCache(
                reportId,
                reportSectionId,
                language,
                { isGenerating: false, input: fullText },
                apolloClient
              );

              return;
            } else {
              rewriteExistedReportInputCache(
                reportId,
                reportSectionId,
                language,
                { isGenerating: false, input: fullText?.substring(0, count) },
                apolloClient
              );
            }
          }, ANIMATION_INTERVAL);

          animationInterval = newInterval;
        }
      }
    },
  });

  const handleGenerateAiReports = async () => {
    rewriteExistedReportInputCache(
      reportId,
      reportSectionId,
      language,
      { isGenerating: true },
      apolloClient
    );

    const response = await generateReport({
      variables: {
        reportId,
        reportSectionId,
      },
    });

    return response?.data?.generateReport;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return {
    handleGenerateAiReports,
    loading,
  };
};

export default useAIGenerateReport;
