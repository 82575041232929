import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageCode, ReportingFramework } from 'src/modules/common/types';
import { useEnhancedQuery } from 'src/modules/common/application';
import { GET_REPORT_BY_ID } from 'src/services/graphql/queries';
import { getLanguageCode } from 'src/modules/common/application/languageCodeFormater';
import { reportBreadCrumbs } from 'src/modules/reporting/application/utils';

import useReportSections from './use-report-sections';

const useReportSection = (reportId: string, sectionId?: string) => {
  const { i18n, t } = useTranslation();

  const {
    data,
    loading: getReportLoading,
    error: getReportError,
  } = useEnhancedQuery(GET_REPORT_BY_ID, {
    variables: {
      reportId,
    },
  });

  const {
    getReportSectionTree,
    loading: getReportSectionsLoading,
    error: getReportSectionsError,
    getReportSectionById,
    getFirstReportSection,
  } = useReportSections(getLanguageCode(i18n.language) as LanguageCode, reportId);

  const [isReportStructureSidebarOpen, setIsReportStructureSidebarOpen] = useState<boolean>(true);

  const handleReportStructureSidebarMenuClick = () => {
    setIsReportStructureSidebarOpen(!isReportStructureSidebarOpen);
  };

  const breadCrumbs = useMemo(() => {
    return reportBreadCrumbs(data?.report?.title || '');
  }, [data?.report?.title]);

  const reportSection = sectionId ? getReportSectionById(sectionId) : undefined;

  const tree = useMemo(
    () =>
      reportSection ? getReportSectionTree(reportSection.framework as ReportingFramework) : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportSection]
  );

  const title = useMemo(
    () => (reportSection ? t(`shared.types.reportSectionChapter.${reportSection?.chapter}`) : ''),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sectionId]
  );

  return {
    getFirstReportSection,
    title,
    loading: getReportSectionsLoading || getReportLoading,
    error: getReportSectionsError || getReportError,
    reportYear: data?.report?.year,
    reportFramework: data?.report.framework,
    breadCrumbs,
    tree,
    isReportStructureSidebarOpen,
    reportSection,
    handleReportStructureSidebarMenuClick,
  };
};

export default useReportSection;
