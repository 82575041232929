import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

interface Control {
  value: string;
  name: string;
}

interface Props {
  name: string;
  controls: Control[];
  onChange: (alignment: string) => void;
  defaultAlignment?: string;
}

const ToggleButtonList = ({ onChange, controls, name, defaultAlignment = '' }: Props) => {
  const { t } = useTranslation();
  const [alignment, setAlignment] = useState(defaultAlignment || controls[0].value);
  const handleChange = (_event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAlignment(newAlignment);
    onChange(newAlignment);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label={`controls-${name}`}
    >
      {controls.map(c => (
        <ToggleButton
          id="h1"
          key={c.value}
          value={c.value}
          sx={{
            paddingTop: '8px',
            paddingBottom: '8px',
            paddingLeft: '22px',
            paddingRight: '22px',
            fontSize: '0.875rem',
            '@media (max-width: 635px)': {
              fontSize: '0.75rem',
            },
          }}
        >
          {t(c.name)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleButtonList;
