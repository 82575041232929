import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserForSuper } from 'src/modules/common/types';
import { useEnhancedQuery, useMessages, useEnhancedMutation } from 'src/modules/common/application';
import { INVITE_USERS, UPDATE_USER_BY_SUPER } from 'src/services/graphql/mutations';
import { GET_USERS_BY_COMPANY_ID } from 'src/services/graphql/queries';

const useUserSuper = (companyId: string | undefined) => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { t } = useTranslation();

  const { loading, data, error, refetch } = useEnhancedQuery<{
    usersByCompanyId: UserForSuper[] | undefined;
  }>(GET_USERS_BY_COMPANY_ID, {
    variables: {
      companyId,
    },
  });

  const users = data ? data.usersByCompanyId : undefined;

  const [user, setUser] = useState<UserForSuper | undefined>(undefined);

  const editMode = user && user.id;

  const handleSelectUser = (id: string) => {
    setUser(users?.find(user => user.id === id));
  };

  const [inviteUsers] = useEnhancedMutation(INVITE_USERS, {
    onError: () => setErrorMessage(t('messages.createUser.error')),
    onCompleted: async () => {
      handleSelectUser('');
      setSuccessMessage(t('messages.createUser.success'));
      await refetch();
    },
    fetchPolicy: 'no-cache',

    update(cache, result) {
      if (result.data.inviteUsers.__typename === 'InviteUserError') return;

      const newUsers: UserForSuper[] = [];

      result.data.inviteUsers.users.forEach((user: UserForSuper) => {
        newUsers.push({
          ...user,
        });
      });
      const { usersByCompanyId } = cache.readQuery<{
        usersByCompanyId: UserForSuper[];
      }>({
        query: GET_USERS_BY_COMPANY_ID,
        variables: {
          companyId,
        },
      }) || { usersByCompanyId: [] };

      cache.writeQuery({
        query: GET_USERS_BY_COMPANY_ID,
        variables: {
          companyId,
        },
        data: {
          usersByCompanyId: [...usersByCompanyId, ...result.data.inviteUsers.users],
        },
      });
    },
  });

  const [updateUserBySuper] = useEnhancedMutation(UPDATE_USER_BY_SUPER, {
    onError: () => setErrorMessage(t('messages.updateUser.error')),
    onCompleted: async () => {
      handleSelectUser('');
      setSuccessMessage(t('messages.updateUser.success'));
      await refetch();
    },
    fetchPolicy: 'no-cache',
  });

  const handleSubmitUser = async (newUser: UserForSuper, companyId: string) => {
    if (!editMode) {
      await inviteUsers({
        variables: {
          input: [
            {
              email: newUser.email,
              role: newUser.role,
              companyId,
            },
          ],
        },
      });
    } else {
      await updateUserBySuper({
        variables: {
          input: {
            id: newUser.id,
            email: newUser.email,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            role: newUser.role,
            position: newUser.position,
            department: newUser.department,
            language: newUser.language,
            numberFormat: newUser.numberFormat,
          },
        },
      });
    }
  };

  return {
    loading,
    users,
    error,
    user,
    handleSelectUser,
    editMode,
    handleSubmitUser,
  };
};

export default useUserSuper;
