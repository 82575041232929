import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme, Divider } from '@mui/material';

import { getFrameworkChipColor } from 'src/modules/common/application/ui';
import { Sidebar } from 'src/modules/common/components/Base';
import { Chip } from 'src/modules/common/components/Chip';
import { ReportingFramework, TranslatedEsgKpi } from 'src/modules/common/types';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';

interface Props {
  sidebarWidth: number;
  isOpen: boolean;
  onClose: () => void;
  esgKpi: TranslatedEsgKpi | null;
}

const EsgDataInputSidebar = ({ sidebarWidth, isOpen, onClose, esgKpi }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const title: string = esgKpi?.translatedKpiTitle ? esgKpi?.translatedKpiTitle : '';

  return (
    <Sidebar isOpen={isOpen} title={title} onClose={onClose}>
      <Box sx={{ display: 'flex', width: '450px' }}>
        {esgKpi ? (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ paddingLeft: 3 }}>
              <Typography variant="body1" sx={{ color: theme.palette.colors.lightGrey, mb: 1 }}>
                {t('modules.dataManagement.esgDataInputSidebar.description')}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 'normal' }}>
                {t('shared.types.kpiDescription.' + removeUnderscoreToCamelCase(esgKpi.title), '-')}
              </Typography>
            </Box>
            <Divider sx={{ mb: 2, mt: 0.5 }} />
            <Box sx={{ paddingLeft: 3 }}>
              <Typography variant="body1" sx={{ color: theme.palette.colors.lightGrey, mb: 1 }}>
                {t('modules.dataManagement.esgDataInputSidebar.source')}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 'normal' }}>
                {t('shared.types.kpiSource.' + removeUnderscoreToCamelCase(esgKpi.title), '-')}
              </Typography>
            </Box>
            <Divider sx={{ mb: 2, mt: 0.5 }} />

            <Box sx={{ paddingLeft: 3 }}>
              <Typography variant="body1" sx={{ color: theme.palette.colors.lightGrey, mb: 1 }}>
                {t('modules.dataManagement.esgDataInputSidebar.frameworkNumber')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {esgKpi.frameworks?.esrs !== undefined && esgKpi.frameworks?.esrs !== null && (
                  <Chip
                    size="small"
                    styles={{
                      maxWidth: 'fit-content',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                      marginRight: 1,
                    }}
                    colorVariant={getFrameworkChipColor(ReportingFramework.ESRS)}
                  >
                    {esgKpi.frameworks?.esrs}
                  </Chip>
                )}
                {esgKpi.frameworks?.gri !== undefined && esgKpi.frameworks?.gri !== null && (
                  <Chip
                    size="small"
                    styles={{
                      maxWidth: 'fit-content',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                      marginRight: 1,
                    }}
                    colorVariant={getFrameworkChipColor(ReportingFramework.GRI)}
                  >
                    {esgKpi.frameworks?.gri}
                  </Chip>
                )}
                {esgKpi.frameworks?.dnk !== undefined && esgKpi.frameworks?.dnk !== null && (
                  <Chip
                    size="small"
                    styles={{
                      maxWidth: 'fit-content',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                    }}
                    colorVariant={getFrameworkChipColor(ReportingFramework.DNK)}
                  >
                    {esgKpi.frameworks?.dnk}
                  </Chip>
                )}
              </Box>
            </Box>
            <Divider sx={{ mb: 2, mt: 0.5 }} />
          </Box>
        ) : null}
      </Box>
    </Sidebar>
  );
};

export default EsgDataInputSidebar;
