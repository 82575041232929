import * as Yup from 'yup';
import { TFunction } from 'react-i18next';

export const companySchema = (t: TFunction) => {
  return Yup.object().shape({
    name: Yup.string()
      .default('')
      .required(t('messages.warning.shared.required'))
      .min(1, t('messages.warning.shared.required')),
    headquarter: Yup.string()
      .default('')
      .required(t('messages.warning.shared.required'))
      .min(1, t('messages.warning.shared.required')),
    employeesNum: Yup.string().default('').required(t('messages.warning.shared.required')),
    dashboardUrl: Yup.string().default(''),
    subIndustryId: Yup.string().default(''),
    status: Yup.string().default('').required(t('messages.warning.shared.required')),
  });
};
