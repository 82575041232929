import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import theme from 'src/styles/theme';

interface Props {
  data: { name: string; collected: boolean };
}

export const QuickViewKPICard = ({ data }: Props) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        p: '12px',
        border: '1px solid #D9D6D6',
        borderRadius: '5px',
        alignItems: 'center',
        mb: 1,
      }}
    >
      <Typography
        color={theme.palette.colors.lightGrey}
        fontSize={'0.875rem !important'}
        fontWeight={500}
        width={'90%'}
      >
        {t(`shared.types.kpi.${removeUnderscoreToCamelCase(data.name)}`)}
      </Typography>
      {data.collected && (
        <CheckCircleOutlineOutlinedIcon
          style={{
            color: theme.palette.colors.successGreen,
          }}
        />
      )}
      {!data.collected && (
        <CancelOutlinedIcon
          style={{
            color: theme.palette.colors.errorRed,
          }}
        />
      )}
    </Box>
  );
};
