import React from 'react';
import { Grid, Typography } from '@mui/material';

import Button from 'src/modules/common/components/Button/Button';
import useReportSuper from 'src/modules/common/application/use-report-super';

import ReportList from './ReportList';

const ReportAdminPanel = ({ companyId }: { companyId: string }) => {
  const {
    reports,
    report,
    generateReportLoading,
    calculateSimilarityLoading,
    handleSelectReport,
    handleGenerateAiReports,
    handleCalculateAiReportSimilarity,
  } = useReportSuper({
    companyId,
  });

  return (
    <Grid item xs={4}>
      <Typography variant="h5">{'Reports by Company'}</Typography>
      <ReportList reports={reports} selected={report} onSelect={handleSelectReport} />
      {report && (
        <Button
          onClick={handleGenerateAiReports}
          loading={generateReportLoading}
          sx={{
            width: '100%',
            mt: 2,
          }}
        >
          Re-Generate AI Reports
        </Button>
      )}
      {report && (
        <Button
          onClick={handleCalculateAiReportSimilarity}
          loading={calculateSimilarityLoading}
          sx={{
            width: '100%',
            mt: 2,
          }}
        >
          Calculate Similarity
        </Button>
      )}
    </Grid>
  );
};

export default ReportAdminPanel;
