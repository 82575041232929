import React from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { useUserData } from 'src/modules/common/application';
import { getStackholderLabel } from 'src/modules/common/application/user';
import { User } from 'src/modules/common/types';
import theme from 'src/styles/theme';
import { YearSelect } from 'src/modules/common/components';

interface HomeWelcomeMessageProps {
  years: number[];
  setSelectedYear: (year: number) => void;
  selectedYear: number;
}

const HomeWelcomeMessage = (props: HomeWelcomeMessageProps) => {
  const { t } = useTranslation();
  const { user } = useUserData();
  const { years, setSelectedYear, selectedYear } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography
        fontSize={'1rem !important'}
        fontWeight={500}
        color={theme.palette.colors.lightGrey}
      >
        {t('modules.home.personalizedTitle', {
          name: getStackholderLabel(user as User),
        })}
      </Typography>
      <Box sx={{ flexShrink: '1' }}>
        <YearSelect years={years} year={selectedYear} setYear={setSelectedYear} />
      </Box>
    </Box>
  );
};

export default HomeWelcomeMessage;
