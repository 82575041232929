import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumbs, Link, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ArrowBack as ArrowBackIcon,
  KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon,
} from '@mui/icons-material';

const Breadcrumb = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const paths = location.pathname.split('/').filter(p => p);

  // Display starting on second navigation level
  if (paths.length < 2) {
    return <></>;
  }

  const handleReroute = (index: number) => {
    const path = '/' + paths.slice(0, index + 1).join('/');
    navigate(path);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 2,
        paddingLeft: 0,
      }}
    >
      <ArrowBackIcon
        sx={{
          color: 'primary.main',
          cursor: 'pointer',
          fontSize: '24px',
          mr: 1,
          mt: 0.25,
          ml: 0.5,
        }}
        onClick={() => navigate(-1)}
      />
      <Breadcrumbs separator={<KeyboardDoubleArrowRightIcon color="inherit" />}>
        {paths.map((path, index) => {
          return (
            <Link
              key={index}
              onClick={() => handleReroute(index)}
              sx={{ cursor: 'pointer', color: 'text.secondary', textDecoration: 'none' }}
            >
              <Typography variant="subtitle1">{t(`${path}`)}</Typography>
            </Link>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};
export default Breadcrumb;
