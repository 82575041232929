import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Sidebar } from 'src/modules/common/components/Base';
import {
  CountryCode,
  CreateFacilityInput,
  Facility,
  FacilityType,
  UpdateFacilityInput,
} from 'src/modules/common/types/graphql';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import {
  getErrorLabelStyles,
  getInputLabelStyles,
} from 'src/modules/common/application/form-styles';
import Button from 'src/modules/common/components/Button/Button';
import { useCountrySelectOptions } from 'src/modules/common/application/use-country-select-options';
import { CountryType } from 'src/modules/common/types/countryCode';
import { getAddFacilitySchema } from 'src/modules/common/application/welcome.schema';

interface Props {
  isEditMode: boolean;
  isOpen: boolean;
  facility: Facility;
  loading: boolean;
  onClose: () => void;
  onUpdate: (values: UpdateFacilityInput) => void;
  onCreate: (values: CreateFacilityInput) => void;
}

const ManageFacilityForm = ({
  isOpen,
  isEditMode,
  onClose,
  facility,
  loading,
  onUpdate,
  onCreate,
}: Props) => {
  const { t } = useTranslation();
  const title = isEditMode
    ? t('modules.settings.facility.editFacility')
    : t('modules.settings.facility.addFacility');
  const primaryBtnText = isEditMode
    ? t('modules.settings.facility.saveFacility')
    : t('modules.settings.facility.addFacility');
  const formInitialValues = {
    name: facility?.name || '',
    city: facility?.city || '',
    country: facility
      ? {
          code: facility?.country,
          label: t(`shared.types.countryCode.${facility?.country}`),
        }
      : { code: CountryCode.DE, label: t(`shared.types.countryCode.${CountryCode.DE}`) },
    type: facility?.type || '',
  };

  const countries = useCountrySelectOptions();

  const handleSubmitClick = (values: any) => {
    if (isEditMode) {
      const updateFacilityInput = {
        id: facility.id,
        name: values.name,
        type: values.type,
        city: values.city,
        country: values.country.code,
      } as UpdateFacilityInput;
      onUpdate(updateFacilityInput);
    } else {
      const createFacilityInput = {
        name: values.name,
        type: values.type,
        city: values.city,
        country: values.country.code,
      } as CreateFacilityInput;
      onCreate(createFacilityInput);
    }
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  const facilitySchema = getAddFacilitySchema(t);

  return (
    <Sidebar isOpen={isOpen} title={title} onClose={handleClose}>
      <Formik
        initialValues={formInitialValues}
        enableReinitialize={true}
        validationSchema={facilitySchema}
        onSubmit={values => {
          handleSubmitClick(values);
        }}
      >
        {({ submitForm, handleChange, setFieldValue, errors, values }) => (
          <Form>
            <Box
              sx={{
                padding: 3,
                maxHeight: 'calc(100vh - 200px)',
                overflowY: 'scroll',
                width: '450px',
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} marginBottom={2}>
                  <FormControl fullWidth>
                    <InputLabel shrink required htmlFor="name" sx={getInputLabelStyles}>
                      {t('modules.settings.facility.name')}
                    </InputLabel>
                    <Field
                      as={TextField}
                      id="name"
                      name="name"
                      type="text"
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      autoComplete="off"
                      values={values.name ? values.name : ''}
                      InputProps={{
                        style: {
                          WebkitBoxShadow: '0 0 0 1000px white inset',
                          caretColor: 'initial',
                          WebkitTapHighlightColor: 'transparent',
                        },
                      }}
                    />
                    {errors.name && (
                      <FormHelperText sx={getErrorLabelStyles}>{errors.name}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} marginBottom={2}>
                  <FormControl fullWidth>
                    <InputLabel shrink required htmlFor="type" sx={getInputLabelStyles}>
                      {t('modules.settings.facility.facilityType')}
                    </InputLabel>
                    <Field
                      as={Select}
                      id="type"
                      name="type"
                      autoComplete="off"
                      variant="outlined"
                      onChange={handleChange}
                      values={values.type ? values.type : ''}
                    >
                      {Object.values(FacilityType).map(type => {
                        return (
                          <MenuItem key={type} value={type}>
                            {t('shared.types.facilityType.' + removeUnderscoreToCamelCase(type))}
                          </MenuItem>
                        );
                      })}
                    </Field>
                    {errors.type && (
                      <FormHelperText sx={getErrorLabelStyles}>{errors.type}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                <Grid item xs={12} marginBottom={2}>
                  <FormControl fullWidth>
                    <InputLabel shrink required htmlFor="city" sx={getInputLabelStyles}>
                      {t('modules.settings.facility.city')}
                    </InputLabel>
                    <Field
                      as={TextField}
                      id="city"
                      name="city"
                      type="text"
                      autoComplete="off"
                      onChange={handleChange}
                      values={values.city ? values.city : ''}
                      fullWidth
                      variant="outlined"
                    />
                    {errors.city && (
                      <FormHelperText sx={getErrorLabelStyles}>{errors.city}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} marginBottom={2}>
                  <FormControl fullWidth>
                    <InputLabel shrink required htmlFor="country" sx={getInputLabelStyles}>
                      {t('modules.settings.facility.country')}
                    </InputLabel>
                    <Field
                      as={Autocomplete}
                      id="country"
                      name="country"
                      options={countries}
                      getOptionLabel={(option: CountryType) => option.label}
                      isOptionEqualToValue={(option: any, value: any) => option.code === value.code}
                      disabled={isEditMode}
                      onChange={(e: any, value: any) => {
                        setFieldValue('country', value);
                      }}
                      values={values.country ? values.country : ''}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          name="country"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                    {errors.country && (
                      <FormHelperText sx={getErrorLabelStyles}>
                        {t('fieldWarnings.requiredWarning')}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                width: '100%',
                position: 'absolute',
                bottom: 0,
                padding: '24px',
                borderTop: '1px solid',
                borderColor: 'background.grey',
              }}
            >
              <Button
                size="small"
                onClick={submitForm}
                fullWidth
                loading={loading}
                sx={{ width: '50%' }}
              >
                {primaryBtnText}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Sidebar>
  );
};

export default ManageFacilityForm;
