import React from 'react';

const VerifyEmailSvg = (props: any) => {
  return (
    <svg
      width="223"
      height="200"
      viewBox="0 0 223 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14396_67466)">
        <path
          d="M220.843 76.484C220.763 76.4841 220.685 76.4596 220.62 76.414L112.521 1.13044C112.192 0.902403 111.801 0.780537 111.4 0.781219C111 0.781902 110.61 0.9051 110.281 1.13426L2.9606 76.4132C2.87571 76.4728 2.77065 76.4962 2.66853 76.4782C2.5664 76.4603 2.47558 76.4026 2.41604 76.3177C2.3565 76.2328 2.33312 76.1277 2.35104 76.0256C2.36896 75.9235 2.42672 75.8326 2.51161 75.7731L109.833 0.494124C110.292 0.173354 110.839 0.000924658 111.399 3.70844e-06C111.959 -0.000917241 112.507 0.169713 112.967 0.488971L221.067 75.7723C221.135 75.8199 221.186 75.8881 221.213 75.9668C221.24 76.0456 221.241 76.1309 221.216 76.2103C221.191 76.2897 221.142 76.3591 221.074 76.4083C221.007 76.4576 220.926 76.4841 220.843 76.484Z"
          fill="#3F3D56"
        />
        <path
          d="M9.09326 79.1529L111.507 3.25195L214.697 84.5536L116.784 142.598L63.6248 130.481L9.09326 79.1529Z"
          fill="#E6E6E6"
        />
        <path
          d="M68.1118 178.45H17.5171C17.2064 178.451 16.8987 178.39 16.6116 178.271C16.3244 178.153 16.0635 177.978 15.8437 177.759C15.6239 177.539 15.4495 177.279 15.3305 176.992C15.2115 176.705 15.1503 176.397 15.1503 176.086C15.1503 175.776 15.2115 175.468 15.3305 175.181C15.4495 174.894 15.6239 174.633 15.8437 174.414C16.0635 174.194 16.3244 174.02 16.6116 173.902C16.8987 173.783 17.2064 173.722 17.5171 173.723H68.1118C68.4225 173.722 68.7302 173.783 69.0173 173.902C69.3045 174.02 69.5654 174.194 69.7852 174.414C70.005 174.633 70.1794 174.894 70.2984 175.181C70.4174 175.468 70.4786 175.776 70.4786 176.086C70.4786 176.397 70.4174 176.705 70.2984 176.992C70.1794 177.279 70.005 177.539 69.7852 177.759C69.5654 177.978 69.3045 178.153 69.0173 178.271C68.7302 178.39 68.4225 178.451 68.1118 178.45Z"
          fill="#4356ED"
        />
        <path
          d="M35.6693 168.287H17.5171C17.2064 168.287 16.8987 168.227 16.6116 168.108C16.3244 167.989 16.0635 167.815 15.8437 167.596C15.6239 167.376 15.4495 167.116 15.3305 166.829C15.2115 166.542 15.1503 166.234 15.1503 165.923C15.1503 165.613 15.2115 165.305 15.3305 165.018C15.4495 164.731 15.6239 164.47 15.8437 164.251C16.0635 164.031 16.3244 163.857 16.6116 163.739C16.8987 163.62 17.2064 163.559 17.5171 163.56H35.6693C35.98 163.559 36.2877 163.62 36.5748 163.739C36.862 163.857 37.1229 164.031 37.3427 164.251C37.5625 164.47 37.7369 164.731 37.8559 165.018C37.9749 165.305 38.0361 165.613 38.0361 165.923C38.0361 166.234 37.9749 166.542 37.8559 166.829C37.7369 167.116 37.5625 167.376 37.3427 167.596C37.1229 167.815 36.862 167.989 36.5748 168.108C36.2877 168.227 35.98 168.287 35.6693 168.287Z"
          fill="#4356ED"
        />
        <path
          d="M112.947 121.429C112.562 121.429 112.181 121.355 111.825 121.209L48.6638 95.0017V12.9676C48.6647 12.2422 48.9532 11.5467 49.4661 11.0338C49.9791 10.5208 50.6745 10.2323 51.3999 10.2314H172.571C173.296 10.2323 173.991 10.5208 174.504 11.0338C175.017 11.5467 175.306 12.2422 175.307 12.9676V95.0589L175.188 95.1097L114.102 121.196C113.736 121.35 113.344 121.429 112.947 121.429Z"
          fill="white"
        />
        <path
          d="M112.947 121.623C112.537 121.624 112.131 121.544 111.751 121.389L48.4684 95.1314V12.9667C48.4692 12.1895 48.7784 11.4443 49.328 10.8948C49.8775 10.3452 50.6227 10.036 51.3999 10.0352H172.571C173.348 10.036 174.093 10.3452 174.643 10.8948C175.192 11.4443 175.501 12.1895 175.502 12.9667V95.1871L114.179 121.375C113.789 121.539 113.37 121.624 112.947 121.623ZM49.2501 94.6092L112.049 120.667C112.635 120.904 113.291 120.9 113.874 120.656L174.72 94.6711V12.9667C174.72 12.3967 174.493 11.8503 174.09 11.4473C173.687 11.0443 173.141 10.8176 172.571 10.8169H51.3999C50.83 10.8176 50.2835 11.0443 49.8805 11.4473C49.4775 11.8503 49.2508 12.3967 49.2501 12.9667L49.2501 94.6092Z"
          fill="#3F3D56"
        />
        <path
          d="M220.062 75.7021H219.983L175.111 94.8628L113.717 121.079C113.477 121.18 113.219 121.232 112.959 121.234C112.699 121.235 112.441 121.185 112.2 121.086L48.8592 94.808L2.8846 75.7334L2.81436 75.7021H2.73611C2.0107 75.703 1.31522 75.9915 0.802275 76.5044C0.289329 77.0174 0.000804906 77.7128 0 78.4383V197.264C0.000805938 197.989 0.289329 198.685 0.802275 199.198C1.31522 199.711 2.0107 199.999 2.73611 200H220.062C220.787 199.999 221.482 199.711 221.995 199.198C222.508 198.685 222.797 197.989 222.798 197.264V78.4383C222.797 77.7128 222.508 77.0174 221.995 76.5044C221.482 75.9915 220.787 75.703 220.062 75.7021ZM222.016 197.264C222.016 197.782 221.81 198.279 221.443 198.645C221.077 199.012 220.58 199.218 220.062 199.218H2.73611C2.21785 199.218 1.72087 199.012 1.3544 198.645C0.987928 198.279 0.781954 197.782 0.781746 197.264V78.4383C0.782455 77.9329 0.978497 77.4473 1.32888 77.0831C1.67927 76.7189 2.15688 76.5042 2.66187 76.4839L48.8592 95.6523L111.899 121.81C112.582 122.087 113.346 122.083 114.026 121.798L175.111 95.711L220.14 76.4839C220.644 76.5057 221.12 76.721 221.47 77.085C221.819 77.449 222.015 77.9337 222.016 78.4383V197.264Z"
          fill="#3F3D56"
        />
        <path
          d="M112.315 98.1663C109.984 98.1707 107.714 97.4167 105.848 96.0181L105.732 95.9312L81.3767 77.2836C80.2488 76.4194 79.3021 75.3414 78.5907 74.1113C77.8794 72.8812 77.4173 71.523 77.2308 70.1143C77.0443 68.7056 77.1371 67.274 77.5039 65.9011C77.8708 64.5283 78.5044 63.2411 79.3686 62.1132C80.2329 60.9852 81.3108 60.0385 82.5409 59.3272C83.771 58.6158 85.1292 58.1537 86.5379 57.9672C87.9466 57.7807 89.3783 57.8736 90.7511 58.2404C92.1239 58.6072 93.4111 59.2408 94.539 60.105L110.315 72.2017L147.595 23.5822C148.46 22.4546 149.538 21.5082 150.769 20.7973C151.999 20.0864 153.357 19.6247 154.766 19.4388C156.175 19.2528 157.607 19.3461 158.979 19.7135C160.352 20.0808 161.639 20.7149 162.767 21.5796L162.535 21.8943L162.773 21.5842C165.047 23.3324 166.536 25.9115 166.911 28.7557C167.286 31.5998 166.518 34.4768 164.775 36.7553L120.924 93.9434C119.91 95.2613 118.606 96.3277 117.113 97.0601C115.62 97.7924 113.978 98.1709 112.315 98.1663Z"
          fill="#4356ED"
        />
      </g>
      <defs>
        <clipPath id="clip0_14396_67466">
          <rect width="222.798" height="200" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VerifyEmailSvg;
