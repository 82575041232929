import { useEffect, useState } from 'react';

import { useEnhancedMutation } from 'src/modules/common/application';
import { LOGIN_VIA_TOKEN } from 'src/services/graphql/mutations';

const useTokenLogin = (token: string | null) => {
  const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [login_via_token] = useEnhancedMutation(LOGIN_VIA_TOKEN, {
    onError: () => {
      setIsTokenExpired(true);
      setIsLoading(false);
    },
    onCompleted: data => {
      setAccessToken(data?.loginViaToken?.user?.accessToken);
      setIsTokenExpired(false);
      setIsLoading(false);
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (token) {
      login_via_token({
        variables: {
          token,
        },
      });
    }
  }, [login_via_token, token]);

  return {
    accessToken,
    loading: isLoading,
    isTokenExpired,
  };
};

export default useTokenLogin;
