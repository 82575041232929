import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { ReportSection } from 'src/modules/common/types';
import theme from 'src/styles/theme';
import { getFirstLetterCapital } from 'src/modules/common/application/string';

import ReportSectionSidebarCard from './ReportSectionSidebarCard';

interface IReportSidebatAccordian {
  reportSidebarData: {
    category: string;
    reportSections: ReportSection[];
  };
  isSidebarCollapsed: boolean;
}

const ReportSidebarAccordian = (props: IReportSidebatAccordian) => {
  const { t } = useTranslation();
  const {
    reportSidebarData: { category, reportSections },
    isSidebarCollapsed,
  } = props;

  const [expanded, setExpanded] = useState<boolean>(false);

  const chapterGroupName = useMemo(() => {
    const translatedName = t(`shared.types.reportSectionCategory.${category}`);
    return isSidebarCollapsed ? getFirstLetterCapital(translatedName) : translatedName;
  }, [category, isSidebarCollapsed, t]);

  const chapterGroupStatusData = useMemo(() => {
    const statusData = {
      status: 'Not Started',
      color: theme.palette.colors.shadeLightGrey,
    };
    const totalLength = reportSections.length;
    const completedLength = reportSections.filter(
      section => section.reportInput?.isMarkedCompleted
    ).length;

    if (totalLength > 0 && completedLength > 0 && totalLength !== completedLength) {
      statusData.color = theme.palette.warning.main;
      statusData.status = 'In Progress';
    } else if (totalLength > 0 && completedLength > 0 && totalLength === completedLength) {
      statusData.color = theme.palette.colors.successGreen;
      statusData.status = 'Completed';
    }

    return statusData;
  }, [reportSections]);

  return (
    <Accordion
      sx={{
        backgroundColor: theme.palette.colors.white,
      }}
      onChange={(e, expanded) => setExpanded(expanded)}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{
              transform: expanded ? 'rotate(-180deg)' : 'rotate(-90deg)',
            }}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Typography
            fontSize={'0.875rem !important'}
            fontWeight={500}
            marginRight={isSidebarCollapsed ? 1 : 0}
            width="50%"
          >
            {chapterGroupName}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor: theme.palette.colors.lightSmokeGreyTransparent,
              px: 1,
              py: isSidebarCollapsed ? 1 : 0,
              borderRadius: '20px',
            }}
          >
            <Box
              sx={{
                height: '10px',
                width: '10px',
                borderRadius: '50%',
                backgroundColor: chapterGroupStatusData.color,
                marginRight: isSidebarCollapsed ? 0 : 1,
              }}
            />
            {!isSidebarCollapsed && (
              <Typography
                fontSize={'0.875rem !important'}
                fontWeight={500}
                color={theme.palette.colors.lightGrey}
              >
                {chapterGroupStatusData.status}
              </Typography>
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          py: 0,
        }}
      >
        {reportSections.map((reportSection, index) => {
          return (
            <ReportSectionSidebarCard
              key={index}
              sectionData={reportSection}
              isSidebarCollapsed={isSidebarCollapsed}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default ReportSidebarAccordian;
