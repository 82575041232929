import { gql } from '@apollo/client';

export const GET_ESG_CATEGORIES = gql`
  query EsgCategories {
    esgCategories {
      area
      id
      title
    }
  }
`;
