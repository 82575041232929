import React from 'react';
import { TableHead, TableRow, TableCell } from '@mui/material';
import { TableHeaderVariantsMap } from './TableHeader.constants';
import { TableHeaderVariants } from './types';

interface Props {
  headers: string[];
  showLastHeader?: boolean;
  variant?: TableHeaderVariants;
  styles?: React.CSSProperties;
  headerCellStyles?: React.CSSProperties;
}

const TableHeader = ({
  headers,
  variant = TableHeaderVariants.PRIMARY,
  showLastHeader = true,
  styles = {},
  headerCellStyles = {},
}: Props) => {
  const headerStyles = TableHeaderVariantsMap[variant];
  return (
    <TableHead sx={{ ...headerStyles, ...styles }}>
      <TableRow
        sx={{
          border: 'none',
        }}
      >
        {headers.map(header => (
          <TableCell
            sx={{ borderRadius: 0, fontSize: '0.875rem !important', ...headerCellStyles }}
            key={header}
          >
            {header}
          </TableCell>
        ))}
        {showLastHeader && (
          <TableCell data-testid="last-header" sx={{ borderRadius: 0 }}>
            {''}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
