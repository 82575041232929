import { useEffect, useState } from 'react';
import useUserData from 'src/modules/common/application/use-user-data';
import { getLanguageCode } from 'src/modules/common/application/languageCodeFormater';
import { useTranslation } from 'react-i18next';

type SetUserType = {
  id: string;
  name: string;
  email: string;
  phone?: string | null | undefined;
} & { [key: string]: string | null | undefined };

type YbugApi = {
  boot: () => void;
  show: (opt?: 'launcher') => void;
  open: (opt?: 'annotate' | 'feedback') => void;
  destroy: () => void;
  setUser: (infos: SetUserType) => void;
  close: () => void;
};

type YbugSettings = {
  id: string;
  feedback?: {
    comment?: string;
    rating?: number;
    email?: string;
    name?: string;
  };
  user?: SetUserType;
  anonymize_elements?: string;
  language_override?: string;
  launcher_position?:
    | 'bottom-left'
    | 'bottom-right'
    | 'left-middle'
    | 'right-middle'
    | 'top-middle';
  widget_position?: 'center' | 'left' | 'right';
  skip_to?: 'feedback';
  hide_launcher?: boolean;
  console_log?: boolean;
  rating?: boolean; // Rating is disabled by default
  rating_required?: boolean;
  comment?: boolean; // Comment is enabled and required
  comment_required?: boolean;
  name?: boolean; // Name field is disabled
  name_required?: boolean;
  email?: boolean; // Email field is enabled and optional
  email_required?: boolean;
  type?: boolean; // Feedback type (Bug, Improvement, Question, ...)
  type_required?: boolean;
  title?: boolean; // Feedback title/summary field
  title_required?: boolean;
  priority?: boolean; // Feedback priority
  priority_required?: boolean;
  phone?: boolean; // Phone number field
  phone_required?: boolean;
  nps?: boolean; // Net Promoter Score® field
  nps_required?: boolean;
  close_countdown?: number;
  shortcut?: boolean;
  nonce?: string; // CSP nonce
  onload: () => unknown;
  onopen: () => unknown;
  onbeforesend: () => unknown;
  oncancel: () => unknown;
  onclose: () => unknown;
};

const useYbugApi = () => {
  const YBUG_DEFAULT_SCRIPT_URL = `https://widget.ybug.io/button/${process.env.REACT_APP_YBUG_PROJECT_ID}.js`;
  const [ybugApi, setYbugApi] = useState<YbugApi | null>(null);

  const { user } = useUserData();
  // const { language } = useLanguage();
  const { i18n } = useTranslation();
  const applicationLanguage = getLanguageCode(i18n.language);

  const setSettings = () => {
    // @ts-expect-error will not be defined in window
    window.ybug_settings = {
      feedback: {
        email: user?.email,
        name: user?.firstName + ' ' + user?.lastName,
      },
      hide_launcher: false,
      language_override: applicationLanguage,
      id: process.env.REACT_APP_YBUG_PROJECT_ID,
      onload: () => {
        // @ts-expect-error will not be defined in window
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setYbugApi(window.Ybug);
      },
    } as YbugSettings;
  };

  useEffect(() => {
    setSettings();
    const script = document.createElement('script');
    script.defer = true;
    script.src = YBUG_DEFAULT_SCRIPT_URL;
    document.head.appendChild(script);

    return () => {
      if (ybugApi) {
        ybugApi.destroy();
      }
      script.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSettings();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, applicationLanguage]);
};

export default useYbugApi;
