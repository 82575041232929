import { useTranslation } from 'react-i18next';
import useMessages from './use-messages';
import { errorCodes } from 'src/modules/common/types/constants';

const useBackendErrorMessage = () => {
  const { setErrorMessage } = useMessages();
  const { i18n } = useTranslation();
  return (code: string | undefined, fallbackMessage: string) => {
    const message =
      code && errorCodes.hasOwnProperty(code)
        ? // @ts-ignore
          errorCodes[code][i18n.language]
        : fallbackMessage;

    setErrorMessage(message);
  };
};

export default useBackendErrorMessage;
