import { useEnhancedQuery } from 'src/modules/common/application';
import { LanguageCode, ReportingFramework, ReportSection } from 'src/modules/common/types';
import { GET_REPORT_SECTIONS } from 'src/services/graphql/queries';

const useReportSections = (language: LanguageCode, reportId: string) => {
  const { loading, data, error } = useEnhancedQuery<{ reportSections: ReportSection[] }>(
    GET_REPORT_SECTIONS,
    {
      variables: {
        language,
        reportId,
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const getReportSectionTree = (
    framework: ReportingFramework
  ): {
    category: string;
    reportSections: ReportSection[];
  }[] => {
    const tree: { category: string; reportSections: ReportSection[] }[] = [];

    data?.reportSections?.forEach(reportSection => {
      if (reportSection.framework === framework) {
        const existedIndex = tree.findIndex(node => node.category === reportSection.category);
        if (existedIndex > -1) {
          tree[existedIndex].reportSections.push(reportSection);
        } else {
          tree.push({
            category: reportSection.category,
            reportSections: [reportSection],
          });
        }
      }
    });
    return tree;
  };

  const getReportSectionById = (sectionId: string) => {
    return data?.reportSections?.find(section => section.id === sectionId);
  };

  const getFirstReportSection = (framework: ReportingFramework) =>
    data?.reportSections?.find(section => section.framework === framework);

  return {
    loading,
    data,
    error,
    getReportSectionTree,
    getReportSectionById,
    getFirstReportSection,
  };
};

export default useReportSections;
