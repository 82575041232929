import React, { Ref } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import ChatMessageContainer from '../ChatMessageContainer/ChatMessageContainer';
import ChatbotMessageInput from '../ChatbotMessageInput/ChatbotMessageInput';
import ChatbotHeader from '../ChatbotHeader';
import { ChatMessage } from '../../types';

interface ChatbotDialogProps {
  open: boolean;
  messages: ChatMessage[];
  loading: boolean;
  handleSendMessage: (inputMessage: string) => Promise<void>;
  toggleDialog: () => void;
  handlePinClick: () => void;
  contentRef: Ref<HTMLDivElement> | null;
}

const ChatbotDialog = (props: ChatbotDialogProps) => {
  const { open, toggleDialog, handlePinClick, messages, loading, handleSendMessage, contentRef } =
    props;

  return (
    <Dialog open={open} onClose={toggleDialog}>
      <DialogTitle sx={{ background: '#fff' }}>
        <ChatbotHeader handlePinClick={handlePinClick} handleOnClose={toggleDialog} />
      </DialogTitle>
      <DialogContent
        sx={{ background: '#fff', maxHeight: '500px', width: '550px', paddingBottom: 0 }}
        ref={contentRef}
      >
        <ChatMessageContainer messages={messages} loading={loading} />
      </DialogContent>
      <DialogActions
        sx={{
          padding: 2,
        }}
      >
        <ChatbotMessageInput
          sendMessage={(message: string) => {
            handleSendMessage(message);
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ChatbotDialog;
