import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const getCreateRequestValidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .default('')
      .required(t('messages.warning.shared.required'))
      .min(2, t('messages.warning.shared.minRequiredWarning', { minCharacter: 2 })),
    lastName: Yup.string()
      .default('')
      .required(t('messages.warning.shared.required'))
      .min(2, t('messages.warning.shared.minRequiredWarning', { minCharacter: 2 })),
    email: Yup.string()
      .default('')
      .email(t('messages.warning.shared.emailFormat'))
      .required(t('messages.warning.shared.emailRequired'))
      .min(1, t('messages.warning.shared.minRequiredWarning', { minCharacter: 1 })),
    position: Yup.string().default('').required(t('messages.warning.shared.required')),
    dueDate: Yup.string().default(null).nullable().required(t('messages.warning.shared.required')),
  });
};

export const getStakeholderEvaluationFormValidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .default('')
      .required(t('messages.warning.shared.required'))
      .min(2, t('messages.warning.shared.minRequiredWarning', { minCharacter: 2 })),
    lastName: Yup.string()
      .default('')
      .required(t('messages.warning.shared.required'))
      .min(2, t('messages.warning.shared.minRequiredWarning', { minCharacter: 2 })),
    email: Yup.string()
      .default('')
      .email(t('messages.warning.shared.emailFormat'))
      .required(t('messages.warning.shared.emailRequired'))
      .min(1, t('messages.warning.shared.minRequiredWarning', { minCharacter: 1 })),
    position: Yup.string().default('').required(t('messages.warning.shared.required')),
    dueDate: Yup.string().default(null).nullable().required(t('messages.warning.shared.required')),
    evaluationTopics: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          title: Yup.string(),
        })
      )
      .default([{ id: '', title: '' }])
      .required(t('messages.warning.shared.required')),
  });
};
