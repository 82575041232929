import { WatchQueryFetchPolicy } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { GET_YEARS } from 'src/services/graphql/queries';
import useEnhancedQuery from 'src/modules/common/application/use-enhanced-query';
import { createYears } from 'src/modules/common/application/year';
import { IYear } from 'src/modules/common/types/year';
import { SET_SELECTED_YEAR, UPDATE_COLLECTION_YEARS } from 'src/services/graphql/mutations';

import useUserData from './use-user-data';
import useEnhancedMutation from './use-enhanced-mutation';
import useMessages from './use-messages';
import { User } from '../types';
import { useState } from 'react';

const useYears = (
  fetchPolicy: WatchQueryFetchPolicy | undefined = 'cache-first',
  showSuccessMessage: boolean = true
) => {
  const { t } = useTranslation();
  const { setErrorMessage, setSuccessMessage } = useMessages();

  const { user } = useUserData();
  const [selectedUserYear, setSelectedUserYear] = useState<number>(
    user?.selectedYear ? user.selectedYear : 2022
  );

  const { loading, data, error } = useEnhancedQuery<{ years: number[] }>(GET_YEARS, {
    fetchPolicy,
  });

  const [setSelectedYear] = useEnhancedMutation<{ setSelectedYear: User }>(SET_SELECTED_YEAR, {
    onCompleted: data => {
      if (data.setSelectedYear.selectedYear) {
        setSelectedUserYear(data.setSelectedYear.selectedYear);
      }
    },
    update: (cache, { data }) => {
      setSelectedUserYear(data!.setSelectedYear.selectedYear!);
    },
  });

  const [updateYears, { loading: updateYearsLoading }] = useEnhancedMutation(
    UPDATE_COLLECTION_YEARS,
    {
      onCompleted: () => {
        if (showSuccessMessage) {
          setSuccessMessage(t('messages.updateCollectionYears.success'));
        }
      },
      onError: () => setErrorMessage(t('messages.updateCollectionYears.error')),
      update: (cache, { data: { updateYears } }) => {
        const data = cache.readQuery<{ years: number[] }>({
          query: GET_YEARS,
        });
        cache.writeQuery({
          query: GET_YEARS,
          data: {
            years: [...(data?.years || []), ...updateYears],
          },
        });
      },
    }
  );

  const availableYears: IYear[] = data && data.years ? createYears(data.years) : [];

  const sortedYears = data?.years
    ? (JSON.parse(JSON.stringify(data?.years)) as number[]).sort((a, b) => a - b)
    : null;

  const handleSetSelectedYear = (year: number) => {
    setSelectedYear({
      variables: { year },
    });
  };

  return {
    data,
    sortedYears,
    availableYears,
    loading,
    error,
    selectedYear: selectedUserYear,
    handleSetSelectedYear,
    updateYears,
    updateYearsLoading,
  };
};

export default useYears;
