import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ApexOptions } from 'apexcharts';
import theme from 'src/styles/theme';
import EnhancedChart from 'src/modules/common/components/EnhancedChart';

const chartOptions: ApexOptions = {
  chart: {
    width: 400,
    type: 'radialBar',
  },
  colors: [theme.palette.colors.cascade],
  labels: [''],
  plotOptions: {
    radialBar: {
      dataLabels: {
        value: {
          offsetY: -10,
          fontSize: '22px',
        },
      },
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
        },
      },
    },
  ],
};

const EmployeesInHS = ({ data }: { data: number }) => {
  const { t } = useTranslation();
  const noData = data === 0;
  return (
    <EnhancedChart
      noData={noData}
      cardProps={{
        title: t('modules.analysis.employeesInHs.title'),
        infoText: t('modules.analysis.employeesInHs.info'),
      }}
      chartProps={{
        height: '100%',
        options: chartOptions,
        series: [data],
        type: 'radialBar',
      }}
    />
  );
};

export default EmployeesInHS;
