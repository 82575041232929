import useEnhancedQuery from 'src/modules/common/application/use-enhanced-query';
import { GET_INDUSTRIES, GET_SUB_INDUSTRIES } from 'src/services/graphql/queries/industry';
import { Industry, SubIndustry } from 'src/modules/common/types';

const useIndustries = () => {
  const {
    loading: loadingIndustries,
    data: industries,
    error: IndustryError,
  } = useEnhancedQuery<{ industries: Industry[] }>(GET_INDUSTRIES, {
    fetchPolicy: 'cache-first',
  });

  const {
    data: subIndustries,
    loading: loadingSubIndustries,
    error: SubIndustryError,
  } = useEnhancedQuery<{
    subIndustries: SubIndustry[];
  }>(GET_SUB_INDUSTRIES, {
    fetchPolicy: 'cache-first',
  });

  const getSubIndustries = (industryId: string) => {
    return subIndustries && subIndustries.subIndustries
      ? subIndustries.subIndustries.filter(subIndustry => subIndustry.industry.id === industryId)
      : [];
  };

  return {
    industries: industries?.industries,
    subIndustries: subIndustries?.subIndustries,
    getSubIndustries,
    loading: loadingIndustries || loadingSubIndustries,
    error: IndustryError || SubIndustryError,
  };
};

export default useIndustries;
