import * as Yup from 'yup';
import { TFunction } from 'react-i18next';

export const facilitySchema = (t: TFunction) => {
  return Yup.object().shape({
    name: Yup.string()
      .default('')
      .required(t('messages.warning.shared.required'))
      .min(1, t('messages.warning.shared.required')),
    city: Yup.string()
      .default('')
      .required(t('messages.warning.shared.required'))
      .min(1, t('messages.warning.shared.required')),
    country: Yup.string()
      .default('')
      .required(t('messages.warning.shared.required'))
      .min(1, t('messages.warning.shared.required')),
    type: Yup.string().default(''),
  });
};
