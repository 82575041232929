import * as Yup from 'yup';
import { TFunction } from 'react-i18next';
import { LanguageCode, UserRole } from '../types';

export const getUserInfoSchema = (t: TFunction) => {
  return Yup.object().shape({
    firstName: Yup.string().default(null).notRequired().nullable(true),
    lastName: Yup.string().default(null).notRequired().nullable(true),
    email: Yup.string()
      .default('')
      .email(t('fieldWarnings.emailWarning'))
      .required(t('fieldWarnings.emailWarning')),
    department: Yup.string().default(null).notRequired().nullable(true),
    position: Yup.string().default(null).notRequired().nullable(true),
  });
};

export const getCompanyInfoSchema = (t: TFunction) => {
  return Yup.object().shape({
    name: Yup.string()
      .default('')
      .required(t('fieldWarnings.requiredWarning'))
      .typeError(t('fieldWarnings.requiredWarning')),
    headquarter: Yup.string()
      .default('')
      .required(t('fieldWarnings.requiredWarning'))
      .typeError(t('fieldWarnings.requiredWarning')),
    subIndustryId: Yup.string()
      .required(t('fieldWarnings.requiredWarning'))
      .typeError(t('fieldWarnings.requiredWarning')),
    employeesNum: Yup.string()
      .default('')
      .required(t('fieldWarnings.requiredWarning'))
      .typeError(t('fieldWarnings.requiredWarning')),
    defaultYear: Yup.number()
      .required(t('fieldWarnings.requiredWarning'))
      .typeError(t('fieldWarnings.requiredWarning')),
  });
};

export const getInviteUserSchema = (t: TFunction) => {
  return Yup.object().shape({
    email: Yup.string()
      .default('')
      .email(t('fieldWarnings.emailWarning'))
      .required(t('fieldWarnings.requiredWarning')),
    firstName: Yup.string().default('').required(t('fieldWarnings.requiredWarning')),
    lastName: Yup.string().default('').required(t('fieldWarnings.requiredWarning')),
    position: Yup.string().default(LanguageCode.DE).required(t('fieldWarnings.requiredWarning')),
    role: Yup.string().default(UserRole.EMPLOYEE).required(t('fieldWarnings.requiredWarning')),
  });
};

export const getAddFacilitySchema = (t: TFunction) => {
  return Yup.object().shape({
    name: Yup.string()
      .default('')
      .required(t('fieldWarnings.requiredWarning'))
      .typeError(t('fieldWarnings.requiredWarning')),
    type: Yup.string()
      .default('')
      .required(t('fieldWarnings.requiredWarning'))
      .typeError(t('fieldWarnings.requiredWarning')),
    city: Yup.string()
      .required(t('fieldWarnings.requiredWarning'))
      .typeError(t('fieldWarnings.requiredWarning')),
    country: Yup.object().shape({
      code: Yup.string().required(t('fieldWarnings.requiredWarning')),
      label: Yup.string().required(t('fieldWarnings.requiredWarning')),
    }),
  });
};
