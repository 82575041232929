import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Table, TableBody, TableContainer, tableCellClasses } from '@mui/material';

import { getRelevantDataHeaders } from 'src/modules/common/types/constants/table-header';
import TableHeader from 'src/modules/common/components/Table/TableHeader';
import theme from 'src/styles/theme';
import { SidebarData } from 'src/modules/common/application/use-additional-info-sidebar';

import RelevantDataTableRow from '../RelevantDataTableRow/RelevantDataTableRow';

interface Props {
  data: SidebarData[];
}

const RelevantDataTable = ({ data }: Props) => {
  const { t } = useTranslation();
  const headers = getRelevantDataHeaders.map(t) as string[];

  const sortedData: SidebarData[] = JSON.parse(JSON.stringify(data));
  sortedData.sort((a: SidebarData, b: SidebarData) => a.title.localeCompare(b.title));

  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        height: '25vh',
        border: theme.custom.border,
        boxShadow: 'none',
        mt: -2,
        overflowY: 'auto',
        borderRadius: '10px',
      }}
    >
      <TableContainer sx={{ border: 'none' }}>
        <Table
          sx={{
            tableLayout: 'auto',
            width: '100%',
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
            },
            backgroundColor: theme.palette.colors.white,
          }}
        >
          <TableHeader headers={headers} headerCellStyles={{ fontSize: '0.875rem !important' }} />
          <TableBody>
            {sortedData.map((esgData, index) => (
              <RelevantDataTableRow data={esgData} key={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RelevantDataTable;
