import React from 'react';
import { useTheme } from '@mui/material';

const UserAvatar = (props: any) => {
  const theme = useTheme();
  return (
    <svg
      {...props}
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.333 5.33333C16.333 8.27886 13.9452 10.6667 10.9997 10.6667C8.05415 10.6667 5.66634 8.27886 5.66634 5.33333C5.66634 2.38781 8.05415 0 10.9997 0C13.9452 0 16.333 2.38781 16.333 5.33333Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M10.9997 12C8.1708 12 5.45773 13.1238 3.45712 15.1241C1.45682 17.1247 0.333008 19.8378 0.333008 22.6667C0.333008 23.0202 0.473488 23.3595 0.723484 23.6095C0.97348 23.8595 1.31277 24 1.66634 24H20.333C20.6866 24 21.0259 23.8595 21.2759 23.6095C21.5259 23.3595 21.6663 23.0202 21.6663 22.6667C21.6663 19.8378 20.5425 17.1247 18.5422 15.1241C16.5416 13.1238 13.8286 12 10.9997 12Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};

export default UserAvatar;
