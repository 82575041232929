import { gql } from '@apollo/client';

export const CORE_REPORT_FIELDS = gql`
  fragment CoreReportFields on Report {
    id
    title
    year
    framework
    progress
  }
`;
