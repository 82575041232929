import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card } from '@mui/material';

import { RequestStatus } from 'src/modules/common/types';
import { Chip } from 'src/modules/common/components/Chip';
import { getRequestStatusChipColor } from 'src/modules/common/application/ui';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';

interface MaterialityEvaluationStatsCardProps {
  type: RequestStatus;
  count: number;
}

const MaterialityEvaluationStatCard = ({ type, count }: MaterialityEvaluationStatsCardProps) => {
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        p: 3,
        border: '1px solid',
        borderColor: 'lightGrey',
        boxShadow: 'none',
        minHeight: '114px',
      }}
    >
      <Chip
        size="small"
        styles={{ maxWidth: 'fit-content' }}
        colorVariant={getRequestStatusChipColor(type)}
      >
        {t(`shared.types.requestStatus.${removeUnderscoreToCamelCase(type)}`)}
      </Chip>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          mt: 1,
          ml: 1,
        }}
      >
        {count}
      </Box>
    </Card>
  );
};

export default MaterialityEvaluationStatCard;
