import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useReportExample from 'src/modules/reporting/application/use-report-example';
import { Loader } from 'src/modules/common/components';
import { useMessages } from 'src/modules/common/application';

interface Props {
  reportId: string;
  reportSectionId: string;
}

const ExampleTab = ({ reportId, reportSectionId }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();
  const { examples, loading, error } = useReportExample(reportId, reportSectionId);

  if (loading) return <Loader />;
  if (error) return <>{setErrorMessage(t('messages.generic.error'))}</>;

  return (
    <Box
      sx={{
        height: '25vh',
        border: theme.custom.border,
        boxShadow: 'none',
        mt: -2,
        overflowY: 'auto',
        borderRadius: '10px',
        backgroundColor: theme.palette.colors.white,
        padding: 2,
      }}
    >
      {examples && examples.length === 0 && (
        <Typography variant="subtitle1" color={theme.palette.colors.lightGrey}>
          {t('modules.reporting.reportExampleTab.noExample')}
        </Typography>
      )}
      {examples.map((example, index) => (
        <Box key={`example-${index}`} sx={{ marginBottom: 2 }}>
          <Typography
            variant="subtitle1"
            whiteSpace="pre-wrap"
            color={theme.palette.colors.lightGrey}
            sx={{ marginBottom: 1 }}
          >
            {t('modules.reporting.reportExampleTab.title', { index: index + 1 })}
          </Typography>
          <Typography
            variant="subtitle2"
            whiteSpace="pre-wrap"
            color={theme.palette.colors.lightGrey}
          >
            {example}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ExampleTab;
