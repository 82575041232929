import { useTranslation } from 'react-i18next';

import { UpdateUserByAdminInput, User } from 'src/modules/common/types';
import { useEnhancedMutation, useMessages } from 'src/modules/common/application';
import { ManageUser } from 'src/modules/settings/components/ManageTeam/types';
import { UPDATE_USER_BY_ADMIN, DELETE_USER } from 'src/services/graphql/mutations';
import { GET_USERS } from 'src/services/graphql/queries';

const useUserAdmin = () => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { t } = useTranslation();

  const [deleteUser, { loading: deleteUserLoading }] = useEnhancedMutation(DELETE_USER, {
    onCompleted: () => {
      setSuccessMessage(t('messages.deleteUser.success'));
    },
    onError: () => setErrorMessage(t('messages.deleteUser.error')),
    update(cache, { data }, { variables }) {
      const { users } = cache.readQuery<{
        users: User[];
      }>({
        query: GET_USERS,
      }) || { users: [] };

      const updatedUsers = users.filter(user => user.id !== variables?.id);

      cache.writeQuery({
        query: GET_USERS,
        data: {
          users: updatedUsers,
        },
      });
    },
  });

  const [updateUserByAdmin, { loading }] = useEnhancedMutation(UPDATE_USER_BY_ADMIN, {
    onError: () => setErrorMessage(t('messages.updateUser.error')),
    onCompleted: async () => {
      setSuccessMessage(t('messages.updateUser.success'));
    },
    fetchPolicy: 'no-cache',
    update(cache, result) {
      const updatedUser = result.data.updateUserByAdmin;

      const { users } = cache.readQuery<{
        users: ManageUser[];
      }>({
        query: GET_USERS,
      }) || { users: [] };

      const existedUserIndex = users?.findIndex(user => user.id === updatedUser.id);

      if (existedUserIndex !== undefined && existedUserIndex > -1) {
        const updatedUsers = JSON.parse(JSON.stringify(users));

        updatedUsers[existedUserIndex].firstName = updatedUser.firstName;
        updatedUsers[existedUserIndex].lastName = updatedUser.lastName;
        updatedUsers[existedUserIndex].role = updatedUser.role;
        updatedUsers[existedUserIndex].status = updatedUser.status;

        cache.writeQuery({
          query: GET_USERS,
          data: {
            users: [...updatedUsers],
          },
        });
      }
    },
  });

  async function handleUpdateUser({
    id,
    firstName,
    lastName,
    role,
    department,
    position,
  }: UpdateUserByAdminInput) {
    await updateUserByAdmin({
      variables: {
        id: id,
        input: {
          id: id,
          firstName: firstName,
          lastName: lastName,
          role: role,
          department: department,
          position: position,
        },
      },
    });
  }

  return {
    loading,
    deleteUserLoading,
    handleUpdateUser,
    deleteUser,
  };
};

export default useUserAdmin;
