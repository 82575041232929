import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  useTheme,
} from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import {
  getErrorLabelStyles,
  getInputLabelStyles,
} from 'src/modules/common/application/form-styles';
import { Formik, Form } from 'formik';
import { InviteUserInput, LanguageCode, UserRole } from 'src/modules/common/types';
import Button from 'src/modules/common/components/Button/Button';
import { getInviteUserSchema } from 'src/modules/common/application/welcome.schema';

interface Props {
  open: boolean;
  onClose: () => void;
  handleSendInvite: (values: InviteUserInput[]) => void;
  loading: boolean;
  submitErrors: string[];
}

const InviteUserDialog = ({ open, onClose, handleSendInvite, loading, submitErrors }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [invitedUsers, setInvitedUsers] = useState<InviteUserInput[]>([]);

  const initialValues = {
    email: '',
    role: UserRole.EMPLOYEE,
    language: LanguageCode.DE,
    firstName: '',
    lastName: '',
  };
  const userSchema = getInviteUserSchema(t);

  const inviteUsers = (values: InviteUserInput) => {
    if (invitedUsers.length === 0) {
      handleSendInvite([values]);
    } else {
      if (values.email === '' || values.firstName === '' || values.lastName === '') {
        handleSendInvite(invitedUsers);
      } else {
        const users: InviteUserInput[] = [...invitedUsers, values];
        handleSendInvite(users);
      }
    }
    setInvitedUsers([]);
    onClose();
  };

  const addMoreUsers = (
    values: InviteUserInput,
    handleReset: (e?: React.SyntheticEvent<any, Event> | undefined) => void
  ) => {
    if (invitedUsers.length === 0) {
      const array: InviteUserInput[] = [values];
      setInvitedUsers(array);
    } else {
      setInvitedUsers([...invitedUsers, values]);
    }

    handleReset();
  };

  const handleCancel = (
    handleReset: (e?: React.SyntheticEvent<any, Event> | undefined) => void
  ) => {
    handleReset();
    setInvitedUsers([]);
    onClose();
  };

  const removeAddedUsers = (index: number) => {
    invitedUsers.splice(index, 1);
    setInvitedUsers([...invitedUsers]);
  };

  return (
    <Dialog
      fullWidth
      onClose={reason => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
      }}
      open={open}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={values => inviteUsers(values)}
        onReset={values => {
          values = initialValues;
        }}
        validationSchema={userSchema}
      >
        {({ errors, values, handleChange, handleReset }) => (
          <Form>
            <Box sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
              <Typography gutterBottom variant="h4" sx={{ mb: 2, mt: 3 }}>
                {t('modules.settings.inviteUser.title')}
              </Typography>
              <Typography gutterBottom variant="body2" sx={{ mb: 1 }}>
                {t('modules.settings.inviteUser.info')}
              </Typography>

              {invitedUsers.length > 0
                ? invitedUsers.map((user, index) => (
                    <Box
                      sx={{
                        height: 50,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderLeft: '2px solid',
                        borderColor: 'primary.main',
                        pl: 1,
                        mb: 2,
                      }}
                      key={index}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                          <Typography variant="body2">{user.firstName}</Typography>
                          <Typography variant="body2" sx={{ ml: 0.3 }}>
                            {user.lastName}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body2" color={theme.palette.colors.lightGrey}>
                            {user.email}
                          </Typography>
                        </Box>
                      </Box>
                      <ClearIcon
                        fontSize="small"
                        sx={{
                          cursor: 'pointer',
                          color: theme.palette.colors.lightGrey,
                          height: '100%',
                        }}
                        onClick={() => removeAddedUsers(index)}
                      />
                    </Box>
                  ))
                : null}
              {invitedUsers.length < 5 && (
                <Box>
                  <Grid
                    container
                    sx={{
                      mt: 4,
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Grid item xs={4.5}>
                      <FormControl fullWidth>
                        <InputLabel shrink={true} htmlFor="firstName" sx={getInputLabelStyles}>
                          {t('modules.settings.inviteUser.firstName')}
                        </InputLabel>
                        <TextField
                          name="firstName"
                          id="firstName"
                          fullWidth
                          placeholder={t('modules.settings.inviteUser.firstName')}
                          data-cy="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                        />
                        {errors.firstName && (
                          <FormHelperText sx={getErrorLabelStyles}>
                            {errors.firstName}
                          </FormHelperText>
                        )}
                        {submitErrors?.includes(values.firstName) && (
                          <FormHelperText sx={getErrorLabelStyles}>
                            {t('messages.register.existed')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={4.5}>
                      <FormControl fullWidth>
                        <InputLabel shrink={true} htmlFor="lastName" sx={getInputLabelStyles}>
                          {t('modules.settings.inviteUser.lastName')}
                        </InputLabel>
                        <TextField
                          name="lastName"
                          id="lastName"
                          fullWidth
                          placeholder={t('modules.settings.inviteUser.lastName')}
                          data-cy="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                        />
                        {errors.lastName && (
                          <FormHelperText sx={getErrorLabelStyles}>
                            {errors.lastName}
                          </FormHelperText>
                        )}
                        {submitErrors?.includes(values.lastName) && (
                          <FormHelperText sx={getErrorLabelStyles}>
                            {t('messages.register.existed')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl fullWidth>
                        <InputLabel shrink htmlFor="language" sx={getInputLabelStyles}>
                          {t('modules.settings.inviteUser.language')}
                        </InputLabel>
                        <Select
                          inputProps={{
                            name: 'language',
                            id: 'language',
                          }}
                          fullWidth
                          value={values.language}
                          displayEmpty
                          onChange={handleChange}
                          renderValue={selected => {
                            return selected.toUpperCase();
                          }}
                        >
                          <MenuItem key={LanguageCode.DE} value={LanguageCode.DE}>
                            {t('modules.settings.manageTeam.de')}
                          </MenuItem>
                          <MenuItem key={LanguageCode.EN} value={LanguageCode.EN}>
                            {t('modules.settings.manageTeam.en')}
                          </MenuItem>
                        </Select>
                        {errors.language && (
                          <FormHelperText sx={getErrorLabelStyles}>
                            {errors.language}
                          </FormHelperText>
                        )}
                        {submitErrors?.includes(values.language) && (
                          <FormHelperText sx={getErrorLabelStyles}>
                            {t('messages.register.existed')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      mt: 6,
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Grid item xs={7.5}>
                      <FormControl fullWidth>
                        <InputLabel shrink={true} htmlFor="email" sx={getInputLabelStyles}>
                          {t('modules.settings.inviteUser.email')}
                        </InputLabel>
                        <TextField
                          name="email"
                          id="email"
                          fullWidth
                          placeholder={t('modules.settings.inviteUser.emailAddress')}
                          data-cy="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        {errors.email && (
                          <FormHelperText sx={getErrorLabelStyles}>{errors.email}</FormHelperText>
                        )}
                        {submitErrors?.includes(values.email) && (
                          <FormHelperText sx={getErrorLabelStyles}>
                            {t('messages.register.existed')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} sx={{ ml: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel shrink htmlFor="role" sx={getInputLabelStyles}>
                          {t('modules.settings.inviteUser.role')}
                        </InputLabel>
                        <Select
                          inputProps={{
                            name: 'role',
                            id: 'role',
                          }}
                          fullWidth
                          value={values.role}
                          displayEmpty
                          onChange={handleChange}
                          renderValue={selected => {
                            if (selected === undefined) {
                              return (
                                <Typography
                                  sx={{
                                    color: theme.palette.colors.shadeLightGrey,
                                    fontSize: 'inherit',
                                    variant: 'body2',
                                  }}
                                >
                                  {t('modules.settings.inviteUser.role')}
                                </Typography>
                              );
                            }
                            return t('shared.types.userRole.' + selected.toLowerCase());
                          }}
                        >
                          <MenuItem key={UserRole.ADMIN} value={UserRole.ADMIN}>
                            {t('shared.types.userRole.admin')}
                          </MenuItem>
                          <MenuItem key={UserRole.EMPLOYEE} value={UserRole.EMPLOYEE}>
                            {t('shared.types.userRole.employee')}
                          </MenuItem>
                        </Select>
                        {errors.role && (
                          <FormHelperText sx={getErrorLabelStyles}>{errors.role}</FormHelperText>
                        )}
                        {submitErrors?.includes(values.role) && (
                          <FormHelperText sx={getErrorLabelStyles}>
                            {t('messages.register.existed')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      mt: 2,
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                    }}
                  >
                    <Button
                      disabled={
                        Object.keys(errors).length > 0 ||
                        (values.email === '' && values.firstName === '' && values.lastName === '')
                      }
                      onClick={() => addMoreUsers(values, handleReset)}
                      sx={{
                        width: 'fit-content',
                        maxWidth: 300,
                        whiteSpace: 'nowrap',
                        pl: 0,
                      }}
                      startIcon={
                        <AddCircleOutlineOutlinedIcon
                          sx={{
                            width: 30,
                            height: 30,
                          }}
                        />
                      }
                      variant="text"
                    >
                      <Typography
                        variant="body2"
                        color={
                          Object.keys(errors).length > 0 ||
                          (values.email === '' && values.firstName === '' && values.lastName === '')
                            ? theme.palette.colors.shadeLightGrey
                            : theme.palette.primary.main
                        }
                      >
                        {t('modules.settings.inviteUser.addMore')}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              )}

              <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
                <Button size="small" variant="outlined" onClick={() => handleCancel(handleReset)}>
                  {t('modules.settings.inviteUser.cancel')}
                </Button>
                <Button
                  disabled={
                    Object.keys(errors).length > 0 ||
                    (values.email === '' &&
                      values.firstName === '' &&
                      values.lastName === '' &&
                      invitedUsers.length === 0)
                  }
                  onClick={() => inviteUsers(values)}
                  size="small"
                  variant="contained"
                  loading={loading}
                  sx={{ ml: 1 }}
                >
                  {t('modules.settings.inviteUser.sendInvitation')}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default InviteUserDialog;
