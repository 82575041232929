import React from 'react';
import { useTheme } from '@mui/material';

const NoDataIcon = (props: any) => {
  const theme = useTheme();
  return (
    <svg {...props} fill="none" viewBox="0 0 34 39">
      <path
        fill={theme.palette.primary.main}
        d="M16.426 19.15l2.954-2.955a.688.688 0 00-.973-.973l-2.954 2.955-2.955-2.955a.688.688 0 00-.973.973l2.955 2.955-2.955 2.954a.688.688 0 10.973.974l2.955-2.955 2.954 2.954a.688.688 0 00.974-.973l-2.955-2.954z"
      ></path>
      <path
        fill={theme.palette.primary.main}
        d="M32.39 32.846l-2.484-2.485V10.105a2.073 2.073 0 00-.647-1.5l-7.426-7.04A2.07 2.07 0 0020.41 1H3.065A2.072 2.072 0 001 3.065v32.56a2.065 2.065 0 002.065 2.065h24.777a2.058 2.058 0 001.872-1.204 2.09 2.09 0 002.395-.4l.282-.282a2.102 2.102 0 000-2.959zm-4.893-24.02h-5.161a.688.688 0 01-.689-.689V3.285l5.85 5.54zm.345 27.488H3.065a.688.688 0 01-.689-.688V3.066c0-.38.309-.689.689-.689H20.27v5.76a2.065 2.065 0 002.065 2.066h6.194v18.789l-2.354-2.354a.89.89 0 00-1.253 0l-.486.487-2.175-2.175a9.02 9.02 0 10-.974.973l2.175 2.175-.522.522a.895.895 0 000 1.26l5.588 5.589v.158a.69.69 0 01-.688.688zM15.453 26.72a7.571 7.571 0 110-15.142 7.571 7.571 0 010 15.142zm15.968 8.114l-.283.282a.723.723 0 01-1.011 0l-5.871-5.87 1.294-1.294 5.87 5.87c.276.281.276.73 0 1.012z"
      ></path>
      <path
        fill={theme.palette.primary.main}
        d="M16.426 19.15l2.954-2.955h0a.688.688 0 00-.973-.973l-2.954 2.955-2.955-2.955v0a.688.688 0 00-.973.973l2.955 2.955-2.955 2.954h0a.688.688 0 10.973.974l2.955-2.955 2.954 2.954v0a.688.688 0 00.974-.973l-2.955-2.954z"
      ></path>
      <path
        fill={theme.palette.primary.main}
        d="M32.39 32.846l-2.484-2.485V10.105a2.073 2.073 0 00-.647-1.5l-7.426-7.04A2.07 2.07 0 0020.41 1H3.065A2.072 2.072 0 001 3.065v32.56a2.065 2.065 0 002.065 2.065h24.777a2.058 2.058 0 001.872-1.204 2.09 2.09 0 002.395-.4l.282-.282a2.102 2.102 0 000-2.959h0zm-4.893-24.02h-5.161a.688.688 0 01-.689-.689V3.285l5.85 5.54zm.345 27.488H3.065a.688.688 0 01-.689-.688V3.066c0-.38.309-.689.689-.689H20.27v5.76a2.065 2.065 0 002.065 2.066h6.194v18.789l-2.354-2.354a.89.89 0 00-1.253 0l-.486.487-2.175-2.175v0a9.02 9.02 0 10-.974.973l2.175 2.175-.522.522a.895.895 0 000 1.26l5.588 5.589v.158a.69.69 0 01-.688.688h0zM15.453 26.72a7.571 7.571 0 110-15.142 7.571 7.571 0 010 15.142zm15.968 8.114l-.283.282a.723.723 0 01-1.011 0l-5.871-5.87 1.294-1.294 5.87 5.87c.276.281.276.73 0 1.012z"
      ></path>
    </svg>
  );
};

export default NoDataIcon;
