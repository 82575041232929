import React from 'react';

const ResetIcon = (props: any) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.4 2.2002C4.921 2.2002 2.1 5.0212 2.1 8.5002H0L2.8 11.3002L5.6 8.5002H3.5C3.5 5.7912 5.691 3.6002 8.4 3.6002C11.109 3.6002 13.3 5.7912 13.3 8.5002C13.3 11.2092 11.109 13.4002 8.4 13.4002C7.343 13.4002 6.363 13.0572 5.558 12.4902L4.564 13.4982C5.628 14.3102 6.958 14.8002 8.4 14.8002C11.879 14.8002 14.7 11.9792 14.7 8.5002C14.7 5.0212 11.879 2.2002 8.4 2.2002Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
};

export default ResetIcon;
