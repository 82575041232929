import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Grid,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
  InputLabel,
  Box,
  SelectChangeEvent,
} from '@mui/material';
import { getErrorLabelStyles } from 'src/modules/common/application/form-styles';
import { Company, EmployeesNum, UpdateCompanyInput } from 'src/modules/common/types/graphql';
import Button from 'src/modules/common/components/Button/Button';
import { ArrowForward } from '@mui/icons-material';
import theme from 'src/styles/theme';
import { getCompanyInfoSchema } from 'src/modules/common/application/welcome.schema';
import { useIndustry, useYears } from 'src/modules/common/application';
import { numberOfEmployeesToString } from 'src/modules/common/application/number-of-employees';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';

type EditedCompany = UpdateCompanyInput & { defaultYear: number };

interface Props {
  data: Company;
  onInformationSubmit: (data: EditedCompany) => void;
  onBack?: () => void;
  loading: boolean;
}

const EditCompanyInfo = ({ data, onInformationSubmit, onBack, loading }: Props) => {
  const { t } = useTranslation();
  const companySchema = getCompanyInfoSchema(t);
  const { availableYears, sortedYears } = useYears('cache-and-network');
  const { industries, subIndustries } = useIndustry();

  const [selectedIndustry, setSelectedIndustry] = useState<string | null>(data?.industry?.id);
  const [subIndustryOptions, setSubIndustryOptions] = useState(
    subIndustries?.filter(subIndustry => subIndustry.industry.id === data?.industry?.id)
  );

  const handleIndustriesChange = (event: SelectChangeEvent<string>) => {
    setSelectedIndustry(event.target.value as string);
    setSubIndustryOptions(
      subIndustries?.filter(
        subIndustry => subIndustry.industry.id === (event.target.value as string)
      )
    );
  };

  useEffect(() => {
    setSubIndustryOptions(
      subIndustries?.filter(subIndustry => subIndustry.industry.id === data?.industry?.id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subIndustries]);

  const initalFormState = {
    dashboardUrl: data?.dashboardUrl,
    employeesNum: data?.employeesNum,
    headquarter: data?.headquarter,
    name: data?.name,
    subIndustryId:
      data?.subIndustry?.id && data?.industry?.id === selectedIndustry
        ? data?.subIndustry?.id
        : undefined,
    defaultYear: sortedYears && sortedYears && sortedYears.length > 0 ? sortedYears[0] : '',
  } as EditedCompany;

  return (
    <div>
      <Formik
        initialValues={{
          ...initalFormState,
        }}
        enableReinitialize={true}
        validationSchema={companySchema}
        onSubmit={values => {
          onInformationSubmit(values);
        }}
        onReset={values => {
          values = initalFormState;
        }}
      >
        {({ submitForm, handleChange, errors, values }) => (
          <Form>
            <Grid container rowSpacing={1} sx={{ p: 0, mt: 2.5 }}>
              {/* name */}
              <Grid item xs={12} mb={2}>
                <FormControl fullWidth>
                  <TextField
                    id="name"
                    name="name"
                    type="text"
                    label={t('shared.types.company.name')}
                    value={values.name ? values.name : ''}
                    onChange={handleChange}
                    fullWidth
                    placeholder={t('shared.types.company.name')}
                    required
                    variant="outlined"
                  />
                  {errors.name && (
                    <FormHelperText sx={getErrorLabelStyles}>{errors.name}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* headquarters */}
              <Grid item xs={12} mb={2}>
                <FormControl fullWidth>
                  <TextField
                    id="headquarter"
                    name="headquarter"
                    type="text"
                    value={values.headquarter ? values.headquarter : ''}
                    label={t('modules.companyOnboarding.company.headquarter')}
                    required
                    variant="outlined"
                    onChange={handleChange}
                    fullWidth
                    placeholder={t('modules.companyOnboarding.company.headquarter')}
                  />
                  {errors.headquarter && (
                    <FormHelperText sx={getErrorLabelStyles}>{errors.headquarter}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* industries */}
              {industries && (
                <Grid item xs={12} mb={2}>
                  <FormControl fullWidth required>
                    <InputLabel htmlFor={'industry'}>
                      {t('modules.companyOnboarding.company.industry')}
                    </InputLabel>
                    <Select
                      id="industry"
                      value={selectedIndustry ? selectedIndustry : ''}
                      name="industry"
                      onChange={handleIndustriesChange}
                      label={t('modules.companyOnboarding.company.industry')}
                      variant="outlined"
                    >
                      {industries.map(industry => {
                        return (
                          <MenuItem key={industry.id} value={industry.id}>
                            {t(
                              'shared.types.industry.' + removeUnderscoreToCamelCase(industry.name)
                            )}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {/* sub industry */}
              {
                <Grid item xs={12} mb={2}>
                  <FormControl fullWidth required>
                    <InputLabel htmlFor={'subIndustryId'}>
                      {t('modules.companyOnboarding.company.subIndustry')}
                    </InputLabel>
                    <Select
                      id="subIndustryId"
                      value={values.subIndustryId ? values.subIndustryId : ''}
                      name="subIndustryId"
                      onChange={handleChange}
                      label={t('modules.companyOnboarding.company.subIndustry')}
                      variant="outlined"
                    >
                      {subIndustryOptions?.map(subIndustry => {
                        return (
                          <MenuItem key={subIndustry.id} value={subIndustry.id}>
                            {t(
                              'shared.types.subIndustry.' +
                                removeUnderscoreToCamelCase(subIndustry.name)
                            )}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {errors.subIndustryId && (
                      <FormHelperText sx={getErrorLabelStyles}>
                        {errors.subIndustryId as string}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              }

              {/* numberOfEmployees */}
              <Grid item xs={12} mb={2}>
                <FormControl fullWidth required>
                  <InputLabel htmlFor={'employeesNum'}>
                    {t('modules.companyOnboarding.company.numberOfEmployees')}
                  </InputLabel>
                  <Select
                    id="employeesNum-select"
                    value={values.employeesNum ? values.employeesNum : ''}
                    name="employeesNum"
                    onChange={handleChange}
                    label={t('modules.companyOnboarding.company.numberOfEmployees')}
                    variant="outlined"
                  >
                    {Object.values(EmployeesNum).map(num => (
                      <MenuItem key={num} value={num}>
                        {numberOfEmployeesToString(num)}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.employeesNum && (
                    <FormHelperText sx={getErrorLabelStyles}>
                      {errors.employeesNum as string}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* year */}
              <Grid item xs={12} mb={2}>
                <FormControl fullWidth required>
                  <InputLabel htmlFor={'defaultYear'}>
                    {t('modules.companyOnboarding.company.firstReportingYear')}
                  </InputLabel>
                  <Select
                    id="defaultYear"
                    name="defaultYear"
                    value={values.defaultYear}
                    label={t('modules.companyOnboarding.company.firstReportingYear')}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    {availableYears.map(year => (
                      <MenuItem key={year.year} value={year.year} disabled={year.disabled}>
                        {year.year}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.defaultYear && (
                    <FormHelperText sx={getErrorLabelStyles}>{errors.defaultYear}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* buttons */}
              <Grid
                item
                xs={12}
                mb={0}
                sx={{
                  mt: 2.5,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Button
                    onClick={onBack}
                    variant="outlined"
                    sx={{
                      fontSize: '14px',
                      pl: 2,
                      pr: 2,
                    }}
                  >
                    {t('modules.companyOnboarding.back')}
                  </Button>
                </Box>
                <Button
                  role="button"
                  aria-label="primary-button"
                  sx={{
                    padding: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  loading={loading}
                  onClick={submitForm}
                >
                  {t('modules.companyOnboarding.next')}
                  <ArrowForward
                    sx={{
                      color: theme.palette.colors.white,
                      pl: 1,
                      width: 32,
                      height: 32,
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditCompanyInfo;
