export const getFirstLetterCapital = (str: string) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase();
};

export const capitalizeFirstLetter = (str: string) => {
  if (!str) return str;
  str = removeSpacesAtStart(str);
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const camelCaseString = (str: string) => {
  if (!str) return str;
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

// https://stackoverflow.com/questions/5582228/insert-space-before-capital-letters
export const insertSpaces = (str: string) => {
  if (!str) return str;
  str = str.replace(/([a-z])([A-Z])/g, '$1 $2');
  str = str.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
  return str;
};

export const removeUnderscoreToCamelCase = (str: string) => {
  if (!str) return str;
  const tmp = str.split(/_/g);
  for (let i = 0; i < tmp.length; i++) {
    tmp[i] = tmp[i].toLowerCase();
    if (i !== 0) {
      tmp[i] = capitalizeFirstLetter(tmp[i]);
    }
  }
  str = tmp.join('');
  return str;
};

export const removeUnderscoreToFirstWordCapitalized = (str: string) => {
  if (!str) return str;
  const tmp = str.split(/_/g);
  for (let i = 0; i < tmp.length; i++) {
    tmp[i] = tmp[i].toLowerCase();
    if (i === 0) {
      tmp[i] = capitalizeFirstLetter(tmp[i]);
    }
  }
  str = tmp.join(' ');
  return str;
};

export const removeSpacesAtStart = (str: string) => {
  if (!str) return str;
  while (str.substring(0, 1) === ' ') {
    str = str.substring(1, str.length);
  }
  return str;
};

export default capitalizeFirstLetter;
