import {
  DataManagementIcon,
  EsgIcon,
  HomeIcon,
  LogoutIcon,
  SettingsIcon,
  ReportIcon,
  LanguageIcon,
  MaterialityAssessmentIcon,
} from 'src/modules/common/components/icons';
import { UserRole } from '../graphql';
import { IntercomDataAttribute } from '../intercom';

export const getMainNavigation = () => {
  return [
    {
      label: 'shared.standards.home',
      to: '/',
      allowedRoles: [UserRole.ADMIN],
      Icon: HomeIcon,
      intercomDataAttribute: IntercomDataAttribute.homeSideNavMenu,
    },
    {
      label: 'shared.standards.materialityAssessment',
      to: '/materiality-assessment',
      allowedRoles: [UserRole.ADMIN],
      Icon: MaterialityAssessmentIcon,
      intercomDataAttribute: IntercomDataAttribute.materialitySideNavMenu,
    },
    {
      label: 'shared.standards.dataManagement',
      to: '/data-management',
      allowedRoles: [UserRole.ADMIN, UserRole.EMPLOYEE],
      Icon: DataManagementIcon,
      intercomDataAttribute: IntercomDataAttribute.dataManagementSideNavMenu,
    },
    {
      label: 'shared.standards.analysis',
      to: '/analysis',
      allowedRoles: [UserRole.ADMIN, UserRole.EMPLOYEE],
      Icon: EsgIcon,
      intercomDataAttribute: IntercomDataAttribute.analysisSideNavMenu,
    },
    {
      label: 'shared.standards.reporting',
      to: '/reports',
      allowedRoles: [UserRole.ADMIN, UserRole.EMPLOYEE],
      Icon: ReportIcon,
      intercomDataAttribute: IntercomDataAttribute.reportingSideNavMenu,
    },
  ];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getBottomNavigation = (actions: any[] | undefined = undefined) => {
  return [
    {
      label: 'shared.standards.settings',
      handleClick: actions && actions.length > 0 ? actions[0] : null,
      Icon: SettingsIcon,
      dataCy: 'action-settings',
    },
    {
      label: 'shared.standards.language',
      handleClick: actions && actions.length > 0 ? actions[1] : null,
      Icon: LanguageIcon,
      dataCy: 'action-language',
    },
    {
      label: 'shared.standards.logout',
      Icon: LogoutIcon,
      handleClick: actions && actions.length > 2 ? actions[2] : null,
      dataCy: 'action-logout',
    },
  ];
};
