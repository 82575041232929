import React from 'react';
import { Box, Typography, useTheme, alpha } from '@mui/material';

interface CollectTabComponentProps {
  tabLabel: String;
  boxValue: String | undefined;
}

const CollectTabComponent = ({ tabLabel, boxValue }: CollectTabComponentProps) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography
        sx={{
          fontSize: '0.875rem !important',
          fontWeight: 600,
          lineHeight: 1.25,
          marginRight: 1,
        }}
      >
        {tabLabel}
      </Typography>
      {boxValue ? (
        <Box
          sx={{
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
            padding: 0.8,
          }}
        >
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: '0.875rem !important',
              fontWeight: 600,
              lineHeight: 1.25,
            }}
          >
            {boxValue}
          </Typography>
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default CollectTabComponent;
