export const EsgDataInputTableHeaders = [
  'modules.dataManagement.esgValueInput.kpi',
  'modules.dataManagement.esgValueInput.category',
  'modules.dataManagement.esgValueInput.value',
  'modules.dataManagement.esgValueInput.unit',
  'modules.dataManagement.esgValueInput.active',
];

export const EsgDataInputTableHeadersPublic = [
  'modules.dataManagement.esgValueInput.kpi',
  'modules.dataManagement.esgValueInput.category',
  'modules.dataManagement.esgValueInput.value',
  'modules.dataManagement.esgValueInput.unit',
];
