/* eslint-disable no-console */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Modes } from 'src/modules/common/types';
import { IntercomDataAttribute } from 'src/modules/common/types/intercom';

import { TabBar, Loader, Chip } from 'src/modules/common/components';
import useEsgValue from 'src/modules/common/application/use-esg-value';
import EsgDataInputTable from 'src/modules/data-management/components/EsgDataInputTable/EsgDataInputTable';
import EsgDataApproval from 'src/modules/data-management/components/EsgDataApproval/EsgDataApproval';
import { getActiveTabIndex } from 'src/modules/common/application/esgValue';
import { getYearChipColor } from 'src/modules/common/application/ui';
import useRequstedValueByOwner from 'src/modules/common/application/use-requsted-values-by-owner';
import CollectTabComponent from 'src/modules/data-management/components/CollectTabComponent/CollectTabComponent';
import { getIntercomDataAttribute } from 'src/modules/common/application/intercom';
import useCollectOverview from 'src/modules/data-management/application/use-collect-overview';
import Button from 'src/modules/common/components/Button/Button';
import useEsgKpi from 'src/modules/common/application/use-kpi';

const EsgDataInput = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { collectOverviewId } = useParams();
  const { getKpisByCategory } = useEsgKpi();

  const { collectOverviewById, collectOverviewByIdLoading } = useCollectOverview(
    undefined,
    undefined,
    undefined,
    collectOverviewId ? collectOverviewId.toString() : ''
  );

  const {
    esgValues,
    esgValueLoading,
    updateEsgValuesLoading,
    numTotalEsgValues,
    numFilledValues,
    handleUpdateEsgValues,
    updateEsgValueOnTableRowDataChange,
    updateEsgValuesOnActivateDectivateKpi,
  } = useEsgValue(collectOverviewId?.toString() || '');

  const { numPendingValues, requestedEsgValuesByOwnerLoading } = useRequstedValueByOwner(
    collectOverviewId?.toString() || ''
  );

  if (
    esgValueLoading ||
    collectOverviewByIdLoading ||
    requestedEsgValuesByOwnerLoading ||
    !esgValues ||
    !collectOverviewById ||
    !numPendingValues
  )
    return <Loader />;

  const { facility, category, year } = collectOverviewById;

  const kpis = getKpisByCategory(category.id);

  const pendingValues = numPendingValues();

  const handleTableRowDataChange = ({
    id,
    field,
    value,
  }: {
    id: string;
    field: string;
    value: string;
  }) => {
    updateEsgValueOnTableRowDataChange(id, field, value, esgValues!);
  };

  const handleOnActivateDectivateKpi = (id: string, isDeactivated: boolean) => {
    updateEsgValuesOnActivateDectivateKpi(id, isDeactivated);
  };

  const handleSaveClick = async () => {
    await handleUpdateEsgValues();
  };

  const TABS = [
    {
      input: (
        <>
          <EsgDataInputTable
            mode={Modes.PRIVATE}
            data={esgValues}
            onTableRowDataChange={handleTableRowDataChange}
            onActivateDectivateKpi={handleOnActivateDectivateKpi}
            handleSaveClick={handleSaveClick}
            loading={updateEsgValuesLoading}
            kpis={kpis}
          />
        </>
      ),
      label: (
        <>
          <CollectTabComponent
            tabLabel={t('modules.dataManagement.esgValueInput.dataTab')}
            boxValue={numFilledValues + '/' + numTotalEsgValues}
          />
        </>
      ),
      index: 0,
      id: 'facility-details-one',
      to: `/data-management/collect/${collectOverviewId}`,
    },
    {
      input: <EsgDataApproval />,
      label: (
        <>
          <CollectTabComponent
            tabLabel={t('modules.dataManagement.esgValueApproval.approvalTab')}
            boxValue={pendingValues ? pendingValues.toString() : undefined}
          />
        </>
      ),
      index: 1,
      id: 'facility-details-two',
      to: `/data-management/collect/${collectOverviewId}/approval`,
    },
  ];
  const activeTabIndex = getActiveTabIndex(pathname);

  return (
    <>
      <Box>
        <Box py={2}>
          <IconButton
            onClick={() => {
              navigate('/data-management/collect');
            }}
          >
            <ArrowBackIcon color="primary" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            paddingTop: 1,
            paddingBottom: 2,
          }}
          data-intercom-target={getIntercomDataAttribute(
            IntercomDataAttribute.kpiDetailHeaderContainer
          )}
        >
          <Typography variant="h4" marginRight={2}>
            {facility?.name}
          </Typography>
          <Chip
            colorVariant={getYearChipColor(year)}
            styles={{
              maxWidth: 'fit-content',
            }}
          >
            {year}
          </Chip>
        </Box>
        <TabBar
          content={TABS}
          activeTabIndex={activeTabIndex}
          SideComponent={() => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  right: 0,
                  alignItems: 'flex-end',
                }}
              >
                {activeTabIndex === 0 && (
                  <Button
                    size="small"
                    loading={updateEsgValuesLoading}
                    onClick={handleSaveClick}
                    data-intercom-target={getIntercomDataAttribute(
                      IntercomDataAttribute.kpiDetailSaveButton
                    )}
                  >
                    {t(`modules.dataManagement.esgValueInput.save`)}
                  </Button>
                )}
              </Box>
            );
          }}
          sideComponentStyles={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        />
      </Box>
    </>
  );
};

export default EsgDataInput;
