import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { UserForSuper } from 'src/modules/common/types';

interface Props {
  users: UserForSuper[] | undefined;
  selected: UserForSuper | undefined;
  onSelect: (value: string) => void;
}

const UserList = ({ users, onSelect, selected }: Props) => {
  return (
    <Select
      sx={{
        marginBottom: 2,
        '&.MuiInputBase-root': {
          width: '100%',
        },
      }}
      id="user-select"
      name="user"
      value={selected ? selected.id : ''}
      IconComponent={KeyboardArrowDownIcon}
      onChange={(e: SelectChangeEvent) => onSelect(e.target.value)}
    >
      {users?.map(user => (
        <MenuItem key={user.id} value={user.id}>
          {user.email} - {user.firstName} {user.lastName}
        </MenuItem>
      ))}
    </Select>
  );
};

export default UserList;
