import React, { memo, useEffect } from 'react';
import {
  Button,
  ButtonProps,
  CircularProgress,
  InputLabelProps,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';

import { ProgressContainer, ButtonLabel } from './styles';
import { getButtonColorContrast } from './utils';

interface Props {
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  labelVariant?: InputLabelProps['variant'];
  buttonLabelStyles?: SxProps<Theme>;
}

const AppButton = ({
  children,
  loading,
  disabled,
  onClick,
  type,
  color = 'primary',
  variant = 'contained',
  labelVariant = 'filled',
  buttonLabelStyles,
  ...rest
}: Props & ButtonProps) => {
  const theme = useTheme();

  const handleSubmit = (event: KeyboardEvent) => {
    if (type === 'submit' && event.key === 'Enter' && typeof onClick === 'function') {
      // @ts-ignore eslint-disable-next-line
      onClick(event);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleSubmit);

    return () => window.removeEventListener('keydown', handleSubmit);
  }, []); // eslint-disable-line
  const buttonColor = getButtonColorContrast(variant, theme);
  const labelVariantFromButtonVariant = variant === 'contained' ? 'filled' : 'outlined';

  return (
    <Button
      role="button"
      sx={{
        color: buttonColor,
        padding: rest.size === 'small' ? '0.75rem 1rem' : '1rem 1.5rem',
        borderRadius: '5px',
        cursor: 'pointer',
      }}
      type={type}
      onClick={onClick}
      disabled={loading || disabled}
      color={color}
      variant={variant}
      {...rest}
    >
      {loading && (
        <ProgressContainer
          data-testid="progress-container"
          variant={variant}
          visible={loading ? 1 : 0}
          theme={theme}
          buttoncolor={color}
        >
          <CircularProgress size={16} thickness={5} />
        </ProgressContainer>
      )}
      <ButtonLabel
        visible={!loading ? 1 : 0}
        theme={theme}
        buttoncolor={color}
        variant={labelVariantFromButtonVariant as InputLabelProps['variant']}
        size={rest.size === 'small' ? 'small' : 'normal'}
        sx={{ ...buttonLabelStyles }}
      >
        {children}
      </ButtonLabel>
    </Button>
  );
};

export default AppButton;

export const MemorizedButton = memo(AppButton, (prevProps, nextProps) => {
  return (
    prevProps.loading === nextProps.loading &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.children === nextProps.children &&
    prevProps.buttonLabelStyles === nextProps.buttonLabelStyles
  );
});
