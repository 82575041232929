import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { getInputLabelStyles } from 'src/modules/common/application/form-styles';
import { UserRole } from 'src/modules/common/types';
import { ManageUser } from '../ManageTeam/types';

interface Props {
  user: ManageUser;
  open: boolean;
  onClose: () => void;
  handleChangePermission: (user: ManageUser) => void;
  handleRoleChange: (role: UserRole) => void;
}

const ChangePermissionDialog = ({
  user,
  handleRoleChange,
  open,
  onClose,
  handleChangePermission,
}: Props) => {
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent<UserRole>) => {
    handleRoleChange(event.target.value as UserRole);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
        <Typography gutterBottom variant="h5">
          {t('modules.settings.changePermission.title')}
        </Typography>
        <Typography gutterBottom variant="body2">
          {t('modules.settings.changePermission.info')}
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 5 }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="email" sx={getInputLabelStyles}>
              {t('shared.types.user.email')}
            </InputLabel>
            <TextField disabled name="email" id="email" value={user.email}></TextField>
          </FormControl>

          <FormControl fullWidth sx={{ ml: 1 }}>
            <InputLabel htmlFor="role" sx={getInputLabelStyles}>
              {t('shared.types.user.role')}
            </InputLabel>
            <Select
              inputProps={{
                name: 'role',
                id: 'role',
              }}
              value={user.role}
              onChange={handleChange}
              sx={{ width: '100%' }}
            >
              <MenuItem value="ADMIN">{t('shared.types.userRole.admin')}</MenuItem>
              <MenuItem value="EMPLOYEE">{t('shared.types.userRole.employee')}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
          <Button size="small" variant="outlined" onClick={onClose}>
            {t('modules.settings.inviteUser.cancel')}
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={() => handleChangePermission(user)}
            sx={{ ml: 3 }}
          >
            {t('modules.settings.changePermission.update')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ChangePermissionDialog;
