import { useTranslation } from 'react-i18next';
import {
  CollectOverview,
  CollectOverviewStatus,
  EsgCategoryEnum,
  User,
} from 'src/modules/common/types';
import useEnhancedQuery from 'src/modules/common/application/use-enhanced-query';
import { TransformedCollectOverview } from 'src/modules/common/types/collect';
import { COLLECT_OVERVIEWS, COLLECT_OVERVIEW_BY_ID } from 'src/services/graphql/queries/collect';

import { useMessages } from 'src/modules/common/application';

const useCollectOverview = (
  year?: number,
  accessToken?: string | null,
  users?: User[],
  id?: string
) => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();

  const { loading, data } = useEnhancedQuery<{ collectOverviews: CollectOverview[] }>(
    COLLECT_OVERVIEWS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        year,
      },
      onError: () => {
        setErrorMessage(t('messages.generic.error'));
      },
      skip: !!id || !year,
      context: {
        headers: {
          authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          'x-user-type': accessToken ? 'external' : 'internal',
        },
      },
    }
  );

  const { data: collectOverviewById, loading: collectOverviewByIdLoader } = useEnhancedQuery<{
    collectOverviewById: CollectOverview;
  }>(COLLECT_OVERVIEW_BY_ID, {
    fetchPolicy: 'cache-first',
    variables: {
      id,
    },
    onError: () => {
      setErrorMessage(t('messages.generic.error'));
    },
    skip: !id,
  });

  const collectOverviewStats = {} as Record<CollectOverviewStatus, number>;

  const getCollectOverviewStatus = (overview: CollectOverview): CollectOverviewStatus => {
    const currentDate = new Date();

    return overview.status === 'COMPLETED'
      ? CollectOverviewStatus.COMPLETED
      : overview.dueDate !== null && new Date(overview.dueDate)?.getTime() < currentDate.getTime()
      ? CollectOverviewStatus.OVERDUE
      : overview.status;
  };

  const collectOverviewCategories = [
    ...new Set(data?.collectOverviews.map(collectOverview => collectOverview.category)),
  ];

  const collectOverviewFacilities = [
    ...new Set(data?.collectOverviews.map(collectOverview => collectOverview.facility)),
  ];

  const getCollectOverviewFacilitiesByCategoryId = (categoryId: string) => {
    const facilitiesByCategoryId = data?.collectOverviews
      .filter(collectOverview => collectOverview.category.id === categoryId)
      .map(collectOverview => collectOverview.facility);
    return [...new Set(facilitiesByCategoryId)];
  };

  const enhancedData = data?.collectOverviews.map(collectOverview => {
    const userData = users
      ? users.find(user => user.id === collectOverview.assignedToId)
      : undefined;

    const collectOverviewStatus = getCollectOverviewStatus(collectOverview);
    collectOverviewStats[collectOverviewStatus] =
      (collectOverviewStats[collectOverviewStatus] || 0) + 1;
    return {
      id: collectOverview.id,
      facilityName: collectOverview.facility.name,
      category: collectOverview.category.title as EsgCategoryEnum,
      year: collectOverview.year,
      assignedTo: userData,
      progress: collectOverview.progress,
      requestedProgress: collectOverview.requestedProgress,
      dueDate: collectOverview.dueDate,
      status: collectOverviewStatus,
      facilityId: collectOverview.facility.id,
      categoryId: collectOverview.category.id,
      assignedToId: collectOverview.assignedToId,
    } as TransformedCollectOverview;
  });

  return {
    collectOverviewLoading: loading,
    collectOverviewByIdLoading: collectOverviewByIdLoader,
    collectOverviews: enhancedData,
    collectOverviewById: collectOverviewById?.collectOverviewById,
    collectOverviewStats: collectOverviewStats || {},
    collectOverviewCategories: collectOverviewCategories || [],
    collectOverviewFacilities: collectOverviewFacilities || [],
    getCollectOverviewFacilitiesByCategoryId,
  };
};

export default useCollectOverview;
