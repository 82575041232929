import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  UUID: any;
  Void: any;
};

export type AiReportSimilarity = {
  __typename?: 'AiReportSimilarity';
  chapter: Scalars['String'];
  similarity?: Maybe<Scalars['Float']>;
};

export enum ApprovalStatus {
  APPROVED = 'APPROVED',
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export type AssignCollectInput = {
  collectOverviewId: Scalars['UUID'];
  userId?: InputMaybe<Scalars['UUID']>;
};

export type AssignFacilityOwnerInput = {
  facilityId: Scalars['String'];
  userId?: InputMaybe<Scalars['UUID']>;
};

export type ChatMessage = {
  content: Scalars['String'];
  role: Scalars['String'];
};

export type CollectFilter = {
  __typename?: 'CollectFilter';
  assignedTo: Array<Scalars['String']>;
  categories: Array<Scalars['String']>;
  facilities: Array<Scalars['String']>;
  orderDirection: SortingOrder;
  orderField: Scalars['String'];
  rowsPerPageCount: Scalars['Int'];
  statuses: Array<CollectOverviewStatus>;
  userId: Scalars['UUID'];
  year: Scalars['Int'];
};

export type CollectFilterInput = {
  assignedTo: Array<Scalars['String']>;
  categories: Array<Scalars['String']>;
  facilities: Array<Scalars['String']>;
  orderDirection: SortingOrder;
  orderField: Scalars['String'];
  rowsPerPageCount: Scalars['Int'];
  statuses: Array<CollectOverviewStatus>;
  year: Scalars['Int'];
};

export type CollectOverview = {
  __typename?: 'CollectOverview';
  assignedById?: Maybe<Scalars['UUID']>;
  assignedToId?: Maybe<Scalars['UUID']>;
  category: EsgCategory;
  dueDate?: Maybe<Scalars['DateTime']>;
  facility: Facility;
  id: Scalars['UUID'];
  progress: Scalars['Int'];
  requestedProgress?: Maybe<Scalars['Int']>;
  status: CollectOverviewStatus;
  year: Scalars['Int'];
};

export enum CollectOverviewStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  OVERDUE = 'OVERDUE',
}

export type CollectReminder = {
  __typename?: 'CollectReminder';
  collectOverviewId: Scalars['UUID'];
  createDate: Scalars['DateTime'];
  executionDate: Scalars['DateTime'];
  id: Scalars['UUID'];
  recipientId: Scalars['UUID'];
  status: ReminderStatus;
};

export type Company = {
  __typename?: 'Company';
  dashboardUrl?: Maybe<Scalars['String']>;
  employeesNum: EmployeesNum;
  headquarter: Scalars['String'];
  id: Scalars['UUID'];
  industry?: Maybe<Industry>;
  name: Scalars['String'];
  status: CompanyStatus;
  subIndustry?: Maybe<SubIndustry>;
};

export enum CompanyStatus {
  COMPANY_ACTIVE = 'COMPANY_ACTIVE',
  COMPANY_CREATED = 'COMPANY_CREATED',
  COMPANY_INFO_COMPLETED = 'COMPANY_INFO_COMPLETED',
}

export type CompletedFacilities = {
  __typename?: 'CompletedFacilities';
  completedFacilities: Scalars['Int'];
  totalFacilities: Scalars['Int'];
};

export enum CountryCode {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  XK = 'XK',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

export type CreateCompanyBySuperInput = {
  dashboardUrl?: InputMaybe<Scalars['String']>;
  employeesNum: EmployeesNum;
  headquarter: Scalars['String'];
  name: Scalars['String'];
  status: CompanyStatus;
  subIndustryId?: InputMaybe<Scalars['UUID']>;
};

export type CreateExternalUserInput = {
  collectOverviewIds: Array<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  language: LanguageCode;
  lastName: Scalars['String'];
};

export type CreateFacilityBySuperInput = {
  city: Scalars['String'];
  companyId: Scalars['UUID'];
  country: CountryCode;
  name: Scalars['String'];
  type: FacilityType;
};

export type CreateFacilityInput = {
  city: Scalars['String'];
  country: CountryCode;
  name: Scalars['String'];
  type: FacilityType;
};

export type CreateMaterialityAssessmentTopicResponse = {
  __typename?: 'CreateMaterialityAssessmentTopicResponse';
  id?: Maybe<Scalars['UUID']>;
  materialityAssessmentId: Scalars['String'];
  status: MaterialityAssessmentStatus;
};

export type CreateReportInput = {
  framework: ReportingFramework;
  title: Scalars['String'];
  year: Scalars['Int'];
};

export enum EmployeesNum {
  N_1_10 = 'N_1_10',
  N_11_50 = 'N_11_50',
  N_51_200 = 'N_51_200',
  N_201_500 = 'N_201_500',
  N_501_1000 = 'N_501_1000',
  N_1001_10000 = 'N_1001_10000',
}

export type EnvironmentalEmissions = {
  __typename?: 'EnvironmentalEmissions';
  directEmissions: Scalars['Float'];
  emissionIntensity: Scalars['Float'];
  environmentalEsgProgress: Scalars['Int'];
  facilityIndirectConsumption: Array<FacilityIndirectConsumption>;
  indirectEmissions: Scalars['Float'];
  totalNonRenewableEnergy: Scalars['Float'];
  totalRenewableEnergy: Scalars['Float'];
  waterConsumption: Array<WaterConsumptionEmissionsFacility>;
};

export enum EsgArea {
  ENVIRONMENT = 'ENVIRONMENT',
  GOVERNANCE = 'GOVERNANCE',
  SOCIAL = 'SOCIAL',
}

export type EsgCategory = {
  __typename?: 'EsgCategory';
  area: EsgArea;
  id: Scalars['String'];
  title: Scalars['String'];
};

export type EsgKpi = {
  __typename?: 'EsgKpi';
  category: EsgCategory;
  description?: Maybe<Scalars['String']>;
  frameworks: ReportingFrameworks;
  id: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  title: Kpi;
  type?: Maybe<KpiType>;
  units: Array<Maybe<Unit>>;
};

export type EsgKpiInput = {
  esgKpiId: Scalars['String'];
  selectedUnit?: InputMaybe<Unit>;
  value?: InputMaybe<Scalars['Float']>;
};

export type EsgProgressByCategory = {
  __typename?: 'EsgProgressByCategory';
  category: EsgCategory;
  progress: Scalars['Int'];
};

export type EsgProgressByFacility = {
  __typename?: 'EsgProgressByFacility';
  environment?: Maybe<Scalars['Int']>;
  facilityId: Scalars['UUID'];
  facilityName: Scalars['String'];
  governance?: Maybe<Scalars['Int']>;
  social?: Maybe<Scalars['Int']>;
};

export type EsgScore = {
  __typename?: 'EsgScore';
  environmentScore?: Maybe<Scalars['Int']>;
  governanceScore?: Maybe<Scalars['Int']>;
  socialScore?: Maybe<Scalars['Int']>;
};

export type EsgValue = {
  __typename?: 'EsgValue';
  collectOverview: CollectOverview;
  id: Scalars['String'];
  kpi: EsgKpi;
  selectedUnit?: Maybe<Unit>;
  value?: Maybe<Scalars['Float']>;
};

export type EsgValueAgg = {
  __typename?: 'EsgValueAgg';
  kpi: EsgKpi;
  selectedUnit?: Maybe<Unit>;
  value?: Maybe<Scalars['Float']>;
  year: Scalars['Int'];
};

export type EsgValueExport = {
  __typename?: 'EsgValueExport';
  category: Scalars['String'];
  facility: Scalars['String'];
  kpiTitle: Scalars['String'];
  unit: Scalars['String'];
  value: Scalars['Float'];
  year: Scalars['Int'];
};

export type EsgValueInput = {
  esgKpiId: Scalars['String'];
  id: Scalars['String'];
  selectedUnit?: InputMaybe<Unit>;
  value?: InputMaybe<Scalars['Float']>;
};

export type EsgValueOutput = {
  __typename?: 'EsgValueOutput';
  id: Scalars['String'];
  selectedUnit?: Maybe<Unit>;
  value?: Maybe<Scalars['Float']>;
};

export type Facility = {
  __typename?: 'Facility';
  city: Scalars['String'];
  companyId: Scalars['UUID'];
  contributors?: Maybe<Array<Maybe<User>>>;
  country: CountryCode;
  id: Scalars['UUID'];
  name: Scalars['String'];
  progress?: Maybe<Scalars['Int']>;
  type: FacilityType;
};

export type FacilityEsgValueInput = {
  esgValues: Array<EsgKpiInput>;
  facilityId: Scalars['String'];
  year: Scalars['Int'];
};

export type FacilityGender = {
  __typename?: 'FacilityGender';
  facility: Facility;
  femaleEmployees?: Maybe<Scalars['Int']>;
  maleEmployees?: Maybe<Scalars['Int']>;
};

export type FacilityIndirectConsumption = {
  __typename?: 'FacilityIndirectConsumption';
  Energy: Scalars['Float'];
  Facility: Facility;
  Gas: Scalars['Float'];
};

export enum FacilityType {
  LOGISTICS = 'LOGISTICS',
  MANUFACTURING = 'MANUFACTURING',
  OFFICE = 'OFFICE',
  SERVICE = 'SERVICE',
  WAREHOUSE = 'WAREHOUSE',
}

export type FlatCollectOverview = {
  __typename?: 'FlatCollectOverview';
  assignedById?: Maybe<Scalars['UUID']>;
  assignedToId?: Maybe<Scalars['UUID']>;
  categoryId: Scalars['String'];
  dueDate?: Maybe<Scalars['DateTime']>;
  facilityId: Scalars['String'];
  id: Scalars['UUID'];
  progress: Scalars['Int'];
  requestedProgress?: Maybe<Scalars['Int']>;
  status: CollectOverviewStatus;
  year: Scalars['Int'];
};

export type GeneralStatistic = {
  __typename?: 'GeneralStatistic';
  completedFacilities?: Maybe<CompletedFacilities>;
  esgProgressByCategory?: Maybe<Array<Maybe<EsgProgressByCategory>>>;
  esgProgressByFacility?: Maybe<Array<Maybe<EsgProgressByFacility>>>;
  numberOfCompletedEsgValues?: Maybe<Scalars['Int']>;
  overallEsgProgress?: Maybe<Scalars['Int']>;
};

export type GroupedRequestedEsgValuesByExternalUser = {
  __typename?: 'GroupedRequestedEsgValuesByExternalUser';
  esgValues?: Maybe<Array<RequestedEsgValuesByOwner>>;
  externalUserEmail: Scalars['String'];
  requestStatus: Status;
};

export type Industry = {
  __typename?: 'Industry';
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type InternalExternalUserOutput = {
  __typename?: 'InternalExternalUserOutput';
  email: Scalars['String'];
};

export type InviteUserError = {
  __typename?: 'InviteUserError';
  error: Scalars['String'];
  existingEmails: Array<Scalars['String']>;
};

export type InviteUserInput = {
  companyId?: InputMaybe<Scalars['UUID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  language: LanguageCode;
  lastName: Scalars['String'];
  role: UserRole;
};

export type InviteUserResponse = InviteUserError | InviteUserSuccess;

export type InviteUserSuccess = {
  __typename?: 'InviteUserSuccess';
  users: Array<User>;
};

export enum Kpi {
  AMOUNT_FINES_VIOLATION_ANTI_CORRUPTION_LAWS = 'AMOUNT_FINES_VIOLATION_ANTI_CORRUPTION_LAWS',
  AVERAGE_EXPENSES_ON_TRAINING_PER_EMPLOYEE = 'AVERAGE_EXPENSES_ON_TRAINING_PER_EMPLOYEE',
  AVERAGE_HOURS_OF_ANTI_CORRUPTION_AND_ANTI_BRIBERY_TRAINING_FOR_MEMBERS_OF_MANAGEMENT_AND_SUPERVISORY_BOARDS = 'AVERAGE_HOURS_OF_ANTI_CORRUPTION_AND_ANTI_BRIBERY_TRAINING_FOR_MEMBERS_OF_MANAGEMENT_AND_SUPERVISORY_BOARDS',
  AVERAGE_HOURS_OF_TRAINING_PER_FEMALE_EMPLOYEE = 'AVERAGE_HOURS_OF_TRAINING_PER_FEMALE_EMPLOYEE',
  AVERAGE_HOURS_OF_TRAINING_PER_MALE_EMPLOYEE = 'AVERAGE_HOURS_OF_TRAINING_PER_MALE_EMPLOYEE',
  AVERAGE_NUMBER_TRAINING_HOURS_FULL_TIME_EMPLOYEES = 'AVERAGE_NUMBER_TRAINING_HOURS_FULL_TIME_EMPLOYEES',
  AVERAGE_NUMBER_TRAINING_HOURS_PART_TIME_EMPLOYEES = 'AVERAGE_NUMBER_TRAINING_HOURS_PART_TIME_EMPLOYEES',
  DIRECT_ECONOMIC_GENERATED_REVENUES = 'DIRECT_ECONOMIC_GENERATED_REVENUES',
  ECONOMIC_DISTRIBUTED = 'ECONOMIC_DISTRIBUTED',
  EMISSION_FACTOR_ELECTRICITY = 'EMISSION_FACTOR_ELECTRICITY',
  FINES_PENALTIES_WORKFORCE_RELATED_ISSUES = 'FINES_PENALTIES_WORKFORCE_RELATED_ISSUES',
  FINES_SOCIAL_HUMAN_RIGHTS_VIOLATIONS = 'FINES_SOCIAL_HUMAN_RIGHTS_VIOLATIONS',
  MONETARY_NON_COMPLIANCE_FINES = 'MONETARY_NON_COMPLIANCE_FINES',
  NUMBER_ACTIONS_ANTI_COMPETITIVE = 'NUMBER_ACTIONS_ANTI_COMPETITIVE',
  NUMBER_CASES_RECORDABLE_WORK_RELATED_ILL_HEALTH = 'NUMBER_CASES_RECORDABLE_WORK_RELATED_ILL_HEALTH',
  NUMBER_COMPLAINTS_FILED_OWN_WORKERS = 'NUMBER_COMPLAINTS_FILED_OWN_WORKERS',
  NUMBER_CONFIRMED_CASES_CORRUPTION = 'NUMBER_CONFIRMED_CASES_CORRUPTION',
  NUMBER_CONVICTIONS_VIOLATION_ANTI_CORRUPTION = 'NUMBER_CONVICTIONS_VIOLATION_ANTI_CORRUPTION',
  NUMBER_CORRUPTION_AGAINST_EMPLOYEES = 'NUMBER_CORRUPTION_AGAINST_EMPLOYEES',
  NUMBER_EMPLOYEES_30_50 = 'NUMBER_EMPLOYEES_30_50',
  NUMBER_EMPLOYEES_COVERED_BY_HEALTH_POLICY = 'NUMBER_EMPLOYEES_COVERED_BY_HEALTH_POLICY',
  NUMBER_EMPLOYEES_DISABILITIES = 'NUMBER_EMPLOYEES_DISABILITIES',
  NUMBER_EMPLOYEES_ENTITLED_FAMILY_LEAVE = 'NUMBER_EMPLOYEES_ENTITLED_FAMILY_LEAVE',
  NUMBER_EMPLOYEES_INJURIES = 'NUMBER_EMPLOYEES_INJURIES',
  NUMBER_EMPLOYEES_MANAGERIAL_LEVEL = 'NUMBER_EMPLOYEES_MANAGERIAL_LEVEL',
  NUMBER_EMPLOYEES_NON_MANAGERIAL_LEVEL = 'NUMBER_EMPLOYEES_NON_MANAGERIAL_LEVEL',
  NUMBER_EMPLOYEES_OCCUPATIONAL_ACCIDENTS = 'NUMBER_EMPLOYEES_OCCUPATIONAL_ACCIDENTS',
  NUMBER_EMPLOYEES_OVER_50 = 'NUMBER_EMPLOYEES_OVER_50',
  NUMBER_EMPLOYEES_TOOK_FAMILY_LEAVE = 'NUMBER_EMPLOYEES_TOOK_FAMILY_LEAVE',
  NUMBER_EMPLOYEES_UNDER_30 = 'NUMBER_EMPLOYEES_UNDER_30',
  NUMBER_EMPLOYEES_WITH_WORK_RELATED_ILLNESS = 'NUMBER_EMPLOYEES_WITH_WORK_RELATED_ILLNESS',
  NUMBER_EMPLOYEE_CORRUPTION_DISMISSALS_DISCIPLINE = 'NUMBER_EMPLOYEE_CORRUPTION_DISMISSALS_DISCIPLINE',
  NUMBER_FATALITIES_WORK_RELATED_ILL_HEALTH_EMPLOYEES = 'NUMBER_FATALITIES_WORK_RELATED_ILL_HEALTH_EMPLOYEES',
  NUMBER_FATALITIES_WORK_RELATED_ILL_HEALTH_NON_EMPLOYEES = 'NUMBER_FATALITIES_WORK_RELATED_ILL_HEALTH_NON_EMPLOYEES',
  NUMBER_FATALITIES_WORK_RELATED_INJURIES_NON_EMPLOYEES = 'NUMBER_FATALITIES_WORK_RELATED_INJURIES_NON_EMPLOYEES',
  NUMBER_FEMALE_EMPLOYEES_DISABILITIES = 'NUMBER_FEMALE_EMPLOYEES_DISABILITIES',
  NUMBER_FEMALE_EMPLOYEES_MANAGERIAL_LEVEL = 'NUMBER_FEMALE_EMPLOYEES_MANAGERIAL_LEVEL',
  NUMBER_FEMALE_EMPLOYEES_NON_MANAGERIAL_LEVEL = 'NUMBER_FEMALE_EMPLOYEES_NON_MANAGERIAL_LEVEL',
  NUMBER_FEMALE_EMPLOYEES_TOOK_FAMILY_LEAVE = 'NUMBER_FEMALE_EMPLOYEES_TOOK_FAMILY_LEAVE',
  NUMBER_FEMALE_FULL_TIME_EMPLOYEES = 'NUMBER_FEMALE_FULL_TIME_EMPLOYEES',
  NUMBER_FEMALE_PART_TIME_EMPLOYEES = 'NUMBER_FEMALE_PART_TIME_EMPLOYEES',
  NUMBER_FEMALE_PERMANENT_EMPLOYEES = 'NUMBER_FEMALE_PERMANENT_EMPLOYEES',
  NUMBER_FEMALE_TEMPORARY_EMPLOYEES = 'NUMBER_FEMALE_TEMPORARY_EMPLOYEES',
  NUMBER_FULLTIME_EMPLOYEES = 'NUMBER_FULLTIME_EMPLOYEES',
  NUMBER_FULL_TIME_EMPLOYEES_PERFORMANCE_REVIEWS = 'NUMBER_FULL_TIME_EMPLOYEES_PERFORMANCE_REVIEWS',
  NUMBER_HIGH_CONSEQUENCE_WORK_RELATED_INJURIES_NON_EMPLOYEES = 'NUMBER_HIGH_CONSEQUENCE_WORK_RELATED_INJURIES_NON_EMPLOYEES',
  NUMBER_LEFT_EMPLOYEES = 'NUMBER_LEFT_EMPLOYEES',
  NUMBER_MALE_EMPLOYEES = 'NUMBER_MALE_EMPLOYEES',
  NUMBER_MALE_EMPLOYEES_DISABILITIES = 'NUMBER_MALE_EMPLOYEES_DISABILITIES',
  NUMBER_MALE_EMPLOYEES_TOOK_FAMILY_LEAVE = 'NUMBER_MALE_EMPLOYEES_TOOK_FAMILY_LEAVE',
  NUMBER_MALE_FULL_TIME_EMPLOYEES = 'NUMBER_MALE_FULL_TIME_EMPLOYEES',
  NUMBER_MALE_PART_TIME_EMPLOYEES = 'NUMBER_MALE_PART_TIME_EMPLOYEES',
  NUMBER_MALE_PERMANENT_EMPLOYEES = 'NUMBER_MALE_PERMANENT_EMPLOYEES',
  NUMBER_MALE_TEMPORARY_EMPLOYEES = 'NUMBER_MALE_TEMPORARY_EMPLOYEES',
  NUMBER_NEW_HIRE = 'NUMBER_NEW_HIRE',
  NUMBER_NON_COMPLIANCE_LAWS_REGULATIONS = 'NUMBER_NON_COMPLIANCE_LAWS_REGULATIONS',
  NUMBER_NON_GUARANTEED_HOURS_EMPLOYEES = 'NUMBER_NON_GUARANTEED_HOURS_EMPLOYEES',
  NUMBER_OF_BOARD_MEMBERS_WITH_PUBLIC_ADMINISTRATION_EXPERIENCE = 'NUMBER_OF_BOARD_MEMBERS_WITH_PUBLIC_ADMINISTRATION_EXPERIENCE',
  NUMBER_OF_EMPLOYEES_RECEIVED_PERFORMANCE_REVIEWS = 'NUMBER_OF_EMPLOYEES_RECEIVED_PERFORMANCE_REVIEWS',
  NUMBER_OF_FATALITIES_FROM_WORK_RELATED_INJURIES_EMPLOYEES = 'NUMBER_OF_FATALITIES_FROM_WORK_RELATED_INJURIES_EMPLOYEES',
  NUMBER_OF_FEMALE_EMPLOYEES_RECEIVED_PERFORMANCE_REVIEWS = 'NUMBER_OF_FEMALE_EMPLOYEES_RECEIVED_PERFORMANCE_REVIEWS',
  NUMBER_OF_FEMALE_MEMBERS_IN_MANAGEMENT_AND_SUPERVISORY_BOARDS = 'NUMBER_OF_FEMALE_MEMBERS_IN_MANAGEMENT_AND_SUPERVISORY_BOARDS',
  NUMBER_OF_FEMALE_NON_GUARANTEED_HOUR_EMPLOYEES = 'NUMBER_OF_FEMALE_NON_GUARANTEED_HOUR_EMPLOYEES',
  NUMBER_OF_HOURS_WORKED_EMPLOYEES = 'NUMBER_OF_HOURS_WORKED_EMPLOYEES',
  NUMBER_OF_HOURS_WORKED_NON_EMPLOYEES = 'NUMBER_OF_HOURS_WORKED_NON_EMPLOYEES',
  NUMBER_OF_INCIDENTS_OF_DISCRIMINATION = 'NUMBER_OF_INCIDENTS_OF_DISCRIMINATION',
  NUMBER_OF_MALE_EMPLOYEES_RECEIVED_PERFORMANCE_REVIEWS = 'NUMBER_OF_MALE_EMPLOYEES_RECEIVED_PERFORMANCE_REVIEWS',
  NUMBER_OF_MALE_NON_GUARANTEED_HOUR_EMPLOYEES = 'NUMBER_OF_MALE_NON_GUARANTEED_HOUR_EMPLOYEES',
  NUMBER_OF_MEMBERS_IN_MANAGEMENT_AND_SUPERVISORY_BOARDS = 'NUMBER_OF_MEMBERS_IN_MANAGEMENT_AND_SUPERVISORY_BOARDS',
  NUMBER_OF_NON_EMPLOYED_WORKERS = 'NUMBER_OF_NON_EMPLOYED_WORKERS',
  NUMBER_OF_PERMANENT_EMPLOYEES = 'NUMBER_OF_PERMANENT_EMPLOYEES',
  NUMBER_OF_RECORDABLE_WORK_RELATED_INJURIES_EMPLOYEES = 'NUMBER_OF_RECORDABLE_WORK_RELATED_INJURIES_EMPLOYEES',
  NUMBER_OF_TEMPORARY_EMPLOYEES = 'NUMBER_OF_TEMPORARY_EMPLOYEES',
  NUMBER_OPERATIONS_ASSESSED_CORRUPTION_RISKS = 'NUMBER_OPERATIONS_ASSESSED_CORRUPTION_RISKS',
  NUMBER_PARTTIME_EMPLOYEES = 'NUMBER_PARTTIME_EMPLOYEES',
  NUMBER_PART_TIME_EMPLOYEES_PERFORMANCE_REVIEWS = 'NUMBER_PART_TIME_EMPLOYEES_PERFORMANCE_REVIEWS',
  NUMBER_RECORDABLE_WORK_RELATED_ILL_HEALTH_EMPLOYEES = 'NUMBER_RECORDABLE_WORK_RELATED_ILL_HEALTH_EMPLOYEES',
  NUMBER_RECORDABLE_WORK_RELATED_INJURIES_NON_EMPLOYEES = 'NUMBER_RECORDABLE_WORK_RELATED_INJURIES_NON_EMPLOYEES',
  NUMBER_SEVERE_WORKFORCE_HUMAN_RIGHTS_ISSUES = 'NUMBER_SEVERE_WORKFORCE_HUMAN_RIGHTS_ISSUES',
  NUMBER_TERMINATED_NOT_RENEWED_CORRUPTION_VIOLATIONS = 'NUMBER_TERMINATED_NOT_RENEWED_CORRUPTION_VIOLATIONS',
  PERCENTAGE_EMPLOYEES_COLLECTIVE_BARGAINING = 'PERCENTAGE_EMPLOYEES_COLLECTIVE_BARGAINING',
  PERCENTAGE_EMPLOYEES_WORKERS_REPRESENTATIVES = 'PERCENTAGE_EMPLOYEES_WORKERS_REPRESENTATIVES',
  PERCENTAGE_INDEPENDENT_BOARD_MEMBERS = 'PERCENTAGE_INDEPENDENT_BOARD_MEMBERS',
  PERCENTAGE_PURCHASED_ENERGY_FROM_NUCLEAR_SOURCES = 'PERCENTAGE_PURCHASED_ENERGY_FROM_NUCLEAR_SOURCES',
  PERCENTAGE_PURCHASED_ENERGY_FROM_PURCHASED_COAL_AND_COAL_PRODUCTS = 'PERCENTAGE_PURCHASED_ENERGY_FROM_PURCHASED_COAL_AND_COAL_PRODUCTS',
  PREVIOUS_PURCHASED_ENERGY_CONSUMPTION = 'PREVIOUS_PURCHASED_ENERGY_CONSUMPTION',
  PRODUCTS_PACKAGING_MATERIALS_RECLAIMED = 'PRODUCTS_PACKAGING_MATERIALS_RECLAIMED',
  PURCHASED_ELECTRICITY = 'PURCHASED_ELECTRICITY',
  PURCHASED_HEATING = 'PURCHASED_HEATING',
  PURCHASED_RENEWABLE_ENERGY = 'PURCHASED_RENEWABLE_ENERGY',
  RECYCLED_INPUT_RAW_MATERIALS_CONSUMED = 'RECYCLED_INPUT_RAW_MATERIALS_CONSUMED',
  RENEWABLE_INPUT_RAW_MATERIALS_CONSUMED = 'RENEWABLE_INPUT_RAW_MATERIALS_CONSUMED',
  SELF_PRODUCED_ENERGY_HEATING_OIL = 'SELF_PRODUCED_ENERGY_HEATING_OIL',
  SELF_PRODUCED_ENERGY_NATURAL_GAS = 'SELF_PRODUCED_ENERGY_NATURAL_GAS',
  SELF_PRODUCED_ENERGY_SOLAR = 'SELF_PRODUCED_ENERGY_SOLAR',
  SELF_PRODUCED_ENERGY_WOOD_PALLETS = 'SELF_PRODUCED_ENERGY_WOOD_PALLETS',
  SUSTAINABLY_SOURCED_BIOLOGICAL_MATERIALS = 'SUSTAINABLY_SOURCED_BIOLOGICAL_MATERIALS',
  TOTAL_AMOUNT_OF_HAZARDOUS_AND_RADIOACTIVE_WASTE_GENERATED = 'TOTAL_AMOUNT_OF_HAZARDOUS_AND_RADIOACTIVE_WASTE_GENERATED',
  TOTAL_DIESEL_CONSUMPTION = 'TOTAL_DIESEL_CONSUMPTION',
  TOTAL_ELECTRICITY_SOLD = 'TOTAL_ELECTRICITY_SOLD',
  TOTAL_HAZARDOUS_WASTE_DIRECTED_TO_DISPOSAL = 'TOTAL_HAZARDOUS_WASTE_DIRECTED_TO_DISPOSAL',
  TOTAL_HAZARDOUS_WASTE_DISPOSED_OF_THROUGH_OTHER_DISPOSAL_OPERATIONS = 'TOTAL_HAZARDOUS_WASTE_DISPOSED_OF_THROUGH_OTHER_DISPOSAL_OPERATIONS',
  TOTAL_HAZARDOUS_WASTE_DIVERTED_FROM_DISPOSAL = 'TOTAL_HAZARDOUS_WASTE_DIVERTED_FROM_DISPOSAL',
  TOTAL_HAZARDOUS_WASTE_DIVERTED_FROM_DISPOSAL_THROUGH_OTHER_RECOVERY_OPERATIONS = 'TOTAL_HAZARDOUS_WASTE_DIVERTED_FROM_DISPOSAL_THROUGH_OTHER_RECOVERY_OPERATIONS',
  TOTAL_HAZARDOUS_WASTE_DIVERTED_FROM_DISPOSAL_THROUGH_RECYCLING = 'TOTAL_HAZARDOUS_WASTE_DIVERTED_FROM_DISPOSAL_THROUGH_RECYCLING',
  TOTAL_HAZARDOUS_WASTE_DIVERTED_FROM_DISPOSAL_THROUGH_REUSE = 'TOTAL_HAZARDOUS_WASTE_DIVERTED_FROM_DISPOSAL_THROUGH_REUSE',
  TOTAL_HAZARDOUS_WASTE_INCINERATED = 'TOTAL_HAZARDOUS_WASTE_INCINERATED',
  TOTAL_HAZARDOUS_WASTE_LANDFILLED = 'TOTAL_HAZARDOUS_WASTE_LANDFILLED',
  TOTAL_HEATING_SOLD = 'TOTAL_HEATING_SOLD',
  TOTAL_IMPROVING_MAINTENANCE_SAFETY = 'TOTAL_IMPROVING_MAINTENANCE_SAFETY',
  TOTAL_IMPROVING_RESOURCE_EFFICIENCY = 'TOTAL_IMPROVING_RESOURCE_EFFICIENCY',
  TOTAL_INPUT_RAW_MATERIALS_CONSUMED = 'TOTAL_INPUT_RAW_MATERIALS_CONSUMED',
  TOTAL_LPG_CONSUMPTION = 'TOTAL_LPG_CONSUMPTION',
  TOTAL_MONETARY_VALUE_POLITICAL_CONTRIBUTIONS = 'TOTAL_MONETARY_VALUE_POLITICAL_CONTRIBUTIONS',
  TOTAL_NON_HAZARDOUS_WASTE_DIRECTED_TO_DISPOSAL_THROUGH_MISCELLANEOUS_OR_UNKNOWN_ACTIVITIES = 'TOTAL_NON_HAZARDOUS_WASTE_DIRECTED_TO_DISPOSAL_THROUGH_MISCELLANEOUS_OR_UNKNOWN_ACTIVITIES',
  TOTAL_NON_HAZARDOUS_WASTE_DISPOSED_OF_THROUGH_OTHER_DISPOSAL_OPERATIONS = 'TOTAL_NON_HAZARDOUS_WASTE_DISPOSED_OF_THROUGH_OTHER_DISPOSAL_OPERATIONS',
  TOTAL_NON_HAZARDOUS_WASTE_DIVERTED_FROM_DISPOSAL_THROUGH_MISCELLANEOUS_OR_UNKNOWN_ACTIVITIES = 'TOTAL_NON_HAZARDOUS_WASTE_DIVERTED_FROM_DISPOSAL_THROUGH_MISCELLANEOUS_OR_UNKNOWN_ACTIVITIES',
  TOTAL_NON_HAZARDOUS_WASTE_DIVERTED_FROM_DISPOSAL_THROUGH_OTHER_RECOVERY_OPERATIONS = 'TOTAL_NON_HAZARDOUS_WASTE_DIVERTED_FROM_DISPOSAL_THROUGH_OTHER_RECOVERY_OPERATIONS',
  TOTAL_NON_HAZARDOUS_WASTE_DIVERTED_FROM_DISPOSAL_THROUGH_RECYCLING = 'TOTAL_NON_HAZARDOUS_WASTE_DIVERTED_FROM_DISPOSAL_THROUGH_RECYCLING',
  TOTAL_NON_HAZARDOUS_WASTE_DIVERTED_FROM_DISPOSAL_THROUGH_REUSE = 'TOTAL_NON_HAZARDOUS_WASTE_DIVERTED_FROM_DISPOSAL_THROUGH_REUSE',
  TOTAL_NON_HAZARDOUS_WASTE_INCINERATED = 'TOTAL_NON_HAZARDOUS_WASTE_INCINERATED',
  TOTAL_NON_HAZARDOUS_WASTE_LANDFILLED = 'TOTAL_NON_HAZARDOUS_WASTE_LANDFILLED',
  TOTAL_NON_RENEWABLE_INPUT_MATERIALS_CONSUMED = 'TOTAL_NON_RENEWABLE_INPUT_MATERIALS_CONSUMED',
  TOTAL_NUMBER_EMPLEOYEES = 'TOTAL_NUMBER_EMPLEOYEES',
  TOTAL_NUMBER_FEMALE_EMPLOYEES = 'TOTAL_NUMBER_FEMALE_EMPLOYEES',
  TOTAL_PETROL_CONSUMPTION = 'TOTAL_PETROL_CONSUMPTION',
  TOTAL_PRODUCTS_SOLD = 'TOTAL_PRODUCTS_SOLD',
  TOTAL_RAW_MATERIAL_CONSUMPTION = 'TOTAL_RAW_MATERIAL_CONSUMPTION',
  TOTAL_RESEARCH_INVESTMENTS = 'TOTAL_RESEARCH_INVESTMENTS',
  TOTAL_SELF_GENERATED_ENERGY_FROM_BIOGAS = 'TOTAL_SELF_GENERATED_ENERGY_FROM_BIOGAS',
  TOTAL_SELF_GENERATED_ENERGY_FROM_BIOMASS = 'TOTAL_SELF_GENERATED_ENERGY_FROM_BIOMASS',
  TOTAL_SELF_GENERATED_ENERGY_FROM_COAL_AND_COAL_PRODUCTS = 'TOTAL_SELF_GENERATED_ENERGY_FROM_COAL_AND_COAL_PRODUCTS',
  TOTAL_SELF_GENERATED_ENERGY_FROM_GEOTHERMAL = 'TOTAL_SELF_GENERATED_ENERGY_FROM_GEOTHERMAL',
  TOTAL_SELF_GENERATED_ENERGY_FROM_HYDROELECTRIC = 'TOTAL_SELF_GENERATED_ENERGY_FROM_HYDROELECTRIC',
  TOTAL_SELF_GENERATED_ENERGY_FROM_HYDROGEN = 'TOTAL_SELF_GENERATED_ENERGY_FROM_HYDROGEN',
  TOTAL_SELF_GENERATED_ENERGY_FROM_WASTE_HEAT_RECOVERY_SYSTEMS = 'TOTAL_SELF_GENERATED_ENERGY_FROM_WASTE_HEAT_RECOVERY_SYSTEMS',
  TOTAL_SELF_GENERATED_ENERGY_FROM_WIND = 'TOTAL_SELF_GENERATED_ENERGY_FROM_WIND',
  TOTAL_SELF_GENERATED_ENERGY_USING_WOOD_CHIPS = 'TOTAL_SELF_GENERATED_ENERGY_USING_WOOD_CHIPS',
  TOTAL_WASTE_DIRECTED_TO_DISPOSAL = 'TOTAL_WASTE_DIRECTED_TO_DISPOSAL',
  TOTAL_WASTE_DIVERTED_FROM_DISPOSAL = 'TOTAL_WASTE_DIVERTED_FROM_DISPOSAL',
  TOTAL_WASTE_GENERATED = 'TOTAL_WASTE_GENERATED',
  TOTAL_WATER_CONSUMPTION = 'TOTAL_WATER_CONSUMPTION',
  TOTAL_WATER_CONSUMPTION_FROM_WATER_STRESS_AREAS = 'TOTAL_WATER_CONSUMPTION_FROM_WATER_STRESS_AREAS',
  TOTAL_WATER_RECYCLED_REUSED = 'TOTAL_WATER_RECYCLED_REUSED',
  TOTAL_WATER_STORAGE_AT_THE_BEGINNING_OF_THE_REPORTING_PERIOD = 'TOTAL_WATER_STORAGE_AT_THE_BEGINNING_OF_THE_REPORTING_PERIOD',
  TOTAL_WATER_STORAGE_AT_THE_END_OF_THE_REPORTING_PERIOD = 'TOTAL_WATER_STORAGE_AT_THE_END_OF_THE_REPORTING_PERIOD',
  TRAINING_HOURS_PER_EMPLOYEE = 'TRAINING_HOURS_PER_EMPLOYEE',
}

export enum KpiType {
  MANDATORY = 'MANDATORY',
  OPTIONAL = 'OPTIONAL',
}

export enum LanguageCode {
  DE = 'de',
  EN = 'en',
}

export enum Level {
  MANAGERIAL = 'MANAGERIAL',
  NON_MANAGERIAL = 'NON_MANAGERIAL',
}

export type ManualReminderInput = {
  collectOverviewIds: Array<Scalars['UUID']>;
  message?: InputMaybe<Scalars['String']>;
};

export type MaterialTopic = {
  __typename?: 'MaterialTopic';
  area: EsgArea;
  id: Scalars['UUID'];
  title: Scalars['String'];
  type: MaterialTopicType;
};

export enum MaterialTopicType {
  OPTIONAL = 'OPTIONAL',
  RECOMMENDED = 'RECOMMENDED',
}

export type Materiality = {
  __typename?: 'Materiality';
  financialMateriality?: Maybe<Scalars['Boolean']>;
  impactMateriality?: Maybe<Scalars['Boolean']>;
};

export type MaterialityAssessment = {
  __typename?: 'MaterialityAssessment';
  companyId: Scalars['UUID'];
  evaluationRequests?: Maybe<Array<MaterialityAssessmentEvaluationRequest>>;
  id: Scalars['UUID'];
  identificationRequests: Array<MaterialityAssessmentIdentificationRequest>;
  topics: Array<MaterialityAssessmentTopic>;
  year: Scalars['Int'];
};

export type MaterialityAssessmentEvaluationRequest = {
  __typename?: 'MaterialityAssessmentEvaluationRequest';
  dueDate: Scalars['DateTime'];
  id: Scalars['UUID'];
  materialityAssessmentId: Scalars['UUID'];
  response?: Maybe<Array<MaterialityAssessmentEvaluationResponse>>;
  stakeholder: User;
  stakeholderId: Scalars['UUID'];
  status: RequestStatus;
};

export type MaterialityAssessmentEvaluationResponse = {
  __typename?: 'MaterialityAssessmentEvaluationResponse';
  result?: Maybe<MaterialityEvaluationResult>;
  topicId: Scalars['UUID'];
};

export type MaterialityAssessmentIdentificationRequest = {
  __typename?: 'MaterialityAssessmentIdentificationRequest';
  dueDate: Scalars['DateTime'];
  id: Scalars['UUID'];
  materialityAssessmentId: Scalars['UUID'];
  stakeholder: User;
  stakeholderId: Scalars['UUID'];
  status: RequestStatus;
};

export enum MaterialityAssessmentStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVE = 'DEACTIVE',
}

export type MaterialityAssessmentTopic = {
  __typename?: 'MaterialityAssessmentTopic';
  against: Array<Scalars['String']>;
  evaluation?: Maybe<MaterialityEvaluationResult>;
  id?: Maybe<Scalars['UUID']>;
  inFavor: Array<Scalars['String']>;
  materialTopic: MaterialTopic;
  materiality?: Maybe<Materiality>;
  materialityAssessmentId: Scalars['String'];
  status: MaterialityAssessmentStatus;
};

export type MaterialityEvaluationInput = {
  financialDescription?: InputMaybe<Scalars['String']>;
  financialProbability?: InputMaybe<Scalars['Int']>;
  financialSignificance?: InputMaybe<Scalars['Int']>;
  impactDescription?: InputMaybe<Scalars['String']>;
  impactIrreversibility?: InputMaybe<Scalars['Int']>;
  impactScale?: InputMaybe<Scalars['Int']>;
  impactScope?: InputMaybe<Scalars['Int']>;
  materialityAssessmentTopicId: Scalars['UUID'];
};

export type MaterialityEvaluationResult = {
  __typename?: 'MaterialityEvaluationResult';
  financialDescription?: Maybe<Scalars['String']>;
  financialProbability?: Maybe<Scalars['Int']>;
  financialSignificance?: Maybe<Scalars['Int']>;
  impactDescription?: Maybe<Scalars['String']>;
  impactIrreversibility?: Maybe<Scalars['Int']>;
  impactScale?: Maybe<Scalars['Int']>;
  impactScope?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  acceptTermsAndConditions: User;
  activateUser?: Maybe<Scalars['Void']>;
  assignCollect?: Maybe<Scalars['Void']>;
  calculateAiReportSimilarity: Array<AiReportSimilarity>;
  calculateCollectOverviewProgressStatus?: Maybe<Scalars['Void']>;
  calculateCompanyStatistics?: Maybe<Scalars['Void']>;
  calculateGeneralStatistics?: Maybe<Scalars['Void']>;
  calculateOverallStatistics?: Maybe<Scalars['Void']>;
  calculateYearlyStatistics?: Maybe<Scalars['Void']>;
  caluculateOveralReportProgress?: Maybe<Scalars['Void']>;
  changeCollectDueDate?: Maybe<Scalars['Void']>;
  chat: Scalars['String'];
  cleanUnlinkedRequestedESGValue?: Maybe<Scalars['Void']>;
  createCompany: Company;
  createExternalUser: User;
  createFacility: Facility;
  createFacilityBySuper: Facility;
  createMaterialityAssessmentTopic: CreateMaterialityAssessmentTopicResponse;
  createReport: Report;
  deleteFacility: Scalars['Boolean'];
  deleteMaterialityAssessmentTopic?: Maybe<Scalars['Void']>;
  deleteReport?: Maybe<Scalars['Boolean']>;
  deleteUser: Scalars['Boolean'];
  generateReport?: Maybe<Array<Maybe<Scalars['String']>>>;
  inviteUsers: InviteUserResponse;
  passwordUpdated: User;
  remind?: Maybe<Scalars['Void']>;
  requestMaterialityEvaluationAssessment: MaterialityAssessmentEvaluationRequest;
  requestMaterialityIdentificationAssessment: MaterialityAssessmentIdentificationRequest;
  resendInviteEmail?: Maybe<Scalars['Void']>;
  resendShareableLink?: Maybe<Scalars['Void']>;
  resendVerifyEmail?: Maybe<Scalars['Void']>;
  scheduleReminders?: Maybe<Scalars['Void']>;
  sendPasswordResetEmail?: Maybe<Scalars['Void']>;
  sendReminder?: Maybe<Scalars['Void']>;
  setSelectedYear: User;
  syncYears?: Maybe<Scalars['Void']>;
  updateCollectFilter: CollectFilter;
  updateCompany: UpdateCompanyOutput;
  updateCompanyBySuper: Company;
  updateEsgValues: UpdateEsgValuesOutput;
  updateEsgValuesByKpiFacility: Array<FlatCollectOverview>;
  updateFacility: Facility;
  updateFacilityBySuper: Facility;
  updateMaterialityEvaluation: MaterialityEvaluationResult;
  updateReport: Report;
  updateReportInput: ReportInput;
  updateRequestedEsgValueApprovalStatus: RequestedEsgValuesByOwner;
  updateRequestedEsgValues?: Maybe<Scalars['Void']>;
  updateUser: User;
  updateUserByAdmin: User;
  updateUserBySuper: UserForSuper;
  updateYears: Array<Maybe<Scalars['Int']>>;
};

export type MutationActivateUserArgs = {
  email: Scalars['String'];
};

export type MutationAssignCollectArgs = {
  assignments: Array<AssignCollectInput>;
  message?: InputMaybe<Scalars['String']>;
  sendMail: Scalars['Boolean'];
};

export type MutationCalculateAiReportSimilarityArgs = {
  companyId: Scalars['UUID'];
  reportId: Scalars['UUID'];
};

export type MutationCalculateCollectOverviewProgressStatusArgs = {
  companyId: Scalars['UUID'];
};

export type MutationCalculateCompanyStatisticsArgs = {
  companyId: Scalars['UUID'];
};

export type MutationCalculateGeneralStatisticsArgs = {
  companyId: Scalars['UUID'];
};

export type MutationCalculateYearlyStatisticsArgs = {
  companyId: Scalars['UUID'];
};

export type MutationChangeCollectDueDateArgs = {
  collectOverviewIds: Array<Scalars['UUID']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
};

export type MutationChatArgs = {
  messages: Array<ChatMessage>;
};

export type MutationCleanUnlinkedRequestedEsgValueArgs = {
  companyId: Scalars['UUID'];
};

export type MutationCreateCompanyArgs = {
  input: CreateCompanyBySuperInput;
};

export type MutationCreateExternalUserArgs = {
  input: CreateExternalUserInput;
};

export type MutationCreateFacilityArgs = {
  input: CreateFacilityInput;
};

export type MutationCreateFacilityBySuperArgs = {
  input: CreateFacilityBySuperInput;
};

export type MutationCreateMaterialityAssessmentTopicArgs = {
  materialTopicId: Scalars['UUID'];
  materialityAssessmentId: Scalars['UUID'];
};

export type MutationCreateReportArgs = {
  input: CreateReportInput;
};

export type MutationDeleteFacilityArgs = {
  id: Scalars['UUID'];
};

export type MutationDeleteMaterialityAssessmentTopicArgs = {
  id: Scalars['UUID'];
};

export type MutationDeleteReportArgs = {
  reportId: Scalars['String'];
};

export type MutationDeleteUserArgs = {
  id: Scalars['UUID'];
};

export type MutationGenerateReportArgs = {
  reportId: Scalars['String'];
  reportSectionId?: InputMaybe<Scalars['String']>;
};

export type MutationInviteUsersArgs = {
  input: Array<InviteUserInput>;
};

export type MutationRemindArgs = {
  reminderId: Scalars['UUID'];
};

export type MutationRequestMaterialityEvaluationAssessmentArgs = {
  input: RequestMaterialityEvaluationAssessmentInput;
};

export type MutationRequestMaterialityIdentificationAssessmentArgs = {
  input: RequestMaterialityIdentificationAssessmentInput;
};

export type MutationResendInviteEmailArgs = {
  email: Scalars['String'];
};

export type MutationResendShareableLinkArgs = {
  email: Scalars['String'];
};

export type MutationResendVerifyEmailArgs = {
  email: Scalars['String'];
};

export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String'];
};

export type MutationSendReminderArgs = {
  input: ManualReminderInput;
};

export type MutationSetSelectedYearArgs = {
  year: Scalars['Int'];
};

export type MutationUpdateCollectFilterArgs = {
  filter: CollectFilterInput;
};

export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};

export type MutationUpdateCompanyBySuperArgs = {
  input: UpdateCompanyBySuperInput;
};

export type MutationUpdateEsgValuesArgs = {
  input: UpdateEsgValuesInput;
};

export type MutationUpdateEsgValuesByKpiFacilityArgs = {
  input: Array<InputMaybe<FacilityEsgValueInput>>;
};

export type MutationUpdateFacilityArgs = {
  input: UpdateFacilityInput;
};

export type MutationUpdateFacilityBySuperArgs = {
  input: UpdateFacilityBySuperInput;
};

export type MutationUpdateMaterialityEvaluationArgs = {
  input: MaterialityEvaluationInput;
};

export type MutationUpdateReportArgs = {
  input: UpdateReportInput;
};

export type MutationUpdateReportInputArgs = {
  input?: InputMaybe<Scalars['String']>;
  isMarkedCompleted?: InputMaybe<Scalars['Boolean']>;
  oldProgressCalculation?: InputMaybe<Scalars['Boolean']>;
  reportId: Scalars['UUID'];
  sectionId: Scalars['UUID'];
};

export type MutationUpdateRequestedEsgValueApprovalStatusArgs = {
  input: UpdatedRequestedEsgValuesByOwnerInput;
};

export type MutationUpdateRequestedEsgValuesArgs = {
  input: Array<InputMaybe<UpdateRequstedEsgValueInput>>;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserByAdminArgs = {
  input: UpdateUserByAdminInput;
};

export type MutationUpdateUserBySuperArgs = {
  input: UpdateUserBySuper;
};

export type MutationUpdateYearsArgs = {
  years: Array<Scalars['Int']>;
};

export type NewEsgValueInput = {
  esgKpiId: Scalars['String'];
  selectedUnit?: InputMaybe<Unit>;
  value?: InputMaybe<Scalars['Float']>;
};

export enum NumberFormat {
  COMMA_PERIOD = 'COMMA_PERIOD',
  PERIOD_COMMA = 'PERIOD_COMMA',
}

export type NuvoCategoryOptions = {
  __typename?: 'NuvoCategoryOptions';
  label: Scalars['String'];
  type: Scalars['String'];
  value: Scalars['String'];
};

export type NuvoColumn = {
  __typename?: 'NuvoColumn';
  columnType: Scalars['String'];
  key: Scalars['String'];
  label: Scalars['String'];
  options?: Maybe<Array<Maybe<NuvoCategoryOptions>>>;
};

export type OpenDataRequest = {
  __typename?: 'OpenDataRequest';
  category: EsgCategory;
  collectOverviewId: Scalars['String'];
  facility: Facility;
  requestedBy?: Maybe<User>;
  status: Status;
  year?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  aIGeneratedReport?: Maybe<Scalars['String']>;
  canRegister: Scalars['Boolean'];
  collectFilter?: Maybe<CollectFilter>;
  collectOverviewById: CollectOverview;
  collectOverviews: Array<CollectOverview>;
  collectYearsByExternal: Array<Scalars['Int']>;
  companies: Array<Maybe<Company>>;
  company: Company;
  downloadReport?: Maybe<Array<Maybe<ReportSectionInput>>>;
  esgCategories: Array<Maybe<EsgCategory>>;
  esgKpis: Array<EsgKpi>;
  esgScore?: Maybe<EsgScore>;
  esgValueExport: Array<EsgValueExport>;
  esgValuesAggByReportSection: Array<Maybe<EsgValueAgg>>;
  esgValuesByCollectOverviewId?: Maybe<Array<EsgValue>>;
  facilities: Array<Maybe<Facility>>;
  facilitiesByCompanyId: Array<Maybe<Facility>>;
  getNextCalculationTime: Scalars['Float'];
  getRoadmapStatistics: RoadmapStatistics;
  getYearlyEnvironmentalStatistics: EnvironmentalEmissions;
  getYearlyGeneralStatistics: GeneralStatistic;
  getYearlySocialStatistics: YearlySocialStatistics;
  industries: Array<Maybe<Industry>>;
  internalExternalUsers: Array<InternalExternalUserOutput>;
  materialTopics: Array<MaterialTopic>;
  materialityAssessment: MaterialityAssessment;
  numberRequestedEsgValues?: Maybe<Scalars['Int']>;
  nuvoColumnFormat: Array<Maybe<NuvoColumn>>;
  openDataRequests?: Maybe<Array<Maybe<OpenDataRequest>>>;
  reminder: Array<CollectReminder>;
  report: Report;
  reportSections: Array<Maybe<ReportSection>>;
  reports: Array<Maybe<Report>>;
  reportsByCompanyId: Array<Maybe<Report>>;
  requestedEsgValuesByCollectOverviewId: Array<EsgValue>;
  requestedEsgValuesByOwner?: Maybe<Array<GroupedRequestedEsgValuesByExternalUser>>;
  subIndustries: Array<Maybe<SubIndustry>>;
  totalEsgScore?: Maybe<Scalars['Int']>;
  user: User;
  users: Array<User>;
  usersByCompanyId: Array<Maybe<UserForSuper>>;
  years: Array<Maybe<Scalars['Int']>>;
};

export type QueryAiGeneratedReportArgs = {
  language: LanguageCode;
  reportId: Scalars['UUID'];
  reportSectionId: Scalars['UUID'];
};

export type QueryCanRegisterArgs = {
  email: Scalars['String'];
};

export type QueryCollectFilterArgs = {
  year: Scalars['Int'];
};

export type QueryCollectOverviewByIdArgs = {
  id: Scalars['String'];
};

export type QueryCollectOverviewsArgs = {
  year: Scalars['Int'];
};

export type QueryDownloadReportArgs = {
  reportId: Scalars['UUID'];
};

export type QueryEsgValueExportArgs = {
  year: Scalars['Int'];
};

export type QueryEsgValuesAggByReportSectionArgs = {
  reportSectionId: Scalars['UUID'];
  year: Scalars['Int'];
};

export type QueryEsgValuesByCollectOverviewIdArgs = {
  collectOverviewId: Scalars['String'];
};

export type QueryFacilitiesByCompanyIdArgs = {
  companyId: Scalars['UUID'];
};

export type QueryGetRoadmapStatisticsArgs = {
  year: Scalars['Int'];
};

export type QueryGetYearlyEnvironmentalStatisticsArgs = {
  year: Scalars['Int'];
};

export type QueryGetYearlyGeneralStatisticsArgs = {
  year: Scalars['Int'];
};

export type QueryGetYearlySocialStatisticsArgs = {
  year: Scalars['Int'];
};

export type QueryMaterialityAssessmentArgs = {
  year: Scalars['Int'];
};

export type QueryNumberRequestedEsgValuesArgs = {
  year?: InputMaybe<Scalars['Int']>;
};

export type QueryNuvoColumnFormatArgs = {
  categoryId: Scalars['String'];
};

export type QueryOpenDataRequestsArgs = {
  year?: InputMaybe<Scalars['Int']>;
};

export type QueryReminderArgs = {
  input: ReminderInput;
};

export type QueryReportArgs = {
  reportId: Scalars['UUID'];
};

export type QueryReportInputArgs = {
  reportId: Scalars['UUID'];
  sectionId: Scalars['UUID'];
};

export type QueryReportSectionsArgs = {
  language: LanguageCode;
  reportId: Scalars['UUID'];
};

export type QueryReportsByCompanyIdArgs = {
  companyId: Scalars['UUID'];
};

export type QueryRequestedEsgValuesByCollectOverviewIdArgs = {
  collectOverviewId: Scalars['String'];
};

export type QueryRequestedEsgValuesByOwnerArgs = {
  collectOverviewId: Scalars['String'];
};

export type QueryUsersByCompanyIdArgs = {
  companyId: Scalars['UUID'];
};

export type ReminderInput = {
  collectOverviewId?: InputMaybe<Scalars['UUID']>;
  createDate?: InputMaybe<Scalars['DateTime']>;
  executionDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['UUID']>;
  recipientId?: InputMaybe<Scalars['UUID']>;
  status?: InputMaybe<ReminderStatus>;
};

export enum ReminderStatus {
  CREATED = 'CREATED',
  EXECUTED = 'EXECUTED',
  SCHEDULED = 'SCHEDULED',
}

export type Report = {
  __typename?: 'Report';
  framework: ReportingFramework;
  id: Scalars['UUID'];
  progress: Scalars['Int'];
  title: Scalars['String'];
  year: Scalars['Int'];
};

export type ReportInput = {
  __typename?: 'ReportInput';
  id: Scalars['UUID'];
  input?: Maybe<Scalars['String']>;
  isGenerating?: Maybe<Scalars['Boolean']>;
  isMarkedCompleted?: Maybe<Scalars['Boolean']>;
};

export type ReportSection = {
  __typename?: 'ReportSection';
  category: Scalars['String'];
  chapter: Scalars['String'];
  example?: Maybe<Scalars['String']>;
  framework: ReportingFramework;
  id: Scalars['UUID'];
  reportInput?: Maybe<ReportInput>;
  requirements?: Maybe<Array<Scalars['String']>>;
};

export type ReportSectionInput = {
  __typename?: 'ReportSectionInput';
  category: Scalars['String'];
  chapter: Scalars['String'];
  id: Scalars['UUID'];
  input?: Maybe<Scalars['String']>;
};

export enum ReportingFramework {
  DNK = 'DNK',
  ESRS = 'ESRS',
  GRI = 'GRI',
}

export type ReportingFrameworks = {
  __typename?: 'ReportingFrameworks';
  dnk?: Maybe<Scalars['String']>;
  esrs?: Maybe<Scalars['String']>;
  gri?: Maybe<Scalars['String']>;
};

export type RequestMaterialityEvaluationAssessmentInput = {
  dueDate: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  materialAssessmentTopicIds: Array<Scalars['String']>;
  position: UserPosition;
  year: Scalars['Int'];
};

export type RequestMaterialityIdentificationAssessmentInput = {
  dueDate: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  position: UserPosition;
  year: Scalars['Int'];
};

export enum RequestStatus {
  FINISHED = 'FINISHED',
  OVERDUE = 'OVERDUE',
  PENDING = 'PENDING',
}

export type RequestedEsgValuesByOwner = {
  __typename?: 'RequestedEsgValuesByOwner';
  approvalStatus?: Maybe<ApprovalStatus>;
  currentUnit?: Maybe<Unit>;
  currentValue?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  kpiTitle: Scalars['String'];
  newUnit?: Maybe<Unit>;
  newValue?: Maybe<Scalars['Float']>;
};

export type RequestedEsgValuesInput = {
  categoryId: Scalars['String'];
  facilityId: Scalars['String'];
  userId: Scalars['String'];
  year: Scalars['Int'];
};

export type RoadmapStatistics = {
  __typename?: 'RoadmapStatistics';
  dataCollection: Scalars['Int'];
  materialityAssessment: Scalars['Int'];
  reportWriting: Scalars['Int'];
};

export enum SortingOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum Status {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
}

export type SubIndustry = {
  __typename?: 'SubIndustry';
  id: Scalars['UUID'];
  industry: Industry;
  name: Scalars['String'];
};

export enum Unit {
  EURO = 'EURO',
  GCO2KWH = 'GCO2KWH',
  HRS = 'HRS',
  KG = 'KG',
  LITRE = 'LITRE',
  M3 = 'M3',
  ML = 'ML',
  MWH = 'MWh',
  PERCENTAGE = 'PERCENTAGE',
  TONNES = 'TONNES',
  KWH = 'kWh',
}

export type UpdateCompanyBySuperInput = {
  dashboardUrl?: InputMaybe<Scalars['String']>;
  employeesNum: EmployeesNum;
  headquarter: Scalars['String'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  status: CompanyStatus;
  subIndustryId?: InputMaybe<Scalars['UUID']>;
};

export type UpdateCompanyInput = {
  dashboardUrl?: InputMaybe<Scalars['String']>;
  employeesNum?: InputMaybe<EmployeesNum>;
  headquarter?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  subIndustryId?: InputMaybe<Scalars['UUID']>;
};

export type UpdateCompanyOutput = {
  __typename?: 'UpdateCompanyOutput';
  company: Company;
};

export type UpdateEsgValuesInput = {
  collectOverviewId: Scalars['String'];
  created?: InputMaybe<Array<NewEsgValueInput>>;
  removed?: InputMaybe<Array<EsgValueInput>>;
  updated?: InputMaybe<Array<EsgValueInput>>;
};

export type UpdateEsgValuesOutput = {
  __typename?: 'UpdateEsgValuesOutput';
  created: Array<EsgValue>;
  removed: Array<EsgValueOutput>;
  updated: Array<EsgValue>;
};

export type UpdateFacilityBySuperInput = {
  city?: InputMaybe<Scalars['String']>;
  companyId: Scalars['UUID'];
  country?: InputMaybe<CountryCode>;
  id: Scalars['UUID'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<FacilityType>;
};

export type UpdateFacilityInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  id: Scalars['UUID'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<FacilityType>;
};

export type UpdateReportInput = {
  reportId: Scalars['String'];
  title: Scalars['String'];
  year: Scalars['Int'];
};

export type UpdateRequstedEsgValueInput = {
  id: Scalars['String'];
  selectedUnit?: InputMaybe<Unit>;
  value?: InputMaybe<Scalars['Float']>;
};

export type UpdateUserByAdminInput = {
  department?: InputMaybe<UserDepartment>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  lastName?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<UserPosition>;
  role?: InputMaybe<UserRole>;
};

export type UpdateUserBySuper = {
  department?: InputMaybe<UserDepartment>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  language?: InputMaybe<LanguageCode>;
  lastName?: InputMaybe<Scalars['String']>;
  numberFormat?: InputMaybe<NumberFormat>;
  position?: InputMaybe<UserPosition>;
  role: UserRole;
};

export type UpdateUserInput = {
  department?: InputMaybe<UserDepartment>;
  firstName?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageCode>;
  lastName?: InputMaybe<Scalars['String']>;
  numberFormat?: InputMaybe<NumberFormat>;
  position?: InputMaybe<UserPosition>;
};

export type UpdatedRequestedEsgValuesByOwnerInput = {
  approvalStatus?: InputMaybe<ApprovalStatus>;
  id: Scalars['String'];
  newUnit?: InputMaybe<Unit>;
  newValue?: InputMaybe<Scalars['Float']>;
};

export type User = {
  __typename?: 'User';
  createdTimestamp: Scalars['DateTime'];
  department?: Maybe<UserDepartment>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  language: LanguageCode;
  lastName?: Maybe<Scalars['String']>;
  numberFormat?: Maybe<NumberFormat>;
  position?: Maybe<UserPosition>;
  role: UserRole;
  selectedYear?: Maybe<Scalars['Int']>;
  status?: Maybe<UserAccountStatus>;
};

export enum UserAccountStatus {
  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  NEW_PASSWORD_SET = 'NEW_PASSWORD_SET',
  PENDING_INVITES = 'PENDING_INVITES',
  SEND_REMINDER = 'SEND_REMINDER',
  USER_INFO_COMPLETED = 'USER_INFO_COMPLETED',
}

export enum UserDepartment {
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  ENVIRONMENTAL_PROTECTION_OCCUPATIONAL_SAFETY = 'ENVIRONMENTAL_PROTECTION_OCCUPATIONAL_SAFETY',
  EXECUTIVE_MANAGEMENT = 'EXECUTIVE_MANAGEMENT',
  FINANCE_CONTROLLING = 'FINANCE_CONTROLLING',
  HUMAN_RESOURCE = 'HUMAN_RESOURCE',
  INFORMATION_TECHNOLOGY = 'INFORMATION_TECHNOLOGY',
  LOGISTICS = 'LOGISTICS',
  MARKETING = 'MARKETING',
  OPERATIONS = 'OPERATIONS',
  OTHER = 'OTHER',
  PRODUCTION = 'PRODUCTION',
  PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT',
  PURCHASING = 'PURCHASING',
  QUALITY_MANAGEMENT = 'QUALITY_MANAGEMENT',
  RESEARCH_DEVELOPMENT = 'RESEARCH_DEVELOPMENT',
  SALES = 'SALES',
  SUSTAINABILITY = 'SUSTAINABILITY',
  TECHNICAL_MAINTENANCE = 'TECHNICAL_MAINTENANCE',
}

export type UserForSuper = {
  __typename?: 'UserForSuper';
  createdTimestamp: Scalars['DateTime'];
  department?: Maybe<UserDepartment>;
  email: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  language?: Maybe<LanguageCode>;
  lastName?: Maybe<Scalars['String']>;
  numberFormat?: Maybe<NumberFormat>;
  position?: Maybe<UserPosition>;
  role: UserRole;
  status?: Maybe<UserAccountStatus>;
};

export enum UserPosition {
  ADMINISTRATOR = 'ADMINISTRATOR',
  COMMUNITY = 'COMMUNITY',
  CUSTOMER = 'CUSTOMER',
  C_LEVEL_EXECUTIVE = 'C_LEVEL_EXECUTIVE',
  EMPLOYEE = 'EMPLOYEE',
  LANDOWNER = 'LANDOWNER',
  MANAGEMENT = 'MANAGEMENT',
  OTHER = 'OTHER',
  PROFESSIONAL = 'PROFESSIONAL',
  SHAREHOLDER = 'SHAREHOLDER',
  SUPPLIER = 'SUPPLIER',
  TEAM_LEAD = 'TEAM_LEAD',
  TEMPORARY_WORKER = 'TEMPORARY_WORKER',
  TRAINEE = 'TRAINEE',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  EMPLOYEE = 'EMPLOYEE',
  EXTERNAL = 'EXTERNAL',
  LEAD = 'LEAD',
  STAKEHOLDER = 'STAKEHOLDER',
  SUPER = 'SUPER',
}

export type WaterConsumptionEmissionsFacility = {
  __typename?: 'WaterConsumptionEmissionsFacility';
  Emission: Scalars['Float'];
  Facility: Facility;
};

export type YearlySocialStatistics = {
  __typename?: 'YearlySocialStatistics';
  employeeTurnoverRate?: Maybe<Scalars['Float']>;
  facilityGenders: Array<FacilityGender>;
  numberOccupationalAccidents?: Maybe<Scalars['Int']>;
  percentageFemaleEmployees?: Maybe<Scalars['Float']>;
  totalFemaleManagerEmployees?: Maybe<Scalars['Int']>;
  totalFemaleNonManagerEmployees?: Maybe<Scalars['Int']>;
  totalMaleManagerEmployees?: Maybe<Scalars['Int']>;
  totalMaleNonManagerEmployees?: Maybe<Scalars['Int']>;
  totalNumberOfEmployees?: Maybe<Scalars['Int']>;
  totalNumberOfEmployees30To50?: Maybe<Scalars['Int']>;
  totalNumberOfEmployeesOver50?: Maybe<Scalars['Int']>;
  totalNumberOfEmployeesUnder30?: Maybe<Scalars['Int']>;
  trainingHoursPerEmployee?: Maybe<Scalars['Float']>;
};
