import { useTranslation } from 'react-i18next';

import useEnhancedQuery from 'src/modules/common/application/use-enhanced-query';
import { COLLECT_OVERVIEW_YEARS_BY_EXTERNAL_USER } from 'src/services/graphql/queries/collect';

import useMessages from '../../common/application/use-messages';

const useCollectYearsByExternal = (accessToken: string | undefined) => {
  const { setErrorMessage } = useMessages();
  const { t } = useTranslation();

  const { loading, data, error } = useEnhancedQuery<{ collectYearsByExternal: number[] }>(
    COLLECT_OVERVIEW_YEARS_BY_EXTERNAL_USER,
    {
      fetchPolicy: 'cache-and-network',
      context: { headers: { authorization: `Bearer ${accessToken}` } },
      onError: () => {
        setErrorMessage(t('messages.getExternalUserYears.error'));
      },
      skip: !accessToken,
    }
  );

  return {
    years: data?.collectYearsByExternal ? data.collectYearsByExternal : [],
    collectionYearsLoading: loading,
    error,
  };
};

export default useCollectYearsByExternal;
