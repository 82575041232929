import { gql } from '@apollo/client';
import { FACILITY_FRAGMENT } from '../fragments';

export const CREATE_FACILITY = gql`
  mutation CreateFacility($input: CreateFacilityInput!) {
    createFacility(input: $input) {
      ...facilityValues
    }
  }
  ${FACILITY_FRAGMENT}
`;

export const CREATE_FACILITY_BUY_SUPER = gql`
  mutation CreateFacilityBySuper($input: CreateFacilityBySuperInput!) {
    createFacilityBySuper(input: $input) {
      ...facilityValues
    }
  }
  ${FACILITY_FRAGMENT}
`;

export const UPDATE_FACILITY = gql`
  mutation UpdateFacility($input: UpdateFacilityInput!) {
    updateFacility(input: $input) {
      ...facilityValues
    }
  }
  ${FACILITY_FRAGMENT}
`;

export const UPDATE_FACILITY_BY_SUPER = gql`
  mutation UpdateFacilityBySuper($input: UpdateFacilityBySuperInput!) {
    updateFacilityBySuper(input: $input) {
      ...facilityValues
    }
  }
  ${FACILITY_FRAGMENT}
`;

export const DELETE_FACILITY = gql`
  mutation DeleteFacility($id: UUID!) {
    deleteFacility(id: $id)
  }
`;
