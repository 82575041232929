import { UserDepartment, UserPosition } from '../graphql';

export const SORTED_USER_POSITIONS: UserPosition[] = [
  UserPosition.MANAGEMENT,
  UserPosition.TEAM_LEAD,
  UserPosition.PROFESSIONAL,
  UserPosition.ADMINISTRATOR,
  UserPosition.TEMPORARY_WORKER,
  UserPosition.TRAINEE,
  UserPosition.OTHER,
];
export const SORTED_USER_DEPARTMENTS: UserDepartment[] = [
  UserDepartment.EXECUTIVE_MANAGEMENT,
  UserDepartment.PRODUCTION,
  UserDepartment.QUALITY_MANAGEMENT,
  UserDepartment.PURCHASING,
  UserDepartment.SALES,
  UserDepartment.HUMAN_RESOURCE,
  UserDepartment.RESEARCH_DEVELOPMENT,
  UserDepartment.INFORMATION_TECHNOLOGY,
  UserDepartment.LOGISTICS,
  UserDepartment.FINANCE_CONTROLLING,
  UserDepartment.TECHNICAL_MAINTENANCE,
  UserDepartment.OPERATIONS,
  UserDepartment.MARKETING,
  UserDepartment.ENVIRONMENTAL_PROTECTION_OCCUPATIONAL_SAFETY,
  UserDepartment.SUSTAINABILITY,
  UserDepartment.PROJECT_MANAGEMENT,
  UserDepartment.CUSTOMER_SERVICE,
  UserDepartment.OTHER,
];

export const SORTED_STAKEHOLDERS_POSITIONS: UserPosition[] = [
  UserPosition.C_LEVEL_EXECUTIVE,
  UserPosition.SHAREHOLDER,
  UserPosition.EMPLOYEE,
  UserPosition.CUSTOMER,
  UserPosition.SUPPLIER,
  UserPosition.COMMUNITY,
  UserPosition.LANDOWNER,
  UserPosition.OTHER,
];
