import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { YearlySocialStatistics } from 'src/modules/common/types';
import { GET_YEARLY_SOCIAL_STATISTICS } from 'src/services/graphql/queries';
import { useEnhancedQuery, useMessages } from 'src/modules/common/application';
import { Loader } from 'src/modules/common/components';

import SocialOverviewStats from '../SocialOverviewStats/SocialOverviewStats';
import LevelGenderStructureChart from '../LevelGenderStructureChart/LevelGenderStructureChart';
import AgeStructure from '../AgeStructure/AgeStructure';
import LocationGenderStructure from '../LocationGenderStructure/LocationGenderStructure';
import EmployeesInHS from '../EmployeesInHS/EmployeesInHS';

const SocialTab = ({ year }: { year: number }) => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();

  const { loading, data, error } = useEnhancedQuery<{
    getYearlySocialStatistics: YearlySocialStatistics;
  }>(GET_YEARLY_SOCIAL_STATISTICS, {
    variables: {
      year,
    },
  });

  if (loading || !data) return <Loader />;
  if (error) return <>{setErrorMessage(t('messages.generic.error'))}</>;

  const {
    employeeTurnoverRate,
    numberOccupationalAccidents,
    percentageFemaleEmployees,
    totalFemaleManagerEmployees,
    totalFemaleNonManagerEmployees,
    totalMaleManagerEmployees,
    totalMaleNonManagerEmployees,
    totalNumberOfEmployees,
    totalNumberOfEmployees30To50,
    totalNumberOfEmployeesOver50,
    totalNumberOfEmployeesUnder30,
    trainingHoursPerEmployee,
    facilityGenders,
  } = data?.getYearlySocialStatistics;
  return (
    <Grid container spacing={4}>
      <Grid item md={12} xs={12}>
        <SocialOverviewStats
          numberEmployees={totalNumberOfEmployees ? totalNumberOfEmployees : 0}
          femaleEmployees={percentageFemaleEmployees ? percentageFemaleEmployees : 0}
          occupationalAccidents={numberOccupationalAccidents ? numberOccupationalAccidents : 0}
          trainingHours={trainingHoursPerEmployee ? trainingHoursPerEmployee : 0}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <LevelGenderStructureChart
          totalFemaleManagerEmployees={totalFemaleManagerEmployees}
          totalFemaleNonManagerEmployees={totalFemaleNonManagerEmployees}
          totalMaleManagerEmployees={totalMaleManagerEmployees}
          totalMaleNonManagerEmployees={totalMaleNonManagerEmployees}
        />
      </Grid>
      <Grid item md={7} xs={12}>
        <AgeStructure
          totalNumberOfEmployeesUnder30={
            totalNumberOfEmployeesUnder30 ? totalNumberOfEmployeesUnder30 : 0
          }
          totalNumberOfEmployees30To50={
            totalNumberOfEmployees30To50 ? totalNumberOfEmployees30To50 : 0
          }
          totalNumberOfEmployeesOver50={
            totalNumberOfEmployeesOver50 ? totalNumberOfEmployeesOver50 : 0
          }
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <EmployeesInHS data={employeeTurnoverRate ? employeeTurnoverRate : 0} />
      </Grid>
      <Grid item md={8} xs={12}>
        <LocationGenderStructure data={facilityGenders ? facilityGenders : []} />
      </Grid>
    </Grid>
  );
};

export default SocialTab;
