import { v4 as uuidv4 } from 'uuid';
import { Status } from 'src/modules/common/types';
import { OpenRequests } from '../OpenDataRequests/types';

export const getHeaders = [
  'modules.dataManagement.openDataRequest.category',
  'modules.dataManagement.openDataRequest.locations',
  'modules.dataManagement.openDataRequest.status',
];

export const tasksMock: OpenRequests[] = [
  {
    id: uuidv4(),
    catagory: 'Direkte Emissionen',
    city: 'Münster', // This should be Id's,
    requestedBy: 'Julius',
    status: Status.COMPLETED,
  },
  {
    id: uuidv4(),
    catagory: 'Indirekte Emissionen',
    city: 'Hamburg', // This should be Id's,
    requestedBy: 'Stefanie',
    status: Status.NOT_STARTED,
  },
  {
    id: uuidv4(),
    catagory: 'Energie',
    city: 'Köln', // This should be Id's,
    requestedBy: 'Donatus',
    status: Status.NOT_STARTED,
  },
];
