import { gql } from '@apollo/client';

export const CREATE_MATERIALITY_ASSESSMENT_TOPIC = gql`
  mutation CreateMaterialityAssessmentTopic(
    $materialityAssessmentId: UUID!
    $materialTopicId: UUID!
  ) {
    createMaterialityAssessmentTopic(
      materialityAssessmentId: $materialityAssessmentId
      materialTopicId: $materialTopicId
    ) {
      id
      materialityAssessmentId
      status
    }
  }
`;

export const DELETE_MATERIALITY_ASSESSMENT_TOPIC = gql`
  mutation DeleteMaterialityAssessmentTopic($id: UUID!) {
    deleteMaterialityAssessmentTopic(id: $id)
  }
`;

export const REQUEST_MATERIALITY_IDENTIFICATION_ASSESSMENT = gql`
  mutation RequestMaterialityIdentificationAssessment(
    $input: RequestMaterialityIdentificationAssessmentInput!
  ) {
    requestMaterialityIdentificationAssessment(input: $input) {
      id
      stakeholderId
      materialityAssessmentId
      dueDate
      status
      stakeholder {
        id
        email
        firstName
        lastName
        position
      }
    }
  }
`;

export const REQUEST_MATERIALITY_ASSESSMENT_EVALUATION_REQUEST = gql`
  mutation RequestMaterialityEvaluationAssessment(
    $input: RequestMaterialityEvaluationAssessmentInput!
  ) {
    requestMaterialityEvaluationAssessment(input: $input) {
      id
      stakeholderId
      materialityAssessmentId
      dueDate
      status
      response {
        topicId
        result {
          impactDescription
          financialDescription
          impactScale
          impactScope
          impactIrreversibility
          financialSignificance
          financialProbability
        }
      }
      stakeholder {
        id
        email
        firstName
        lastName
        position
      }
    }
  }
`;

export const UPDATE_MATERIALITY_EVALUATION = gql`
  mutation UpdateMaterialityEvaluation($input: MaterialityEvaluationInput!) {
    updateMaterialityEvaluation(input: $input) {
      impactDescription
      financialDescription
      impactScale
      impactScope
      impactIrreversibility
      financialSignificance
      financialProbability
    }
  }
`;
