export const createArrayFromLength = (length: number) => Array.from({ length }, (_, i) => i + 1);

export const getRandomArrayIndex = (arr: string[]) => Math.floor(Math.random() * arr.length);

export const areArraysEqual = (firstArray: string[], secondArray: string[]) => {
  if (firstArray.length !== secondArray.length) {
    return false;
  }

  for (let i = 0; i < firstArray.length; i++) {
    if (firstArray[i] !== secondArray[i]) {
      return false;
    }
  }

  return true;
};
