import React from 'react';
import { Box, Typography } from '@mui/material';
import Avatar from 'src/modules/common/components/Avatar/Avatar';

export interface Props {
  text: string;
}

const UserNameAvatar = ({ text }: Props) => {
  return (
    <Box display="flex" alignItems="center">
      <Avatar
        styles={{
          width: 30,
          height: 30,
          fontSize: '0.875rem',
        }}
        input={text}
      />
      <Typography variant="h5" sx={{ ml: 1, fontSize: '0.875rem !important' }}>
        {text}
      </Typography>
    </Box>
  );
};

export default UserNameAvatar;
