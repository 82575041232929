import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { Typography, Grid, Select, MenuItem, InputLabel, SelectChangeEvent } from '@mui/material';
import { PostHogFeature } from 'posthog-js/react';

import { User } from 'src/modules/common/types';
import { GET_USER_SETTING_DETAILS } from 'src/services/graphql/queries';
import { useEnhancedQuery, useMessages, useYears } from 'src/modules/common/application';
import { getInputLabelStyles } from 'src/modules/common/application/form-styles';
import useCsvDataExport from 'src/modules/common/application/use-csv-data-export';
import { Loader } from 'src/modules/common/components';
import Button from 'src/modules/common/components/Button/Button';
import { FeatureFlag } from 'src/modules/common/types/featureFlags';

import EditOtherDetailsForm from './EditOtherDetailsForm';

const OtherSettings = () => {
  const { t } = useTranslation();
  const { setErrorMessage } = useMessages();
  const {
    data: yearsData,
    loading: loadingYears,
    error: errorYears,
    updateYears,
    updateYearsLoading,
    selectedYear,
  } = useYears();
  const { getExportData, loading: csvLoading } = useCsvDataExport();

  const [isEditState, seteditState] = useState(false);
  const [exportYear, setExportYear] = useState<number>(selectedYear);

  const { loading, data, error } = useEnhancedQuery<{ user: User }>(GET_USER_SETTING_DETAILS);

  const changeEditState = () => {
    seteditState(!isEditState);
  };

  const handleInformationSubmit = async (years: number[]) => {
    await updateYears({ variables: { years } });
    seteditState(false);
  };

  const handleReset = () => {
    seteditState(false);
  };

  const handleExportSelectChange = (event: SelectChangeEvent<number>) => {
    setExportYear(event.target.value as number);
  };

  if (loading || loadingYears || !data || !yearsData) return <Loader />;
  if (error || errorYears) return <>{setErrorMessage(t('messages.getUserInfo.error'))}</>;

  const { user } = data;

  return (
    <>
      {!user ? null : (
        <>
          <Box
            sx={{
              height: '100%',
              backgroundColor: 'background.white',
              display: 'flex',
              padding: 1,
              flexDirection: 'column',
              color: 'text.secondary',
              border: '1px solid',
              borderColor: 'background.grey',
              borderRadius: '10px',
              fontSize: '14px',
              mb: 2,
            }}
          >
            <Box
              sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', m: 1 }}
            >
              <Box>
                <Typography variant="h5" sx={{ color: 'text.primary' }}>
                  {t('modules.settings.other.title')}
                </Typography>
              </Box>
              <Box>
                {isEditState ? null : (
                  <Button onClick={changeEditState} variant="outlined" size="small">
                    {t('modules.settings.editPersonalDetails.edit')}
                  </Button>
                )}
              </Box>
            </Box>
            <EditOtherDetailsForm
              years={yearsData?.years ? yearsData?.years : []}
              onInformationSubmit={handleInformationSubmit}
              disabled={!isEditState}
              onReset={handleReset}
              showCancel={true}
              loading={updateYearsLoading}
            />
          </Box>
          <PostHogFeature flag={FeatureFlag.CSV_DATA_EXPORT} match={true}>
            <Grid
              container
              sx={{
                height: '100%',
                backgroundColor: 'background.white',
                display: 'flex',
                flexDirection: 'column',
                padding: 1,
                color: 'text.secondary',
                border: '1px solid',
                borderColor: 'background.grey',
                borderRadius: '10px',
                fontSize: '14px',
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  m: 1,
                }}
              >
                <Typography variant="h5" sx={{ color: 'text.primary' }}>
                  {t('modules.settings.other.esgDataExport')}
                </Typography>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => getExportData(exportYear)}
                  loading={csvLoading}
                >
                  {t('modules.settings.other.export')}
                </Button>
              </Grid>
              <Grid item sx={{ p: 2, maxWidth: '100%' }}>
                <InputLabel htmlFor="collectionYear" sx={getInputLabelStyles}>
                  {t('modules.settings.other.chooseYear')}
                </InputLabel>
                <Select
                  id="collectionYear"
                  name="collectionYear"
                  defaultValue={selectedYear}
                  onChange={e => handleExportSelectChange(e)}
                  sx={{ width: '420px', mt: -3, maxWidth: '100%' }}
                >
                  {[...yearsData?.years]
                    .sort((a, b) => a - b)
                    .map(year => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>{' '}
          </PostHogFeature>
        </>
      )}
    </>
  );
};

export default OtherSettings;
