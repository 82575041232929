import React from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Grid,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { getInputLabelStyles } from 'src/modules/common/application/form-styles';
import {
  LanguageCode,
  NumberFormat,
  UserForSuper,
  UserRole,
} from 'src/modules/common/types/graphql';
import Button from 'src/modules/common/components/Button/Button';
import { userForSuperSchema } from 'src/modules/common/application/user.schema';
import {
  SORTED_USER_DEPARTMENTS,
  SORTED_USER_POSITIONS,
} from 'src/modules/common/types/constants/sorted-enums';

interface Props {
  data?: UserForSuper;
  handleSubmit: (data: UserForSuper) => void;
  handleReset?: () => void;
  editMode?: boolean;
}

const UserForm = ({ data, handleSubmit, handleReset, editMode }: Props) => {
  const { t } = useTranslation();
  const initalFormState = data
    ? data
    : ({
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        role: UserRole.EMPLOYEE,
        status: undefined,
        position: undefined,
        department: undefined,
        language: LanguageCode.EN,
        numberFormat: NumberFormat.COMMA_PERIOD,
      } as UserForSuper);
  const fieldStyle = {
    marginBottom: 5,
  };

  return (
    <div>
      <Formik
        initialValues={{
          ...initalFormState,
        }}
        enableReinitialize={true}
        validationSchema={userForSuperSchema(t)}
        onSubmit={(values: UserForSuper) => {
          handleSubmit(values);
        }}
      >
        {({ submitForm, handleChange, errors, values }) => (
          <Form>
            <Grid container rowSpacing={2} sx={{ mt: 2.5 }}>
              <Grid container item columnSpacing={3} sx={{ paddingTop: 2 }}>
                <Grid item mb={0}>
                  {/* email */}
                  <FormControl fullWidth sx={fieldStyle}>
                    <InputLabel htmlFor={values.email || ''} sx={getInputLabelStyles}>
                      {t('shared.types.user.email')}
                    </InputLabel>
                    <TextField
                      id="email"
                      name="email"
                      type="text"
                      value={values.email ? values.email : ''}
                      onChange={handleChange}
                      fullWidth
                      placeholder={t('shared.types.user.email')}
                    />
                    {errors.email && <FormHelperText>{errors.email}</FormHelperText>}
                  </FormControl>

                  {/* account status */}
                  <FormControl fullWidth sx={fieldStyle}>
                    <InputLabel htmlFor={values.status || ''} sx={getInputLabelStyles}>
                      {t('shared.types.user.status')}
                    </InputLabel>
                    <TextField
                      id="status"
                      name="status"
                      type="text"
                      value={values.status ? values.status : ''}
                      disabled
                      fullWidth
                      placeholder={t('shared.types.user.status')}
                    />
                    {errors.email && <FormHelperText>{errors.email}</FormHelperText>}
                  </FormControl>
                  {editMode && (
                    <>
                      {/* firstName */}
                      <FormControl fullWidth sx={fieldStyle}>
                        <InputLabel htmlFor={values.firstName || ''} sx={getInputLabelStyles}>
                          {t('shared.types.user.firstName')}
                        </InputLabel>
                        <TextField
                          id="firstName"
                          name="firstName"
                          type="text"
                          value={values.firstName ? values.firstName : ''}
                          onChange={handleChange}
                          fullWidth
                          placeholder={t('shared.types.user.firstName')}
                        />
                        {errors.firstName && <FormHelperText>{errors.firstName}</FormHelperText>}
                      </FormControl>
                      {/* lastName */}
                      <FormControl fullWidth sx={fieldStyle}>
                        <InputLabel htmlFor={values.lastName || ''} sx={getInputLabelStyles}>
                          {t('shared.types.user.lastName')}
                        </InputLabel>
                        <TextField
                          id="lastName"
                          name="lastName"
                          type="text"
                          value={values.lastName ? values.lastName : ''}
                          onChange={handleChange}
                          fullWidth
                          placeholder={t('shared.types.user.lastName')}
                        />
                        {errors.lastName && <FormHelperText>{errors.lastName}</FormHelperText>}
                      </FormControl>
                    </>
                  )}

                  {/* role */}
                  <FormControl fullWidth sx={fieldStyle}>
                    <InputLabel htmlFor={values.role || ''} sx={getInputLabelStyles}>
                      {t('shared.types.user.role')}
                    </InputLabel>
                    <Select
                      fullWidth
                      name="role"
                      id="role"
                      value={values.role ? values.role : ''}
                      onChange={handleChange}
                      displayEmpty
                    >
                      {Object.values(UserRole).map(role => (
                        <MenuItem key={role} value={role}>
                          {role}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.role && <FormHelperText>{errors.role}</FormHelperText>}
                  </FormControl>

                  {editMode && (
                    <>
                      {/* position */}
                      <FormControl fullWidth sx={fieldStyle}>
                        <InputLabel htmlFor={values.position || ''} sx={getInputLabelStyles}>
                          {t('shared.types.user.position')}
                        </InputLabel>
                        <Select
                          fullWidth
                          name="position"
                          id="position"
                          value={values.position ? values.position : ''}
                          onChange={handleChange}
                          displayEmpty
                        >
                          {SORTED_USER_POSITIONS.map(position => (
                            <MenuItem key={position} value={position}>
                              {position}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.position && <FormHelperText>{errors.position}</FormHelperText>}
                      </FormControl>

                      {/* department */}
                      <FormControl fullWidth sx={fieldStyle}>
                        <InputLabel htmlFor={values.department || ''} sx={getInputLabelStyles}>
                          {t('shared.types.user.department')}
                        </InputLabel>
                        <Select
                          fullWidth
                          name="department"
                          id="department"
                          value={values.department ? values.department : ''}
                          onChange={handleChange}
                          displayEmpty
                        >
                          {SORTED_USER_DEPARTMENTS.map(department => (
                            <MenuItem key={department} value={department}>
                              {department}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.department && <FormHelperText>{errors.department}</FormHelperText>}
                      </FormControl>
                    </>
                  )}

                  {/* language */}
                  <FormControl fullWidth sx={fieldStyle}>
                    <InputLabel htmlFor={values.language || ''} sx={getInputLabelStyles}>
                      {t('shared.standards.language')}
                    </InputLabel>
                    <Select
                      fullWidth
                      name="language"
                      id="language"
                      value={values.language ? values.language : ''}
                      onChange={handleChange}
                      displayEmpty
                    >
                      {Object.values(LanguageCode).map(language => (
                        <MenuItem key={language} value={language}>
                          {language}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.language && <FormHelperText>{errors.language}</FormHelperText>}
                  </FormControl>

                  {/* numberFormat */}
                  <FormControl fullWidth sx={fieldStyle}>
                    <InputLabel htmlFor={values.numberFormat || ''} sx={getInputLabelStyles}>
                      {t('shared.standards.numberFormat')}
                    </InputLabel>
                    <Select
                      fullWidth
                      name="numberFormat"
                      id="numberFormat"
                      value={values.numberFormat ? values.numberFormat : ''}
                      onChange={handleChange}
                      displayEmpty
                    >
                      {Object.values(NumberFormat).map(numberFormat => (
                        <MenuItem key={numberFormat} value={numberFormat}>
                          {numberFormat}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.numberFormat && <FormHelperText>{errors.numberFormat}</FormHelperText>}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                item
                sx={{
                  mt: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Button onClick={submitForm}>
                  {editMode ? t('modules.super.update') : t('modules.super.saveChanges')}
                </Button>
                <Button onClick={handleReset} color="secondary">
                  {t('modules.super.reset')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserForm;
