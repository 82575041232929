import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { CREATE_EXTERNAL_USER } from 'src/services/graphql/mutations';

import useMessages from './use-messages';
import useEnhancedMutation from './use-enhanced-mutation';
import { CollectOverview, LanguageCode, User } from 'src/modules/common/types';
import { COLLECT_OVERVIEWS, GET_USERS } from 'src/services/graphql/queries';
import useUserData from './use-user-data';

const useExternalUser = (year: number) => {
  const { t } = useTranslation();
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { user } = useUserData();

  const [isInviteExternalUserDialogOpen, setInviteExternalUserDialogOpen] = useState(false);
  const [selectedCollectOverviewIds, setSelectedCollectOverviewIds] = useState<string[]>([]);

  const [createExternalUser] = useEnhancedMutation(CREATE_EXTERNAL_USER, {
    onCompleted: () => setSuccessMessage(t('messages.createExternalUser.success')),
    onError: () => setErrorMessage(t('messages.createExternalUser.error')),
    update(cache, { data: { createExternalUser } }, { variables }) {
      const { collectOverviews } = cache.readQuery<{ collectOverviews: CollectOverview[] }>({
        query: COLLECT_OVERVIEWS,
        variables: {
          year,
        },
      }) || { collectOverviews: [] };

      const existedData: CollectOverview[] = JSON.parse(JSON.stringify(collectOverviews));

      for (const collectOverviewId of variables?.input?.collectOverviewIds) {
        const index = existedData.findIndex(
          collectOverview => collectOverview.id === collectOverviewId
        );

        if (index > -1) {
          existedData[index].assignedToId = createExternalUser.id;
          existedData[index].assignedById = user?.id;
        }
      }

      cache.writeQuery({
        query: COLLECT_OVERVIEWS,
        data: { collectOverviews: existedData },
        variables: {
          year,
        },
      });

      const { users } = cache.readQuery<{
        users: User[];
      }>({
        query: GET_USERS,
      }) || { users: [] };

      cache.writeQuery({
        query: GET_USERS,
        data: {
          users: [...users, createExternalUser],
        },
      });
    },
  });

  const handleInviteAndAssignExternalUser = (data: {
    firstName: string;
    lastName: string;
    email: string;
    language: LanguageCode;
  }) => {
    createExternalUser({
      variables: {
        input: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          collectOverviewIds: selectedCollectOverviewIds,
          language: data.language,
        },
      },
    });
  };

  const handleInviteExternalUserDialogClose = () => {
    setInviteExternalUserDialogOpen(false);
    setSelectedCollectOverviewIds([]);
  };

  const handleInviteExternalUserDialogOpen = (collectOverviewIds: string[]) => {
    setInviteExternalUserDialogOpen(true);
    setSelectedCollectOverviewIds(collectOverviewIds);
  };

  return {
    isInviteExternalUserDialogOpen,
    handleInviteExternalUserDialogOpen,
    handleInviteAndAssignExternalUser,
    handleInviteExternalUserDialogClose,
  };
};

export default useExternalUser;
