import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Facility } from 'src/modules/common/types';
import { useEnhancedQuery, useMessages, useEnhancedMutation } from 'src/modules/common/application';
import {
  CREATE_FACILITY_BUY_SUPER,
  UPDATE_FACILITY_BY_SUPER,
} from 'src/services/graphql/mutations';
import { GET_FACILITIES_BY_COMPANY_ID } from 'src/services/graphql/queries';

const useFacilitySuper = (companyId: string | undefined) => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { t } = useTranslation();

  const { loading, data, error, refetch } = useEnhancedQuery<{
    facilitiesByCompanyId: Facility[] | undefined;
  }>(GET_FACILITIES_BY_COMPANY_ID, {
    variables: {
      companyId,
    },
  });

  const facilities = data ? data.facilitiesByCompanyId : undefined;

  const [facility, setFacility] = useState<Facility | undefined>(undefined);

  const editMode = facility && facility.id;

  const handleSelectFacility = (id: string) => {
    setFacility(facilities?.find(facility => facility.id === id));
  };

  const [createFacility] = useEnhancedMutation(CREATE_FACILITY_BUY_SUPER, {
    onError: () => setErrorMessage(t('messages.createFacility.error')),
    onCompleted: async () => {
      handleSelectFacility('');
      setSuccessMessage(t('messages.createFacility.success'));
      await refetch();
    },
    fetchPolicy: 'no-cache',
  });

  const [updateFacilityBySuper] = useEnhancedMutation(UPDATE_FACILITY_BY_SUPER, {
    onError: () => setErrorMessage(t('messages.updateFacility.error')),
    onCompleted: async () => {
      handleSelectFacility('');
      setSuccessMessage(t('messages.updateFacility.success'));
      await refetch();
    },
    fetchPolicy: 'no-cache',
  });

  const handleSubmitFacility = async (newFacility: Facility) => {
    if (!editMode) {
      const result = await createFacility({
        variables: {
          input: {
            name: newFacility.name,
            city: newFacility.city,
            country: newFacility.country,
            type: newFacility.type,
            companyId: companyId,
          },
        },
      });
      setFacility(result.data.createCompany);
    } else {
      await updateFacilityBySuper({
        variables: {
          input: {
            id: facility.id,
            name: newFacility.name,
            city: newFacility.city,
            country: newFacility.country,
            type: newFacility.type,
            companyId: companyId,
          },
        },
      });
    }
  };

  return {
    loading,
    facilities,
    error,
    facility,
    handleSelectFacility,
    editMode,
    handleSubmitFacility,
  };
};

export default useFacilitySuper;
