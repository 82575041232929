import React, { useState } from 'react';
import { Box, Dialog, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Button from 'src/modules/common/components/Button/Button';
import theme from 'src/styles/theme';
import { MAX_CHARACTER_COUNT } from 'src/modules/common/types/constants/reminder-dialog';
import { TransformedCollectOverview } from 'src/modules/common/types/collect';

import { textFieldInputStyle } from './styles';
import { ReminderStep } from './ReminderStep';
import useReminder from '../../application/use-reminder';

interface Props {
  collectOverviewId: string | undefined;
  isOpen: boolean;
  onCancel: () => void;
  onSend: (message?: string) => void;
  selectedRows: Record<string, TransformedCollectOverview>;
  singleSelection: TransformedCollectOverview | null;
  dispatchMutli: boolean;
  recipientId: string | undefined;
}

export const ReminderDialog = ({
  collectOverviewId,
  isOpen,
  onCancel,
  onSend,
  selectedRows,
  singleSelection,
  dispatchMutli,
  recipientId,
}: Props) => {
  const { t } = useTranslation();
  const {
    handleSendReminder,
    loadingSendReminder,
    data: reminders,
  } = useReminder(collectOverviewId, selectedRows, singleSelection, dispatchMutli, recipientId);

  const [note, setNote] = useState<string>('');

  const handleSave = () => {
    onSend();
    setNote('');
    handleSendReminder(note);
  };

  const handleOnCancel = () => {
    onCancel();
    setNote('');
  };

  return (
    <Dialog open={isOpen}>
      <Box
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          minWidth: '600px',
          background: '#fff',
        }}
      >
        <Typography fontSize={'1.5rem !important'} fontWeight={600}>
          {t('modules.dataManagement.reminderDialog.title')}
        </Typography>

        {reminders && <ReminderStep reminders={reminders} />}
        <Box sx={{ height: '160px' }}>
          <Typography
            sx={{ color: theme.palette.colors.lightGrey }}
            fontSize={'0.875rem !important'}
            fontWeight={500}
          >
            {t('modules.dataManagement.reminderDialog.leaveNote')}
          </Typography>
          <TextField
            multiline
            margin="normal"
            inputProps={{
              maxLength: MAX_CHARACTER_COUNT,
            }}
            sx={textFieldInputStyle}
            rows={5}
            variant="outlined"
            fullWidth
            placeholder={t('modules.dataManagement.reminderDialog.optional')}
            helperText={`${note.length}/${MAX_CHARACTER_COUNT}`}
            value={note}
            onChange={e => setNote(e.target.value)}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="outlined" size="small" onClick={handleOnCancel}>
            {t('modules.dataManagement.reminderDialog.cancel')}
          </Button>
          <Button
            variant="contained"
            size="small"
            loading={loadingSendReminder}
            onClick={handleSave}
            sx={{ ml: 2 }}
          >
            {t('modules.dataManagement.reminderDialog.save')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
