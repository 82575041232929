import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Company, User } from 'src/modules/common/types';
import { UPDATE_PASSWORD } from 'src/services/graphql/mutations';
import { SetPasswordForm } from 'src/modules/auth/components';
import { useMessages, useUserData, useEnhancedMutation } from 'src/modules/common/application';
import { GET_USER_LOGIN_DETAILS } from 'src/services/graphql/queries';
import { AuthLayout, CardLayout } from 'src/modules/common/components';

export interface ErrnoException extends Error {
  errno?: number;
  code?: string;
  path?: string;
  syscall?: string;
  stack?: string;
}

const SetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSuccessMessage, setErrorMessage } = useMessages();
  const { hasCompanyOnboarded } = useUserData();

  const [updatePassword, { loading }] = useEnhancedMutation(UPDATE_PASSWORD, {
    fetchPolicy: 'no-cache',
    onError: () => setErrorMessage(t('messages.updatePassword.error')),
    onCompleted: async () => {
      setSuccessMessage(t('messages.updatePassword.success'));
      hasCompanyOnboarded ? navigate('/user-info') : navigate('/onboarding-start');
    },
    update(cache, result, { variables }) {
      const { user, company } = cache.readQuery<{
        user: User;
        company: Company;
      }>({
        query: GET_USER_LOGIN_DETAILS,
      }) || { user: undefined, company: undefined };

      if (result.data.updatePassword) {
        cache.writeQuery({
          query: GET_USER_LOGIN_DETAILS,
          data: {
            user: {
              ...user,
              status: result.data.updatePassword.status,
            },
            company,
          },
        });
      }
    },
  });

  const onSubmit = async (values: Record<string, any>) => {
    const { password } = values;
    await updatePassword({
      variables: {
        newPassword: password,
      },
    });
  };

  return (
    <>
      <AuthLayout title="" showAppLogo={false} showDashboard={true}>
        <CardLayout height="fit-content">
          <SetPasswordForm onSubmit={onSubmit} loading={loading} />
        </CardLayout>
      </AuthLayout>
    </>
  );
};

export default SetPassword;
