import React from 'react';
import { CALCULATE_YEARLY_STATISTICS } from 'src/services/graphql/mutations';
import { useMessages, useEnhancedMutation } from 'src/modules/common/application';
import { useTranslation } from 'react-i18next';
import Button from 'src/modules/common/components/Button/Button';

interface Props {
  companyId: string | undefined;
}

const YearlyCalculations = ({ companyId }: Props) => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { t } = useTranslation();

  const [calculateGeneralStatistics, { loading }] = useEnhancedMutation(
    CALCULATE_YEARLY_STATISTICS,
    {
      onCompleted: () => setSuccessMessage(t('messages.calculation.success')),
      onError: () => setErrorMessage(t('messages.calculation.error')),
    }
  );

  const handleClick = () => {
    calculateGeneralStatistics({
      variables: {
        companyId,
      },
    });
  };

  return (
    <Button
      sx={{
        width: '100%',
        mt: 2,
      }}
      onClick={handleClick}
      loading={loading}
    >
      Calculate Yearly Statistics
    </Button>
  );
};

export default YearlyCalculations;
