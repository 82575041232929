import React from 'react';
import { useTranslation } from 'react-i18next';
import { Step, StepLabel, Stepper } from '@mui/material';
import { useLocation } from 'react-router-dom';

const OnboardingStepper = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const activeStep = location.pathname.includes('user-info')
    ? 0
    : location.pathname.includes('company-info')
    ? 1
    : 2;

  const steps = [
    t('modules.companyOnboarding.stepper.userInfo'),
    t('modules.companyOnboarding.stepper.companyInfo'),
    t('modules.companyOnboarding.stepper.addFacility'),
  ];

  return (
    <Stepper activeStep={activeStep} alternativeLabel sx={{ width: '100%' }}>
      {steps.map(label => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default OnboardingStepper;
