import React, { useState } from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LanguageCode, NumberFormat } from 'src/modules/common/types';
import { useUserData } from 'src/modules/common/application';
import theme from 'src/styles/theme';
import useUserSettings from 'src/modules/common/application/use-user-settings';
import useLanguage from 'src/modules/common/application/use-language';
import { updateIntercomLanguagePreference } from 'src/modules/common/application/context/IntercomContext';

const GeneralSettings = () => {
  const { t } = useTranslation();
  const { user } = useUserData();
  const { updateUser } = useUserSettings();
  const { language, setLanguage } = useLanguage();

  const [selectedNumberFormat, setSelectedNumberFormat] = useState(
    user && user.numberFormat ? user.numberFormat : NumberFormat.COMMA_PERIOD
  );

  const handleLanguageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLang = (event.target as HTMLInputElement).value as LanguageCode;
    setLanguage(newLang);

    //Update the intercom language preference
    updateIntercomLanguagePreference(newLang);
  };

  const handleNumberFormatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFormat = (event.target as HTMLInputElement).value as NumberFormat;
    setSelectedNumberFormat(newFormat);
    updateUser({ numberFormat: newFormat });
  };

  return (
    <Box>
      <Box
        sx={{
          height: '100%',
          backgroundColor: 'background.white',
          display: 'flex',
          padding: 1,
          flexDirection: 'column',
          color: 'text.secondary',
          border: '1px solid',
          borderColor: 'background.grey',
          borderRadius: '10px',
          fontSize: '14px',
          mb: 2,
        }}
      >
        <Box sx={{ pl: 2.5, pt: 1 }}>
          <Typography sx={{ color: 'text.primary', fontSize: '18px', fontWeight: 600, mb: 3 }}>
            {t('modules.settings.general.languageTitle')}
          </Typography>

          <RadioGroup value={language} onChange={handleLanguageChange} sx={{ pl: 1 }}>
            <FormControlLabel
              value={LanguageCode.EN}
              control={<Radio />}
              label={
                <Typography sx={{ color: theme.palette.colors.lightGrey }} variant="subtitle1">
                  {t('modules.settings.general.english')}
                </Typography>
              }
              sx={{ mb: 0.5 }}
            />
            <FormControlLabel
              value={LanguageCode.DE}
              control={<Radio />}
              label={
                <Typography sx={{ color: theme.palette.colors.lightGrey }} variant="subtitle1">
                  {t('modules.settings.general.german')}
                </Typography>
              }
            />
          </RadioGroup>
        </Box>
      </Box>
      <Box
        sx={{
          height: '100%',
          backgroundColor: 'background.white',
          display: 'flex',
          padding: 1,
          flexDirection: 'column',
          color: 'text.secondary',
          border: '1px solid',
          borderColor: 'background.grey',
          borderRadius: '10px',
          fontSize: '14px',
        }}
      >
        <Box sx={{ pl: 2.5, pt: 1 }}>
          <Typography sx={{ color: 'text.primary', fontSize: '18px', fontWeight: 600, mb: 3 }}>
            {t('modules.settings.general.numberFormatTitle')}
          </Typography>

          <RadioGroup value={selectedNumberFormat} onChange={handleNumberFormatChange}>
            <Box>
              <Typography
                sx={{ color: theme.palette.colors.lightGrey }}
                variant="subtitle2"
                display="inline"
              >
                {t('modules.settings.general.numberFormatCommaPeriod.title')}
              </Typography>{' '}
              <Typography
                sx={{ color: theme.palette.colors.grey, fontWeight: 'bold' }}
                display="inline"
                variant="subtitle2"
              >
                {t('modules.settings.general.numberFormatCommaPeriod.example')}
              </Typography>
            </Box>
            <FormControlLabel
              value={NumberFormat.COMMA_PERIOD}
              control={<Radio />}
              label={
                <Typography sx={{ color: theme.palette.colors.lightGrey }} variant="subtitle1">
                  {t('modules.settings.general.numberFormatCommaPeriod.text')}
                </Typography>
              }
              sx={{ mb: 0.5, pl: 1 }}
            />

            <Box>
              <Typography
                sx={{ color: theme.palette.colors.lightGrey, display: 'inline-block' }}
                variant="subtitle2"
              >
                {t('modules.settings.general.numberFormatPeriodComma.title')}
              </Typography>{' '}
              <Typography
                sx={{
                  color: theme.palette.colors.grey,
                  fontWeight: 'bold',
                  display: 'inline-block',
                }}
                variant="subtitle2"
              >
                {t('modules.settings.general.numberFormatPeriodComma.example')}
              </Typography>
            </Box>
            <FormControlLabel
              value={NumberFormat.PERIOD_COMMA}
              control={<Radio />}
              label={
                <Typography sx={{ color: theme.palette.colors.lightGrey }} variant="subtitle1">
                  {t('modules.settings.general.numberFormatPeriodComma.text')}
                </Typography>
              }
              sx={{ mb: 0.5, pl: 1 }}
            />
          </RadioGroup>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralSettings;
