import React, { ReactNode } from 'react';
import { Box, SxProps, useTheme } from '@mui/material';
import { Drawer } from '../Base';
import { DrawerContent } from '../Base/Drawer';
import useDrawerState from 'src/modules/common/application/useDrawerState';

interface Props {
  children?: ReactNode;
  mainSx?: SxProps;
}

const Layout = ({ children, mainSx }: Props) => {
  const { drawerOpen, toggleDrawer } = useDrawerState();
  const theme = useTheme();
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
      }}
    >
      <Drawer open={drawerOpen}>
        <DrawerContent drawerExpanded={drawerOpen} toggleDrawer={toggleDrawer} />
      </Drawer>
      <Box
        component="main"
        sx={{
          position: 'relative',
          overflow: 'hidden',
          flexGrow: 1,
          minHeight: '100vh',
          padding: theme.spacing(0, 4, 3, 3),
          ...mainSx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
