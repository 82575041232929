import { gql } from '@apollo/client';
import { CORE_ESG_VALUE_FIELDS } from '../fragments/esgValue';

export const UPDATE_ESG_VALUES_BY_OVERVIEW_ID = gql`
  ${CORE_ESG_VALUE_FIELDS}
  mutation UpdateEsgValues($input: UpdateEsgValuesInput!) {
    updateEsgValues(input: $input) {
      created {
        ...CoreEsgValueFields
      }
      updated {
        ...CoreEsgValueFields
      }
      removed {
        id
      }
    }
  }
`;

export const CLEAN_UNLINKED_REQUESTED_ESG_VALUES = gql`
  mutation CleanUnlinkedRequestedESGValue($companyId: UUID!) {
    cleanUnlinkedRequestedESGValue(companyId: $companyId)
  }
`;
