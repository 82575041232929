import { useState, useEffect } from 'react';
import { EnhancedChatMessage } from '../types';

const useTypingAnimation = (initialMessage: EnhancedChatMessage, interval = 20) => {
  const [displayText, setDisplayText] = useState('');
  const [completedTyping, setCompletedTyping] = useState(false);

  useEffect(() => {
    if (!initialMessage.showAnimated) {
      setDisplayText(initialMessage.content);
      return () => {};
    } else {
      const initialText = initialMessage.content || '';

      let i = 0;

      const intervalId = setInterval(() => {
        setDisplayText(initialText.slice(0, i));
        i++;

        if (i > initialText.length) {
          clearInterval(intervalId);
          setCompletedTyping(true);
        }
      }, interval);

      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMessage.showAnimated, interval]);

  return { displayText, completedTyping };
};

export default useTypingAnimation;
