import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Company } from 'src/modules/common/types';
import { useEnhancedQuery, useMessages, useEnhancedMutation } from 'src/modules/common/application';
import { CREATE_COMPANY, EDIT_COMPANY_BY_SUPER } from 'src/services/graphql/mutations';
import { GET_COMPANIES } from 'src/services/graphql/queries';

const useCompanySuper = () => {
  const { setErrorMessage, setSuccessMessage } = useMessages();
  const { t } = useTranslation();

  const { loading, data, error, refetch } = useEnhancedQuery<{
    companies: Company[] | undefined;
  }>(GET_COMPANIES);

  const companies = data ? data.companies : undefined;

  const [company, setCompany] = useState<Company | undefined>(undefined);

  const editMode = company && company.id;

  const handleSelectCompany = (id: string) => {
    setCompany(companies?.find(company => company.id === id));
  };

  const [createCompany] = useEnhancedMutation(CREATE_COMPANY, {
    onError: () => setErrorMessage(t('messages.createCompany.error')),
    onCompleted: async () => {
      handleSelectCompany('');
      setSuccessMessage(t('messages.createCompany.success'));
      await refetch();
    },
    fetchPolicy: 'no-cache',
  });

  const [updateCompanyBySuper] = useEnhancedMutation(EDIT_COMPANY_BY_SUPER, {
    onError: () => setErrorMessage(t('messages.updateCompany.error')),
    onCompleted: async () => {
      handleSelectCompany('');
      setSuccessMessage(t('messages.updateCompany.success'));
      await refetch();
    },
    fetchPolicy: 'no-cache',
  });

  const handleSubmitCompany = async (newCompany: Company) => {
    if (!editMode) {
      const result = await createCompany({
        variables: {
          input: {
            name: newCompany.name,
            dashboardUrl: newCompany.dashboardUrl,
            headquarter: newCompany.headquarter,
            employeesNum: newCompany.employeesNum,
            subIndustryId: undefined,
            status: newCompany.status,
          },
        },
      });
      setCompany(result.data.createCompany);
    } else {
      await updateCompanyBySuper({
        variables: {
          input: {
            id: company.id,
            name: newCompany.name,
            dashboardUrl: newCompany.dashboardUrl,
            headquarter: newCompany.headquarter,
            employeesNum: newCompany.employeesNum,
            status: newCompany.status,
          },
        },
      });
    }
  };

  return {
    loading,
    companies,
    error,
    company,
    handleSelectCompany,
    editMode,
    handleSubmitCompany,
  };
};

export default useCompanySuper;
