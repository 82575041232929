import { useState } from 'react';
import { StateholdersResultsModalType, User } from 'src/modules/common/types';

const useMaterialityAssessmentResultDialog = () => {
  const [resultModalData, setResultModalOpen] = useState<StateholdersResultsModalType>({
    isOpen: false,
  });

  const handleOnClose = () => {
    setResultModalOpen({
      isOpen: false,
      data: undefined,
      type: undefined,
    });
  };

  const handleOpenDialog = (type: 'FAVOUR' | 'AGAINST', data: User[]) => {
    setResultModalOpen({
      isOpen: true,
      data: data,
      type: type,
    });
  };

  return {
    isOpen: resultModalData.isOpen,
    data: resultModalData.data,
    type: resultModalData.type,
    onClose: handleOnClose,
    openDialog: handleOpenDialog,
  };
};

export default useMaterialityAssessmentResultDialog;
