import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { sendMessageToSlack } from 'src/services/slack.service';

import { REGISTER_USER, RESEND_VERIFY_EMAIL } from 'src/services/graphql/mutations';

import useMessages from './use-messages';

const useRegister = () => {
  const { t } = useTranslation();
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [email, setEmail] = useState('');
  const { setErrorMessage, setSuccessMessage } = useMessages();

  const [registerUser] = useMutation(REGISTER_USER, {
    onError: () => {
      setSuccessMessage(t('messages.register.newLeadMessage'));
      sendMessageToSlack('Leeeaaaad *_*: ' + email);
    },
    fetchPolicy: 'no-cache',
  });

  const [resendVerifyEmail] = useMutation(RESEND_VERIFY_EMAIL, {
    onError: () => {
      setErrorMessage(t('messages.register.error'));
    },
    fetchPolicy: 'no-cache',
  });

  const onSubmit = async ({ email }: { email: string }) => {
    try {
      setEmail(email);
      const result = await registerUser({
        variables: { email },
      });

      if (result.data && !result.errors) {
        setShowVerifyEmail(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { onSubmit, resendVerifyEmail, showVerifyEmail, email };
};

export default useRegister;
