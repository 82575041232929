import React, { useState } from 'react';
import {
  Dialog,
  Box,
  Typography,
  Select,
  SelectChangeEvent,
  Button,
  MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import { EsgCategory } from 'src/modules/common/types';

interface Props {
  open: boolean;
  categories: EsgCategory[];
  handleAssign: (categoryId: string) => void;
  onClose: () => void;
}

const ImportFileDialog = ({ open, categories, handleAssign, onClose }: Props) => {
  const { t } = useTranslation();

  const [selectedUser, setSelectedUser] = useState('');

  const handleChange = (person: SelectChangeEvent<string>) => {
    setSelectedUser(person.target.value as string);
  };

  const resetStates = () => {
    setSelectedUser('');
  };
  const onSend = () => {
    handleAssign(selectedUser);
    resetStates();
    onClose();
  };

  const onCancel = () => {
    resetStates();
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onCancel} open={open}>
      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ pb: 4, display: 'flex', flexDirection: 'column' }}>
          <Typography align="left" gutterBottom variant="h5">
            {t('modules.dataManagement.importFileDialog.title')}
          </Typography>
          <Typography align="left" color="text.secondary" variant="body2">
            {t('modules.dataManagement.importFileDialog.description')}
          </Typography>
        </Box>
        <Select
          labelId="responsiblePerson"
          id="responsiblePerson"
          value={selectedUser}
          onChange={handleChange}
          sx={{ mb: 3 }}
        >
          <MenuItem value="">
            <em>{t('modules.dataManagement.importFileDialog.categorySelect')}</em>
          </MenuItem>
          {categories?.map((category: EsgCategory) => {
            return (
              <MenuItem key={category.id} value={category.id}>
                {t('shared.types.esgCategory.' + removeUnderscoreToCamelCase(category.title))}
              </MenuItem>
            );
          })}
        </Select>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="outlined" onClick={onCancel}>
            {t('modules.dataManagement.importFileDialog.cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={onSend}
            sx={{ ml: 2 }}
            disabled={selectedUser === ''}
          >
            {t('modules.dataManagement.importFileDialog.next')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ImportFileDialog;
