import theme from 'src/styles/theme';

export enum ChipColorVariants {
  ALMOND = 'almond',
  LIGHT_GREEN = 'light-green',
  PURPLE = 'purple',
  LIGHT_YELLOW = 'light-yellow',
  WATER = 'water',
  TEALISH_BLUE = 'tealish-blue',
  GREENISH_CYAN = 'greenish-cyan',
  GREENISH_YELLOW = 'greenish-yellow',
  PALE_GREEN = 'pale-green',
  ICE_BERG = 'ice-berg',
  GHOST = 'ghost',
  JAGGED_ICE = 'jagged-ice',
  RED = 'red',
  GRAY = 'gray',
  LIGHT_RED = 'light-red',
  DEFAULT = 'default',
  WARM_BLUE = 'warm-blue',
}

export const ChipColorVariantsMap = {
  [ChipColorVariants.ALMOND]: {
    background: theme.palette.colors.almond,
    color: theme.palette.colors.grey,
  },
  [ChipColorVariants.PURPLE]: {
    background: theme.palette.colors.lightPurple,
    color: theme.palette.colors.grey,
  },
  [ChipColorVariants.LIGHT_GREEN]: {
    background: theme.palette.colors.lightGreen,
    color: theme.palette.colors.grey,
  },
  [ChipColorVariants.LIGHT_YELLOW]: {
    background: theme.palette.colors.citrineWhite,
    color: theme.palette.colors.gunMetal,
  },
  [ChipColorVariants.WATER]: {
    background: theme.palette.colors.iceBerg,
    color: theme.palette.colors.grey,
  },
  [ChipColorVariants.TEALISH_BLUE]: {
    background: theme.palette.colors.tealishBlue,
    color: theme.palette.colors.grey,
  },
  [ChipColorVariants.GREENISH_CYAN]: {
    background: theme.palette.colors.greenishCyan,
    color: theme.palette.colors.grey,
  },
  [ChipColorVariants.GREENISH_YELLOW]: {
    background: theme.palette.colors.greenishYellow,
    color: theme.palette.colors.grey,
  },
  [ChipColorVariants.PALE_GREEN]: {
    background: theme.palette.colors.paleGreen,
    color: theme.palette.colors.grey,
  },
  [ChipColorVariants.ICE_BERG]: {
    background: theme.palette.colors.iceBerg,
    color: theme.palette.colors.grey,
  },
  [ChipColorVariants.GHOST]: {
    background: theme.palette.colors.ghost,
    color: theme.palette.colors.grey,
  },
  [ChipColorVariants.JAGGED_ICE]: {
    background: theme.palette.colors.jaggedIce,
    color: theme.palette.colors.grey,
  },
  [ChipColorVariants.RED]: {
    background: theme.palette.colors.red,
    color: theme.palette.colors.grey,
  },
  [ChipColorVariants.GRAY]: {
    background: theme.palette.background.grey,
    color: theme.palette.colors.grey,
  },
  [ChipColorVariants.LIGHT_RED]: {
    background: theme.palette.colors.lightRed,
    color: theme.palette.colors.grey,
  },
  [ChipColorVariants.DEFAULT]: {
    background: theme.palette.background.default,
    color: theme.palette.colors.grey,
  },
  [ChipColorVariants.WARM_BLUE]: {
    background: theme.palette.colors.softPrimary,
    color: theme.palette.primary.main,
  },
} as const;
