import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';

import { LOGIN, STORE_USER_EMAIL } from 'src/services/graphql/mutations';
import {
  useBackendErrorMessage,
  useEnhancedQuery,
  useMessages,
  useUserData,
  useEnhancedMutation,
} from 'src/modules/common/application';
import { LoginForm } from 'src/modules/auth/components';
import { GET_INITIAL_STATE } from 'src/services/graphql/queries';
import {
  AuthLayout,
  CardLayout,
  LanguageSelect,
  TermsAndConditions,
} from 'src/modules/common/components';

const Login = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const { refetch } = useUserData();
  const { setErrorMessage } = useMessages();

  const { data } = useEnhancedQuery(GET_INITIAL_STATE);

  const setBackendError = useBackendErrorMessage();
  const [login] = useEnhancedMutation(LOGIN, {
    onError: () => {
      setErrorMessage(t('messages.login.error'));
    },
    onCompleted: () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const redirectPath = ((state as Record<string, any>)?.redirect as string) || '/';
      navigate(redirectPath);
    },
    fetchPolicy: 'no-cache',
  });

  const [storeEmail] = useEnhancedMutation(STORE_USER_EMAIL, {
    onError: error => {
      setBackendError(error.message, t('messages.error.storeMail'));
    },
  });

  const onSubmit = async ({
    rememberUser,
    email,
    password,
  }: {
    rememberUser: boolean;
    email: string;
    password: string;
  }) => {
    rememberUser && (await storeEmail({ variables: { email } }));
    await login({
      variables: { email, password },
    });
    refetch();
  };

  return (
    <AuthLayout title={t('modules.login.authLayout.title')} showDashboard={true}>
      <CardLayout height="fit-content">
        <LoginForm onSubmit={onSubmit} prefilledEmail={data?.userEmail || undefined} />
      </CardLayout>
      <Box sx={{ width: 500, mt: 2 }}>
        <LanguageSelect />
      </Box>
      <Box sx={{ position: 'absolute', bottom: 5 }}>
        <TermsAndConditions />
      </Box>
    </AuthLayout>
  );
};
export default Login;
