import React from 'react';
import { Grid } from '@mui/material';

import { User, RequestStatus } from 'src/modules/common/types';

import MAStatsCard from '../MAStatsCard/MAStatsCard';

interface Props {
  data: {
    title: string;
    type: RequestStatus;
    stackeholders: User[];
  }[];
}

const MAOverviewStats = ({ data }: Props) => {
  return (
    <Grid
      container
      spacing={4}
      sx={{
        alignItems: 'stretch',
        paddingTop: '0px !important',
        marginTop: 0,
      }}
    >
      {data.map(s => (
        <Grid key={s.title} item md={4} sm={6} xs={12} sx={{ paddingTop: '24px !important' }}>
          <MAStatsCard title={s.title} type={s.type} stackeholders={s.stackeholders} />
        </Grid>
      ))}
    </Grid>
  );
};

export default MAOverviewStats;
