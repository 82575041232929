import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, useTheme } from '@mui/material';

import { useMessages, useRegister } from 'src/modules/common/application';
import { ErrorState } from 'src/modules/common/components';
import Button from 'src/modules/common/components/Button/Button';

export interface ResendProps {
  email: string;
  title: string;
  subtitle: string;
  buttonText: string;
}

const ResendVerification = ({ email, title, subtitle, buttonText }: ResendProps) => {
  const { setErrorMessage } = useMessages();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const [resentVerificationMail, setResentVerificationMail] = useState(false);
  const { resendVerifyEmail } = useRegister();

  const handleResendVerification = async () => {
    try {
      setLoading(true);
      await resendVerifyEmail({
        variables: { email },
      });
      setResentVerificationMail(true);
    } catch (error: any) {
      setErrorMessage(error.message);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  if (error)
    return <ErrorState error={error} to="/login" label={t('shared.authPages.loginReferral')} />;

  if (!resentVerificationMail) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
            {subtitle}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handleResendVerification} loading={loading} fullWidth>
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">
            {t('modules.registerInvited.resendVerification.resendConfirmationTitle')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.secondary,
              textAlign: 'center',
            }}
          >
            {t('modules.registerInvited.resendVerification.resendConfirmationSubtitle')}
            {`${email}`}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResendVerification;
