import { useContext } from 'react';
import LanguageContext from './context/LanguageContext';

const useLanguage = () => {
  const { language, setLanguage } = useContext(LanguageContext);

  return {
    language,
    setLanguage,
  };
};

export default useLanguage;
