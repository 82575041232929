import React from 'react';
import {
  Box,
  Step,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  stepLabelClasses,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getTotalDone } from 'src/modules/common/application/reminder';
import { CollectReminder, ReminderStatus } from 'src/modules/common/types';
import { getProperFormatedDate } from 'src/modules/common/application/date';
import theme from 'src/styles/theme';

interface ReminderStepProps {
  reminders: CollectReminder[];
}

const ReminderStepIcon = (
  props: StepIconProps & { isLastStep: boolean; isDone: boolean; showTooltip: boolean }
) => {
  const { t } = useTranslation();
  const { active, completed, isLastStep, isDone, showTooltip } = props;
  const iconComp = (
    <Box
      sx={{
        height: '20px',
        width: '20px',
        borderRadius: '50%',
        background: isLastStep ? theme.palette.colors.successGreen : theme.palette.primary.main,
        opacity: active || completed ? 1 : 0.5,
      }}
    />
  );
  return (
    <React.Fragment>
      {showTooltip && (
        <Tooltip
          title={t(
            `modules.dataManagement.reminderDialog.${isDone ? 'lastReminder' : 'nextReminder'}`
          )}
        >
          {iconComp}
        </Tooltip>
      )}
      {!showTooltip && iconComp}
    </React.Fragment>
  );
};

export const ReminderStep = (props: ReminderStepProps) => {
  const { reminders } = props;
  const { totalDone, lastReminderId, nextReminderId } = getTotalDone(reminders);

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
      <Stepper
        activeStep={totalDone - 1}
        alternativeLabel
        sx={{
          width: '100%',
          [`& .${stepLabelClasses.label}`]: {
            mt: 0.5,
            fontSize: '0.875rem !important',
          },
          [`& .${stepConnectorClasses.root}`]: {
            top: '10px',
            [`& .${stepConnectorClasses.line}`]: {
              height: '2px',
              borderWidth: 0,
              background: '#4356ED80',
            },
            [`&.${stepConnectorClasses.completed}`]: {
              [`& .${stepConnectorClasses.line}`]: {
                background: `${theme.palette.primary.main} !important`,
              },
            },
            [`&.${stepConnectorClasses.active}`]: {
              [`& .${stepConnectorClasses.line}`]: {
                background: `${theme.palette.primary.main} !important`,
              },
            },
          },
        }}
      >
        {reminders.map((item, key) => (
          <Step key={key}>
            <StepLabel
              StepIconComponent={props => (
                <ReminderStepIcon
                  {...props}
                  isDone={item.status === ReminderStatus.EXECUTED}
                  showTooltip={key === lastReminderId || key === nextReminderId}
                  isLastStep={key === reminders.length - 1}
                />
              )}
            >
              {getProperFormatedDate(item.executionDate, 'DD.MM.YYYY')}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
