import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Facility } from 'src/modules/common/types/graphql';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';
import { getChipColorForLetter } from 'src/modules/common/application/ui';
import { Chip } from 'src/modules/common/components/Chip';
import { StyledTableCell } from './styles';

interface Props {
  data: Facility;
}

const ManageFacilityTableRowData = ({ data }: Props) => {
  const { name, city, country, type } = data;
  const textStyles = { fontWeight: 600, fontSize: '0.875rem !important' };
  const chipStyles = { maxWidth: 'fit-content' };
  const { t } = useTranslation();
  return (
    <>
      <StyledTableCell>
        <Typography variant="body2" sx={textStyles}>
          {name}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Chip size="small" styles={chipStyles} colorVariant={getChipColorForLetter(city)}>
          {city}
        </Chip>
      </StyledTableCell>
      <StyledTableCell>
        <Chip size="small" styles={chipStyles} colorVariant={getChipColorForLetter(country)}>
          {t(`shared.types.countryCode.${country}`)}
        </Chip>
      </StyledTableCell>
      <StyledTableCell>
        <Chip size="small" styles={chipStyles} colorVariant={getChipColorForLetter(type)}>
          {t('shared.types.facilityType.' + removeUnderscoreToCamelCase(type || ''))}
        </Chip>
      </StyledTableCell>
    </>
  );
};

export default memo(ManageFacilityTableRowData);
