import React from 'react';
import { Stats } from 'src/modules/common/types';
import { OverviewStats } from 'src/modules/common/components';

interface Props {
  environmentalProgress: number;
  directEmissions: number;
  indirectEmissions: number;
  emissionIntensity: number;
}

const EnvironmentalOverviewStats = ({
  environmentalProgress,
  directEmissions,
  indirectEmissions,
  emissionIntensity,
}: Props) => {
  const environmentalProgressStatData: Stats = {
    title: 'modules.analysis.environmentalOverviewStats.environmentalDataCollection',
    value: environmentalProgress,
    unit: '%',
    progress: environmentalProgress,
  };
  return (
    <OverviewStats
      data={[
        environmentalProgressStatData,
        {
          title: 'modules.analysis.environmentalOverviewStats.directEmissions',
          value: directEmissions,
          unit: 'tCO₂e',
        },
        {
          title: 'modules.analysis.environmentalOverviewStats.indirectEmissions',
          value: indirectEmissions,
          unit: 'tCO₂e',
        },
        {
          title: 'modules.analysis.environmentalOverviewStats.emissionIntensity',
          value: emissionIntensity,
          unit: 'tCO₂e',
        },
      ]}
    />
  );
};

export default EnvironmentalOverviewStats;
