import { EsgValue, EsgKpi, ReportingFrameworks } from './graphql';

export enum EsgCategoryEnum {
  VEHICLE_FLEET = 'VEHICLE_FLEET',
  PURCHASED_ENERGY = 'PURCHASED_ENERGY',
  WATER = 'WATER',
  AGE_STRUCTURE = 'AGE_STRUCTURE',
  TRAINING = 'TRAINING',
  HEALTH_SAFETY = 'HEALTH_SAFETY',
  WASTE = 'WASTE',
  DISCRIMINATION = 'DISCRIMINATION',
  RAW_MATERIALS = 'RAW_MATERIALS',
  EMPLOYMENT_DIVERSITY = 'EMPLOYMENT_DIVERSITY',
  FINANCIAL = 'FINANCIAL',
  BOARD_DIVERSITY = 'BOARD_DIVERSITY',
  COMPLIANCE = 'COMPLIANCE',
  SELF_PRODUCED_ENERGY = 'SELF_PRODUCED_ENERGY',
}

export type TranslatedEsgKpi = EsgKpi & {
  esgValue?: EsgValue;
  translatedKpiTitle: string;
  translatedCategoryTitle: string;
  frameworks?: ReportingFrameworks;
};
