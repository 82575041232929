import React from 'react';
import { CompletedFacilities, Stats } from 'src/modules/common/types';
import { OverviewStats } from 'src/modules/common/components';

interface Props {
  overallProgress: number;
  numberRequestedValues: number;
  completedFacilities: CompletedFacilities;
  completedCategory: {
    completedCategories: number;
    totalCategories: number;
  };
}

const EnvironmentalOverviewStats = ({
  overallProgress,
  numberRequestedValues,
  completedFacilities,
  completedCategory,
}: Props) => {
  const overallProgressStatData: Stats = {
    title: 'modules.dataManagement.dataOverviewStats.progress',
    value: overallProgress,
    unit: '%',
    progress: overallProgress,
  };

  const completedFacilitiesStatData: Stats = {
    title: 'modules.dataManagement.dataOverviewStats.completedFacilities',
    value: completedFacilities.completedFacilities,
    unit: '/' + completedFacilities.totalFacilities,
  };

  const completedCategoryStatData: Stats = {
    title: 'modules.dataManagement.dataOverviewStats.completedCategories',
    value: completedCategory.completedCategories,
    unit: '/' + completedCategory.totalCategories,
  };

  const numberRequestedValuesStatData: Stats = {
    title: 'modules.dataManagement.dataOverviewStats.numberRequestedValues',
    value: numberRequestedValues,
    unit: '',
  };

  return (
    <OverviewStats
      data={[
        overallProgressStatData,
        numberRequestedValuesStatData,
        completedFacilitiesStatData,
        completedCategoryStatData,
      ]}
    />
  );
};

export default EnvironmentalOverviewStats;
