import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableRow, TableCell, Typography } from '@mui/material';
import { SidebarData } from 'src/modules/common/application/use-additional-info-sidebar';
import { removeUnderscoreToCamelCase } from 'src/modules/common/application/string';

interface Props {
  data: SidebarData;
}

const RelevantDataTableRow = ({ data }: Props) => {
  const { t } = useTranslation();
  return (
    <TableRow
      sx={{
        backgroundColor: 'white',
        width: '100%',
      }}
    >
      <TableCell width={'70%'}>
        <Typography fontSize={'0.875rem !important'} fontWeight={500}>
          {data.title}
        </Typography>
      </TableCell>
      <TableCell width={'20%'}>
        <Typography display="inline" variant="subtitle2" sx={{ mr: 0.5 }}>
          {`${data.value}`}
        </Typography>
      </TableCell>
      <TableCell width={'10%'}>
        <Typography display="inline" variant="subtitle2" sx={{ color: 'grey' }}>
          {data.unit ? t(`shared.types.unit.${removeUnderscoreToCamelCase(data.unit)}`) : '#'}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default RelevantDataTableRow;
