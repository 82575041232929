import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import {
  removeUnderscoreToCamelCase,
  removeSpacesAtStart,
} from 'src/modules/common/application/string';
import { UserNameAvatar } from 'src/modules/common/components';
import { Chip } from 'src/modules/common/components/Chip';

import { getRoleChipColor } from './utils';
import { StyledTableCell } from './styles';
import { ManageUser } from '../ManageTeam/types';

const textStyles = { color: 'text.grey', fontSize: '0.875rem !important' };
const chipStyles = {
  maxWidth: 'fit-content',
};

const ManageTeamTableRowData = ({ data }: { data: ManageUser }) => {
  const { t } = useTranslation();
  const { email, firstName, lastName, role, language, status } = data;

  const displayName: string =
    firstName && lastName
      ? `${removeSpacesAtStart(firstName)} ${removeSpacesAtStart(lastName)}`
      : (email as string);
  return (
    <>
      {firstName ? (
        <StyledTableCell>
          <UserNameAvatar text={displayName} />
        </StyledTableCell>
      ) : (
        <StyledTableCell>
          <Typography textAlign="center">{'-'}</Typography>
        </StyledTableCell>
      )}
      <StyledTableCell>
        <Typography variant="body2" sx={textStyles}>
          {email}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Chip size="small" styles={chipStyles} colorVariant={getRoleChipColor(role!)}>
          {t('shared.types.userRole.' + removeUnderscoreToCamelCase(role || ''))}
        </Chip>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body2" sx={textStyles}>
          {t(`modules.settings.manageTeam.${language}`)}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="body2" sx={textStyles}>
          {t('shared.types.userAccountStatus.' + removeUnderscoreToCamelCase(status || ''))}
        </Typography>
      </StyledTableCell>
    </>
  );
};

export default ManageTeamTableRowData;
