import { ButtonProps, Theme } from '@mui/material';

export const getButtonColorContrast = (variant: ButtonProps['variant'], theme: Theme) => {
  if (variant === 'contained') {
    return `${theme.palette.primary.contrastText} !important`;
  }
  return `${theme.palette.colors.lightPurple} !important`;
};

export const getButtonLabelColorContrast = ({
  variant,
  theme,
  color,
}: {
  variant?: string;
  color: string;
  theme: Theme;
}) => {
  if (variant === 'filled') {
    if (color === 'primary') {
      return `${theme.palette.colors.white} !important`;
    }
    return `${theme.palette.secondary.contrastText} !important`;
  }

  return `${theme.palette.primary.main} !important`;
};

export default getButtonColorContrast;
