import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';

import {
  AuthLayout,
  CardLayout,
  LanguageSelect,
  TermsAndConditions,
} from 'src/modules/common/components';
import { useRegister } from 'src/modules/common/application';
import { RegisterForm } from 'src/modules/auth/components';
import VerifyEmail from 'src/modules/auth/components/VerifyEmail/VerifyEmail';

const Register = () => {
  const { t } = useTranslation();
  const { onSubmit, showVerifyEmail, email } = useRegister();

  return showVerifyEmail ? (
    <VerifyEmail email={email} />
  ) : (
    <AuthLayout title={t('modules.login.authLayout.title')} showDashboard={true}>
      <CardLayout height="fit-content">
        <RegisterForm onSubmit={onSubmit} />
      </CardLayout>
      <Box sx={{ width: 500, mt: 2 }}>
        <LanguageSelect />
      </Box>
      <Box sx={{ position: 'absolute', bottom: 5 }}>
        <TermsAndConditions />
      </Box>
    </AuthLayout>
  );
};
export default Register;
