import theme from 'src/styles/theme';

export const AvatarColorVariants = {
  A: theme.palette.colors.almond,
  B: theme.palette.colors.lightPurple,
  C: theme.palette.colors.lightGreen,
  D: theme.palette.colors.citrineWhite,
  E: theme.palette.colors.iceBerg,
  F: theme.palette.colors.tealishBlue,
  G: theme.palette.colors.greenishCyan,
  H: theme.palette.colors.greenishYellow,
  I: theme.palette.colors.paleGreen,
  J: theme.palette.colors.almond,
  K: theme.palette.colors.lightPurple,
  L: theme.palette.colors.lightGreen,
  M: theme.palette.colors.citrineWhite,
  N: theme.palette.colors.iceBerg,
  O: theme.palette.colors.tealishBlue,
  P: theme.palette.colors.greenishCyan,
  Q: theme.palette.colors.greenishYellow,
  R: theme.palette.colors.paleGreen,
  S: theme.palette.colors.almond,
  T: theme.palette.colors.lightPurple,
  U: theme.palette.colors.lightGreen,
  V: theme.palette.colors.citrineWhite,
  W: theme.palette.colors.iceBerg,
  X: theme.palette.colors.tealishBlue,
  Y: theme.palette.colors.greenishCyan,
  Z: theme.palette.colors.greenishYellow,
} as const;
