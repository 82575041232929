import React, { useState } from 'react';
import type { DropzoneOptions, FileWithPath } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import ClearIcon from '@mui/icons-material/Clear';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import Button from 'src/modules/common/components/Button/Button';
import { bytesToSize } from 'src/modules/common/application/bytes';

export type File = FileWithPath;

interface Props extends DropzoneOptions {
  onUpload?: () => void;
}

const listIconStyles = {
  width: '20px',
};

const Dropzone = ({ onUpload, accept, maxSize, minSize, maxFiles }: Props) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[]>([]);
  const onDrop = (newFiles: File[]): void => {
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  };
  const onRemove = (file: File): void => {
    setFiles(prevFiles => prevFiles.filter(_file => _file.path !== file.path));
  };

  const onRemoveAll = () => {
    setFiles([]);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    maxSize,
    minSize,
  });

  return (
    <Box
      sx={theme => ({
        borderRadius: '10px',
        maxWidth: 900,
        margin: '0 auto',
        backgroundColor: 'colors.white',
        border: theme.custom.border,
        paddingBottom: '48px',
      })}
    >
      <Box
        sx={() => ({
          padding: 3,
          marginBottom: 6,
          border: '1px solid transparent',
          borderBottomColor: 'colors.softGrey',
        })}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: '20px !important',
            fontWeight: 600,
          }}
        >
          {t('modules.dataManagement.facilityFileImporter.import')}
        </Typography>
      </Box>
      <Box
        sx={{
          maxWidth: 670,
          margin: '0 auto',
          alignItems: 'center',
          borderRadius: '10px',
          border: '3px',
          borderStyle: 'dashed',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          outline: 'none',
          borderColor: 'divider',
          backgroundColor: 'action.hover',
          p: 6,
          ...(isDragActive && {
            backgroundColor: 'action.active',
            opacity: 0.5,
          }),
          '&:hover': {
            backgroundColor: 'action.hover',
            cursor: 'pointer',
          },
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Box
          sx={{
            p: 2,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            borderRadius: '10px',
          }}
        >
          <DriveFolderUploadIcon sx={{ mb: 2, width: 52, height: 52 }} />
          <Typography variant="h6">
            {t('modules.dataManagement.facilityFileImporter.uploadTemplate')}
          </Typography>

          <Typography variant="body1" sx={{ mt: 2, color: 'colors.lightGrey' }}>
            {t('modules.dataManagement.facilityFileImporter.dragAndDrop')}
          </Typography>
        </Box>
      </Box>
      {files.length > 0 && (
        <Box sx={{ mt: 2, paddingLeft: '24px', paddingRight: '24px' }}>
          <List>
            {files.map(file => (
              <ListItem
                key={file.path}
                sx={{
                  border: 1,
                  borderColor: 'divider',
                  borderRadius: '10px',
                  '& + &': {
                    mt: 1,
                  },
                  padding: '0 16px',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '48px',
                  }}
                >
                  <FileCopyIcon sx={listIconStyles} />
                </ListItemIcon>
                <ListItemText
                  primary={file.name}
                  primaryTypographyProps={{
                    color: 'textPrimary',
                    variant: 'body2',
                    fontSize: '0.675rem !important',
                  }}
                  secondary={bytesToSize(file.size)}
                  secondaryTypographyProps={{
                    color: 'colors.lightGrey',
                    variant: 'body2',
                  }}
                />
                <Tooltip title="Remove">
                  <IconButton edge="end" onClick={() => onRemove?.(file)}>
                    <ClearIcon sx={listIconStyles} />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 2,
            }}
          >
            <Button onClick={onRemoveAll} variant="outlined" size="small" type="button">
              {t('modules.dataManagement.facilityFileImporter.removeAll')}
            </Button>
            <Button
              onClick={onUpload}
              size="small"
              sx={{ ml: 2 }}
              type="button"
              variant="contained"
            >
              {t('modules.dataManagement.facilityFileImporter.upload')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Dropzone;
