import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useFeatureFlagEnabled } from 'posthog-js/react';

import { FeatureFlag } from 'src/modules/common/types/featureFlags';
import { useMessages, useYears } from 'src/modules/common/application';
import { Loader, TabBar, YearSelect } from 'src/modules/common/components';

import MaterialityAssessmentTopicPreSelection from '../MATopicPreSelection/MATopicPreSelection';
import { MAPreSelectionIcon } from './MATabIcons';
import MaterialityAssessmentTopicEvaluation from '../MATopicEvaluation/MATopicEvaluation';
import { getActiveTabIndex } from '../../application/utils';
import MARequestInputButton from '../MARequestInputButton/MARequestInputButton';

const MaterialityAssessmentContainer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setErrorMessage } = useMessages();
  const [isOpen, setOpen] = useState(false);

  const isMaterialityEvaluationEnabled = useFeatureFlagEnabled(
    FeatureFlag.MATERIALITY_EVALUATION_ROUTES
  );

  const { data, loading, error, selectedYear, handleSetSelectedYear, sortedYears } = useYears();

  const seletedTabIndex = getActiveTabIndex(location.pathname);

  const handleOnButtonClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleOnRequestFormClose = useCallback(() => {
    setOpen(false);
  }, []);

  if (loading || !data) return <Loader />;
  if (error) return <>{setErrorMessage(t('messages.generic.error'))}</>;

  const years = sortedYears ? sortedYears : [];

  const evaluationTab = isMaterialityEvaluationEnabled
    ? [
        {
          input: (
            <MaterialityAssessmentTopicEvaluation
              year={selectedYear}
              isRequestFormOpen={isOpen}
              handleOnRequestFormClose={handleOnRequestFormClose}
            />
          ),
          label: (
            <MAPreSelectionIcon
              title={t('modules.materialityAssessment.topicEvaluation')}
              tooltipText={t('modules.materialityAssessment.topicEvaluationTooltip')}
            />
          ),
          index: 1,
          id: 'topic-evaluation',
          to: `/materiality-assessment/evaluation`,
        },
      ]
    : [];

  const identifiactionTab = [
    {
      input: (
        <MaterialityAssessmentTopicPreSelection
          year={selectedYear}
          isRequestFormOpen={isOpen}
          handleOnRequestFormClose={handleOnRequestFormClose}
        />
      ),
      label: (
        <MAPreSelectionIcon
          title={t('modules.materialityAssessment.preSelection')}
          tooltipText={t('modules.materialityAssessment.preSelectionTooltip')}
        />
      ),
      index: 0,
      id: 'pre-selection',
      to: `/materiality-assessment`,
    },
  ];

  const TABS = [...identifiactionTab, ...evaluationTab];

  return (
    <TabBar
      activeTabIndex={seletedTabIndex}
      content={TABS}
      SideComponent={() => (
        <Box
          sx={{
            display: 'flex',
            right: 0,
            alignItems: 'flex-end',
          }}
        >
          <Box
            sx={{
              marginRight: 1,
            }}
          >
            <YearSelect years={years} year={selectedYear} setYear={handleSetSelectedYear} />
          </Box>
          <MARequestInputButton handleOnButtonClick={handleOnButtonClick} />
        </Box>
      )}
      sideComponentStyles={{
        display: 'flex',
        top: 0,
      }}
    />
  );
};

export default MaterialityAssessmentContainer;
