import { InputLabel, styled, Theme } from '@mui/material';
import { getButtonLabelColorContrast } from './utils';

interface VisibilityProps {
  buttoncolor: string;
  variant?: string;
  size?: string;
  visible: number;
  theme: Theme;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ProgressContainer = styled('div')<VisibilityProps>(
  ({ visible, variant, theme, buttoncolor }) => ({
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: '0',
    right: '0',
    opacity: 0,
    transform: 'translateY(30px)',
    transition: 'transform 0.125s ease-in',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > *': {
      color: getButtonLabelColorContrast({
        color: buttoncolor,
        theme,
        variant,
      }),
    },

    ...(visible && {
      opacity: 1,
      transform: 'translateY(0)',
    }),
  })
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ButtonLabel = styled(InputLabel)<VisibilityProps>(
  ({ visible, variant, size, theme, buttoncolor }) => ({
    transition: 'all 0.125s ease-in',
    display: 'flex',
    alignItems: 'center',
    opacity: 1,
    transform: 'translateY(0)',
    lineHeight: 1.25,
    cursor: 'pointer',
    fontSize: size === 'small' ? '0.875rem !important' : '1rem !important',
    maxWidth: 'none',

    color: getButtonLabelColorContrast({
      color: buttoncolor,
      theme,
      variant,
    }),

    ...(!visible && {
      opacity: 0,
      transform: 'translateY(-30px)',
    }),

    '& > svg': {
      marginRight: '8px',
    },
  })
);
